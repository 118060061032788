import { getUnixTime } from 'date-fns';

export function getDateRangeFilter({ gte, lte }: { gte?: Date | number; lte?: Date | number }): {
  gte: number | string;
  lte: number | string;
} {
  return {
    gte: gte ? getUnixTime(gte) : '',
    lte: lte ? getUnixTime(lte) : '',
  };
}
