import React from 'react';

import { ShowGuesser, ShowGuesserProps } from '@api-platform/admin';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useShowController } from 'react-admin';

import { useExistingChildren } from '../../../hooks/useExistingChildren';
import { layoutBase } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

type Props = {
  title: string;
  listName?: string;
  children: React.ReactNode;
  toolbarActions?: (React.ReactElement | false)[];
} & ShowGuesserProps;

const StyledShowGuesser = styled(ShowGuesser)(layoutBase);

export function AppShowGuesser({
  title,
  listName,
  children,
  toolbarActions,
  ...props
}: Props): JSX.Element {
  const { record = {} } = useShowController();
  const filteredChildren = useExistingChildren(children, Object.keys(record));

  return (
    <Box>
      {listName && <AppBreadCrumbs currentPage={title} listName={listName} />}
      <StyledShowGuesser {...props}>
        <AppPanelHeader pb={1}>
          <AppPanelHeaderToolbar actions={toolbarActions} titleText={title} />
        </AppPanelHeader>
        {filteredChildren}
      </StyledShowGuesser>
    </Box>
  );
}
