import React from 'react';

import { Box, Typography } from '@mui/material';
import { format as formatDate } from 'date-fns';
import { ru } from 'date-fns/locale';

import { PopoverToolbarDatesStyled, PopoverToolbarStyled } from './DatePickerToolbar.styled';

export type DatePickerToolbarProps = {
  title?: string;
  start: number | null;
  end: number | null;
  format?: string;
};

export const DatePickerToolbar: React.FC<DatePickerToolbarProps> = ({
  title = 'Выберите диапазон дат',
  start,
  end,
  format = 'd MMMM',
}) => {
  return (
    <PopoverToolbarStyled>
      <Typography variant="overline">{title}</Typography>
      <PopoverToolbarDatesStyled>
        <Box sx={{ display: 'flex' }}>
          <Typography component="h6" variant="h6">
            {start ? formatDate(start, format, { locale: ru }) : 'Начало'}
          </Typography>
          <Typography component="h5" variant="h5">
            &nbsp;–&nbsp;
          </Typography>
          <Typography component="h6" variant="h6">
            {end ? formatDate(end, format, { locale: ru }) : 'Конец'}
          </Typography>
        </Box>
      </PopoverToolbarDatesStyled>
    </PopoverToolbarStyled>
  );
};
