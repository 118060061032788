import { useEffect } from 'react';

import { useRecordContext } from 'react-admin';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import { Merchant, MerchantSpaces } from '../../../../types';
import { MerchantSpacesFormValues } from '../MerchantSpacesEdit';

type UseEditSitesFieldsReturnValues = {
  fields: FieldArrayWithId<MerchantSpacesFormValues, 'sites', 'id'>[];
};

export function useEditSitesFields(merchants: Merchant[]): UseEditSitesFieldsReturnValues {
  const { control, reset, watch } = useFormContext<MerchantSpacesFormValues>();
  const watchedCompanyId = watch('sourceCompany');
  const record = useRecordContext<MerchantSpaces>();

  const { fields, append } = useFieldArray<MerchantSpacesFormValues, 'sites', 'id'>({
    control,
    name: 'sites',
  });

  useEffect(() => {
    if (merchants.length <= 0) {
      return;
    }

    reset((formValues) => ({
      ...formValues,
      sites: [],
    }));

    merchants.forEach((merchant) => {
      const currentMerchant = record?.sites?.find((site) => site.sourceMerchantId === merchant.id);

      append({
        record: merchant,
        sourceMerchantId: merchant.id,
        active: currentMerchant?.active || false,
      });
    });
  }, [merchants, watchedCompanyId]);

  return { fields };
}
