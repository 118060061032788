import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Row } from 'shared/mui/Row/Row';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import {
  AutocompleteInputIntrospected,
  NumberInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Permission, Provider } from 'types';

import { ProviderWhiteIpAddressesDeleteDialog } from './components/ProviderWhiteIpAddressesDeleteDialog/ProviderWhiteIpAddressesDeleteDialog';

export const ProviderWhiteIpAddressesEdit: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { goBack } = useGoBack();
  const { record } = useEditController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError: () => undefined }}
        open={!openRecordModalDelete}
        sx={{ width: '600px' }}
        title="Редактирование белого IP"
      >
        <CardContent sx={{ width: 'calc(100% - 48px)', padding: '16px 24px 0!important' }}>
          <ReferenceInput perPage={200} reference="providers" source="provider">
            <AutocompleteInputIntrospected
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Провайдер"
              optionText="name"
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceInput>
          <Row>
            <TextInputIntrospected
              fullWidth
              label="IP"
              size="medium"
              source="ip"
              variant="outlined"
            />
            <NumberInputIntrospected
              fullWidth
              label="Маска"
              size="medium"
              source="mask"
              variant="outlined"
            />
          </Row>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('provider_white_ip_addresses', [
              Permission.Full,
              Permission.Delete,
            ]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <ProviderWhiteIpAddressesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
