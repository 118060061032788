import { AxiosError } from 'axios';
import { createMerchantPayoutControls } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { LoaderCircleMain } from 'shared/mui/LoaderCircleMain/LoaderCircleMain';
import { Merchant } from 'types';

import { CreatePayoutControl } from './components/CreatePayoutControl/CreatePayoutControl';
import { EditPayoutControl } from './components/EditPayoutControl/EditPayoutControl';
import { useMerchantPayoutControls } from './hooks/useMerchantPayoutControls';

type MerchantsShowPayoutControlsProps = {
  record?: Merchant;
};

export const MerchantsShowPayoutControls: React.FC<MerchantsShowPayoutControlsProps> = ({
  record,
}) => {
  const notify = useNotify();

  const { merchantPayoutControls, isLoadingMerchantPayoutControls, merchantPayoutControlsRefetch } =
    useMerchantPayoutControls(record?.originId);

  const { mutate, isLoading } = useMutation(createMerchantPayoutControls, {
    onSuccess: () => {
      notify('Контроль выводов успешно создан', { type: 'success' });
      merchantPayoutControlsRefetch();
    },
    onError: (error: AxiosError<{ detail: string }>) => {
      notify(error?.response?.data?.detail || 'Что-то пошло не так. Повторите запрос позже.', {
        type: 'error',
      });
    },
  });

  if (isLoadingMerchantPayoutControls || isLoading || !record) {
    return <LoaderCircleMain sx={{ position: 'absolute', top: '52%', left: '52%' }} />;
  }

  const createPayoutControl = () => mutate(record.id);

  if (merchantPayoutControls) {
    return <EditPayoutControl merchantPayoutControls={merchantPayoutControls} />;
  }

  return <CreatePayoutControl onCreate={createPayoutControl} />;
};
