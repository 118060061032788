import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GatewayRulesFormMappingItemWrapper = styled(Box)({
  display: 'grid',
  width: '100%',
  gap: '16px',
  gridTemplateColumns: '1fr 1fr 1fr 1fr auto',
  paddingBottom: '0',
});
