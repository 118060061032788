import { Fragment } from 'react';

import { fieldValidateGuesser } from '../../../../utils';
import { SelectInput } from '../SelectInput/SelectInput';

export const SelectInputWithGuesser = ({ fields, validate, ...props }: any): JSX.Element => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });
  if (isError) {
    return <Fragment />;
  }
  return <SelectInput validate={guessedValidate} {...props} />;
};
