import { getMerchantNotificationsEventNames } from 'config/requests';
import { useQuery } from 'react-query';

export const useGetMerchantNotificationsEventNames = () => {
  const { data: eventNames, isLoading } = useQuery(
    'merchant_notification_event_names',
    getMerchantNotificationsEventNames,
    {
      staleTime: Infinity,
    },
  );

  return { eventNames, isLoading };
};
