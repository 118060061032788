import { OrderStatuses } from 'constants/orderStatuses';

import { OrdersShowInfo } from './OrdersShowInfo';
import { OrdersShowStatuses } from './OrdersShowStatuses';
import { OrdersShowTransactions } from './OrdersShowTransactions';
import { OrderConfirmationPanel } from '../components/OrderConfirmation/OrderConfirmationPanel';

type Props = {
  notificationUrl?: string;
  statusAlias: string;
};

export const OrdersShowInfoLayout = ({ notificationUrl, statusAlias }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {statusAlias === OrderStatuses.WaitingConfirmation && <OrderConfirmationPanel />}
      <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
        <div
          style={{
            width: '396px',
            minWidth: '396px',
            paddingRight: '24px',
            borderRight: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <OrdersShowInfo notificationUrl={notificationUrl} />
        </div>
        <div style={{ flex: '1', minWidth: '0' }}>
          <OrdersShowTransactions />
          <OrdersShowStatuses />
        </div>
      </div>
    </div>
  );
};
