import { DEFAULT_NO_LIST_PAGINATION } from 'constants/defaultPagination';

import { useFlag } from '@unleash/proxy-client-react';
import { usePermissions } from 'hooks/usePermissions';
import { useGetList, useShowController } from 'react-admin';
import { ShowPageTabbed } from 'shared/react-admin/Pages';
import { Tab } from 'shared/react-admin/Tab/Tab';
import { ApiClient } from 'types';

import { ApiClientsShowApiKeys } from './ApiClientsShowApiKeys/ApiClientsShowApiKeys';
import { ApiClientsShowCommonInfo } from './ApiClientsShowCommonInfo/ApiClientsShowCommonInfo';
import { ApiClientsShowTopPanel } from './ApiClientsShowTopPanel/ApiClientsShowTopPanel';
import { ApiClientsShowWhiteIpAddresses } from './ApiClientsShowWhiteIpAddresses/ApiClientsShowWhiteIpAddresses';

export const ApiClientsShow = () => {
  const { hasResourceAccess } = usePermissions();
  const { record } = useShowController<ApiClient>();
  const isNewWhiteIpAddressesEnable = useFlag('wppa_6808_new_white_ip_addresses');

  const { total: totalWhiteIpAddresses } = useGetList(
    'api_client_white_ip_addresses',
    {
      filter: {
        apiClient: record?.id,
        ...DEFAULT_NO_LIST_PAGINATION,
      },
    },
    { enabled: !!record && isNewWhiteIpAddressesEnable },
  );

  return (
    <ShowPageTabbed
      headerTitle={{ titleText: record?.name }}
      listName="Клиенты Api"
      title="Управление клиентом API"
      topPanel={<ApiClientsShowTopPanel />}
    >
      <Tab label="информация">
        <ApiClientsShowCommonInfo record={record} />
      </Tab>
      {hasResourceAccess('api_client_white_ip_addresses') && isNewWhiteIpAddressesEnable && (
        <Tab label={`белые ip адреса  (${totalWhiteIpAddresses || 0})`}>
          <ApiClientsShowWhiteIpAddresses apiClientId={record?.id} />
        </Tab>
      )}
      {hasResourceAccess('api_keys') && (
        <Tab label="ключи api">
          <ApiClientsShowApiKeys record={record} />
        </Tab>
      )}
    </ShowPageTabbed>
  );
};
