import { ReportNames } from 'constants/exportFormats';

import { getReportExport } from 'config/requests';
import { useStore } from 'react-admin';
import { useMutation } from 'react-query';
import { ConfigurableExportField } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';

type UseReportExportProps = {
  filters: Record<string, any>;
  preferenceKey: ReportNames;
  onError: (error: any) => void;
  onSuccess: () => void;
};

type UseReportExportReturnValue = {
  isLoading: boolean;
  mutate: () => void;
};

export function useReportExport({
  filters,
  preferenceKey,
  onSuccess,
  onError,
}: UseReportExportProps): UseReportExportReturnValue {
  const [availableFields] = useStore<ConfigurableExportField[]>(
    `preferences.${preferenceKey}.export.availableFields`,
    [],
  );
  const [exportFields] = useStore(`preferences.${preferenceKey}.export.exportFields`, []);
  const selectedFields = exportFields.map(
    (fieldIndex) => availableFields.find((field) => field.index === fieldIndex)?.source || '',
  );

  const { mutate, isLoading } = useMutation(
    ['export', filters, selectedFields],
    () => getReportExport(filters, selectedFields),
    {
      retry: false,
      onError,
      onSuccess,
    },
  );

  return { isLoading, mutate };
}
