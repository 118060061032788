import { LocationDescriptor } from 'react-admin';

export const getLinkParams = (locationDescriptor?: LocationDescriptor | string) => {
  if (!locationDescriptor) {
    return undefined;
  }

  if (typeof locationDescriptor === 'string') {
    return { to: locationDescriptor };
  }

  const { redirect, replace, state, ...to } = locationDescriptor;
  return {
    to,
    redirect,
    replace,
    state,
  };
};
