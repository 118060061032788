import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('Обязательное поле')
      .max(255, 'Значение слишком длинное. Должно быть равно 255 символам или меньше.')
      .min(3, 'Значение слишком короткое. Должно быть равно 3 символам или больше.'),
    alias: yup
      .string()
      .required('Обязательное поле')
      .max(255, 'Значение слишком длинное. Должно быть равно 255 символам или меньше.')
      .min(3, 'Значение слишком короткое. Должно быть равно 3 символам или больше.'),
    provider: yup.string().required('Обязательное поле'),
    direction: yup.string().required('Обязательное поле'),
    status: yup.string().required('Обязательное поле'),
    defaultCurrency: yup.string().required('Обязательное поле'),
    orderExpirationTimeSec: yup
      .number()
      .required('Обязательное поле')
      .integer('Значение должно быть целым числом')
      .min(1, 'Значение не может быть меньше 1'),
    transactionRequestMaxAttempts: yup.number().integer('Значение должно быть целым числом'),
    attemptIntervalConfig: yup
      .string()
      .matches(/^\d+(, \d+)*$/, 'Введите последовательность чисел разделенных запятой и пробелом')
      .required('Обязательное поле'),
  })
  .required();

export const getGatewaysSchemaResolver = (): any => yupResolver(schema);
