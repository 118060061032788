import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import { Permission, WorkTeam } from 'types';

import { WorkTeamsDeleteDialog } from './components/WorkTeamsDeleteDialog';
import { WorkTeamsForm } from './components/WorkTeamsForm';

export const WorkTeamsEdit = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<WorkTeam>();

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError: () => undefined }}
        open={!openRecordModalDelete}
        title="Редактирование рабочей группы"
      >
        <CardContent sx={{ width: '552px' }}>
          <WorkTeamsForm />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButtonValidated key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('work_teams', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
          sx={{ paddingTop: 0 }}
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <WorkTeamsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
