import { getDateFromNumber } from './getDateFromNumber';

export const splitCreatedAtFilterValue = (
  createdAt?: string,
): { startDate: Date | string; endDate: Date | string } => {
  if (!createdAt) {
    return { startDate: '', endDate: '' };
  }
  const [startDateNum, endDateNum] = createdAt.split('..');
  const startDate = getDateFromNumber(startDateNum);
  const endDate = getDateFromNumber(endDateNum);

  return { startDate, endDate };
};
