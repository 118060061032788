import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { getMerchantSpacesRequest } from 'config/requests';
import { FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsMerchantSpaceField<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  const { data: merchantSpace } = useQuery(['merchant_spaces', chipValue?.value], () =>
    getMerchantSpacesRequest(chipValue?.value),
  );

  if (!chipValue.value || !merchantSpace) {
    return <></>;
  }

  return (
    <Chip
      deleteIcon={<CancelIcon />}
      label={`${chipValue?.label}: ${merchantSpace?.spaceName}`}
      {...restProps}
    />
  );
}
