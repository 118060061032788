import ContentCreate from '@mui/icons-material/Create';
import { useHistoryContext } from 'hooks/useHistoryContext';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';

import { IconButton, IconButtonProps } from '..';

export type EditDialogIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function EditDialogIconButton(props: EditDialogIconButtonProps): JSX.Element {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const location = useLocation();
  const { baseRoute } = useHistoryContext();

  return (
    <IconButton
      {...props}
      component={Link}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record.id)}/edit`,
        state: { backgroundLocation: baseRoute || location },
      }}
    >
      <ContentCreate fontSize="inherit" />
    </IconButton>
  );
}
