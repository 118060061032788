import { getNotificationTransports } from 'config/requests';
import { useQuery } from 'react-query';
import { NotificationTransport } from 'types';

type UseGetMerchantNotificationsReturnValue = {
  isLoading: boolean;
  notificationTransports?: NotificationTransport[];
};

export function useGetNotificationTransports(): UseGetMerchantNotificationsReturnValue {
  const { data: notificationTransports, isLoading } = useQuery(
    ['notification_transports'],
    getNotificationTransports,
    {
      staleTime: Infinity,
    },
  );

  return { notificationTransports, isLoading };
}
