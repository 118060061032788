import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import graph1 from './images/graph1.webp';
import graph2 from './images/graph2.webp';
import graph3 from './images/graph3.webp';
import graph4 from './images/graph4.webp';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const OrderConversionListGatewayReferenceDialog: React.FC<Props> = ({ open, onClose }) => (
  <Dialog onClose={onClose} open={open} transitionDuration={0}>
    <Box sx={{ width: 600 }}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title="Справка"
      />
      <CardContent
        sx={{ paddingTop: '0!important', overflow: 'auto', maxHeight: 'calc(100vh - 160px)' }}
      >
        <Typography sx={{ marginBottom: '8px' }} variant="body1">
          Как работает мониторинг конверсии заказов
        </Typography>
        <Typography variant="body2">
          Система каждые пять минут проверяет состояние заказов для активных шлюзов у мерчантов,
          используя данные за последний час. Она анализирует не только текущие заказы, но и заказы
          за предыдущие периоды, формируя на основе этих данных средний процент конверсии.
        </Typography>
        <img alt="graph1" src={graph1} style={{ margin: '16px 0', width: '100%' }} />
        <Typography variant="body2">
          Таким образом, при каждой проверке мы получаем два значения: фактическое и среднее за
          прошлые периоды. При настройке порогов указываются допустимые отклонения между
          фактическими данными и средним значением.
        </Typography>
        <Typography sx={{ margin: '16px 0' }} variant="body1">
          Как настроить пороговые значения
        </Typography>
        <Typography variant="body2">
          <b>Процент отклонения по успешным заказам:</b> Определяет, на сколько процентов
          фактическое количество успешных заказов может быть ниже среднего.
        </Typography>
        <img alt="graph2" src={graph2} style={{ margin: '16px 0', width: '100%' }} />
        <ul style={{ marginTop: 0, paddingLeft: '16px' }}>
          <li>
            <Typography variant="body2">
              <b style={{ color: '#2E7D32' }}>Положительный</b> - фактическое выше среднего
              значения;
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              <b style={{ color: '#ED6C02' }}>Допустимый</b> - фактическое ниже среднего значения,
              но в рамках допустимых отклонений;
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              <b style={{ color: '#D32F2F' }}>Отклонение</b> - фактическое ниже среднего и
              отклонение больше допустимого значения.
            </Typography>
          </li>
        </ul>
        <Typography variant="body2">
          <b>Процент отклонения по отмененным заказам:</b> Определяет, на сколько процентов
          фактическое количество отмененных заказов может быть выше среднего.
        </Typography>
        <img alt="graph3" src={graph3} style={{ margin: '16px 0', width: '100%' }} />
        <Typography variant="body2">
          <b>Процент отклонения по заказам в очереди:</b> Определяет, на сколько процентов
          фактическое количество заказов в промежуточных статусах может превышать среднее значение.
        </Typography>
        <img alt="graph4" src={graph4} style={{ margin: '16px 0', width: '100%' }} />
        <Typography variant="body2">
          <b>Важно учитывать,</b> что настройка порогов может быть очень чувствительной для шлюзов с
          малым количеством заказов и менее критичной для шлюзов с большим количеством. Это важно
          учитывать при настройках, так как высокие или низкие значения, например 10% или 90%, могут
          привести к большому числу уведомлений.
        </Typography>
        <Button
          label="Вернуться к настройке пороговых значений"
          onClick={onClose}
          sx={{ margin: '24px 0 8px' }}
          variant="outlined"
        />
      </CardContent>
    </Box>
  </Dialog>
);
