import { OrdersReportFilterFormValues } from 'components/Finance/OrdersReport/OrdersReportFilter';

// import { OrdersReportListShortReportAlertLinkIcon } from './OrdersReportListShortReportAlertLinkIcon';
import { OrdersReportListShortReportAlertTypography } from './OrdersReportListShortReportAlertTypography';

type Props = {
  filterValues: OrdersReportFilterFormValues;
  key?: string;
  link: string;
  name: string;
  status: string;
  value: number;
};

// ToDo когда будет выполнена задача по реазизации фильтров в query в заказах - убрать комментарии и появятся кнопки со ссылкой
export const OrdersReportListShortReportAlertLi: React.FC<Props> = ({
  // filterValues,
  name,
  // status,
  value,
}) => {
  return (
    <li style={{ marginTop: '4px' }}>
      {name}: <OrdersReportListShortReportAlertTypography label={value} />
      {/* <OrdersReportListShortReportAlertLinkIcon filterValues={filterValues} status={status} /> */}
    </li>
  );
};
