import { useGetList, useRecordContext } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { MappingRecord, Merchant } from 'types';

import { DialogStyled } from './EditPayoutControlMappingAddDialog.styled';
import { EditPayoutControlMappingAddDialogAlert } from './EditPayoutControlMappingAddDialogAlert/EditPayoutControlMappingAddDialogAlert';
import { EditPayoutControlMappingField } from '../EditPayoutControlMappingField/EditPayoutControlMappingField';

type MappingExceptionsAddDialogProps = {
  onOpenConfirmDialog: () => void;
} & DialogProps;

export const EditPayoutControlMappingAddDialog: React.FC<MappingExceptionsAddDialogProps> = ({
  open,
  onClose,
  onOpenConfirmDialog,
}) => {
  const record = useRecordContext<Merchant>();

  const handleChoice = () => {
    onClose();
    onOpenConfirmDialog();
  };

  const { data: mappings, isLoading: isLoadingMappings } = useGetList<MappingRecord>('mappings', {
    filter: {
      active: true,
      direction: 'payout',
      'gatewayRule.active': true,
      'gatewayRule.merchant': record.id,
    },
  });
  const isEmptyMappingList = !mappings?.length;

  const renderMappings = () =>
    isEmptyMappingList ? (
      <EditPayoutControlMappingAddDialogAlert />
    ) : (
      mappings.map((mapping, index) => (
        <EditPayoutControlMappingField key={index} mapping={mapping} onChoice={handleChoice} />
      ))
    );

  return (
    <DialogStyled isEmpty={isEmptyMappingList} maxWidth="lg" onClose={onClose} open={open}>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}
        title="Добавление маппинга в исключение"
      />
      <CardContent>{isLoadingMappings ? <LinearProgress /> : renderMappings()}</CardContent>
    </DialogStyled>
  );
};
