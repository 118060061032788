import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle160 = getColumnStyle(160);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-id': getColumnStyle(),
  '& .column-id:nth-of-type(2)': getColumnStyle(140),
  '& .column-gateway': getColumnStyle(200),
  '& th:nth-of-type(5), td:nth-of-type(5)': getColumnStyle(100),
  '& th:nth-of-type(7), td:nth-of-type(7)': {
    ...columnStyle160,
    '& .MuiTypography-root': columnStyle160,
  },
  '& td:nth-of-type(8)': {
    '& .MuiTypography-root': {
      gap: '12px',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  '& .column-active': getColumnStyle(),
  '& .column-undefined': {
    verticalAlign: 'top',
  },
});
