import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { ShowPage } from 'shared/react-admin/Pages';
import { Method, Permission } from 'types';

import { MethodsShowTable } from './MethodsShowTable';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { MethodsDeleteDialog } from '../components/MethodsDeleteDialog/MethodsDeleteDialog';

export const MethodsShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useEditController();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Method>();

  return (
    <ShowPage
      actions={
        hasResourceAccess('user_actions')
          ? [<UserActionsIconButton entityName="method" key="user-action-link" />]
          : undefined
      }
      leftActionsSlot={[
        hasResourcePermissions('methods', [Permission.Full, Permission.Update]) && (
          <EditLinkButton key="edit-button" label="Редактировать" />
        ),
      ]}
      listName="Методы"
      rightActionsSlot={[
        hasResourcePermissions('methods', [Permission.Full, Permission.Delete]) && (
          <DeleteButton
            key="delete-button"
            onClick={() => handleOpenRecordModal()}
            variant="outlined"
          />
        ),
      ]}
      title="Просмотр метода"
    >
      <MethodsShowTable />
      {openRecordModal && (
        <MethodsDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </ShowPage>
  );
};
