import { TabbedShowLayout, TabbedShowLayoutProps } from 'react-admin';
import { appColors } from 'themes/variables';

export function OrdersTabbedShowLayout({
  children,
  ...restProps
}: TabbedShowLayoutProps): JSX.Element {
  return (
    <TabbedShowLayout
      sx={{
        '& .RaTabbedShowLayout-content': {
          backgroundColor: appColors.background,
          padding: '24px',
          marginBottom: '24px',
          border: '1px solid rgba(0,0,0,0.12)',
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
        },
        '& .MuiDivider-root': {
          display: 'none',
        },
      }}
      {...restProps}
    >
      {children}
    </TabbedShowLayout>
  );
}
