import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';

import { IconButton, IconButtonProps } from '..';

export type CloseIconButtonProps = Omit<IconButtonProps, 'icon'> & {
  onClose?: () => void;
};

export function CloseIconButton({ onClose, ...rest }: CloseIconButtonProps): JSX.Element {
  const { toRoot } = useNavigateToRoot();
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (onClose) {
        onClose();

        return;
      }

      toRoot();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <IconButton aria-label="close" onClick={onClose || toRoot} size="small" {...rest}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
