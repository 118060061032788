import React, { useMemo, useState } from 'react';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useCreateMerchantSpaceUsersInvitation } from 'components/MerchantSpacesUsers/hooks/useCreateMerchantSpaceUsersInvitation';
import { useListContext, useResourceContext, useUnselectAll } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { MerchantSpacesUsersInviteDialog } from 'shared/widgets/MerchantSpacesUsersDatagrid/components/MerchantSpacesUsersDialogs/MerchantSpacesUsersInviteDialog';
import { MerchantSpacesUsersInviteListDialog } from 'shared/widgets/MerchantSpacesUsersDatagrid/components/MerchantSpacesUsersDialogs/MerchantSpacesUsersInviteListDialog';
import { getId } from 'utils';

type MerchantSpacesUsersInvitationButtonProps = {
  filterValues?: FieldValues;
  resource?: string;
  selectedIds?: string[];
};

export const MerchantSpacesUsersInvitationButton: React.FC<
  MerchantSpacesUsersInvitationButtonProps
> = ({ selectedIds }) => {
  const { data: merchantSpacesUsers } = useListContext();
  const [isOpenInviteDialog, setIsOpenInviteDialog] = useState(false);
  const [isOpenListDialog, setIsOpenListDialog] = useState(false);
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);

  const toggleInviteDialog = (): void => {
    setIsOpenInviteDialog((isOpen) => !isOpen);
  };

  const toggleListDialog = (): void => {
    setIsOpenListDialog((isOpen) => !isOpen);
  };

  const { createMerchantSpaceUsersInvitation } = useCreateMerchantSpaceUsersInvitation(() => {
    toggleInviteDialog();
    toggleListDialog();
  });

  const selectedUsers = useMemo(() => {
    return merchantSpacesUsers.filter((user) => selectedIds?.includes(user.id));
  }, [selectedIds, merchantSpacesUsers]);

  const onSubmit = (): void => {
    createMerchantSpaceUsersInvitation.mutate({
      users: selectedIds?.map((id) => Number(getId(id))),
    });
  };

  return (
    <>
      <Button label="Пригласить" onClick={toggleInviteDialog} startIcon={<EmailOutlinedIcon />} />
      <MerchantSpacesUsersInviteDialog
        onClose={toggleInviteDialog}
        onSubmit={onSubmit}
        open={isOpenInviteDialog}
        users={selectedUsers}
      />
      <MerchantSpacesUsersInviteListDialog
        onClose={() => {
          toggleListDialog();
          unselectAll();
        }}
        open={isOpenListDialog}
        users={selectedUsers}
      />
    </>
  );
};
