import { ReactElement } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { RecordContextProvider } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { FunctionField, OrderStatusChipField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { OrderAdjustments } from '../../../types/orderAdjustments';

type Props = {
  label?: string | ReactElement | boolean;
  source?: string;
};

export const OrderAdjustmentsReportChangeStatusField: React.FC<Props> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      render={(record: OrderAdjustments) => {
        return (
          <RecordContextProvider value={record}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <ReferenceField reference="new/order_statuses" source="oldStatus">
                <OrderStatusChipField source="description" />
              </ReferenceField>
              <ReferenceField reference="new/order_statuses" source="newStatus">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightIcon fontSize="small" />
                  <OrderStatusChipField source="description" />
                </Box>
              </ReferenceField>
            </Box>
          </RecordContextProvider>
        );
      }}
      source={source}
    />
  );
};
