import React from 'react';

import { queryClient } from 'App';
import { useNotify, useResourceContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CreateGuesser, CreateGuesserProps } from 'shared/react-admin/Guessers';

import { CreateDialogStyled } from './CreateGuesserDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';

export type CreateGuesserDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  title: string;
} & Omit<CreateGuesserProps, 'actions' | 'toolbar'>;

export const CreateGuesserDialog: React.FC<CreateGuesserDialogProps> = ({
  actions,
  title,
  children,
  mutationOptions,
  ...restProps
}) => {
  const { goBack } = useGoBack();
  const notify = useNotify();
  const resource = useResourceContext();
  const onSuccess = async () => {
    await queryClient.invalidateQueries([resource]);
    notify('ra.notification.created', {
      type: 'info',
      messageArgs: { smart_count: 1 },
    });
    goBack();
  };

  return (
    <CreateDialogStyled open={true}>
      <CreateGuesser
        actions={false}
        mutationOptions={{ onSuccess, ...mutationOptions }}
        toolbar={false}
        {...restProps}
      >
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </CreateGuesser>
    </CreateDialogStyled>
  );
};
