import { styled } from '@mui/material/styles';
import { Chip } from 'shared/mui/Chips';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { ChipField } from 'shared/react-admin/Fields';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(179);
const columnStyle160 = getColumnStyle(160);
const columnStyle120 = getColumnStyle(120);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': columnStyle,
  '& .column-alias': columnStyle,
  '& .column-provider': getColumnStyle(140),
  '& .column-direction': columnStyle160,
  '& .column-gatewayMethods': columnStyle,
  '& .column-defaultCurrency': columnStyle160,
  '& .column-supportedCurrencies': columnStyle160,
  '& .column-createdAt': columnStyle120,
  '& .column-updatedAt': columnStyle120,
  'th:nth-last-child(4)': columnStyle160,
});

export const StyledChip = styled(Chip)({
  '&:not(:first-child)': {
    marginTop: '12px',
  },
});

export const StyledChipField = styled(ChipField)({
  '&:not(:first-child)': {
    marginTop: '12px',
  },
  width: 'fit-content',
});
