import { useEffect } from 'react';

import { useFirstRender } from 'hooks/useFirstRender';
import { useFormContext, useWatch } from 'react-hook-form';

import { StyledTextInputIntrospected } from './WatchedTextInput.styled';
import type { TextInputProps } from '../TextInput/TextInput';

export type WatchedTextInputProps = {
  watchField: string;
} & TextInputProps;

export const WatchedTextInput = ({ watchField, ...props }: WatchedTextInputProps): JSX.Element => {
  const { clearErrors, setValue } = useFormContext();
  const watchedValue = useWatch({ name: watchField });
  const { isFirstRender } = useFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      setValue(props.source, null);
      clearErrors(props.source);
    }
  }, [watchedValue]);

  return <StyledTextInputIntrospected InputLabelProps={{ shrink: true }} {...props} />;
};
