import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { FilterChipType } from 'constants/filterChipType';

import { useFilterChips } from 'hooks/useFilterChips';
import { useFilters } from 'hooks/useFilters';
import { RaRecord, useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

type GatewayRulesFilterValues = {
  active: boolean | null;
  name: string;
  id: string;
  merchant: string;
  country: string[];
  currency: string[];
  conditionGroup: {
    expressions: {
      clientId: string;
    };
  };
  mappings: {
    mappingItems: {
      gateway: string;
    };
    method: string;
  };
};

type GatewayRulesFiltersProps = {
  resetExpandedRules: () => void;
};

export const GatewayRulesFilters: React.FC<GatewayRulesFiltersProps> = ({ resetExpandedRules }) => {
  const { filterValues } = useListContext();
  const { form, resetFilter, onSubmit, resetFilterArrayById, resetFilterBySource } =
    useFilters<GatewayRulesFilterValues>({
      active: null,
      name: '',
      id: '',
      merchant: '',
      country: [],
      currency: [],
      conditionGroup: {
        expressions: {
          clientId: '',
        },
      },
      mappings: {
        mappingItems: {
          gateway: '',
        },
        method: '',
      },
    });

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'active', label: 'Активен', type: FilterChipType.Boolean },
      { source: 'name', label: 'Название', type: FilterChipType.String },
      { source: 'id', label: 'ID', type: FilterChipType.String },
      { source: 'mappings.mappingItems.gateway', label: 'Шлюз', type: FilterChipType.Id },
      { source: 'merchant', label: 'Мерчант', type: FilterChipType.Id },
      { source: 'mappings.method', label: 'Метод', type: FilterChipType.Id },
      { source: 'currency', label: 'Валюта', type: FilterChipType.IdArray },
      { source: 'country', label: 'Страна', type: FilterChipType.IdArray },
      {
        source: 'conditionGroup.expressions.clientId',
        label: 'Идентификатор пользователя',
        type: FilterChipType.String,
      },
    ],
    appliedFilters: filterValues,
  });

  const handleSubmit = (values: GatewayRulesFilterValues) => {
    onSubmit(values);
    resetExpandedRules();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilter} />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById as any}
          resetFilterBySource={resetFilterBySource as any}
        >
          <FilterRow columnsCount={5} sx={{ mb: 2 }}>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              resettable
              source="name"
              sx={{ minWidth: 0 }}
              variant="outlined"
            />
            <TextInputShrink
              alwaysOn
              helperText={false}
              key="id"
              label="ID"
              resettable
              source="id"
              sx={{ minWidth: 0 }}
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              helperText={false}
              label="Активен"
              name="active"
              source="active"
              sx={{ minWidth: 0 }}
              variant="outlined"
            />
            <ReferenceInput name="merchant" perPage={100} reference="merchants" source="merchant">
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчант"
                optionText="name"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              name="method"
              perPage={100}
              reference="methods"
              source="mappings.method"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Метод"
                optionText="name"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
            </ReferenceInput>
          </FilterRow>
          <FilterRow>
            <ReferenceInput
              key="gateway"
              perPage={100}
              reference="gateways"
              source="mappings.mappingItems.gateway"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                optionText="name"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
            </ReferenceInput>
            <TextInputShrink
              helperText={false}
              label="Идентификатор клиента"
              name="conditionGroup.expressions.clientId"
              resettable
              source="conditionGroup.expressions.clientId"
              sx={{ minWidth: 0 }}
              variant="outlined"
            />
            <ReferenceArrayInput
              isFilter={true}
              name="country"
              perPage={100}
              reference="countries"
              source="country"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Страна"
                name="country"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              filter={{ active: true }}
              isFilter={true}
              name="currency"
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                name="currency"
                optionText="name"
                renderChipLabel={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
                size="small"
              />
            </ReferenceArrayInput>
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
