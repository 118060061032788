import { EMPTY_FIELD } from 'constants/emptyField';

import React from 'react';

import { VpnKey } from '@mui/icons-material';
import { useRecordModal } from 'hooks/useRecordModal';
import { Button } from 'shared/mui/Buttons';
import { FunctionField } from 'shared/react-admin/Fields';
import { ApiClient } from 'types';

import { ApiClientsListApiKeysFieldShowDialog } from './ApiClientsListApiKeysFieldShowDialog';

type Props = {
  label?: string | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const ApiClientsListApiKeysField: React.FC<Props> = ({ label }) => {
  const {
    record: recordApiKey,
    handleOpenRecordModal: handleOpenRecordModalApiKey,
    openRecordModal: openRecordModalApiKey,
    handleCloseRecordModal: handleCloseRecordModalApiKey,
  } = useRecordModal<ApiClient>();

  return (
    <>
      <FunctionField
        label={label}
        render={(record: ApiClient) => {
          return record.apiKeys?.length ? (
            <Button
              label="Показать"
              onClick={() => handleOpenRecordModalApiKey(record)}
              startIcon={<VpnKey />}
              sx={{ top: '-8px' }}
            />
          ) : (
            EMPTY_FIELD
          );
        }}
        source="apiKeys"
      />
      {openRecordModalApiKey && (
        <ApiClientsListApiKeysFieldShowDialog
          onClose={handleCloseRecordModalApiKey}
          open={openRecordModalApiKey}
          record={recordApiKey}
        />
      )}
    </>
  );
};
