import { getSecondsFromMilliseconds } from './getSecondsFromMilliseconds';

export const transformDateFilterValue = (startDate: Date | null, endDate: Date | null): string => {
  if (startDate && endDate) {
    return getSecondsFromMilliseconds(startDate) + '..' + getSecondsFromMilliseconds(endDate);
  } else if (startDate) {
    return getSecondsFromMilliseconds(startDate) + '..' + getSecondsFromMilliseconds(new Date());
  } else if (endDate) {
    return 0 + '..' + getSecondsFromMilliseconds(endDate);
  } else {
    return '';
  }
};
