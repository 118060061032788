import { styled } from '@mui/material';

export const OrderRequestLogsExpandTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  '& td': {
    width: '50%',
  },
  '& tr:first-child': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  },
  '& td:first-child': {
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
  },
});
