import { LocalStorageCachedKeys } from 'constants/localStorageStoreKeys';

import { Store } from 'react-admin';

import { LocalStorageShim, memoryStorage } from './localStorageShim';
import { testLocalStorage, tryParse } from './localStorageStoreUtils';

// this file provides configurations for react-admin localStorageStore (import { localStorageStore } from 'react-admin';)

type Subscription = {
  key: string;
  callback: (value: any) => void;
};

const RA_STORE = 'RaStore';

const localStorageAvailable = testLocalStorage();

type LocalStorageStoreOptions = {
  version: string;
  appKey: string;
  disabledCacheKeys?: LocalStorageCachedKeys[];
};

/**
 * Store using localStorage, or memory storage in incognito mode
 *
 * @example
 *
 * import { localStorageStore } from 'react-admin';
 *
 * const App = () => (
 *    <Admin store={localStorageStore()}>
 *       ...
 *   </Admin>
 * );
 */
export const localStorageStore = ({
  version,
  appKey,
  disabledCacheKeys, // localStorageStore state keys which will provide caching only while reloading page
}: LocalStorageStoreOptions): Store => {
  const prefix = `${RA_STORE}${appKey}`;
  const prefixLength = prefix.length;
  const subscriptions: { [key: string]: Subscription } = {};
  // map from disabledCacheKeys
  const disabledCacheKeysMap: Record<string, boolean> | undefined = disabledCacheKeys?.reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {},
  );
  const publish = (key: string, value: any) => {
    Object.keys(subscriptions).forEach((id) => {
      if (!subscriptions[id]) {
        return;
      } // may happen if a component unmounts after a first subscriber was notified
      if (subscriptions[id].key === key) {
        subscriptions[id].callback(value);
      }
    });
  };

  // Whenever the local storage changes in another document, look for matching subscribers.
  // This allows to synchronize state across tabs
  const onLocalStorageChange = (event: StorageEvent): void => {
    if (event.key?.substring(0, prefixLength) !== prefix) {
      return;
    }
    const key = event.key.substring(prefixLength + 1);
    const value = event.newValue ? tryParse(event.newValue) : undefined;
    Object.keys(subscriptions).forEach((id) => {
      if (!subscriptions[id]) {
        return;
      } // may happen if a component unmounts after a first subscriber was notified
      if (subscriptions[id].key === key) {
        if (value === null) {
          // an event with a null value is sent when the key is deleted.
          // to enable default value, we need to call setValue(undefined) instead of setValue(null)
          subscriptions[id].callback(undefined);
        } else {
          subscriptions[id].callback(value == null ? undefined : value);
        }
      }
    });
  };

  return {
    setup: () => {
      if (localStorageAvailable) {
        const storedVersion = getStorage().getItem(`${prefix}.version`);
        if (storedVersion && storedVersion !== version) {
          const storage = getStorage();
          Object.keys(storage).forEach((key) => {
            if (key.startsWith(prefix)) {
              storage.removeItem(key);
            }
          });
        }
        getStorage().setItem(`${prefix}.version`, version);
        window.addEventListener('storage', onLocalStorageChange);
      }
    },
    teardown: () => {
      if (localStorageAvailable) {
        window.removeEventListener('storage', onLocalStorageChange);
      }
    },
    getItem<T = any>(key: string, defaultValue?: T): T {
      // added functionality for optional caching for certain keys only while reloading page
      const stateKey = key.split('.').at(-1);
      const stateKeyDisabled =
        disabledCacheKeysMap?.[stateKey as keyof typeof disabledCacheKeysMap];
      if (stateKeyDisabled) {
        return {} as T;
      }
      if (disabledCacheKeysMap && stateKeyDisabled === false) {
        disabledCacheKeysMap[stateKey as keyof typeof disabledCacheKeysMap] = true;
      }

      const valueFromStorage = getStorage().getItem(`${prefix}.${key}`);

      return valueFromStorage == null ? defaultValue : tryParse(valueFromStorage);
    },
    setItem<T = any>(key: string, value: T): void {
      if (value === undefined) {
        getStorage().removeItem(`${prefix}.${key}`);
      } else {
        getStorage().setItem(`${prefix}.${key}`, JSON.stringify(value));
      }
      publish(key, value);
    },
    removeItem(key: string): void {
      getStorage().removeItem(`${prefix}.${key}`);
      publish(key, undefined);
    },
    removeItems(keyPrefix: string): void {
      const storage = getStorage();
      Object.keys(storage).forEach((key) => {
        if (key.startsWith(`${prefix}.${keyPrefix}`)) {
          storage.removeItem(key);
          const publishKey = key.substring(prefixLength + 1);
          publish(publishKey, undefined);
        }
      });
    },
    reset(): void {
      const storage = getStorage();
      Object.keys(storage).forEach((key) => {
        if (key.startsWith(prefix)) {
          storage.removeItem(key);
          const publishKey = key.substring(prefixLength + 1);
          publish(publishKey, undefined);
        }
      });
    },
    subscribe: (key: string, callback: (value: string) => void) => {
      const id = Math.random().toString();
      subscriptions[id] = {
        key,
        callback,
      };
      return () => {
        delete subscriptions[id];
      };
    },
  };
};

export const getStorage = (): Storage | LocalStorageShim => {
  return localStorageAvailable ? window.localStorage : memoryStorage;
};
