import ContentCreate from '@mui/icons-material/Create';

import { IconButton, IconButtonProps } from '..';

export type EditIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function EditIconButton(props: EditIconButtonProps) {
  return (
    <IconButton {...props}>
      <ContentCreate fontSize="inherit" />
    </IconButton>
  );
}
