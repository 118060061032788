import { UNLIMITED_PAGINATION_PARAMS } from 'constants/unlimitedPaginationParams';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider, useGetList, useGetOne, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Card } from 'shared/mui/Card';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { Divider } from 'shared/mui/Divider/Divider';
import {
  DeleteIconButton,
  EditLinkIconButton,
  IconButton,
  ShowLinkIconButton,
} from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Typography } from 'shared/mui/Typography';
import { TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { GatewayRuleActiveSwitch } from 'shared/widgets/GatewayRuleActiveSwitch/GatewayRuleActiveSwitch';
import { GatewayRuleRecord, MappingRecord, Permission } from 'types';

import { GatewayRuleCardCollapseContent, GatewayRuleMapping } from '.';
import { GatewayRuleField } from './GatewayRuleField';
import { GatewayRuleInfo } from './GatewayRuleInfo';
import { GatewayRulePercentOfTrafficField } from './GatewayRulePercentOfTrafficField';
import { GatewayRuleRemoveButton } from './GatewayRuleRemoveButton';
import { useExpandedCard, useGatewayRuleCardStyles } from '../hooks';

export function GatewayRuleCard() {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { gatewayRuleCardSx } = useGatewayRuleCardStyles();

  const record = useRecordContext<GatewayRuleRecord>();
  const { data } = useGetOne('gateway_rules', { id: record.id });
  const { isExpandedCard, toggleExpanded } = useExpandedCard(record.id);

  const { data: mappings = [], isLoading: isLoadingMappings } = useGetList<MappingRecord>(
    'mappings',
    {
      pagination: UNLIMITED_PAGINATION_PARAMS,
      filter: { gatewayRule: record.id },
    },
    {
      enabled: isExpandedCard,
    },
  );

  function renderMappingFields(): JSX.Element | JSX.Element[] {
    if (isLoadingMappings) {
      return <LinearProgress />;
    }

    return mappings.map((mapping, index) => {
      return (
        <RecordContextProvider key={index} value={mapping}>
          <GatewayRuleMapping key={mapping.id}>
            {hasResourceAccess('gateways') && (
              <ReferenceOneField
                emptyText="—"
                label="Шлюз"
                link="show"
                reference="gateways"
                source="gateway"
                target="id"
              >
                <TextField source="name" />
              </ReferenceOneField>
            )}
            <GatewayRulePercentOfTrafficField label="Процент трафика" mappingRecord={mapping} />
          </GatewayRuleMapping>
        </RecordContextProvider>
      );
    });
  }

  function renderArrow(): JSX.Element {
    return (
      <IconButton onClick={toggleExpanded} sx={gatewayRuleCardSx.button}>
        {isExpandedCard ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
      </IconButton>
    );
  }

  return (
    <Card
      sx={{
        marginBottom: '8px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          padding: '16px',
        }}
      >
        {hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Update]) && (
          <GatewayRuleActiveSwitch />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <GatewayRuleInfo />
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <ActionsSlots
              render={() => (
                <>
                  {hasResourceAccess('user_actions') && (
                    <UserActionsIconButton entityName="gateway_rule" />
                  )}
                  <ShowLinkIconButton />
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Update,
                  ]) && (
                    <>
                      <EditLinkIconButton />
                      <GatewayRuleRemoveButton
                        id={data?.id || ''}
                        merchantId={data?.merchant || ''}
                        originId={data?.originId || ''}
                        render={({ onClick }) => {
                          return <DeleteIconButton onClick={onClick} />;
                        }}
                      />
                    </>
                  )}
                </>
              )}
            />
            <Divider flexItem orientation="vertical" sx={{ marginX: 2 }} />
            {renderArrow()}
          </Box>
        </Box>
      </Box>
      <Collapse in={isExpandedCard} timeout="auto" unmountOnExit>
        <GatewayRuleCardCollapseContent>
          <Box
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.08)',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <Box>
              <Typography component="h2" mb={2} variant="h6">
                Условие
              </Typography>
              <GatewayRuleField label="Правила" sortable={false} source="conditionGroup" />
            </Box>
          </Box>
          <Box sx={{ boxSizing: 'border-box', padding: '16px' }}>
            <Box>
              <Typography component="h2" mb={2} variant="h6">
                Маппинги
              </Typography>
              {renderMappingFields()}
            </Box>
          </Box>
        </GatewayRuleCardCollapseContent>
      </Collapse>
    </Card>
  );
}
