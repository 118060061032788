import React from 'react';

import { TextInputProps, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/TextField/TextField';
import { TextInput } from 'shared/react-admin/Inputs/TextInputs';

type Props = { notFoundIds: string[] | undefined } & TextInputProps;

export const IdDialogInput: React.FC<Props> = ({ notFoundIds, ...props }) => {
  const { field } = useInput(props);
  const { setValue } = useFormContext();

  if (Array.isArray(field.value) && field.value?.length) {
    return (
      <Autocomplete
        disableClearable={true}
        disabled={true}
        freeSolo
        multiple
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            InputProps={{ ...params.InputProps, ...props.InputProps }}
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '8px 16px',
                alignItems: 'start',
              },
            }}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((id, index: number) => (
            <Chip
              color={notFoundIds?.includes(id) ? 'warning' : 'default'}
              label={id}
              {...getTagProps({ index })}
              key={index}
              onDelete={() =>
                setValue(
                  field.name,
                  field.value.filter((value: string) => value !== id),
                )
              }
              size="small"
              sx={{
                '&.Mui-disabled': {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              }}
            />
          ))
        }
        sx={props.sx}
        value={field.value}
      />
    );
  }
  return <TextInput {...props}></TextInput>;
};
