import { getOrderConversion, OrderConversionRequestParams } from 'config/requests';
import { useQuery } from 'react-query';

export function useOrderConversion(
  params: OrderConversionRequestParams,
  refetchInterval?: number | false,
) {
  return useQuery(
    [
      'monitoring/order_conversion',
      params.period,
      params.merchantId,
      params?.detailed,
      params?.providerIds,
      params?.gatewayIds,
    ],
    () => getOrderConversion(params),
    {
      enabled: Boolean(params?.merchantId),
      retry: false,
      refetchInterval,
    },
  );
}
