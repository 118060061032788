import { useState } from 'react';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { RaRecord, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';

import { EMPTY_FIELD } from '../../../../constants';
import { IconButton } from '../../../mui/IconButtons';
import { Tooltip } from '../../../mui/Tooltip';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type CopyToClipboardFieldProps = {
  source: string;
  value?: string;
} & Omit<FunctionFieldProps, 'render'>;

export const CopyToClipboardField: React.FC<CopyToClipboardFieldProps> = ({
  onClick,
  source,
  sx = { textDecoration: 'underline' },
  value,

  ...rest
}) => {
  const record = useRecordContext();
  const [tooltipText, setTooltipText] = useState('');

  if (!(source && record[source])) {
    return <span>{EMPTY_FIELD}</span>;
  }

  const recordValue = record[source as string];
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(recordValue);
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 1000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  return (
    <FunctionField
      render={(record: RaRecord) => {
        return (
          <Box
            onClick={onClick}
            sx={{ position: 'relative', gap: '4px', display: 'flex', alignItems: 'center' }}
          >
            <Typography sx={sx} variant="body2">
              {value || record[source]}
            </Typography>
            <Tooltip TransitionProps={{ timeout: 0 }} open={!!tooltipText} title={tooltipText}>
              <IconButton onClick={handleCopy} size="small">
                <CopyAllIcon sx={{ fontSize: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }}
      {...rest}
    />
  );
};
