import { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { ComparisonOperator } from 'types';

import { StyledExpressionFormControl } from './GatewayRulesFormRuleExpression.styled';

type Props = {
  name: string;
  comparisonOperators: ComparisonOperator[];
  expressionParamName?: string;
};

export function GatewayRulesFormRuleExpressionOperator({
  name,
  comparisonOperators,
  expressionParamName,
}: Props): JSX.Element {
  const { control, setValue, getValues } = useFormContext();
  const renderValue = (currentValue: string | ComparisonOperator) => {
    if (typeof currentValue === 'string') {
      const regex = /[^/]+$/;
      const match = currentValue.match(regex);
      return comparisonOperators.find((operator) => `${operator.id}` === match?.[0])?.description;
    } else {
      return comparisonOperators.find((operator) => `${operator.id}` === currentValue.id)
        ?.description;
    }
  };

  useEffect(() => {
    const currentValue = getValues(name);
    if (typeof currentValue !== 'string') {
      setValue(name, `/admin/comparison_operators/${currentValue?.id}`);
    }
  }, [expressionParamName]); // Согласно контракту приводим значение к строке при обновлении условия

  return (
    <StyledExpressionFormControl>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            variant="outlined"
            {...field}
            onChange={(event) => {
              const { name, value } = event.target;

              if (typeof value !== 'string') {
                setValue(name, `/admin/comparison_operators/${value.id}`);
                field.onChange(`/admin/comparison_operators/${value.id}`);
              } else {
                setValue(name, value);
                field.onChange(value);
              }
            }}
            renderValue={renderValue}
            value={field.value}
          >
            {comparisonOperators.map((operator) => {
              return (
                <MenuItem key={operator.id} value={operator as any}>
                  {operator?.description}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </StyledExpressionFormControl>
  );
}
