import { MappingFormValues } from 'components/GatewayRules/components/GatewayRulesForm/hooks/useGatewayRulesForm';
import { getInitialMappingItem } from 'components/GatewayRules/components/GatewayRulesForm/utils/getInitialMappingItem';

export function getInitialMapping(): Nullable<MappingFormValues> {
  return {
    active: false,
    direction: null,
    method: null,
    mappingItems: [getInitialMappingItem()],
  } as any;
}
