import { styled } from '@mui/material';

import { Box } from '../../Box';

export const WrapperStyled = styled(Box)({
  width: '100%',
  padding: '12px 0px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const ChipsStyled = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px',
});
