import React, { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import { useResourceContext } from 'react-admin';

import { fieldValidateGuesser } from '../../../../utils';
import { AutocompleteInput, AutocompleteInputProps } from '../AutocompleteInput/AutocompleteInput';

export type AutocompleteInputIntrospectedProps = AutocompleteInputProps;

const AppAutocompleteInput = ({ fields, validate, ...props }: any) => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });

  if (isError) {
    return <Fragment />;
  }

  return <AutocompleteInput validate={guessedValidate} {...props} />;
};

export const AutocompleteInputIntrospected = ({
  disabled,
  ...restProps
}: AutocompleteInputIntrospectedProps): JSX.Element => {
  const resource = useResourceContext(restProps);

  return (
    <Introspecter
      component={AppAutocompleteInput}
      disabled={disabled}
      includeDeprecated={disabled}
      resource={resource}
      {...restProps}
    />
  );
};
