import * as React from 'react';

import { RecordContextProvider, useNotify } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { SettingsMerchantAvailabilityDialogForm } from './SettingsMerchantAvailabilityDialogForm';
import {
  CreateMerchantsSpaceUsersSitesParams,
  createMerchantsSpaceUsersSitesRequest,
} from '../../../config/requests';
import { useMerchantSpacesUsers } from '../../../hooks/queries/useMerchantSpacesUsers';
import { MerchantSpacesUsers, MerchantSpacesUsersSite } from '../../../types';
import { CancelButton } from '../../mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from '../../mui/Card';
import { CardContent } from '../../mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from '../../mui/Dialogs';
import { CloseIconButton } from '../../mui/IconButtons';
import { LinearProgress } from '../../mui/LinearProgress/LinearProgress';
import { SaveButton } from '../../react-admin/Buttons';

export type SettingsMerchantAvailabilityFormValues = {
  sites?: MerchantSpacesUsersSite[];
};

type Props = DialogProps & {
  record: MerchantSpacesUsers | undefined;
};

export const SettingsMerchantAvailabilityDialog: React.FC<Props> = (props) => {
  const notify = useNotify();

  const { merchantSpacesUsers, isLoadingMerchantSpacesUsers } = useMerchantSpacesUsers(
    props.record?.id,
  );

  const form = useForm<SettingsMerchantAvailabilityFormValues>({
    shouldUnregister: true,
  });

  const { mutate: createMerchantsSpaceUsersSites } = useMutation(
    ({ id, data }: { id: string; data: CreateMerchantsSpaceUsersSitesParams }) =>
      createMerchantsSpaceUsersSitesRequest(id, data),
  );

  const onSubmit = (values: SettingsMerchantAvailabilityFormValues) => {
    const sites = values.sites?.filter((site) => site.active)?.map((site) => site.id) || [];

    createMerchantsSpaceUsersSites(
      {
        id: props.record?.id as string,
        data: {
          sites,
        },
      },
      {
        onSuccess: () => {
          notify('Настройка доступности мерчантов успешно отредактирована!', { type: 'success' });
          props.onClose();
        },
      },
    );
  };

  if (isLoadingMerchantSpacesUsers) {
    return <LinearProgress />;
  }

  return (
    <Dialog fullWidth {...props}>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={props.onClose} />]}
          title="Настройка доступности мерчантов"
        />
        <RecordContextProvider value={merchantSpacesUsers}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <CardContent>
                <SettingsMerchantAvailabilityDialogForm />
              </CardContent>
              <CardActionsButtons
                leftActionsSlot={[
                  <SaveButton key="save-button" label="Сохранить" variant="contained" />,
                  <CancelButton key="cancel-button" onClick={props.onClose} variant="outlined" />,
                ]}
              />
            </form>
          </FormProvider>
        </RecordContextProvider>
      </Card>
    </Dialog>
  );
};
