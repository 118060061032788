import { Box } from 'shared/mui/Box';

import { Loading, LoadingProps } from '..';

export type PageLoadingProps = LoadingProps;

export const PageLoading = (props: PageLoadingProps): JSX.Element => (
  <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    <Loading sx={{ marginTop: '-3em' }} {...props} />
  </Box>
);
