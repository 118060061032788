import { styled } from '@mui/material/styles';

import { Dialog } from '../../../mui/Dialogs';

export const StyledDialog = styled(Dialog)({
  '.MuiBackdrop-root': {
    transition: 'none !important',
  },
  '.MuiCardContent-root': {
    padding: '0',
    paddingBottom: '0!important',
  },
  '.RaEdit-main': {
    marginTop: '0!important',
  },
});
