import { Introspecter } from '@api-platform/admin';
import { ReferenceArrayInputProps, useResourceContext } from 'react-admin';

import { ReferenceArrayInputIntrospectedComponent } from './ReferenceArrayInputIntrospectedComponent';

export type ReferenceArrayInputIntrospectedProps = {
  isFilter?: boolean;
} & ReferenceArrayInputProps;

export const ReferenceArrayInputIntrospected = (
  props: ReferenceArrayInputIntrospectedProps,
): JSX.Element => {
  const resource = useResourceContext(props);
  const { filter = {} } = props;

  return (
    <Introspecter
      component={ReferenceArrayInputIntrospectedComponent}
      includeDeprecated={true}
      resource={resource}
      {...props}
      filter={{ ...filter, groups: ['reference'] }}
    />
  );
};
