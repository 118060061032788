import { OrderStatuses } from 'constants/orderStatuses';

import { styled } from '@mui/material/styles';
import { ChipField } from 'react-admin';
import { appColors } from 'themes/variables';

export const ConfirmationChipField = styled(ChipField)({
  backgroundColor: appColors.orderStatus[OrderStatuses.WaitingConfirmation],
  color: appColors.background,
  margin: '0 !important',
  height: '24px',
});

export const OrderStatusChipFieldStyled = styled(ChipField)({
  margin: '0 !important',
  height: '24px',
});
