import { endOfDay, isToday } from 'date-fns';

export const getEndOfDate = (date?: Date): Date | null => {
  if (!date) {
    return null;
  }

  if (isToday(date)) {
    return new Date();
  } else {
    return endOfDay(date);
  }
};
