import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

import { useFilters } from '../../hooks/useFilters';

type CompaniesFilterValues = {
  name: string;
  archive: boolean | null;
};

export const CompaniesFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<CompaniesFilterValues>({
    name: '',
    archive: null,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              source="name"
              sx={{ marginBottom: 0 }}
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={[
                { id: true, name: 'В архиве' },
                { id: false, name: 'Активна' },
              ]}
              helperText={false}
              label="Состояние"
              name="archive"
              source="archive"
              variant="outlined"
            />
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
