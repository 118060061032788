import { useState } from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from 'shared/mui/IconButtons';

import { NotificationCounter } from './NotificationButton.styled';
import { useGetNotificationsCount } from '../hooks/useGetNotificationsCount';
import { NotificationsPopper } from '../NotificationPopper/NotificationPopper';

export const NotificationButton = (): JSX.Element => {
  const { notificationsCount, refetch } = useGetNotificationsCount();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="Уведомления"
        color="inherit"
        onClick={handleClick}
        size="large"
        sx={{ padding: '8px', position: 'relative' }}
      >
        {!!Number(notificationsCount) && (
          <NotificationCounter>{notificationsCount}</NotificationCounter>
        )}
        <NotificationsIcon />
      </IconButton>
      <NotificationsPopper anchorEl={anchorEl} onClose={handleClose} refetchCounter={refetch} />
    </>
  );
};
