import { useGetOne } from 'react-admin';

import { Merchant } from '../../../types';
import { LinearProgress } from '../../mui/LinearProgress/LinearProgress';
import { BooleanInput, BooleanInputProps } from '../../react-admin/Inputs';

type Props = BooleanInputProps & {
  merchantId: string;
};

export const SitesAvailabilityFormBooleanInput: React.FC<Props> = ({
  merchantId,
  label,
  ...restProps
}) => {
  const { data: merchant, isLoading } = useGetOne<Merchant>('merchants', { id: merchantId });

  if (isLoading) {
    return <LinearProgress />;
  }

  return <BooleanInput label={label || merchant?.name} {...restProps} />;
};
