import { ReactElement } from 'react';

import { RecordContextProvider } from 'react-admin';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { Order } from '../../../types';

type Props = {
  label?: string | ReactElement | boolean;
};

export const OrderAdjustmentsReportMethodField: React.FC<Props> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={() => {
        return (
          <ReferenceOneField
            label="Метод"
            link={false}
            reference="orders"
            sortable={false}
            source="order"
            target="id"
          >
            <FunctionField
              render={(record: Order) => {
                return (
                  <RecordContextProvider value={record}>
                    <ReferenceOneField
                      link={false}
                      reference="methods"
                      sortable={false}
                      source="method"
                      target="id"
                    >
                      <TextField source="name" />
                    </ReferenceOneField>
                  </RecordContextProvider>
                );
              }}
            />
          </ReferenceOneField>
        );
      }}
      source="method"
    />
  );
};
