export const APP_VERSIONS = [
  { id: 'KASSA_CASH' },
  { id: 'KASSA_GUEST' },
  { id: 'RFID_STAVKOMAT' },
  { id: 'ANDROID' },
  { id: 'ANDROID_NEW' },
  { id: 'IOS' },
  { id: 'IOS_NEW' },
  { id: 'IOS_NEW_2' },
  { id: 'IOS_IPAD' },
  { id: 'IOS_BROWSER' },
  { id: 'WEB' },
  { id: 'WEB_NEW' },
  { id: 'MOBI' },
  { id: 'MOBI_NEW' },
  { id: 'WIN_PHONE' },
  { id: 'TELEGRAM' },
  { id: 'WIN' },
];
