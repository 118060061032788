import React from 'react';

import { useGetOne, useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';

export const DirectionField: React.FC<{ source: string; label?: string }> = ({ source }) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne(
    'directions',
    { id: record?.[source] },
    { enabled: !!record?.[source] },
  );
  if (isLoading) {
    return <LinearProgress />;
  }

  return <Chip label={data?.description} />;
};
