import { styled } from '@mui/material/styles';
import { AutocompleteInput } from 'react-admin';

export const AppNewAutocompleteInput = styled(AutocompleteInput)({
  margin: 0,
  '& .MuiFormControl-root': {
    margin: 0,
  },
  '& .MuiInputBase-root': {
    padding: '0 !important',
  },
  '& .MuiInputBase-input': {
    height: 24,
    padding: '16px 12px !important',
  },
  '& .MuiFormHelperText-root': {
    height: 24,
    marginTop: 0,
  },
  '& .MuiFormLabel-root': {
    top: 8,
  },
  '& .MuiInputLabel-shrink': {
    top: 0,
  },
});
