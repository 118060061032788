import EventNoteIcon from '@mui/icons-material/EventNote';
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { IconButton } from '../IconButton/IconButton';

type Props = {
  entityName: string;
  entityId?: string;
  target?: string;
};

export const UserActionsIconButton: React.FC<Props> = ({
  entityName,
  entityId,
  target = '_self',
}) => {
  const record = useRecordContext();
  const encodeFilter = encodeURIComponent(
    JSON.stringify({ entityId: entityId || record?.originId, entityName }),
  );

  return (
    <IconButton
      component={Link}
      target={target}
      to={{
        pathname: '/user_actions',
        search: `filter=${encodeFilter}`,
      }}
    >
      <EventNoteIcon style={{ fontSize: 'inherit', marginTop: '1px' }} />
    </IconButton>
  );
};
