import { Link } from 'react-router-dom';
import { ChipProps } from 'shared/mui/Chips';
import { getLinkParams } from 'utils';

import { StyledChip } from './ApiClientsListMerchantField.styled';

type Props = ChipProps & {
  isLast: boolean;
};

export const ApiClientsMerchantFieldLinkedChip: React.FC<Props> = (props) => {
  const linkParams = getLinkParams({
    pathname: `/merchants/${encodeURIComponent(props.id as string)}/show`,
  });

  return <StyledChip component={Link} {...props} {...linkParams} />;
};
