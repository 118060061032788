import { styled } from '@mui/material/styles';
import { CreateGuesser } from 'shared/react-admin/Guessers';
import { appColors } from 'themes/variables';

export const StyledCreateGuesser = styled(CreateGuesser)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  backgroundColor: appColors.background,
  width: '444px',
  padding: 0,
  '& .MuiCardContent-root': {
    padding: 0,
  },
  '& .MuiCardContent-root:last-child': {
    padding: '0!important',
  },
  '& .RaCreate-main': {
    margin: 0,
  },
});
