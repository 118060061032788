import { useFormContext } from 'react-hook-form';

import { PopoverContentStyled } from './PopoverChip.styled';
import { PopoverChipFilterResetButton } from './PopoverChipFilterResetButton/PopoverChipFilterResetButton';
import { Card, CardActionsButtons } from '../../../../../Card';
import { Popover, PopoverProps } from '../../../../../Popover/Popover';
import { FilterApplyButton } from '../../../../FilterButtons';

type PopoverChipProps = PopoverProps & {
  onApply: () => void;
  onReset: () => void;
};

export const PopoverChip: React.FC<PopoverChipProps> = ({
  children,
  onApply,
  onReset,
  ...restProps
}) => {
  const { formState } = useFormContext();

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...restProps}
    >
      <Card>
        <PopoverContentStyled>{children}</PopoverContentStyled>
        <CardActionsButtons
          leftActionsSlot={[
            <FilterApplyButton
              disabled={!formState.isValid}
              key="apply-button"
              onClick={onApply}
            />,
            <PopoverChipFilterResetButton key="reset-button" label="Отменить" onClick={onReset} />,
          ]}
          sx={{
            padding: '16px 24px',
          }}
        />
      </Card>
    </Popover>
  );
};
