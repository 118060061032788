import { Typography } from 'shared/mui/Typography';

type Props = {
  label: number | string;
};

export const OrdersReportListShortReportAlertTypography: React.FC<Props> = ({ label }) => {
  return (
    <Typography component="span" fontSize={14} fontWeight={700}>
      {label}
    </Typography>
  );
};
