import { Table, TableProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTable = styled(Table)({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

export function AppShowDialogTable({ children, ...restProps }: TableProps): JSX.Element {
  return <StyledTable {...restProps}>{children}</StyledTable>;
}
