import React, { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, TextField } from '@mui/material';
import { useNotify } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';

import { sendNewPassword } from '../../config/requests';

const SetNewPassword = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const { token } = useParams();
  const notify = useNotify();
  const navigate = useNavigate();

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (token) {
      if (password !== repeatPassword) {
        return notify('Пароли не совпадают', { type: 'error' });
      }
      sendNewPassword(token, password)
        .then((res) => {
          notify(res.data.message, { type: 'success' });
          navigate('/login');
        })
        .catch((e) => notify(e.data.errors[0].title, { type: 'error' }));
    }
  };

  return (
    <form onSubmit={submit}>
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          id="password"
          label="Новый пароль"
          margin="normal"
          onChange={(e) => setPassword(e.target.value)}
          required
          type="password"
          value={password}
          variant="outlined"
        />
        <TextField
          id="repeatPassword"
          label="Повторите пароль"
          margin="normal"
          onChange={(e) => setRepeatPassword(e.target.value)}
          required
          type="password"
          value={repeatPassword}
          variant="outlined"
        />
        <Button sx={{ marginBottom: '8px' }} type="submit" variant="contained">
          Отправить
        </Button>
        <Button
          onClick={() => navigate('login')}
          startIcon={<ArrowBackIcon />}
          sx={{ alignSelf: 'flex-start' }}
        >
          Назад
        </Button>
      </Container>
    </form>
  );
};

export default SetNewPassword;
