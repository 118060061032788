import { styled } from '@mui/material/styles';
import { DatagridConfigurable } from 'react-admin';

export const DatagridConfigurableStyled = styled(DatagridConfigurable)({
  minWidth: '36px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  padding: 0,
  '& .RaDatagrid-headerCell': {
    verticalAlign: 'top',
    padding: '13px 16px',
    lineHeight: '23px',
  },
  '& .RaDatagrid-rowCell': {
    verticalAlign: 'center',
    padding: '12px 16px',
  },
  '& .MuiTableCell-paddingCheckbox': {
    paddingTop: '15px !important',
    verticalAlign: 'top',
  },
  '& .MuiTableCell-root': {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    verticalAlign: 'top',
    overflow: 'hidden',
    textAlign: 'left',
  },
  '& .column-originId': {
    width: 0,
  },
});
