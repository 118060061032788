import { ReactChild, ReactNode } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';

export interface OverflowTypograpyProps {
  children?: ReactChild;
  tooltipTitle?: ReactNode;
}

export const TooltipOverflow: React.FC<OverflowTypograpyProps> = ({ children, tooltipTitle }) => {
  return (
    <Tooltip PopperProps={{ placement: 'bottom' }} arrow={true} title={tooltipTitle || children}>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
