import { styled } from '@mui/material/styles';

import { Datagrid } from '../../../../shared/react-admin/Datagrids';

export const StyledDatagrid = styled(Datagrid)({
  th: {
    '&:last-child': {
      width: '50px',
    },
  },
  td: {
    '&:last-child': {
      width: '50px',
    },
  },
});
