import { maxValue, minValue, required } from 'react-admin';
import { NumberInput } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

import { CardContentStyled } from './ApiClientsShowWhiteIpAddressesForm.styled';
import { IP_PLACEHOLDER } from '../../constants/ipRegex';
import { useIpValidation } from '../../hooks/useIpValidation';

export const ApiClientsShowWhiteIpAddressesForm = () => {
  const ipValidation = useIpValidation();
  return (
    <CardContentStyled>
      <TextInputShrink
        label="IP адрес"
        placeholder={IP_PLACEHOLDER}
        size="medium"
        source="ip"
        validate={[required(), ipValidation]}
        variant="outlined"
      />
      <NumberInput
        InputLabelProps={{ shrink: true }}
        label="Маска"
        size="medium"
        source="mask"
        validate={[minValue(0), maxValue(32)]}
        variant="outlined"
      />
    </CardContentStyled>
  );
};
