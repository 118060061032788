import { queryClient } from 'App';
import { archiveCompany } from 'config/requests';
import { useGoBack } from 'hooks/useGoBack';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { CancelButton } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { DeleteStageDialogForm } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogForm';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Company } from 'types';

import { StyledBox } from './CompaniesArchiveDialog.styled';

type CompaniesArchiveDialogProps = {
  record?: Company;
  open: boolean;
  onClose: VoidFunction;
  isToRootOnSuccess?: boolean;
};

export const CompaniesArchiveDialog: React.FC<CompaniesArchiveDialogProps> = ({
  record,
  open,
  onClose,
  isToRootOnSuccess,
}) => {
  const { goBack } = useGoBack();
  const { deleteOneRecord, denyDeleteReason, stage } = useStagedDelete({
    actionFn: (id: string) => archiveCompany(id, true),
    record,
    onSuccess: () => {
      onClose();
      isToRootOnSuccess && goBack();
      queryClient.refetchQueries(['companies']);
    },
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Архивирование компании"
        />
        <DeleteStageDialogForm
          deleteLabel="В архив"
          deleteOneRecord={deleteOneRecord}
          details={`Компания ${record?.name} будет переведена в архив и перестанет быть доступной для настроек в системе и формирования финансовой отчетности. При необходимости, функциональность данной компании может быть восстановлена.`}
          onClose={onClose}
          open={true}
          stage={stage}
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton
              label="Вернуться к списку компаний"
              onClick={isToRootOnSuccess ? goBack : onClose}
              variant="outlined"
            />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialogForm>
      </StyledBox>
    </Dialog>
  );
};
