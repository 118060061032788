import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Show, ShowProps } from 'react-admin';

import { appColors } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

type Props = {
  title: string;
  listName: string;
  children: React.ReactNode;
  toolbarActions?: (React.ReactElement | false)[];
} & ShowProps;

const StyledShow = styled(Show)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  marginTop: '24px',
  backgroundColor: appColors.background,
});

export function AppShow({
  title,
  listName,
  children,
  toolbarActions,
  ...props
}: Props): JSX.Element {
  return (
    <Box>
      <AppBreadCrumbs currentPage={title} listName={listName} />
      <StyledShow {...props}>
        <AppPanelHeader pb={1} pl={3}>
          <AppPanelHeaderToolbar actions={toolbarActions} titleText={title} />
        </AppPanelHeader>
        {children}
      </StyledShow>
    </Box>
  );
}
