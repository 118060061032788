import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Show } from '../../Show/Show';

export const StyledShow = styled(Show)({
  '& .RaShow-main': {
    width: '600px',
    margin: 0,
    border: `1px solid ${appColors.divider}`,
    borderRadius: '4px',
  },
});
