import { useNotify, useUpdate } from 'react-admin';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { ApiClient } from 'types';

type Props = {
  onClose: VoidFunction;
  apiClient: ApiClient;
} & DialogProps;

export const ApiClientsListActiveFieldSwitchConfirmOff: React.FC<Props> = ({
  open,
  onClose,
  apiClient,
}) => {
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const onSubmit = () => {
    update(
      'api_clients',
      {
        id: apiClient?.id,
        data: {
          active: false,
        },
      },
      {
        onSuccess: () => {
          notify('Клиент API успешно отключен', {
            type: 'success',
            autoHideDuration: 3000,
          });
          onClose();
        },
        onError: (error) =>
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, {
            type: 'error',
            autoHideDuration: 3000,
          }),
      },
    );
  };

  return (
    <Dialog open={open}>
      <Card sx={{ width: '600px' }}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Выключение клиента API"
        />
        <CardContent>
          <Typography>
            {`Доступ к public API для клиента API ${apiClient.name} будет временно отключен.`}
          </Typography>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              color="warning"
              isLoading={isLoading}
              key="save-button"
              label="Отключить"
              onClick={onSubmit}
              variant="contained"
            />,
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
