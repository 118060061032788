import { useGetOne, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';

import { StyledBox } from '../RolesList/RolesList.styled';

type RoleNameFieldProps = {
  label?: string;
};

export const RoleNameField: React.FC<RoleNameFieldProps> = () => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne('role_categories', { id: record?.roleCategories });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img alt="icon" src={data?.description} />
      <StyledBox>{record?.name}</StyledBox>
    </Box>
  );
};
