import { ROLES_ICONS } from 'constants/roles';

import { useEffect } from 'react';

import { FormControlLabel, MenuItem } from '@mui/material';
import { FormDataConsumer, useEditController } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { Switch } from 'shared/mui/Switch';
import {
  AutocompleteInputIntrospected,
  BooleanInput,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { SelectIntrospected } from 'shared/react-admin/Selects';
import { SelectItem } from 'shared/react-admin/Selects/Select/Select';

import { Role } from '../../../constants/roles';
import { AutocompleteArrayInputIntrospected } from '../components/AutocompleteArrayInputIntrospected';
import { NewSchemeInfoTooltip } from '../components/NewSchemeInfoTooltip';
import { ACCESS_SELECT_ITEMS } from '../constants/accessSelectItems';

export const ApiClientsEditForm = () => {
  const { setValue, watch, clearErrors } = useFormContext();
  const {
    record: { merchant },
  } = useEditController();
  const roles = watch('roles');

  useEffect(() => {
    if (roles && roles.includes(Role.Service)) {
      setValue('merchants', []);
    }
  }, [roles]);

  const isShowNewAuthScheme = roles?.includes(Role.Merchant);

  return (
    <>
      <BooleanInput label="Активно" source="active" sx={{ marginBottom: 4 }} />
      <TextInputIntrospected fullWidth label="Название" source="name" variant="outlined" />
      <TextInputWithAliasIntrospected fullWidth label="Алиас" source="alias" variant="outlined" />
      <SelectIntrospected
        fullWidth
        items={ACCESS_SELECT_ITEMS}
        label="Доступ"
        renderValue={({ label, value }: SelectItem<Role>) => (
          <Chip icon={ROLES_ICONS[value]} label={label} />
        )}
        shrink={true}
        source="roles"
      >
        {ACCESS_SELECT_ITEMS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <Chip icon={ROLES_ICONS[value]} label={label} />
          </MenuItem>
        ))}
      </SelectIntrospected>
      {isShowNewAuthScheme && (
        <>
          <Box marginTop={3}>
            <FormControlLabel
              control={<Switch checked={!merchant} disabled name="scheme" />}
              label="Новая схема аутентификации"
              sx={{ marginRight: '0' }}
            />
            <NewSchemeInfoTooltip />
          </Box>
          {!merchant ? (
            <ReferenceArrayInput reference="merchants" source="merchants">
              <FormDataConsumer>
                {({ formData }) => {
                  const disabled = (formData.roles || []).find(
                    (role: Role) => role === Role.Service,
                  );

                  return (
                    <AutocompleteArrayInputIntrospected
                      disabled={disabled}
                      filterToQuery={(searchText: string) => ({ name: searchText })}
                      label="Мерчанты"
                      onChange={() => {
                        clearErrors('merchants');
                        clearErrors('merchant');
                      }}
                      optionText="name"
                      size="medium"
                      source="name"
                      sx={{ marginTop: '24px', maxWidth: '394px' }}
                      validate={[]}
                      variant="outlined"
                    />
                  );
                }}
              </FormDataConsumer>
            </ReferenceArrayInput>
          ) : (
            <ReferenceInput reference="merchants" source="merchant">
              <AutocompleteInputIntrospected
                filterToQuery={(searchText: string) => ({ name: searchText })}
                label="Мерчант"
                optionText="name"
                size="medium"
                source="name"
                sx={{ marginTop: '24px', maxWidth: '394px' }}
                variant="outlined"
              />
            </ReferenceInput>
          )}
        </>
      )}
    </>
  );
};
