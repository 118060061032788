import { forwardRef } from 'react';

import { InputBaseComponentProps } from '@mui/material';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { TextInputProps } from '../TextInput/TextInput';
import { TextInputShrink } from '../TextInputShrink/TextInputShrink';

export type TextInputMaskedProps = TextInputProps & {
  MaskedInputProps: MaskedInputProps;
};

const MaskedInputWrapper = forwardRef<
  HTMLElement,
  MaskedInputProps & Partial<InputBaseComponentProps>
>((props) => {
  const { inputRef, ...rest } = props;
  return <MaskedInput {...rest} ref={inputRef} />;
});

export const TextInputMasked: React.FC<TextInputMaskedProps> = (props) => {
  return (
    <TextInputShrink
      {...props}
      InputProps={{
        inputComponent: MaskedInputWrapper,
        inputProps: { ...props.MaskedInputProps },
      }}
    />
  );
};

MaskedInputWrapper.displayName = 'MaskedInputWrapper';
