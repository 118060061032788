import { SortPayload, useListController } from 'react-admin';
import { Order } from 'types';

import { OrdersListContent } from './OrderListContent';
import { AppList } from '../../Common/List';

export const OrdersListNew = (): JSX.Element => {
  const { filterValues } = useListController<Order>({
    resource: 'orders',
    queryOptions: { enabled: false },
  });
  const isListFilterExist = Object.keys(filterValues).length > 0;

  return (
    <AppList
      empty={false}
      pagination={<></>}
      queryOptions={{ enabled: isListFilterExist }}
      sort={{} as SortPayload}
    >
      <OrdersListContent />
    </AppList>
  );
};
