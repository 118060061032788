import { useEffect } from 'react';

import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import { Merchant } from '../../../../types';
import { MerchantSpacesFormValues } from '../MerchantSpacesCreate';

type UseCreateSitesFieldsReturnValues = {
  fields: FieldArrayWithId<MerchantSpacesFormValues, 'sites', 'id'>[];
};

export function useCreateSitesFields(merchants: Merchant[]): UseCreateSitesFieldsReturnValues {
  const { control, reset, watch } = useFormContext<MerchantSpacesFormValues>();
  const watchedCompanyId = watch('sourceCompany');

  const { fields, append } = useFieldArray<MerchantSpacesFormValues, 'sites', 'id'>({
    control,
    name: 'sites',
  });

  useEffect(() => {
    if (merchants.length <= 0) {
      return;
    }

    reset((formValues) => ({
      ...formValues,
      sites: [],
    }));

    merchants.forEach((merchant) => {
      append({
        record: merchant,
        sourceMerchantId: merchant.id,
        active: false,
      });
    });
  }, [merchants, watchedCompanyId]);

  return { fields };
}
