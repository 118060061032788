import { styled } from '@mui/material/styles';
import { EditButton } from 'react-admin';

import { appColors } from '../../../../themes/variables';

export const StyledEditIconButton = styled(EditButton)({
  color: appColors.secondary.main,
  height: '28px',
  minWidth: '28px',
  padding: 0,
  '& .MuiButton-startIcon': {
    padding: 0,
    margin: 0,
  },
  '&:hover': { borderRadius: '50%' },
});
