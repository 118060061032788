import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider } from 'react-admin';
import { Box } from 'shared/mui/Box';
import {
  ChipField,
  GatewayStatusIconField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MappingRecord, Permission } from 'types';

import { EditPayoutControlMappingFieldCard } from './EditPayoutControlMappingFieldCard/EditPayoutControlMappingFieldCard';

type Props = {
  mapping: MappingRecord;
  onChoice?: () => void;
  onReplace?: () => void;
  onDelete?: () => void;
};

export const EditPayoutControlMappingField: React.FC<Props> = ({
  mapping,
  onChoice,
  onDelete,
  onReplace,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <RecordContextProvider value={mapping}>
      <EditPayoutControlMappingFieldCard
        key={mapping.id}
        {...(hasResourcePermissions('merchant_payout_controls', [
          Permission.Full,
          Permission.Patch,
        ]) && {
          onChoice,
          onDelete,
          onReplace,
        })}
      >
        {hasResourceAccess('gateways') && (
          <ReferenceOneField
            emptyText="—"
            label="Шлюз"
            link="show"
            reference="gateways"
            source="gateway"
            target="id"
          >
            <Box display="flex" gap={1}>
              <GatewayStatusIconField />
              <TextField source="name" />
            </Box>
          </ReferenceOneField>
        )}
        <ReferenceOneField
          emptyText="—"
          label="Счет"
          link="show"
          reference="merchant_accounts"
          source="merchantAccount"
          target="id"
        >
          <TextField source="name" />
        </ReferenceOneField>
        {hasResourceAccess('commissions') && (
          <ReferenceArrayField label="Комиссии" reference="commissions" source="commissions">
            <SingleFieldList linkType={false}>
              <ChipField size="small" source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <TextField label="Процент трафика" source="value" />
      </EditPayoutControlMappingFieldCard>
    </RecordContextProvider>
  );
};
