import { styled } from '@mui/material';
import { Box } from 'shared/mui/Box';

export const MappingCardDatagridEmptyStyled = styled(Box)({
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '16px',
  borderRadius: '2px',
  fontSize: '14px',
  fontWeight: '500',
});
