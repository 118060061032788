import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { SimpleForm, useNotify } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { CreatePage } from 'shared/react-admin/Pages/CreatePage/CreatePage';

import { ProviderAccountsForm } from './components/ProviderAccountsForm';

export const ProviderAccountsCreate: React.FC = () => {
  const { toRoot } = useNavigateToRoot();
  const notify = useNotify();

  async function onSuccess() {
    toRoot();
  }

  async function onError(error: any) {
    notify(error.message, { type: 'error' });
  }

  return (
    <CreatePage
      listName="Личные кабинеты"
      mutationOptions={{ onSuccess, onError }}
      title="Создание личного кабинета"
    >
      <SimpleForm sx={{ '&.MuiCardContent-root': { padding: 0 } }} toolbar={false}>
        <CardContent sx={{ width: '100%', padding: '16px 24px 0!important' }}>
          <ProviderAccountsForm />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButtonValidated key="save-button" />,
            <CancelButton key="cancel-button" variant="outlined" />,
          ]}
        />
      </SimpleForm>
    </CreatePage>
  );
};
