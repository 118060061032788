import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(120);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': { maxWidth: '300px' },
  '& .column-createdAt': columnStyle,
  '& .column-updatedAt': columnStyle,
  '& .column-archive': getColumnStyle(131),
  '& .column-merchantCount': getColumnStyle(120),
  '& .column-undefined': getColumnStyle(180),
});
