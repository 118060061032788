import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';

import { useEffect } from 'react';

import { SubmissionErrors } from '@api-platform/admin';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController, useNotify } from 'react-admin';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import 'react-datepicker/dist/react-datepicker.css';
import { SaveButton } from 'shared/react-admin/Buttons';
import { appColors } from 'themes/variables';
import { Gateway, Permission } from 'types';

import { GatewaysEditFormDeleteDialog } from './GatewaysEditFormDeleteDialog';
import { GatewaysEditFormMain } from './GatewaysEditFormMain';
import { GatewaysEditFormSettingsGateway } from './GatewaysEditFormSettingsGateway/GatewaysEditFormSettingsGateway';
import { useGatewayMethodsFieldArray } from './hooks/useGatewayMethodsFieldArray';

type Props = {
  onSubmit: (e?: React.SyntheticEvent) => void;
  errors: SubmissionErrors | undefined;
};

export const GatewaysEditForm: React.FC<Props> = ({ onSubmit, errors }) => {
  const { record } = useEditController();
  const notify = useNotify();
  const { hasResourcePermissions } = usePermissions();
  const { control, setError } = useFormContext();
  const { errors: formErrors } = useFormState();

  const watchStatus = useWatch({ control, name: 'status' });
  const watchPilotModeSchedule = useWatch({
    control,
    name: 'gatewayPilotModeSchedule.schedule',
  });

  const isPilotGatewayStatus = watchStatus === GATEWAY_STATUSES[GatewayStatus.Pilot].id;
  const isDisabledGatewayStatus = watchStatus === GATEWAY_STATUSES[GatewayStatus.Disabled].id;

  const { gatewayMethodFields } = useGatewayMethodsFieldArray();
  const { handleOpenRecordModal, handleCloseRecordModal, openRecordModal } =
    useRecordModal<Gateway>();

  useEffect(() => {
    if (errors) {
      const errorsArray = Object.entries(errors);
      errorsArray?.forEach((error: any) => {
        const [property, message] = error;
        setError(property, { type: 'string', message });
      });
    }
  }, [errors]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!!Object.keys(formErrors).length || errors) {
      notify('Ошибка: Форма заполнена неверно, проверьте, пожалуйста, ошибки', { type: 'error' });
    }
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardContent sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: '384px',
            marginRight: isPilotGatewayStatus ? '24px' : 0,
          }}
        >
          <GatewaysEditFormMain isDisabledGatewayStatus={isDisabledGatewayStatus} />
        </Box>
        {isPilotGatewayStatus && (
          <Box
            sx={{
              borderLeft: `1px solid ${appColors.divider}`,
              width: '667px',
              paddingLeft: '24px',
            }}
          >
            <GatewaysEditFormSettingsGateway
              gatewayMethodFields={gatewayMethodFields}
              isPilotModeSchedule={watchPilotModeSchedule}
            />
          </Box>
        )}
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
        ]}
        rightActionsSlot={
          hasResourcePermissions('gateways', [Permission.Full, Permission.Delete]) && (
            <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
          )
        }
      />
      <GatewaysEditFormDeleteDialog
        handleCloseRecordModal={handleCloseRecordModal}
        openRecordModal={openRecordModal}
        record={record}
      />
    </form>
  );
};
