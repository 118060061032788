import { InfoOutlined } from '@mui/icons-material';
import { Typography } from 'shared/mui/Typography';

import { StyledBox } from './GatewaysEditFormSettingsGateway.styled';

export const GatewaysEditFormSettingsGatewayInfoBox = (): JSX.Element => (
  <StyledBox>
    <InfoOutlined color="info" sx={{ height: 18, width: 18, marginRight: '16px' }} />
    <Typography sx={{ lineHeight: 1, marginTop: '2px' }} variant="body1">
      Добавьте методы, чтобы можно было настроить пилотный режим.
    </Typography>
  </StyledBox>
);
