import { styled } from '@mui/material';
import { appColors } from 'themes/variables';

export const StyledCumulativeReportSummaryPeriod = styled('div')(
  ({ isOpen }: { isOpen: boolean }) => ({
    width: '100%',
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${appColors.divider}`,
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: isOpen ? 0 : '4px',
    borderBottomLeftRadius: isOpen ? 0 : '4px',
    padding: '0 32px',
    backgroundColor: appColors.background,
  }),
);
