import * as React from 'react';

import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { GatewayConversionSetting } from 'types/gatewayConversionSetting';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  record: GatewayConversionSetting | undefined;
};

export const OrderConversionListGatewaySwitchEnable: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  onClose,
  record,
}) => {
  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        title="Включить уведомления для шлюза"
      />
      <CardContent>
        Отправка уведомлений для шлюза {record?.id} {record?.gateway.name} будет выполняться в
        соответствии с настройками уведомлений для мерчанта {record?.merchant.name}
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <Button
            key="save-button"
            label="Подтвердить"
            onClick={() => {
              onConfirm && onConfirm();
              onClose();
            }}
            variant="contained"
          />,
          <Button
            key="close-button"
            label="Отменить"
            onClick={() => {
              onCancel && onCancel();
              onClose();
            }}
            variant="outlined"
          />,
        ]}
      />
    </Dialog>
  );
};
