import { useGetList } from 'react-admin';
import {
  AutocompleteInputShrink,
  AutocompleteInputShrinkProps,
} from 'shared/react-admin/Inputs/AutocompleteInputShrink/AutocompleteInputShrink';

export const EnumAutocompleteInputShrink: React.FC<
  { resource: string } & AutocompleteInputShrinkProps
> = ({ resource, ...rest }) => {
  const { data = [] } = useGetList(resource);
  return <AutocompleteInputShrink choices={data} {...rest} />;
};
