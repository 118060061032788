import { styled } from '@mui/material/styles';
import { CardHeader } from 'shared/mui/Card';
import { LoaderCircleMain } from 'shared/mui/LoaderCircleMain/LoaderCircleMain';
import { Datagrid } from 'shared/react-admin/Datagrids';

export const StyledDatagrid = styled(Datagrid)({
  '& .MuiTableCell-root': {
    verticalAlign: 'middle',
  },
  '& td.MuiTableCell-paddingCheckbox': {
    paddingTop: '20px !important',
  },
});

export const StyledLoaderCircle = styled(LoaderCircleMain)({
  position: 'absolute',
  top: '52%',
  left: '52%',
});

export const StyledCardHeader = styled(CardHeader)({
  padding: '0 0 16px 0',
});
