import { getExpressionParams } from 'config/requests';
import { useQuery } from 'react-query';
import { ExpressionParam } from 'types';

type UseExpressionParamsReturnValues = {
  expressionParams: ExpressionParam[];
  loadingExpressionParams: boolean;
};

export function useExpressionParams(): UseExpressionParamsReturnValues {
  const { data: expressionParams = [], isLoading: loadingExpressionParams } = useQuery<
    ExpressionParam[]
  >(['expression_params'], () => getExpressionParams());

  return { expressionParams, loadingExpressionParams };
}
