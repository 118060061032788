import { useMemo } from 'react';

import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

import { GatewayRuleFieldChip } from '../GatewayRuleFieldChip';

type Props = {
  currencies: Currency[];
  codes: string[];
};

export function GatewayRuleFieldExpressionParamCurrency({ currencies, codes }: Props): JSX.Element {
  const currenciesValue = useMemo(() => {
    return codes
      ?.map((code) => currencies?.find(({ alphaCode }) => alphaCode === code)?.name)
      ?.join(', ');
  }, [currencies, codes]);

  if (currencies.length <= 0) {
    return <></>;
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={<Typography fontSize={11}>{currenciesValue}</Typography>}
    >
      <GatewayRuleFieldChip label={currenciesValue} />
    </Tooltip>
  );
}
