import { TextField } from 'react-admin';
import { TableBody, TableProps, TableRowWrapper } from 'shared/mui/Table';
import { BooleanField } from 'shared/react-admin/Fields';

import { StyledTable } from '../ProviderShowDialog/ProviderShowDialog.styled';

export const ProviderShowTable = ({ sx }: TableProps): JSX.Element => (
  <StyledTable sx={sx}>
    <TableBody>
      <TableRowWrapper title="ID">
        <TextField source="originId" />
      </TableRowWrapper>
      <TableRowWrapper title="Название">
        <TextField source="name" />
      </TableRowWrapper>
      <TableRowWrapper title="Алиас">
        <TextField source="alias" />
      </TableRowWrapper>
      <TableRowWrapper title="Активный">
        <BooleanField source="active" />
      </TableRowWrapper>
      <TableRowWrapper title="Комментарий">
        <TextField emptyText="—" source="comment" />
      </TableRowWrapper>
    </TableBody>
  </StyledTable>
);
