import { Box } from 'shared/mui/Box';

import { StyledRuleList, StyledRuleListItem, StyledRuleDot } from './GatewayRulesFormRule.styled';
import { GatewayRulesFormRuleExpression } from './GatewayRulesFormRuleExpression/GatewayRulesFormRuleExpression';
import { GatewayRulesFormRuleGroupActions } from './GatewayRulesFormRuleGroupActions';
import { GatewayRulesFormRuleIsAnd } from './GatewayRulesFormRuleIsAnd';
import { useGatewayRulesFormContext } from '../../context/GatewayRulesFormContext';
import { useChildrenField, useExpressionField } from '../../hooks';
import { GatewayRulesFormRemoveButton } from '../GatewayRulesFormRemoveButton';

type Props = {
  className?: string;
  prefix?: string;
  onRemoveChildrenClick?: () => void;
  onRemoveConditionClick?: () => void;
};

export const GatewayRulesFormRule: React.FC<Props> = ({
  className,
  prefix = '',
  onRemoveChildrenClick,
  onRemoveConditionClick,
}) => {
  const { expressionParams } = useGatewayRulesFormContext();

  const { expressionsFields, onRemoveExpressionClick, onAddExpressionClick } = useExpressionField({
    expressionParams,
    name: `conditionGroup.${prefix}expressions`,
  });

  const { childrenFields, onAddChildrenClick, onRemoveChildren } = useChildrenField({
    name: `conditionGroup.${prefix}children`,
  });

  return (
    <StyledRuleList className={className}>
      <Box component="li" sx={{ alignSelf: 'flex-start' }}>
        <GatewayRulesFormRuleIsAnd name={`conditionGroup.${prefix}isAnd`} />
        <GatewayRulesFormRemoveButton
          onClick={prefix ? onRemoveChildrenClick : onRemoveConditionClick}
          sx={{ marginLeft: '16px' }}
        />
      </Box>
      <StyledRuleList
        style={{
          marginLeft: '40px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledRuleDot className="dot-expression" />
        {expressionsFields.map((field, expressionIndex) => (
          <StyledRuleListItem key={field.id}>
            <StyledRuleDot />
            <GatewayRulesFormRuleExpression
              comparisonOperatorName={`conditionGroup.${prefix}expressions.${expressionIndex}.comparisonOperator`}
              expressionIndex={expressionIndex}
              expressionParamName={`conditionGroup.${prefix}expressions.${expressionIndex}.expressionParam`}
              key={field.id}
              onRemoveExpressionClick={onRemoveExpressionClick}
              valueName={`conditionGroup.${prefix}expressions.${expressionIndex}.value`}
            />
          </StyledRuleListItem>
        ))}
        {childrenFields.map((field, childrenIndex) => (
          <StyledRuleListItem
            key={field.id}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <GatewayRulesFormRule
              className={`nested ${expressionsFields.length <= 0 ? 'empty-tree' : ''}`}
              key={field.id}
              onRemoveChildrenClick={onRemoveChildren ? onRemoveChildren(childrenIndex) : undefined}
              prefix={`${prefix}children.${childrenIndex}.`}
            />
          </StyledRuleListItem>
        ))}
        <StyledRuleListItem>
          <StyledRuleDot className="dot-expression" />
          <GatewayRulesFormRuleGroupActions
            onAddExpressionClick={onAddExpressionClick}
            onAddGroupClick={onAddChildrenClick}
          />
        </StyledRuleListItem>
      </StyledRuleList>
    </StyledRuleList>
  );
};
