import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';

import { blue } from '@mui/material/colors';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useShowController } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRow, TableRowWrapper } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import {
  BooleanField,
  ChipField,
  FunctionField,
  GatewayStatusIconField,
  TextField,
} from 'shared/react-admin/Fields';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';
import { Gateway, Provider } from 'types';

import { GatewaysShowTableMethodsDatagrid } from './GatewaysShowTableMethodsDatagrid';
import { GatewaysShowTableSingleFieldList } from './GatewaysShowTableSingleFieldList';
import { StyledChip } from '../GatewaysShow.styled';

export const GatewaysShowTable: React.FC = () => {
  const { record } = useShowController();
  const { hasResourceAccess } = usePermissions();
  const {
    record: provider,
    handleCloseRecordModal: handleCloseProviderModal,
    openRecordModal: openProviderModal,
    handleOpenRecordModal: handleOpenProviderModal,
  } = useRecordModal<Provider>();

  return (
    <CardTable sx={{ tableLayout: 'fixed' }}>
      <TableBody>
        <TableRowWrapper title="ID">
          <TextField source="originId" />
        </TableRowWrapper>

        <TableRowWrapper title="Название">
          <TextField source="name" />
        </TableRowWrapper>

        <TableRowWrapper title="Алиас">
          <TextField source="alias" />
        </TableRowWrapper>

        <TableRowWrapper title="Статус">
          <GatewayStatusIconField label="Статус" sx={{ display: 'flex' }} />
        </TableRowWrapper>

        {record?.status === GATEWAY_STATUSES[GatewayStatus.Disabled].id && (
          <TableRowWrapper title="Причина деактивации">
            <ReferenceOneField
              reference="gateway_deactivation_reasons"
              source="deactivationReason"
              target="id"
            >
              <TextField source="reason" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}

        {hasResourceAccess('providers') && (
          <TableRowWrapper title="Провайдер">
            <ReferenceOneField reference="providers" source="provider" target="id">
              <FunctionField
                render={(record: Provider | undefined) => (
                  <TextField
                    onClick={() => handleOpenProviderModal(record)}
                    source="name"
                    sx={{
                      cursor: 'pointer',
                      color: blue[700],
                    }}
                  />
                )}
              />
            </ReferenceOneField>
          </TableRowWrapper>
        )}

        <TableRowWrapper title="Направления">
          <ReferenceField label="Направление" reference="gateway_directions" source="direction">
            <FunctionField
              render={(record: RaRecord) =>
                record.description === 'Все направления' ? (
                  <Box display="flex" flexDirection="row">
                    <StyledChip label="Пополнение" />
                    <StyledChip label="Вывод" />
                  </Box>
                ) : (
                  <StyledChip label={record.description} />
                )
              }
            />
          </ReferenceField>
        </TableRowWrapper>

        <TableRowWrapper title="PCI DSS">
          <BooleanField source="pcidss" />
        </TableRowWrapper>

        {record?.status === GATEWAY_STATUSES[GatewayStatus.Pilot].id &&
          hasResourceAccess('methods') && (
            <TableRow>
              <GatewaysShowTableMethodsDatagrid />
            </TableRow>
          )}

        {record?.status !== GATEWAY_STATUSES[GatewayStatus.Pilot].id &&
          hasResourceAccess('methods') && (
            <TableRowWrapper title="Методы">
              <ReferenceArrayField reference="gateway_methods" source="gatewayMethods">
                <GatewaysShowTableSingleFieldList linkType={false}>
                  <ReferenceField label="Методы" link={false} reference="methods" source="method">
                    <FunctionField
                      render={(record: RaRecord) => <StyledChip label={record.name} />}
                    />
                  </ReferenceField>
                </GatewaysShowTableSingleFieldList>
              </ReferenceArrayField>
            </TableRowWrapper>
          )}

        {hasResourceAccess('currencies') && (
          <TableRowWrapper title="Валюта по умолчанию">
            <ReferenceOneField reference="currencies" source="defaultCurrency" target="id">
              <TextField source="alphaCode" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}

        {hasResourceAccess('currencies') && (
          <TableRowWrapper title="Поддерживаемые валюты">
            <ReferenceArrayField reference="currencies" source="supportedCurrencies">
              <GatewaysShowTableSingleFieldList linkType={false}>
                <ChipField
                  source="alphaCode"
                  sx={{
                    marginLeft: '0!important',
                    marginRight: '8px!important',
                  }}
                />
              </GatewaysShowTableSingleFieldList>
            </ReferenceArrayField>
          </TableRowWrapper>
        )}

        <TableRowWrapper title="Срок действия заказа (сек)">
          <TextField source="orderExpirationTimeSec" />
        </TableRowWrapper>

        <TableRowWrapper title="Сохранить данные метода пользователя">
          <BooleanField source="saveCustomerMethodData" />
        </TableRowWrapper>

        <TableRowWrapper title="Опрос статуса заказа">
          <BooleanField source="statusRequestEnabled" />
        </TableRowWrapper>

        <TableRowWrapper title="Макс. количество попыток опроса">
          <TextField source="transactionRequestMaxAttempts" />
        </TableRowWrapper>

        <TableRowWrapper title="Интервал опроса статуса">
          <FunctionField
            render={(record: Gateway) => {
              return (
                <Typography component="span" variant="body2">
                  {record?.attemptIntervalConfig?.join(', ')}
                </Typography>
              );
            }}
          />
        </TableRowWrapper>
      </TableBody>
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseProviderModal}
          open={openProviderModal}
          provider={provider}
        />
      )}
    </CardTable>
  );
};
