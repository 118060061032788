import { styled } from '@mui/material/styles';
import { Avatar } from 'shared/mui/Avatars';
import { Box } from 'shared/mui/Box';

export const StyledBox = styled(Box)({
  display: 'flex',
});

export const StyledAvatar = styled(Avatar)({
  width: 40,
  height: 40,
  fontSize: '20px',
});

export const StyledProfileBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '12px',
});
