import { Box, BoxProps } from 'shared/mui/Box';

import { StyledCard } from './FilterBox.styled';

export type FilterBoxProps = BoxProps;

export const FilterBox = ({ children, ...restProps }: FilterBoxProps): JSX.Element => (
  <StyledCard>
    <Box {...restProps}>{children}</Box>
  </StyledCard>
);
