import { useMemo, useState } from 'react';

import { DatagridProps, SortPayload } from 'react-admin';

import { sortBy } from '../utils';

type Props<T> = {
  data: T[];
  defaultSort?: SortPayload;
};

export type UseLocalDatagridReturnValue<T> = Pick<DatagridProps, 'setSort' | 'sort'> & {
  sortedData: T[];
};

export function useLocalDatagrid<T>({
  data,
  defaultSort = undefined,
}: Props<T>): UseLocalDatagridReturnValue<T> {
  const [currentSort, setCurrentSort] = useState<SortPayload | undefined>(defaultSort);

  const sortedData: T[] = useMemo(() => {
    if (currentSort) {
      return sortBy<T>({
        array: data,
        field: currentSort.field as keyof T,
        order: currentSort.order,
      });
    }
    return data;
  }, [data, currentSort]);

  function setSort(payload: SortPayload): void {
    setCurrentSort(payload);
  }

  return {
    sort: currentSort,
    setSort,
    sortedData,
  };
}
