import { InviteStatuses } from 'constants/inviteStatuses';
import { MerchantSpacesRole } from 'constants/roles';

import polyglotI18nProvider from 'ra-i18n-polyglot';
// @ts-ignore
import russianMessages from 'ra-language-russian';
import { TranslationMessages } from 'react-admin';

export const messages: TranslationMessages = {
  ra: {
    ...russianMessages.ra,
    message: {
      ...russianMessages.ra.message,
      delete_content: 'Вы уверены что хотите удалить этот объект?',
      delete_title: 'Удалить %{name} #%{id}?',
    },
    action: {
      ...russianMessages.ra.action,
      open: 'Открыть',
      bulk_actions:
        '1 элемент выбран |||| %{smart_count} элемента выбрано |||| %{smart_count} элементов выбрано',
      select_columns: 'Настройка колонок',
    },
  },
  merchant_spaces_roles: {
    [MerchantSpacesRole.HeadManager]: 'Главный менеджер',
    [MerchantSpacesRole.FinanceManager]: 'Фин. менеджер',
    [MerchantSpacesRole.PaymentManager]: 'Плат. менеджер',
    [MerchantSpacesRole.RiskManager]: 'Риск менеджер',
    [MerchantSpacesRole.Support]: 'Поддержка',
    [MerchantSpacesRole.Observer]: 'Наблюдатель',
  },
  invite_status: {
    [InviteStatuses.Wait]: 'Ожидает',
    [InviteStatuses.Expired]: 'Истекло',
    [InviteStatuses.Sent]: 'Отправлено',
    [InviteStatuses.Accepted]: 'Принято',
  },
};

export const i18nProvider = polyglotI18nProvider(() => messages, 'ru', {
  allowMissing: true,
});
