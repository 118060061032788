import { Role } from 'constants/roles';

import { RaRecord, required } from 'react-admin';
import {
  AutocompleteInput,
  AutocompleteMultiselectInput,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import {
  ReferenceArrayInputIntrospected,
  ReferenceInputIntrospected,
} from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const WorkTeamsForm = (): JSX.Element => {
  return (
    <>
      <TextInputIntrospected
        fullWidth
        label="Название"
        size="medium"
        source="name"
        validate={required()}
        variant="outlined"
      />
      <ReferenceArrayInputIntrospected
        isFilter={true}
        perPage={200}
        reference="countries"
        source="countries"
      >
        <AutocompleteMultiselectInput
          label="Страны"
          name="countries"
          optionText="name"
          size="medium"
          sx={{ marginBottom: '8px' }}
        />
      </ReferenceArrayInputIntrospected>
      <ReferenceInputIntrospected
        filter={{ 'roleEntities.alias': [Role.FinanceLead], enabled: true }}
        perPage={50}
        reference="users"
        source="manager"
      >
        <AutocompleteInput
          filterToQuery={(searchText: string) => ({ fullName: searchText })}
          fullWidth
          label="Руководитель группы"
          name="manager"
          optionText={(record: RaRecord) => record && `${record.firstName} ${record.lastName}`}
          size="medium"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInputIntrospected>
      <ReferenceArrayInputIntrospected
        filter={{
          'roleEntities.alias': [Role.Finance],
          enabled: true,
        }}
        perPage={200}
        reference="users"
        source="members"
      >
        <AutocompleteMultiselectInput
          filterToQuery={(searchText: string) => ({
            fullName: searchText,
          })}
          label="Сотрудники"
          name="members"
          optionText="name"
          renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
          size="medium"
          source="firstName"
          validate={required()}
        />
      </ReferenceArrayInputIntrospected>
    </>
  );
};
