import { DOMAIN_REGEX } from 'constants/regexp';

import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

type UseFiltersReturnValues = {
  form: UseFormReturn<any>;
  resetFilter: () => void;
};

const schema = yup.object().shape({
  merchantId: yup.string().required('Обязательное поле'),
  currency: yup.string().required('Обязательное поле'),
  country: yup.string().required('Обязательное поле'),
  customerId: yup.string().required('Обязательное поле'),
  direction: yup.string().required('Обязательное поле'),
  language: yup.string().nullable(),
  countDeposit: yup.number().nullable(),
  appVersion: yup.string().nullable(),
  domain: yup.string().matches(DOMAIN_REGEX, { message: 'Некорректный домен' }).nullable(),
});

export function useOrderMethodsFilterForm(): UseFiltersReturnValues {
  const form = useForm({ resolver: yupResolver(schema) });

  const resetFilter = useCallback(() => {
    form.reset();
  }, [form]);

  return {
    form,
    resetFilter,
  };
}
