import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterBaseApplyButton } from 'shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from 'shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextInput } from 'shared/react-admin/Inputs';

export type MerchantShowRulesFilterValues = {
  name?: string | null;
};

const INITIAL_VALUES = {
  name: null,
};

export const MerchantShowRulesListFilter = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantShowRulesFilterValues>(
    INITIAL_VALUES,
    INITIAL_VALUES,
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInput
              InputLabelProps={{ shrink: true }}
              helperText={false}
              label="Название"
              source="name"
              sx={{ marginTop: '8px !important', marginBottom: '8px' }}
              variant="outlined"
            />
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterBaseApplyButton key="apply-button" type="submit" />
              <FilterBaseResetButton key="reset-button" onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
