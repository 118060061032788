import { useMemo } from 'react';

import { UserActionsFiltersType } from 'components/UserActions/UserActionsList';
import { RaRecord, useListContext } from 'react-admin';
import DatePicker from 'react-datepicker';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { CalendarTodayIcon } from 'shared/mui/Icons';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';
import { getDateRangeFilter, getEndOfDate } from 'utils';

import { useGetEntities } from './hooks/useGetEntities';

type Props = {
  form: UseFormReturn<UserActionsFiltersType>;
  resetValues: UserActionsFiltersType;
};

export const UserActionsFilters: React.FC<Props> = ({ form, resetValues }) => {
  const { displayedFilters, setFilters, filterValues } = useListContext();

  const { data } = useGetEntities();
  const entitiesList = Object.entries(data?.data || []);
  const entities = entitiesList?.map(([key, value]) => ({ id: key, name: value }));

  const onSubmit = (values: UserActionsFiltersType) => {
    setFilters(
      {
        ...values,
        'user.roleEntities': values.roleEntities,
        createdAt: getDateRangeFilter(values.createdAt),
      },
      displayedFilters,
    );
  };

  const defaultEntity = useMemo(
    () => filterValues?.entityName && entities.find(({ id }) => id === filterValues.entityName),
    [entities, filterValues],
  );

  const resetFilters = () => {
    setFilters({}, displayedFilters);
    form.reset(resetValues);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <Controller
              control={form.control}
              name="createdAt.gte"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      fullWidth
                      label="Дата и время от"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy HH:mm"
                  endDate={form.watch('createdAt.lte')}
                  locale="ru"
                  maxDate={form.watch('createdAt.lte') || new Date()}
                  onChange={(date: Date) => {
                    field.onChange(date);
                  }}
                  selected={field.value}
                  selectsStart
                  showTimeInput
                  startDate={field.value}
                  timeInputLabel="Время"
                />
              )}
            />
            <Controller
              control={form.control}
              name="createdAt.lte"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      fullWidth
                      label="Дата и время до"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy HH:mm"
                  endDate={field.value}
                  locale="ru"
                  maxDate={new Date()}
                  minDate={form.watch('createdAt.gte')}
                  onChange={(date: Date) => {
                    field.onChange(getEndOfDate(date));
                  }}
                  selected={field.value}
                  selectsEnd
                  showTimeInput
                  startDate={form.watch('createdAt.gte')}
                  timeInputLabel="Время"
                />
              )}
            />
            <AutocompleteMultiselectInputShrink
              defaultValue={defaultEntity && [defaultEntity as RaRecord]}
              fullWidth
              helperText={false}
              label="Сущность"
              name="entityName"
              optionText="name"
              optionValue="id"
              options={entities}
              size="small"
            />
            <TextInputShrink
              helperText={false}
              label="ID сущности"
              name="entityId"
              resettable
              source="entityId"
              variant="outlined"
            />
          </FilterRow>
          <FilterRow marginTop={2}>
            <ReferenceArrayInput
              isFilter={true}
              name="roleEntities"
              perPage={200}
              reference="roles"
              source="roleEntities"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Роль"
                name="roleEntities"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              isFilter={true}
              name="user"
              perPage={200}
              reference="users"
              source="user"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({
                  fullName: searchText,
                })}
                helperText={false}
                label="Пользователь"
                name="user"
                optionText="name"
                renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
                size="small"
              />
            </ReferenceArrayInput>
          </FilterRow>
          <FilterRow sx={{ marginTop: '16px' }}>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilters} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
