import { useGoBack } from 'hooks/useGoBack';
import { useRecordModal } from 'hooks/useRecordModal';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DeleteDialog } from 'shared/react-admin/Dialogs/DeleteDialog/DeleteDialog';
import { TextField } from 'shared/react-admin/Fields';
import { Merchant } from 'types';

export const MerchantsDelete = (): JSX.Element => {
  const { goBack } = useGoBack();
  const { handleCloseRecordModal: handleCloseProviderModal } = useRecordModal<Merchant>();

  const onDeleteCallback = () => {
    handleCloseProviderModal();
    goBack();
  };

  return (
    <DeleteDialog title="Удаление мерчанта">
      <CardContent>
        {'Мерчант '}
        <TextField label="Название" source="name" sx={{ fontSize: 16 }} />
        {' будет удален без возможности восстановления.'}
      </CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          <DeleteButton key="delete-base-button" onDeleteCallback={onDeleteCallback} />,
        ]}
      />
    </DeleteDialog>
  );
};
