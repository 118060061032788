import React, { useState } from 'react';

import { CheckCircleOutline } from '@mui/icons-material';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';

import { ConfirmEnableSitesAccessDialog } from './ConfirmEnableSitesAccessDialog';
import { MerchantSpacesSiteFieldValues } from '../MerchantSpacesCreate/MerchantSpacesCreate';

type EnableAccessButtonProps = {
  filterValues?: FieldValues;
  resource?: string;
  selectedIds?: string[];
};

export const EnableSitesAccessButton: React.FC<EnableAccessButtonProps> = (props) => {
  const { setValue, getValues } = useFormContext();
  const [open, setOpen] = useState(false);

  const onClose = (): void => {
    setOpen(false);
  };

  const onConfirm = (): void => {
    const sites = getValues('sites');
    sites.forEach((site: MerchantSpacesSiteFieldValues, index: number) => {
      if (props.selectedIds?.includes(site?.record?.id)) {
        setValue(`sites.${index}.active`, true);
      }
    });

    onClose();
  };

  const onClick = (): void => {
    setOpen(true);
  };

  return (
    <>
      <Button label="Включить доступ" onClick={onClick} startIcon={<CheckCircleOutline />} />
      <ConfirmEnableSitesAccessDialog
        onClose={onClose}
        onConfirm={onConfirm}
        open={open}
        selectedIds={props.selectedIds}
        sites={getValues('sites')}
      />
    </>
  );
};
