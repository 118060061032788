import { useMemo } from 'react';

interface IRuleCardStyles {
  gatewayRuleCardSx: {
    button: Record<string, string | Record<string, number>>;
  };
}

export function useGatewayRuleCardStyles(): IRuleCardStyles {
  const gatewayRuleCardSx = useMemo(
    () => ({
      button: {
        height: '28px',
        width: '28px',
        borderRadius: '50%',
        fontSize: 'inherit',
        '& .MuiButton-startIcon': {
          marginRight: 0,
        },
      },
    }),
    [],
  );

  return { gatewayRuleCardSx };
}
