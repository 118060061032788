import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';

import { OrderConversionListFormValues } from '../OrderConversionList';
import { OrderConversionListFilterForm } from '../OrderConversionListFilter/OrderConversionListFilterForm';
import { OrderConversionListFilterChips } from '../OrderConversionListFilterChips/OrderConversionListFilterChips';
import {
  ConversionPeriod,
  OrderConversionListPeriodToggle,
} from '../OrderConversionListPeriodToggle/OrderConversionListPeriodToggle';

type Props = {
  onReset: VoidFunction;
  setPeriod: (value: ConversionPeriod) => void;
  form: UseFormReturn<OrderConversionListFormValues, any, any>;
  onSubmit: (values: OrderConversionListFormValues) => void;
  toggleDrawer: (value: boolean) => VoidFunction;
  isListFilterExist: boolean;
  period: ConversionPeriod;
  openDrawer: boolean;
  appliedFilters: Record<string, any>;
  setAppliedFilter: (name: string, value: any) => void;
};

export const OrderConversionListFilter: React.FC<Props> = ({
  onReset,
  setPeriod,
  form,
  toggleDrawer,
  isListFilterExist,
  period,
  openDrawer,
  appliedFilters,
  setAppliedFilter,
  onSubmit,
}) => {
  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton key="clear" onClick={onReset} visible={isListFilterExist} />,
        ]}
        rightActionsSlot={[
          <OrderConversionListPeriodToggle
            key="toggle"
            onChange={(_, newValue) => setPeriod(newValue)}
            value={period}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent sx={{ gap: '0' }}>
              <OrderConversionListFilterForm />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <OrderConversionListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
    </>
  );
};
