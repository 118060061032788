import React from 'react';

import { useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';

export const GatewaysChip: React.FC = () => {
  const record = useRecordContext();

  return <Chip label={record.description} />;
};
