import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import { useEffect, useState } from 'react';

import { useCumulativeReportFilters } from 'components/Finance/CumulativeReport/hooks/useCumulativeReportFilters';
import { useCumulativeReportSearchParamsFilters } from 'components/Finance/CumulativeReport/hooks/useCumulativeReportSearchParamsFilters';
import { getUserInfo } from 'config/requests';
import { fromUnixTime } from 'date-fns';
import { useReportExport } from 'hooks/queries/useReportExport';
import { ListContextProvider, useList, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { CumulativeReportDatagrid } from './components/CumulativeReportDatagrid/CumulativeReportDatagrid';
import { CumulativeReportSummaryCollapsible } from './components/CumulativeReportSummaryCollapsible';
import { EXPORT_FIELDS_CUMULATIVE_REPORT } from './constants/exportFields';
import { CumulativeReportFilter } from './CumulativeReportFilter';
import { useGetCumulativeReport } from './hooks/useGetCumulativeReport';

export type CumulativeReportFilterValues = {
  currency?: string | null;
  periodFrom?: number;
  periodTo?: number;
  company?: string;
  merchant?: string;
  provider?: string;
  merchantAccounts?: string[] | [];
  exportType?: string;
  accountsSummary?: boolean | null;
  jobType?: string;
  notificationTransport?: [ExportTransportTypes.Email];
};

export const CumulativeReportList: React.FC = () => {
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);

  const searchParamsFilter = useCumulativeReportSearchParamsFilters();

  const { form, onSubmit, resetFilter } = useCumulativeReportFilters();

  const { cumulativeReport, isLoading } = useGetCumulativeReport(searchParamsFilter);

  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const { data: user } = useQuery('me', getUserInfo);
  const notify = useNotify();

  const reportListContext = useList({
    data: cumulativeReport?.reportItems || [],
    isLoading,
  });
  const summaryListContext = useList({
    data: cumulativeReport?.accountsSummaryItems || [],
    isLoading,
  });

  const { mutate: exportReportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...searchParamsFilter,
      exportType: exportFormat,
      jobType: ExportJobTypes.Cumulative,
      ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
    },
    preferenceKey: ReportNames.Cumulative,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по нарастающему итогу формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  useEffect(() => {
    const entries = Object.entries(
      cleanEmpty({
        ...searchParamsFilter,
        periodFrom: searchParamsFilter.periodFrom
          ? fromUnixTime(searchParamsFilter.periodFrom)
          : '',
        periodTo: searchParamsFilter.periodTo ? fromUnixTime(searchParamsFilter.periodTo) : '',
      }),
    );
    entries.forEach(([key, value]) => {
      form.setValue(key, value, { shouldDirty: true });
    });
  }, []);

  return (
    <ListContextProvider value={reportListContext}>
      <PageHeader
        actions={[
          <ExportFormatsButton
            disabled={!Object.keys(searchParamsFilter).length}
            exportFormat={exportFormat}
            key="export-button"
            onChangeFormat={setExportFormat}
            onClick={() => setIsOpenNotifyDialog(true)}
          />,
        ]}
        headerTitle={{
          titleText: 'Отчет по нарастающему итогу',
          tooltipText:
            'Нарастающий итог – это сумма показателей, где к данным текущего периода добавляются суммы предыдущих периодов.',
        }}
        mt={3}
      />
      <CumulativeReportFilter
        filters={searchParamsFilter}
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
      />
      {!!cumulativeReport?.accountsSummaryItems?.length && (
        <ListContextProvider value={summaryListContext}>
          <CumulativeReportSummaryCollapsible filters={searchParamsFilter} />
        </ListContextProvider>
      )}
      <CumulativeReportDatagrid filters={searchParamsFilter} />
      <NotifyDialog
        isLoading={isExportLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportReportMutate}
        open={isOpenNotifyDialog}
        title={
          <>
            <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
              Экспорт отчета
            </Typography>
            <ExportEditButton
              fields={EXPORT_FIELDS_CUMULATIVE_REPORT}
              preferenceKey={ReportNames.Cumulative}
            />
          </>
        }
      />
    </ListContextProvider>
  );
};
