import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { ButtonProps } from '../../../Buttons';
import { FilterBaseApplyButton } from '../FilterBaseApplyButton/FilterBaseApplyButton';

export type FilterApplyButtonProps = ButtonProps;

// Кнопка предназначена для фильтров, в которых используется ListContext
export const FilterApplyButton: React.FC<FilterApplyButtonProps> = ({
  label = 'Применить',
  color = 'primary',
  variant = 'contained',
  ...restProps
}) => {
  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext();

  const { filterValues = {} } = useListContext();
  const isEmptyUrlFilters = Object.keys(filterValues).length === 0;

  const disabled =
    (!isDirty && isEmptyUrlFilters) || (Object.keys(dirtyFields).length === 0 && isEmptyUrlFilters);

  return (
    <FilterBaseApplyButton
      color={color}
      disabled={disabled}
      label={label}
      variant={variant}
      {...restProps}
    />
  );
};
