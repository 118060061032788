import { useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { FieldValues } from 'react-hook-form';

import { FilterCollapsibleChipsToolbar } from './FilterCollapsible.styled';
import { FilterCollapsibleToolbar } from './FilterCollapsibleToolbar/FilterCollapsibleToolbar';
import { FilterChipValue } from '../../../../hooks/useFilterChips';
import { ResetFilterArrayById, ResetFilterBySource } from '../../../../hooks/useFilters';
import { Button } from '../../Buttons';
import { Filter, FilterProps } from '../Filter/Filter';
import { FilterChipsContextProvider } from '../FilterChips/context/filterChipsContext';
import { FilterChips } from '../FilterChips/FilterChips';

export type FilterCollapsibleProps<TFilterValues extends FieldValues> = {
  actions?: (React.ReactElement | false)[] | false;
  filterChipValues: FilterChipValue<TFilterValues>[];
  resetFilterBySource: ResetFilterBySource<TFilterValues>;
  resetFilterArrayById?: ResetFilterArrayById<TFilterValues>;
  alert?: React.ReactElement | undefined;
} & FilterProps;

export function FilterCollapsible<TFilterValues extends FieldValues>({
  children,
  alert,
  actions,
  filterChipValues,
  resetFilterBySource,
  resetFilterArrayById,
  ...restProps
}: FilterCollapsibleProps<TFilterValues>) {
  const [close, setClose] = useState(false);

  function toggleClose(): void {
    setClose((prev) => !prev);
  }

  function getContent() {
    if (close) {
      return (
        <FilterChipsContextProvider
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById}
          resetFilterBySource={resetFilterBySource}
        >
          <FilterCollapsibleChipsToolbar>
            <FilterChips />
            <Button
              endIcon={<ArrowDownwardIcon />}
              label="Развернуть фильтр"
              onClick={toggleClose}
              size="small"
              sx={{ minWidth: 'fit-content' }}
            />
          </FilterCollapsibleChipsToolbar>
        </FilterChipsContextProvider>
      );
    }

    return (
      <>
        {children}
        <FilterCollapsibleToolbar
          actions={actions}
          additionalActions={[
            <Button
              endIcon={<ArrowUpwardIcon />}
              key="close-button"
              label="Свернуть фильтр"
              onClick={toggleClose}
              size="small"
            />,
          ]}
        />
      </>
    );
  }

  return (
    <Filter alert={alert} {...restProps}>
      {getContent()}
    </Filter>
  );
}
