import { useMemo } from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';

type Props = ButtonProps & { label?: string };

const StyledResetFilterButton = styled(Button)({
  width: '100%',
});

export function AppResetFilterButton({
  color = 'primary',
  variant = 'outlined',
  label = 'Сбросить',
  ...restProps
}: Props): JSX.Element {
  const { formState } = useFormContext();

  const hasDirtyFields = useMemo(() => {
    if (typeof formState.defaultValues === 'object') {
      return Object.values(formState.defaultValues).some((v) => {
        if (Array.isArray(v) && v.length === 0) {
          return false;
        }
        return !!v;
      });
    } else {
      return false;
    }
  }, [formState]);

  return (
    <StyledResetFilterButton
      color={color}
      disabled={!(formState.isDirty || formState.isSubmitted || hasDirtyFields)}
      variant={variant}
      {...restProps}
    >
      {label}
    </StyledResetFilterButton>
  );
}
