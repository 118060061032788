import { SxProps, Theme } from '@mui/material';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';

type DenyDeleteProps = {
  reason: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

export const DeleteStageDialogDenyReason: React.FC<DenyDeleteProps> = ({
  reason,
  sx = { padding: '0 24px 24px' },
  children,
}) => {
  return (
    <Box sx={sx}>
      <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
        {reason}
      </Typography>
      <Box display="flex" pt={3}>
        {children}
      </Box>
    </Box>
  );
};
