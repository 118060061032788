import { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import ContentAdd from '@mui/icons-material/Add';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { RaRecord, WithListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import {
  DeleteIconButton,
  EditIconButton,
  ShowIconButton,
  UserEntityPermissionsIconButton,
} from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TooltipOverflow } from 'shared/mui/Tooltip/TooltipOverflow/TooltipOverflow';
import { TooltipTextField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { UserEntityPermissionsNames } from 'shared/widgets/UserEntityPermissionsDialog/constants/userEntityPermissionsNames';
import { UserEntityPermissionsDialog } from 'shared/widgets/UserEntityPermissionsDialog/UserEntityPermissionsDialog';
import { Currency, MerchantAccount, Permission } from 'types';

import { MerchantShowAccountsActivityField } from './components/MerchantShowAccountsActivityField.tsx/MerchantShowAccountsActivityField';
import { MerchantShowAccountsCredentialsDialog } from './components/MerchantShowAccountsCredentialsDialog/MerchantShowAccountsCredentialsDialog';
import { MerchantShowAccountsNameField } from './components/MerchantShowAccountsNameField/MerchantShowAccountsNameField';
import { StyledChipFieldLink, StyledDatagrid } from './MerchantShowAccounts.styled';
import { MerchantShowAccountsCreateDialog } from './MerchantShowAccountsCreateDialog/MerchantShowAccountsCreateDialog';
import { MerchantShowAccountsDeleteDialog } from './MerchantShowAccountsDeleteDialog/MerchantShowAccountsDeleteDialog';
import { MerchantShowAccountsEditDialog } from './MerchantShowAccountsEditDialog/MerchantShowAccountsEditDialog';
import { MerchantShowAccountsFilters } from './MerchantShowAccountsFilters';
import { MerchantShowAccountsShowDialog } from './MerchantShowAccountsShowDialog/MerchantShowAccountsShowDialog';
import { EMPTY_FIELD } from '../../../../constants';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRecordModal } from '../../../../hooks/useRecordModal';
import {
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
} from '../../../../shared/react-admin/Fields';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantShowAccounts: React.FC<Props> = ({ merchantId, merchantName }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [returnDialog, setReturnDialog] = useState('');

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<MerchantAccount>();
  const {
    record: recordCredentialsDialogShow,
    handleCloseRecordModal: handleCloseCredentialsDialogShow,
    openRecordModal: openCredentialsDialogShow,
    handleOpenRecordModal: handleOpenCredentialsDialogShow,
  } = useRecordModal<MerchantAccount>();
  const {
    record: recordCredentialsDialogList,
    handleCloseRecordModal: handleCloseCredentialsDialogList,
    openRecordModal: openCredentialsDialogList,
    handleOpenRecordModal: handleOpenCredentialsDialogList,
  } = useRecordModal<MerchantAccount>();
  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<MerchantAccount>();
  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<MerchantAccount>();
  const {
    record: recordPermissoinsDialog,
    handleCloseRecordModal: handleClosePermissionsDialog,
    openRecordModal: openPermissionsDialog,
    handleOpenRecordModal: handleOpenPermissionsDialog,
  } = useRecordModal<MerchantAccount>();

  const closeDeleteDialog = (record?: MerchantAccount) => {
    handleCloseDeleteDialog();
    setReturnDialog('');
    if (!record) {
      return;
    }
    if (returnDialog === 'show') {
      handleOpenShowDialog(record);
    }
    if (returnDialog === 'edit') {
      handleOpenEditDialog(record);
    }
  };

  return (
    <ListPage
      actions={[
        hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Create]) && (
          <Button
            key="create"
            label="Добавить"
            onClick={() => setOpenCreateDialog(true)}
            startIcon={<ContentAdd />}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
            variant="contained"
          />
        ),
      ]}
      disableSyncWithLocation
      empty={false}
      filter={{ merchants: [merchantId] }}
      headerTitle={{
        titleText: 'Список счетов мерчанта',
        titleSize: 'small',
      }}
      resource="merchant_accounts"
      sort={{ field: 'id', order: 'DESC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <WithListContext
        render={({ data, isLoading, filterValues }) => {
          const showAlert = !data?.length && !isLoading && !Object.keys(filterValues).length;
          if (showAlert) {
            return <Alert severity="info">Мерчанту {merchantName} не добавлены счета.</Alert>;
          }
          return (
            <>
              <MerchantShowAccountsFilters />
              <StyledDatagrid bulkActionButtons={false}>
                <TextField
                  component="div"
                  label="ID"
                  sortBy="id"
                  source="originId"
                  sx={{ marginTop: '2px' }}
                />
                <MerchantShowAccountsNameField label="Название" source="name" />
                <MerchantShowAccountsActivityField
                  label="Последняя активность"
                  merchantId={merchantId}
                  source="balanceUpdatedAtMessage"
                />
                <FunctionField
                  label="Доступ к счету {json}"
                  render={(record: MerchantAccount) =>
                    record.credentials ? (
                      <Button
                        label="Показать"
                        onClick={() => handleOpenCredentialsDialogList(record)}
                        size="small"
                        startIcon={<DataObjectIcon />}
                        sx={{ marginTop: '-4px' }}
                      />
                    ) : (
                      <Button
                        disabled
                        label="НЕТ ДОСТУПА"
                        size="small"
                        startIcon={<DataObjectIcon />}
                      />
                    )
                  }
                  sortable={false}
                  source="credentials"
                />
                <TooltipTextField
                  label="Мерчант"
                  sortable={false}
                  source="merchants"
                  value={merchantName}
                />
                {hasResourceAccess('providers') && (
                  <ReferenceOneField
                    emptyText={EMPTY_FIELD}
                    label="Провайдер"
                    link="show"
                    reference="providers"
                    sortable={false}
                    source="provider"
                    target="id"
                  >
                    <TextField source="name" />
                  </ReferenceOneField>
                )}
                <FunctionField
                  label="Сквозной ID"
                  render={({ merchantIdEnabled }: MerchantAccount) =>
                    merchantIdEnabled ? 'Да' : 'Нет'
                  }
                />
                {hasResourceAccess('currencies') && (
                  <ReferenceOneField
                    label="Валюта"
                    reference="currencies"
                    sortable={false}
                    source="currency"
                    target="id"
                  >
                    <FunctionField
                      render={(record?: Currency) => (
                        <TooltipOverflow tooltipTitle={`${record?.alphaCode}, ${record?.name}`}>
                          {record?.alphaCode}
                        </TooltipOverflow>
                      )}
                    />
                  </ReferenceOneField>
                )}
                {hasResourceAccess('gateways') && (
                  <ReferenceArrayField
                    emptyText={EMPTY_FIELD}
                    label="Шлюзы"
                    reference="gateways"
                    sortable={false}
                    source="gateways"
                  >
                    <SingleFieldList linkType="show">
                      <FunctionField
                        render={(record: RaRecord) => (
                          <StyledChipFieldLink color="default" record={record} source="name" />
                        )}
                      />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
                <DateTimeField label="Дата создания" source="createdAt" />
                <DateTimeField label="Дата редактир." source="updatedAt" />
                <ActionsSlots
                  render={(record: MerchantAccount) => (
                    <>
                      {hasResourceAccess('user_entity_permissions') && (
                        <UserEntityPermissionsIconButton
                          onClick={() => handleOpenPermissionsDialog(record)}
                        />
                      )}
                      {hasResourceAccess('user_actions') && (
                        <UserActionsIconButton entityName="merchant_account" target="_blank" />
                      )}
                      {hasResourcePermissions('merchant_accounts', [
                        Permission.Full,
                        Permission.Read,
                      ]) && <ShowIconButton onClick={() => handleOpenShowDialog(record)} />}
                      {hasResourcePermissions('merchant_accounts', [
                        Permission.Full,
                        Permission.Update,
                      ]) &&
                        !record?.locked && (
                          <EditIconButton onClick={() => handleOpenEditDialog(record)} />
                        )}
                      {hasResourcePermissions('merchant_accounts', [
                        Permission.Full,
                        Permission.Delete,
                      ]) && <DeleteIconButton onClick={() => handleOpenDeleteDialog(record)} />}
                    </>
                  )}
                  sx={{ marginTop: '-6px' }}
                />
              </StyledDatagrid>
            </>
          );
        }}
      />
      <MerchantShowAccountsCreateDialog
        merchantId={merchantId}
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
      />
      <MerchantShowAccountsEditDialog
        merchantId={merchantId}
        onClose={handleCloseEditDialog}
        onDelete={(record: MerchantAccount) => {
          handleOpenDeleteDialog(record);
          setReturnDialog('edit');
        }}
        open={openEditDialog}
        record={recordEditDialog}
      />
      <MerchantShowAccountsShowDialog
        handleOpenCredentialsDialog={handleOpenCredentialsDialogShow}
        handleOpenPermissionsDialog={handleOpenPermissionsDialog}
        onClose={handleCloseShowDialog}
        onDelete={(record: MerchantAccount) => {
          handleOpenDeleteDialog(record);
          setReturnDialog('show');
        }}
        onEdit={handleOpenEditDialog}
        open={openShowDialog}
        record={recordShowDialog}
      />
      {openCredentialsDialogShow && (
        <MerchantShowAccountsCredentialsDialog
          actions={
            <CardActionsButtons
              leftActionsSlot={
                <Button
                  label="К просмотру"
                  onClick={() => {
                    handleCloseCredentialsDialogShow();
                    handleOpenShowDialog(recordCredentialsDialogShow);
                  }}
                  startIcon={<ArrowBack />}
                  variant="outlined"
                />
              }
              rightActionsSlot={
                hasResourcePermissions('merchant_accounts', [
                  Permission.Full,
                  Permission.Update,
                ]) && (
                  <Button
                    label="Редактировать"
                    onClick={() => {
                      handleCloseCredentialsDialogShow();
                      handleOpenEditDialog(recordCredentialsDialogShow);
                    }}
                    variant="outlined"
                  />
                )
              }
            />
          }
          onClose={() => handleCloseCredentialsDialogShow()}
          open={openCredentialsDialogShow}
          record={recordCredentialsDialogShow}
        />
      )}
      {openCredentialsDialogList && (
        <MerchantShowAccountsCredentialsDialog
          actions={
            <CardActionsButtons
              leftActionsSlot={
                hasResourcePermissions('merchant_accounts', [
                  Permission.Full,
                  Permission.Update,
                ]) && (
                  <Button
                    label="Редактировать"
                    onClick={() => {
                      handleCloseCredentialsDialogShow();
                      handleOpenEditDialog(recordCredentialsDialogList);
                    }}
                    variant="outlined"
                  />
                )
              }
            />
          }
          onClose={() => handleCloseCredentialsDialogList()}
          open={openCredentialsDialogList}
          record={recordCredentialsDialogList}
        />
      )}
      <MerchantShowAccountsDeleteDialog
        onClose={closeDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
      {recordPermissoinsDialog && openPermissionsDialog && (
        <UserEntityPermissionsDialog
          entityName={UserEntityPermissionsNames.MerchantAccount}
          onClose={handleClosePermissionsDialog}
          open={openPermissionsDialog}
          record={recordPermissoinsDialog}
          titleCreate="Включение доступа к счету"
          titleEdit="Редактирование доступа к счету"
        />
      )}
    </ListPage>
  );
};
