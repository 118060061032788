import { styled } from '@mui/material/styles';

import { Card } from '../../../../shared/mui/Card';
import { Datagrid } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const CardStyled = styled(Card)({
  width: '100%',
  background: 'transparent',
});

const columnStyle = {
  width: '50%',
  maxWidth: '300px',
  minWidth: '300px',
};

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': columnStyle,
  '& .column-provider': columnStyle,
  '& .column-comment': getColumnStyle(200),
  '& .column-credentials': getColumnStyle(160),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
