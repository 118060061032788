import { useApiContext } from 'context/apiContext';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledDatagrid } from './CommissionsList.styled';
import { CommissionsListFilters } from './CommissionsListFilters';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Commission, Permission } from '../../../types';
import { CommissionsDeleteDialog } from '../components/CommissionsDeleteDialog';

export const CommissionsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  const { user } = useApiContext();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('commissions', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create-comissions" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Комиссии',
        tooltipText: 'Данные, на основе которых рассчитываются комиссии, взимаемые провайдером.',
      }}
    >
      <CommissionsListFilters />
      {user.showAlertEmptyAccess ? (
        <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных комиссий, обратитесь к администратору системы настроек доступа`}</Alert>
      ) : (
        <StyledDatagrid bulkActionButtons={false}>
          <TextField label="ID" sortBy="id" source="originId" />
          <TooltipTextField label="Название" source="name" />
          {hasResourceAccess('providers') && (
            <ReferenceOneField
              label="Провайдер"
              reference="providers"
              sortBy="provider.name"
              source="provider"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          )}
          {hasResourceAccess('merchants') && (
            <ReferenceOneField
              emptyText="—"
              label="Мерчант"
              link="show"
              reference="merchants"
              sortBy="merchant.name"
              source="merchant"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          )}
          {hasResourceAccess('currencies') && (
            <ReferenceOneField
              label="Валюта"
              reference="currencies"
              sortBy="currency.alphaCode"
              source="currency"
              target="id"
            >
              <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
            </ReferenceOneField>
          )}
          {hasResourceAccess('transaction_types') && (
            <ReferenceArrayField
              label="Типы транзакций"
              reference="transaction_types"
              sortable={false}
              source="transactionTypes"
            >
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )}
          <BooleanField label="Нулевая комиссия" source="zeroed" />
          <NumberField emptyText="—" label="Процент" source="percent" />
          <NumberField emptyText="—" label="Фикс." source="fix" />
          <NumberField emptyText="—" label="Макс." source="max" />
          <NumberField emptyText="—" label="Мин." source="min" />
          <DateTimeField label="Дата создания" source="createdAt" />
          <DateTimeField label="Дата редактир." source="updatedAt" />
          <ActionsSlots
            render={(record: Commission) => (
              <>
                {hasResourceAccess('user_actions') && (
                  <UserActionsIconButton entityName="commission" />
                )}
                <ShowDialogIconButton />
                {hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
                  <EditDialogIconButton />
                )}
                {hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                  <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
                )}
              </>
            )}
          />
        </StyledDatagrid>
      )}
      {openRecordModalDelete && (
        <CommissionsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
    </ListPage>
  );
};
