import { OrdersListFilterFormValues } from '../OrdersListNew/OrderListContent';

export const FILTER_RESET_VALUES: OrdersListFilterFormValues = {
  merchant: null,
  appliedDate: [null, null, null],
  amountPenny: {
    lte: null,
    gte: null,
  },
  currency: [],
  direction: null,
  gateway: [],
  provider: null,
  method: [],
  status: [],
  timezone: null,
  customer: null,
};
