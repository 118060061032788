import React, { HTMLAttributeAnchorTarget } from 'react';

import { useCreatePath, useRecordContext, useResourceContext, CreatePathType } from 'react-admin';
import { Link } from 'shared/mui/Link/Link';

export type ReferenceLinkProps = {
  type: CreatePathType;
  children: React.ReactNode;
  target?: HTMLAttributeAnchorTarget | undefined;
};

export const ReferenceLink = ({ type, children, target = '_blank' }: ReferenceLinkProps) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();

  const path = createPath({ resource, id: record.id, type: type });

  return (
    <Link href={path} target={target} underline="none">
      {children}
    </Link>
  );
};
