import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';

type Props = ButtonProps & { label?: string };

const StyledAppApplyFilterButton = styled(Button)({
  width: '100%',
});

export function AppApplyFilterButton({
  color = 'primary',
  variant = 'contained',
  label = 'Применить',
  ...restProps
}: Props): JSX.Element {
  const {
    formState: { isDirty },
  } = useFormContext();
  return (
    <StyledAppApplyFilterButton color={color} disabled={!isDirty} variant={variant} {...restProps}>
      {label}
    </StyledAppApplyFilterButton>
  );
}
