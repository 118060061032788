import { EMPTY_FIELD } from 'constants/emptyField';

import CheckIcon from '@mui/icons-material/Check';
import { useRecordContext } from 'react-admin';
import { appColors } from 'themes/variables';
import { User } from 'types';

export const UsersListCorporateEmailField: React.FC = () => {
  const { corporateEmail } = useRecordContext<User>();

  return corporateEmail ? <CheckIcon htmlColor={appColors.success.light} /> : EMPTY_FIELD;
};
