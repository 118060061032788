import { useState } from 'react';

import { queryClient } from 'App';
import { merchantPayoutControlsCustomersDelete } from 'config/requests';
import { useNotify, useRecordContext } from 'react-admin';
import { DeleteDialog, DeleteDialogProps } from 'shared/mui/Dialogs';

import { useEditPayoutControlContext } from '../../context/EditPayoutControlContext';

export const EditPayoutControlTestClientsExceptionsDeleteDialog: React.FC<
  Omit<DeleteDialogProps, 'title' | 'details'>
> = ({ record, ...props }) => {
  const notify = useNotify();
  const merchant = useRecordContext();
  const [isLoading, setIsLoading] = useState(false);
  const { payoutControl } = useEditPayoutControlContext();

  const handleDeleteCustomer = async () => {
    setIsLoading(true);
    try {
      if (record?.id) {
        await merchantPayoutControlsCustomersDelete(payoutControl.id, Number(record.id));
      } else {
        notify('Ошибка сервера', { type: 'error' });
        return;
      }

      notify('Тестовый клиент успешно удален', { type: 'success' });
      queryClient.refetchQueries(['merchant_payout_controls', merchant?.originId]);
      props.onClose();
    } catch (error: any) {
      notify(error.data.detail || 'Ошибка сервера', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DeleteDialog
      {...props}
      details={`Тестовый клиент ${record?.customerId || ''} будет удален из исключений.`}
      isLoading={isLoading}
      onDelete={handleDeleteCustomer}
      record={record}
      title="Удаление тестового клиента из исключений"
    />
  );
};
