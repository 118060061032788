import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { ChipProps } from 'shared/mui/Chips';
import { getLinkParams } from 'utils';

import { StyledLinkedChipAll } from './ApiClientsListMerchantField.styled';

type Props = ChipProps;

export const ApiClientsMerchantFieldLinkedChipAll: React.FC<Props> = (props) => {
  const record = useRecordContext();
  const linkParams = getLinkParams({
    pathname: `/api_clients/${encodeURIComponent(record.id)}/show`,
  });

  return <StyledLinkedChipAll component={Link} {...props} {...linkParams} />;
};
