import { useGatewayRulesFormContext } from 'components/GatewayRules/components/GatewayRulesForm/context/GatewayRulesFormContext';
import { useInitialExpression } from 'components/GatewayRules/components/GatewayRulesForm/hooks/index';
import { useFieldArray, useFormContext } from 'react-hook-form';

type UseChildrenFieldReturnValues = {
  childrenFields: Record<'id', string>[];
  onAddChildrenClick: () => void;
  onRemoveChildren: (index: number) => () => void;
};

type Props = {
  name: string;
};

export function useChildrenField({ name }: Props): UseChildrenFieldReturnValues {
  const { control } = useFormContext();
  const { expressionParams } = useGatewayRulesFormContext();

  const {
    fields: childrenFields,
    append: appendChildren,
    remove: removeChildren,
  } = useFieldArray({
    name,
    control,
  });

  const initialExpression = useInitialExpression(expressionParams);

  function onAddChildrenClick(): void {
    appendChildren({
      isAnd: true,
      expressions: [initialExpression],
      children: [],
    });
  }

  const onRemoveChildren = (index: number) => (): void => {
    removeChildren(index);
  };

  return {
    onRemoveChildren,
    onAddChildrenClick,
    childrenFields,
  };
}
