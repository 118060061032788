import { IconButton } from 'shared/mui/IconButtons';
import { ListAltIcon } from 'shared/mui/Icons';

type IdInputIconButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const IdInputIconButton = ({ disabled, onClick }: IdInputIconButtonProps) => {
  return (
    <IconButton disabled={disabled} onClick={onClick} sx={{ padding: '4px' }}>
      <ListAltIcon color="primary" />
    </IconButton>
  );
};
