import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useRedirect } from 'react-admin';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ApiClient, Permission } from 'types';

import { ApiClientsShowCommonInfoTable } from './ApiClientsShowCommonInfoTable';
import { UserActionsIconButton } from '../../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

type Props = {
  record?: ApiClient;
};

export const ApiClientsShowCommonInfo: React.FC<Props> = ({ record }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const redirect = useRedirect();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<ApiClient>();

  return (
    <Card sx={{ border: '1px solid rgba(0,0,0,0.12)', width: '600px' }}>
      <CardHeader
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="api_client" key="user-action-link" />]
            : undefined
        }
        title="Общая информация"
      />
      <ApiClientsShowCommonInfoTable />
      <CardActionsButtons
        leftActionsSlot={
          hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) ? (
            <EditLinkButton />
          ) : undefined
        }
        rightActionsSlot={
          hasResourcePermissions('api_clients', [Permission.Full, Permission.Delete]) ? (
            <DeleteButton onClick={() => handleOpenRecordModal()} variant="outlined" />
          ) : undefined
        }
      />
      <DeleteDialog
        details={`Клиент API ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => redirect('list', 'api_clients')}
        open={openRecordModal}
        record={record}
        title="Удаление клиента API"
      />
    </Card>
  );
};
