import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { ArchiveConditionListChip } from 'shared/mui/Chips';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { ArchiveIconButton } from 'shared/mui/IconButtons/ArchiveIconButton/ArchiveIconButton';
import { ExportButton } from 'shared/react-admin/Buttons';
import { LinkTextButton } from 'shared/react-admin/Buttons';
import { DateTimeField, FunctionField, TextField } from 'shared/react-admin/Fields';
import { TooltipTextField } from 'shared/react-admin/Fields/TooltipTextField/TooltipTextField';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledDatagrid } from './CompaniesList.styled';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Company, Permission } from '../../../types';
import { CompaniesFilter } from '../CompaniesFilter';
import { CompaniesArchiveDialog } from '../components/CompaniesArchiveDialog/CompaniesArchiveDialog';
import { CompaniesDeleteDialog } from '../components/CompaniesDeleteDialog/CompaniesDeleteDialog';
import { CompaniesUnarchiveDialog } from '../components/CompaniesUnarchiveDialog/CompaniesUnarchiveDialog';

export const CompaniesList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Company>();
  const {
    record: recordUnarchive,
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Company>();
  const {
    record: recordArchive,
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Company>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('companies', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create_companies" />
        ),
        <ExportButton key="export_companies" />,
      ]}
      headerTitle={{
        titleText: 'Компании',
        tooltipText: 'Юридическое лицо, к которому принадлежит мерчант.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <CompaniesFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextField label="Название" source="name" />
        <FunctionField
          label="Состояние"
          render={({ archive }: Company) => (
            <ArchiveConditionListChip activeLabel="Активна" archive={archive} />
          )}
          sortable={false}
          source="archive"
        />
        <FunctionField
          label="Кол-во мерчантов"
          render={({ merchantCount, id }: Company) => (
            <LinkTextButton
              filter={{ companies: id }}
              label={String(merchantCount)}
              pathname="/merchants"
            />
          )}
          sortable={false}
          source="merchantCount"
        />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <ActionsSlots
          render={(record: Company) => (
            <>
              {hasResourceAccess('user_actions') && <UserActionsIconButton entityName="company" />}
              <ShowDialogIconButton />
              {hasResourcePermissions('companies', [Permission.Full, Permission.Update]) &&
                (record.archive ? (
                  <ArchiveIconButton
                    isRotate
                    onClick={() => handleOpenRecordModalUnarchive(record)}
                  />
                ) : (
                  <EditDialogIconButton />
                ))}
              {hasResourcePermissions('companies', [Permission.Full, Permission.Delete]) &&
                (record.archive ? (
                  <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
                ) : (
                  <ArchiveIconButton onClick={() => handleOpenRecordModalArchive(record)} />
                ))}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <CompaniesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {openRecordModalUnarchive && recordUnarchive && (
        <CompaniesUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={recordUnarchive}
        />
      )}
      {openRecordModalArchive && recordArchive && (
        <CompaniesArchiveDialog
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={recordArchive}
        />
      )}
    </ListPage>
  );
};
