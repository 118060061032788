import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)({
  height: '36px',
  minWidth: '36px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  padding: '3px 16px',
});

export function AppNewShowTableCell({ children, ...restProps }: TableCellProps): JSX.Element {
  return <StyledTableCell {...restProps}>{children}</StyledTableCell>;
}
