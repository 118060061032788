import SyncIcon from '@mui/icons-material/Sync';
import { useNotify } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { ClickAwayListener } from 'shared/mui/ClickAwayListener/ClickAwayListener';
import { IconButton } from 'shared/mui/IconButtons';
import { ListItem } from 'shared/mui/List';
import { List } from 'shared/mui/List/List';
import { Popper } from 'shared/mui/Popper/Popper';
import { appColors } from 'themes/variables';

import {
  NotificationsPopperCard,
  NotificationsPopperCardContent,
} from './NotificationPopperCard.styled';
import { NotificationPopperListItem } from './NotificationPopperListItem/NotificationPopperListItem';
import { NotificationPopperMenuButton } from './NotificationPopperMenuButton';
import { useGetNotifications } from '../hooks/useGetNotifications';

type NotificationsPopperProps = {
  anchorEl?: Element | null;
  onClose: () => void;
  refetchCounter: () => void;
};

export const NotificationsPopper = ({
  anchorEl,
  onClose,
  refetchCounter,
}: NotificationsPopperProps): JSX.Element => {
  const { notifications, refetch: refetchNotifications } = useGetNotifications(Boolean(anchorEl));
  const notify = useNotify();

  return (
    <Popper
      anchorEl={anchorEl}
      open={!!anchorEl}
      placement="bottom-end"
      sx={{
        zIndex: 100,
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <NotificationsPopperCard>
          <CardHeader
            actions={
              <CardActionsButtons
                leftActionsSlot={
                  <IconButton onClick={refetchNotifications}>
                    <SyncIcon />
                  </IconButton>
                }
                rightActionsSlot={
                  notifications?.length ? (
                    <NotificationPopperMenuButton
                      notifications={notifications}
                      onSuccess={() => {
                        refetchCounter();
                        refetchNotifications();
                        notify('Все уведомления отмечены как прочитанные', { type: 'success' });
                      }}
                    />
                  ) : undefined
                }
                sx={{ flex: 1, padding: 0 }}
              />
            }
            sx={{
              borderBottom: `1px solid ${appColors.divider}`,
            }}
            title="Уведомления"
          />
          <NotificationsPopperCardContent>
            <List sx={{ padding: 0 }}>
              {notifications?.length ? (
                notifications.map((notification) => (
                  <NotificationPopperListItem
                    key={notification.id}
                    notification={notification}
                    onSuccess={() => {
                      window.open(notification.body.content.fileUrl, '_blank');
                      refetchNotifications();
                    }}
                  />
                ))
              ) : (
                <ListItem
                  sx={{
                    padding: '16px',
                  }}
                >
                  <Alert severity="info" sx={{ width: '100%' }}>
                    Пока нет уведомлений.
                  </Alert>
                </ListItem>
              )}
            </List>
          </NotificationsPopperCardContent>
        </NotificationsPopperCard>
      </ClickAwayListener>
    </Popper>
  );
};
