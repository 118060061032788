import { useMemo } from 'react';

import { BoxProps } from '@mui/material';
import { RaRecord, useRecordContext } from 'react-admin';

import { StyledBox } from './ActionsSlots.styled';

export type ActionsSlotsProps<RecordType extends RaRecord> = {
  render: (record: RecordType, source?: string) => JSX.Element;
} & BoxProps;

export function ActionsSlots<RecordType extends RaRecord>({
  render,
  ...rest
}: ActionsSlotsProps<RecordType>): JSX.Element | null {
  const record = useRecordContext<RecordType>();

  return useMemo(
    () => (record ? <StyledBox {...rest}>{render(record)}</StyledBox> : null),
    [record, render, rest],
  );
}
