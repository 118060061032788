import CloseIcon from '@mui/icons-material/Close';
import { useGetOne, useRecordContext } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { IconButton } from 'shared/mui/IconButtons';
import { MappingRecord } from 'types';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
} & DialogProps;

export const GatewayRuleMappingDeactivateConfirm: React.FC<Props> = ({
  onConfirm,
  onClose,
  open,
}) => {
  const record = useRecordContext<MappingRecord>();

  const { data: method } = useGetOne('methods', {
    id: record.method,
  });

  const { data: gatewayRule } = useGetOne('gateway_rules', {
    id: record.gatewayRule,
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <Card>
        <CardHeader
          actions={[
            <IconButton aria-label="close" key="close-button" onClick={onClose}>
              <CloseIcon />
            </IconButton>,
          ]}
          title={record.active ? 'Выключение метода' : 'Включение метода'}
        />
        <CardContent>
          {record?.active
            ? `Метод ${method?.name} для правила ${gatewayRule?.name} будет выключен.`
            : `Метод ${method?.name} для правила ${gatewayRule?.name} после подтверждения активации будет работать в соответствии с текущими настройками.`}{' '}
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              key="confirm-button"
              label="Подтвердить"
              onClick={() => {
                onConfirm();
                onClose();
              }}
              variant="contained"
            />,
            <Button key="cancel-button" label="Отменить" onClick={onClose} variant="outlined" />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
