import { useCallback } from 'react';

import { TextInputProps, useInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip/Tooltip/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import { TextInputIntrospected } from '..';
import { generateAlias } from '../../../../../config/requests';
import { ReactComponent as GenerateTextSVG } from '../../../../../images/generateText.svg';

export type TextInputWithAliasIntrospectedProps = {
  target?: string;
  transformValue?: (value: string) => string;
} & TextInputProps;

export function TextInputWithAliasIntrospected({
  target = 'name',
  transformValue,
  ...rest
}: TextInputWithAliasIntrospectedProps): JSX.Element {
  const { field } = useInput({ source: rest.source });
  const targetValue = useWatch({ name: target });

  const handleClick = useCallback(async () => {
    const response = await generateAlias(
      transformValue ? transformValue(targetValue) : targetValue,
    );
    field.onChange(response.data.alias);
  }, [targetValue]);

  return (
    <TextInputIntrospected
      {...rest}
      InputProps={{
        endAdornment: (
          <Tooltip title={<Typography fontSize={12}>Сгенерировать алиас</Typography>}>
            <span>
              <IconButton disabled={!targetValue} onClick={handleClick}>
                <GenerateTextSVG style={{ color: targetValue ? appColors.primary.main : 'gray' }} />
              </IconButton>
            </span>
          </Tooltip>
        ),
      }}
    />
  );
}
