import {
  SaveButton as AdminSaveButton,
  SaveButtonProps as AdminSaveButtonProps,
} from 'react-admin';

export type SaveButtonProps = AdminSaveButtonProps;

export function SaveButton({
  variant = 'contained',
  size = 'medium',
  icon = <></>,
  label = 'Создать',
  ...restProps
}: SaveButtonProps): JSX.Element {
  return <AdminSaveButton icon={icon} label={label} size={size} variant={variant} {...restProps} />;
}
