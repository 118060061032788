import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const defaultStyle120 = getColumnStyle(120);
const defaultStyle140 = getColumnStyle(140);
const defaultStyle200 = getColumnStyle(200);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-id': {
    position: 'relative',
  },
  '& .column-name': getColumnStyle(220),
  '& .column-alias': defaultStyle200,
  '& .column-roles': defaultStyle140,
  '& .column-merchants': getColumnStyle(160),
  '& .column-apiKeys': defaultStyle140,
  '& .column-securityIssues': defaultStyle200,
  '& .column-createdAt': defaultStyle120,
  '& .column-updatedAt': defaultStyle120,
  '& .column-active': {
    textAlign: 'center',
    ...getColumnStyle(74),
  },
  '& .MuiSwitch-root': {
    right: '6px',
    top: '-8px',
  },
});
