import { Dialog as DialogMui, DialogProps as DialogMuiProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type DialogProps = { onClose: () => void } & DialogMuiProps;

export const Dialog = styled(DialogMui)({
  '.MuiBackdrop-root': {
    transition: 'none !important',
  },
  '.MuiPaper-root': {
    overflowY: 'auto',
  },
});
