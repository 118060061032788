import { useFormContext } from 'react-hook-form';

import { FilterApplyButtonStyled } from './FilterApplyButton.styled';
import { ButtonProps } from '../../../Buttons';

export type FilterApplyButtonProps = ButtonProps;

export const FilterApplyButton: React.FC<FilterApplyButtonProps> = ({
  label = 'Применить',
  color = 'primary',
  variant = 'contained',
  ...restProps
}) => {
  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext();

  const disabled = !isDirty || Object.keys(dirtyFields).length === 0;

  return (
    <FilterApplyButtonStyled
      color={color}
      disabled={disabled}
      label={label}
      variant={variant}
      {...restProps}
    />
  );
};
