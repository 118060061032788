import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { appColors } from 'themes/variables';

import { Avatar } from '..';

export const AvatarStyled = styled(Avatar)({
  background: blue[800],
  color: appColors.text.light,
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
});
