import { styled } from '@mui/material/styles';
import { SaveButton, SaveButtonProps } from 'react-admin';

const StyledSaveButton = styled(SaveButton)({
  height: '36px',
});

export function AppEditFormSaveButton({
  variant = 'contained',
  label = 'Сохранить',
  ...restProps
}: SaveButtonProps): JSX.Element {
  return <StyledSaveButton label={label} variant={variant} {...restProps} />;
}
