import React from 'react';

import { SxProps, Theme } from '@mui/material';
import { Box } from 'shared/mui/Box';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';
import { Typography } from 'shared/mui/Typography';

type DeleteStageDialogLoaderProps = {
  sx?: SxProps<Theme>;
};

export const DeleteStageDialogLoader: React.FC<DeleteStageDialogLoaderProps> = ({ sx }) => {
  return (
    <Box display="flex" flexDirection="column" height="108px" justifyContent="center" sx={sx}>
      <Box alignItems="center" display="flex">
        <Typography style={{ paddingRight: '8px' }}>Проверка...</Typography>
        <CircularProgress size={20} />
      </Box>
    </Box>
  );
};
