import React from 'react';

import { Box, Typography } from '@mui/material';
import { appColors } from 'themes/variables';

import { ProtectedMenuItemLink } from '../../../components/Common/Protected/ProtectedMenuItemLink';
import { MultiLevelMenuItem } from '../constants/menuList';

type Props = {
  selectedResourceMenuItem: MultiLevelMenuItem;
  setCurrentResourceMenuItem: (resource: string | null) => void;
};

export function ResourceSidebar({
  selectedResourceMenuItem: { title, items },
  setCurrentResourceMenuItem,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        position: 'absolute',
        minWidth: '256px',
        backgroundColor: appColors.background,
        height: '100%',
        left: '64px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        zIndex: 1,
      }}
    >
      <Typography fontSize="14px" mb={2} ml={3} mt={2}>
        {title}
      </Typography>
      {items.map(({ resource, title }) => (
        <ProtectedMenuItemLink
          className="RaMenuItemLink__submenu"
          key={title}
          onClick={() => setCurrentResourceMenuItem(null)}
          primaryText={title}
          resource={resource}
          to={`/${resource}`}
          tooltipProps={{
            title: false,
            children: <></>,
          }}
        />
      ))}
    </Box>
  );
}
