import React from 'react';

import { CancelButton } from 'shared/mui/Buttons';
import { BaseDialog, DialogProps } from 'shared/mui/Dialogs';
import { MerchantSpacesUsers } from 'types';

import { MerchantSpacesUsersInviteList } from '../MerchantSpacesUsersInviteList';

type Props = {
  users?: MerchantSpacesUsers[];
  onClose: () => void;
} & DialogProps;

export const MerchantSpacesUsersInviteListDialog: React.FC<Props> = ({ users, open, onClose }) => {
  return (
    <BaseDialog
      message={<MerchantSpacesUsersInviteList users={users} />}
      onClose={onClose}
      open={open}
      rightActionsSlot={[
        <CancelButton key="cancel-button" label="Закрыть" onClick={onClose} variant="outlined" />,
      ]}
      title="Приглашение отправлено"
    />
  );
};
