import React, { useState } from 'react';

import { DoDisturbOutlined } from '@mui/icons-material';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';

import { ConfirmDisableSitesAccessDialog } from './ConfirmDisableSitesAccessDialog';
import { MerchantSpacesSiteFieldValues } from '../MerchantSpacesCreate/MerchantSpacesCreate';

type DisableAccessButtonProps = {
  filterValues?: FieldValues;
  resource?: string;
  selectedIds?: string[];
};

export const DisableSitesAccessButton: React.FC<DisableAccessButtonProps> = (props) => {
  const { setValue, getValues } = useFormContext();
  const [open, setOpen] = useState(false);

  const onClose = (): void => {
    setOpen(false);
  };

  const onConfirm = (): void => {
    const sites = getValues('sites');
    sites.forEach((site: MerchantSpacesSiteFieldValues, index: number) => {
      if (props.selectedIds?.includes(site?.record?.id)) {
        setValue(`sites.${index}.active`, false);
      }
    });

    onClose();
  };

  const onClick = (): void => {
    setOpen(true);
  };

  return (
    <>
      <Button label="Отключить доступ" onClick={onClick} startIcon={<DoDisturbOutlined />} />
      <ConfirmDisableSitesAccessDialog
        onClose={onClose}
        onConfirm={onConfirm}
        open={open}
        selectedIds={props.selectedIds}
        sites={getValues('sites')}
      />
    </>
  );
};
