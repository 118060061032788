import { useEffect } from 'react';

import { TextInputProps, useInput } from 'react-admin';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip/Tooltip/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { ReactComponent as GenerateKeySVG } from '../../../../../images/generateKey.svg';
import { TextInputIntrospected } from '../../TextInputs/TextInputIntrospected/TextInputIntrospected';

export type KeyInputIntrospectedProps = {
  onClickGenerateKey: () => void;
} & TextInputProps;

export const KeyInputIntrospected = ({
  onClickGenerateKey,
  value,
  ...rest
}: KeyInputIntrospectedProps): JSX.Element => {
  const { field } = useInput({ source: rest.source });
  useEffect(() => {
    if (value) {
      field.onChange(value);
    }
  }, [value]);

  return (
    <TextInputIntrospected
      {...rest}
      InputProps={{
        endAdornment: (
          <Tooltip title={<Typography fontSize={12}>Сгенерировать ключ</Typography>}>
            <span>
              <IconButton onClick={onClickGenerateKey}>
                <GenerateKeySVG />
              </IconButton>
            </span>
          </Tooltip>
        ),
      }}
    />
  );
};
