import { queryClient } from 'App';
import { deleteUserEntityPermissions } from 'config/requests';
import { useNotify } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { UserEntityPermissionsNames } from '../constants/userEntityPermissionsNames';
import {
  UserEntityPermission,
  UserEntityPermissionFormValues,
} from '../UserEntityPermissionsDialogCreate/UserEntityPermissionsDialogCreate';

type UseUserEntityPermissionsFieldArrayReturnValues = {
  fields: UserEntityPermission[];
  onAddField: () => void;
  onRemoveField: (index: number) => Promise<any>;
};

export function useUserEntityPermissionsFieldArray(
  recordId: string,
  entityName: UserEntityPermissionsNames,
): UseUserEntityPermissionsFieldArrayReturnValues {
  const { control } = useFormContext<UserEntityPermissionFormValues>();
  const notify = useNotify();

  const { fields, append, remove } = useFieldArray({
    name: 'permissions',
    control,
    keyName: '_key',
  });

  const onAddField = (): void => {
    append({
      entity: entityName,
      rowId: Number(recordId),
      user: null,
      expiredAt: null,
    });
  };

  const onRemoveField = async (index: number): Promise<any> => {
    const permissionId = fields[index].id;
    if (permissionId) {
      try {
        await deleteUserEntityPermissions(permissionId);
        await queryClient.refetchQueries(['user_entity_permissions']);
        notify('Настройка доступа успешно удалена!', { type: 'success' });
      } catch (error) {
        notify(`Ошибка: ${error as string}` || 'Ошибка удаления настройки доступа', {
          type: 'error',
        });
      }
      return;
    }
    remove(index);
  };

  return {
    fields,
    onAddField,
    onRemoveField,
  };
}
