import { styled } from '@mui/material/styles';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';

import { getColumnStyle } from '../../utils';

export const DatagridStyled = styled(DatagridConfigurable)({
  '& .column-originId': getColumnStyle(96),
  '& .column-impactToBalance': getColumnStyle(107),
  '& .column-rejectReason': getColumnStyle(240),
  '& .column-change-status': getColumnStyle(280),
  '& .column-adjustmentType': getColumnStyle(264),
  '& .column-order': getColumnStyle(170),
  '& .column-amount': { ...getColumnStyle(170), textAlign: 'right' },
});
