import { EXPORT_FORMAT_NAMES, ExportFormats } from 'constants/exportFormats';

import { SelectChangeEvent } from '@mui/material';

import { StyledButton, StyledSelect } from './ExportFormatsButton.styled';
import { ExportFormat } from '../../../../types';
import { MenuItem } from '../../MenuItem/MenuItem';

export type ExportFormatsButtonProps = {
  disabled?: boolean;
  exportFormat: ExportFormat;
  onClick: () => void;
  onChangeFormat: (exportFormat: ExportFormat) => void;
};

export const ExportFormatsButton: React.FC<ExportFormatsButtonProps> = ({
  disabled,
  exportFormat,
  onClick,
  onChangeFormat,
}) => {
  function onChange(event: SelectChangeEvent<unknown>) {
    onChangeFormat(event.target.value as ExportFormat);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
      <StyledButton
        color="primary"
        disabled={disabled}
        label={`Выгрузить в ${EXPORT_FORMAT_NAMES[exportFormat]}`}
        onClick={onClick}
        size="medium"
        variant="contained"
      />
      <StyledSelect
        MenuProps={{
          PaperProps: {
            sx: {
              transform:
                exportFormat === ExportFormats.Excel
                  ? 'translateX(-38px) !important'
                  : 'translateX(-30px) !important',
            },
          },
        }}
        disabled={disabled}
        onChange={onChange}
        value={exportFormat}
      >
        <MenuItem value={ExportFormats.Excel}>Выгрузить в Excel</MenuItem>
        <MenuItem value={ExportFormats.CSV}>Выгрузить в CSV</MenuItem>
      </StyledSelect>
    </div>
  );
};
