import { useFormContext } from 'react-hook-form';

import { Chip } from '../../../../Chips';
import {
  DEFAULT_DATE_TIME_MASK,
  DEFAULT_TIMEZONE,
} from '../../../../DatePicker/config/defaultValues';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { fromTimestampRangeToString } from '../../../../DatePicker/utils/fromTimestampRangeToString';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

export type FilterDateTimeRangeChipProps = {
  label: string;
  name: string;
  children: React.ReactNode;
};

export const FilterDateTimeRangeTimezoneChip: React.FC<FilterDateTimeRangeChipProps> = ({
  children,
  label,
  name,
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();

  const { appliedFilters, setAppliedFilter } = useFilterContext();
  const { reset, getValues, setValue } = useFormContext();

  if (!appliedFilters[name] || (!appliedFilters[name]?.[0] && !appliedFilters[name]?.[1])) {
    return <></>;
  }

  function onApply() {
    setAppliedFilter(name, getValues(name));
  }

  function onReset() {
    setAppliedFilter(name, appliedFilters?.[name]);
    setValue(name, appliedFilters?.[name]);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApply}
        onClose={handleClose}
        onReset={onReset}
        open={open}
      >
        {children}
      </PopoverChip>
      <Chip
        label={`${label}: ${fromTimestampRangeToString(appliedFilters?.[name], DEFAULT_DATE_TIME_MASK, appliedFilters[name]?.[2] || DEFAULT_TIMEZONE)}`}
        onClick={handleClick}
        onDelete={() => {
          reset({ [name]: [null, null, null] }, { keepDirty: true });
          setAppliedFilter(name, [null, null, null]);
        }}
        size="medium"
      />
    </>
  );
};
