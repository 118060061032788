import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';

export const WrapperStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '16px 24px',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  border: '1px solid #00000014',
  gap: '16px',
});

export const TitleWrapperStyled = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ChartWrapperStyled = styled(Box)({
  height: '16px',
  width: '100%',
  display: 'flex',
});

export const LegendWrapperStyled = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ButtonStyled = styled(Button)({
  letterSpacing: 'initial',
  textTransform: 'initial',
  fontWeight: 'initial',
  color: '#000000',
  fontSize: '16px',
});
