import { styled } from '@mui/material/styles';
import { Pagination } from 'react-admin';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .column-id': {
    maxWidth: '100%',
    minWidth: '40px',
  },
  '& .column-undefined': {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
  },
  '& .column-createdAt': getColumnStyle(133),
  '& .column-updatedAt': getColumnStyle(181),
});

export const StyledPagination = styled(Pagination)({
  display: 'block',
  marginBottom: '24px',
});
