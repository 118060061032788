import { styled } from '@mui/material/styles';
import { Dialog } from 'shared/mui/Dialogs';

export const ShowDialogStyled = styled(Dialog)({
  '.RaShow-main': {
    margin: '0!important',
  },
  '.MuiCardContent-root': {
    padding: 0,
  },
});
