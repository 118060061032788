import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const commonStyle = getColumnStyle();

const columnStyle = {
  width: '50%',
  maxWidth: '300px',
  minWidth: '300px',
};

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': columnStyle,
  '& .column-alias': columnStyle,
  '& .column-archive': getColumnStyle(131),
  '& .column-createdAt': commonStyle,
  '& .column-updatedAt': commonStyle,
  '& .column-notificationKey': getColumnStyle(165),
  '& .column-companies': {
    width: '180px',
  },
});
