import { MAX_AMOUNT } from '../constants';

export const splitAmountFilterValue = (
  amount?: string,
): { amountFrom: string; amountTo: string } => {
  if (!amount) {
    return { amountFrom: '', amountTo: '' };
  }
  let [amountFrom, amountTo] = amount.split('..');

  if (amountFrom === '0') {
    amountFrom = '';
  }
  if (amountTo === MAX_AMOUNT) {
    amountTo = '';
  }

  return { amountFrom, amountTo };
};
