import ImageIcon from '@mui/icons-material/Image';
import { useGetOne } from 'react-admin';
import { Card } from 'shared/mui/Card';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';

type Props = {
  methodLogoId: string | undefined;
  isLoadingMethod: boolean;
  isForm?: boolean;
};

export const GatewayRuleMappingMethod: React.FC<Props> = ({
  methodLogoId,
  isLoadingMethod,
  isForm,
}) => {
  const { data: logo, isLoading: isLoadingLogo } = useGetOne(
    'method_logos',
    { id: methodLogoId },
    { enabled: !isLoadingMethod && !!methodLogoId },
  );

  const renderImage = () => {
    if (isLoadingLogo || isLoadingMethod) {
      return <CircularProgress size={16} />;
    }
    return !logo?.filePath ? (
      <ImageIcon sx={{ color: 'rgba(0,0,0,0.26)', objectFit: 'contain' }} />
    ) : (
      <img
        alt={logo?.name}
        src={logo?.filePath}
        style={{ width: '48px', height: '48px', objectFit: 'contain' }}
      />
    );
  };

  return (
    <Card
      sx={{
        border: `1px solid ${isForm ? 'rgba(0,0,0,0.23)' : 'rgba(0,0,0,0.12)'}`,
        width: '56px',
        height: '56px',
        marginRight: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renderImage()}
    </Card>
  );
};
