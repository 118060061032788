import React from 'react';

import { Box } from 'shared/mui/Box';
import { CardAdditionalButtons } from 'shared/mui/Card/CardAdditionalButtons/CardAdditionalButtons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Divider } from 'shared/mui/Divider/Divider';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Row } from 'shared/mui/Row/Row';
import { Typography } from 'shared/mui/Typography';
import { AutocompleteInput, BooleanInput, SelectInput, TextInput } from 'shared/react-admin/Inputs';
import { SitesAvailabilityForm } from 'shared/widgets/SitesAvailabilityForm/SitesAvailabilityForm';

import { useEditSitesFields } from './hooks/useEditSitesFields';
import { MerchantSpacesRoleOption } from '../components/MerchantSpacesRoleOption';
import { useMerchantSpaces } from '../hooks/useMerchantSpaces';
import { useMerchantSpacesUsersRoleChoices } from '../hooks/useMerchantSpacesUsersRoleChoices';

export const MerchantSpacesUsersEditForm: React.FC = () => {
  const { merchantSpacesUsersRoleChoices } = useMerchantSpacesUsersRoleChoices();
  const { merchantSpaces, isLoading } = useMerchantSpaces();

  const { fields, isLoadingFields } = useEditSitesFields();

  function renderAvailabilityForm() {
    if (isLoadingFields) {
      return <LinearProgress />;
    }

    return <SitesAvailabilityForm fields={fields} />;
  }

  return (
    <>
      <CardAdditionalButtons>
        <BooleanInput label="Активно" source="active" />
      </CardAdditionalButtons>
      <CardContent>
        <Row>
          <TextInput fullWidth label="Имя" size="medium" source="firstName" variant="outlined" />
          <TextInput fullWidth label="Фамилия" size="medium" source="lastName" variant="outlined" />
        </Row>
        <Row>
          <TextInput fullWidth label="Email" size="medium" source="email" variant="outlined" />
          <TextInput
            fullWidth
            label="Номер телефона"
            size="medium"
            source="phoneNumber"
            variant="outlined"
          />
        </Row>
        <Row>
          <TextInput fullWidth label="Пароль" size="medium" source="password" variant="outlined" />
        </Row>
        <Row>
          <AutocompleteInput
            choices={merchantSpaces?.items}
            disabled={true}
            filterToQuery={(searchText: string) => {
              return { spaceName: searchText };
            }}
            fullWidth
            isLoading={isLoading}
            label="Личный кабинет"
            optionText="spaceName"
            optionValue="id"
            size="medium"
            source="merchantSpaceId"
            variant="outlined"
          />
          <SelectInput
            choices={merchantSpacesUsersRoleChoices}
            fullWidth
            label="Роль"
            optionText={<MerchantSpacesRoleOption />}
            size="medium"
            source="role"
            variant="outlined"
          />
        </Row>
        <Box>
          <Divider />
          <Typography sx={{ marginBottom: '24px', marginTop: '24px' }} variant="h6">
            Настройка доступности мерчантов
          </Typography>
          {renderAvailabilityForm()}
        </Box>
      </CardContent>
    </>
  );
};
