import { Box, styled } from '@mui/material';

import { modalBase } from '../../../themes/variables';

export const OrdersRefundChargebackBox = styled(Box)({
  ...modalBase,
  top: '45%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
