import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';
import { MethodTypes } from 'constants/methodTypes';

import { useState } from 'react';

import { SubmissionErrors, hydraSchemaAnalyzer } from '@api-platform/admin';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { dataProvider } from 'providers/dataProvider';
import {
  HttpError,
  useGetList,
  useGetMany,
  useGetManyReference,
  useGetOne,
  useNotify,
} from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EditPage } from 'shared/react-admin/Pages/EditPage/EditPage';
import { GatewayMethod, Method } from 'types';

import { GatewayEditFormProvider } from './context/GatewayEditFormContext';
import { GatewaysEditForm } from './GatewaysEditForm';
import { GatewayEditFormValues, useGatewayEditForm } from './hooks/useGatewayEditForm';
import { formatGatewayPilotModeSchedule } from './utils/formatGatewayPilotModeSchedule';
import { UNLIMITED_PAGINATION_PARAMS } from '../../../constants';
import { getId } from '../../../utils';
import { getIntervalConfig } from '../utils/getAttemptIntervalConfig';

export const GatewaysEdit: React.FC = () => {
  const { id } = useParams();
  const notify = useNotify();
  const { toRoot } = useNavigateToRoot();
  const { data: gateway } = useGetOne('gateways', { id });
  const { data: gatewayMethods } = useGetManyReference<GatewayMethod>('gateway_methods', {
    target: 'gateway',
    id,
  });
  const { data: initialMethods } = useGetMany<Method>('methods', {
    ids: gatewayMethods?.map((gatewayMethod) => gatewayMethod.method),
  });

  const { data: gatewayPilotModeSchedule } = useGetOne(
    'gateway_pilot_mode_schedules',
    { id: gateway?.gatewayPilotModeSchedule },
    { enabled: !!gateway?.gatewayPilotModeSchedule },
  );

  const { form, onSubmit } = useGatewayEditForm({
    gateway,
    gatewayMethods,
    gatewayPilotModeSchedule,
    submitHandler,
  });

  const { data: allMethods } = useGetList<Method>('methods', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
    filter: form.watch('pcidss')
      ? {
          methodType: {
            alias: MethodTypes.PCIDSSBankCard,
          },
        }
      : {},
  });

  const [errors, setErrors] = useState<SubmissionErrors | undefined>();

  async function submitHandler(formValues: GatewayEditFormValues) {
    if (!id) {
      return;
    }

    const isGatewayDisabledStatus =
      formValues.status === GATEWAY_STATUSES[GatewayStatus.Disabled].id;

    const formattedFormValues = {
      ...formValues,
      id: parseInt(getId(id), 10),
      deactivationReason: isGatewayDisabledStatus ? formValues.deactivationReason : null,
      gatewayPilotModeSchedule: formValues.gatewayPilotModeSchedule
        ? formatGatewayPilotModeSchedule(formValues.gatewayPilotModeSchedule, id)
        : formValues.gatewayPilotModeSchedule,
      attemptIntervalConfig: getIntervalConfig(formValues.attemptIntervalConfig),
    };

    try {
      await dataProvider.update('gateways', {
        id,
        data: formattedFormValues,
        previousData: gateway,
      });
      notify('Шлюз отредактирован!', { type: 'info' });
      toRoot();
    } catch (error) {
      notify('Ошибка: Форма заполнена неверно, проверьте, пожалуйста, ошибки', { type: 'error' });
      const submissionErrors = hydraSchemaAnalyzer().getSubmissionErrors(error as HttpError);

      if (submissionErrors) {
        setErrors(submissionErrors);
      }
    }
  }

  return (
    <EditPage listName="Шлюзы" title="Редактирование шлюза">
      <GatewayEditFormProvider
        value={{
          initialMethods,
          allMethods,
          gatewayMethods,
        }}
      >
        <FormProvider {...form}>
          <GatewaysEditForm errors={errors} onSubmit={onSubmit} />
        </FormProvider>
      </GatewayEditFormProvider>
    </EditPage>
  );
};
