import { Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)({
  '.MuiBackdrop-root': {
    transition: 'none !important',
  },
  '.MuiCardContent-root': {
    padding: '0 24px 24px 24px',
  },
});

export function AppDialog({
  children,
  maxWidth = 'md',
  fullWidth = true,
  ...restProps
}: DialogProps): JSX.Element {
  return (
    <StyledDialog
      TransitionComponent={({ children }) => children}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...restProps}
    >
      {children}
    </StyledDialog>
  );
}
