import { useGetOne } from 'react-admin';
import { ProviderAccount } from 'types';

export function useProviderAccountName(record: ProviderAccount | undefined) {
  const { data: company } = useGetOne('companies', { id: record?.company }, { enabled: !!record });
  const { data: provider } = useGetOne(
    'providers',
    { id: record?.provider },
    { enabled: !!record },
  );

  return { providerAccountName: `${company?.name || ''} | ${provider?.name || ''}` };
}
