import VisibilityIcon from '@mui/icons-material/Visibility';
import { RecordContextProvider, useGetOne } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton, UserEntityPermissionsIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { EMPTY_FIELD } from '../../../../../constants';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { MerchantCredentials, Permission } from '../../../../../types';

type Props = {
  open: boolean;
  onEdit: (record: MerchantCredentials) => void;
  onDelete: (record: MerchantCredentials) => void;
  handleOpenCredentialsDialog: (record: MerchantCredentials) => void;
  handleOpenPermissionsDialog: (record: MerchantCredentials) => void;
  onClose: () => void;
  id: string | undefined;
};

export const MerchantsShowCredentialsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  onDelete,
  id,
  handleOpenPermissionsDialog,
  handleOpenCredentialsDialog,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const { data } = useGetOne<MerchantCredentials>(
    'merchant_credentials',
    {
      id: id || '',
    },
    {
      enabled: Boolean(open && id),
    },
  );

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={data}>
        <CardHeader
          actions={[
            hasResourceAccess('user_entity_permissions') && (
              <UserEntityPermissionsIconButton
                key="user-action-link"
                onClick={() => {
                  data && handleOpenPermissionsDialog(data);
                  onClose();
                }}
              />
            ),
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="merchant_credentials" key="user-action-link" />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр реквизитов доступа"
        />
        <CardTable sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRowWrapper title="ID">
              <TextField source="originId" />
            </TableRowWrapper>
            <TableRowWrapper title="Название">
              <TextField source="name" />
            </TableRowWrapper>
            <TableRowWrapper title="Провайдер">
              <ReferenceOneField
                label="Провайдер"
                reference="providers"
                source="provider"
                target="id"
              >
                <TextField emptyText={EMPTY_FIELD} label="Провайдер" source="name" />
              </ReferenceOneField>
            </TableRowWrapper>
            <TableRowWrapper title="Комментарий">
              <TextField
                emptyText={EMPTY_FIELD}
                source="comment"
                sx={{
                  overflow: 'hidden',
                  display: 'block',
                  wordBreak: 'break-all',
                }}
              />
            </TableRowWrapper>
            <TableRowWrapper title="Реквизиты доступа {json}">
              <Button
                label="Показать"
                onClick={() => {
                  data && handleOpenCredentialsDialog(data);
                  onClose();
                }}
                startIcon={<VisibilityIcon />}
              />
            </TableRowWrapper>
            <TableRowWrapper title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('merchant_credentials', [Permission.Full, Permission.Update]) &&
            !data?.locked && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  data && onEdit(data);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            hasResourcePermissions('merchant_credentials', [
              Permission.Full,
              Permission.Delete,
            ]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  data && onDelete(data);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
