import { queryClient } from 'App';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { MerchantNotification } from 'types';

import { useEditMerchantNotification } from '../../hooks/useEditMerchantNotification';

type Props = {
  notificationTransportName: string;
  onClose: VoidFunction;
  notification: MerchantNotification;
} & DialogProps;

export const MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOn: React.FC<Props> = ({
  notificationTransportName,
  open,
  onClose,
  notification,
}) => {
  const { isLoading, mutate } = useEditMerchantNotification(notification.id, () => {
    onClose();
    queryClient.invalidateQueries('merchant_notification_settings');
  });

  return (
    <Dialog open={open}>
      <Card sx={{ width: '600px' }}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Включение настроек уведомления"
        />
        <CardContent>
          <Typography>
            {`Уведомление ID ${notification.id} с типом ${notificationTransportName} будет временно включено.`}
          </Typography>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              isLoading={isLoading}
              key="save-button"
              label="Включить"
              onClick={() => mutate({ active: true })}
              variant="contained"
            />,
            <CancelButton
              key="cancel-button"
              label="отменить"
              onClick={onClose}
              variant="outlined"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
