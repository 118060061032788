import { green, orange } from '@mui/material/colors';
import { Chip } from 'shared/mui/Chips';

export const CompanyShowDialogConditionChip: React.FC<{ archive: boolean }> = ({ archive }) => (
  <Chip
    color="primary"
    label={archive ? 'В архиве' : 'Активна'}
    size="small"
    sx={{
      background: archive ? orange[800] : green[800],
    }}
  />
);
