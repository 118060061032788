import {
  FilterAutocompleteChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { ACCESS_SELECT_ITEMS, ACCESS_TITLES } from '../constants/accessSelectItems';
import { SECURITY_ISSUES, SECURITY_ISSUES_SELECT_ITEMS } from '../constants/securityIssues';

export const ApiClientsListFilterChips: React.FC = () => {
  return (
    <>
      <FilterTextChip label="Название" name="name">
        <TextInputShrink
          helperText={false}
          label="Название"
          resettable
          source="name"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip
        getName={(filterValue: keyof typeof ACCESS_TITLES) => ACCESS_TITLES[filterValue]}
        label="Доступ"
        name="roles"
      >
        <SelectInputShrink
          choices={ACCESS_SELECT_ITEMS}
          defaultValue={null}
          fullWidth
          helperText={false}
          id="roles"
          label="Доступ"
          name="roles"
          optionText="label"
          optionValue="value"
          source="roles"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterAutocompleteChip label="Мерчант" name="merchants" resource="merchants">
        <ReferenceInput perPage={100} reference="merchants" source="merchants">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Мерчант"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterTextChip
        getName={(filterValue: keyof typeof SECURITY_ISSUES) => SECURITY_ISSUES[filterValue]?.title}
        label="Уведомление безопасности"
        name="securityIssues"
      >
        <SelectInputShrink
          choices={SECURITY_ISSUES_SELECT_ITEMS}
          defaultValue={null}
          fullWidth
          helperText={false}
          id="securityIssues"
          label="Уведомление безопасности"
          name="securityIssues"
          source="securityIssues"
          variant="outlined"
        />
      </FilterTextChip>
    </>
  );
};
