import { AxiosError } from 'axios';
import {
  updateGatewayConversionSettingsNotify,
  UpdateGatewayConversionSettingsNotifyParams,
} from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';

export function useUpdateGatewayConversionSettingsNotify() {
  const notify = useNotify();

  const mutation = useMutation(
    ({ id, data }: { id: number; data: UpdateGatewayConversionSettingsNotifyParams }) =>
      updateGatewayConversionSettingsNotify(id, data),
    {
      onSuccess: async () => {
        notify('Уведомления успешно отредактированы', { type: 'success' });
      },
      onError: (e) => {
        const error = e as AxiosError;
        notify(`Ошибка: ${error.message}`, { type: 'error' });
      },
    },
  );

  return { updateGatewayConversionSettingsNotify: mutation };
}
