import { Percent } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { IconLabel } from 'shared/mui/Labels/IconLabel/IconLabel';
import { ExportButton } from 'shared/react-admin/Buttons';
import {
  BooleanField,
  FunctionField,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { ProvidersFilter } from './ProvidersFilter';
import { StyledDatagrid } from './ProvidersList.styled';
import { ProvidersListCommissions } from './ProvidersListComissions';
import { ProvidersListWhiteIp } from './ProvidersListWhiteIp';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Permission, Provider } from '../../../types';
import { ProvidersDeleteDialog } from '../components/ProvidersDeleteDialog/ProvidersDeleteDialog';

export const ProvidersList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('providers', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create-providers" />
        ),
        <ExportButton key="export-providers" />,
      ]}
      headerTitle={{
        titleText: 'Провайдеры',
        tooltipText:
          'Сервис, предоставляющий услуги проведения и учета платежей в Интернет с помощью одного или нескольких методов.',
      }}
    >
      <ProvidersFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextField label="Название" source="name" />
        <TooltipTextField label="Алиас" source="alias" />
        <BooleanField label="Активен" source="active" />
        {hasResourceAccess('commissions') && (
          <FunctionField
            label={<IconLabel icon={Percent} label="Комиссии" />}
            render={() => <ProvidersListCommissions />}
          />
        )}
        {hasResourceAccess('provider_white_ip_addresses') && (
          <FunctionField
            label={<IconLabel icon={ListAltIcon} label="Белые IP" />}
            render={() => <ProvidersListWhiteIp />}
          />
        )}
        <ActionsSlots
          render={(record: Provider) => (
            <>
              {hasResourceAccess('user_actions') && <UserActionsIconButton entityName="provider" />}
              <ShowDialogIconButton />
              {hasResourcePermissions('providers', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
              {hasResourcePermissions('providers', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <ProvidersDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
    </ListPage>
  );
};
