import { ReactElement } from 'react';

import { RecordContextProvider } from 'react-admin';
import { ChipField, FunctionField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { Order } from '../../../types';

type Props = {
  label?: string | ReactElement | boolean;
};

export const OrderAdjustmentsReportMerchantField: React.FC<Props> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={() => {
        return (
          <ReferenceOneField
            link={false}
            reference="orders"
            sortable={false}
            source="order"
            target="id"
          >
            <FunctionField
              render={(record: Order) => {
                return (
                  <RecordContextProvider value={record}>
                    <ReferenceField link={false} reference="merchants" source="merchant">
                      <ChipField source="name" />
                    </ReferenceField>
                  </RecordContextProvider>
                );
              }}
            />
          </ReferenceOneField>
        );
      }}
      source="merchant"
    />
  );
};
