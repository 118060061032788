import React, { createContext, useContext } from 'react';

import { DefaultValues } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export const FilterContext = createContext<{
  appliedFilters: FieldValues | DefaultValues<FieldValues>;
  setAppliedFilter: (name: string, value: any) => void;
}>({
  appliedFilters: {} as FieldValues,
  setAppliedFilter: () => undefined,
});

type Props = {
  children: React.ReactNode;
  appliedFilters: FieldValues | DefaultValues<FieldValues>;
  setAppliedFilter: (name: string, value: any) => void;
};

export const FilterContextProvider: React.FC<Props> = ({
  children,
  appliedFilters,
  setAppliedFilter,
}): JSX.Element => {
  return (
    <FilterContext.Provider value={{ setAppliedFilter, appliedFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export function useFilterContext() {
  const { appliedFilters, setAppliedFilter } = useContext(FilterContext);

  return { appliedFilters, setAppliedFilter };
}
