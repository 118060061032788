export function getLimitTagsText(arr: string[], limitTags?: number) {
  const newArr = [...arr];

  if (limitTags && limitTags > -1 && Array.isArray(newArr)) {
    const more = newArr.length - limitTags;
    const value = newArr.reverse().splice(0, limitTags).join(' или ');
    return more > 0 ? `${value} +${more}` : value;
  }

  return newArr.join(' или ');
}
