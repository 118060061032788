import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, SimpleForm, useEditController, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditDialog } from 'shared/react-admin/Dialogs/EditDialog/EditDialog';
import { Commission, Permission } from 'types';

import { CommissionEditForm } from './CommissionEditForm';
import { CommissionsDeleteDialog } from '../components/CommissionsDeleteDialog';
import { commissionSchema } from '../schemas/schema';

const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
  return {
    ...data,
    min: data.min ? data.min + '' : null,
    max: data.max ? data.max + '' : null,
    fix: data.fix ? data.fix + '' : null,
    percent: data.percent ? data.percent + '' : null,
  };
};

export const CommissionsEdit = (): JSX.Element => {
  const notify = useNotify();
  const { goBack } = useGoBack();
  const [errors, setErrors] = useState<string[]>([]);
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const { toRoot } = useNavigateToRoot();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  const onClose = () => {
    handleCloseRecordModalDelete();
    toRoot();
  };
  const onSuccess = async () => {
    await queryClient.invalidateQueries(['commissions']);
    notify('ra.notification.updated', {
      type: 'info',
      messageArgs: { smart_count: 1 },
    });
    goBack();
  };

  return (
    <>
      <EditDialog
        mutationMode="pessimistic"
        mutationOptions={{
          onError: (error: any) => {
            setErrors(error?.message.split('\n'));
          },
          onSuccess,
        }}
        open={!openRecordModalDelete}
        title="Редактирование комиссии"
        transform={transform}
      >
        <SimpleForm
          resolver={yupResolver<FieldValues>(commissionSchema)}
          toolbar={
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton alwaysEnable key="save-button" label="Сохранить" />,
                <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
              ]}
              rightActionsSlot={
                hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) ? (
                  <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
                ) : undefined
              }
            />
          }
        >
          <CommissionEditForm errors={errors} />
        </SimpleForm>
      </EditDialog>
      {openRecordModalDelete && (
        <CommissionsDeleteDialog onClose={onClose} open={openRecordModalDelete} record={record} />
      )}
    </>
  );
};
