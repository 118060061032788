import { Introspecter } from '@api-platform/admin';
import { useResourceContext } from 'react-admin';

import { SelectInputWithGuesser } from './SelectInputWithGuesser';
import { SelectInputProps } from '../SelectInput/SelectInput';

export type SelectInputIntrospectedProps = SelectInputProps;

export const SelectInputIntrospected = (props: SelectInputProps): JSX.Element => {
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={SelectInputWithGuesser as any}
      includeDeprecated={true}
      resource={resource}
      {...props}
    />
  );
};
