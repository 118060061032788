import { MenuItemLink, MenuItemLinkProps } from 'react-admin';

import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';

type Props = {
  resource: string;
} & MenuItemLinkProps;

export function ProtectedMenuItemLink({ resource, ...restProps }: Props): JSX.Element | null {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  if (
    !hasResourceAccess(resource) ||
    !hasResourcePermissions(resource, [Permission.Full, Permission.Read])
  ) {
    return null;
  }

  return <MenuItemLink {...restProps} />;
}
