import { styled } from '@mui/material/styles';
import { NullableBooleanInput } from 'react-admin';

export const StyledNullableBooleanInput = styled(NullableBooleanInput)({
  '&.MuiFormControl-root': {
    marginTop: 0,
  },
  '&.MuiFormHelperText-root': {
    marginTop: 0,
  },
});
