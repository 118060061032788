import React, { createContext, useContext, useState } from 'react';

import { DEFAULT_USER } from '../constants';
import { User } from '../types';

export const APIContext = createContext<{ user: User; setUser: (user: User) => void }>({
  user: DEFAULT_USER,
  setUser: () => undefined,
});

type Props = {
  children: React.ReactNode;
};

export const APIContextProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [user, setUser] = useState<User>(DEFAULT_USER);

  return <APIContext.Provider value={{ user, setUser }}>{children}</APIContext.Provider>;
};

export function useApiContext(): { user: User } {
  const { user } = useContext(APIContext);
  return { user };
}
