import { Role } from '../../../constants/roles';

export const ACCESS_TITLES = {
  [Role.Merchant]: 'Мерчант',
  [Role.Service]: 'Сервис',
};

export const ACCESS_SELECT_ITEMS = [
  { value: Role.Merchant, label: 'Мерчант' },
  { value: Role.Service, label: 'Сервис' },
];
