import { queryClient } from 'App';
import { getUserEntityPermissions } from 'config/requests';
import {
  Form,
  LinearProgress,
  RecordContextProvider,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useQuery } from 'react-query';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { UserEntityPermissionsDialogForm } from '../components/UserEntityPermissionsForm/UserEntityPermissionsDialogForm';
import {
  USER_ENTITY_PERMISSIONS_RESOURCES,
  UserEntityPermissionsNames,
} from '../constants/userEntityPermissionsNames';
import { UserEntityPermissionFormValues } from '../UserEntityPermissionsDialogCreate/UserEntityPermissionsDialogCreate';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  recordId: string;
  recordName: string;
  entityName: UserEntityPermissionsNames;
};

export const UserEntityPermissionsDialogEdit: React.FC<Props> = ({
  open,
  onClose,
  title,
  recordId,
  recordName,
  entityName,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data: permissions, isLoading } = useQuery(['user_entity_permissions', recordId], () =>
    getUserEntityPermissions({
      entity: entityName,
      rowId: Number(recordId),
    }),
  );
  const onSubmit = async (formValues: UserEntityPermissionFormValues) => {
    const createdPermissions = formValues.permissions.filter((permission) => !permission.id);
    if (!createdPermissions.length) {
      await queryClient.invalidateQueries([USER_ENTITY_PERMISSIONS_RESOURCES[entityName]]);
      onClose();
      return;
    }
    try {
      await Promise.all(
        createdPermissions.map((createdPermission) =>
          dataProvider.create('user_entity_permissions', {
            data: createdPermission,
          }),
        ),
      );
      await queryClient.invalidateQueries([USER_ENTITY_PERMISSIONS_RESOURCES[entityName]]);
      notify('Все настройки доступа успешно созданы!', { type: 'success' });
      onClose();
    } catch (error) {
      notify((error as Error)?.message || 'Ошибка сервера', { type: 'error' });
    }
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={{ permissions }}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title={title}
          />
          <CardContent sx={{ width: '600px', boxSizing: 'border-box' }}>
            {isLoading ? (
              <LinearProgress sx={{ width: '100%' }} />
            ) : (
              <UserEntityPermissionsDialogForm
                entityName={entityName}
                recordId={recordId}
                recordName={recordName}
              />
            )}
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Подтвердить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
