import { styled } from '@mui/material/styles';
import { SaveButtonProps, Toolbar } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';

import { appColors } from '../../../themes/variables';
import { AppCreateFormSaveButton } from '../Buttons';

type Props = {
  mutationOptions?: SaveButtonProps['mutationOptions'];
};

const StyledToolbar = styled(Toolbar)({
  backgroundColor: appColors.background,
  minHeight: '0 !important',
  marginBottom: '24px',
});

/*
 * TODO: Здесь может быть любой набор кнопок, не нужно их хранить внутри тулбара,
 * нужно все кнопки передавать снаружи на страницах создания.
 * Тулбар должен отвечать только за верстку и быть одинаковым на страницах создания и редактирования.
 * Все кнопки должны передаваться в children.
 * Это нужно отрефакторить!
 *
 * @deprecated
 * */

export function AppCreateToolbar({ mutationOptions }: Props): JSX.Element {
  return (
    <StyledToolbar>
      <AppCreateFormSaveButton
        icon={<></>}
        mutationOptions={mutationOptions}
        sx={{ marginRight: '8px' }}
        type="button"
      />
      <CancelButton key="CancelButton" linkType="back" />
    </StyledToolbar>
  );
}
