import { useEffect, useState } from 'react';

import {
  BalanceReportFilterValues,
  ConversionRateTypes,
} from 'components/Finance/BalanceReport/BalanceReportList';
import { BalanceReportPeriodAlert } from 'components/Finance/BalanceReport/components/BalanceReportAlerts/BalanceReportPeriodAlert';
import { BalanceReportRequiredFieldsAlert } from 'components/Finance/BalanceReport/components/BalanceReportAlerts/BalanceReportRequiredFieldsAlert';
import { BalanceReportSuccessAlert } from 'components/Finance/BalanceReport/components/BalanceReportAlerts/BalanceReportSuccessAlert';
import { differenceInDays, getUnixTime } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useFilters } from 'hooks/useFilters';
import { RaRecord, required, useListContext } from 'react-admin';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { CalendarTodayIcon } from 'shared/mui/Icons';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { CompanyBalanceReport } from 'types';
import { getEndOfDate } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';

registerLocale('ru', ru);

type Props = {
  onGenerateClick: (filter: BalanceReportFilterValues) => void;
  setFilters: (filters: BalanceReportFilterValues) => void;
  filters: BalanceReportFilterValues;
};

export const BalanceReportFilter: React.FC<Props> = ({ onGenerateClick, filters, setFilters }) => {
  const resetFilterValues = {
    periodTo: '',
    periodFrom: '',
    conversionRateType: ConversionRateTypes.Actual,
    currency: null,
    companies: [],
    merchants: [],
    providers: [],
    merchantAccounts: [],
  };
  const { form, resetFilter } = useFilters<any>(resetFilterValues, {
    ...resetFilterValues,
    conversionRateType: null,
  });
  const [showAlert, setShowAlert] = useState(true);
  const { data } = useListContext<CompanyBalanceReport>();
  const [companies, merchants, providers] = form.watch(['companies', 'merchants', 'providers']);

  const onReset = () => {
    setFilters({});
    resetFilter();
  };

  const onSubmit = () => {
    setShowAlert(true);
    const intervalInDays = differenceInDays(
      form.getValues('periodTo'),
      form.getValues('periodFrom'),
    );

    if (intervalInDays > 1825) {
      form.setError('periodError', {
        type: 'custom',
        message: 'Период формирования отчета не должен превышать 1825 календарных дней!',
      });
    }

    onGenerateClick(
      cleanEmpty({
        ...form.getValues(),
        periodFrom: getUnixTime(form?.getValues('periodFrom') as Date),
        periodTo: getUnixTime(form.getValues('periodTo') as Date),
      }),
    );
  };

  const renderAlert = () => {
    if (Object.keys(form.formState.errors).length && !form.formState.errors?.periodError) {
      return <BalanceReportRequiredFieldsAlert onClose={() => setShowAlert(false)} />;
    }

    if (form.formState.errors?.periodError) {
      return <BalanceReportPeriodAlert onClose={() => setShowAlert(false)} />;
    }

    if (data.length && Object.keys(filters).length >= 4) {
      return <BalanceReportSuccessAlert filters={filters} onClose={() => setShowAlert(false)} />;
    }

    return <></>;
  };

  useEffect(() => {
    form.setValue('merchants', []);
    form.setValue('providers', []);
    form.setValue('merchantAccounts', []);
  }, [companies]);

  useEffect(() => {
    form.setValue('providers', []);
    form.setValue('merchantAccounts', []);
  }, [merchants]);

  useEffect(() => {
    form.setValue('merchantAccounts', []);
  }, [providers]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter alert={showAlert ? renderAlert() : undefined}>
          <FilterRow sx={{ mb: 2 }}>
            <Controller
              control={form.control}
              name="periodFrom"
              render={({ field, fieldState, formState }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      error={!!fieldState.error || !!formState.errors.periodError}
                      fullWidth
                      label="Период отчета от"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={form.watch('periodTo')}
                  locale="ru"
                  maxDate={form.watch('periodTo') || new Date()}
                  onChange={(date) => {
                    field.onChange(date);
                    form.clearErrors('periodError');
                  }}
                  selected={field.value}
                  selectsStart
                  startDate={field.value}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={form.control}
              name="periodTo"
              render={({ field, fieldState, formState }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      error={!!fieldState.error || !!formState.errors.periodError}
                      fullWidth
                      label="Период отчета до"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={field.value}
                  locale="ru"
                  maxDate={new Date()}
                  minDate={form.watch('periodFrom')}
                  onChange={(date: Date) => {
                    field.onChange(getEndOfDate(date));
                    form.clearErrors('periodError');
                  }}
                  selected={field.value}
                  selectsEnd
                  startDate={form.watch('periodFrom')}
                />
              )}
              rules={{ required: true }}
            />
            <SelectInputShrink
              choices={[
                { id: ConversionRateTypes.Actual, label: 'Актуальный' },
                { id: ConversionRateTypes.Historical, label: 'Исторический' },
              ]}
              defaultValue={ConversionRateTypes.Actual}
              helperText={false}
              label="Курс конвертации"
              name="conversionRateType"
              optionText="label"
              source="conversionRateType"
              validate={required()}
              variant="outlined"
            />
            <Controller
              name="currency"
              render={({ fieldState }) => {
                return (
                  <ReferenceInput
                    filter={{ active: true }}
                    name="currency"
                    perPage={1000}
                    reference="currencies"
                    resettable
                    resource="currencies"
                    sort={{ field: 'id', order: 'ASC' }}
                    source="currency"
                  >
                    <AutocompleteInputShrink
                      clearOnBlur={false}
                      filterToQuery={(searchText: string) => ({ currency: searchText })}
                      helperText={false}
                      label="Валюта отчета"
                      name="currency"
                      optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
                      optionValue="alphaCode"
                      renderInput={(params) => (
                        <TextFieldShrink
                          {...params}
                          error={!!fieldState.error}
                          label="Валюта отчета"
                          variant="outlined"
                        />
                      )}
                    />
                  </ReferenceInput>
                );
              }}
              rules={{ required: true }}
            />
          </FilterRow>
          <FilterRow>
            <ReferenceArrayInput
              filter={{ archive: false }}
              isFilter={true}
              name="companies"
              perPage={200}
              reference="companies"
              resettable
              resource="companies"
              source="companies"
            >
              <AutocompleteMultiselectInputShrink
                clearOnBlur={false}
                helperText={false}
                label="Компания"
                name="companies"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() => !!companies?.length}
              filter={{ companies, exists: { merchantAccounts: true }, archive: false }}
              isFilter={true}
              name="merchants"
              perPage={200}
              reference="merchants"
              resettable
              resource="merchants"
              source="merchants"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!companies?.length}
                helperText={false}
                label="Мерчант"
                name="merchants"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() => !!merchants?.length}
              filter={{ merchantAccounts: { merchants } }}
              isFilter={true}
              name="providers"
              perPage={200}
              reference="providers"
              resettable
              resource="providers"
              source="providers"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!merchants?.length}
                helperText={false}
                label="Провайдер"
                name="providers"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() => !!merchants?.length}
              filter={{ merchants, provider: providers }}
              isFilter={true}
              name="merchantAccounts"
              perPage={200}
              reference="merchant_accounts"
              resettable
              resource="merchant_accounts"
              source="merchantAccounts"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!merchants?.length}
                helperText={false}
                label="Счет мерчанта"
                name="merchantAccounts"
                optionText="name"
              />
            </ReferenceArrayInput>
          </FilterRow>
          <FilterRow>
            <FilterActions marginTop={2}>
              <FilterApplyButton key="apply-button" type="submit" />
              <FilterResetButton key="reset-button" onClick={onReset} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
