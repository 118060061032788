import { useQuery } from 'react-query';

import { getMerchantSpacesList, MerchantSpaceResponse } from '../../../config/requests';

type UseGetMerchantSpacesReturnValue = {
  merchantSpaces: MerchantSpaceResponse | undefined;
  isLoading: boolean;
};

export function useMerchantSpaces(): UseGetMerchantSpacesReturnValue {
  const { data: merchantSpaces, isLoading } = useQuery<MerchantSpaceResponse>(
    ['merchant_spaces'],
    () => getMerchantSpacesList({ page: 1, itemsPerPage: 1500 }),
  );

  return { merchantSpaces, isLoading };
}
