import { useState } from 'react';

import { Box, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AutocompleteInput, TextInput, useNotify } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';

import { getRoleOrderStatuses, ordersBulkUpdate } from '../../../config/requests';
import { appColors } from '../../../themes/variables';
import { OrdersBulkUpdateError, OrdersBulkUpdateValues } from '../../../types';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

const StyledCard = styled(Card)({
  marginTop: '32px',
  marginBottom: '32px',
  padding: '24px',
  border: '1px solid rgba(0,0,0,0.12)',
  borderRadius: '4px',
});

const AppOrdersBulkUpdate = (): JSX.Element => {
  const notify = useNotify();

  const { data: newRoleOrderStatuses } = useQuery('role_order_statuses', getRoleOrderStatuses);

  const [foundIds, setFoundIds] = useState<string[]>([]);
  const [notFoundIds, setNotFoundIds] = useState<string[]>([]);
  const [errors, setErrors] = useState({
    orderStatus: [],
    orderIds: [],
  });

  const methods = useForm({ defaultValues: { order_ids: '', order_status: '' } });

  const clearMessages = (): void => {
    setFoundIds([]);
    setNotFoundIds([]);
    setErrors({
      orderStatus: [],
      orderIds: [],
    });
  };

  const onSubmit = (values: OrdersBulkUpdateValues): void => {
    clearMessages();

    const transformedValues = {
      order_ids: values.order_ids ? values.order_ids.replace(/\s/g, '').split(',') : [],
      order_status: values.order_status,
    };

    ordersBulkUpdate(transformedValues)
      .then((res) => {
        if (res.data) {
          setFoundIds(res.data?.found_ids || []);
          setNotFoundIds(res.data?.not_found_ids || []);
        }

        notify('Изменения отправлены.', { type: 'info' });
      })
      .catch((response) => {
        if (response.status === 500) {
          notify(response.data.errors[0]?.title, { type: 'error' });
        } else {
          const orderIds =
            response?.data?.errors
              ?.filter((error: OrdersBulkUpdateError) => error.source.parameter === 'orderIds')
              ?.map((error: OrdersBulkUpdateError) => error.title) || [];
          const orderStatus =
            response.data.errors
              ?.filter((error: OrdersBulkUpdateError) => error.source.parameter === 'orderStatus')
              ?.map((error: OrdersBulkUpdateError) => error.title) || [];

          setErrors({ orderIds, orderStatus });
        }

        notify('Ошибка в запросе!', { type: 'error' });
      });
  };

  return (
    <StyledCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AppPanelHeader>
            <AppPanelHeaderToolbar titleText="Массовое изменение статусов заказов" />
          </AppPanelHeader>
          <Box display="flex" flexWrap="wrap">
            <Box mr={4}>
              <TextInput
                helperText={errors.orderIds.join(' ')}
                label="Id заказов через запятую"
                onChange={() =>
                  setErrors((prev) => {
                    return { ...prev, orderIds: [] };
                  })
                }
                resettable
                source="order_ids"
                sx={{ '& .MuiFormHelperText-root': { color: appColors.error.main } }}
                variant="outlined"
              />
            </Box>
            <Box mr={4}>
              <AutocompleteInput
                choices={newRoleOrderStatuses?.data.statuses.map((orderStatus) => {
                  return { id: orderStatus.alias, name: orderStatus.name };
                })}
                helperText={errors.orderStatus.join(' ')}
                label="Статус"
                onChange={() =>
                  setErrors((prev) => {
                    return { ...prev, orderStatus: [] };
                  })
                }
                source="order_status"
                sx={{ '& .MuiFormHelperText-root': { color: appColors.error.main } }}
                variant="outlined"
              />
            </Box>
            <Button
              color="primary"
              size="medium"
              sx={{ height: 40, marginTop: 1 }}
              type="submit"
              variant="outlined"
            >
              Применить
            </Button>
            {foundIds && foundIds.length > 0 && (
              <Typography mt={2} width="100%">
                Успешно выполнено для заказов: {foundIds?.map((id) => id + ' ')}
              </Typography>
            )}
            {notFoundIds && notFoundIds.length > 0 && (
              <Typography color="error" mt={2} width="100%">
                Заказы не найдены: {notFoundIds?.map((id) => id + ' ')}
              </Typography>
            )}
          </Box>
        </form>
      </FormProvider>
    </StyledCard>
  );
};

export default AppOrdersBulkUpdate;
