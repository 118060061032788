import { useHistoryContext } from 'hooks/useHistoryContext';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';

import { Button, ButtonProps } from '..';

export type EditButtonProps = ButtonProps;

export function EditButton({
  label = 'Редактировать',
  color = 'primary',
  variant = 'outlined',
  size = 'medium',
  ...restProps
}: EditButtonProps): JSX.Element {
  const resource = useResourceContext(restProps);
  const record = useRecordContext(restProps);
  const location = useLocation();
  const { baseRoute } = useHistoryContext();

  return (
    <Button
      color={color}
      component={Link}
      label={label}
      size={size}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record?.id)}/edit`,
        state: { backgroundLocation: baseRoute || location },
      }}
      variant={variant}
      {...restProps}
    />
  );
}
