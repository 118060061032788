import { useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Chip } from '../../../../Chips';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

export type FilterEnumChipProps = {
  label: string;
  resource: string;
  name: string;
  children: React.ReactNode;
  onDelete?: () => void;
};

export const FilterEnumAutocompleteChip: React.FC<FilterEnumChipProps> = ({
  resource,
  children,
  name,
  label,
  onDelete,
}) => {
  const { getValues, setValue } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();

  const { data } = useGetOne(
    resource,
    { id: `admin/${resource}/${appliedFilters[name]}` },
    { enabled: Boolean(appliedFilters[name]), keepPreviousData: true },
  );

  if (!appliedFilters[name]) {
    return <></>;
  }

  function onApply() {
    handleClose();
    setAppliedFilter(name, getValues(name));
  }

  function onResetHandler() {
    setAppliedFilter(name, appliedFilters?.[name]);
    setValue(name, appliedFilters?.[name]);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApply}
        onClose={handleClose}
        onReset={onResetHandler}
        open={open}
      >
        {children}
      </PopoverChip>
      <Chip
        label={`${label}: ${data?.description || ''}`}
        onClick={handleClick}
        onDelete={() => {
          onDelete && onDelete();
          setValue(name, null);
          setAppliedFilter(name, null);
        }}
        size="medium"
      />
    </>
  );
};
