import { useEffect } from 'react';

import { useRecordContext } from 'react-admin';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';

import { useMerchantSpacesUsersSites } from '../../../../hooks/queries/useMerchantSpacesUsersSites';
import { MerchantSpacesUsers } from '../../../../types';
import { MerchantSpacesUsersFormValues } from '../MerchantSpacesUsersEdit';

type UseCreateSitesFieldsReturnValues = {
  fields: FieldArrayWithId<MerchantSpacesUsersFormValues, 'sites', 'id'>[];
  isLoadingFields: boolean;
};

export function useEditSitesFields(): UseCreateSitesFieldsReturnValues {
  const record = useRecordContext<MerchantSpacesUsers>();

  const { merchantSpacesUsersSites, isLoadingMerchantSpacesUsersSites } =
    useMerchantSpacesUsersSites(record?.id || '');

  const { fields, append } = useFieldArray<MerchantSpacesUsersFormValues>({
    name: 'sites',
  });

  useEffect(() => {
    merchantSpacesUsersSites?.forEach((site) => append(site));
  }, [merchantSpacesUsersSites]);

  return { fields, isLoadingFields: isLoadingMerchantSpacesUsersSites };
}
