import React, { useEffect } from 'react';

import { ListContextProvider, useList } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';

import { useEditSitesFields } from './hooks/useEditSitesFields';
import { MerchantSpacesFormValues } from './MerchantSpacesEdit';
import { CompanyMerchantsDatagrid } from '../components/CompanyMerchantsDatagrid/CompanyMerchantsDatagrid';
import { useMerchants } from '../hooks/useMerchants';

export const MerchantSpacesEditFormDatagrid: React.FC = () => {
  const { watch } = useFormContext<MerchantSpacesFormValues>();

  const watchedCompanyId = watch('sourceCompany');

  const { merchants, isLoadingMerchants } = useMerchants(watchedCompanyId);
  const { fields } = useEditSitesFields(merchants);

  const listContext = useList({
    data: merchants,
    perPage: 10,
    sort: { field: 'originId', order: 'ASC' },
  });

  useEffect(() => {
    listContext.onUnselectItems();
  }, [isLoadingMerchants]);

  if (!watchedCompanyId) {
    return (
      <Box sx={{ padding: '0 16px 16px 16px' }}>
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          Появится после выбора компании.
        </Alert>
      </Box>
    );
  }

  if (isLoadingMerchants) {
    return <LinearProgress />;
  }

  return (
    <ListContextProvider value={listContext}>
      <CompanyMerchantsDatagrid fields={fields} />
    </ListContextProvider>
  );
};
