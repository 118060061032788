import { useNotify, useUpdate } from 'react-admin';
import { convertReactAdminIdToHydraId } from 'utils';

import { getMerchantNotificationData } from '../utils/getMerchantNotificationData';

type DataType = Partial<ReturnType<typeof getMerchantNotificationData>>;

export function useEditMerchantNotification(id: string, onSuccess: VoidFunction) {
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();

  const mutate = (data: DataType) => {
    update(
      'merchant_notification_settings',
      {
        id: convertReactAdminIdToHydraId(id, 'merchant_notification_settings'),
        data,
      },
      {
        onError: (error) => {
          notify(`Ошибка: ${error as string}` || 'Ошибка.', { type: 'error' });
        },
        onSuccess: () => {
          onSuccess();
          notify('Успешно!', { type: 'success' });
        },
      },
    );
  };

  return { isLoading, mutate };
}
