import React from 'react';

import { useGoBack } from 'hooks/useGoBack';
import { Box } from 'shared/mui/Box';

import { ShowDialogStyled } from './ShowGuesserDialog.styled';
import { ShowGuesserDialogLayout } from './ShowGuesserDialogLayout';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';
import { Show, ShowProps } from '../../Show/Show';

export type ShowGuesserDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  open?: boolean;
  title: string;
} & Omit<ShowProps, 'actions'>;

export const ShowGuesserDialog: React.FC<ShowGuesserDialogProps> = ({
  actions,
  open = true,
  title,
  children,
  ...restProps
}) => {
  const { goBack } = useGoBack();

  return (
    <ShowDialogStyled open={open}>
      <Show actions={false} {...restProps}>
        <ShowGuesserDialogLayout>
          <CardHeader
            actions={
              <Box>
                {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
              </Box>
            }
            title={title}
          />
          {children}
        </ShowGuesserDialogLayout>
      </Show>
    </ShowDialogStyled>
  );
};
