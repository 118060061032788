import React from 'react';

import { Close } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  clearAmountFrom: () => void;
  clearAmountTo: () => void;
};

export const BankOperationsFilterAmount = ({
  clearAmountFrom,
  clearAmountTo,
}: Props): JSX.Element => {
  const form = useFormContext();

  return (
    <>
      <Controller
        control={form.control}
        name="amountPennyFrom"
        render={({ field, fieldState }) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: field.value && <Close onClick={clearAmountFrom} />,
            }}
            error={!!fieldState.error}
            fullWidth
            helperText={fieldState.error?.message}
            label="Сумма от"
            onChange={(value) => field.onChange(value)}
            value={field.value}
            variant="outlined"
          />
        )}
        rules={{
          maxLength: { value: 10, message: 'Допустимы значения до 10 символов' },
          pattern: {
            value: /^[0-9]*\.?[0-9]*$/,
            message: 'Введите число',
          },
        }}
      />
      <Controller
        control={form.control}
        name="amountPennyTo"
        render={({ field, fieldState }) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: field.value && <Close onClick={clearAmountTo} />,
            }}
            error={!!fieldState.error}
            fullWidth
            helperText={fieldState.error?.message}
            label="Сумма до"
            onChange={(value) => field.onChange(value)}
            value={field.value}
            variant="outlined"
          />
        )}
        rules={{
          maxLength: { value: 10, message: 'Допустимы значения до 10 символов' },
          pattern: {
            value: /^[0-9]*\.?[0-9]*$/,
            message: 'Введите число',
          },
        }}
      />
    </>
  );
};
