import { EMPTY_FIELD } from 'constants/emptyField';

import { useEffect, useState } from 'react';

import { Download } from '@mui/icons-material';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListContextProvider, RecordContextProvider, useList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';
import { Chip } from 'shared/mui/Chips';
import { PageHeader } from 'shared/mui/PageHeader';
import {
  BooleanField,
  ChipField,
  FunctionField,
  GatewayStatusIconField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';

import { DirectionField } from './components/DirectionField';
import { useExportProviderReports } from './hooks/useExportProviderReports';
import { useFormattedProviderReportsFilter } from './hooks/useFormattedProviderReportsFilter';
import { useGetProviderReports } from './hooks/useGetProviderReports';
import { StyledDatagrid } from './ProviderReportsList.styled';
import {
  ProviderReportsListFilter,
  ProviderReportsListFilterType,
} from './ProviderReportsListFilter';
import { Gateway, Provider, ProviderReport } from '../../types';
import { cleanEmpty } from '../../utils/cleanEmpty';

const getGatewayValue = (gateway: Gateway) => ({
  ...gateway,
  '@id': `/admin/gateways/${gateway.id}`,
});

export const ProviderReportsList: React.FC = () => {
  const { hasResourceAccess } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [appliedFilters, setAppliedFilters] = useState<ProviderReportsListFilterType>({
    gatewayAlias: searchParams.getAll('gatewayAlias'),
    providerAlias: searchParams.getAll('providerAlias'),
    page: 1,
    itemsPerPage: 10,
  });

  const { formattedFilters } = useFormattedProviderReportsFilter(appliedFilters);
  const { providerReportsList, isLoading, refetch } = useGetProviderReports(formattedFilters);
  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  function onSubmitClick(filter: ProviderReportsListFilterType) {
    setAppliedFilters({ ...filter, page: 1, itemsPerPage: appliedFilters.itemsPerPage });
    setSearchParams(
      cleanEmpty({ ...filter, page: '1', itemsPerPage: appliedFilters.itemsPerPage.toString() }),
    );
  }

  const { exportReport } = useExportProviderReports(formattedFilters);

  const context = useList<ProviderReport>({
    data:
      providerReportsList?.data.map((report, index) => {
        return { ...report, id: index.toString(), _id: report.id };
      }) || [],
    page: appliedFilters.page,
    perPage: appliedFilters.itemsPerPage,
  });

  useEffect(() => {
    setSearchParams({
      ...appliedFilters,
      page: appliedFilters.page.toString(),
      itemsPerPage: appliedFilters.itemsPerPage.toString(),
    });
    refetch();
  }, [appliedFilters]);

  useEffect(() => {
    const { page, itemsPerPage } = Object.fromEntries(searchParams);
    const formatFilter = {
      gatewayAlias: searchParams.getAll('gatewayAlias'),
      providerAlias: searchParams.getAll('providerAlias'),
      page: Number(page) || 1,
      itemsPerPage: Number(itemsPerPage) || 10,
    };
    setAppliedFilters(formatFilter);
  }, []);

  const resetFilter = (): void => {
    setAppliedFilters((prev) => ({
      ...prev,
      providerAlias: [],
      gatewayAlias: [],
      page: 1,
    }));
  };

  return (
    <ListContextProvider value={context}>
      <PageHeader
        actions={[
          <Button
            key="export-providers"
            label="Экспортировать"
            onClick={exportReport}
            startIcon={<Download />}
            variant="outlined"
          />,
        ]}
        headerTitle={{
          titleText: 'Отчет по провайдерам',
          tooltipText:
            'Отчет, предоставляющий сводную информацию о текущих статусах шлюзов и состоянии провайдеров',
        }}
        mt={3}
      />
      <ProviderReportsListFilter onReset={resetFilter} onSubmit={onSubmitClick} />
      <StyledDatagrid bulkActionButtons={false} isLoading={isLoading}>
        <TextField label="ID провайдера" source="_id" />
        <ReferenceOneField
          label="Провайдер"
          reference="providers"
          resource="providers"
          source="_id"
          target="id"
        >
          <FunctionField
            render={(record: Provider | undefined) => (
              <Chip
                color="primary"
                label={record?.name}
                onClick={() => handleOpenRecordModalProvider(record)}
              />
            )}
          />
        </ReferenceOneField>
        <BooleanField label="Статус провайдера" source="active" />
        {hasResourceAccess('gateways') && (
          <FunctionField
            label="Шлюз"
            render={({ gateway }: ProviderReport) => {
              return (
                <RecordContextProvider value={getGatewayValue(gateway)}>
                  <ReferenceOneField link="show" reference="gateways" target="id">
                    <TextField source="name" />
                  </ReferenceOneField>
                </RecordContextProvider>
              );
            }}
            source="gateway"
          />
        )}
        {hasResourceAccess('gateways') && (
          <FunctionField
            label="Статус шлюза"
            render={({ gateway }: ProviderReport) => {
              return (
                <RecordContextProvider value={getGatewayValue(gateway)}>
                  <ReferenceOneField reference="gateways" target="id">
                    <GatewayStatusIconField />
                  </ReferenceOneField>
                </RecordContextProvider>
              );
            }}
            source="gateway"
          />
        )}
        <FunctionField
          label="Направление"
          render={(record: ProviderReport) => (
            <DirectionField direction={record?.gateway?.direction} />
          )}
        />
        {hasResourceAccess('gateways') && (
          <FunctionField
            label="Метод"
            render={({ gateway }: ProviderReport) => {
              return (
                <RecordContextProvider value={getGatewayValue(gateway)}>
                  <ReferenceArrayField label="Метод" reference="methods" source="methods">
                    <SingleFieldList linkType="show">
                      <ChipField
                        color="primary"
                        source="name"
                        sx={{ '.MuiChip-label': { color: 'white' } }}
                      />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </RecordContextProvider>
              );
            }}
          />
        )}
        <FunctionField
          emptyText="—"
          label="Поддерживаемые валюты"
          render={(record: ProviderReport) =>
            record?.gateway?.supportedCurrencies?.map((currency) => (
              <Chip key={currency.id} label={currency.alphaCode} sx={{ height: '24px' }} />
            )) || EMPTY_FIELD
          }
        />
      </StyledDatagrid>
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
      <Pagination
        page={appliedFilters.page}
        perPage={appliedFilters.itemsPerPage}
        setPage={(page) => setAppliedFilters({ ...appliedFilters, page })}
        setPerPage={(itemsPerPage) =>
          setAppliedFilters({ ...appliedFilters, page: 1, itemsPerPage })
        }
        total={providerReportsList?.totalItems}
      />
    </ListContextProvider>
  );
};
