import { styled } from '@mui/material/styles';
import { IconButton } from 'shared/mui/IconButtons';

export const StyledIconButton = styled(IconButton)(({ iconSize }: { iconSize?: number }) => ({
  '& .MuiBox-root': {
    position: 'static',
  },
  '& .MuiSvgIcon-root': {
    width: iconSize || 17,
    height: iconSize || 17,
  },
}));
