import { styled } from '@mui/material';
import { Create } from 'shared/react-admin/Create/Create';

export const CreateStyled = styled(Create)({
  margin: 0,
  width: '600px',
  '& .RaCreate-main': {
    marginTop: 0,
  },
});
