import React from 'react';

import { IconButton, TextField, TextFieldProps } from '@mui/material';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { CalendarTodayIcon } from '../../../Icons';

export type DatePickerMaskInputProps = Pick<
  MaskedInputProps,
  'mask' | 'placeholder' | 'onChange' | 'value' | 'onClick' | 'disabled'
> &
  Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue'>;

export const DatePickerMaskInput: React.FC<DatePickerMaskInputProps> = ({
  mask,
  onChange,
  placeholder,
  onClick,
  disabled,
  value,
  ...restProps
}) => {
  return (
    <MaskedInput
      guide={true}
      keepCharPositions={true}
      mask={mask}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      render={(innerRef, maskProps) => (
        <TextField
          {...restProps}
          {...maskProps}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton disabled={disabled} edge="end">
                <CalendarTodayIcon />
              </IconButton>
            ),
          }}
          autoComplete="off"
          disabled={disabled}
          inputRef={innerRef}
          sx={{ width: '100%' }}
        />
      )}
      value={value}
    />
  );
};
