import { useCreate, useNotify } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { ApiClient } from 'types';

import { CreateStyled } from './ApiClientsShowApiKeysCreateDialog.styled';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  apiClient?: ApiClient;
};

export const ApiClientsShowApiKeysCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  apiClient,
}) => {
  const notify = useNotify();
  const [update, { isLoading }] = useCreate();
  const onSubmit = () => {
    update(
      'api_keys',
      {
        data: {
          apiClient: apiClient?.id,
        },
      },
      {
        onSuccess: () => {
          notify('Ключ API успешно создан', {
            type: 'success',
            autoHideDuration: 3000,
          });
          onClose();
        },
        onError: (error) =>
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, {
            type: 'error',
            autoHideDuration: 3000,
          }),
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <CreateStyled>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Создание настроек уведомления"
        />
        <CardContent>
          <Alert severity="warning">
            Не создавайте новый ключ API, если в этом нет необходимости и его не нужно заменять на
            стороне клиента.
          </Alert>
          <Typography sx={{ marginTop: '16px' }}>
            После подтверждения, новый ключ API будет автоматически сгенерирован и назначен как
            &quot;Активен&quot; для Клиента API {apiClient?.name}
          </Typography>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              isLoading={isLoading}
              key="create-button"
              label="Создать"
              onClick={onSubmit}
              type="submit"
              variant="contained"
            />,
            <Button key="cancel-button" label="Отменить" onClick={onClose} variant="outlined" />,
          ]}
        />
      </CreateStyled>
    </Dialog>
  );
};
