import { Field } from '@api-platform/api-doc-parser';
import { required, Validator } from 'react-admin';

type FieldValidateGuesserParams = {
  fields: Field[];
  validate: Validator | Validator[] | null | undefined;
  resource: string;
  source: string;
};

type FieldValidateGuesserReturnValue = {
  guessedValidate: Validator | Validator[] | null | undefined;
  isError: boolean;
  field: Field | null;
};

export function fieldValidateGuesser({
  fields,
  validate,
  resource,
  source,
}: FieldValidateGuesserParams): FieldValidateGuesserReturnValue {
  const field = fields?.find(({ name }) => name === source);

  if (!field) {
    // eslint-disable-next-line no-console
    console.error(
      `Field ${source} does not present in API description for the resource ${resource}`,
    );
    return { field: null, guessedValidate: null, isError: true };
  }

  const guessedValidate = !validate && field.required ? [required()] : validate;

  return { field, guessedValidate, isError: false };
}
