import { styled } from '@mui/material/styles';

import { Box, BoxProps } from '../../../Box';

export type FilterDrawerRowProps = BoxProps;

export const FilterDrawerRow: React.FC<FilterDrawerRowProps> = styled(Box)({
  gap: '16px',
  width: '100%',
  alignItems: 'flex-start',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
});
