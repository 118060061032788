import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { format, fromUnixTime } from 'date-fns';
import { Button } from 'shared/mui/Buttons';

import { StyledCumulativeReportSummaryPeriod } from './CumulativeReportSummaryPeriod.styled';
import { CumulativeReportFilterValues } from '../../CumulativeReportList';

type CumulativeReportSummaryPeriodProps = {
  filters: CumulativeReportFilterValues;
  toggleIsOpen: () => void;
  isOpen: boolean;
};

export const CumulativeReportSummaryPeriod: React.FC<CumulativeReportSummaryPeriodProps> = ({
  filters,
  toggleIsOpen,
  isOpen,
}) => (
  <StyledCumulativeReportSummaryPeriod isOpen={isOpen}>
    <div>{`Итого за период ${format(
      fromUnixTime(filters?.periodFrom || 0),
      'dd.MM.yyyy',
    )} — ${format(fromUnixTime(filters?.periodTo || 0), 'dd.MM.yyyy')}`}</div>
    <Button
      endIcon={isOpen ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      label={isOpen ? 'Свернуть итог' : 'Развернуть итог'}
      onClick={toggleIsOpen}
      size="small"
      sx={{ minWidth: 'fit-content' }}
    />
  </StyledCumulativeReportSummaryPeriod>
);
