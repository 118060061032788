import { useFormContext } from 'react-hook-form';
import { AutocompleteInputShrink, AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { cleanEmpty } from 'utils/cleanEmpty';

export const OrderConversionListFilterForm: React.FC = () => {
  const { watch } = useFormContext();

  const merchantWatched = watch('merchant');
  const providerWatched = watch('provider');

  return (
    <>
      <ReferenceInput name="merchant" perPage={100} reference="merchants" source="merchant">
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          isRequired={true}
          label="Мерчант"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={cleanEmpty({ merchantAccounts: { merchants: merchantWatched } })}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Провайдер"
          limitTags={1}
          name="provider"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          merchantAccounts: { merchants: merchantWatched },
          provider: providerWatched,
        })}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          optionText="name"
        />
      </ReferenceArrayInput>
    </>
  );
};
