import React from 'react';

import { Form, required, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Create } from 'shared/react-admin/Create/Create';
import { AutocompleteInputShrink, TextInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { CardContentStyled, JsonInputStyled } from './MerchantsShowCredentialsCreateDialog.styled';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
};

interface CreateFormValues extends FieldValues {
  merchant: string;
  provider: string;
  name: string;
  comment: string;
  credentials: Record<string, any>;
}

export const MerchantsShowCredentialsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
}) => {
  const [create] = useCreate();
  const notify = useNotify();

  const onSubmit = (formValues: CreateFormValues) => {
    create(
      'merchant_credentials',
      {
        data: {
          ...formValues,
          merchant: merchantId,
        },
      },
      {
        onSuccess: () => {
          notify('Реквизит доступа успешно создан!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => notify(`Ошибка: ${(error as Error).message}`, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open} scroll="body" transitionDuration={0}>
      <Create resource="merchant_credentials" sx={{ margin: 0 }}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание реквизитов доступа"
          />
          <Box sx={{ display: 'flex', width: '100%' }}>
            <CardContentStyled sx={{ width: '396px' }}>
              <TextInput
                InputLabelProps={{ shrink: true }}
                fullWidth={true}
                key="name"
                label="Название"
                resettable
                size="medium"
                source="name"
                validate={required()}
                variant="outlined"
              />
              <ReferenceInput perPage={200} reference="providers" source="provider">
                <AutocompleteInputShrink
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  fullWidth
                  label="Провайдер"
                  optionText="name"
                  size="medium"
                  source="provider"
                  sx={{ marginTop: '0px' }}
                  validate={required()}
                  variant="outlined"
                />
              </ReferenceInput>
              <TextInput
                InputLabelProps={{ shrink: true }}
                fullWidth={true}
                key="comment"
                label="Комментарий"
                multiline
                resettable
                size="medium"
                source="comment"
                sx={{ textarea: { height: '360px !important', overflowY: 'auto !important' } }}
                variant="outlined"
              />
            </CardContentStyled>
            <CardContentStyled sx={{ width: '707px' }}>
              <JsonInputStyled label="Редактор реквизитов доступа {json}" source="credentials" />
            </CardContentStyled>
          </Box>
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Создать" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </Create>
    </Dialog>
  );
};
