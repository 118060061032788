import { useEffect, useRef, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

type QueryObject = Record<string, any>;

/**
 * Получает значение параметра `filter` из query-строки URL.
 * @param {string} queryString - Строка query-параметров.
 * @returns {string | null} Значение параметра `filter` или null, если параметр отсутствует.
 */
const getFilterQueryString = (queryString: string): string | null => {
  try {
    const params = new URLSearchParams(queryString);

    return params.get('filter');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse query string:', e);
  }

  return null;
};

/**
 * Преобразует строку query-параметров обратно в объект.
 * @param {string} queryString - Строка query-параметров.
 * @returns {QueryObject} Объект с параметрами фильтрации.
 */
const decodeQueryStringToObject = (queryString: string): QueryObject => {
  const filter = getFilterQueryString(queryString);

  return filter ? JSON.parse(decodeURIComponent(filter)) : {};
};

/**
 * Хук для синхронизации query-параметров фильтрации URL с состоянием фильтров в форме.
 * Позволяет обновлять и сбрасывать фильтры, удалять отдельные поля из фильтров.
 *
 * @returns {Object} Объект с методами и значениями для управления query-параметрами:
 * - `setQueryFilterString` - Функция для установки строки query-параметров с новыми значениями фильтров.
 * - `queryFilterObject` - Текущий объект с фильтрами, извлечённый из query-параметров URL.
 * - `resetQueryString` - Функция для сброса всех query-параметров фильтров.
 * - `removeFieldsFromQueryString` - Функция для удаления определённых полей из query-параметров.
 * - `isEmptyFilters` - Флаг, показывающий, пусты ли текущие фильтры.
 */
export const useQueryFilters = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const location = useLocation();
  const [isEmptyFilters, setIsEmptyFilters] = useState(
    () => !getFilterQueryString(location.search),
  );

  const objectFromQuery = useRef<QueryObject | null>(decodeQueryStringToObject(location.search));

  useEffect(() => {
    objectFromQuery.current = decodeQueryStringToObject(location.search);
    const filter = getFilterQueryString(location.search);

    setIsEmptyFilters(!filter);
  }, [location.search]);

  /**
   * Устанавливает новую строку query-параметров с новыми значениями фильтров.
   * @param {QueryObject} newObj - Новый объект с параметрами фильтрации.
   */
  const setQueryFilterString = (newObj: QueryObject): void => {
    if (!Object.keys(newObj).length) {
      setSearchParams('');

      return;
    }

    const filterString = encodeURIComponent(JSON.stringify(newObj));
    setSearchParams({ filter: filterString });
  };

  /**
   * Сбрасывает query-параметры фильтрации, удаляя все параметры `filter`.
   */
  const resetQueryString = () => {
    setSearchParams('');
  };

  /**
   * Удаляет указанные поля из query-параметров фильтрации.
   * @param {string | string[]} fieldsToRemove - Поле или массив полей для удаления из фильтра.
   */
  const removeFieldsFromQueryString = (fieldsToRemove: string | string[]) => {
    const currentObjectFromQuery = objectFromQuery.current;
    if (
      typeof fieldsToRemove === 'string' &&
      currentObjectFromQuery &&
      fieldsToRemove in currentObjectFromQuery
    ) {
      delete currentObjectFromQuery[fieldsToRemove];
    }
    if (Array.isArray(fieldsToRemove) && currentObjectFromQuery) {
      fieldsToRemove.forEach((field) => {
        delete currentObjectFromQuery[field];
      });
    }

    setQueryFilterString({ ...currentObjectFromQuery });
  };

  return {
    setQueryFilterString,
    queryFilterObject: objectFromQuery.current,
    resetQueryString,
    removeFieldsFromQueryString,
    isEmptyFilters,
  };
};
