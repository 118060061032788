import { useEffect, useMemo } from 'react';

import { getGatewaysSchemaResolver } from 'components/Gateways/utils/getGatewaysSchemaResolver';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Currency, GatewayMethod, GatewayPilotModeSchedule } from 'types';

export type GatewayEditFormValues = {
  id?: string;
  active?: boolean;
  alias?: string;
  deactivationReason?: string | null;
  pilotMode?: boolean;
  defaultCurrency?: Currency;
  directions?: string[];
  direction?: string;
  gatewayPilotModeSchedule?: GatewayPilotModeSchedule;
  gatewayMethods?: GatewayMethod[];
  methods?: string[];
  name?: string;
  provider?: string;
  requireAmount?: boolean;
  supportedCurrencies?: Currency[];
  status?: string;
  statusRequestEnabled?: boolean;
  saveCustomerMethodData?: boolean;
  orderExpirationTimeSec?: number | null;
  attemptIntervalConfig?: string;
  transactionRequestMaxAttempts?: number;
  pcidss: boolean;
};

type UseGatewayEditFormReturnValues = {
  form: UseFormReturn<GatewayEditFormValues>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

type Props = {
  gateway?: Omit<GatewayEditFormValues, 'attemptIntervalConfig'> & {
    attemptIntervalConfig?: number[];
  };
  gatewayMethods?: GatewayMethod[];
  gatewayPilotModeSchedule?: GatewayPilotModeSchedule;
  submitHandler: (data: GatewayEditFormValues) => void;
};

// доктрина на бэке по дефолту проставляет для этого поля дату 1970-01-01
// что не позволяет корректно отображать время в соответствии с часовым поясом.
const formatTime = (date: Date | string): Date => {
  const formattedDateString = (date as string).replace(
    '1970-01-01',
    new Date().toISOString().split('T')[0],
  );
  return new Date(formattedDateString);
};

const periodStringToDate = (value: Date | string | null) =>
  value ? new Date(value as Date) : value;
const timeStringToDate = (value: Date | string | null) =>
  value ? formatTime(value as Date) : value;

// изначально поле null, но для формы нужны значения
const initialGatewayPilotModeSchedule = {
  schedule: false,
  periodStart: null,
  periodEnd: null,
  timeStart: null,
  timeEnd: null,
};

export function useGatewayEditForm({
  gateway,
  gatewayMethods,
  gatewayPilotModeSchedule,
  submitHandler,
}: Props): UseGatewayEditFormReturnValues {
  const defaultValues = useMemo(() => {
    return {
      id: gateway?.id,
      alias: gateway?.alias,
      deactivationReason: gateway?.deactivationReason,
      defaultCurrency: gateway?.defaultCurrency,
      directions: gateway?.directions,
      direction: gateway?.direction,
      gatewayPilotModeSchedule: gatewayPilotModeSchedule
        ? {
            id: gatewayPilotModeSchedule.id,
            schedule: gatewayPilotModeSchedule.schedule,
            periodStart: periodStringToDate(gatewayPilotModeSchedule.periodStart),
            periodEnd: periodStringToDate(gatewayPilotModeSchedule.periodEnd),
            timeStart: timeStringToDate(gatewayPilotModeSchedule.timeStart),
            timeEnd: timeStringToDate(gatewayPilotModeSchedule.timeEnd),
          }
        : initialGatewayPilotModeSchedule,
      gatewayMethods: gatewayMethods,
      name: gateway?.name,
      provider: gateway?.provider,
      requireAmount: gateway?.requireAmount,
      supportedCurrencies: gateway?.supportedCurrencies,
      status: gateway?.status,
      statusRequestEnabled: gateway?.statusRequestEnabled,
      saveCustomerMethodData: gateway?.saveCustomerMethodData,
      orderExpirationTimeSec: gateway?.orderExpirationTimeSec,
      transactionRequestMaxAttempts: gateway?.transactionRequestMaxAttempts,
      attemptIntervalConfig: gateway?.attemptIntervalConfig?.join(', '),
      pcidss: gateway?.pcidss,
    };
  }, [gateway, gatewayMethods, gatewayPilotModeSchedule]);

  const form = useForm<GatewayEditFormValues>({
    mode: 'onBlur',
    defaultValues,
    resolver: getGatewaysSchemaResolver(),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  function onSubmitHandler(data: GatewayEditFormValues) {
    submitHandler(data);
  }

  return {
    form,
    onSubmit: form.handleSubmit(onSubmitHandler),
  };
}
