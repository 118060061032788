import { Button, ButtonProps } from '../../../Buttons';

export type FilterClearButtonProps = ButtonProps & {
  visible?: boolean;
};

export const FilterClearButton: React.FC<FilterClearButtonProps> = ({
  label = 'Очистить фильтр',
  color = 'primary',
  visible = true,
  ...restProps
}) => {
  return (
    <Button
      color={color}
      label={label}
      sx={{
        display: visible ? 'initial' : 'none',
      }}
      {...restProps}
    />
  );
};
