import { DeleteStages } from 'constants/deleteStages';

import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteStageDialogLoader } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogLoader';
import { DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

type Props = {
  title?: string;
  details: string;
  deleteLabel?: string;
  children: React.ReactNode;
  stage: DeleteStages;
  deleteOneRecord: () => void;
} & DialogProps;

export const DeleteStageDialogForm: React.FC<Props> = ({
  children,
  title,
  details,
  deleteLabel,
  deleteOneRecord,
  stage,
  onClose,
}) => {
  return (
    <>
      {title && (
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title={title}
        />
      )}
      {stage === DeleteStages.Initial && (
        <>
          <Typography sx={{ padding: '16px 24px', wordBreak: 'break-word' }} variant="body1">
            {details}
          </Typography>
          <CardActionsButtons
            rightActionsSlot={[
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
              <DeleteButton
                key="delete-base-button"
                label={deleteLabel}
                onClick={deleteOneRecord}
              />,
            ]}
          />
        </>
      )}
      {stage === DeleteStages.Loading && (
        <DeleteStageDialogLoader sx={{ padding: '0 24px 24px' }} />
      )}
      {stage === DeleteStages.DenyDelete && children}
    </>
  );
};
