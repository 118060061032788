import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormControl = styled(FormControl)({
  '& .MuiFormLabel-root': {
    top: 8,
  },
  '& .MuiInputLabel-shrink': {
    top: 0,
  },
});
