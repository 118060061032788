import { styled } from '@mui/material/styles';
import { EditButton, EditButtonProps } from 'react-admin';

const StyledEditButton = styled(EditButton)({
  height: '36px',
  padding: '12px 16px',
  lineHeight: '1 !important',
  '& .MuiButton-startIcon': {
    padding: 0,
    margin: 0,
  },
});

export function AppEditButton({
  label = '',
  variant = 'outlined',
  ...restProps
}: EditButtonProps): JSX.Element {
  return <StyledEditButton label={label} variant={variant} {...restProps} />;
}
