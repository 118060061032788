import { queryClient } from 'App';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { Commission } from 'types';

type Props = {
  record?: Commission;
  open: boolean;
  onClose: () => void;
};

export const MerchantCommissionsDeleteDialog = ({ onClose, open, record }: Props) => {
  const onSuccess = async () => {
    await queryClient.invalidateQueries(['commissions']);
    onClose();
  };

  return (
    <DeleteDialog
      details={`Комиссия ${record?.name} будет удалена без возможности восстановления.`}
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      record={record}
      title="Удаление комиссии"
    />
  );
};
