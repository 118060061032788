import { RecordContextProvider } from 'react-admin';
import { ArrayPath, FieldArrayWithId, FieldValues } from 'react-hook-form';
import { Row } from 'shared/mui/Row/Row';
import { Typography } from 'shared/mui/Typography';

import { SitesAvailabilityFormBooleanInput } from './SitesAvailabilityFormBooleanInput';

type SitesAvailabilityFormProps<
  TFieldValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues>,
> = {
  fields: FieldArrayWithId<TFieldValues, TFieldArrayName, 'id' | 'sourceMerchantId'>[];
};

export function SitesAvailabilityForm<
  TFieldValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues>,
>({ fields }: SitesAvailabilityFormProps<TFieldValues, TFieldArrayName>) {
  if (fields.length === 0) {
    return <Typography>Мерчанты не найдены.</Typography>;
  }

  return (
    <Row sx={{ flexDirection: 'column' }}>
      {fields.map((field, index) => {
        return (
          <RecordContextProvider key={index} value={field}>
            <SitesAvailabilityFormBooleanInput
              helperText={false}
              key={field.id}
              merchantId={field.sourceMerchantId}
              source={`sites.${index}.active`}
            />
          </RecordContextProvider>
        );
      })}
    </Row>
  );
}
