import { useState } from 'react';

import { RaRecord, SimpleForm } from 'react-admin';
import { BankOperation } from 'types';

import { BankOperationsForm } from './components/BankOperationsForm';
import { AppNewCreate } from '../../Common/Create';
import { AppCreateToolbar } from '../../Common/Toolbars';

export const BankOperationsCreate = (): JSX.Element => {
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [provider, setProvider] = useState<string | undefined>(undefined);
  const transform = (data: BankOperation): RaRecord => {
    const date = data.dateOfOperation;
    return {
      ...data,
      dateOfOperation:
        date instanceof Date ? date.getTime() / 1000 : new Date(date).getTime() / 1000,
      currency,
      provider,
    };
  };
  return (
    <AppNewCreate
      listName="Банковские операции"
      redirect="list"
      sx={{
        '& .RaCreate-main': {
          width: 600,
        },
      }}
      title="Создание банковской операции"
      transform={transform}
    >
      <SimpleForm sx={{ padding: '8px 24px' }} toolbar={<AppCreateToolbar />}>
        <BankOperationsForm
          currency={currency}
          provider={provider}
          setCurrency={setCurrency}
          setProvider={setProvider}
        />
      </SimpleForm>
    </AppNewCreate>
  );
};
