import { getInitialMappingItem } from 'components/GatewayRules/components/GatewayRulesForm/utils';
import { useFieldArray, useFormContext } from 'react-hook-form';

type UseMappingItemFieldReturnValues = {
  mappingItemFields: Record<'id', string>[];
  onAddMappingItemClick: () => void;
  onRemoveMappingItemClick: (index: number) => void;
};

type Props = {
  mappingIndex: number;
  name: string;
};

export function useMappingItemField({
  name,
  mappingIndex,
}: Props): UseMappingItemFieldReturnValues {
  const { control, getValues } = useFormContext();
  const {
    replace,
    fields: mappingItemFields,
    append: appendMappingItem,
    remove: removeMappingItem,
  } = useFieldArray({
    name,
    control,
  });

  function onAddMappingItemClick(): void {
    appendMappingItem(getInitialMappingItem());
  }

  function onRemoveMappingItemClick(index: number): void {
    const currentValues = getValues();

    if (currentValues.mappings[mappingIndex].mappingItems.length === 1) {
      replace([getInitialMappingItem()]);
      return;
    }
    removeMappingItem(index);
  }

  return {
    mappingItemFields,
    onAddMappingItemClick,
    onRemoveMappingItemClick,
  };
}
