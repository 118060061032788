import { useCallback, useState } from 'react';

type UseRecordModalResult<T> = {
  openRecordModal: boolean;
  record: T | undefined;
  handleOpenRecordModal: (record?: T) => void;
  handleCloseRecordModal: () => void;
};

export function useRecordModal<T>(): UseRecordModalResult<T> {
  const [openRecordModal, setOpenRecordModal] = useState<boolean>(false);
  const [record, setRecord] = useState<T | undefined>(undefined);

  const handleOpenRecordModal = useCallback((record: T | undefined): void => {
    setRecord(record);
    setOpenRecordModal(true);
  }, []);

  const handleCloseRecordModal = (): void => {
    setRecord(undefined);
    setOpenRecordModal(false);
  };

  return {
    openRecordModal,
    record,
    handleOpenRecordModal,
    handleCloseRecordModal,
  };
}
