import { Introspecter } from '@api-platform/admin';
import { ReferenceInputProps, useResourceContext } from 'react-admin';

import { ReferenceInputIntrospectedComponent } from './ReferenceInputIntrospectedComponent';

export type ReferenceInputIntrospectedProps = ReferenceInputProps;

export const ReferenceInputIntrospected = (props: ReferenceInputIntrospectedProps): JSX.Element => {
  const { filter = {} } = props;
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={ReferenceInputIntrospectedComponent}
      includeDeprecated={true}
      resource={resource}
      {...props}
      filter={{ ...filter, groups: ['reference'] }}
    />
  );
};
