import { useCallback } from 'react';

import { generateName } from 'config/requests';
import { TextInputProps, useInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip/Tooltip/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { TextInput } from 'shared/react-admin/Inputs';
import { appColors } from 'themes/variables';

import { ReactComponent as GenerateTextSVG } from '../../../images/generateText.svg';

export const CommissionsNameInput: React.FC<TextInputProps> = (props) => {
  const { field } = useInput({ source: props.source });
  const [provider, currency, merchant] = useWatch({ name: ['provider', 'currency', 'merchant'] });

  const handleClick = useCallback(async () => {
    const response = await generateName('merchant_account', {
      merchant,
      provider,
      currency,
    });
    field.onChange(response.data.name);
  }, [merchant, provider, currency]);

  return (
    <TextInput
      {...props}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <Tooltip
            title={
              <Typography fontSize={12}>
                Сгенерировать название.
                {(!provider || !merchant || !currency) &&
                  ` Для генерации надо заполнить поля: ${provider ? '' : 'Провайдер, '}${merchant ? '' : 'Мерчант, '}${currency ? '' : 'Валюта, '}`}
              </Typography>
            }
          >
            <span>
              <IconButton disabled={!provider || !currency || !merchant} onClick={handleClick}>
                <GenerateTextSVG
                  style={{
                    color: provider && currency && merchant ? appColors.primary.main : 'gray',
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        ),
      }}
      size="medium"
      sx={{ marginBottom: '16px' }}
    />
  );
};
