export function updateObjectPropByPath(
  obj: Record<string, any>,
  path: string,
  value: any,
): Record<string, any> {
  const [head, ...rest] = path.split('.');

  return {
    ...obj,
    [head]: rest.length ? updateObjectPropByPath(obj[head], rest.join('.'), value) : value,
  };
}
