import { useContext, useState } from 'react';
import { SyntheticEvent } from 'react';

import { useIntrospection } from '@api-platform/admin';
import { TextField, Button } from '@mui/material';
import camelcaseKeys from 'camelcase-keys';
import { useAuthProvider, useLogin, useNotify } from 'react-admin';
import { AuthStepProps } from 'types';

import { AppSecondFactorQrCode } from './SecondFactor';
import { APIContext } from '../../context/apiContext';

const AppSecondFactorForm = (props: AuthStepProps): JSX.Element => {
  const [code, setCode] = useState('');
  const authProvider = useAuthProvider();
  const introspect = useIntrospection();
  const login = useLogin();
  const notify = useNotify();
  const { setUser } = useContext(APIContext);

  const qrCodeComponent =
    undefined !== authProvider.currentStepResponseContent.QrCodeUrl ? (
      <AppSecondFactorQrCode qrCode={authProvider.currentStepResponseContent.QrCodeUrl} />
    ) : (
      ''
    );

  const submit = (captchaToken: string) => {
    login({ captchaToken, code })
      .then((user) => {
        setUser({ ...camelcaseKeys(user), isLoaded: true });
      })
      .then(() => {
        introspect();
      })
      .catch(() => notify('Invalid captcha', { type: 'error' }));
  };

  const proxySubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    props.callback(submit);
  };

  return (
    <form onSubmit={proxySubmit}>
      {qrCodeComponent}

      <TextField
        autoComplete="code"
        autoFocus
        fullWidth
        id="code"
        label="Код из Google Authenticator"
        margin="normal"
        name="code"
        onChange={(e) => setCode(e.target.value)}
        required
        value={code}
        variant="outlined"
      />
      <Button color="primary" fullWidth type="submit" variant="contained">
        Отправить
      </Button>
    </form>
  );
};

export default AppSecondFactorForm;
