import { Role } from 'constants/roles';

import { useFilters } from 'hooks/useFilters';
import { RaRecord } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

type WorkMemberFilterValues = {
  name: string;
  countries: string[];
  manager: string;
  members: string[];
};

export const WorkTeamsListFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<WorkMemberFilterValues>({
    name: '',
    countries: [],
    manager: '',
    members: [],
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FilterFieldGroup sx={{ flex: 1, paddingRight: 2 }}>
            <TextInputShrink
              helperText={false}
              label="Группа"
              name="name"
              source="name"
              variant="outlined"
            />
            <ReferenceArrayInput
              isFilter={true}
              name="countries"
              perPage={200}
              reference="countries"
              source="countries"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Страны"
                name="countries"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
            <ReferenceInput
              filter={{ 'roleEntities.alias': [Role.FinanceLead] }}
              perPage={50}
              reference="users"
              source="manager"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ fullName: searchText })}
                fullWidth
                helperText={false}
                label="Руководитель группы"
                name="manager"
                optionText={(record: RaRecord) =>
                  record && `${record.firstName} ${record.lastName}`
                }
                size="small"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              filter={{
                'roleEntities.alias': [Role.Finance],
              }}
              perPage={200}
              reference="users"
              source="members"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({
                  fullName: searchText,
                })}
                helperText={false}
                label="Сотрудники"
                name="members"
                optionText="name"
                renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
                size="small"
              />
            </ReferenceArrayInput>
          </FilterFieldGroup>
          <FilterActions>
            <FilterApplyButton type="submit" />
            <FilterResetButton onClick={resetFilter} />
          </FilterActions>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
