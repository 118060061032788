import { useGetList } from 'react-admin';
import { Avatar } from 'shared/mui/Avatars';
import { Badge } from 'shared/mui/Badge/Badge';
import { Box } from 'shared/mui/Box';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields/ReferenceField/ReferenceField';
import { RoleCategory, RoleEntity, User } from 'types';

import { SmallAvatar } from './UserActionUserField.styled';

export const UserActionUserField: React.FC = () => {
  const { data: categories } = useGetList<RoleCategory>('role_categories');

  const getRoleImg = (record: RoleEntity) => {
    if (!record || !categories) {
      return <></>;
    }
    const current = categories?.find((category) => category.id === record.roleCategories);
    return (
      <Tooltip arrow title={record.name}>
        <SmallAvatar src={current?.description} />
      </Tooltip>
    );
  };

  return (
    <FunctionField
      link={false}
      render={(record: User) => {
        const { firstName, lastName } = record;
        return (
          <Box display="flex" gap={1}>
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <ReferenceField link={false} reference="roles" source="roleEntities[0]">
                  <FunctionField render={(record: RoleEntity) => getRoleImg(record)} />
                </ReferenceField>
              }
              overlap="circular"
            >
              <Avatar>{`${firstName[0]}${lastName[0]}`.toUpperCase()}</Avatar>
            </Badge>
            <div>
              <Typography gutterBottom variant="body2">{`${firstName} ${lastName}`}</Typography>
              <Typography color="#00000061" variant="body2">
                {record?.corporateEmail || record?.email}
              </Typography>
            </div>
          </Box>
        );
      }}
    />
  );
};
