import { useCallback, useEffect } from 'react';

import { useListContext } from 'react-admin';
import { FieldValues, Path, PathValue, useForm, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getDeepValueByPath, updateObjectPropByPath } from 'utils';

export type ResetFilterBySource<TFilterValues extends FieldValues> = (
  source: Path<TFilterValues>,
) => void;
export type ResetFilterArrayById<TFilterValues extends FieldValues> = (
  source: Path<TFilterValues>,
  id: string,
) => void;

type UseFiltersReturnValues<TFilterValues extends FieldValues> = {
  form: UseFormReturn<TFilterValues, any>;
  onSubmit: (values: TFilterValues) => void;
  resetFilter: () => void;
  resetFilterBySource: ResetFilterBySource<TFilterValues>;
  resetFilterArrayById: ResetFilterArrayById<TFilterValues>;
};

export function useFilters<TFilterValues extends FieldValues, TContext = any>(
  resetValues: TFilterValues,
  defaultValues?: TFilterValues,
): UseFiltersReturnValues<TFilterValues> {
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const [searchParams] = useSearchParams();
  const form = useForm<TFilterValues, TContext>({
    defaultValues: defaultValues || filterValues,
  });

  const onSubmit = useCallback(
    (values: TFilterValues) => {
      setFilters(values, displayedFilters);
    },
    [setFilters],
  );

  const resetFilter = useCallback(() => {
    form.reset(resetValues);
    setFilters({}, displayedFilters);
  }, [setFilters]);

  const resetFilterBySource = useCallback(
    (source: Path<TFilterValues>) => {
      form.setValue(source, undefined as PathValue<TFilterValues, any>);
      setFilters(updateObjectPropByPath(filterValues, source, undefined), displayedFilters);
    },
    [setFilters],
  );

  // нужен для сброса полей формы фильров, если в url нет параметров
  useEffect(() => {
    if (!searchParams.size && !!setFilters) {
      resetFilter();
    }
  }, [searchParams]);

  const resetFilterArrayById = useCallback(
    (source: Path<TFilterValues>, id: string) => {
      function getValuesWithoutId<T>(oldValues: T) {
        // @ts-ignore
        return oldValues.filter((item: string) => item !== id);
      }

      form.setValue(source, getValuesWithoutId(form.getValues(source)));
      setFilters(
        updateObjectPropByPath(
          filterValues,
          source,
          getValuesWithoutId(getDeepValueByPath(filterValues, source)),
        ),
        displayedFilters,
      );
    },
    [setFilters],
  );

  return {
    form,
    onSubmit,
    resetFilter,
    resetFilterBySource,
    resetFilterArrayById,
  };
}
