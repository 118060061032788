import { ReactElement } from 'react';

import get from 'lodash/get';
import {
  RaRecord,
  ReferenceOneField as RaReferenceOneField,
  ReferenceOneFieldProps as RaReferenceOneFieldProps,
  useRecordContext,
  useReferenceOneFieldController,
} from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Typography } from 'shared/mui/Typography';

export type ReferenceOneFieldProps = RaReferenceOneFieldProps & {
  emptyComponent?: ReactElement;
};

export const ReferenceOneField = (props: ReferenceOneFieldProps): JSX.Element | null => {
  const { children, emptyComponent, emptyText, filter = {}, source, ...restProps } = props;
  const record = useRecordContext(props);
  const { isLoading, referenceRecord } = useReferenceOneFieldController<RaRecord>({
    filter: { ...filter, groups: ['reference'] },
    record: get(record, source) !== null ? record : undefined,
    source,
    ...restProps,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  function getEmptyComponent(): JSX.Element | null {
    if (emptyComponent) {
      return emptyComponent;
    }

    if (emptyText) {
      return (
        <Typography component="span" variant="body2">
          {emptyText}
        </Typography>
      );
    }

    return null;
  }

  if (!record || (!isLoading && referenceRecord == null)) {
    return getEmptyComponent();
  }

  return <RaReferenceOneField {...props}>{children}</RaReferenceOneField>;
};
