import { useEffect, useRef, useState } from 'react';

import { ScrollableBoxStyled } from './ScrollableBox.styled';
import { BoxProps } from '../Box';

export type ScrollableBoxProps = BoxProps;

export const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children, ...restProps }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [left, setLeft] = useState<number>(0);
  const [drag, setDrag] = useState<boolean>(false);
  const [coordinateX, setCoordinateX] = useState<number>(0);

  useEffect(() => {
    document.addEventListener('mouseup', () => {
      if (ref.current) {
        setDrag(false);
        setLeft(ref.current.scrollLeft);
      }
    });
  });

  return (
    <ScrollableBoxStyled
      onMouseDown={(event) => {
        if (ref.current) {
          setDrag(true);
          setCoordinateX(event.pageX - ref.current.offsetLeft);
        }
      }}
      onMouseMove={(event) => {
        if (drag && ref.current) {
          ref.current.scrollLeft = left - (event.pageX - ref.current.offsetLeft - coordinateX);
        }
      }}
      ref={ref}
      {...restProps}
    >
      {children}
    </ScrollableBoxStyled>
  );
};
