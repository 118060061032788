import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { OrderAdjustments } from '../../../types/orderAdjustments';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  record: OrderAdjustments | undefined;
};

export const OrderAdjustmentsReportConfirmSearchDialog: React.FC<Props> = ({
  open,
  record,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ width: '600px' }}
        title="Поиск связанных корректировок по заказу"
      />
      <CardContent>Выполнить поиск связанных операций для заказа {record?.originId}?</CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <Button key="save-button" label="Подтвердить" onClick={onConfirm} variant="contained" />,
          <CancelButton
            key="cancel-button"
            label="отменить"
            onClick={onClose}
            variant="outlined"
          />,
        ]}
      />
    </Dialog>
  );
};
