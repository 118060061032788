import React from 'react';

import { Box, Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)({
  '.MuiBackdrop-root': {
    transition: 'none !important',
  },
  '.MuiCardContent-root': {
    padding: '0 24px 24px 24px',
  },
  '.MuiPaper-root': {
    overflowY: 'visible',
  },
});

export function AppDialogBase({ children, ...rest }: DialogProps): JSX.Element {
  return (
    <StyledDialog fullWidth {...rest}>
      <Box p={3} pt={2}>
        {children}
      </Box>
    </StyledDialog>
  );
}
