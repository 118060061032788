import { Validator } from 'react-admin';

import { IP_REGEX } from '../constants/ipRegex';

export function useIpValidation(): Validator {
  const ipValidation = (value: string) => {
    if (!IP_REGEX.test(value)) {
      return 'Введите корректный IP-адрес';
    }
    return undefined;
  };

  return ipValidation;
}
