import { Accordion, styled } from '@mui/material';

import { AccordionSummary } from '../../../../../shared/mui/Accordion';
import { appColors } from '../../../../../themes/variables';

export const StyledAccordion = styled(Accordion)({
  borderRadius: '0 !important',
  borderLeft: 0,
  borderRight: 0,
  '&.MuiAccordion-root::before': { backgroundColor: 'transparent' },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  '.MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': { margin: 0 },
  },
  '&.MuiAccordionSummary-root:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  paddingRight: 0,
  borderBottom: `1px solid ${appColors.divider}`,
});
