import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InputAdornment } from '@mui/material';
import { blue } from '@mui/material/colors';
import { IconButton } from 'shared/mui/IconButtons';
import { TextFieldProps } from 'shared/mui/TextField/TextField';
import { Tooltip } from 'shared/mui/Tooltip';

import { TextFieldStyled } from './TextFieldWithCopyButton.styled';

export type TextFieldWithCopyButtonProps = { value?: string } & TextFieldProps;

export const TextFieldWithCopyButton: React.FC<TextFieldWithCopyButtonProps> = ({
  value,
  ...props
}) => {
  const [text, setText] = useState<string>(value || '');
  const [open, setOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipText('Cкопировано');
      setOpen(true);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <TextFieldStyled
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpen(false)}
              open={open}
              title={tooltipText}
            >
              <IconButton edge="end" onClick={handleCopy}>
                <ContentCopyIcon sx={{ color: blue[700] }} />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      value={text}
    />
  );
};
