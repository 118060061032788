import ContentCreate from '@mui/icons-material/Create';
import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  EditButton,
  EditButtonProps,
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import { useCreateEntityFilterQueryString } from '../../../../hooks/useCreateEntityFilterQueryString';
import { appColors } from '../../../../themes/variables';

const StyledEditButton = styled(EditButton)({
  color: appColors.secondary.main,
  height: '28px',
  minWidth: '28px',
  padding: 0,
  '& .MuiButton-startIcon': {
    padding: 0,
    margin: 0,
  },
  '&:hover': { borderRadius: '50%' },
});

type AppListEditButtonProps = {
  iconSx?: SxProps<Theme>;
  entity?: string;
} & EditButtonProps;

export function AppListEditButton({
  label = '',
  variant,
  entity,
  iconSx,
  ...restProps
}: AppListEditButtonProps): JSX.Element {
  const createPath = useCreatePath();
  const resource = useResourceContext(restProps);
  const record = useRecordContext(restProps);

  const { createEntityFilterQueryString } = useCreateEntityFilterQueryString();
  const search = entity ? createEntityFilterQueryString({ entity }) : '';
  const icon = restProps.icon || <ContentCreate sx={iconSx} />;

  return (
    <StyledEditButton
      icon={icon}
      label={label}
      to={{ pathname: createPath({ type: 'edit', resource, id: record.id }), search }}
      variant={variant}
      {...restProps}
    />
  );
}
