import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { NumberInput as RaNumberInput, NumberInputProps as RaNumberInputProps } from 'react-admin';

import { fieldWidth } from '../../../../themes/variables';

export type NumberInputProps = RaNumberInputProps;

const defaultSx: SxProps<Theme> = {
  '&.MuiFormControl-root': {
    marginTop: 0,
  },
  '.MuiFormHelperText-root': {
    marginTop: 0,
  },
  ...fieldWidth.medium,
};

export const NumberInput: React.FC<RaNumberInputProps> = ({
  sx,
  ...restProps
}: NumberInputProps) => <RaNumberInput sx={{ ...defaultSx, ...sx }} {...restProps} />;
