import { RecordContextProvider } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { BooleanField, DateTimeField, TextField } from 'shared/react-admin/Fields';
import { YesIcon } from 'shared/react-admin/Fields/BooleanField/BooleanField';

import { usePermissions } from '../../../../../hooks/usePermissions';
import { MerchantNotification, Permission } from '../../../../../types';
import { useGetNotificationTransports } from '../hooks/useGetNotificationTransports';
import { useSendTestNotification } from '../hooks/useSendTestNotification';
import { getNotificationTransportName } from '../utils/getNotificationTransportName';

type Props = {
  record: MerchantNotification;
  open: boolean;
  onEdit: (record: MerchantNotification) => void;
  onDelete: (record: MerchantNotification) => void;
  onClose: VoidFunction;
  onSendNotification: VoidFunction;
};

export const MerchantsShowNotificationsShowDialog: React.FC<Props> = ({
  onClose,
  onDelete,
  onEdit,
  open,
  record,
  onSendNotification,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { notificationTransports } = useGetNotificationTransports();
  const notificationTransportName = getNotificationTransportName(
    record?.notificationTransport,
    notificationTransports,
  );
  const { isLoading, sendTestNotification } = useSendTestNotification(record.id, () => {
    onSendNotification();
    onClose();
  });

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <CardHeader
          actions={[
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton
                entityId={record?.id}
                entityName="merchant_notification_settings"
                key="user-action-link"
              />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр настроек оповещения"
        />
        <CardTable sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRowWrapper title="Активно">
              <BooleanField source="active" />
            </TableRowWrapper>
            <TableRowWrapper title="Тип уведомления">
              {`${notificationTransportName.charAt(0).toLocaleUpperCase()}${notificationTransportName.slice(1).toLowerCase()}`}
            </TableRowWrapper>
            {notificationTransportName.toLowerCase() === 'email' && (
              <TableRowWrapper title="Email">
                <TextField source="credentials.email" />
              </TableRowWrapper>
            )}
            {notificationTransportName.toLowerCase() === 'reddy' && (
              <>
                <TableRowWrapper title="ID канала">
                  <TextField source="credentials.chatId" />
                </TableRowWrapper>
                <TableRowWrapper title="Токен">
                  <TextField source="credentials.token" />
                </TableRowWrapper>
              </>
            )}
            <TableRowWrapper title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
            {record?.events.map(({ name: { description, value } }) => (
              <TableRowWrapper key={value} title={description}>
                <YesIcon />
              </TableRowWrapper>
            ))}
            <TableRowWrapper title="Тестовое сообщение">
              <Button
                isLoading={isLoading}
                label="Отправить"
                onClick={sendTestNotification}
                size="small"
                variant="outlined"
              />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Update]) && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  onEdit(record);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Delete]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  onDelete(record);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
