export const EXPORT_FIELDS_ORDERS_REPORT = [
  {
    index: '0',
    source: 'id',
    label: 'Kubera ID',
  },
  {
    index: '1',
    source: 'createdAt',
    label: 'Создан',
  },
  {
    index: '2',
    source: 'completedAt',
    label: 'Исполнен',
  },
  {
    index: '3',
    source: 'amount',
    label: 'Сумма заказа',
  },
  {
    index: '4',
    source: 'currency',
    label: 'Валюта заказа',
  },
  {
    index: '5',
    source: 'commissionValueByCurrencyOrder',
    label: 'Комиссия по заказу',
  },
  {
    index: '6',
    source: 'commissionCurrency',
    label: 'Валюта комисси по заказу',
  },
  {
    index: '7',
    source: 'commissionValueByCurrencyCommission',
    label: 'Комиссия по правилу',
  },
  {
    index: '8',
    source: 'conversionCurrency',
    label: 'Валюта комиссии по правилу',
  },
  {
    index: '9',
    source: 'commission',
    label: 'Комиссионное правило',
  },

  {
    index: '10',
    source: 'totalAmount',
    label: 'Итого по заказу',
  },
  {
    index: '11',
    source: 'direction',
    label: 'Направление',
  },
  {
    index: '12',
    source: 'merchant',
    label: 'Мерчант',
  },
  {
    index: '13',
    source: 'gateway',
    label: 'Шлюз',
  },
  {
    index: '14',
    source: 'method',
    label: 'Метод',
  },
  {
    index: '15',
    source: 'merchantAccount',
    label: 'Счет',
  },
  {
    index: '16',
    source: 'merchantReference',
    label: 'Мерчант ID',
  },
  {
    index: '17',
    source: 'providerReferenceId',
    label: 'Провайдер ID',
  },
];
