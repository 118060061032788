import SearchIcon from '@mui/icons-material/Search';

import { Button, ButtonProps } from '../../../Buttons';

export type FilterButtonProps = ButtonProps;

export const FilterIdButton: React.FC<FilterButtonProps> = ({
  label = 'Поиск по ID',
  color = 'secondary',
  ...restProps
}) => {
  return <Button color={color} label={label} startIcon={<SearchIcon />} {...restProps} />;
};
