import { blue } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';

type Props = {
  isAnd: boolean;
};

export function GatewayRuleGroupIsAnd({ isAnd }: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '8px 0px',
        width: '32px',
        height: '32px',
        backgroundColor: blue[50],
        borderColor: blue[700],
        color: blue[700],
        borderRadius: '4px',
        fontSize: '13px',
        borderWidth: '1px',
        borderStyle: 'solid',
        textTransform: 'lowercase',
      }}
    >
      {isAnd ? 'И' : 'ИЛИ'}
    </Box>
  );
}
