import React from 'react';

import { Check, DoNotDisturbAlt } from '@mui/icons-material';
import { useGetOne } from 'react-admin';
import { TableCell, TableCellHeader, TableRow } from 'shared/mui/Table';
import { appColors } from 'themes/variables';
import { MerchantSpacesUsersSite } from 'types';

export const SiteAvailabilityRow: React.FC<{
  merchantSpacesUsersSite: MerchantSpacesUsersSite;
}> = ({ merchantSpacesUsersSite }) => {
  const { data } = useGetOne('merchants', { id: merchantSpacesUsersSite?.sourceMerchantId });
  return (
    <TableRow>
      <TableCellHeader title={data?.name} width={165} />
      <TableCell>
        {merchantSpacesUsersSite.active ? (
          <Check fontSize="small" htmlColor={appColors.success.light} />
        ) : (
          <DoNotDisturbAlt fontSize="small" htmlColor={appColors.error.light} />
        )}
      </TableCell>
    </TableRow>
  );
};
