import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne } from 'react-admin';
import { Chip } from 'shared/mui/Chips';

export const DirectionField: React.FC<{ direction: string }> = ({
  direction,
}: {
  direction: string;
}) => {
  const { data, isLoading } = useGetOne(
    'gateway_directions',
    {
      id: `/admin/gateway_directions/${direction}`,
    },
    { enabled: !!direction },
  );

  if (!direction || isLoading) {
    return EMPTY_FIELD;
  }

  return <Chip label={data?.description} />;
};
