import { usePermissions } from 'hooks/usePermissions';
import { useGetOne, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Typography } from 'shared/mui/Typography';
import { TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MappingItemRecord, MethodLogo } from 'types';

import { BoxStyled, ImgStyled } from './EditPayoutControlMappingFieldCardHeader.styled';
import { useCurrentMappingDataContext } from '../../../../context/CurrentMappingContext';

type Props = {
  isLoading?: boolean;
  mapping: MappingItemRecord;
  onChoice?: () => void;
  onDelete?: () => void;
  onReplace?: () => void;
};

export const EditPayoutControlMappingFieldCardHeader: React.FC<Props> = ({
  isLoading,
  mapping,
  onChoice,
  onDelete,
  onReplace,
}) => {
  const { hasResourceAccess } = usePermissions();
  const record = useRecordContext();
  const { setMappingData, mappingChosen } = useCurrentMappingDataContext();

  const { data: method, isLoading: isMethodLoading } = useGetOne('methods', { id: record.method });
  const { data: logo, isLoading: isLogoLoading } = useGetOne<MethodLogo>('method_logos', {
    id: method?.logo,
  });

  const isDisabledButton = isLoading || isMethodLoading;
  const isDisabledChoseButton = isDisabledButton || record.id === mappingChosen?.id;

  const handleClick = () => {
    setMappingData({ method, mapping });
    onChoice?.();
  };

  return (
    <BoxStyled sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
      <BoxStyled>
        {isLogoLoading ? (
          <LinearProgress sx={{ width: '56px' }} />
        ) : (
          <ImgStyled src={logo?.filePath} />
        )}
        <BoxStyled sx={{ margin: '0 16px' }}>
          <Typography component="span" mr={1} variant="subtitle2">
            Метод:
          </Typography>
          {hasResourceAccess('methods') && (
            <ReferenceOneField link="show" reference="methods" source="method" target="id">
              <TextField source="name" />
            </ReferenceOneField>
          )}
        </BoxStyled>
        <BoxStyled>
          <Typography component="span" mr={1} variant="subtitle2">
            Направление:
          </Typography>
          <Typography component="span" variant="body2">
            Вывод
          </Typography>
        </BoxStyled>
      </BoxStyled>
      <Box display="flex" gap="12px">
        {onChoice && (
          <Button
            disabled={isDisabledChoseButton}
            label="выбрать"
            onClick={handleClick}
            size="small"
            variant="outlined"
          />
        )}
        {onReplace && (
          <Button
            disabled={isDisabledButton}
            label="заменить"
            onClick={onReplace}
            size="small"
            variant="outlined"
          />
        )}
        {onDelete && (
          <Button
            color="error"
            disabled={isDisabledButton}
            label="удалить"
            onClick={onDelete}
            size="small"
            variant="outlined"
          />
        )}
      </Box>
    </BoxStyled>
  );
};
