import { useEffect, useState } from 'react';

import { OutlinedInput, SelectChangeEvent } from '@mui/material';
import { useInput } from 'react-admin';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { Select as SelectMui, SelectProps as SelectPropsMui } from 'shared/mui/Select/Select';

import { StyledFormControl } from './Select.styled';

export type SelectItem<T> = {
  value: T;
  label: string;
  description?: string;
};

export type SelectProps<T> = {
  items?: SelectItem<T>[];
  label: string;
  renderValue?: (props: SelectItem<T>) => JSX.Element;
  source: string;
  shrink?: boolean;
  defaultValue?: SelectItem<T>;
} & Omit<SelectPropsMui, 'renderValue'>;

export function Select<T>({
  children,
  defaultValue,
  fullWidth,
  items,
  label,
  onChange,
  renderValue,
  size = 'medium',
  shrink = false,
  sx,
  ...props
}: SelectProps<T>) {
  const [selectedValue, setSelectedValue] = useState<string[] | null>(null);
  const { onBlur, source, ...rest } = props;

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue([defaultValue.value as string]);
    }
  }, []);

  const { field } = useInput({
    source,
    type: 'text',
    onBlur,
    onChange,
    ...rest,
  });

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setSelectedValue([value as string]);
    event.target.value = [value];
    field.onChange(event);
  };

  function renderCurrentValue<T>(currentValue: T[]) {
    const item = items?.find((item) => (item.value as string) === currentValue[0]);

    return item ? renderValue?.(item) : undefined;
  }

  return (
    <StyledFormControl fullWidth={fullWidth} sx={sx} variant="outlined">
      <InputLabel htmlFor="select-label" shrink={shrink}>
        {label}
      </InputLabel>
      <SelectMui
        {...props}
        id="select-label"
        label={label}
        onChange={handleChange}
        renderValue={renderValue && renderCurrentValue}
        size={size}
        value={selectedValue || field.value}
        {...(shrink && { input: <OutlinedInput id="select-label" label={label} notched /> })}
      >
        {children}
      </SelectMui>
    </StyledFormControl>
  );
}
