import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useEffect, useMemo } from 'react';

import { ListContextProvider, useList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { CreateButton } from 'shared/mui/Buttons';
import { PageHeader } from 'shared/mui/PageHeader';
import { MerchantSpacesUsers } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';
import { getBooleanFromString } from 'utils/getBooleanFromString';

import { MerchantSpacesUsersListFilter } from './components/MerchantSpacesUsersListFilter';
import { MerchantSpacesUsersDatagrid } from '../../../shared/widgets/MerchantSpacesUsersDatagrid/MerchantSpacesUsersDatagrid';
import { useGetMerchantSpacesUsers } from '../hooks/useGetMerchantSpacesUsers';
import { useMerchantSpacesUsersFilters } from '../hooks/useMerchantSpacesUsersFilter';

export type MerchantSpacesUsersFilter = {
  active?: boolean | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  merchantSpaceId?: string;
  role?: string | null;
  page: number;
  itemsPerPage: number;
};

export const MerchantSpacesUsersList: React.FC = () => {
  const [searchParams] = useSearchParams();

  const searchParamsFilter: MerchantSpacesUsersFilter = useMemo(() => {
    return cleanEmpty({
      email: searchParams.get('email'),
      active: getBooleanFromString(searchParams.get('active')),
      merchantSpaceId: searchParams.get('merchantSpaceId'),
      role: searchParams.get('role'),
      firstName: searchParams.get('firstName'),
      lastName: searchParams.get('lastName'),

      page: Number(searchParams.get('page')) || DEFAULT_PAGINATION.page,
      itemsPerPage: Number(searchParams.get('itemsPerPage')) || DEFAULT_PAGINATION.itemsPerPage,
    });
  }, [searchParams]);

  const { form, onSubmit, resetFilter, resetFilterBySource } = useMerchantSpacesUsersFilters();

  const { merchantSpacesUsers, refetch, isLoading } = useGetMerchantSpacesUsers(searchParamsFilter);

  const list = useList<MerchantSpacesUsers>({
    data: merchantSpacesUsers?.items || [],
    page: form.getValues('page'),
    perPage: form.getValues('itemsPerPage'),
  });

  useEffect(() => {
    form.reset(searchParamsFilter);
  }, []);

  return (
    <ListContextProvider value={list}>
      <PageHeader
        actions={[<CreateButton key="create-button" />]}
        headerTitle={{
          titleText: 'Пользователи мерчантов',
        }}
        mt={3}
      />
      <MerchantSpacesUsersListFilter
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
        resetFilterBySource={resetFilterBySource}
      />
      <MerchantSpacesUsersDatagrid
        form={form}
        isLoading={isLoading}
        refetch={refetch}
        total={merchantSpacesUsers?.totalItems}
      />
    </ListContextProvider>
  );
};
