import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { Button, DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { CompanyShowDialogTable } from 'shared/widgets/CompanyShowDialog/CompanyShowDialogTable';
import { Company, Permission } from 'types';

import { CompaniesArchiveDialog } from './components/CompaniesArchiveDialog/CompaniesArchiveDialog';
import { CompaniesDeleteDialog } from './components/CompaniesDeleteDialog/CompaniesDeleteDialog';
import { CompaniesUnarchiveDialog } from './components/CompaniesUnarchiveDialog/CompaniesUnarchiveDialog';

export const CompaniesShow = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Company>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="company" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModalDelete && !openRecordModalArchive && !openRecordModalUnarchive}
        title="Просмотр компании"
      >
        <CompanyShowDialogTable />
        <CardActionsButtons
          leftActionsSlot={[
            hasResourcePermissions('companies', [Permission.Full, Permission.Update]) &&
              (record?.archive ? (
                <Button
                  key="archive-button"
                  label="восстановить"
                  onClick={() => handleOpenRecordModalUnarchive()}
                  variant="contained"
                />
              ) : (
                <EditButton />
              )),
          ]}
          rightActionsSlot={
            hasResourcePermissions('companies', [Permission.Full, Permission.Delete]) &&
            (record?.archive ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : (
              <DeleteButton
                label="В архив"
                onClick={() => handleOpenRecordModalArchive()}
                variant="outlined"
              />
            ))
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <CompaniesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
      {openRecordModalArchive && (
        <CompaniesArchiveDialog
          isToRootOnSuccess={true}
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={record}
        />
      )}
      {openRecordModalUnarchive && (
        <CompaniesUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={record}
        />
      )}
    </>
  );
};
