import { EMPTY_FIELD } from 'constants/emptyField';

import { Typography } from '@mui/material';
import { AppReferenceOneField } from 'components/Common';
import { useRecordModal } from 'hooks/useRecordModal';
import {
  Datagrid,
  FunctionField,
  NumberField,
  RaRecord,
  ReferenceArrayField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { LinkChip } from 'shared/mui/Chips';
import { DateTimeField } from 'shared/react-admin/Fields';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { Commission, Transaction } from 'types';
import { formatCurrency } from 'utils';

import { OrdersShowTableCard } from './OrdersShowTableCard';
import { Role } from '../../../constants/roles';
import { usePermissions } from '../../../hooks/usePermissions';

export const OrdersShowTransactions = (): JSX.Element => {
  const { hasResourceAccess, roles } = usePermissions();
  const { record, handleOpenRecordModal, handleCloseRecordModal, openRecordModal } =
    useRecordModal<Commission>();

  return (
    <>
      {hasResourceAccess('transactions') && (
        <OrdersShowTableCard>
          <Typography component="h2" fontWeight={500} mb={2} ml={2} mt={2} variant="h6">
            Транзакции
          </Typography>
          <ReferenceManyField
            label={false}
            reference="transactions"
            sort={{ field: 'id', order: 'ASC' }}
            target="order"
          >
            <Datagrid
              bulkActionButtons={false}
              sx={{
                border: 'none',
                '& .MuiTableCell-root': { padding: '16px' },
                '& .MuiTableCell-head': {
                  verticalAlign: 'top',
                },
              }}
            >
              <TextField label="ID" source="originId" />
              <DateTimeField label="Время создания" source="createdAt" />
              {hasResourceAccess('transaction_types') && (
                <AppReferenceOneField
                  label="Тип транзакции"
                  reference="transaction_types"
                  source="type"
                  target="id"
                >
                  <TextField source="name" />
                </AppReferenceOneField>
              )}
              <NumberField label="Сумма" options={{ minimumFractionDigits: 2 }} source="amount" />
              {hasResourceAccess('currencies') && (
                <AppReferenceOneField
                  label="Валюта"
                  reference="currencies"
                  source="currency"
                  target="id"
                >
                  <FunctionField
                    render={(record: RaRecord | undefined) =>
                      `${record?.alphaCode}, ${record?.name}`
                    }
                  />
                </AppReferenceOneField>
              )}
              {hasResourceAccess('commissions') && (
                <ReferenceArrayField
                  label="Комиссии для расчёта"
                  reference="commissions"
                  source="commissions"
                >
                  <SingleFieldList empty={<Typography>{EMPTY_FIELD}</Typography>} linkType={false}>
                    <FunctionField
                      render={(record: Commission) => (
                        <LinkChip
                          label={record.name}
                          onClick={() => {
                            handleOpenRecordModal(record);
                          }}
                          size="medium"
                        />
                      )}
                    />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}
              {!roles.includes(Role.Support) && (
                <FunctionField
                  label="Значение комиссии"
                  render={(record: Transaction) => {
                    const commissionValues = record?.commissionValue?.split(' / ') || '';
                    return record?.commissionValue
                      ? commissionValues
                          ?.map((commissionValue: string) => {
                            const [value, currency] = commissionValue?.split(' ') || '';
                            return `${formatCurrency(Number(value))} ${currency} `;
                          })
                          .join(' / ')
                      : EMPTY_FIELD;
                  }}
                />
              )}
            </Datagrid>
          </ReferenceManyField>
          {record && (
            <CommissionShowDialog
              commission={record}
              onClose={handleCloseRecordModal}
              open={openRecordModal}
            />
          )}
        </OrdersShowTableCard>
      )}
    </>
  );
};
