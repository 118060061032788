import { useCallback, useMemo, useState, Dispatch, SetStateAction } from 'react';

import { TablePaginationProps } from '@mui/material';
import { PaginationActions, useListContext, useTranslate } from 'react-admin';

import { paginate } from '../utils';

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

type Props<T> = {
  data?: T[];
  component?: string;
  defaultRowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  defaultPage?: number;
} & Pick<TablePaginationProps, 'ActionsComponent'>;

export type UseLocalDatagridPaginationReturnValue<T> = Pick<
  TablePaginationProps,
  | 'labelDisplayedRows'
  | 'labelRowsPerPage'
  | 'page'
  | 'rowsPerPage'
  | 'onPageChange'
  | 'onRowsPerPageChange'
  | 'rowsPerPageOptions'
  | 'ActionsComponent'
> & {
  component?: string;
  dataPerPage: T[];
  setPage: Dispatch<SetStateAction<number>>;
};

export function useLocalDatagridPagination<T>({
  data = [],
  defaultPage = 0,
  defaultRowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  defaultRowsPerPage = DEFAULT_ROWS_PER_PAGE,
  /* @ts-expect-error */
  ActionsComponent = PaginationActions,
  component = 'span',
}: Props<T>): UseLocalDatagridPaginationReturnValue<T> {
  const translate = useTranslate();

  const labelDisplayedRows = useCallback(
    ({ from, to, count }: { from: number; to: number; count: number }) =>
      count === -1
        ? translate('ra.navigation.partial_page_range_info', {
            offsetBegin: from,
            offsetEnd: to,
            _: '%{from}-%{to} of more than %{to}',
          })
        : translate('ra.navigation.page_range_info', {
            offsetBegin: from,
            offsetEnd: to,
            total: count === -1 ? to : count,
            _: '%{from}-%{to} of %{count === -1 ? to : count}',
          }),
    [translate],
  );

  const labelRowsPerPage = translate('ra.navigation.page_rows_per_page');

  const { filterValues } = useListContext();
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(defaultPage);

  useMemo(() => {
    setPage(0);
  }, [filterValues]);

  const onPageChange = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [rowsPerPage, setPage],
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const dataPerPage: T[] = useMemo(() => {
    if (rowsPerPage !== undefined && page !== undefined) {
      return paginate(data, rowsPerPage, page);
    }
    return data;
  }, [data, rowsPerPage, page]);

  return {
    dataPerPage,
    component,
    ActionsComponent,
    labelDisplayedRows,
    labelRowsPerPage,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPageOptions: defaultRowsPerPageOptions,
    setPage,
  };
}
