import { MAX_AMOUNT } from '../constants';

export const transformAmountFilterValue = (amountFrom: string, amountTo: string): string => {
  if (amountFrom && amountTo) {
    return amountFrom + '..' + amountTo;
  } else if (amountFrom) {
    return amountFrom + '..' + MAX_AMOUNT;
  } else if (amountTo) {
    return '0' + '..' + amountTo;
  } else {
    return '';
  }
};
