import React from 'react';

import History from '@mui/icons-material/History';
import HotTub from '@mui/icons-material/HotTub';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

const NotFound = (): JSX.Element => {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <>
      {localStorage.getItem('token') ? (
        <Card sx={{ height: '100%', width: '100%', backgroundColor: '#F9FAFB' }}>
          <CardContent
            sx={{
              margin: '15% auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HotTub
              sx={{
                display: 'inline-block',
                color: '#808080',
                width: '200px',
                height: '200px',
              }}
            />
            <Typography
              align="center"
              component="h3"
              sx={{ color: '#808080' }}
              variant="h3"
              width="100%"
            >
              404
            </Typography>
            <Typography
              align="center"
              component="h4"
              mb={2}
              sx={{ color: '#808080' }}
              variant="h4"
              width="100%"
            >
              Страница не найдена
            </Typography>
            <Button onClick={goBack} startIcon={<History />} variant="contained">
              Назад
            </Button>
          </CardContent>
        </Card>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default NotFound;
