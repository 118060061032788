import { CreateButton } from 'shared/mui/Buttons';
import { EditDialogIconButton, ShowDialogIconButton } from 'shared/mui/IconButtons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { TextField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { RolesListFilter } from './RolesListFilter';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';
import { RoleNameField } from '../components/RoleNameField';
import { RolesStatusesChipsField } from '../components/RolesStatusesChipsField';

export const RolesList: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('roles', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} />
        ),
      ]}
      headerTitle={{ titleText: 'Роли' }}
    >
      <RolesListFilter />
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <RoleNameField label="Название" />
        <TextField label="Алиас" source="alias" />
        <RolesStatusesChipsField label="Статусы заказа" />
        <ActionsSlots
          render={() => (
            <>
              <ShowDialogIconButton />
              {hasResourcePermissions('roles', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
            </>
          )}
        />
      </Datagrid>
    </ListPage>
  );
};
