import { EMPTY_FIELD } from 'constants/emptyField';

import { LinearProgress } from '@mui/material';
import { useListContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Merchant } from 'types';

import { ApiClientsMerchantFieldLinkedChip } from './ApiClientsMerchantFieldLinkedChip';
import { ApiClientsMerchantFieldLinkedChipAll } from './ApiClientsMerchantFieldLinkedChipAll';

// Параметр, указывающий сколько мерчантов отображать полностью, остальные будут спятаны в Chip
//  с указанием количеста и ссылкой на текущегоapi клиента api
const CHIPS_COUNT = 2;

const renderChips = (chips: Merchant[]) =>
  chips.map(({ name, id }, index) => (
    <ApiClientsMerchantFieldLinkedChip
      id={id}
      isLast={index === chips.length - 1}
      key={name}
      label={name}
    />
  ));

export const ApiClientsMerchantFieldList = (): JSX.Element => {
  const { data, isLoading } = useListContext<Merchant>();
  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data.length) {
    return <span>{EMPTY_FIELD}</span>;
  }

  return (
    <Box display="flex" flexDirection="column">
      {renderChips(data.slice(0, CHIPS_COUNT))}
      {data.length > CHIPS_COUNT && (
        <ApiClientsMerchantFieldLinkedChipAll label={`+${data.length - CHIPS_COUNT}`} />
      )}
    </Box>
  );
};
