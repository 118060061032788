import { format } from 'date-fns';
import { usePermissions } from 'hooks/usePermissions';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { GatewayRuleRecord } from 'types';

export function GatewayRuleInfo(): JSX.Element {
  const { hasResourceAccess } = usePermissions();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box alignItems="center" display="flex" mr={2}>
        <TextField fontSize="18px" source="name" />
      </Box>
      <Box
        display="flex"
        sx={{
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        <Box alignItems="center" display="flex" mr={2}>
          <Typography fontWeight={500} marginRight="5px">
            ID:
          </Typography>
          <TextField fontSize="14px" source="originId" />
        </Box>
        <Box alignItems="center" display="flex" mr={2}>
          <Typography fontWeight={500} marginRight="5px">
            Мерчант:
          </Typography>
          {hasResourceAccess('merchants') && (
            <ReferenceOneField
              link="show"
              reference="merchants"
              sortBy="merchant.name"
              source="merchant"
              target="id"
            >
              <TextField fontSize="14px" source="name" />
            </ReferenceOneField>
          )}
        </Box>
        <Box alignItems="center" display="flex" mr={2}>
          <Typography fontWeight={500} marginRight="5px">
            Дата создания:
          </Typography>
          <FunctionField
            render={(record: GatewayRuleRecord) =>
              format(new Date(record?.createdAt), 'dd.MM.yyyy, HH:mm')
            }
          />
        </Box>
        <Box alignItems="center" display="flex">
          <Typography fontWeight={500} marginRight="5px">
            Дата редактир.:
          </Typography>
          <FunctionField
            render={(record: GatewayRuleRecord) =>
              format(new Date(record?.updatedAt), 'dd.MM.yyyy, HH:mm')
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
