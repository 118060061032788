import { FieldValues, Path } from 'react-hook-form';

import { FilterChipType } from '../../../../../constants/filterChipType';
import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ResetFilterArrayById, ResetFilterBySource } from '../../../../../hooks/useFilters';
import { FilterChipsAmountBetweenField } from '../FilterChipsFields/FilterChipsAmountBetweenField';
import { FilterChipsBooleanField } from '../FilterChipsFields/FilterChipsBooleanField';
import { FilterChipsDateBetweenField } from '../FilterChipsFields/FilterChipsDateBetweenField';
import { FilterChipsDateIntervalField } from '../FilterChipsFields/FilterChipsDateIntervalField';
import { FilterChipsDefaultDateField } from '../FilterChipsFields/FilterChipsDefaultDateField';
import { FilterChipsDirectionField } from '../FilterChipsFields/FilterChipsDirectionField';
import { FilterChipsExistsField } from '../FilterChipsFields/FilterChipsExistsField';
import { FilterChipsIdArrayField } from '../FilterChipsFields/FilterChipsIdArrayField';
import { FilterChipsIdField } from '../FilterChipsFields/FilterChipsIdField';
import { FilterChipsMerchantSpaceField } from '../FilterChipsFields/FilterChipsMerchantSpaceField';
import { FilterChipsOrderStatusField } from '../FilterChipsFields/FilterChipsOrderStatusField';
import { FilterChipsSelect } from '../FilterChipsFields/FilterChipsSelect';
import { FilterChipsStringField } from '../FilterChipsFields/FilterChipsStringField';
import { FilterChipsUsersField } from '../FilterChipsFields/FilterChipsUsersField';

export type FilterChipsSwitcherProps<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
  resetFilterBySource: ResetFilterBySource<TFilterValues>;
  resetFilterArrayById?: ResetFilterArrayById<TFilterValues>;
};

export function FilterChipsSwitcher<TFilterValues extends FieldValues>({
  chipValue,
  resetFilterBySource,
  resetFilterArrayById,
}: FilterChipsSwitcherProps<TFilterValues>) {
  switch (chipValue.type) {
    case FilterChipType.IdArray:
      return (
        <FilterChipsIdArrayField
          chipValue={chipValue}
          onDelete={(source, id) => {
            if (!resetFilterArrayById) {
              // eslint-disable-next-line no-console
              console.error('Property `resetFilterArrayById` is missing in props');
              return;
            }
            resetFilterArrayById(source as Path<TFilterValues>, id);
          }}
        />
      );
    case FilterChipType.Users:
      return (
        <FilterChipsUsersField
          chipValue={chipValue}
          onDelete={(source, id) => {
            if (!resetFilterArrayById) {
              // eslint-disable-next-line no-console
              console.error('Property `resetFilterArrayById` is missing in props');
              return;
            }
            resetFilterArrayById(source as Path<TFilterValues>, id);
          }}
        />
      );
    case FilterChipType.OrderStatus:
      return (
        <FilterChipsOrderStatusField
          chipValue={chipValue}
          onDelete={(source, id) => {
            if (!resetFilterArrayById) {
              // eslint-disable-next-line no-console
              console.error('Property `resetFilterArrayById` is missing in props');
              return;
            }
            resetFilterArrayById(source as Path<TFilterValues>, id);
          }}
        />
      );
    case FilterChipType.Boolean:
      return (
        <FilterChipsBooleanField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.String:
      return (
        <FilterChipsStringField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.Direction:
      return (
        <FilterChipsDirectionField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.Id:
      return (
        <FilterChipsIdField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.Exists:
      return (
        <FilterChipsExistsField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.DateBetween:
      return (
        <FilterChipsDateBetweenField
          chipValue={chipValue}
          onDelete={() => {
            resetFilterBySource('startDate' as Path<TFilterValues>);
            resetFilterBySource('endDate' as Path<TFilterValues>);
            resetFilterBySource(chipValue.source);
          }}
        />
      );
    case FilterChipType.AmountBetween:
      return (
        <FilterChipsAmountBetweenField
          chipValue={chipValue}
          onDelete={() => {
            resetFilterBySource('amountFrom' as Path<TFilterValues>);
            resetFilterBySource('amountTo' as Path<TFilterValues>);
            resetFilterBySource(chipValue.source);
          }}
        />
      );
    case FilterChipType.DefaultDate:
      return <FilterChipsDefaultDateField chipValue={chipValue} />;
    case FilterChipType.DateInterval:
      return (
        <FilterChipsDateIntervalField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.MerchantSpaceId:
      return (
        <FilterChipsMerchantSpaceField
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    case FilterChipType.Select:
      return (
        <FilterChipsSelect
          chipValue={chipValue}
          onDelete={() => resetFilterBySource(chipValue.source)}
        />
      );
    default:
      return <></>;
  }
}
