import { EMPTY_FIELD } from 'constants/emptyField';

import { usePermissions } from 'hooks/usePermissions';
import { RaRecord } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';

const dateStyle = { padding: '0 16px' };

export const CommissionsShowTable = () => {
  const { hasResourceAccess } = usePermissions();

  return (
    <CardTable
      sx={{
        tableLayout: 'fixed',
      }}
    >
      <TableBody>
        <TableRowWrapper title="ID">
          <TextField label="Id" source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField label="Название" source="name" />
        </TableRowWrapper>
        {hasResourceAccess('providers') && (
          <TableRowWrapper title="Провайдер">
            <ReferenceOneField
              label="Провайдер"
              reference="providers"
              source="provider"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('merchants') && (
          <TableRowWrapper title="Мерчант">
            <ReferenceOneField
              emptyText={EMPTY_FIELD}
              link="show"
              reference="merchants"
              source="merchant"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('currencies') && (
          <TableRowWrapper title="Валюта">
            <ReferenceOneField label="Валюта" reference="currencies" source="currency" target="id">
              <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('transaction_types') && (
          <TableRowWrapper title="Типы транзакций">
            <ReferenceArrayField
              label="Типы транзакций"
              reference="transaction_types"
              source="transactionTypes"
            >
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </TableRowWrapper>
        )}
        <TableRowWrapper title="Нулевая комиссия">
          <BooleanField label="Нулевая комиссия" source="zeroed" />
        </TableRowWrapper>
        <TableRowWrapper title="Процент">
          <NumberField emptyText="—" label="Процент" source="percent" />
        </TableRowWrapper>
        <TableRowWrapper title="Фиксированная">
          <NumberField emptyText="—" label="Фиксированная" source="fix" />
        </TableRowWrapper>
        <TableRowWrapper title="Максимальная">
          <NumberField emptyText="—" label="Максимальная" source="max" />
        </TableRowWrapper>
        <TableRowWrapper title="Минимальная">
          <NumberField emptyText="—" label="Минимальная" source="min" />
        </TableRowWrapper>
        <TableRowWrapper contentStyle={dateStyle} title="Дата создания">
          <DateTimeField source="createdAt" />
        </TableRowWrapper>
        <TableRowWrapper contentStyle={dateStyle} title="Дата редактир.">
          <DateTimeField source="updatedAt" />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
