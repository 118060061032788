import { styled } from '@mui/material/styles';
import { Chip } from 'shared/mui/Chips';
import { appColors } from 'themes/variables';

export const StyledLinkedChipAll = styled(Chip)({
  background: appColors.primary.main,
  color: appColors.text.light,
  marginTop: '12px',
  cursor: 'pointer',
  marginBottom: 0,
});

export const StyledChip = styled(Chip)(({ isLast }: { isLast: boolean }) => ({
  marginBottom: isLast ? '0' : '12px',
  cursor: 'pointer',
}));
