import React, { useEffect, useMemo, useState } from 'react';

import { Button, Popover, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getTime, isValid } from 'date-fns';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useDebounce } from 'hooks/useDebounce';
import { DateObject } from 'react-multi-date-picker';
import { DatePickerTimezoneToolbar } from 'shared/mui/DatePicker/components/DatePickerTimezoneToolbar/DatePickerTimezoneToolbar';
import { formatCalendarValue } from 'shared/mui/DatePicker/utils/formatCalendarValue';
import { getZonedEndOfDay } from 'shared/mui/DatePicker/utils/getZonedTime';

import { Calendar } from '../../Calendar/Calendar';
import { DatePickerActions } from '../components/DatePickerActions/DatePickerActions';
import { DatePickerMaskInput } from '../components/DatePickerMaskInput/DatePickerMaskInput';
import { DatePickerShortcuts } from '../components/DatePickerShortcuts/DatePickerShortcuts';
import { DatePickerTime } from '../components/DatePickerTime/DatePickerTime';
import {
  DEFAULT_DATE_TIME_MASK,
  DEFAULT_DATE_TIME_RANGE_MASK,
  DEFAULT_DATE_TIME_RANGE_PLACEHOLDER,
  DEFAULT_SEPARATOR,
  DEFAULT_TIME_MASK,
  DEFAULT_TIMEZONE,
} from '../config/defaultValues';
import {
  PopoverCalendarContainerStyled,
  PopoverContentStyled,
  PopoverDatesStyled,
  PopoverLayoutStyled,
  PopoverListStyled,
  PopoverTimesStyled,
} from '../DatePicker.styled';
import { useAnchor } from '../hooks/useAnchor';
import { formatStringToTimestamp } from '../utils/formatStringToTimestamp';
import { fromTimestampRangeToString } from '../utils/fromTimestampRangeToString';
import { getValidCalendarDate } from '../utils/getValidCalendarDate';
import { isValidDate } from '../utils/isValidDate';
import { parseStringToDate } from '../utils/parseStringToDate';
import { setCurrentTime } from '../utils/setCurrentTime';
import { setToday } from '../utils/setToday';

dayjs.extend(utc);
dayjs.extend(timezone);

export type DateRangePickerValue = number | null;
export type DateRangeTimezoneValue = string | null;

export type DateTimeRangePickerProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'defaultValue'
> & {
  minDate?: Date | string | number;
  maxDate?: Date | string | number;
  value: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  displayShortcuts?: boolean;
  onChange?: (
    newValue: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue],
  ) => void;
};

export const DateTimeRangeTimezonePicker: React.FC<DateTimeRangePickerProps> = ({
  label,
  variant = 'outlined',
  displayShortcuts = true,
  disabled,
  onChange: onChangeProp,
  minDate,
  maxDate,
  value,
  ...restProps
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const [error, setError] = useState(false);
  const [startTime, setStartTime] = useState<DateRangePickerValue>(value[0]);
  const [endTime, setEndTime] = useState<DateRangePickerValue>(value[1]);
  const [timezoneValue, setTimezoneValue] = useState<DateRangeTimezoneValue>(value[2]);
  const [resetValue, setResetValue] =
    useState<[DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue]>(value);

  const [inputValue, setInputValue] = useState<string | undefined>(
    fromTimestampRangeToString(
      [value[0], value[1]],
      DEFAULT_DATE_TIME_MASK,
      timezoneValue || DEFAULT_TIMEZONE,
    ),
  );

  const [calendarValue, setCalendarValue] = useState<[DateRangePickerValue, DateRangePickerValue]>([
    value[0],
    value[1],
  ]);

  const startTimeZoned = useMemo(() => (startTime ? dayjs(startTime) : null), [startTime]);
  const endTimeZoned = useMemo(() => (endTime ? dayjs(endTime) : null), [endTime]);
  const calendarValueZoned = useMemo(
    () => [
      formatCalendarValue(calendarValue[0], timezoneValue),
      formatCalendarValue(calendarValue[1], timezoneValue),
    ],
    [calendarValue],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'date-time-picker-popover' : undefined;

  useEffect(() => {
    setStartTime(value[0]);
    setEndTime(value[1]);
    setInputValue(
      fromTimestampRangeToString(
        [value[0], value[1]],
        DEFAULT_DATE_TIME_MASK,
        timezoneValue || DEFAULT_TIMEZONE,
      ),
    );
    setCalendarValue([value[0], value[1]]);
  }, [value]);

  const debouncedOnChangeInputHandler = useDebounce((target: string) => {
    const [start, end] = target.split(DEFAULT_SEPARATOR);

    if (!start && !end) {
      setError(false);
      setStartTime(null);
      setEndTime(null);
      setCalendarValue([null, null]);
      setInputValue(undefined);
      return;
    }

    const [startDate, startTime] = start.split(' ');
    const [endDate, endTime] = end.split(' ');
    setError(true);
    const validStartDate = dayjs(startDate).isValid() ? formatStringToTimestamp(startDate) : null;
    const validEndDate = dayjs(endDate).isValid() ? formatStringToTimestamp(endDate) : null;

    if (validStartDate && isValidDate(startDate) && isValidDate(startTime, DEFAULT_TIME_MASK)) {
      const newValue = setCurrentTime(
        validStartDate,
        parseStringToDate(startTime, DEFAULT_TIME_MASK),
      );
      setError(false);
      setCalendarValue((prev) => [newValue, prev[1]]);
      setStartTime(newValue);
    } else {
      setCalendarValue((prev) => [null, prev[1]]);
      setStartTime(null);
    }

    if (validEndDate && isValidDate(endDate) && isValidDate(endTime, DEFAULT_TIME_MASK)) {
      const newValue = setCurrentTime(validEndDate, parseStringToDate(endTime, DEFAULT_TIME_MASK));

      setError(false);
      setCalendarValue((prev) => [prev[0], newValue]);
      setEndTime(newValue);
    } else {
      setCalendarValue((prev) => [prev[0], null]);
      setEndTime(null);
    }

    setInputValue(target);
  }, 300);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedOnChangeInputHandler(event?.target?.value);
  }

  function onChangeCalendar(value: (DateObject | null)[]) {
    const [start, end] = value;

    const validStart = getValidCalendarDate(start, startTime);
    const validEnd = getValidCalendarDate(end, endTime);
    const validEndOfDay = validEnd
      ? getTime(getZonedEndOfDay(validEnd, timezoneValue || DEFAULT_TIMEZONE))
      : null;

    const validValue: [DateRangePickerValue, DateRangePickerValue] = [validStart, validEndOfDay];

    setStartTime(validStart);
    setEndTime(validEndOfDay);

    setInputValue(
      fromTimestampRangeToString(
        validValue,
        DEFAULT_DATE_TIME_MASK,
        timezoneValue || DEFAULT_TIMEZONE,
      ),
    );
    setCalendarValue(validValue);
  }

  const onChangeStartTime = (value: Date) => {
    const [startDate, endDate] = calendarValue;

    if (!isValid(value)) {
      setStartTime(null);
      setCalendarValue((prevValue) => [null, prevValue[1]]);
      setInputValue(
        fromTimestampRangeToString(
          [null, endDate],
          DEFAULT_DATE_TIME_MASK,
          timezoneValue || DEFAULT_TIMEZONE,
        ),
      );

      return;
    }

    if (!startDate) {
      const newValue = setToday(value);
      setStartTime(newValue);
      setCalendarValue((prevValue) => [newValue, prevValue[1]]);
      setInputValue(
        fromTimestampRangeToString(
          [newValue, endDate],
          DEFAULT_DATE_TIME_MASK,
          timezoneValue || DEFAULT_TIMEZONE,
        ),
      );

      return;
    }

    const newDateTime = setCurrentTime(startDate, value);
    setStartTime(newDateTime);
    setCalendarValue((prevValue) => [newDateTime, prevValue[1]]);
    setInputValue(
      fromTimestampRangeToString(
        [newDateTime, endDate],
        DEFAULT_DATE_TIME_MASK,
        timezoneValue || DEFAULT_TIMEZONE,
      ),
    );
  };
  const onChangeEndTime = (value: Date) => {
    const [startDate, endDate] = calendarValue;

    if (!isValid(value)) {
      setEndTime(null);
      setCalendarValue((prevValue) => [prevValue[0], null]);
      setInputValue(
        fromTimestampRangeToString(
          [startDate, null],
          DEFAULT_DATE_TIME_MASK,
          timezoneValue || DEFAULT_TIMEZONE,
        ),
      );

      return;
    }

    if (!endDate) {
      const newValue = setToday(value);
      setEndTime(newValue);
      setCalendarValue((prevValue) => [prevValue[0], newValue]);
      setInputValue(
        fromTimestampRangeToString(
          [startDate, newValue],
          DEFAULT_DATE_TIME_MASK,
          timezoneValue || DEFAULT_TIMEZONE,
        ),
      );

      return;
    }

    const newDateTime = setCurrentTime(endDate, value);
    setEndTime(newDateTime);
    setCalendarValue((prevValue) => [prevValue[0], newDateTime]);
    setInputValue(
      fromTimestampRangeToString(
        [startDate, newDateTime],
        DEFAULT_DATE_TIME_MASK,
        timezoneValue || DEFAULT_TIMEZONE,
      ),
    );
  };

  const onClickShortcut = (value: [DateRangePickerValue, DateRangePickerValue]) => {
    const [startDate, endDate] = value;

    setStartTime(startDate);
    setEndTime(endDate);
    setInputValue(
      fromTimestampRangeToString(value, DEFAULT_DATE_TIME_MASK, timezoneValue || DEFAULT_TIMEZONE),
    );
    setCalendarValue(value);
  };

  function onClickApply() {
    if (onChangeProp) {
      onChangeProp([...calendarValue, timezoneValue]);
      setResetValue(resetValue);
      handleClose();
    }
  }

  function onClickReset() {
    if (onChangeProp) {
      onChangeProp(resetValue);
      setStartTime(resetValue[0]);
      setEndTime(resetValue[1]);
      setInputValue(
        fromTimestampRangeToString([resetValue[0], resetValue[1]], DEFAULT_DATE_TIME_MASK),
      );
      setCalendarValue([resetValue[0], resetValue[1]]);
      handleClose();
    }
  }

  return (
    <>
      <DatePickerMaskInput
        {...restProps}
        disabled={disabled}
        error={error}
        label={label}
        mask={DEFAULT_DATE_TIME_RANGE_MASK}
        onChange={onChangeInput}
        onClick={(e) => {
          if (disabled) {
            return;
          }

          handleClick(e);
        }}
        placeholder={DEFAULT_DATE_TIME_RANGE_PLACEHOLDER}
        value={inputValue}
        variant={variant}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <PopoverLayoutStyled>
          <PopoverCalendarContainerStyled>
            {displayShortcuts && (
              <PopoverListStyled>
                <DatePickerShortcuts
                  onClick={onClickShortcut}
                  timezone={timezoneValue || DEFAULT_TIMEZONE}
                />
              </PopoverListStyled>
            )}
            <PopoverContentStyled>
              <DatePickerTimezoneToolbar
                end={calendarValue[1]}
                setTimezoneValue={setTimezoneValue}
                start={calendarValue[0]}
                timezone={timezoneValue || DEFAULT_TIMEZONE}
                title="Выберите диапазон дат и время"
              />
              <PopoverDatesStyled>
                <PopoverCalendarContainerStyled>
                  <Calendar
                    maxDate={maxDate}
                    minDate={minDate}
                    numberOfMonths={2}
                    onChange={onChangeCalendar}
                    range
                    value={calendarValueZoned}
                  />
                </PopoverCalendarContainerStyled>
                <PopoverTimesStyled>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerTime
                      label="Время начала"
                      onChange={onChangeStartTime}
                      timezone={timezoneValue || DEFAULT_TIMEZONE}
                      value={startTimeZoned}
                    />
                    <DatePickerTime
                      label="Время конца"
                      onChange={onChangeEndTime}
                      timezone={timezoneValue || DEFAULT_TIMEZONE}
                      value={endTimeZoned}
                    />
                  </LocalizationProvider>
                </PopoverTimesStyled>
              </PopoverDatesStyled>
            </PopoverContentStyled>
          </PopoverCalendarContainerStyled>
          <DatePickerActions>
            <Button onClick={onClickReset} variant="outlined">
              Отменить
            </Button>
            <Button onClick={onClickApply} variant="contained">
              Подтвердить
            </Button>
          </DatePickerActions>
        </PopoverLayoutStyled>
      </Popover>
    </>
  );
};
