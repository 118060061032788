import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';

import { WorkTeamsForm } from './components/WorkTeamsForm';

export const WorkTeamsCreate = () => {
  return (
    <CreateGuesserDialog
      mutationOptions={{ onError: () => undefined }}
      title="Добавление рабочей группы"
    >
      <CardContent sx={{ width: '552px' }}>
        <WorkTeamsForm />
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButtonValidated key="save-button" />,
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
        ]}
        sx={{ paddingTop: '8px' }}
      />
    </CreateGuesserDialog>
  );
};
