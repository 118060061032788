import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigateToRoot(): { toRoot: () => void } {
  const navigate = useNavigate();
  const location = useLocation();

  function toRoot(): void {
    const rootPage = location.pathname.split('/')[1];
    navigate(`/${rootPage}`);
  }

  return { toRoot };
}
