export enum FilterChipType {
  String = 'string',
  Id = 'id',
  IdArray = 'id_array',
  Boolean = 'boolean',
  Exists = 'exists',
  DateBetween = 'dateBetween',
  AmountBetween = 'amountBetween',
  DefaultDate = 'defaultDate',
  Direction = 'direction',
  DateInterval = 'dateInterval',
  MerchantSpaceId = 'merchantSpaceId',
  Select = 'select',
  OrderStatus = 'order_status',
  Users = 'users',
}
