import { useNotify, useRedirect } from 'react-admin';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CreatePage } from 'shared/react-admin/Pages/CreatePage/CreatePage';

import { MerchantSpacesCreateForm } from './MerchantSpacesCreateForm';
import { createMerchantSpaceRequest } from '../../../config/requests';
import { Merchant } from '../../../types';

export type MerchantSpacesSiteFieldValues = {
  record: Merchant;
  sourceMerchantId: string;
  active: boolean;
};

export interface MerchantSpacesFormValues {
  active: boolean;
  spaceName: string;
  sourceCompany: string;
  alias: string;
  sites: MerchantSpacesSiteFieldValues[];
}

export const MerchantSpacesCreate: React.FC = () => {
  const redirect = useRedirect();
  const { mutate: createMerchantSpace } = useMutation(createMerchantSpaceRequest);

  const form = useForm<MerchantSpacesFormValues>({});

  const notify = useNotify();

  const onSubmit: SubmitHandler<MerchantSpacesFormValues> = (values) => {
    const sites = values.sites.filter((site) => site.active)?.map((site) => site.sourceMerchantId);

    createMerchantSpace(
      {
        active: values.active,
        spaceName: values.spaceName,
        sourceCompanyId: values.sourceCompany,
        sites,
      },
      {
        onSuccess: () => {
          notify('Кабинет мерчанта успешно создан!', { type: 'success' });
          redirect('/merchant_spaces');
        },
        onError: (error: any) => {
          notify(error.data.errors[0].title || 'Something went wrong', { type: 'error' });
        },
      },
    );
  };

  return (
    <CreatePage fullWidth={true} listName="Кабинеты мерчантов" title="Создание кабинета мерчантов">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <MerchantSpacesCreateForm />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="save-button" label="Создать" type="submit" variant="contained" />,
              <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
            ]}
          />
        </form>
      </FormProvider>
    </CreatePage>
  );
};
