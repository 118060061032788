import { useMemo } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Row } from 'shared/mui/Row/Row';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';
import {
  AutocompleteInputIntrospected,
  NumberInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

type ProviderDefaultValueType = {
  provider: string;
  source?: string;
};

export const ProviderWhiteIpAddressesCreate = () => {
  const location = useLocation();
  const defaultValues = useMemo(() => {
    const filterValues = qs.parse(location?.search.replace('?', ''))?.filter;
    return JSON.parse((filterValues || '{}') as string) as ProviderDefaultValueType;
  }, [location]);

  return (
    <CreateGuesserDialog
      mutationOptions={{ onError: () => undefined }}
      sx={{ width: '600px' }}
      title="Добавление белого IP"
    >
      <CardContent sx={{ width: 'calc(100% - 48px)' }}>
        <ReferenceInput perPage={200} reference="providers" source="provider">
          <AutocompleteInputIntrospected
            defaultValue={defaultValues.provider}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            label="Провайдер"
            optionText="name"
            size="medium"
            source="name"
            variant="outlined"
          />
        </ReferenceInput>
        <Row>
          <TextInputIntrospected
            fullWidth
            label="IP"
            size="medium"
            source="ip"
            variant="outlined"
          />
          <NumberInputIntrospected
            fullWidth
            label="Маска"
            size="medium"
            source="mask"
            variant="outlined"
          />
        </Row>
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButton key="save-button" />,
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
        ]}
      />
    </CreateGuesserDialog>
  );
};
