import React, { ComponentType, isValidElement, ReactElement, useMemo } from 'react';

import { ResourceGuesserProps } from '@api-platform/admin';
import { ResourceContextProvider, ResourceProps } from 'react-admin';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useHistoryURL } from '../../../hooks/useHistoryURL';

export type PageResourceProps = ResourceGuesserProps & {
  history?: ComponentType<any> | ReactElement;
  historyRecord?: ComponentType<any> | ReactElement;
};

export const PageResource = (props: PageResourceProps): JSX.Element => {
  const {
    create: Create,
    edit: Edit,
    list: List,
    name,
    show: Show,
    history: History,
    historyRecord: HistoryRecord,
  } = props;
  useHistoryURL();
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  // Нужно для того, чтобы открывать модальное окно после перехода по ссылке
  // Так как внутри state после перехода по ссылке не будет backgroundLocation
  const isOpenModal = useMemo(
    () => ['/history'].some((subPath) => location.pathname.includes(subPath)),
    [location],
  );

  return (
    <ResourceContextProvider value={name}>
      <>
        <Routes location={state?.backgroundLocation || location}>
          {Create && (
            // @ts-ignore
            <Route element={isValidElement(Create) ? Create : <Create />} path="create/*" />
          )}
          {Show && (
            // @ts-ignore
            <Route element={isValidElement(Show) ? Show : <Show />} path=":id/show/*" />
          )}
          {Edit && (
            // @ts-ignore
            <Route element={isValidElement(Edit) ? Edit : <Edit />} path=":id/*" />
          )}
          {List && (
            // @ts-ignore
            <Route element={isValidElement(List) ? List : <List />} path="/*" />
          )}
        </Routes>
        {(state?.backgroundLocation || isOpenModal) && (
          <Routes>
            {History && (
              // @ts-ignore
              <Route element={<History />} path="/:id/show/history/*" />
            )}
            {HistoryRecord && (
              // @ts-ignore
              <Route element={<HistoryRecord />} path="/:id/show/history/:historyId/*" />
            )}
          </Routes>
        )}
      </>
    </ResourceContextProvider>
  );
};

PageResource.raName = 'Resource';

PageResource.registerResource = ({
  icon,
  create,
  list,
  edit,
  show,
  name,
  options,
  recordRepresentation,
}: ResourceProps & { history?: React.FC; historyRecord?: React.FC }) => ({
  name,
  options,
  hasList: !!list,
  hasCreate: !!create,
  hasEdit: !!edit,
  hasShow: !!show,
  icon,
  recordRepresentation,
});
