import { useQuery } from 'react-query';
import { MerchantSpaces } from 'types';

import { getMerchantSpacesRequest } from '../../config/requests';

export type UseMerchantSpacesReturnValues = {
  merchantSpaces: MerchantSpaces | undefined;
  isLoadingMerchantSpaces: boolean;
};

export function useMerchantSpaces(id: string | undefined): UseMerchantSpacesReturnValues {
  const { data: merchantSpaces, isLoading: isLoadingMerchantSpaces } = useQuery(
    ['merchant_spaces', id],
    () => getMerchantSpacesRequest(id || ''),
    { enabled: !!id, cacheTime: 0 },
  );

  return { merchantSpaces, isLoadingMerchantSpaces };
}
