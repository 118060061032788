import { Fragment } from 'react';

import { TextInput } from 'shared/react-admin/Inputs/index';
import { fieldValidateGuesser } from 'utils';

const defaultSx = {
  margin: 0,
};

export const TextInputComponent = ({
  fields,
  validate,
  size = 'medium',
  sx,
  ...props
}: any): JSX.Element => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });

  if (isError) {
    return <Fragment />;
  }

  return (
    <TextInput size={size} sx={{ ...defaultSx, ...sx }} validate={guessedValidate} {...props} />
  );
};
