import { Dispatch, SetStateAction, useEffect } from 'react';

import { required, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { ImageUploader } from 'shared/mui/ImageUploader/ImageUploader';
import { TextInput } from 'shared/react-admin/Inputs';

import { MethodLogo } from '../../../types';

type MethodLogosEditFormProps = {
  errors: any;
  setIsShowImage?: Dispatch<SetStateAction<boolean>>;
};

export function MethodLogosEditForm({ errors, setIsShowImage }: MethodLogosEditFormProps) {
  const { setError } = useFormContext();
  const record = useRecordContext<MethodLogo>();

  useEffect(() => {
    if (errors) {
      errors?.violations.forEach((error: any) => {
        setError(error?.propertyPath, { type: 'string', message: error.message });
      });
    }
  }, [errors, setError]);

  return (
    <CardContent sx={{ width: '396px' }}>
      <TextInput
        InputLabelProps={{ shrink: true }}
        fullWidth={true}
        helperText={false}
        key="name"
        label="Название"
        resettable
        size="medium"
        source="name"
        validate={required()}
        variant="outlined"
      />
      <ImageUploader filePath={record?.filePath} setIsShowImage={setIsShowImage} />
    </CardContent>
  );
}
