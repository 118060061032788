import { TransactionType } from 'types';

const paymentList = ['Пополнение', 'Рефанд', 'Чарджбэк', 'Отмена'];
const payoutList = ['Вывод', 'Отмена'];

const getTransactionTypesIds = (transactionTypes: TransactionType[], transactionsList: string[]) =>
  transactionTypes.filter((type) => transactionsList.includes(type.name)).map((type) => type.id);

export const getComissionsFilter = (
  transactionTypes?: TransactionType[],
  direction?: string | null,
) => {
  if (!transactionTypes || !direction) {
    return null;
  }

  return direction?.includes('payment')
    ? getTransactionTypesIds(transactionTypes, paymentList)
    : getTransactionTypesIds(transactionTypes, payoutList);
};
