import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { generateNotificationKeyRequest } from '../../../config/requests';

type Props = {
  onSuccess: (data: AxiosResponse) => void;
};

type UseGenerateNotificationKeyResult = {
  generateNotificationKey: () => void;
};

export function useGenerateNotificationKey({ onSuccess }: Props): UseGenerateNotificationKeyResult {
  const { mutate: generateNotificationKey } = useMutation(generateNotificationKeyRequest, {
    onSuccess,
  });

  return { generateNotificationKey };
}
