import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import React, { useEffect, useState } from 'react';

import { BalanceReportFilter } from 'components/Finance/BalanceReport/BalanceReportFilter';
import { BalanceReportDatagrid } from 'components/Finance/BalanceReport/components/BalanceReportDatagrid/BalanceReportDatagrid';
import { useBalanceReportList } from 'components/Finance/BalanceReport/hooks/useBalanceReportList';
import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { ListContextProvider, useList, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';

import { AppListContextProviderCard } from '../../Common/Card';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../../Common/PanelHeader';

export enum ConversionRateTypes {
  Actual = 'actual',
  Historical = 'historical',
}

export type BalanceReportFilterValues = {
  currency?: string | null;
  conversionRateType?: ConversionRateTypes | undefined;
  periodFrom?: number | null;
  periodTo?: number | null;
  companies?: string[] | [];
  merchants?: string[] | [];
  providers?: string[] | [];
  merchantAccounts?: string[] | [];
  exportType?: ExportFormat;
};

export const BalanceReportList: React.FC = () => {
  const [filters, setFilters] = useState<BalanceReportFilterValues>({});
  const { balanceList, refetchBalanceList, isLoading } = useBalanceReportList(filters);
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const { data: user } = useQuery('me', getUserInfo);
  const notify = useNotify();

  const listContext = useList({
    data: balanceList?.items || [],
  });

  const { mutate: exportReportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...filters,
      exportType: exportFormat,
      jobType: ExportJobTypes.Balance,
      ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
    },
    preferenceKey: ReportNames.Balance,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по балансам формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      refetchBalanceList();
    }
  }, [filters]);

  function onGenerateClick(filter: BalanceReportFilterValues) {
    setFilters(filter);
  }

  return (
    <ListContextProvider value={listContext}>
      <AppListContextProviderCard>
        <AppPanelHeader>
          <AppPanelHeaderToolbar
            actions={[
              <ExportFormatsButton
                exportFormat={exportFormat}
                key="export-button"
                onChangeFormat={setExportFormat}
                onClick={() => setIsOpenNotifyDialog(true)}
              />,
            ]}
            titleText="Отчёт о балансах"
            tooltipText="Отчет о балансах - это сводный отчет по движению финансовых средств на балансе “Счетов мерчантов” за запрошенный период времени. Обратите внимание, что данный отчет не отражает фактическое состояние баланса."
          />
        </AppPanelHeader>
        <BalanceReportFilter
          filters={filters}
          onGenerateClick={onGenerateClick}
          setFilters={setFilters}
        />
        <BalanceReportDatagrid filters={filters} />
      </AppListContextProviderCard>
      <NotifyDialog
        isLoading={isExportLoading || isLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportReportMutate}
        open={isOpenNotifyDialog}
        title="Экспорт отчета"
      />
    </ListContextProvider>
  );
};
