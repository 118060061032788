import { useState } from 'react';

import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { useMediaQuery, Theme } from '@mui/material';
import { ConfigurableDatagridColumn, useStore, useResourceContext } from 'react-admin';
import { IconButton } from 'shared/mui/IconButtons';
import { List } from 'shared/mui/List/List';
import { Popover } from 'shared/mui/Popover/Popover';
import { Tooltip } from 'shared/mui/Tooltip';
import { sortColumnsByIndex } from 'utils';

import { FieldToggle } from './FieldToggle/FieldToggle';
import { StyledButton } from './SelectColumnButton.styled';

interface SelectColumnButtonProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  resource?: string;
  preferenceKey?: string;
  omit?: string[];
}

const title = 'Настройка колонок';

export const SelectColumnButton: React.FC<SelectColumnButtonProps> = (props) => {
  const { preferenceKey } = props;

  const resource = useResourceContext(props);
  const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [availableColumns, setAvailableColumns] = useStore<ConfigurableDatagridColumn[]>(
    `preferences.${finalPreferenceKey}.availableColumns`,
    [],
  );

  const [columns, setColumns] = useStore<string[]>(
    `preferences.${finalPreferenceKey}.columns`,
    availableColumns.map((column) => column.index),
  );

  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.checked) {
      const filteredColumns = availableColumns
        .filter((column) => column.index === event.target?.name || columns.includes(column.index))
        .map((column) => column.index);
      setColumns(filteredColumns);
    } else {
      setColumns((prev) => prev.filter((index: string) => index !== event.target?.name));
    }
  };

  const handleMove = (arr: string[]): void => {
    const newAvailableColumns = sortColumnsByIndex(availableColumns, arr);
    setAvailableColumns(newAvailableColumns);
    setColumns((columns) =>
      newAvailableColumns
        .filter((column) => columns.includes(column.index))
        .map((column) => column.index),
    );
  };

  return (
    <>
      {isXSmall ? (
        <Tooltip title={title}>
          <IconButton aria-label={title} color="primary" onClick={handleClick} size="large">
            <ViewWeekIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <StyledButton
          label={title}
          onClick={handleClick}
          size="small"
          startIcon={<ViewWeekIcon />}
        />
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List sx={{ padding: 1 }}>
          {availableColumns.map((column) => (
            <FieldToggle
              index={column.index}
              key={column.index}
              label={column.label}
              onMove={handleMove}
              onToggle={handleToggle}
              selected={columns.includes(column.index)}
              source={column.source}
            />
          ))}
        </List>
      </Popover>
    </>
  );
};
