import { useQuery } from 'react-query';

import { MerchantSpaceResponse, getMerchantSpacesList } from '../../../../config/requests';
import { MerchantSpacesFilter } from '../MerchantSpacesList';

type UseGetMerchantSpacesReturnValue = {
  merchantSpaces: MerchantSpaceResponse | undefined;
  isLoading: boolean;
  refetch: () => void;
};

export function useGetMerchantSpaces(
  filters: MerchantSpacesFilter,
): UseGetMerchantSpacesReturnValue {
  const {
    data: merchantSpaces,
    isLoading,
    refetch,
  } = useQuery<MerchantSpaceResponse>(
    ['merchant_spaces', filters],
    () => getMerchantSpacesList(filters),
    { enabled: !!filters },
  );

  return { merchantSpaces, isLoading, refetch };
}
