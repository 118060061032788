import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { AppPanelHeaderActions } from './AppPanelHeaderActions';
import { AppTypography } from '../Typography';

type Props = {
  titleText: React.ReactNode;
  tooltipText?: React.ReactNode;
  description?: string;
  titleSize?: 'medium' | 'large';
  actions?: (React.ReactElement | false)[];
};

export function AppPanelHeaderToolbar({
  titleText,
  tooltipText,
  titleSize = 'large',
  description,
  actions = [],
}: Props): JSX.Element {
  return (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
        <Box alignItems="center" display="flex">
          <AppTypography mr={1} size={titleSize}>
            {titleText}
          </AppTypography>
          {tooltipText && (
            <Tooltip
              placement="bottom-start"
              title={<Typography fontSize={12}>{tooltipText}</Typography>}
            >
              <IconButton>
                <InfoIcon color="secondary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <AppPanelHeaderActions>{actions}</AppPanelHeaderActions>
      </Box>
      {description && <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{description}</Typography>}
    </Box>
  );
}
