export const enum NotificationTypes {
  Report = 'report_export',
}

export const enum NotificationStatuses {
  Done = 'done',
  Failure = 'failed',
}

export const NOTIFICATION_STATUSES = {
  [NotificationStatuses.Done]: 'готов для скачивания',
  [NotificationStatuses.Failure]: 'ошибка при формировании',
};

export const NOTIFICATIONS_REFETCH_INTERVAL = 30000;
