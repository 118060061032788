import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

type MerchantsEditConfirmGenerateKeyDialogProps = {
  onConfirm: () => void;
  onClose: () => void;
} & DialogProps;

export const MerchantsEditConfirmGenerateKeyDialog = ({
  onConfirm,
  open,
  onClose,
}: MerchantsEditConfirmGenerateKeyDialogProps): JSX.Element => (
  <Dialog onClose={onClose} open={open}>
    <CardHeader
      actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
      title="Обновление ключа нотификации"
    />
    <Typography sx={{ padding: '16px 24px' }} variant="body1">
      Будет сгенерирован новый ключ, который применится после сохранения формы редактирования
      мерчанта.
    </Typography>
    <CardActionsButtons
      leftActionsSlot={[
        <Button
          key="refresh-button"
          label="Обновить"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          variant="contained"
        />,
        <CancelButton key="cancel-button" onClick={onClose} />,
      ]}
    />
  </Dialog>
);
