import { AxiosError, AxiosResponse } from 'axios';
import { changeMerchantSpacesUserActivity } from 'config/requests';
import { Identifier, useNotify } from 'react-admin';
import { useMutation, UseMutationResult } from 'react-query';

type UseChangeMerchantSpacesusersActivityReturnValue = {
  changeMarchantSpacesUsersActivityMutation: UseMutationResult<
    AxiosResponse,
    unknown,
    { id: Identifier; active: boolean },
    unknown
  >;
};

export function useChangeMarchantSpacesUsersActivity(
  refetchMerchantSpacesUsers: () => void,
): UseChangeMerchantSpacesusersActivityReturnValue {
  const notify = useNotify();

  const mutation = useMutation(
    ({ id, active }: { id: Identifier; active: boolean }) =>
      changeMerchantSpacesUserActivity(id, active),
    {
      onSuccess: async () => {
        notify('Активность успешно изменена', { type: 'success' });
        refetchMerchantSpacesUsers();
      },
      onError: (e) => {
        const error = e as AxiosError;
        notify(`Ошибка: ${error.message}`, { type: 'error' });
      },
    },
  );

  return { changeMarchantSpacesUsersActivityMutation: mutation };
}
