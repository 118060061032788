import { styled } from '@mui/material';
import { appColors, sidebarWidth } from 'themes/variables';

type MenuBaseProps = React.HTMLAttributes<HTMLDivElement> & {
  isClosed: boolean;
};

export const MenuBase = styled('div')<MenuBaseProps>(({ isClosed }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginRight: '1em',
  height: '100%',
  width: sidebarWidth,
  flexGrow: 0,
  position: 'relative',
  backgroundColor: appColors.background,
  ...(isClosed && {
    width: '64px',
    backgroundColor: '#FAFAFA',
  }),
}));
