import { useGetOne, useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips/index';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';

export const UserRoleChip = () => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne('role_categories', { id: record.roleCategories });

  if (isLoading) {
    return <LinearProgress />;
  }

  return <Chip icon={<img alt="icon" src={data.description} />} label={record.name} />;
};
