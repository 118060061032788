import { ReferenceArrayField, useRecordContext } from 'react-admin';
import { ChipField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { ApiClientsMerchantFieldList } from './ApiClientsMerchantFieldList';

type Props = {
  label?: string;
};

export const ApiClientsListMerchantField = ({ label }: Props) => {
  const record = useRecordContext();
  if (!record?.merchant && !record?.merchants?.length) {
    return '—';
  }
  return record?.merchants?.length ? (
    <ReferenceArrayField
      label={label}
      reference="merchants"
      sortBy="merchants.name"
      source="merchants"
    >
      <ApiClientsMerchantFieldList />
    </ReferenceArrayField>
  ) : (
    <ReferenceOneField
      label={label}
      link="show"
      reference="merchants"
      source="merchant"
      target="id"
    >
      <ChipField source="name" sx={{ color: 'black!important', margin: '0!important' }} />
    </ReferenceOneField>
  );
};
