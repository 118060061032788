import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ActionsPosition } from '../PageHeader';

export type PageHeaderActionsProps = BoxProps;

export const PageHeaderActions = styled(Box)(
  ({ actionsPosition }: { actionsPosition: ActionsPosition }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '10px',
    whiteSpace: 'nowrap',
    flex: '0 1 auto',
    alignSelf: actionsPosition,
  }),
);
