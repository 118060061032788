import { useEffect, useMemo, useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { useStore } from 'react-admin';
import { IconButton } from 'shared/mui/IconButtons';
import { List } from 'shared/mui/List/List';
import { Popover } from 'shared/mui/Popover/Popover';
import { FieldToggle } from 'shared/react-admin/Table/SelectColumnButton/FieldToggle/FieldToggle';
import { sortColumnsByIndex } from 'utils';

export interface ConfigurableExportField {
  index: string;
  source?: string;
  label?: string;
}

interface ExportEditButtonProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  resource?: string;
  preferenceKey?: string;
  omit?: string[];
  fields: ConfigurableExportField[];
}

const title = 'Настройка экспорта';

export const ExportEditButton: React.FC<ExportEditButtonProps> = ({
  preferenceKey,
  fields,
  omit = [],
}) => {
  const filterFields = (field: ConfigurableExportField) => !omit?.includes(field.source || '');
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [availableFields, setAvailableFields] = useStore<ConfigurableExportField[]>(
    `preferences.${preferenceKey}.export.availableFields`,
    [],
  );
  const currentAvailableFields = useMemo(
    () => availableFields.filter(filterFields),
    [availableFields],
  );

  const [exportFields, setExportFields] = useStore<string[]>(
    `preferences.${preferenceKey}.export.exportFields`,
    availableFields.map((field) => field.index),
  );

  useEffect(() => {
    if (availableFields.length !== fields.length) {
      setAvailableFields(fields);
      setExportFields(fields.map((field) => field.index));
    }
  }, [fields]);

  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.checked) {
      const filteredFields = availableFields
        .filter((field) => field.index === event.target?.name || exportFields.includes(field.index))
        .map((field) => field.index);
      setExportFields(filteredFields);
    } else {
      setExportFields((prev) => prev.filter((index: string) => index !== event.target?.name));
    }
  };

  const handleMove = (arr: string[]): void => {
    const newAvailableColumns = sortColumnsByIndex(availableFields, arr);
    setAvailableFields(newAvailableColumns);
    setExportFields((columns) =>
      newAvailableColumns
        .filter((column) => columns.includes(column.index))
        .map((column) => column.index),
    );
  };

  return (
    <>
      <IconButton
        aria-label={title}
        color="secondary"
        onClick={handleClick}
        size="large"
        sx={{ padding: '6px', marginLeft: '6px' }}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        sx={{
          '& .MuiPaper-root': {
            boxShadow:
              '0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F, 0px 5px 5px -3px #00000033',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{ padding: 1 }}>
          {currentAvailableFields.map((field) => (
            <FieldToggle
              index={field.index}
              key={field.index}
              label={field.label}
              onMove={handleMove}
              onToggle={handleToggle}
              selected={exportFields.includes(field.index)}
              source={field.source}
            />
          ))}
        </List>
      </Popover>
    </>
  );
};
