import React, { useState } from 'react';

export function useAnchor<T>() {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const handleClick = (event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
  };
}
