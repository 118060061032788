import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Select } from '../../Select/Select';
import { Button } from '../Button/Button';

export const StyledButton = styled(Button)(({ disabled }: { disabled?: boolean }) => ({
  height: '36px',
  borderRight: `1px solid ${disabled ? appColors.divider : appColors.primary.dark}`,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}));

export const StyledSelect = styled(Select)(({ disabled }: { disabled?: boolean }) => ({
  height: '36px',
  width: '48px',
  backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : appColors.primary.main,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  color: appColors.text.light,
  '& .MuiSelect-select': {
    fontSize: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '6px',
    fill: disabled ? appColors.text.disabled : appColors.text.light,
  },
}));
