import { CardContent } from '@mui/material';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

type MerchantsShowNotificationsSendNotificationDialogProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const MerchantsShowNotificationsSendNotificationDialog: React.FC<
  MerchantsShowNotificationsSendNotificationDialogProps
> = ({ open, onClose }) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        title="Отправка тестового сообщения"
      />
      <CardContent>
        По указанным настройкам доступа было отправлено тестовое сообщение. Если тестовое сообщение
        не пришло по указанному каналу, то необходимо изменить настройки доступа и повторить
        отправку сообщения.
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={<Button label="Закрыть" onClick={onClose} variant="outlined" />}
      />
    </Dialog>
  );
};
