import { createContext, useContext } from 'react';

import { GatewayMethod, Method } from 'types';

type GatewayEditFormContextData = {
  initialMethods?: Method[];
  allMethods?: Method[];
  gatewayMethods?: GatewayMethod[];
};

const GatewayEditFormContext = createContext<GatewayEditFormContextData | null>(null);
export const GatewayEditFormProvider = GatewayEditFormContext.Provider;

export function useGatewayEditFormContext(): GatewayEditFormContextData {
  const context = useContext(GatewayEditFormContext);

  if (!context) {
    throw new Error('Can not `useGatewayEditFormContext` outside of the `GatewayEditFormProvider`');
  }

  return context;
}
