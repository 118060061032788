import { useFormContext } from 'react-hook-form';

import { FilterBaseApplyButtonStyled } from './FilterBaseApplyButton.styled';
import { ButtonProps } from '../../../Buttons';

export type FilterApplyButtonProps = ButtonProps;

// Кнопка предназначена для фильтров, в которых не используется ListContext
export const FilterBaseApplyButton: React.FC<FilterApplyButtonProps> = ({
  label = 'Применить',
  color = 'primary',
  variant = 'contained',
  ...restProps
}) => {
  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext();

  const disabled = !isDirty || Object.keys(dirtyFields).length === 0;

  return (
    <FilterBaseApplyButtonStyled
      color={color}
      disabled={disabled}
      label={label}
      variant={variant}
      {...restProps}
    />
  );
};
