import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box/Box';

export const WrapperStyled = styled(Box)({
  width: '100%',
  padding: '24px',
  boxSizing: 'border-box',
  display: 'flex',
  marginTop: 'auto',
});

export const ActionsStyled = styled(Box)({
  gap: '8px',
  width: '100%',
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
});
