import { SxProps, Theme } from '@mui/material';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';

import { BoxStyled, StyledLogo } from './LoaderCircleMain.styled';

export type LoaderCircleMainProps = {
  sx?: SxProps<Theme>;
};

export const LoaderCircleMain: React.FC<LoaderCircleMainProps> = (props) => (
  <BoxStyled {...props}>
    <StyledLogo />
    <CircularProgress color="primary" size={140} thickness={1.2} />
  </BoxStyled>
);
