import { DNOTE_SERVICE_URL } from 'constants/urls';

import { Link } from 'react-router-dom';
import { Alert } from 'shared/mui/Alert/Alert';
import { Card, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { ClickAwayListener } from 'shared/mui/ClickAwayListener/ClickAwayListener';
import { TextFieldWithCopyButton } from 'shared/mui/Fields';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { appColors } from 'themes/variables';

type NotificationKeyTooltipCardProps = {
  value?: string;
  onClose: () => void;
};

export const NotificationKeyTooltipCard: React.FC<NotificationKeyTooltipCardProps> = ({
  value,
  onClose,
}) => (
  <ClickAwayListener onClickAway={onClose}>
    <Card
      sx={{
        width: '444px',
      }}
    >
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Ключ нотификации"
      />
      <CardContent sx={{ paddingTop: '0!important' }}>
        <TextFieldWithCopyButton
          disabled
          fullWidth
          size="medium"
          sx={{ marginTop: 0 }}
          value={value}
          variant="outlined"
        />
        <Alert severity="info" sx={{ marginTop: '12px' }}>
          Используйте внутренний сервис{' '}
          <Link style={{ color: appColors.primary.main }} target="_blank" to={DNOTE_SERVICE_URL}>
            DNOTE
          </Link>{' '}
          для передачи значения ключа API.
        </Alert>
      </CardContent>
    </Card>
  </ClickAwayListener>
);
