import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { StyledBox } from './ProviderShowDialog.styled';
import { Provider } from '../../../types';
import { ProviderShowTable } from '../ProviderShowTable/ProviderShowTable';

export type ProviderShowDialogProps = {
  provider?: Provider;
  onClose: () => void;
} & DialogProps;

export const ProviderShowDialog = ({
  open,
  provider,
  onClose,
}: ProviderShowDialogProps): JSX.Element => (
  <Dialog maxWidth="md" onClose={onClose} open={open}>
    <StyledBox>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Просмотр провайдера"
      />
      <RecordContextProvider value={provider}>
        <ProviderShowTable
          sx={{ marginBottom: '48px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        />
      </RecordContextProvider>
    </StyledBox>
  </Dialog>
);
