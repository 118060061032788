import _ from 'lodash';

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

export function cleanEmpty<T extends Record<string, any>>(collection: T): NoUndefinedField<T> {
  const add = _.isArray(collection)
    ? (collection: any, key: any, value: any) => collection.push(value)
    : (collection: any, key: any, value: any) => (collection[key] = value);

  return _.transform(collection, (collection, value, key) => {
    if (_.isObject(value)) {
      // eslint-disable-next-line no-param-reassign
      value = cleanEmpty(value);
      if (_.isEmpty(value)) {
        return;
      }
    } else {
      if (!value) {
        return;
      }
    }
    add(collection, key, value);
  });
}
