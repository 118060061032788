export const enum UserEntityPermissionsNames {
  Gateway = 'Gateway',
  MerchantAccount = 'MerchantAccount',
  MerchantCredentials = 'MerchantCredentials',
}

export const USER_ENTITY_PERMISSIONS_RESOURCES = {
  [UserEntityPermissionsNames.Gateway]: 'gateways',
  [UserEntityPermissionsNames.MerchantAccount]: 'merchant_accounts',
  [UserEntityPermissionsNames.MerchantCredentials]: 'merchant_credentials',
};
