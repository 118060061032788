import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';

export const CardContentStyled = styled(CardContent)({
  width: '600px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  marginBottom: 0,
});
