import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { ImageField, TextField } from 'shared/react-admin/Fields';

export const MethodLogosShowDialogTable: React.FC = () => {
  return (
    <CardTable>
      <TableBody>
        <TableRowWrapper title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField
            source="name"
            sx={{
              display: 'inline-block',
              overflowWrap: 'break-word',
              maxWidth: '290px',
            }}
          />
        </TableRowWrapper>
        <TableRowWrapper title="Лого">
          <ImageField
            label="Логотип"
            source="filePath"
            sx={{ '& img': { width: '200px!important', objectFit: 'contain' } }}
          />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
