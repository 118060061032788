import { Box, styled } from '@mui/material';

export const StyledCardActionsButtons = styled(Box)<{ isOnlyRightSlot: boolean }>(
  ({ isOnlyRightSlot }) => ({
    width: '100%',
    padding: '24px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: isOnlyRightSlot ? 'flex-end' : 'space-between',
  }),
);

export const StyledActions = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
