import { Percent } from '@mui/icons-material';
import { useGetList, useRecordContext } from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { ListButton } from 'shared/react-admin/Buttons';
import { Provider } from 'types';

export const ProvidersListCommissions = () => {
  const record = useRecordContext();
  const { data: commissions, isLoading } = useGetList<Provider>('commissions', {
    filter: {
      provider: record.id,
    },
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return commissions?.length ? (
    <ListButton
      filter={{
        provider: record?.id,
      }}
      icon={<Percent sx={{ fontSize: '18px' }} />}
      label="Перейти"
      pathname="/commissions"
      sx={{ fontSize: '14px' }}
    />
  ) : (
    <ListButton
      filter={{
        provider: record?.id,
      }}
      icon={<Percent sx={{ fontSize: '18px' }} />}
      label="Добавить"
      pathname="/commissions/create"
      sx={{ fontSize: '14px' }}
    />
  );
};
