import { ReactNode } from 'react';

import { Breadcrumbs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCreatePath } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from 'shared/mui/Typography';

type Props = {
  listName: ReactNode;
  currentPage: string;
};

const StyledBreadcrumbs = styled(Breadcrumbs)({
  marginTop: '24px',
});

export function AppBreadCrumbs({ listName, currentPage }: Props): JSX.Element {
  const { pathname } = useLocation();
  const createPath = useCreatePath();
  const [listUrl, ...pageUrls] = pathname.split('/').slice(1);

  return (
    <StyledBreadcrumbs>
      <Link key={listUrl} to={createPath({ resource: listUrl, type: 'list' })}>
        {listName}
      </Link>
      {pageUrls.length > 0 && (
        <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>{currentPage}</Typography>
      )}
    </StyledBreadcrumbs>
  );
}
