import React from 'react';

import { DisableSitesAccessButton } from '../DisableSitesAccessButton';
import { EnableSitesAccessButton } from '../EnableSitesAccessButton';

export const CompanyMerchantsDatagridBulkActionButtons: React.FC = (props: any) => (
  <>
    <EnableSitesAccessButton {...props} />
    <DisableSitesAccessButton {...props} />
  </>
);
