import React from 'react';

import { CreateGuesser, CreateGuesserProps } from '@api-platform/admin';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { appColors } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

type Props = {
  title: string;
  listName: string;
  children: React.ReactNode;
} & CreateGuesserProps;

const StyledCreateGuesser = styled(CreateGuesser)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  marginTop: '24px',
  backgroundColor: appColors.background,
});

export function AppCreateGuesser({ title, listName, children, ...props }: Props): JSX.Element {
  return (
    <Box>
      <AppBreadCrumbs currentPage={title} listName={listName} />
      <StyledCreateGuesser {...props}>
        <AppPanelHeader pb={1}>
          <AppPanelHeaderToolbar titleText={title} />
        </AppPanelHeader>
        {children}
      </StyledCreateGuesser>
    </Box>
  );
}
