import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { ListButton } from 'shared/react-admin/Buttons';

type UsersLinkButtonProps = {
  userId?: string;
};

export const UsersLinkButton: React.FC<UsersLinkButtonProps> = ({ userId }) => {
  return (
    <ListButton
      color="secondary"
      filter={{ user: userId }}
      icon={<PermContactCalendarIcon style={{ marginTop: '1px' }} />}
      label=""
      pathname="/user_actions"
      sx={{
        minWidth: 0,
        span: {
          marginRight: '0!important',
        },
        '&:hover': {
          borderRadius: '50%',
        },
        svg: {
          marginLeft: '2px',
        },
        '&.MuiTypography-root': {
          height: '22px',
        },
      }}
    />
  );
};
