import { usePermissions } from 'hooks/usePermissions';
import { FunctionField } from 'shared/react-admin/Fields';
import { Permission } from 'types';

import { ApiClientsListActiveFieldSwitch } from './ApiClientsListActiveFieldSwitch/ApiClientsListActiveFieldSwitch';

type Props = {
  label: string;
  source?: string;
};

export const ApiClientsListActiveField: React.FC<Props> = ({ label }) => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <FunctionField
      label={label}
      render={() =>
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) && (
          <ApiClientsListActiveFieldSwitch />
        )
      }
      sortable={false}
      source="active"
    />
  );
};
