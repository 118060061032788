import { useMemo, useState } from 'react';

import { useRecordModal } from 'hooks/useRecordModal';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { PageHeader } from 'shared/mui/PageHeader';
import { Typography } from 'shared/mui/Typography';
import { GatewayConversionListItem } from 'types/orderConversion';

import { useOrderConversion } from './hooks/useOrderConversion';
import { useOrderConversionFilter } from './hooks/useOrderConversionFilter';
import { OrderConversionListConversion } from './OrderConversionListConversion/OrderConversionListConversion';
import { OrderConversionListFilter } from './OrderConversionListFilter/OrderConversionListFilter';
import { OrderConversionListGateway } from './OrderConversionListGateway/OrderConversionListGateway';
import { OrderConversionListGatewayCreate } from './OrderConversionListGateway/OrderConversionListGatewayCreate/OrderConversionListGatewayCreate';
import { OrderConversionListGatewayDelete } from './OrderConversionListGateway/OrderConversionListGatewayDelete/OrderConversionListGatewayDelete';
import { OrderConversionListGatewayEdit } from './OrderConversionListGateway/OrderConversionListGatewayEdit/OrderConversionListGatewayEdit';
import { OrderConversionListGatewayReferenceDialog } from './OrderConversionListGateway/OrderConversionListGatewayReferenceDialog/OrderConversionListGatewayReferenceDialog';
import { OrderConversionListOrders } from './OrderConversionListOrders/OrderConversionListOrders';
import { OrderConversionListRefreshDisable } from './OrderConversionListRefreshDisable/OrderConversionListRefreshDisable';
import { OrderConversionListRefreshEnable } from './OrderConversionListRefreshEnable/OrderConversionListRefreshEnable';

export type OrderConversionListFormValues = {
  merchant: string | null;
  gateway: string[];
  provider: string[];
  period: string;
};

export const OrderConversionList = () => {
  const [isOpenRederenceDialog, setIsOpenRederenceDialog] = useState(false);
  const { form, params, ...filterProps } = useOrderConversionFilter();

  const [autoRefresh, setAutoRefresh] = useState(false);

  const [openRefreshEnable, setOpenRefreshEnable] = useState(false);
  const [openRefreshDisable, setOpenRefreshDisable] = useState(false);

  const onChangeAutoRefresh = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    checked ? setOpenRefreshEnable(true) : setOpenRefreshDisable(true);
  };

  const { data, isLoading, isFetching } = useOrderConversion(params, autoRefresh ? 60000 : false);
  const [isOnlyReject, setIsOnlyReject] = useState<boolean>(false);

  const {
    record: recordCreate,
    handleOpenRecordModal: handleOpenCreateModal,
    openRecordModal: openCreateModal,
    handleCloseRecordModal: handleCloseCreateModal,
  } = useRecordModal<GatewayConversionListItem>();

  const {
    record: recordEdit,
    handleOpenRecordModal: handleOpenEditModal,
    openRecordModal: openEditModal,
    handleCloseRecordModal: handleCloseEditModal,
  } = useRecordModal<GatewayConversionListItem>();

  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenDeleteModal,
    openRecordModal: openDeleteModal,
    handleCloseRecordModal: handleCloseDeleteModal,
  } = useRecordModal<GatewayConversionListItem>();

  const gatewayConversionList = useMemo(() => {
    if (!isOnlyReject) {
      return data?.gatewayConversionList;
    }

    return data?.gatewayConversionList.filter(
      (gatewayConversion) => gatewayConversion.messages.length > 0,
    );
  }, [isOnlyReject, data?.gatewayConversionList]);
  const openReferenceDialog = () => setIsOpenRederenceDialog(true);

  return (
    <div>
      <PageHeader
        actions={[]}
        headerTitle={{
          titleText: 'Мониторинг конверсии заказов',
          tooltipText:
            'Конверсия заказов — это метрика, отражающая процентное соотношение заказов, находящихся в состоянии «выполнен», «ожидание» или «отклонен» за выбранный период времени.',
        }}
        sx={{ marginTop: '24px' }}
      />
      <OrderConversionListFilter form={form} {...filterProps} />
      {!isLoading && (
        <>
          {!data ? (
            <Alert severity="info">Пожалуйста, укажите мерчанта для отображения статистики</Alert>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <OrderConversionListConversion
                  autoRefresh={autoRefresh}
                  items={data?.summaryConversionItems || []}
                  onChangeAutoRefresh={onChangeAutoRefresh}
                />
                <OrderConversionListRefreshEnable
                  onClose={() => setOpenRefreshEnable(false)}
                  onConfirm={() => {
                    setAutoRefresh(true);
                  }}
                  open={openRefreshEnable}
                />
                <OrderConversionListRefreshDisable
                  onClose={() => setOpenRefreshDisable(false)}
                  onConfirm={() => {
                    setAutoRefresh(false);
                  }}
                  open={openRefreshDisable}
                />
                <OrderConversionListOrders orders={data?.totalOrders} />
              </Box>
              <PageHeader
                actions={[
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isOnlyReject}
                        onChange={(_, checked) => {
                          setIsOnlyReject(checked);
                        }}
                      />
                    }
                    key="conversion"
                    label={<Typography component="span">Показывать только отклонения</Typography>}
                    sx={{ marginRight: 0 }}
                  />,
                ]}
                headerTitle={{ titleText: 'Список шлюзов' }}
                sx={{ marginTop: '21px' }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {gatewayConversionList?.map((listItem) => {
                  return (
                    <OrderConversionListGateway
                      isLoading={isFetching}
                      key={listItem.id}
                      listItem={listItem}
                      onOpenCreateModal={handleOpenCreateModal}
                      onOpenEditModal={handleOpenEditModal}
                    />
                  );
                })}
              </Box>
            </>
          )}
        </>
      )}
      {openCreateModal && (
        <OrderConversionListGatewayCreate
          merchant={form.getValues('merchant')}
          onClose={handleCloseCreateModal}
          onOpenReferenceDialog={openReferenceDialog}
          open={openCreateModal}
          params={params}
          record={recordCreate}
        />
      )}
      {openEditModal && (
        <OrderConversionListGatewayEdit
          onClose={handleCloseEditModal}
          onDelete={(record) => {
            handleOpenDeleteModal(record);
            handleCloseEditModal();
          }}
          onOpenReferenceDialog={openReferenceDialog}
          open={openEditModal}
          params={params}
          record={recordEdit}
        />
      )}
      <OrderConversionListGatewayDelete
        onCancel={(record) => {
          handleOpenEditModal(record);
          handleCloseDeleteModal();
        }}
        onClose={handleCloseDeleteModal}
        open={openDeleteModal}
        record={recordDelete}
      />
      {isOpenRederenceDialog && (
        <OrderConversionListGatewayReferenceDialog
          onClose={() => setIsOpenRederenceDialog(false)}
          open={isOpenRederenceDialog}
        />
      )}
    </div>
  );
};
