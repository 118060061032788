import { EditPayoutControlProvider } from './context/EditPayoutControlContext';
import { EditPayoutControlManage } from './EditPayoutControlManage/EditPayoutControlManage';
import { EditPayoutControlMapping } from './EditPayoutControlMapping/EditPayoutControlMapping';
import { EditPayoutControlTestClientsExceptions } from './EditPayoutControlTestClientsExceptions/EditPayoutControlTestClientsExceptions';
import { MerchantPayoutControl } from '../../../../../../types';

type Props = {
  merchantPayoutControls: MerchantPayoutControl;
};

export const EditPayoutControl: React.FC<Props> = ({ merchantPayoutControls }) => {
  return (
    <EditPayoutControlProvider value={{ payoutControl: merchantPayoutControls }}>
      <EditPayoutControlManage />
      <EditPayoutControlTestClientsExceptions />
      <EditPayoutControlMapping />
    </EditPayoutControlProvider>
  );
};
