import React from 'react';

import { useCreatePath } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs } from 'shared/mui/Breadcrumbs';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

export type PageHeaderBreadcrumbsProps = {
  listName: React.ReactNode;
  currentPage: string;
};

export function PageHeaderBreadcrumbs({
  listName,
  currentPage,
}: PageHeaderBreadcrumbsProps): JSX.Element {
  const { pathname } = useLocation();
  const createPath = useCreatePath();
  const [listUrl, ...pageUrls] = pathname.split('/').slice(1);

  return (
    <Breadcrumbs sx={{ marginTop: '24px' }}>
      <Link
        key={listUrl}
        style={{ textDecoration: 'none', color: appColors.primary.main }}
        to={createPath({ resource: listUrl, type: 'list' })}
      >
        {listName}
      </Link>
      {pageUrls.length > 0 && (
        <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>{currentPage}</Typography>
      )}
    </Breadcrumbs>
  );
}
