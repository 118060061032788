import { FieldProps, FunctionField, RaRecord } from 'react-admin';

export function AppDateField({ source, ...rest }: FieldProps): JSX.Element {
  if (!source) {
    return <></>;
  }

  return (
    <FunctionField
      render={(record: RaRecord) => {
        if (!record[source]) {
          return '—';
        }
        return new Date(record[source] * 1000).toLocaleDateString('en-GB');
      }}
      {...rest}
    />
  );
}
