import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Drawer } from 'shared/mui/Drawer/Drawer';
import { appColors } from 'themes/variables';

export const StyledDrawer = styled(Drawer)({
  '.MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '.MuiDrawer-paper': {
    top: '48px',
    width: '600px',
    height: 'calc(100% - 48px)',
    boxShadow:
      '0px 9px 46px 8px #0000001F, 0px 24px 38px 3px #00000024, 0px 11px 15px -7px #00000033',
  },
});

export const StyledJsonViewWrapper = styled(Box)({
  margin: '16px',
  border: `1px solid ${appColors.divider}`,
  backgroundColor: '#fbfbfb',
  overflow: 'auto',
  height: 'calc(100vh - 200px)',
});
