import { Box } from 'shared/mui/Box';

import { ShowDialogStyled } from './ShowDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';
import { Show } from '../../Show/Show';

export type ShowDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  open?: boolean;
  title: string;
  width?: string;
};

export const ShowDialog: React.FC<ShowDialogProps> = ({
  actions,
  title,
  children,
  width,
  open = true,
}) => {
  const { goBack } = useGoBack();

  return (
    <ShowDialogStyled open={open}>
      <Show actions={false} sx={{ width: width || '600px' }}>
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </Show>
    </ShowDialogStyled>
  );
};
