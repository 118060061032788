import { RaRecord } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';

import { MethodsForm } from './components/MethodsForm';

export const MethodsCreate: React.FC = () => {
  const transform = (data: RaRecord): RaRecord => {
    return {
      ...data,
      logo: data.logo || null,
    };
  };

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Методы"
      mutationOptions={{ onError: () => undefined }}
      sx={{ marginTop: 0, padding: 0, width: '600px' }}
      title="Создание метода"
      transform={transform}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <MethodsForm />
      </CardContent>
    </CreateGuesserPage>
  );
};
