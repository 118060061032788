import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const StyledBox = styled(Box)({
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginLeft: 'auto',
  marginTop: '-2px',
});
