import { ReactElement } from 'react';

import { Typography } from '@mui/material';
import get from 'lodash/get';
import { ReferenceField, ReferenceFieldProps, useRecordContext } from 'react-admin';

export interface AppReferenceFieldProps extends ReferenceFieldProps {
  emptyComponent?: ReactElement;
}

const AppReferenceField = (props: AppReferenceFieldProps): JSX.Element | null => {
  const { source, emptyComponent, emptyText } = props;
  const record = useRecordContext(props);

  return get(record, source) == null ? (
    emptyComponent ? (
      emptyComponent
    ) : emptyText ? (
      <Typography component="span" variant="body2">
        {emptyText}
      </Typography>
    ) : null
  ) : (
    <ReferenceField {...props}>{props.children}</ReferenceField>
  );
};

export default AppReferenceField;
