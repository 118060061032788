import { useGetExistingIdFilterName } from 'hooks/useGetExistingIdFilterName';
import { FieldValues } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';

type Props = {
  filterValues: FieldValues;
  isLoading: boolean;
  notFoundOrdersReportIds: string[];
};

export const OrdersReportListNotFoundOrdersAlert: React.FC<Props> = ({
  isLoading,
  notFoundOrdersReportIds,
  filterValues,
}) => {
  const existingIdFilterName = useGetExistingIdFilterName(filterValues);

  if (notFoundOrdersReportIds.length && !isLoading) {
    const ids = notFoundOrdersReportIds.join(' ');

    return (
      <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
        {`Заказы с ID: ${ids} в результате поиска по ${existingIdFilterName} не найдены в системе как исполненные.`}
      </Alert>
    );
  }

  return null;
};
