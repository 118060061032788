import React, { useEffect } from 'react';

import {
  ListGuesser,
  CreateGuesser,
  EditGuesser,
  ShowGuesser,
  IntrospectedResourceGuesserProps,
  ResourceGuesserProps,
  Introspecter,
} from '@api-platform/admin';
import PropTypes from 'prop-types';
import { useResourceDefinition, useResourceDefinitionContext } from 'react-admin';
import type { ResourceDefinition, ResourceProps } from 'react-admin';

import { PageResource } from './PageResource';

export const IntrospectedPageResourceGuesser = ({
  resource,
  schema,
  list = ListGuesser,
  edit = EditGuesser,
  create = CreateGuesser,
  show = ShowGuesser,
  ...props
}: IntrospectedResourceGuesserProps): JSX.Element => {
  const { register } = useResourceDefinitionContext();
  const registeredDefinition = useResourceDefinition({ resource });

  let hasList = false;
  let hasEdit = false;
  let hasCreate = false;
  let hasShow = false;
  schema.operations?.forEach((operation) => {
    if (operation.type === 'list') {
      hasList = true;
    }
    if (operation.type === 'edit') {
      hasEdit = true;
    }
    if (operation.type === 'create') {
      hasCreate = true;
    }
    if (operation.type === 'show') {
      hasShow = true;
    }
  });

  useEffect(() => {
    if (
      registeredDefinition.hasList !== hasList ||
      registeredDefinition.hasEdit !== hasEdit ||
      registeredDefinition.hasCreate !== hasCreate ||
      registeredDefinition.hasShow !== hasShow
    ) {
      register({
        name: resource,
        icon: props.icon,
        options: props.options,
        hasList,
        hasEdit,
        hasCreate,
        hasShow,
      });
    }
  }, [
    register,
    resource,
    props.icon,
    props.options,
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    registeredDefinition,
  ]);

  return (
    <PageResource
      {...props}
      create={create as React.FC}
      edit={edit as React.FC}
      list={list as React.FC}
      name={resource}
      show={show as React.FC}
    />
  );
};

export const PageResourceGuesser = ({ name, ...props }: ResourceGuesserProps): JSX.Element => (
  <Introspecter component={IntrospectedPageResourceGuesser} resource={name} {...props} />
);

PageResourceGuesser.raName = 'Resource';

PageResourceGuesser.registerResource = (props: ResourceProps): ResourceDefinition => ({
  name: props.name,
  icon: props.icon,
  options: props.options,
  hasList: true,
  hasEdit: true,
  hasCreate: true,
  hasShow: true,
});

PageResourceGuesser.propTypes = {
  name: PropTypes.string.isRequired,
};
