import { OrdersReportSummary } from 'config/requests';

type SummaryConfig = {
  name: string;
  status: keyof OrdersReportSummary;
  link: string;
};

export const summaryConfig: SummaryConfig[] = [
  {
    name: 'Создан',
    status: 'created',
    link: '/',
  },
  {
    name: 'Подготовлен',
    status: 'prepared',
    link: '/',
  },
  {
    name: 'Ожидание',
    status: 'pending',
    link: '/',
  },
  {
    name: 'Провал',
    status: 'failure',
    link: '/',
  },
  {
    name: 'Отменен',
    status: 'cancelled',
    link: '/',
  },
  {
    name: 'Рефанд',
    status: 'refund',
    link: '/',
  },
  {
    name: 'Чарджбек',
    status: 'chargeback',
    link: '/',
  },
  {
    name: 'Просрочен',
    status: 'expired',
    link: '/',
  },
  {
    name: 'Ожидает проверки',
    status: 'waitingConfirmation',
    link: '/',
  },
];
