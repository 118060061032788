import {
  CardContent as CardContentMui,
  CardContentProps as CardContentMuiProps,
  styled,
} from '@mui/material';

export type CardContentProps = CardContentMuiProps;

export const CardContent = styled(CardContentMui)({
  padding: '16px 24px !important',
});
