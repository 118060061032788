import { styled } from '@mui/material/styles';
import { IconButton } from 'shared/mui/IconButtons';
import { ListItem } from 'shared/mui/List/ListItem';
import { appColors } from 'themes/variables';

export const StyledNotificationPopperListItem = styled(ListItem)({
  alignItems: 'flex-start',
  padding: '16px 24px 16px 16px',
  borderBottom: `1px solid ${appColors.divider}`,
});

export const NotificationPopperListItemSuccessIconButton = styled(IconButton)({
  height: '40px',
  width: '40px',
  backgroundColor: appColors.primary.main,
  '&:hover': {
    backgroundColor: appColors.primary.main,
  },
});

export const NotificationPopperListItemFailureIconButton = styled(IconButton)({
  height: '40px',
  width: '40px',
  backgroundColor: appColors.divider,
  '&:hover': {
    backgroundColor: appColors.divider,
  },
});
