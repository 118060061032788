import { useMemo } from 'react';

import { useList, UseListValue } from 'react-admin';

import { useOrderMethodsList } from './useOrderMethodsList';
import { OrderMethodsListFilter } from '../OrderMethodsList';

type UseOrderMethodsContextResult = {
  context: UseListValue | UseListValue<never>;
  refetch: () => void;
};
export function useOrderMethodsContext(
  filters: OrderMethodsListFilter,
): UseOrderMethodsContextResult {
  const { orderMethods, refetch } = useOrderMethodsList(filters);
  const context = useList({
    data: orderMethods?.data.methods ?? [],
    perPage: 1000,
  });

  const emptyContext = useList({
    data: [],
    perPage: 1000,
  });

  const resultContext = useMemo(
    () => (Object.keys(filters).length !== 0 ? context : emptyContext),
    [filters, context, emptyContext],
  );

  return { context: resultContext, refetch };
}
