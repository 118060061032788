import { useCallback } from 'react';

import { queryClient } from 'App';
import { useNotify, useRecordContext, useUpdate } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { MerchantAccount } from 'types';

type Props = {
  size?: 'small' | 'medium';
};

export function MerchantAccountsNameFieldActiveSwitch({ size = 'small' }: Props): JSX.Element {
  const notify = useNotify();
  const record = useRecordContext<MerchantAccount>();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(['merchant_accounts']);
    notify('Активность успешна изменена.', { type: 'success' });
  };

  const [update] = useUpdate(
    'gateway_rules',
    { id: record.id, data: { active: !record.active } },
    {
      onSuccess: onSuccess,
      onError: (error: any) => {
        notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
      },
    },
  );

  const toggleActiveGatewayRule = useCallback(() => {
    update();
  }, [record.active, record]);

  return (
    <Switch
      checked={record.active}
      onClick={toggleActiveGatewayRule}
      size={size}
      sx={{
        marginRight: '16px',
      }}
    />
  );
}
