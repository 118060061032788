import { styled } from '@mui/material';
import { TextField } from 'shared/mui/TextField/TextField';

export const TextFieldStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderStyle: 'dotted',
    },
  },
});
