import { useState } from 'react';

import { AxiosResponse } from 'axios';
import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { BooleanInput, KeyInputIntrospected } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs/AutocompleteMultiselectInput/AutocompleteMultiselectInput';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import {
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs/TextInputs';
import { ReferenceArrayInputIntrospected } from 'shared/react-admin/ReferenceInputs';

import { MerchantsEditConfirmGenerateKeyDialog } from './MerchantsEditConfirmGenerateKeyDialog';
import { useGenerateNotificationKey } from '../hooks/useGenerateNotificationKey';
import { useGetPlatforms } from '../hooks/useGetPlatforms';
import { useNotificationKeyValidation } from '../hooks/useNotificationKeyValidation';

export const MerchantsEditForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { setValue, clearErrors, getValues } = useFormContext();
  const { generateNotificationKey } = useGenerateNotificationKey({
    onSuccess: (data: AxiosResponse) => {
      clearErrors('notificationKey');
      setValue('notificationKey', data.data.value);
    },
  });
  const { notificationKeyValidation } = useNotificationKeyValidation();
  const platforms = useGetPlatforms();

  return (
    <>
      <TextInputIntrospected fullWidth label="Название" source="name" variant="outlined" />
      <TextInputWithAliasIntrospected fullWidth label="Алиас" source="alias" variant="outlined" />
      <KeyInputIntrospected
        fullWidth
        label="Ключ нотификации"
        onClickGenerateKey={() => setIsOpen(true)}
        source="notificationKey"
        validate={[required(), notificationKeyValidation]}
        value={getValues().notificationKey}
        variant="outlined"
      />
      <ReferenceArrayInputIntrospected
        filter={{ archive: false }}
        perPage={200}
        reference="companies"
        source="companies"
      >
        <AutocompleteMultiselectInput
          helperText={false}
          label="Компания"
          name="companies"
          optionText="name"
          size="medium"
        />
      </ReferenceArrayInputIntrospected>
      <BooleanInput label="Партнерство" source="partner" sx={{ margin: '16px 0' }} />
      <SelectInputShrink
        choices={platforms}
        fullWidth
        helperText={false}
        label="Платформа"
        name="platform"
        optionText="label"
        size="medium"
        source="platform"
        sx={{ marginBottom: '24px!important' }}
        validate={required()}
        variant="outlined"
      />
      <MerchantsEditConfirmGenerateKeyDialog
        onClose={() => setIsOpen(false)}
        onConfirm={generateNotificationKey}
        open={isOpen}
      />
    </>
  );
};
