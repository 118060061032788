import { styled } from '@mui/material/styles';

import { Box } from '../../../Box';

export const FilterCollapsibleToolbarStyled = styled(Box)({
  display: 'grid',
  gap: '16px',
  alignItems: 'center',
  padding: '0',
  gridTemplateColumns: 'repeat(4, 1fr)',
  marginTop: '16px',
});

export const FilterCollapsibleToolbarAdditionalStyled = styled(Box)({
  display: 'flex',
  gridArea: '1 / 5/ 1/ 4',
  justifyContent: 'flex-end',
  marginTop: '4px',
  width: '100%',
});
