import { useQuery } from 'react-query';

import { getOrderMethods } from '../../../config/requests';
import { OrderMethodsListFilter } from '../OrderMethodsList';

interface IUseOrderMethodsListReturn {
  orderMethods: any;
  refetch: () => Promise<any>;
}

export function useOrderMethodsList(filters: OrderMethodsListFilter): IUseOrderMethodsListReturn {
  const { data: orderMethods, refetch } = useQuery(
    ['order_methods'],
    () => getOrderMethods(filters),
    {
      enabled: false,
    },
  );

  return { orderMethods, refetch };
}
