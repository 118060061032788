import { EMPTY_FIELD } from 'constants/emptyField';

import { TextField } from 'react-admin';
import { TableBody, TableProps, TableRowWrapper } from 'shared/mui/Table';
import { NumberField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { StyledTable } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog.styled';

export const ProviderWhiteIpAddressesShowTable = ({ sx }: TableProps): JSX.Element => (
  <StyledTable sx={sx}>
    <TableBody>
      <TableRowWrapper title="ID">
        <TextField source="originId" />
      </TableRowWrapper>
      <TableRowWrapper title="Провайдер">
        <ReferenceOneField label="Провайдер" reference="providers" source="provider" target="id">
          <TextField source="name" />
        </ReferenceOneField>
      </TableRowWrapper>
      <TableRowWrapper title="IP">
        <TextField source="ip" />
      </TableRowWrapper>
      <TableRowWrapper title="Маска">
        <NumberField emptyText={EMPTY_FIELD} label="Маска" source="mask" />
      </TableRowWrapper>
    </TableBody>
  </StyledTable>
);
