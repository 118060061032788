import React, { useMemo } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { BulkActionProps, useListContext } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { MerchantAccess } from 'types';

type Props = {
  setOpenDisableDialog: (v: boolean) => void;
  setSelectedAccesses: (v: MerchantAccess[]) => void;
} & BulkActionProps;

export const MerchantShowAccessBulkDisableButton: React.FC<Props> = ({
  setOpenDisableDialog,
  selectedIds,
  setSelectedAccesses,
}) => {
  const { data: allMerchantAccesses } = useListContext<MerchantAccess>();

  const selectedMerchantAccesses = useMemo(
    () => allMerchantAccesses.filter((merchantAccess) => selectedIds?.includes(merchantAccess.id)),
    [allMerchantAccesses, selectedIds],
  );

  const onBulkActionClick = () => {
    setSelectedAccesses(selectedMerchantAccesses);
    setOpenDisableDialog(true);
  };

  return (
    <Button
      color="error"
      label="Отключить"
      onClick={onBulkActionClick}
      startIcon={<RemoveCircleOutlineIcon />}
    />
  );
};
