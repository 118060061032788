import { useQuery } from 'react-query';

import { CumulativeReportResponse, getCumulativeReport } from '../../../../config/requests';
import { CumulativeReportFilterValues } from '../CumulativeReportList';

type UseGetCumulativeReportReturnValue = {
  cumulativeReport: CumulativeReportResponse | undefined;
  isLoading: boolean;
};

export function useGetCumulativeReport(
  filters: CumulativeReportFilterValues,
): UseGetCumulativeReportReturnValue {
  const { data: cumulativeReport, isLoading } = useQuery<CumulativeReportResponse>(
    [
      'cumulative_report',
      filters.periodTo,
      filters.periodFrom,
      filters.accountsSummary,
      filters.currency,
      filters.merchantAccounts,
      filters.merchant,
      filters.provider,
    ],
    () => getCumulativeReport(filters),
    { enabled: Object.keys(filters).length > 0 },
  );

  return { cumulativeReport, isLoading };
}
