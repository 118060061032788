import { FieldProps, useRecordContext } from 'react-admin';

export type ListFieldProps<T extends { id: string }> = {
  array: T[];
  source: string;
  renderItem: (props: T) => JSX.Element;
} & FieldProps;

export function ListField<T extends { id: string }>({
  source,
  array,
  renderItem,
}: ListFieldProps<T>): JSX.Element {
  const record = useRecordContext();
  const sourceArray: Array<string> = (record as Record<string, any>)[source];
  if (!sourceArray) {
    return <></>;
  }

  const itemsToRender = array?.filter((item) => sourceArray.indexOf(item.id as string) > -1);

  return <>{itemsToRender?.map((item) => renderItem(item))}</>;
}
