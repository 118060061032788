import { Stack } from '@mui/material';
import { useCreatePath, useGetMany, useRecordContext } from 'react-admin';
import { Chip, LinkChip } from 'shared/mui/Chips';
import { Merchant, MerchantSpaces } from 'types';

export function MerchantSpacesMerchantsField(): JSX.Element {
  const record = useRecordContext<MerchantSpaces>();
  const { data: merchants } = useGetMany<Merchant>('merchants', {
    ids: record.sites.map((site) => site.sourceMerchantId),
  });
  const createPath = useCreatePath();

  if (!record.sites.length) {
    return <span>—</span>;
  }

  return (
    <Stack flexDirection="column" gap={1}>
      {merchants && merchants?.length > 3 ? (
        <>
          {merchants.slice(0, 2).map((record) => (
            <LinkChip
              href={createPath({ resource: 'merchants', id: record?.id, type: 'show' })}
              key={record.id}
              label={record.name}
              size="small"
            />
          ))}
          <Chip color="primary" label={`+${merchants.length - 2}`} size="small" />
        </>
      ) : (
        merchants?.map((record) => (
          <LinkChip
            clickable
            component="a"
            href={createPath({ resource: 'merchants', id: record?.id, type: 'show' })}
            key={record.id}
            label={record.name}
            size="small"
          />
        ))
      )}
    </Stack>
  );
}
