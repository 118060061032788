import { queryClient } from 'App';
import { MerchantNotificationsEventNamesType } from 'config/requests';
import { usePermissions } from 'hooks/usePermissions';
import { Form, RecordContextProvider } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { MerchantNotification, Permission } from 'types';

import {
  MerchantsShowNotificationsForm,
  MerchantsShowNotificationsFormValues,
} from '../components/MerchantsShowNotificationsForm/MerchantsShowNotificationsForm';
import { useEditMerchantNotification } from '../hooks/useEditMerchantNotification';
import { getMerchantNotificationData } from '../utils/getMerchantNotificationData';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onDelete: (record: MerchantNotification) => void;
  record: MerchantNotification;
  merchantId: string;
  eventNames?: MerchantNotificationsEventNamesType[];
};

export const MerchantsShowNotificationsEditDialog: React.FC<Props> = ({
  open,
  onClose,
  onDelete,
  merchantId,
  record,
  eventNames,
}) => {
  const { hasResourcePermissions } = usePermissions();
  const { isLoading, mutate } = useEditMerchantNotification(record.id, () => {
    onClose();
    queryClient.invalidateQueries('merchant_notification_settings');
  });

  const handleSubmit = (formData: MerchantsShowNotificationsFormValues) => {
    const data = getMerchantNotificationData(formData, merchantId, eventNames);
    mutate(data);
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <RecordContextProvider value={record}>
        <Form onSubmit={(data) => handleSubmit(data as MerchantsShowNotificationsFormValues)}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Изменение настроек уведомления"
          />
          <MerchantsShowNotificationsForm eventNames={eventNames} record={record} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button
                isLoading={isLoading}
                key="save"
                label="Сохранить"
                type="submit"
                variant="contained"
              />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Delete]) && (
                <DeleteButton
                  onClick={() => {
                    onClose();
                    record && onDelete(record);
                  }}
                  variant="outlined"
                />
              )
            }
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
