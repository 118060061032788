import React, { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, TextField } from '@mui/material';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { sendRestorePasswordLink } from '../../config/requests';

const ForgotPassword = (): JSX.Element => {
  const [reddyNumber, setReddyNumber] = useState('');
  const notify = useNotify();
  const navigate = useNavigate();

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    sendRestorePasswordLink(reddyNumber)
      .then((res) => {
        notify(res.data.message, { type: 'success' });
        navigate('login');
      })
      .catch((e) => notify(e.data.errors[0].title, { type: 'error' }));
  };

  return (
    <form onSubmit={submit}>
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          fullWidth
          id="reddyNumber"
          label="Reddy Number"
          margin="normal"
          onChange={(e) => setReddyNumber(e.target.value)}
          required
          type="text"
          value={reddyNumber}
          variant="outlined"
        />
        <Button sx={{ marginBottom: '8px' }} type="submit" variant="contained">
          Отправить
        </Button>
        <Button
          onClick={() => navigate('login')}
          startIcon={<ArrowBackIcon />}
          sx={{ alignSelf: 'flex-start' }}
        >
          Назад
        </Button>
      </Container>
    </form>
  );
};

export default ForgotPassword;
