import React from 'react';

import { FunctionField } from 'shared/react-admin/Fields';
import { Merchant } from 'types';

type Props = {
  source?: string;
  label?: string;
};

export const MerchantsPartnersField: React.FC<Props> = () => {
  return <FunctionField render={({ partner }: Merchant) => (partner ? 'Да' : 'Нет')} />;
};
