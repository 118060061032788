import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Create, CreateProps } from 'react-admin';

import { appColors } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

type Props = {
  title: string;
  listName: string;
  children: React.ReactNode;
} & CreateProps;

const StyledCreate = styled(Create)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  marginTop: '24px',
  backgroundColor: appColors.background,
});

export function AppCreate({ title, listName, children, ...props }: Props): JSX.Element {
  return (
    <Box>
      <AppBreadCrumbs currentPage={title} listName={listName} />
      <StyledCreate {...props}>
        <Box>
          <AppPanelHeader pb={1} pl={2}>
            <AppPanelHeaderToolbar titleText={title} />
          </AppPanelHeader>
          {children}
        </Box>
      </StyledCreate>
    </Box>
  );
}
