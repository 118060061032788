import { green, orange } from '@mui/material/colors';
import { Chip } from 'shared/mui/Chips';
import { FunctionField } from 'shared/react-admin/Fields';
import { ApiKey } from 'types';

type Props = {
  source?: string;
  label: string;
};

export const ApiClientsShowApiKeysStatusField: React.FC<Props> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      render={({ active }: ApiKey) => (
        <Chip
          color="primary"
          label={active ? 'Активен' : 'Не активен'}
          size="small"
          sx={{
            background: active ? green[800] : orange[800],
          }}
        />
      )}
      sortable={false}
      source={source}
    />
  );
};
