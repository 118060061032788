import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';
import { getBalanceReport } from 'config/requests';
import { useQuery } from 'react-query';
import { QueryObserverBaseResult } from 'react-query/types/core/types';
import { BalanceReport } from 'types';

export function useBalanceReportList(filters: BalanceReportFilterValues): {
  balanceList: BalanceReport | undefined;
  refetchBalanceList: QueryObserverBaseResult<BalanceReport>['refetch'];
  isLoading: boolean;
} {
  const {
    data: balanceList,
    refetch,
    isLoading,
  } = useQuery(['balance_list', filters], () => getBalanceReport(filters), {
    enabled: false,
  });

  return { balanceList, refetchBalanceList: refetch, isLoading };
}
