import { useState } from 'react';

import { MethodLogosCreateForm } from 'components/MethodLogos/MethodLogosCreate/MethodLogosCreateForm';
import { useNotify } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Modal } from 'shared/mui/Modal/Modal';
import { SaveButton } from 'shared/react-admin/Buttons';
import { modalBase } from 'themes/variables';
import { ModalProps } from 'types';

import { sendLogo } from '../../../config/requests';

type MethodLogosCreateLogoModalProps = {
  onSetLogo: (id: string) => void;
} & ModalProps;

type MethodLogosCreateFormValues = {
  file?: FileList;
  filePath: string;
  name: string;
};

export const MethodLogosCreateLogoModal: React.FC<MethodLogosCreateLogoModalProps> = ({
  closeModal,
  openModal,
  onSetLogo,
}) => {
  const notify = useNotify();
  const [errors, setErrors] = useState<any>();
  const form = useForm({
    defaultValues: {
      name: '',
      filePath: '',
      file: undefined,
    },
  });

  const submitForm = (data: MethodLogosCreateFormValues) => {
    if (data?.file?.[0]) {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('name', data.name);

      sendLogo(formData)
        .then((response: any) => {
          notify('Логотип создан!', { type: 'info' });
          onSetLogo(`/admin/method_logos/${response.id}`);
          closeModal();
        })
        .catch((e) => setErrors(e?.data));
    } else {
      notify('Загрузите логотип!', { type: 'error' });
    }
  };

  return (
    <Modal onClose={closeModal} open={openModal}>
      <Box
        sx={{
          ...modalBase,
          display: 'flex',
          top: '50%',
          flexDirection: 'column',
          padding: 0,
          width: '444px',
        }}
      >
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={closeModal} />]}
          title="Загрузка логотипа"
        />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitForm)}>
            <MethodLogosCreateForm errors={errors} />
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton key="save-button" label="Загрузить" type="submit" />,
                <CancelButton key="method-logos-cancel" onClick={closeModal} variant="outlined" />,
              ]}
            />
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};
