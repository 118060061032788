import { EMPTY_FIELD } from 'constants/emptyField';

import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { UserRoleChip } from 'shared/mui/Chips';
import { TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField } from 'shared/react-admin/ReferenceFields';
import { User } from 'types';

import { StyledTableBody } from './UsersShow.styled';

const dateStyle = { padding: '0 16px' };

export const UsersShowTable = (): JSX.Element => (
  <CardTable sx={{ tableLayout: 'fixed' }}>
    <StyledTableBody>
      <TableRowWrapper title="ID">
        <TextField source="originId" />
      </TableRowWrapper>
      <TableRowWrapper title="Пользователь">
        <FunctionField render={({ firstName, lastName }: User) => `${firstName} ${lastName}`} />
      </TableRowWrapper>
      <TableRowWrapper title="Электронная почта">
        <TextField source="email" />
      </TableRowWrapper>
      <TableRowWrapper title="Роль">
        <ReferenceArrayField reference="roles" source="roleEntities">
          <SingleFieldList linkType={false}>
            <UserRoleChip />
          </SingleFieldList>
        </ReferenceArrayField>
      </TableRowWrapper>
      <TableRowWrapper title="Активен">
        <BooleanField source="enabled" />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Дата создания">
        <DateTimeField source="createdAt" />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Дата редактир.">
        <DateTimeField source="updatedAt" />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Reddy">
        <TextField source="reddyNumber" />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Групповой email">
        <TextField emptyText={EMPTY_FIELD} source="corporateEmail" />
      </TableRowWrapper>
      <TableRowWrapper title="2FA">
        <BooleanField source="twoFactor" />
      </TableRowWrapper>
    </StyledTableBody>
  </CardTable>
);
