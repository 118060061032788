import { EMPTY_FIELD } from 'constants/emptyField';

import { useRecordModal } from 'hooks/useRecordModal';
import { ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';
import { CreateButton } from 'shared/mui/Buttons';
import { UserRoleChip } from 'shared/mui/Chips';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { ExportButton } from 'shared/react-admin/Buttons';
import {
  BooleanField,
  DateTimeField,
  FunctionField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledDatagrid } from './UsersList.styled';
import { UsersListBulkActionsToolbar } from './UsersListBulkActionsToolbar';
import { UsersListCorporateEmailField } from './UsersListCorporateEmailField';
import { UsersListFilters } from './UsersListFilters';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission, User } from '../../../types';
import { AppListShowButton, AppListEditButton } from '../../Common/Buttons';
import { UserDeleteModal } from '../components/UserDeleteModal';
import { UsersLinkButton } from '../components/UsersLinkButton';

export const UsersList = (): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<User>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('users', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-button" />
        ),
        <ExportButton key="export-button" />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Пользователи',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <UsersListFilters />
      <StyledDatagrid
        bulkActionButtons={
          hasResourcePermissions('users', [Permission.Full, Permission.Update]) && (
            <UsersListBulkActionsToolbar />
          )
        }
      >
        <TextField label="ID" sortBy="id" source="originId" />
        <FunctionField
          cellClassName="userInfo"
          label="Пользователь"
          render={() => <UserProfileField />}
        />
        <ReferenceArrayField label="Роль" reference="roles" sortable={false} source="roleEntities">
          <SingleFieldList linkType={false}>
            <UserRoleChip />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField label="Активен" source="enabled" />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <TextField emptyText={EMPTY_FIELD} label="Reddy" source="reddyNumber" />
        <FunctionField
          label="Групповой email"
          render={() => <UsersListCorporateEmailField />}
          source="corporateEmail"
        />
        <BooleanField label="2FA" source="twoFactor" />
        <ActionsSlots
          render={(record: User) => (
            <>
              <UsersLinkButton key="user-actions" userId={record?.id} />
              <AppListShowButton />
              {hasResourcePermissions('users', [Permission.Full, Permission.Update]) && (
                <AppListEditButton />
              )}
              {hasResourcePermissions('users', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModal(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModal && (
        <UserDeleteModal
          handleCloseRecordModal={handleCloseRecordModal}
          openRecordModal={openRecordModal}
          record={record}
        />
      )}
    </ListPage>
  );
};
