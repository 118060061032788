import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { useFilters } from '../../../hooks/useFilters';
import { useGetPlatforms } from '../hooks/useGetPlatforms';

type MerchantsFilterValues = {
  name: string;
  companies: string[];
  partner: boolean | null;
  platform: string;
};

export const MerchantsListFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantsFilterValues>({
    name: '',
    companies: [],
    partner: null,
    platform: '',
  });
  const platforms = useGetPlatforms();

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              id="name"
              key="name"
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={[
                { id: true, name: 'В архиве' },
                { id: false, name: 'Активен' },
              ]}
              helperText={false}
              label="Состояние"
              name="archive"
              source="archive"
              variant="outlined"
            />
            <ReferenceArrayInput
              isFilter={true}
              name="companies"
              perPage={200}
              reference="companies"
              source="companies"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Компания"
                name="companies"
                optionText="name"
              />
            </ReferenceArrayInput>
            <SelectInputShrink
              choices={platforms}
              fullWidth
              helperText={false}
              label="Платформа"
              name="platform"
              optionText="label"
              size="small"
              source="platform"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              label="Партнерский"
              name="partner"
              source="partner"
              variant="outlined"
            />
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
