export const EXPORT_FIELDS_CUMULATIVE_REPORT = [
  {
    index: '0',
    source: 'Периоды',
    label: 'Периоды',
  },
  {
    index: '1',
    source: 'Валюта',
    label: 'Валюта',
  },
  {
    index: '2',
    source: 'Пополнения',
    label: 'Пополнения',
  },
  {
    index: '3',
    source: 'Выводы',
    label: 'Выводы',
  },
  {
    index: '4',
    source: 'Рефанды',
    label: 'Рефанды',
  },
  {
    index: '5',
    source: 'Чарджбеки',
    label: 'Чарджбеки',
  },
  {
    index: '6',
    source: 'Комиссии',
    label: 'Комиссии',
  },
  {
    index: '7',
    source: 'Итого за период',
    label: 'Итого за период',
  },
  {
    index: '8',
    source: 'Нарастающий итог',
    label: 'Нарастающий итог',
  },
];
