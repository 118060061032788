import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { appColors } from 'themes/variables';

export const StyledLinkChip = styled(Chip)({
  width: 'fit-content',
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
