import ImageEye from '@mui/icons-material/RemoveRedEye';

import { IconButton, IconButtonProps } from '..';

export type ShowIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function ShowIconButton(props: ShowIconButtonProps) {
  return (
    <IconButton {...props}>
      <ImageEye fontSize="inherit" sx={{ marginLeft: '1px', marginTop: '1px' }} />
    </IconButton>
  );
}
