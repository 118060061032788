import { StyledShow } from './ShowPage.styled';
import { Box } from '../../../mui/Box';
import { CardActionsButtons, CardHeader } from '../../../mui/Card';
import { CardAdditionalButtons } from '../../../mui/Card/CardAdditionalButtons/CardAdditionalButtons';
import { PageHeader, PageHeaderTitleProps } from '../../../mui/PageHeader';
import { SimpleShowLayout } from '../../SimpleShowLayout/SimpleShowLayout';

export type ShowPageProps = {
  headerTitle?: PageHeaderTitleProps;
  title: string;
  listName: string;
  children: React.ReactNode;
  leftActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  rightActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  actions?: React.ReactElement[];
  additionalActions?: React.ReactElement[] | false;
};

export const ShowPage: React.FC<ShowPageProps> = ({
  title,
  listName,
  children,
  headerTitle,
  leftActionsSlot,
  rightActionsSlot,
  additionalActions,
  actions,
}) => (
  <Box>
    <PageHeader
      breadCrumbs={{
        currentPage: title,
        listName: listName,
      }}
      headerTitle={headerTitle}
    />
    <StyledShow actions={false}>
      <SimpleShowLayout>
        <CardHeader actions={actions} title={title} />
        {additionalActions && <CardAdditionalButtons>{additionalActions}</CardAdditionalButtons>}
        {children}
        <CardActionsButtons leftActionsSlot={leftActionsSlot} rightActionsSlot={rightActionsSlot} />
      </SimpleShowLayout>
    </StyledShow>
  </Box>
);
