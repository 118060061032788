import ImageEye from '@mui/icons-material/RemoveRedEye';
import { useHistoryContext } from 'hooks/useHistoryContext';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';

import { IconButton, IconButtonProps } from '..';

export type ShowDialogIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function ShowDialogIconButton(props: ShowDialogIconButtonProps): JSX.Element {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const location = useLocation();
  const { baseRoute } = useHistoryContext();

  return (
    <IconButton
      {...props}
      component={Link}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record.id)}/show`,
        state: { backgroundLocation: baseRoute || location },
      }}
    >
      <ImageEye fontSize="inherit" sx={{ marginLeft: '1px', marginTop: '1px' }} />
    </IconButton>
  );
}
