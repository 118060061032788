import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { JsonInput } from 'shared/react-admin/Inputs/JsonInputIntrospected/JsonInput';

export const CardContentStyled = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  '&:first-child': {
    borderRight: '1px solid #ebebeb',
  },
});

export const JsonInputStyled = styled(JsonInput)({
  '& .jer-editor-container': {
    background: '#fff !important',
    border: '1px solid #c4c4c4',
  },
});
