import { MERCHANT_SPACES_ROLES_ICONS, MerchantSpacesRole } from 'constants/roles';

import React from 'react';

import { useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';

export const MerchantSpacesRoleOption: React.FC = () => {
  const record = useRecordContext();

  return (
    <Chip icon={MERCHANT_SPACES_ROLES_ICONS[record.id as MerchantSpacesRole]} label={record.name} />
  );
};
