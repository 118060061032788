import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ChipField } from 'shared/react-admin/Fields';

export const StyledChipField = styled(ChipField)({
  '.MuiChip-label': {
    color: '#FFF',
    cursor: 'pointer',
    lineHeight: '12px',
  },
});

export const StyledChipFieldLinked = styled(ChipField)({
  '.MuiChip-label': {
    color: blue[700],
    lineHeight: '12px',
  },
});
