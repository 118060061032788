import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { FilterChipType } from 'constants/filterChipType';

import { useMemo } from 'react';

import {
  ResetFilterArrayById,
  ResetFilterBySource,
} from 'components/MerchantSpaces/MerchantSpacesList/hooks/useMerchantSpacesFilters';
import { useFilterChips } from 'hooks/useFilterChips';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantSpacesFilter } from '../MerchantSpacesList';

type MerchantSpacesListFilterProps = {
  form: UseFormReturn<MerchantSpacesFilter>;
  resetFilter: () => void;
  onSubmit: (values: MerchantSpacesFilter) => void;
  resetFilterBySource: ResetFilterBySource<FieldValues>;
  resetFilterArrayById: ResetFilterArrayById<FieldValues>;
};

export const MerchantSpacesListFilter: React.FC<MerchantSpacesListFilterProps> = ({
  form,
  resetFilter,
  resetFilterBySource,
  resetFilterArrayById,
  onSubmit,
}) => {
  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'sites', label: 'Мерчант', type: FilterChipType.IdArray },
      { source: 'sourceCompany', label: 'Компания', type: FilterChipType.Id },
      { source: 'active', label: 'Активен', type: FilterChipType.Boolean },
      { source: 'spaceName', label: 'Название', type: FilterChipType.String },
    ],
    appliedFilters: cleanEmpty(form.getValues()),
  });

  const isResetDisabled = useMemo(() => {
    const hasDirtyFields = form
      .watch(['sites', 'sourceCompany', 'active', 'spaceName'])
      .some((value) => {
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        return Boolean(value);
      });
    return !(hasDirtyFields || form.formState.isDirty);
  }, [form, onSubmit]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton
              disabled={isResetDisabled}
              key="reset-button"
              onClick={resetFilter}
            />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById}
          resetFilterBySource={resetFilterBySource}
        >
          <FilterRow>
            <TextInputShrink
              fullWidth
              helperText={false}
              label="Название"
              name="spaceName"
              source="spaceName"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              label="Активен"
              name="active"
              source="active"
              variant="outlined"
            />
            <ReferenceInput
              isFilter={true}
              perPage={100}
              reference="companies"
              source="sourceCompany"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                fullWidth
                helperText={false}
                label="Компания"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput isFilter={true} perPage={100} reference="merchants" source="sites">
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                fullWidth
                helperText={false}
                label="Мерчант"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
