import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Alert } from 'shared/mui/Alert/Alert';

export const AlertStyled = styled(Alert)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: blue[50],
  color: 'black',
  height: '62px',
  width: '100%',
});
