import { styled } from '@mui/material/styles';

import { Box } from '../../../../Box';

export const RowStyled = styled(Box)({
  gap: '10px',
  width: '100%',
  alignItems: 'flex-start',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
});
