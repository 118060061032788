import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = { isBold?: boolean } & TableCellProps;

const StyledTableCell = styled(TableCell)<{ isBold?: boolean }>(({ isBold }) => ({
  pl: 3,
  pr: 3,
  fontWeight: isBold ? 500 : 400,
}));

export function AppShowDialogTableCell({ children, isBold, ...restProps }: Props): JSX.Element {
  return (
    <StyledTableCell isBold={isBold} {...restProps}>
      {children}
    </StyledTableCell>
  );
}
