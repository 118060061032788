import { useCallback } from 'react';

import { useRecordContext } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { MerchantSpacesUsers } from 'types';

import { useChangeMarchantSpacesUsersActivity } from '../../hooks/useChangeMerchantSpacesUsersActivity';

export function MerchantSpacesUsersActivitySwitch({
  refetchMerchantSpacesUsers,
}: {
  refetchMerchantSpacesUsers: () => void;
}): JSX.Element {
  const record = useRecordContext<MerchantSpacesUsers>();

  const { changeMarchantSpacesUsersActivityMutation } = useChangeMarchantSpacesUsersActivity(
    refetchMerchantSpacesUsers,
  );

  const toggleActivity = useCallback(() => {
    if (!record) {
      return;
    }
    changeMarchantSpacesUsersActivityMutation.mutate({
      id: record.id,
      active: !record.active,
    });
  }, [record.active]);

  return <Switch checked={record?.active} onChange={toggleActivity} size="small" />;
}
