import { styled } from '@mui/material/styles';
import { ShowButton } from 'react-admin';

import { appColors } from '../../../../themes/variables';

export const StyledShowIconButton = styled(ShowButton)({
  color: appColors.secondary.main,
  height: '28px',
  minWidth: '28px',
  padding: '1px 0 0 3px',
  '& .MuiButton-startIcon': {
    fontSize: '20px',
    marginRight: 0,
  },
  '&:hover': { borderRadius: '50%' },
});
