import { useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { getOrderStatuses } from 'config/requests';
import { FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
  onDelete: (source: string, id: string) => void;
} & Omit<ChipProps, 'onDelete'>;

export function FilterChipsOrderStatusField<TFilterValues extends FieldValues>({
  chipValue,
  onDelete,
  ...restProps
}: Props<TFilterValues>) {
  const { data: orderStatuses } = useQuery('new/order_statuses', getOrderStatuses);

  const selectedStatuses = useMemo(() => {
    return orderStatuses?.filter((status) => chipValue.value.includes(status.value));
  }, [orderStatuses, chipValue.value]);

  if (!chipValue.value) {
    return <></>;
  }

  return (
    <>
      {selectedStatuses?.map((status) => (
        <Chip
          deleteIcon={<CancelIcon />}
          key={status.id}
          label={`${chipValue.label}: ${status?.description}`}
          onDelete={() => onDelete(chipValue.source, status.value)}
          {...restProps}
        />
      ))}
    </>
  );
}
