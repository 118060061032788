import { styled } from '@mui/material/styles';
import { AvatarGroup } from 'shared/mui/AvatarGroup/AvatarGroup';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(160);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': columnStyle,
  '& .column-countries': getColumnStyle(220),
  '& .column-manager': columnStyle,
  '& .column-members': getColumnStyle(456),
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  width: 'fit-content',
  '& .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: '11px',
    fontWeight: 500,
  },
});
