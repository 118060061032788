import { EMPTY_FIELD } from 'constants/emptyField';

import { useListContext } from 'react-admin';
import { Avatar } from 'shared/mui/Avatars/Avatar/Avatar';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { User } from 'types';

import { StyledAvatarGroup } from './WorkTeamsList.styled';

export const WorkTeamsListMembersAvatars = (): JSX.Element => {
  const { data, isLoading } = useListContext<User>();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data.length) {
    return <span>{EMPTY_FIELD}</span>;
  }

  return (
    <StyledAvatarGroup max={5}>
      {data?.map((user) => (
        <Avatar
          key={user.id}
          sx={{ width: 24, height: 24, fontSize: '8px' }}
          title={`${user.firstName} ${user.lastName}`}
        >
          {`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()}
        </Avatar>
      ))}
    </StyledAvatarGroup>
  );
};
