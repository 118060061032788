import { useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button, ButtonProps } from 'shared/mui/Buttons';
import { AutocompleteInputShrink, BooleanInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { fieldWidth } from 'themes/variables';
import { Method } from 'types';
import { jsonEqual } from 'utils';

import { GatewayRulesFormMappingItem } from './GatewayRulesFormMappingItem';
import { GatewayRuleMappingMethod } from '../../../GatewayRuleMapping/GatewayRuleMappingMethod';
import { useMappingItemField } from '../../hooks';
import { getInitialMapping } from '../../utils';

type Props = {
  mappingIndex: number;
  onRemoveMappingClick: (index: number) => void;
  onChangeMethod: (index: number) => void;
  onChangeDirection: (index: number) => void;
};

export function GatewayRulesFormMapping({
  mappingIndex,
  onChangeMethod,
  onChangeDirection,
  onRemoveMappingClick,
}: Props): JSX.Element {
  const { getValues, formState, watch } = useFormContext();
  const direction = watch(`mappings.${mappingIndex}.direction`);

  const { mappingItemFields, onRemoveMappingItemClick, onAddMappingItemClick } =
    useMappingItemField({
      mappingIndex,
      name: `mappings.${mappingIndex}.mappingItems`,
    });

  const { data: method, isLoading: isLoadingMethod } = useGetOne<Method>(
    'methods',
    {
      id: getValues(`mappings.${mappingIndex}.method`),
    },
    { enabled: !!getValues(`mappings.${mappingIndex}.method`) },
  );

  const isDisabledRemoveMapping = useMemo<boolean>(() => {
    const currentValues = getValues();
    return (
      currentValues.mappings.length === 1 &&
      jsonEqual(currentValues.mappings[0], getInitialMapping())
    );
  }, [formState]);

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingBottom: '16px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'flex-start', paddingBottom: '0' }}>
        <ReferenceInput
          filter={{
            groups: ['reference'],
          }}
          perPage={1000}
          reference="methods"
          source={`mappings.${mappingIndex}.method`}
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Метод"
            name={`mappings.${mappingIndex}.method`}
            onChange={() => onChangeMethod(mappingIndex)}
            optionText="name"
            size="medium"
            sx={fieldWidth.medium}
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput reference="directions" source="direction">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Направление"
            name={`mappings.${mappingIndex}.direction`}
            onChange={() => onChangeDirection(mappingIndex)}
            optionText="description"
            size="medium"
            source="value"
            sx={fieldWidth.medium}
            variant="outlined"
          />
        </ReferenceInput>
        <GatewayRuleMappingMethod
          isForm
          isLoadingMethod={isLoadingMethod}
          methodLogoId={method?.logo}
        />
        <BooleanInput
          label="Активен"
          name={`mappings.${mappingIndex}.active`}
          source="active"
          sx={{ marginTop: 1 }}
        />
        <Box sx={{ marginLeft: 'auto', marginTop: 1 }}>
          <Button
            color={'error' as ButtonProps['color']}
            disabled={isDisabledRemoveMapping}
            label="Удалить маппинг"
            onClick={() => onRemoveMappingClick(mappingIndex)}
            variant="outlined"
          />
        </Box>
      </Box>
      {mappingItemFields.map((mappingItem, mappingItemIndex) => (
        <GatewayRulesFormMappingItem
          direction={direction}
          key={mappingItem.id}
          mappingIndex={mappingIndex}
          mappingItemIndex={mappingItemIndex}
          onRemoveMappingItemClick={onRemoveMappingItemClick}
        />
      ))}
      <Box sx={{ marginTop: '10px' }}>
        <Button
          label="Добавить шлюз"
          onClick={onAddMappingItemClick}
          startIcon={<AddIcon />}
          variant="outlined"
        />
      </Box>
    </Box>
  );
}
