import { useEffect } from 'react';

import { CAPTCHA_PUBLIC_KEY } from 'config/entrypoint';

const removeGrecaptchaScript = <T extends HTMLElement>(script: T | null = null) => {
  if (null !== script && null !== script.parentNode) {
    script.parentNode.removeChild(script);
  }
};

export function useCaptcha() {
  useEffect(() => {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_PUBLIC_KEY}`;
    script.id = '_grecaptcha.element.id';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return function cleanup() {
      const grecaptchaScript: HTMLElement | null =
        document.getElementById('_grecaptcha.element.id');
      removeGrecaptchaScript(grecaptchaScript);
    };
  });
}
