export const enum ExportFormats {
  Excel = 'xlsx',
  CSV = 'csv',
}

export const EXPORT_FORMAT_NAMES = {
  [ExportFormats.Excel]: 'Excel',
  [ExportFormats.CSV]: 'CSV',
} as const;

export type ExportFormatNames = (typeof EXPORT_FORMAT_NAMES)[keyof typeof EXPORT_FORMAT_NAMES];

export const enum ReportNames {
  Cumulative = 'cumulative_report',
  Orders_report = 'orders_report',
  Balance = 'balance_report',
  Orders = 'orders',
}

export const enum ExportJobTypes {
  Cumulative = 'export_cumulative',
  Success_transaction = 'export_success_transactions',
  Orders = 'export_orders',
  Balance = 'export_balance',
}

export const enum ExportTransportTypes {
  Email = 'email',
  Admin = 'admin_panel',
}

export const EXPORT_REPORT_NAMES = {
  [ExportJobTypes.Cumulative]: 'Отчет по нарастающему итогу',
  [ExportJobTypes.Orders]: 'Отчет по заказам',
  [ExportJobTypes.Success_transaction]: 'Отчет по исполненным транзакциям',
  [ExportJobTypes.Balance]: 'Отчет по балансам',
};
