import { ConfigurableDatagridColumn } from 'react-admin';

export const sortColumnsByIndex = (columns: ConfigurableDatagridColumn[], orderArr: string[]) => {
  const orderMap = columns.reduce<Record<string, ConfigurableDatagridColumn>>((result, data) => {
    result[data.index] = data;

    return result;
  }, {});

  return orderArr.map((index) => orderMap[index]);
};
