import * as React from 'react';

import { useSitesFields } from './hooks/useSitesFields';
import { SitesAvailabilityForm } from '../SitesAvailabilityForm/SitesAvailabilityForm';

export const SettingsMerchantAvailabilityDialogForm: React.FC = () => {
  const { fields } = useSitesFields();

  return <SitesAvailabilityForm fields={fields} />;
};
