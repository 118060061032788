import { AxiosError, AxiosResponse } from 'axios';
import { Identifier, useNotify } from 'react-admin';
import { useMutation, UseMutationResult } from 'react-query';

import { queryClient } from '../../../../../App';
import {
  changeMappingActivity,
  ChangeMappingActivityResponse,
} from '../../../../../config/requests';

type UseChangeMappingActivityReturnValue = {
  changeMappingActivityMutation: UseMutationResult<
    AxiosResponse<ChangeMappingActivityResponse, any>,
    unknown,
    { id: Identifier; active: boolean },
    unknown
  >;
};

export function useChangeMappingActivity(): UseChangeMappingActivityReturnValue {
  const notify = useNotify();

  const mutation = useMutation(
    ({ id, active }: { id: Identifier; active: boolean }) => changeMappingActivity(id, active),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('mappings');
        notify('Активность успешно изменена', { type: 'success' });
      },
      onError: (e) => {
        const error = e as AxiosError;
        notify(`Ошибка: ${error}`, { type: 'error' });
      },
    },
  );

  return { changeMappingActivityMutation: mutation };
}
