import { SxProps } from '@mui/material';
import { TableCell, TableCellHeader, TableRow } from 'shared/mui/Table';

export type TableRowWrapperProps = {
  title: string;
  children: React.ReactNode;
  headerStyle?: SxProps;
  contentStyle?: SxProps;
};

export const TableRowWrapper = ({
  title,
  children,
  headerStyle,
  contentStyle,
}: TableRowWrapperProps): JSX.Element => (
  <TableRow>
    <TableCellHeader sx={{ ...headerStyle, verticalAlign: 'top' }} title={title} />
    <TableCell
      component="th"
      scope="row"
      sx={{ textWrap: 'wrap', wordBreak: 'break-all', ...contentStyle }}
    >
      {children}
    </TableCell>
  </TableRow>
);
