import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowGuesserDialog } from 'shared/react-admin/Dialogs/ShowGuesserDialog/ShowGuesserDialog';
import { MethodLogosShowDialogTable } from 'shared/widgets/MethodLogosShowDialog/MethodLogosShowDialogTable';
import { MethodLogo, Permission } from 'types';

import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { MethodLogosDeleteDialog } from '../components/MethodLogosDeleteDialog';

export const MethodLogosShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<MethodLogo>();

  return (
    <>
      <ShowGuesserDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="method_logo" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModal}
        sx={{ width: '444px' }}
        title="Просмотр логотипа"
      >
        <MethodLogosShowDialogTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('method_logos', [Permission.Full, Permission.Update]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('method_logos', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton
                label="Удалить"
                onClick={() => handleOpenRecordModal()}
                variant="outlined"
              />
            ) : undefined
          }
        />
      </ShowGuesserDialog>
      {openRecordModal && (
        <MethodLogosDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </>
  );
};
