import { NotificationStatuses } from 'constants/notifications';

import CircleIcon from '@mui/icons-material/Circle';
import DownloadIcon from '@mui/icons-material/Download';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ReportIcon from '@mui/icons-material/Report';
import { useNotify } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { ListItemAvatar, ListItemText } from 'shared/mui/List';
import { appColors } from 'themes/variables';
import { Notification } from 'types';

import {
  NotificationPopperListItemFailureIconButton,
  NotificationPopperListItemSuccessIconButton,
  StyledNotificationPopperListItem,
} from './NotificationPopperListItem.styled';
import { NotificationPopperListItemPrimaryText } from './NotificationPopperListItemPrimaryText';
import { NotificationPopperListItemSecondaryText } from './NotificationPopperListItemSecondaryText';
import { useChangeViewedStatus } from '../../hooks/useChangeViewedStatus';

type NotificationPopperListItemProps = {
  notification: Notification;
  onSuccess: () => void;
};

export const NotificationPopperListItem: React.FC<NotificationPopperListItemProps> = ({
  notification,
  onSuccess,
}) => {
  const { body: notificationBody } = notification;
  const { status, content } = notificationBody;
  const isSuccess = status === NotificationStatuses.Done;
  const isFailure = status === NotificationStatuses.Failure;

  const notify = useNotify();

  const { changeViewedStatus } = useChangeViewedStatus({
    ids: [notification.id],
    onSuccess,
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <StyledNotificationPopperListItem>
      <ListItemAvatar>
        <>
          {isSuccess && (
            <NotificationPopperListItemSuccessIconButton onClick={changeViewedStatus}>
              <DownloadIcon htmlColor={appColors.background} />
            </NotificationPopperListItemSuccessIconButton>
          )}
          {isFailure && (
            <NotificationPopperListItemFailureIconButton>
              <ReportIcon color="error" />
            </NotificationPopperListItemFailureIconButton>
          )}
        </>
      </ListItemAvatar>
      <ListItemText
        primary={
          <NotificationPopperListItemPrimaryText
            exportType={content.exportType}
            periodFrom={content.reportPeriod.from}
            periodTo={content.reportPeriod.to}
          />
        }
        primaryTypographyProps={{ component: 'div', sx: { marginBottom: '8px' } }}
        secondary={
          <NotificationPopperListItemSecondaryText
            createdAt={content.createdAt}
            fileExtension={content.fileExtension}
            isFailure={isFailure}
            isSuccess={isSuccess}
            status={status}
          />
        }
        secondaryTypographyProps={{ component: 'div' }}
        sx={{ margin: 0 }}
      />
      <Box sx={{ marginTop: '2px' }}>
        {notification.isViewed ? (
          <RadioButtonUncheckedIcon htmlColor={appColors.primary.light} sx={{ fontSize: '8px' }} />
        ) : (
          <CircleIcon color="primary" sx={{ fontSize: '8px' }} />
        )}
      </Box>
    </StyledNotificationPopperListItem>
  );
};
