import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { StyledBox } from './BaseDialog.styled';

export type BaseDialogProps = {
  title: React.ReactNode;
  message: JSX.Element | string;
  onClose: () => void;
  leftActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  rightActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
} & Omit<DialogProps, 'title'>;

export const BaseDialog = ({
  leftActionsSlot,
  rightActionsSlot,
  open,
  onClose,
  message,
  title,
}: BaseDialogProps) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title={title}
        />
        <Typography sx={{ padding: '16px 24px' }} variant="body1">
          {message}
        </Typography>
        <CardActionsButtons leftActionsSlot={leftActionsSlot} rightActionsSlot={rightActionsSlot} />
      </StyledBox>
    </Dialog>
  );
};
