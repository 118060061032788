import { useQuery } from 'react-query';

import { getProviderReportsList } from '../../../config/requests';
import { ProviderReportResponse } from '../../../types';
import { ProviderReportsListFilterType } from '../ProviderReportsListFilter';

type Result = {
  providerReportsList: ProviderReportResponse | undefined;
  isLoading: boolean;
  refetch: () => void;
};

export function useGetProviderReports(formattedFilters: ProviderReportsListFilterType): Result {
  const { gatewayAlias, providerAlias } = formattedFilters;
  const {
    data: providerReportsList,
    isLoading,
    refetch,
  } = useQuery<ProviderReportResponse>(
    ['providerReports', gatewayAlias, providerAlias],
    () => getProviderReportsList(formattedFilters),
    { enabled: !!formattedFilters },
  );

  return { providerReportsList, isLoading, refetch };
}
