import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import {
  BooleanField as RaBooleanField,
  BooleanFieldProps as RaBooleanFieldProps,
} from 'react-admin';
import { SvgIcon, SvgIconProps } from 'shared/mui/SvgIcon/SvgIcon';
import { appColors } from 'themes/variables';

export type BooleanFieldProps = RaBooleanFieldProps;

const NoIcon: typeof SvgIcon = (props: SvgIconProps) => (
  <DoNotDisturbAltIcon htmlColor={appColors.error.light} {...props} />
);
NoIcon.muiName = 'NoIcon';

export const YesIcon: typeof SvgIcon = (props: SvgIconProps) => (
  <CheckIcon htmlColor={appColors.success.light} {...props} />
);
YesIcon.muiName = 'YesIcon';

export const BooleanField = (props: BooleanFieldProps): JSX.Element => (
  <RaBooleanField {...props} FalseIcon={NoIcon} TrueIcon={YesIcon} />
);
