import React from 'react';

import { EditGuesser, EditGuesserProps } from '@api-platform/admin';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { appColors } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../PanelHeader';

type Props = {
  title: string;
  listName: string;
  children: React.ReactNode;
} & EditGuesserProps;

const StyledEditGuesser = styled(EditGuesser)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  marginTop: '24px',
  backgroundColor: appColors.background,
});

export function AppEditGuesser({ title, listName, children, ...props }: Props): JSX.Element {
  return (
    <Box>
      <AppBreadCrumbs currentPage={title} listName={listName} />
      <StyledEditGuesser {...props}>
        <AppPanelHeader pb={1}>
          <AppPanelHeaderToolbar titleText={title} />
        </AppPanelHeader>
        {children}
      </StyledEditGuesser>
    </Box>
  );
}
