import * as React from 'react';

import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { GatewayConversionSetting } from 'types/gatewayConversionSetting';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  record: GatewayConversionSetting | undefined;
};

export const OrderConversionListGatewaySwitchDisable: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  onClose,
  record,
}) => {
  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        title="Выключить уведомления для шлюза"
      />
      <CardContent>
        Отправка уведомлений по шлюзу {record?.id} {record?.gateway.name} для
        {record?.merchant.name} будет отключена после подтверждения.
      </CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <Button
            key="close-button"
            label="Отменить"
            onClick={() => {
              onCancel && onCancel();
              onClose();
            }}
            variant="outlined"
          />,
          <Button
            color="error"
            key="save-button"
            label="Выключить"
            onClick={() => {
              onConfirm && onConfirm();
              onClose();
            }}
            variant="contained"
          />,
        ]}
      />
    </Dialog>
  );
};
