import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AutocompleteProps } from '@mui/material';
import { debounce } from 'lodash';
import { Validator, useChoicesContext, useInput } from 'react-admin';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Box } from 'shared/mui/Box';
import { TextField } from 'shared/mui/TextField/TextField';
import { MethodLogo } from 'types';

export type AutocompleteMultiselectInputProps<
  OptionType = MethodLogo,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<OptionType, true, DisableClearable, SupportCreate>,
  'renderInput' | 'options' | 'renderOption' | 'defaultValue'
> & {
  defaultValue?: MethodLogo;
  name: string;
  label: string;
  optionValue?: string;
  validate?: Validator | Validator[];
  error?: boolean;
  disabled?: boolean;
  newLogo?: string | null;
  onChangeLogo: Dispatch<SetStateAction<string | null | undefined>>;
};

const defaultSx = {
  '& .MuiInputBase-input': {
    minWidth: '0!important',
  },
};

export const MethodsLogoAutocompleteInput: React.FC<AutocompleteMultiselectInputProps> = ({
  name,
  optionValue = 'id',
  label,
  defaultValue,
  error,
  disabled,
  sx,
  onChangeLogo,
  ...restProps
}) => {
  const { field } = useInput({
    name,
    source: name,
    ...restProps,
    defaultValue,
  });
  const { allChoices, setFilters, displayedFilters } = useChoicesContext();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue);
      setInputValue(defaultValue?.name || '');
    }
  }, [defaultValue]);

  const handleInputChange = (event: any, newInputValue: string) => {
    if (!event) {
      return;
    }
    setInputValue(newInputValue);
    const debouncedSetFilters = debounce(() => {
      setFilters({ name: newInputValue }, displayedFilters);
    }, 500);
    debouncedSetFilters();
  };

  const handleBlur = () => {
    setFilters({}, displayedFilters);
  };

  return (
    <Autocomplete
      {...field}
      {...restProps}
      // @ts-ignore
      defaultValue={defaultValue}
      disabled={disabled}
      getOptionLabel={(option) => {
        return option?.name ?? '';
      }}
      id={name}
      inputValue={inputValue}
      // @ts-ignore
      isOptionEqualToValue={(option, value) => {
        return option[optionValue] === value || option[optionValue] === value[optionValue];
      }}
      onBlur={handleBlur}
      onChange={(e, data) => {
        // @ts-ignore
        onChangeLogo(data?.filePath);
        return field.onChange(data);
      }}
      onInputChange={handleInputChange}
      options={allChoices || []}
      renderInput={(params) => (
        <TextField {...params} error={error} label={label} size="medium" variant="outlined" />
      )}
      renderOption={(props, { name, filePath }) => (
        <Box component="li" display="flex" {...props}>
          <img
            alt={name}
            src={filePath}
            style={{ width: '80px', aspectRatio: '1', objectFit: 'contain', marginRight: 8 }}
          />
          <Box component="p">{name}</Box>
        </Box>
      )}
      sx={{ ...defaultSx, ...sx }}
      value={field.value}
    />
  );
};
