import React from 'react';

import { OptionalRecordContextProvider, RaRecord, useRecordContext } from 'react-admin';

type ShowGuesserDialogLayoutProps = {
  record?: RaRecord;
  children: React.ReactNode;
};

export const ShowGuesserDialogLayout: React.FC<ShowGuesserDialogLayoutProps> = (props) => {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  return (
    <OptionalRecordContextProvider value={props.record}>
      <>{props.children}</>
    </OptionalRecordContextProvider>
  );
};
