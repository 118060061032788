import { queryClient } from 'App';
import { useNotify, useResourceContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { EditGuesser, EditGuesserProps } from 'shared/react-admin/Guessers';

import { EditDialogStyled } from './EditGuesserDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';

export type EditGuesserDialogProps = {
  actions?: React.ReactElement[] | null;
  open?: boolean;
  children: React.ReactNode;
  title: string;
} & Omit<EditGuesserProps, 'actions' | 'toolbar'>;

export const EditGuesserDialog: React.FC<EditGuesserDialogProps> = ({
  actions,
  open = true,
  title,
  children,
  mutationOptions,
  ...restProps
}) => {
  const { goBack } = useGoBack();
  const notify = useNotify();
  const resource = useResourceContext();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([resource]);
    notify('ra.notification.updated', {
      type: 'info',
      messageArgs: { smart_count: 1 },
    });
    goBack();
  };

  return (
    <EditDialogStyled open={open}>
      <EditGuesser
        actions={false}
        mutationOptions={{ onSuccess, ...mutationOptions }}
        toolbar={false}
        {...restProps}
      >
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </EditGuesser>
    </EditDialogStyled>
  );
};
