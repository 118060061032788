import { styled } from '@mui/material/styles';
import { Button } from 'shared/mui/Buttons';

export const StyledButton = styled(Button, {
  name: 'RaSelectColumnsButton',
  overridesResolver: (props, styles) => styles.root,
})({
  '&.MuiButton-sizeSmall': {
    lineHeight: 1.5,
  },
});
