import ArchiveIcon from '@mui/icons-material/Archive';

import { IconButton, IconButtonProps } from '..';

export type ArchiveIconButtonProps = Omit<IconButtonProps, 'icon'> & {
  isRotate?: boolean;
};

export const ArchiveIconButton: React.FC<ArchiveIconButtonProps> = ({ isRotate, ...props }) => (
  <IconButton {...props}>
    <ArchiveIcon
      fontSize="inherit"
      sx={{ marginTop: '1px', transform: isRotate ? 'rotate(180deg)' : 'none' }}
    />
  </IconButton>
);
