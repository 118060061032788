import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { queryClient } from 'App';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { SaveButton } from 'shared/react-admin/Buttons';
import { GatewayConversionListItem } from 'types/orderConversion';
import * as yup from 'yup';

import { OrderConversionRequestParams } from '../../../../../../config/requests';
import { usePermissions } from '../../../../../../hooks/usePermissions';
import { Permission } from '../../../../../../types';
import { useGatewayConversionSettings } from '../../hooks/useGatewayConversionSettings';
import { useUpdateGatewayConversionSettings } from '../../hooks/useUpdateGatewayConversionSettings';
import { GatewaySettingsForm } from '../components/GatewaySettingsForm/GatewaySettingsForm';
import { GatewaySettingsFormValues } from '../OrderConversionListGatewayCreate/OrderConversionListGatewayCreate';
import { deviationFieldSchema } from '../schemas/shema';

type Props = {
  record: GatewayConversionListItem | undefined;
  open: boolean;
  onClose: VoidFunction;
  onDelete: (record: GatewayConversionListItem | undefined) => void;
  params: OrderConversionRequestParams;
  onOpenReferenceDialog: VoidFunction;
};

export const OrderConversionListGatewayEdit: React.FC<Props> = ({
  open,
  record,
  onClose,
  onDelete,
  params,
  onOpenReferenceDialog,
}) => {
  const { hasResourcePermissions } = usePermissions();

  const { data } = useGatewayConversionSettings(record?.gatewayConversionSettingId || null);

  const form = useForm<GatewaySettingsFormValues>({
    resolver: yupResolver<any>(
      yup.object().shape({
        successDeviation: deviationFieldSchema,
        cancelDeviation: deviationFieldSchema,
        pendingDeviation: deviationFieldSchema,
      }),
    ),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (data) {
      form.reset({
        notify: data?.notify,
        pendingDeviation: data?.pendingDeviation,
        successDeviation: data?.successDeviation,
        cancelDeviation: data?.cancelDeviation,
      });
    }
  }, [data, form.reset]);

  const { updateGatewayConversionSettings } = useUpdateGatewayConversionSettings();

  async function onSubmit(values: GatewaySettingsFormValues) {
    if (!data?.id) {
      return;
    }

    await updateGatewayConversionSettings.mutateAsync({
      id: data?.id,
      data: {
        successDeviation: values.successDeviation.toString(),
        cancelDeviation: values.cancelDeviation.toString(),
        pendingDeviation: values.pendingDeviation.toString(),
        notify: values.notify,
      },
    });

    onClose();
    await queryClient.refetchQueries(['gateway_conversion_settings', data?.id]);
    await queryClient.refetchQueries([
      'monitoring/order_conversion',
      params.period,
      params.merchantId,
      params?.detailed,
      params?.providerIds,
      params?.gatewayIds,
    ]);
  }

  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title="Редактирование настроек по пороговым значениям мониторинга"
      />

      <Box sx={{ padding: '8px 24px' }}>
        <Alert severity="warning">
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '8px',
            }}
          >
            <Typography variant="body2">
              В соответствии с установленными пороговыми значениями, будет определяться
              максимально-допустимое отклонение от среднестатистических показателей рассчитанных за
              14 дней по конкретному шлюзу.
            </Typography>
            <Button
              label="справка по настройке"
              onClick={onOpenReferenceDialog}
              sx={{ marginLeft: '-8px', color: 'rgb(102, 60, 0)' }}
            />
          </Box>
        </Alert>
      </Box>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <GatewaySettingsForm />
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              hasResourcePermissions('gateway_conversion_settings', [
                Permission.Full,
                Permission.Update,
              ]) && (
                <SaveButton key="save-button" label="Сохранить" type="submit" variant="contained" />
              ),
              <Button key="close-button" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={[
              hasResourcePermissions('gateway_conversion_settings', [
                Permission.Full,
                Permission.Delete,
              ]) && (
                <Button
                  color="error"
                  key="delete-button"
                  label="Удалить"
                  onClick={() => {
                    onDelete(record);
                  }}
                  variant="outlined"
                />
              ),
            ]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
