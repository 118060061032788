import { styled } from '@mui/material/styles';
import { ChipField } from 'react-admin';
import { appColors } from 'themes/variables';

import { AdjustmentStatuses } from '../../../../constants/adjustmentStatuses';

export const ChipFieldStyled = styled(ChipField)<{ status: AdjustmentStatuses }>(({ status }) => ({
  backgroundColor: appColors.adjustmentStatus[status],
  color: appColors.background,
  margin: '0 !important',
  height: '24px',
}));
