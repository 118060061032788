import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Table } from 'shared/mui/Table';

export const StyledBox = styled(Box)({
  display: 'flex',
  top: '50%',
  flexDirection: 'column',
  padding: 0,
  width: '600px',
});

export const StyledTable = styled(Table)({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});
