import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { merchantPayoutControlsUpdateMapping } from 'config/requests';
import { useNotify, useRecordContext } from 'react-admin';
import { useMutation } from 'react-query';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { useCurrentMappingDataContext } from '../../context/CurrentMappingContext';
import { useEditPayoutControlContext } from '../../context/EditPayoutControlContext';

type Props = {
  onSubmit: () => void;
} & DialogProps;

export const EditPayoutControlMappingAddConfirmDialog: React.FC<Props> = ({
  onClose,
  onSubmit,
  ...props
}) => {
  const record = useRecordContext();
  const notify = useNotify();
  const { mappingData, setMappingData } = useCurrentMappingDataContext();
  const {
    payoutControl: { id: payoutControlId },
  } = useEditPayoutControlContext();

  const { mutate, isLoading } = useMutation('mappings', merchantPayoutControlsUpdateMapping, {
    onSuccess: () => {
      queryClient.refetchQueries(['merchant_payout_controls', record?.originId]);
      onSubmit();
      notify('Маппинг успешно добавлен в исключение', { type: 'success' });
      setMappingData({});
    },
    onError: (error: AxiosError<{ detail: string }>) => {
      notify(error?.response?.data?.detail || 'Что-то пошло не так. Повторите запрос позже.', {
        type: 'error',
      });
    },
  });

  const onConfirm = () => {
    mutate({ payoutControlId, mappingId: mappingData?.mapping?.id as string });
  };

  return (
    <Dialog {...props} fullWidth>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Добавление маппинга в исключение"
        />
        <CardContent>
          <Typography>
            Маппинг для метода {mappingData?.method?.name} будет добавлен в исключение.
          </Typography>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              disabled={isLoading}
              key="apply-button"
              label="Подтвердить"
              onClick={onConfirm}
              variant="contained"
            />,
            <CancelButton
              disabled={isLoading}
              key="cancel-button"
              onClick={onClose}
              variant="outlined"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
