import { useRecordContext } from 'react-admin';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { TextField } from 'shared/mui/TextField/TextField';
import { TextInputIntrospected } from 'shared/react-admin/Inputs';

export const GatewayDeactivationReasonsEditForm = (): JSX.Element => {
  const record = useRecordContext();

  return (
    <CardContent sx={{ width: 'calc(100% - 48px)' }}>
      <TextField
        defaultValue={record?.originId}
        disabled
        fullWidth
        helperText={false}
        label="ID"
        size="medium"
        sx={{ marginBottom: '16px' }}
        variant="outlined"
      />
      <TextField
        defaultValue={record?.gatewayCount}
        disabled
        fullWidth
        helperText={false}
        label="Кол-во шлюзов"
        size="medium"
        sx={{ marginBottom: '16px' }}
        variant="outlined"
      />
      <TextInputIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        helperText={false}
        label="Причина"
        multiline
        size="medium"
        source="reason"
        sx={{ marginTop: '8px !important' }}
        variant="outlined"
      />
    </CardContent>
  );
};
