import { SxProps } from '@mui/material';

import { AlertStyled, TypographyStyled } from './EditPayoutControlManageAlert.styled';

type EditPayoutControlManageProps = {
  onClose: () => void;
  sx?: SxProps;
};

export const EditPayoutControlManageAlert: React.FC<EditPayoutControlManageProps> = (props) => {
  return (
    <AlertStyled severity="warning" variant="filled" {...props}>
      <TypographyStyled variant="body2">
        Контроль выводов необходим для формирования списка методов на вывод денежных средств.
        <br />
        Если контроль выводов активен, то клиенту будут доступны методы:
        <ol>
          <li>Которые клиент использовал для пополнения, </li>
          <li>Добавлен вручную в маппинг исключение.</li>
        </ol>
        Перед настройкой и включением контроля выводов необходимо ознакомиться с{' '}
        <u>“Руководством по созданию и настройке правил”</u>.
      </TypographyStyled>
    </AlertStyled>
  );
};
