import { EMPTY_FIELD } from 'constants/emptyField';

import { blue } from '@mui/material/colors';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Chip, GroupFieldChip } from 'shared/mui/Chips';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { BooleanField, FunctionField, SingleFieldList, TextField } from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MerchantShowAccountsShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { Company, MerchantAccount, Provider, User, WorkTeam } from 'types';

import { StyledChipField, StyledChipFieldLinked } from './ProviderAccountsShow.styled';
import { ProviderAccountsManagerField } from '../components/ProviderAccountsManagerField';
import { ProviderAccountsName } from '../components/ProviderAccountsName';
import { StyledChip } from '../ProviderAccountsList/ProviderAccountsList.styled';

type ProviderAccountsShowTableProps = {
  handleOpenCompanyModal: (record: Company | undefined) => void;
  handleOpenRecordModalProvider: (record: Provider | undefined) => void;
};

export const ProviderAccountsShowTable = ({
  handleOpenCompanyModal,
  handleOpenRecordModalProvider,
}: ProviderAccountsShowTableProps): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  return (
    <CardTable sx={{ tableLayout: 'fixed' }}>
      <TableBody>
        <TableRowWrapper title="Партнерский">
          <BooleanField source="partner" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <ProviderAccountsName />
        </TableRowWrapper>
        <TableRowWrapper title="Компания">
          <ReferenceOneField
            emptyText="—"
            label="Компания"
            link={false}
            reference="companies"
            source="company"
            target="id"
          >
            <FunctionField
              render={(record: Company) => (
                <StyledChipField
                  color="primary"
                  onClick={() => handleOpenCompanyModal(record)}
                  source="name"
                />
              )}
            />
          </ReferenceOneField>
        </TableRowWrapper>
        {hasResourceAccess('providers') && (
          <TableRowWrapper title="Провайдер">
            <ReferenceOneField
              label="Провайдер"
              reference="providers"
              sortBy="provider.name"
              source="provider"
              target="id"
            >
              <FunctionField
                render={(record: Provider | undefined) => (
                  <TextField
                    onClick={() => handleOpenRecordModalProvider(record)}
                    source="name"
                    sx={{
                      cursor: 'pointer',
                      color: blue[700],
                      wordWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                    }}
                  />
                )}
              />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        <TableRowWrapper title="Мерчант">
          <ReferenceArrayField label="Мерчант" reference="merchants" source="merchants">
            <SingleFieldList linkType="show">
              <FunctionField
                render={(record: RaRecord) => (
                  <StyledChipFieldLinked
                    color="default"
                    record={record}
                    size="small"
                    source="name"
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </TableRowWrapper>
        <TableRowWrapper title="Счета мерчанта">
          <ReferenceArrayField
            emptyText="-"
            reference="merchant_accounts"
            source="merchantAccounts"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                render={(record: MerchantAccount) => (
                  <StyledChip
                    label={record.name}
                    onClick={() => handleOpenMerchantAccountModal(record)}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </TableRowWrapper>
        <TableRowWrapper title="Рабочая группа">
          <ReferenceOneField
            emptyText="—"
            label="Рабочая группа"
            link={false}
            reference="work_teams"
            source="workTeam"
            target="id"
          >
            <FunctionField
              render={(record: WorkTeam) =>
                record ? <GroupFieldChip group={record} /> : EMPTY_FIELD
              }
            />
          </ReferenceOneField>
        </TableRowWrapper>
        <TableRowWrapper title="Руководитель группы">
          <ReferenceOneField
            emptyText="—"
            label="Руководитель группы"
            link={false}
            reference="work_teams"
            source="workTeam"
            target="id"
          >
            <ProviderAccountsManagerField />
          </ReferenceOneField>
        </TableRowWrapper>
        <TableRowWrapper title="Ответственные">
          <ReferenceArrayField reference="users" sortable={false} source="members">
            <SingleFieldList>
              <FunctionField
                render={(record: User) => (
                  <Chip
                    label={`${record.firstName} ${record.lastName}`}
                    sx={{ marginRight: '4px', marginBottom: '4px' }}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </TableRowWrapper>
      </TableBody>
      {merchantAccount && (
        <MerchantShowAccountsShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </CardTable>
  );
};
