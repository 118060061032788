import { usePermissions } from 'hooks/usePermissions';
import { RaRecord, useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';

import { MethodsDeleteDialog } from './components/MethodsDeleteDialog/MethodsDeleteDialog';
import { MethodsForm } from './components/MethodsForm';
import { useRecordModal } from '../../hooks/useRecordModal';
import { Method, Permission } from '../../types';

const transform = (data: RaRecord) => {
  return {
    ...data,
    logo: data.logo || null,
  };
};

export const MethodsEdit: React.FC = () => {
  const { record } = useEditController();
  const { hasResourcePermissions } = usePermissions();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Method>();

  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Методы"
      mutationOptions={{ onError: () => undefined }}
      rightActionsSlot={
        hasResourcePermissions('methods', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal()} variant="outlined" />
        ) : undefined
      }
      sx={{ marginTop: 0, padding: '0!important', width: '600px' }}
      title="Редактирование метода"
      transform={transform}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <MethodsForm />
      </CardContent>
      {openRecordModal && (
        <MethodsDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </EditGuesserPage>
  );
};
