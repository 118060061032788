import { useState } from 'react';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Button } from 'shared/mui/Buttons';
import { Tooltip } from 'shared/mui/Tooltip';
import { appColors } from 'themes/variables';

import { NotificationKeyTooltipCard } from './components/NotificationKeyTooltipCard';

type NotificationKeyTooltipProps = {
  value?: string;
  icon?: React.ReactNode;
};

export const NotificationKeyTooltip: React.FC<NotificationKeyTooltipProps> = ({ value, icon }) => {
  const [isShowNotificationKey, setIsShowNotificationKey] = useState(false);
  const handleTooltipOpen = () => {
    setIsShowNotificationKey(true);
  };
  const handleTooltipClose = () => {
    setIsShowNotificationKey(false);
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: appColors.background,
            minWidth: '444px',
            boxShadow: '0 0 14px 4px rgba(0, 0, 0, 0.2)',
            padding: 0,
          },
        },
        popper: {
          sx: {
            width: '444px',
          },
        },
      }}
      disableHoverListener
      open={isShowNotificationKey}
      placement="bottom-start"
      title={<NotificationKeyTooltipCard onClose={handleTooltipClose} value={value} />}
    >
      <Button
        label="ПОКАЗАТЬ"
        onClick={handleTooltipOpen}
        startIcon={icon || <RemoveRedEyeIcon />}
      />
    </Tooltip>
  );
};
