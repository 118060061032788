import { createContext, useContext } from 'react';

import { MerchantPayoutControl } from 'types';

type EditPayoutControlContextData = {
  payoutControl: MerchantPayoutControl;
};

const EditPayoutControlContext = createContext<EditPayoutControlContextData | null>(null);
export const EditPayoutControlProvider = EditPayoutControlContext.Provider;

export function useEditPayoutControlContext(): EditPayoutControlContextData {
  const context = useContext(EditPayoutControlContext);

  if (!context) {
    throw new Error(
      'Can not `useEditPayoutControlContext` outside of the `EditPayoutControlProvider`',
    );
  }

  return context;
}
