import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(200);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-active': columnStyle,
  '& .column-logo': columnStyle,
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
