import { useMemo } from 'react';

import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';

type UseGetShowLinkByPermissionResult = {
  merchants: 'show' | false;
  gateways: 'show' | false;
};

export function useShowLinkByPermission(): UseGetShowLinkByPermissionResult {
  const { hasResourcePermissions } = usePermissions();

  return useMemo(
    () => ({
      merchants: hasResourcePermissions('merchants', [Permission.Read, Permission.Full])
        ? 'show'
        : false,
      gateways: hasResourcePermissions('gateways', [Permission.Read, Permission.Full])
        ? 'show'
        : false,
    }),
    [hasResourcePermissions],
  );
}
