import { Labeled, useGetOne } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { JsonFieldStyled } from './MerchantShowAccountsMerchantCredentialsField.styled';

export const MerchantShowAccountsMerchantCredentialsField = (): JSX.Element => {
  const merchantCredentialsId = useWatch({ name: 'merchantCredentials' });
  const { data } = useGetOne(
    'merchant_credentials',
    {
      id: merchantCredentialsId,
    },
    { enabled: !!merchantCredentialsId },
  );

  return (
    <Labeled label="Реквизиты доступа к мерчанту {json}">
      <JsonFieldStyled label="Реквизиты доступа к мерчанту" src={data ? data?.credentials : {}} />
    </Labeled>
  );
};
