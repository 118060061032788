import { Box } from 'shared/mui/Box';

import { StyledBox } from '../../RolesCreate/RolesCreate.styled';

type RolesFormDirectionProps = {
  description?: string;
  value?: string;
};

export const RolesFormDirection: React.FC<RolesFormDirectionProps> = ({ description, value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {description && (
        <img alt="icon" src={description} style={{ height: '22px', width: '22px' }} />
      )}
      {value && <StyledBox>{value}</StyledBox>}
    </Box>
  );
};
