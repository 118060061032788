import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps } from '../../../../../../Buttons';

export type FilterResetButtonButtonProps = ButtonProps & {
  onReset?: () => void;
};

export const PopoverChipFilterResetButton: React.FC<FilterResetButtonButtonProps> = ({
  onReset,
  ...restProps
}) => {
  const { reset } = useFormContext();

  function onClick() {
    reset();
    if (onReset) {
      onReset();
    }
  }

  return <Button onClick={onClick} sx={{ width: '100%' }} {...restProps} />;
};
