import { useNotify } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { ApiClient } from 'types';

import { ApiClientsCreateForm } from './ApiClientsCreateForm';
import { CardContent } from '../../../shared/mui/Card/CardContent/CardContent';

const transform = (data: ApiClient): ApiClient => {
  return {
    ...data,
    merchant: data.merchant || null,
  };
};

export const ApiClientsCreate = () => {
  const notify = useNotify();

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Клиенты Api"
      mutationOptions={{
        onError: (error) => {
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, {
            type: 'error',
            autoHideDuration: 3000,
          });
        },
      }}
      title="Создание клиента Api"
      transform={transform}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <ApiClientsCreateForm />
      </CardContent>
    </CreateGuesserPage>
  );
};
