import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { useGetOne } from 'react-admin';
import { FieldValues } from 'react-hook-form';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { getResourceFromId } from '../../../../../utils';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsIdField<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  const { data } = useGetOne(
    chipValue?.value && getResourceFromId(chipValue?.value),
    { id: chipValue.value },
    { enabled: !!chipValue.value },
  );

  if (!chipValue.value || !data) {
    return <></>;
  }

  return (
    <Chip deleteIcon={<CancelIcon />} label={`${chipValue?.label}: ${data?.name}`} {...restProps} />
  );
}
