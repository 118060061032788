import { styled, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';

export type SwitchProps = MuiSwitchProps;

export const Switch = styled(MuiSwitch)<SwitchProps>({
  '.MuiSwitch-thumb': {
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  },
  '.MuiTouchRipple-root': {
    border: 'none',
  },
});
