import React from 'react';

import { TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';

export const BankOperationsFilterDate = (): JSX.Element => {
  const form = useFormContext();

  return (
    <>
      <Controller
        control={form.control}
        name="startDate"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Период от"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            endDate={form.watch('endDate')}
            locale="ru"
            maxDate={form.watch('endDate')}
            onChange={(date) => {
              field.onChange(date);
            }}
            selected={field.value}
            selectsStart
            startDate={field.value}
          />
        )}
      />
      <Controller
        control={form.control}
        name="endDate"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Период до"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            endDate={field.value}
            locale="ru"
            minDate={form.watch('startDate')}
            onChange={(date) => {
              field.onChange(date);
            }}
            selected={field.value}
            selectsEnd
            startDate={form.watch('startDate')}
          />
        )}
      />
    </>
  );
};
