import { deleteRecord } from 'config/requests';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Button, ButtonProps } from 'shared/mui/Buttons';

export type DeleteButtonProps = Omit<ButtonProps, 'onError'> & {
  onDeleteCallback?: () => void;
  onError?: (data?: string) => void;
};

export function DeleteButton({
  variant = 'contained',
  color = 'error',
  label = 'Удалить',
  size = 'medium',
  onDeleteCallback,
  onClick,
  onError,
  ...restProps
}: DeleteButtonProps): JSX.Element {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext(restProps);

  const deleteOneRecord = () =>
    deleteRecord(record?.id.toString() || '')
      .then(() => {
        refresh();
        onDeleteCallback?.();
      })
      .catch((error) => {
        const errorMsg = error?.data?.detail;
        onError?.(errorMsg) || notify(errorMsg, { type: 'error', multiLine: true });
      });

  return (
    <Button
      color={color}
      label={label}
      onClick={onClick || deleteOneRecord}
      size={size}
      variant={variant}
      {...restProps}
    />
  );
}
