import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const OrdersItemStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 24px',
  boxSizing: 'border-box',
  outline: '1px solid #00000014',
});
