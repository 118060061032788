import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import { Chip } from '../../../../Chips';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

type FilterTextChipProps = {
  label: string;
  name: string;
  children: React.ReactNode;
  getName?: (filterValue: any) => string;
  onDelete?: () => void;
  onApply?: () => void;
};

export const FilterTextChip: React.FC<FilterTextChipProps> = ({
  name,
  label,
  children,
  getName,
  onApply,
  onDelete,
}) => {
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const { resetField, getValues, setValue } = useFormContext();
  const filterValue = _.get(appliedFilters, name);

  if (!filterValue) {
    return <></>;
  }

  function onApplyHandler() {
    handleClose();
    onApply && onApply();
    setAppliedFilter(name, getValues(name));
  }

  function onResetHandler() {
    setAppliedFilter(name, filterValue);
    setValue(name, filterValue);
  }

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApplyHandler}
        onClose={handleClose}
        onReset={onResetHandler}
        open={!!anchorEl}
      >
        {children}
      </PopoverChip>
      <Chip
        label={`${label}: ${getName?.(appliedFilters[name]) || appliedFilters[name] || filterValue}`}
        onClick={handleClick}
        onDelete={() => {
          onDelete && onDelete();
          resetField(name);
          setAppliedFilter(name, null);
        }}
        size="medium"
      />
    </>
  );
};
