import { ListProps } from 'react-admin';
import { BoxProps } from 'shared/mui/Box';
import { PageHeader, PageHeaderTitleProps } from 'shared/mui/PageHeader';
import { StyledListBox, StyledList } from 'shared/react-admin/Pages/ListPage/ListPage.styled';

export type ListPageProps = {
  headerTitle?: PageHeaderTitleProps;
  actions?: (React.ReactElement | false)[] | false;
  listBoxProps?: BoxProps;
} & Omit<ListProps, 'actions'>;

export function ListPage({
  children,
  actions = false,
  headerTitle,
  listBoxProps,
  ...restProps
}: ListPageProps): JSX.Element {
  return (
    <StyledList actions={false} {...restProps}>
      <PageHeader actions={actions} headerTitle={headerTitle} />
      <StyledListBox {...listBoxProps}>{children}</StyledListBox>
    </StyledList>
  );
}
