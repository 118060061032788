import { useEffect } from 'react';

import { FormControl } from '@mui/material';
import { AxiosError } from 'axios';
import { useNotify, useUpdate } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { DialogProps } from 'shared/mui/Dialogs';
import { TextField } from 'shared/mui/Fields';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';

import { Gateway, MappingItemRecord, Method } from '../../../../types';
import { AppButton } from '../../../Common/Buttons/AppButton';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from '../../../Common/Dialog';

type Props = {
  mappingItemRecord: MappingItemRecord | undefined;
  gatewayRecord: Gateway | undefined;
  methodRecord: Method | undefined;
  onClose: () => void;
} & DialogProps;

type FormValues = {
  value: string;
};

export function GatewayRulePercentOfTrafficDialog({
  gatewayRecord,
  methodRecord,
  mappingItemRecord,
  open,
  onClose,
}: Props): JSX.Element {
  const notify = useNotify();
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: { value: '0' },
  });

  useEffect(() => {
    if (mappingItemRecord) {
      reset({ value: `${mappingItemRecord.value}` });
    }
  }, [mappingItemRecord]);

  const [update] = useUpdate();

  const isDirty = Object.keys(formState.dirtyFields).length > 0;

  function onSubmit(formValues: FormValues) {
    if (!mappingItemRecord) {
      return;
    }

    update(
      'mapping_items',
      {
        id: mappingItemRecord.id,
        data: { value: parseInt(formValues.value, 10) },
        previousData: mappingItemRecord,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (e) => {
          const error = e as AxiosError;
          notify(`Ошибка: ${error.message}`, { type: 'error' });
        },
      },
    );
  }

  return (
    <AppDialogBase onClose={onClose} open={open}>
      <AppDialogHeader
        onClose={onClose}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Изменение процента трафика
            <TooltipWithInfoIcon
              sx={{
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                '& .MuiSvgIcon-root': {
                  width: '20px',
                  height: '20px',
                },
              }}
              tooltipText="0 —  шлюз будет отключен. В иных случаях трафик будет распределяться пропорционально к сумме всех значений процента трафика."
            />
          </Box>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth={true}>
          <TextField
            defaultValue={methodRecord?.name}
            disabled={true}
            label="Метод"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <TextField
            defaultValue={gatewayRecord?.name}
            disabled={true}
            label="Шлюз"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <Controller
            control={control}
            name="value"
            render={({ field, fieldState }) => (
              <TextField
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="Процент трафика"
                type="number"
                variant="outlined"
                {...field}
              />
            )}
            rules={{
              required: 'Обязательно для заполнения',
            }}
          />
        </FormControl>
        <AppDialogActions>
          <Box display="flex" gap={1}>
            <AppButton
              disabled={!isDirty && !formState.isValidating}
              label="Применить"
              type="submit"
              variant="contained"
            />
            <AppButton label="Отменить" onClick={onClose} />
          </Box>
        </AppDialogActions>
      </form>
    </AppDialogBase>
  );
}
