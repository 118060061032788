import { useEffect, useState } from 'react';

import RuleIcon from '@mui/icons-material/Rule';
import { Stack, Typography } from '@mui/material';
import { useOrderMethodsFilterForm } from 'components/OrderMethods/hooks/useOrderMethodsFilterForm';
import { ListContextProvider, UseListValue } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { BooleanField, FunctionField, ImageField, TextField } from 'shared/react-admin/Fields';
import { OrderMethod } from 'types';

import { useOrderMethodsContext } from './hooks/useOrderMethodsContext';
import OrderMethodsFilter from './OrderMethodsFilter';
import OrderMethodsRulesShowDialog from './OrderMethodsRulesShowDialog';
import { AppListContextProviderCard } from '../Common/Card';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

export type Direction = 'payout' | 'payment';

export type OrderMethodsListFilter = {
  merchantId?: string;
  currency?: string;
  direction?: string;
  customerId?: string;
  country?: string;
  language?: string;
  countDeposit?: number;
  appVersion?: string;
  domain?: string;
};

export const OrderMethodsList: React.FC = () => {
  const [filters, setFilters] = useState<OrderMethodsListFilter>({});
  const { form, resetFilter } = useOrderMethodsFilterForm();
  const { context, refetch } = useOrderMethodsContext(filters);
  const [selectedOrderMethod, setSelectedOrderMethod] = useState<OrderMethod | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      refetch();
    }
  }, [filters]);

  return (
    <ListContextProvider value={context as UseListValue}>
      <OrderMethodsRulesShowDialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        orderMethod={selectedOrderMethod}
      />
      <AppListContextProviderCard>
        <AppPanelHeader>
          <AppPanelHeaderToolbar titleText="Проверка правил и методов" />
        </AppPanelHeader>
        <OrderMethodsFilter form={form} resetFilter={resetFilter} setFilters={setFilters} />
        <Datagrid bulkActionButtons={false}>
          <TextField label="Название метода" source="name" />
          <TextField label="Алиас" source="alias" />
          <ImageField
            emptyText="—"
            label="Лого"
            source="logo"
            sx={{
              '& .RaImageField-image': {
                maxWidth: 60,
                maxHeight: 60,
                minWidth: 60,
                minHeight: 60,
                objectFit: 'contain',
              },
            }}
          />
          <TextField label="Тип метода" source="type.name" />
          <TextField label="Шлюз" source="gateway" />
          <FunctionField
            label={
              <Stack alignItems="center" direction="row" fontSize="13px" gap={1}>
                <RuleIcon sx={{ fontSize: '18px' }} />
                <Typography variant="body1">Правила</Typography>
              </Stack>
            }
            render={(orderMethod: OrderMethod) => (
              <Button
                label="Смотреть"
                onClick={() => {
                  setSelectedOrderMethod(orderMethod);
                  setIsOpen(true);
                }}
                startIcon={<RuleIcon sx={{ fontSize: '18px' }} />}
              />
            )}
          />
          <BooleanField label="Запрос суммы" source="requestFormAmount" />
        </Datagrid>
      </AppListContextProviderCard>
    </ListContextProvider>
  );
};
