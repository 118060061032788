import ListAltIcon from '@mui/icons-material/ListAlt';
import { useGetList, useRecordContext } from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { ListButton } from 'shared/react-admin/Buttons';

export const ProvidersListWhiteIp = () => {
  const record = useRecordContext();
  const { data: ipList, isLoading } = useGetList('provider_white_ip_addresses', {
    filter: {
      provider: record.id,
    },
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return ipList?.length ? (
    <ListButton
      filter={{
        provider: record?.id,
      }}
      icon={<ListAltIcon sx={{ fontSize: '18px' }} />}
      label="Перейти"
      pathname="/provider_white_ip_addresses"
      sx={{
        fontSize: '14px',
      }}
    />
  ) : (
    <ListButton
      filter={{
        provider: record?.id,
      }}
      icon={<ListAltIcon sx={{ fontSize: '18px' }} />}
      label="Добавить"
      pathname="/provider_white_ip_addresses/create"
      sx={{
        fontSize: '14px',
      }}
    />
  );
};
