import { styled } from '@mui/material/styles';

import { PageHeader } from '../../../mui/PageHeader';
import { Show } from '../../Show/Show';
import { SimpleShowLayout } from '../../SimpleShowLayout/SimpleShowLayout';
import { TabbedShowLayout } from '../../TabbedShowLayout/TabbedShowLayout';

export const ShowStyled = styled(Show)({
  '& .MuiTabs-flexContainer': {
    borderBottom: '1px solid #E5E6E7',
  },
  '& .RaShow-main': {
    margin: 0,
  },
  'span > p.MuiTypography-root': { display: 'none' },
  '.RaTab-row': {
    backgroundColor: '#F9FAFB',
  },
});

export const PageHeaderStyled = styled(PageHeader)({
  '& .MuiBox-root': {
    paddingBottom: 0,
  },
});

export const TabbedShowLayoutStyled = styled(TabbedShowLayout)({
  '& .RaTabbedShowLayout-content': {
    marginTop: '16px',
    padding: '0!important',
  },
  '& .MuiDivider-root': {
    display: 'none',
  },
});

export const SimpleShowLayoutStyled = styled(SimpleShowLayout)({
  padding: '0px 0px',
  backgroundColor: '#F9FAFB',
});
