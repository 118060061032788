import { APP_VERSIONS } from 'constants/appVersions';
import { LANGUAGES } from 'constants/languages';

import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';
import { getLastUrlSegment } from 'utils/getLastUrlSegment';

import { Direction, OrderMethodsListFilter } from './OrderMethodsList';

type Props = {
  form: UseFormReturn<OrderMethodsListFilter>;
  resetFilter: () => void;
  setFilters: (filter: OrderMethodsListFilter) => void;
};

const OrderMethodsFilter: React.FC<Props> = ({ form, setFilters, resetFilter }) => {
  const handleFilter = (): void => {
    const { direction, ...values } = form.getValues();
    setFilters({ ...values, direction: getLastUrlSegment(direction) as Direction });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleFilter)}>
        <Filter>
          <FilterRow sx={{ mb: 1, alignItems: 'flex-start' }}>
            <ReferenceInput
              name="merchant"
              reference="merchants"
              resource="merchants"
              source="merchantId"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                name="merchantId"
                optionText="name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors?.merchantId}
                    helperText={form.formState.errors?.merchantId?.message}
                    label="Мерчант"
                    variant="outlined"
                  />
                )}
                variant="outlined"
              />
            </ReferenceInput>
            <TextInputShrink label="Домен" name="domain" source="domain" variant="outlined" />
            <AutocompleteInputShrink
              choices={APP_VERSIONS}
              label="Версия приложения"
              name="appVersion"
              optionText="id"
              source="appVersion"
              variant="outlined"
            />
            <ReferenceInput
              name="country"
              reference="countries"
              resource="countries"
              source="country"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                name="country"
                optionText="name"
                optionValue="alphaCode"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors?.country}
                    helperText={form.formState.errors?.country?.message}
                    label="Страна"
                    variant="outlined"
                  />
                )}
                variant="outlined"
              />
            </ReferenceInput>
          </FilterRow>
          <FilterRow sx={{ mb: 1, alignItems: 'flex-start' }}>
            <ReferenceInput
              name="currency"
              perPage={300}
              reference="currencies"
              resource="currencies"
              source="currency"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                name="currency"
                optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                optionValue="alphaCode"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors?.currency}
                    helperText={form.formState.errors?.currency?.message}
                    label="Валюта"
                    variant="outlined"
                  />
                )}
                variant="outlined"
              />
            </ReferenceInput>
            <TextInputShrink
              id="customerId"
              label="ID клиента"
              name="customerId"
              source="customerId"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={LANGUAGES}
              label="Язык"
              name="language"
              optionText="title"
              optionValue="code"
              source="language"
              variant="outlined"
            />
            <TextInputShrink
              label="Количество депозитов"
              name="countDeposit"
              source="countDeposit"
              variant="outlined"
            />
          </FilterRow>
          <FilterRow>
            <AutocompleteInputShrink
              choices={[
                { id: 'payment', name: 'Пополнение' },
                { id: 'payout', name: 'Вывод' },
              ]}
              fullWidth
              name="direction"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  error={!!form.formState.errors?.direction}
                  helperText={form.formState.errors?.direction?.message}
                  label="Направление"
                  variant="outlined"
                />
              )}
              variant="outlined"
            />
          </FilterRow>
          <FilterRow marginTop={2}>
            <FilterActions>
              <FilterApplyButton label="Применить" type="submit" />
              <FilterResetButton label="Сбросить" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};

export default OrderMethodsFilter;
