import { DNOTE_SERVICE_URL } from 'constants/urls';

import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { TextFieldWithCopyButton } from 'shared/mui/Fields';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { appColors } from 'themes/variables';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  apiKey?: string;
};

export const ApiClientsShowApiKeysFieldDialog: React.FC<Props> = ({ open, onClose, apiKey }) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ width: '600px' }}
        title="Ключ API"
      />
      <CardContent>
        <TextFieldWithCopyButton
          disabled
          fullWidth
          size="medium"
          sx={{ marginTop: 0 }}
          value={apiKey || ''}
          variant="outlined"
        />
        <Box sx={{ marginTop: 2 }}>
          Используйте внутренний сервис{' '}
          <Link style={{ color: appColors.primary.main }} target="_blank" to={DNOTE_SERVICE_URL}>
            DNOTE
          </Link>{' '}
          для передачи значения ключа API.
        </Box>
      </CardContent>
    </Dialog>
  );
};
