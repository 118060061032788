import { styled } from '@mui/material/styles';
import { Dialog } from 'shared/mui/Dialogs';

export const DialogStyled = styled(Dialog)({
  '.MuiCardContent-root': {
    height: 'calc(100% - 64px)',
    overflow: 'auto',
    padding: 0,
    paddingBottom: '0!important',
    ':&last-child': {
      paddingBottom: '0!important',
    },
  },
  '.MuiPaper-root': {
    width: '1200px',
    height: '90%',
  },
});
