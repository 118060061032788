import React, { createContext, useContext } from 'react';

import { FieldValues } from 'react-hook-form';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ResetFilterArrayById, ResetFilterBySource } from '../../../../../hooks/useFilters';

export type FilterChipsContextType<TFilterValues extends FieldValues> = {
  filterChipValues: FilterChipValue<TFilterValues>[];
  resetFilterBySource: ResetFilterBySource<TFilterValues>;
  resetFilterArrayById?: ResetFilterArrayById<TFilterValues>;
};

export const FilterChipsContext = createContext<FilterChipsContextType<any>>(undefined as any);

type ContainerContextProviderProps<TFilterValues extends FieldValues> = {
  children: React.ReactNode;
  filterChipValues: FilterChipValue<TFilterValues>[];
  resetFilterBySource: ResetFilterBySource<TFilterValues>;
  resetFilterArrayById?: ResetFilterArrayById<TFilterValues>;
};

export function FilterChipsContextProvider<TFilterValues extends FieldValues>({
  resetFilterBySource,
  resetFilterArrayById,
  filterChipValues,
  children,
}: ContainerContextProviderProps<TFilterValues>) {
  return (
    <FilterChipsContext.Provider
      value={{
        resetFilterBySource,
        resetFilterArrayById,
        filterChipValues,
      }}
    >
      {children}
    </FilterChipsContext.Provider>
  );
}

export function useFilterChipsContext<TFilterValues extends FieldValues>() {
  const context = useContext<FilterChipsContextType<TFilterValues>>(FilterChipsContext);

  if (!context) {
    throw new Error('Can not `useFilterChipsContext` outside of the `FilterChipsContextProvider`');
  }

  return context;
}
