import {
  ImageField as AdminImageField,
  ImageFieldProps as AdminImageFieldProps,
} from 'react-admin';

export type ImageFieldProps = AdminImageFieldProps & {
  onClick?: (params: any) => void;
};

export const ImageField = (props: ImageFieldProps): JSX.Element => <AdminImageField {...props} />;
