import { styled } from '@mui/material';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';

export const AlertStyled = styled(Alert)({
  marginTop: '15px',
  '.MuiAlert-message': {
    width: '100%',
  },
});

export const BoxStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const UlStyled = styled('ul')({
  paddingLeft: '24px',
  marginTop: 0,
  marginBottom: '4px',
  height: '78px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '482px',
});
