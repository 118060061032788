import React, { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import {
  useResourceContext,
  AutocompleteArrayInput as RaAutocompleteArrayInput,
  AutocompleteArrayInputProps as RaAutocompleteArrayInputProps,
} from 'react-admin';

import { fieldValidateGuesser } from '../../../../utils';

export type AutocompleteArrayInputIntrospectedProps = RaAutocompleteArrayInputProps;

const AutocompleteArrayInput = ({ fields, validate, ...props }: any) => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });
  if (isError) {
    return <Fragment />;
  }
  return <RaAutocompleteArrayInput validate={guessedValidate} {...props} />;
};

export const AutocompleteArrayInputIntrospected = ({
  disabled,
  ...restProps
}: AutocompleteArrayInputIntrospectedProps): JSX.Element => {
  const resource = useResourceContext(restProps);

  return (
    <Introspecter
      component={AutocompleteArrayInput}
      disabled={disabled}
      includeDeprecated={disabled}
      resource={resource}
      {...restProps}
    />
  );
};
