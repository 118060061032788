import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';
import { Card } from 'shared/mui/Card';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/TextField/TextField';
import { Typography } from 'shared/mui/Typography';
import { JsonField } from 'shared/react-admin/Fields';

import { OrdersShowResponseBox } from './OrdersShowResponseBox';
import { OrdersShowResponseTitle } from './OrdersShowResponseTitle';
import { sendOrderNotification } from '../../../config/requests';

type Props = {
  notificationUrl?: string;
};

const OrdersShowSendNotification: React.FC<Props> = ({ notificationUrl }) => {
  const { id } = useParams();

  const [response, setResponse] = useState({});
  const [status, setStatus] = useState<number>();
  const [url, setUrl] = useState(notificationUrl || '');

  useEffect(() => {
    if (notificationUrl) {
      setUrl(notificationUrl);
    }
  }, [notificationUrl]);

  const sendNotification = useCallback(() => {
    sendOrderNotification(id || '')
      .then((response) => {
        setResponse(response.data.data);
        setStatus(response.status);
      })
      .catch((error) => {
        setResponse(error.data);
        setStatus(error.status);
      });
  }, [id]);

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <Typography component="h2" fontWeight={500} mb={2} mt={2} variant="h6">
        Отправить нотификацию
      </Typography>
      <TextField
        InputProps={{
          endAdornment: <Button label="Отправить" onClick={sendNotification} variant="contained" />,
        }}
        disabled
        fullWidth
        label="HTTP запрос"
        size="medium"
        sx={{ marginBottom: '16px' }}
        value={url}
        variant="outlined"
      />

      <TextField
        defaultValue="{}"
        disabled
        label="Тело запроса"
        size="medium"
        sx={{ marginBottom: '25px' }}
        variant="outlined"
      />
      {status && (
        <OrdersShowResponseBox>
          <OrdersShowResponseTitle fontSize="12px" fontWeight={400}>
            Ответ
          </OrdersShowResponseTitle>
          <JsonField src={response} />
          <Chip
            color={status === 200 ? 'primary' : 'error'}
            label={`Status: ${status}`}
            state="default"
            sx={{ position: 'absolute', top: '15px', right: '15px' }}
            variant="outlined"
          />
        </OrdersShowResponseBox>
      )}
    </Card>
  );
};

export default OrdersShowSendNotification;
