import { useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import qs from 'qs';
import { RaRecord, SimpleForm, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateDialog } from 'shared/react-admin/Dialogs/CreateDialog/CreateDialog';

import { CommissionCreateForm } from './CommissionCreateForm';
import { commissionSchema } from '../schemas/schema';

export type ProviderDefaultValueType = {
  provider: string;
  source?: string;
};

const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
  return {
    ...data,
    min: data.min ? data.min + '' : null,
    max: data.max ? data.max + '' : null,
    fix: data.fix ? data.fix + '' : null,
    percent: data.percent ? data.percent + '' : null,
  };
};

export const CommissionsCreate = (): JSX.Element => {
  const location = useLocation();
  const { goBack } = useGoBack();
  const defaultValues = useMemo(() => {
    const filterValues = qs.parse(location?.search.replace('?', ''))?.filter;
    return JSON.parse((filterValues || '{}') as string);
  }, [location]);

  const [errors, setErrors] = useState<string[]>([]);

  const notify = useNotify();
  const onSuccess = async () => {
    await queryClient.invalidateQueries(['commissions']);
    goBack();
    notify('ra.notification.created', {
      type: 'info',
      messageArgs: { smart_count: 1 },
    });
  };

  return (
    <CreateDialog
      mutationOptions={{
        onError: (error: any) => {
          setErrors(error?.message.split('\n'));
        },
        onSuccess,
      }}
      title="Cоздание комиссии"
      transform={transform}
    >
      <SimpleForm
        resolver={yupResolver<FieldValues>(commissionSchema)}
        toolbar={
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton key="save-button" />,
              <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
            ]}
          />
        }
      >
        <CommissionCreateForm defaultValues={defaultValues} errors={errors} />
      </SimpleForm>
    </CreateDialog>
  );
};
