import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { CumulativeReportSummary } from 'types';

import { EMPTY_FIELD } from '../../../../constants/emptyField';

type CumulativeReportSummaryFieldProps = {
  summaryColor?: string;
  valueKey: keyof CumulativeReportSummary;
  originalValueKey: keyof CumulativeReportSummary;
} & Omit<FunctionFieldProps, 'render'>;

export const CumulativeReportSummaryField = ({
  label,
  summaryColor,
  valueKey,
  originalValueKey,
}: CumulativeReportSummaryFieldProps): JSX.Element => {
  return (
    <FunctionField
      label={label}
      render={(record: CumulativeReportSummary) => {
        if (Number(record[valueKey]) === 0) {
          return EMPTY_FIELD;
        }
        const isOriginalCurrency = record.merchantAccountCurrencyCode === record.currencyCode;
        return (
          <Stack direction="column">
            <span style={{ color: summaryColor }}>
              {`${record[valueKey]} ${record.currencyCode}`}
            </span>
            {!isOriginalCurrency && (
              <span style={{ color: appColors.text.disabled }}>
                {`${record[originalValueKey]} ${record.merchantAccountCurrencyCode}`}
              </span>
            )}
          </Stack>
        );
      }}
    />
  );
};
