import { GatewayPilotModeSchedule } from 'types';

// приведенеие даты к формату ожидаемому на бэке (e.g. 2021-05-12 00:00:00+00:00)
const formatDate = (date: Date | string) => {
  const isoDateString = (date as Date).toISOString();
  const separatedDateTime = isoDateString.split('T');
  return `${separatedDateTime[0]} ${separatedDateTime[1].split('.')[0]}+00:00`;
};

const dateToString = (value: Date | string | null) => (value ? formatDate(value as Date) : value);

export const formatGatewayPilotModeSchedule = (
  gatewayPilotModeSchedule: GatewayPilotModeSchedule,
  id?: string,
): GatewayPilotModeSchedule | null => {
  // если gatewayPilotModeSchedule отсутствовал изначально и не был создан пользователем,
  // возвращаем на бэк исходное значение
  const isInitialGatewayPilotModeSchedule = !Object.values(gatewayPilotModeSchedule).some(
    (value) => value,
  );

  if (isInitialGatewayPilotModeSchedule) {
    return null;
  }

  const { periodStart, periodEnd, timeStart, timeEnd } = gatewayPilotModeSchedule;

  return {
    id: gatewayPilotModeSchedule.id,
    gateway: id,
    schedule: gatewayPilotModeSchedule.schedule,
    periodStart: dateToString(periodStart),
    periodEnd: dateToString(periodEnd),
    timeStart: dateToString(timeStart),
    timeEnd: dateToString(timeEnd),
  };
};
