import { TotalOrders } from 'types/orderConversion';

import { OrdersLineStyled, OrdersWrapperStyled } from './OrderConversionListOrders.styled';
import { OrderConversionListOrdersItem } from './OrderConversionListOrdersItem/OrderConversionListOrdersItem';

type Props = {
  orders: TotalOrders | undefined;
};

export const OrderConversionListOrders: React.FC<Props> = ({ orders }) => {
  return (
    <OrdersWrapperStyled>
      <OrdersLineStyled>
        <OrderConversionListOrdersItem label="Всего заказов" value={orders?.orderCount || 0} />
        <OrderConversionListOrdersItem label="Создано" value={orders?.created || 0} />
        <OrderConversionListOrdersItem label="Ожидание" value={orders?.pending || 0} />
        <OrderConversionListOrdersItem label="Успех" value={orders?.success || 0} />
        <OrderConversionListOrdersItem label="Провал" value={orders?.failure || 0} />
        <OrderConversionListOrdersItem label="Отменен" value={orders?.canceled || 0} />
        <OrderConversionListOrdersItem label="Просроч." value={orders?.expired || 0} />
        <OrderConversionListOrdersItem label="Рефанд" value={orders?.refund || 0} />
        <OrderConversionListOrdersItem label="Чарджбек" value={orders?.chargeback || 0} />
        <OrderConversionListOrdersItem label="Подготов." value={orders?.prepared || 0} />
      </OrdersLineStyled>
    </OrdersWrapperStyled>
  );
};
