import { useMemo } from 'react';

import { useGetMany } from 'react-admin';

import { Gateway, Provider } from '../../../types';
import { ProviderReportsListFilterType } from '../ProviderReportsListFilter';

export function useFormattedProviderReportsFilter(appliedFilters: ProviderReportsListFilterType): {
  formattedFilters: ProviderReportsListFilterType;
} {
  const { data: gateways = [] } = useGetMany<Gateway>(
    'gateways',
    {
      ids: appliedFilters?.gatewayAlias,
    },
    { enabled: !!appliedFilters.gatewayAlias?.length },
  );

  const { data: providers = [] } = useGetMany<Provider>(
    'providers',
    {
      ids: appliedFilters?.providerAlias,
    },
    { enabled: !!appliedFilters.providerAlias?.length },
  );

  const formattedFilters = useMemo(() => {
    return {
      ...appliedFilters,
      providerAlias: providers?.map((provider) => provider.alias),
      gatewayAlias: gateways?.map((gateway) => gateway.alias),
    };
  }, [appliedFilters, providers, gateways]);

  return { formattedFilters };
}
