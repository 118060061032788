import { DeleteStages } from 'constants/deleteStages';

import { useCallback, useState } from 'react';

import { AxiosResponse } from 'axios';
import { deleteRecord } from 'config/requests';
import { RaRecord } from 'react-admin';

type DeleteStageError = {
  data: {
    detail?: string;
  };
};

type Props = {
  actionFn?: (id: string) => Promise<AxiosResponse>;
  record: RaRecord | undefined;
  onSuccess: () => void;
  onError?: (error: DeleteStageError) => void;
};

type UseStagedDeleteReturn = {
  stage: DeleteStages;
  setStage: React.Dispatch<React.SetStateAction<DeleteStages>>;
  deleteOneRecord: () => void;
  denyDeleteReason: string;
};

export function useStagedDelete({
  actionFn,
  record,
  onSuccess,
  onError,
}: Props): UseStagedDeleteReturn {
  const [stage, setStage] = useState<DeleteStages>(DeleteStages.Initial);
  const [denyDeleteReason, setDenyDeleteReason] = useState('');

  const deleteOneRecord = useCallback(() => {
    setStage(DeleteStages.Loading);
    (actionFn?.(record?.id.toString() || '') || deleteRecord(record?.id.toString() || ''))
      .then(() => {
        onSuccess();
      })
      .catch((error: DeleteStageError) => {
        if (onError) {
          onError(error);
        }
        setDenyDeleteReason(error?.data?.detail || '');
        setStage(DeleteStages.DenyDelete);
      });

    return;
  }, [record]);

  return { stage, setStage, deleteOneRecord, denyDeleteReason };
}
