import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { FilterChipType } from 'constants/filterChipType';

import { useFilterChips } from 'hooks/useFilterChips';
import { useFilters } from 'hooks/useFilters';
import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

type UsersFiltersValues = {
  firstName: string;
  lastName: string;
  roleEntities: string[];
  email: string;
  twoFactor: boolean | null;
  enabled: boolean | null;
  reddyNumber: string;
};

export const UsersListFilters: React.FC = () => {
  const { form, resetFilter, onSubmit, resetFilterBySource, resetFilterArrayById } =
    useFilters<UsersFiltersValues>({
      lastName: '',
      firstName: '',
      roleEntities: [],
      email: '',
      twoFactor: null,
      enabled: null,
      reddyNumber: '',
    });

  const { filterValues } = useListContext();

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'firstName', label: 'Имя', type: FilterChipType.String },
      { source: 'lastName', label: 'Фамилия', type: FilterChipType.String },
      { source: 'roleEntities', label: 'Роли', type: FilterChipType.IdArray },
      { source: 'email', label: 'E-mail', type: FilterChipType.String },
      { source: 'twoFactor', label: 'Двухфакторная аутентификация', type: FilterChipType.Boolean },
      { source: 'enabled', label: 'Активен', type: FilterChipType.Boolean },
      { source: 'reddyNumber', label: 'Reddy', type: FilterChipType.String },
    ],
    appliedFilters: filterValues,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilter} />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById as any}
          resetFilterBySource={resetFilterBySource as any}
        >
          <FilterRow sx={{ mb: 2 }}>
            <TextInputShrink
              helperText={false}
              key="firstName"
              label="Имя"
              name="firstName"
              source="firstName"
              variant="outlined"
            />
            <TextInputShrink
              helperText={false}
              key="lastName"
              label="Фамилия"
              name="lastName"
              source="lastName"
              variant="outlined"
            />
            <TextInputShrink
              helperText={false}
              key="email"
              label="E-mail"
              name="email"
              source="email"
              variant="outlined"
            />
            <ReferenceArrayInput
              isFilter={true}
              name="roleEntities"
              perPage={100}
              reference="roles"
              source="roleEntities"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Роль"
                name="roleEntities"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
          </FilterRow>
          <FilterRow>
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              key="enabled"
              label="Активен"
              name="enabled"
              source="enabled"
              variant="outlined"
            />
            <TextInputShrink
              helperText={false}
              key="reddyNumber"
              label="Reddy"
              name="reddyNumber"
              source="reddyNumber"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              key="twoFactor"
              label="Двухфакторная аутентификация"
              name="twoFactor"
              source="twoFactor"
              variant="outlined"
            />
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
