import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { MethodLogo } from 'types';

import { StyledBox } from './MethodLogosShowDialog.styled';
import { MethodLogosShowDialogTable } from './MethodLogosShowDialogTable';

type MethodLogosShowDialogTableProps = {
  methodLogo?: MethodLogo;
  onClose: () => void;
} & DialogProps;

export const MethodLogosShowDialog = ({
  open,
  methodLogo,
  onClose,
}: MethodLogosShowDialogTableProps): JSX.Element => (
  <Dialog maxWidth="md" onClose={onClose} open={open}>
    <StyledBox>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Просмотр логотипа"
      />
      <RecordContextProvider value={methodLogo}>
        <MethodLogosShowDialogTable />
      </RecordContextProvider>
    </StyledBox>
  </Dialog>
);
