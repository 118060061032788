import { Fragment } from 'react';

import { fieldValidateGuesser } from 'utils';

import { ReferenceArrayInput } from '..';

export const ReferenceArrayInputIntrospectedComponent = ({
  fields,
  validate,
  perPage = 1000,
  isFilter = false,
  ...props
}: any): JSX.Element => {
  const { guessedValidate, isError } = !isFilter
    ? fieldValidateGuesser({ fields, validate, ...props })
    : { guessedValidate: null, isError: false };

  if (isError) {
    return <Fragment />;
  }

  return (
    <ReferenceArrayInput validate={guessedValidate} {...props} perPage={perPage}>
      {props.children}
    </ReferenceArrayInput>
  );
};
