import { styled } from '@mui/material/styles';
import { AutocompleteArrayInputIntrospected as AutocompleteArrayInputIntrospectedComponent } from 'shared/react-admin/Inputs';

export const AutocompleteArrayInputIntrospected = styled(
  AutocompleteArrayInputIntrospectedComponent,
)({
  margin: 0,
  '& .MuiFormControl-root': {
    margin: 0,
  },
});
