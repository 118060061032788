import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { FilterChipValue } from 'hooks/useFilterChips';
import { FieldValues } from 'react-hook-form';

import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsSelect<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  const label = chipValue?.options?.find((option) => chipValue.value === option.id)?.label;
  return (
    <Chip deleteIcon={<CancelIcon />} label={`${chipValue.label}: ${label || ''}`} {...restProps} />
  );
}
