import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AppPanelHeaderActions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '10px',
  whiteSpace: 'nowrap',
  flex: '0 1 auto',
});
