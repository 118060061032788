import { NotificationTransport } from 'types';

export const getNotificationTransportName = (
  id?: string,
  notificationTransports?: NotificationTransport[],
) => {
  return id && notificationTransports
    ? notificationTransports?.find((data) => data['@id'] === id)?.name || ''
    : '';
};
