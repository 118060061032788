import { FormControl, styled } from '@mui/material';

export const FormControlStyled = styled(FormControl)({
  marginBottom: '24px',
  '& .MuiSelect-select': {
    display: 'flex',
  },
  '& .MuiFormLabel-root': {
    top: 3,
  },
  '& .MuiInputLabel-shrink': {
    left: '2px',
    top: '-13px',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
  },
});

export const menuStyles = {
  '& .MuiPaper-root': {
    boxShadow: '0px 3px 14px 2px #0000001F, 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024',
  },
  '& .MuiMenuItem-root': {
    height: '52px',
    fontSize: '16px',
    padding: '6px 12px',
    margin: '0 !important',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
  },
};
