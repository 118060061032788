import { useRecordContext } from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';
import { User } from 'types';

import { StyledAvatar, StyledBox, StyledProfileBox } from './UserProfileField.styled';

export const UserProfileField = () => {
  const { firstName, lastName, email } = useRecordContext<User>();

  return (
    <StyledBox>
      <StyledAvatar title={`${firstName} ${lastName}`}>
        {`${firstName[0]}${lastName[0]}`.toUpperCase()}
      </StyledAvatar>
      <StyledProfileBox>
        <Typography fontSize={14}>
          {firstName} {lastName}
        </Typography>
        <Typography fontSize={13} sx={{ color: appColors.secondary.main }}>
          {email}
        </Typography>
      </StyledProfileBox>
    </StyledBox>
  );
};
