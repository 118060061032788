import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { Permission, Provider } from 'types';

import { ProviderWhiteIpAddressesShowTable } from './ProviderWhiteIpAddressesShowTable';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ProviderWhiteIpAddressesDeleteDialog } from '../components/ProviderWhiteIpAddressesDeleteDialog/ProviderWhiteIpAddressesDeleteDialog';

export const ProviderWhiteIpAddressesShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [
                <UserActionsIconButton
                  entityName="provider_white_ip_address"
                  key="user-action-link"
                />,
              ]
            : undefined
        }
        open={!openRecordModalDelete}
        title="Просмотр белого IP"
      >
        <ProviderWhiteIpAddressesShowTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('provider_white_ip_addresses', [
              Permission.Full,
              Permission.Update,
            ]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('provider_white_ip_addresses', [
              Permission.Full,
              Permission.Delete,
            ]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <ProviderWhiteIpAddressesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
