import { grey } from '@mui/material/colors';

import { StyledChip } from './GroupFieldChip.styled';

export type GroupFieldChipProps = {
  group: {
    color?: string;
    name: string;
  };
};

export const GroupFieldChip = ({ group }: GroupFieldChipProps): JSX.Element => (
  <StyledChip
    backgroundColor={group.color || grey[500]}
    color="primary"
    label={group.name}
    size="small"
  />
);
