import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';

export const StyledTitleCardContent = styled(CardContent)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
});
