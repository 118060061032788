import {
  BulkDeleteButton as RaBulkDeleteButton,
  BulkDeleteButtonProps as RaBulkDeleteButtonProps,
} from 'react-admin';

export type BulkDeleteButtonProps = RaBulkDeleteButtonProps;

export const BulkDeleteButton: React.FC<BulkDeleteButtonProps> = (props) => (
  <RaBulkDeleteButton {...props} />
);
