import { useStore } from 'react-admin';
import { ToggleButton } from 'shared/mui/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from 'shared/mui/ToggleButtonGroup/ToggleButtonGroup';

import { ReactComponent as RulesCompactView } from '../../../images/RulesCompactView.svg';
import { ReactComponent as RulesFullView } from '../../../images/RulesFullView.svg';

export function GatewayRulesViewToggle() {
  const [expandedAll, toggleExpandedAll] = useStore('gateway_rules.datagrid.view', false);
  const [, toggleExpandedIds] = useStore('gateway_rules.datagrid.expanded');

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={(_, value) => {
        if (value !== null) {
          toggleExpandedAll(value);
          toggleExpandedIds([]);
        }
      }}
      size="small"
      sx={{ '& .MuiButtonBase-root': { color: 'rgba(0,0,0, 0.6)', width: '40px' } }}
      value={expandedAll}
    >
      <ToggleButton onClick={() => toggleExpandedIds([])} value={true}>
        <RulesFullView />
      </ToggleButton>
      <ToggleButton onClick={() => toggleExpandedIds([])} value={false}>
        <RulesCompactView />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
