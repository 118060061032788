import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const StyledBox = styled(Box)({
  display: 'flex',
  top: '50%',
  flexDirection: 'column',
  padding: 0,
  width: '600px',
});
