import { styled } from '@mui/material';

export const GatewayRuleConditionFieldListItem = styled('li')({
  position: 'relative',
  '&:after': {
    position: 'absolute',
    left: '-16px',
    bottom: '0px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '23px',
  },
  '&:before': {
    position: 'absolute',
    left: '-16px',
    top: '0px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '23px',
  },
  '&:last-child:after': {
    display: ' none',
  },
});
