import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ShowPage } from 'shared/react-admin/Pages';
import { Permission, User } from 'types';

import { UsersShowTable } from './UsersShowTable';
import { UserDeleteModal } from '../components/UserDeleteModal';
import { UsersLinkButton } from '../components/UsersLinkButton';

export const UsersShow = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useShowController<User>();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } = useRecordModal<User>();

  return (
    <ShowPage
      actions={[
        <Box display="flex" gap={1} key="actions">
          <UsersLinkButton key="user-actions" userId={record?.id} />
          <UserActionsIconButton entityName="user" key="user-action-link" />
        </Box>,
      ]}
      leftActionsSlot={[
        hasResourcePermissions('users', [Permission.Full, Permission.Update]) && (
          <EditLinkButton key="edit-button" label="Редактировать" />
        ),
      ]}
      listName="Пользователи"
      rightActionsSlot={[
        hasResourcePermissions('users', [Permission.Full, Permission.Delete]) && (
          <DeleteButton
            key="delete-button"
            onClick={() => handleOpenRecordModal(record)}
            variant="outlined"
          />
        ),
      ]}
      title="Просмотр пользователя"
    >
      <UsersShowTable />
      <UserDeleteModal
        handleCloseRecordModal={handleCloseRecordModal}
        openRecordModal={openRecordModal}
        record={record}
      />
    </ShowPage>
  );
};
