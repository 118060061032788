import { Typography } from 'shared/mui/Typography';

type Props = {
  title: string;
};

export const ApiClientsShowTopPanelTitle: React.FC<Props> = ({ title }) => {
  return (
    <Typography component="span" fontWeight={600} sx={{ marginRight: '6px' }} variant="body1">
      {title}:
    </Typography>
  );
};
