import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useHistoryContext } from './useHistoryContext';

export function useHistoryURL(): void {
  const { setBaseRoute } = useHistoryContext();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    // проверка на то является ли роут базовым
    if ((pathname.match(/\//g) || []).length < 2) {
      setBaseRoute(location);
    }
  }, [location.pathname, location.search]);
}
