import { useGoBack } from 'hooks/useGoBack';
import { RaRecord, useEditController } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import { OrderStatus } from 'types';

import { RolesForm } from './components/RolesForm/RolesForm';

const transform = ({ roleCategories, ...data }: RaRecord): RaRecord => ({
  ...data,
  roleCategories: typeof roleCategories === 'string' ? roleCategories : roleCategories[0],
  allowedStatusesEditing: data.allowedStatusesEditing.map(
    (status: OrderStatus) => status?.value || status,
  ),
});

export const RolesEdit: React.FC = () => {
  const { record } = useEditController();
  const { goBack } = useGoBack();

  return (
    <EditGuesserDialog
      mutationOptions={{ onError: () => undefined }}
      sx={{ maxWidth: '444px' }}
      title="Редактирование роли"
      transform={transform}
    >
      <RolesForm defaultOrderStatuses={record?.allowedStatusesEditing} />
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButtonValidated key="save-button" label="Сохранить" />,
          <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
        ]}
      />
    </EditGuesserDialog>
  );
};
