import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { getMerchantSpacesUsersRoles } from '../../../config/requests';

type MerchantSpacesUsersRoleChoice = {
  id: string;
  name: string;
};

export type UseMerchantSpacesUsersRolesReturnValues = {
  merchantSpacesUsersRoleChoices: MerchantSpacesUsersRoleChoice[];
  isLoadingMerchantSpacesUsersRoles: boolean;
};

export function useMerchantSpacesUsersRoleChoices(): UseMerchantSpacesUsersRolesReturnValues {
  const { data, isLoading: isLoadingMerchantSpacesUsersRoles } = useQuery(
    ['merchant_spaces_roles'],
    () => getMerchantSpacesUsersRoles(),
  );

  const merchantSpacesUsersRoleChoices = useMemo<MerchantSpacesUsersRoleChoice[]>(() => {
    if (!data) {
      return [];
    }
    return data?.map((role) => ({ name: role, id: role }));
  }, [data]);

  return { merchantSpacesUsersRoleChoices, isLoadingMerchantSpacesUsersRoles };
}
