import { EMPTY_FIELD } from 'constants/emptyField';

import { VpnKey } from '@mui/icons-material';
import { useRecordModal } from 'hooks/useRecordModal';
import { Button } from 'shared/mui/Buttons';
import { FunctionField } from 'shared/react-admin/Fields';
import { ApiKey } from 'types';

import { ApiClientsShowApiKeysFieldDialog } from './ApiClientsShowApiKeysFieldDialog';

type Props = {
  label?: string | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const ApiClientsShowApiKeysField: React.FC<Props> = ({ label }) => {
  const {
    record: apiKey,
    handleOpenRecordModal: handleOpenRecordModalApiKey,
    openRecordModal: openRecordModalApiKey,
    handleCloseRecordModal: handleCloseRecordModalApiKey,
  } = useRecordModal<string>();

  return (
    <>
      <FunctionField
        label={label}
        render={(record: ApiKey) => {
          return record.value ? (
            <Button
              label="Показать"
              onClick={() => handleOpenRecordModalApiKey(record.value)}
              startIcon={<VpnKey />}
              sx={{ top: '-8px' }}
            />
          ) : (
            EMPTY_FIELD
          );
        }}
        source="apiKeys"
      />
      {openRecordModalApiKey && (
        <ApiClientsShowApiKeysFieldDialog
          apiKey={apiKey}
          onClose={handleCloseRecordModalApiKey}
          open={openRecordModalApiKey}
        />
      )}
    </>
  );
};
