import { Form, Identifier, RecordContextProvider, useNotify, useUpdate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { ApiClientWhiteIp } from 'types';

import { ApiClientsShowWhiteIpAddressesForm } from '../components/ApiClientsShowWhiteIpAddressesForm/ApiClientsShowWhiteIpAddressesForm';

type Props = {
  open: boolean;
  onClose: () => void;
  record?: ApiClientWhiteIp;
  apiClient?: Identifier;
};

interface EditFormValues extends FieldValues {
  ip: string;
  mask: string;
  apiClient: Identifier;
}

export const ApiClientsShowWhiteIpAddressesEditDialog: React.FC<Props> = ({
  open,
  onClose,
  record,
}) => {
  const [update] = useUpdate();
  const notify = useNotify();

  const onSubmit = (formValues: EditFormValues) => {
    update(
      'api_client_white_ip_addresses',
      {
        id: record?.id,
        data: {
          ...formValues,
          apiClient: record?.apiClient,
        },
      },
      {
        onSuccess: () => {
          notify('Белый IP адрес успешно отредактирован!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => notify(`Ошибка: ${(error as Error).message}`, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Редактирование белого IP адреса"
          />
          <ApiClientsShowWhiteIpAddressesForm />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="submit" label="Сохранить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
