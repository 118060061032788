import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { BooleanField, FunctionField, ImageField, TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MethodLogosShowDialog } from 'shared/widgets/MethodLogosShowDialog/MethodLogosShowDialog';
import { MethodLogo } from 'types';

export const MethodsShowTable = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<MethodLogo>();

  return (
    <CardTable>
      <TableBody>
        <TableRowWrapper title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField source="name" />
        </TableRowWrapper>
        <TableRowWrapper title="Алиас">
          <TextField label="Алиас" source="alias" />
        </TableRowWrapper>
        {hasResourceAccess('method_types') && (
          <TableRowWrapper title="Тип метода">
            <ReferenceOneField
              label="Тип метода"
              reference="method_types"
              source="methodType"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        <TableRowWrapper title="Активен">
          <BooleanField label="Активен" source="active" />
        </TableRowWrapper>

        {hasResourceAccess('method_logos') && (
          <TableRowWrapper title="Лого">
            <ReferenceOneField label="Логотип" reference="method_logos" source="logo" target="id">
              <FunctionField
                render={(record: MethodLogo | undefined) => (
                  <ImageField
                    onClick={() => handleOpenRecordModal(record)}
                    source="filePath"
                    sx={{
                      cursor: 'pointer',
                      '& .RaImageField-image': {
                        maxWidth: 200,
                        minWidth: 200,
                        objectFit: 'contain',
                      },
                    }}
                  />
                )}
              />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
      </TableBody>
      {record && (
        <MethodLogosShowDialog
          methodLogo={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
    </CardTable>
  );
};
