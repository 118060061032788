import { BankOperationStatuses } from 'constants/bankOperationStatuses';
import { Role } from 'constants/roles';

import { AppBaseDeleteButton, AppEditButton } from 'components/Common/Buttons';
import { BankOperationsShowTable } from 'components/Finance/BankOperations/BankOperationsShow/BankOperationsShowTable';
import { ApprovalConfirm } from 'components/Finance/BankOperations/components/Approvals/ApprovalConfirm';
import { ApprovalDecline } from 'components/Finance/BankOperations/components/Approvals/ApprovalDecline';
import { ApprovalReviewing } from 'components/Finance/BankOperations/components/Approvals/ApprovalReviewing';
import { useBankOperationEditable } from 'components/Finance/BankOperations/hooks/useBankOperationEditable';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useGetOne, useShowController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ShowPage } from 'shared/react-admin/Pages';
import { BankOperationStatus, Permission } from 'types';

export const BankOperationsShow = (): JSX.Element => {
  const { hasResourcePermissions, roles } = usePermissions();
  const { record } = useShowController();
  const { data: bankOperationStatus } = useGetOne<BankOperationStatus>(
    'bank_operation_statuses',
    { id: record?.status },
    { enabled: !!record },
  );

  const navigate = useNavigate();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } = useRecordModal();

  const { checkBankOperationEditable } = useBankOperationEditable();

  const approvalReview = useRecordModal();
  const approvalConfirm = useRecordModal();
  const approvalDecline = useRecordModal();

  // данная функциональность была связана с удаленными сущностями userGroup userGroupMember
  // const { isUserFinanceHead } = useIsUserFinanceHead();
  const isUserFinanceHead = false;

  function getAdditionalActions() {
    if (
      (roles.includes(Role.Tech) || roles.includes(Role.Admin) || isUserFinanceHead) &&
      bankOperationStatus?.value === BankOperationStatuses.Reviewing
    ) {
      return [
        <Box key="approve-actions" sx={{ display: 'flex', gap: 2 }}>
          <Button
            color="success"
            key="approve-operation"
            label="Согласовать"
            onClick={approvalConfirm.handleOpenRecordModal}
            size="small"
            variant="contained"
          />
          <Button
            color="error"
            key="decline-operation"
            label="Отклонить"
            onClick={approvalDecline.handleOpenRecordModal}
            size="small"
            variant="contained"
          />
        </Box>,
      ];
    } else if (
      !isUserFinanceHead &&
      (bankOperationStatus?.value === BankOperationStatuses.Created ||
        bankOperationStatus?.value === BankOperationStatuses.Canceled)
    ) {
      return [
        <Button
          color="primary"
          key="review-operation"
          label="Отправить на согласование"
          onClick={approvalReview.handleOpenRecordModal}
          size="small"
          variant="contained"
        />,
      ];
    }

    return false;
  }

  return (
    <ShowPage
      additionalActions={getAdditionalActions()}
      leftActionsSlot={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Update]) &&
          checkBankOperationEditable(record) && (
            <AppEditButton icon={<></>} key="edit-button" label="Редактировать" />
          ),
      ]}
      listName="Банковские операции"
      rightActionsSlot={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) &&
          checkBankOperationEditable(record) && (
            <AppBaseDeleteButton
              onClick={() => handleOpenRecordModal(record)}
              startIcon={<></>}
              title="Удалить"
            />
          ),
      ]}
      title="Банковская операция"
    >
      <BankOperationsShowTable />
      <ApprovalReviewing
        onClose={approvalReview.handleCloseRecordModal}
        open={approvalReview.openRecordModal}
      />
      <ApprovalConfirm
        onClose={approvalConfirm.handleCloseRecordModal}
        open={approvalConfirm.openRecordModal}
      />
      <ApprovalDecline
        onClose={approvalDecline.handleCloseRecordModal}
        open={approvalDecline.openRecordModal}
      />
      <DeleteDialog
        details="Заявка будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={record}
        title="Удаление заявки"
      />
    </ShowPage>
  );
};
