// replaces unavailable local storage
export class LocalStorageShim {
  valuesMap: any = new Map();

  getItem(key: string): string | null {
    if (this.valuesMap.has(key)) {
      return String(this.valuesMap.get(key));
    }
    return null;
  }

  setItem(key: string, value: string): void {
    this.valuesMap.set(key, value);
  }

  removeItem(key: string): void {
    this.valuesMap.delete(key);
  }

  removeItems(keyPrefix: string): void {
    this.valuesMap.forEach((key: string) => {
      if (key.startsWith(keyPrefix)) {
        this.valuesMap.delete(key);
      }
    });
  }

  clear(): void {
    this.valuesMap.clear();
  }

  key(i: number): string {
    if (arguments.length === 0) {
      throw new TypeError(
        'Failed to execute \'key\' on \'Storage\': 1 argument required, but only 0 present.',
      ); // this is a TypeError implemented on Chrome, Firefox throws Not enough arguments to Storage.key.
    }
    const arr = Array.from(this.valuesMap.keys()) as string[];
    return arr[i];
  }

  get length(): number {
    return this.valuesMap.size;
  }
}

export const memoryStorage = new LocalStorageShim();
