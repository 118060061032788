import { Box } from 'shared/mui/Box';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { TypographyTitle } from 'shared/mui/Typography';

export type PageHeaderTitleProps = {
  titleText: React.ReactNode;
  tooltipText?: string;
  titleSize?: 'medium' | 'large' | 'small';
};

export const PageHeaderTitle = ({
  titleText,
  tooltipText,
  titleSize = 'large',
}: PageHeaderTitleProps): JSX.Element => (
  <Box alignItems="center" display="flex" mt={1} pb={0}>
    <TypographyTitle mr={1} size={titleSize}>
      {titleText}
    </TypographyTitle>
    {tooltipText && <TooltipWithInfoIcon iconSize={24} tooltipText={tooltipText} />}
  </Box>
);
