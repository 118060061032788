import { Button, CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

type EditPayoutControlManageSwitchOnDialogProps = {
  isLoading: boolean;
  merchantName?: string;
  onSubmit: () => void;
} & DialogProps;

export const EditPayoutControlManageSwitchOnDialog: React.FC<
  EditPayoutControlManageSwitchOnDialogProps
> = ({ isLoading, merchantName, onClose, onSubmit, ...props }) => (
  <Dialog {...props} fullWidth>
    <Card>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Включение контроля выводов"
      />
      <CardContent>
        <Typography>
          После включения контроля выводов, методы на вывод для мерчанта {merchantName} будут
          формироваться после прохождения проверки соответствия способам пополнения.
        </Typography>
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <Button
            disabled={isLoading}
            key="apply-button"
            label="Подтвердить"
            onClick={onSubmit}
            variant="contained"
          />,
          <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
        ]}
      />
    </Card>
  </Dialog>
);
