import {
  FilterCollapsibleToolbarAdditionalStyled,
  FilterCollapsibleToolbarStyled,
} from './FilterCollapsibleToolbar.styled';
import { Box, BoxProps } from '../../../Box';
import { FilterActions } from '../../FilterActions/FilterActions';

export type FilterCollapsibleToolbarProps = {
  actions?: (React.ReactElement | false)[] | false;
  additionalActions?: (React.ReactElement | false)[] | false;
} & BoxProps;

export const FilterCollapsibleToolbar: React.FC<FilterCollapsibleToolbarProps> = ({
  children,
  actions,
  additionalActions,
  ...restProps
}) => {
  return (
    <Box {...restProps}>
      {children}
      <FilterCollapsibleToolbarStyled>
        <FilterActions>{actions}</FilterActions>
        <FilterCollapsibleToolbarAdditionalStyled>
          {additionalActions}
        </FilterCollapsibleToolbarAdditionalStyled>
      </FilterCollapsibleToolbarStyled>
    </Box>
  );
};
