import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

type RolesListFilterValues = {
  name: string;
};

export const RolesListFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<RolesListFilterValues>({
    name: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
