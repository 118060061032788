import {
  ListContextProvider,
  useCreatePath,
  useGetMany,
  useList,
  useShowController,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { BooleanField, FunctionField, TextField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { GatewayMethod } from '../../../../types';
import { StyledDatagrid } from '../GatewaysShow.styled';

export const GatewaysShowTableMethodsDatagrid = (): JSX.Element => {
  const { record } = useShowController();
  const createPath = useCreatePath();

  const { data: gatewayMethods, isLoading } = useGetMany(
    'gateway_methods',
    {
      ids: record?.gatewayMethods,
    },
    {
      enabled: !!record?.gatewayMethods,
    },
  );

  const gatewayMethodsListContext = useList({
    data: gatewayMethods || [],
    perPage: 10,
    sort: { field: 'originId', order: 'ASC' },
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <ListContextProvider value={gatewayMethodsListContext}>
      <StyledDatagrid bulkActionButtons={false}>
        <FunctionField
          label="Метод"
          render={(record: GatewayMethod) => (
            <Link
              style={{ textDecoration: 'none', color: appColors.primary.main }}
              target="_blank"
              to={createPath({ resource: 'methods', id: record?.method, type: 'show' })}
            >
              <TextField label="Метод" sortable={false} source="methodName" />
            </Link>
          )}
          sortable={false}
          source="methodName"
        />
        <BooleanField label="Активен" sortable={false} source="active" />
        <BooleanField label="Тестирование" sortable={false} source="pilotMode" />
        <BooleanField
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                paddingTop: '5px',
                lineHeight: 1.2,
              }}
            >
              <span lang="ru" style={{ width: '85px', marginRight: '4px', hyphens: 'auto' }}>
                Авто&shy;отключение
              </span>
              <span style={{ marginTop: '-6px' }}>
                <TooltipWithInfoIcon tooltipText="Режим тестирования будет отключён при создании первого заказа в системе." />
              </span>
            </div>
          }
          sortable={false}
          source="autoDeactivation"
        />
      </StyledDatagrid>
    </ListContextProvider>
  );
};
