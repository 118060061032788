import { AxiosInstance, AxiosResponse } from 'axios';

import { LOGIN_PATH, SECOND_FACTOR_PATH, CHANGE_PASSWORD_PATH } from '../config/entrypoint';
import { AuthStep } from '../constants';
import { LoginParams, StepData } from '../providers/authProvider';
import { Credentials } from '../types';

const authUrls = {
  preLogin: LOGIN_PATH,
  login: SECOND_FACTOR_PATH,
  changePassword: CHANGE_PASSWORD_PATH,
};

type PreLoginData = {
  step: AuthStep;
  remember: boolean;
} & Omit<Credentials, 'remember'>;

type LoginData = {
  step: AuthStep;
  token?: string;
} & LoginParams;

export type ChangePassData = {
  new_password: string;
  repeat_password: string;
  captchaToken: string;
  token: string;
};

export class AuthService {
  constructor(private httpClient: AxiosInstance) {}

  preLogin(data: PreLoginData): Promise<AxiosResponse<StepData, any>> {
    return this.httpClient.post<StepData>(authUrls.preLogin, data);
  }

  login(data: LoginData): Promise<AxiosResponse<StepData, any>> {
    return this.httpClient.post<StepData>(authUrls.login, data);
  }

  changePassword(data: ChangePassData): Promise<AxiosResponse<StepData, any>> {
    return this.httpClient.post<StepData>(authUrls.changePassword, data);
  }
}
