import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Dialog } from 'shared/mui/Dialogs';

export const StyledDialog = styled(Dialog)({
  '.MuiCardContent-root': {
    padding: 0,
    paddingBottom: '0!important',
    ':&last-child': {
      paddingBottom: '0!important',
    },
  },
});

export const StyledBox = styled(Box)({
  width: '600px',
});

export const StyledAlertBox = styled(Box)({
  padding: '8px 24px',
});

export const StyledFormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '16px',
  lineHeight: '24px',
});

export const StyledList = styled('ul')({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: '20px',
});
