import { AuthStep } from 'constants/authStep';

import axios, { AxiosError } from 'axios';
import {
  CAPTCHA_PUBLIC_KEY,
  LOGIN_PATH,
  SECOND_FACTOR_PATH,
  CHANGE_PASSWORD_PATH,
} from 'config/entrypoint';
import { UserIdentity, AuthProvider } from 'react-admin';
import { Credentials, User } from 'types';

import { getUserInfo } from '../config/requests';
import { AuthService, ChangePassData } from '../services/authService';

type Error = {
  status: any;
  response: { status: any; url: string };
};

export type LoginParams = {
  captchaToken: string;
  code: string;
};

export type ChangePasswordParams = {
  new_password: string;
  repeat_password: string;
  captchaToken: string;
  code: string;
};

export type StepData = {
  QrCodeUrl?: string;
  preLoginToken?: string;
  token?: string;
  is_temporary_password?: string;
};

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json' },
});
const authService = new AuthService(axiosInstance);

export const authProvider: AuthProvider = {
  captchaPublicKey: CAPTCHA_PUBLIC_KEY,
  currentStepResponseContent: {} as StepData,
  step: AuthStep.Credentials,
  stepPath: LOGIN_PATH,
  preLogin: async function (credentials: Credentials): Promise<StepData | undefined> {
    try {
      const { data } = await authService.preLogin({
        ...credentials,
        step: this.step,
      });
      if (data?.is_temporary_password) {
        this.step = AuthStep.ChangePassword;
        this.stepPath = CHANGE_PASSWORD_PATH;
      } else {
        this.step = AuthStep.SecondFactor;
        this.stepPath = SECOND_FACTOR_PATH;
      }
      this.currentStepResponseContent = data;

      return data;
    } catch (e) {
      const error = e as AxiosError;
      if (!error.response) {
        throw new Error('No Server Response!');
      }
      if (error.response.status < 200 || error.response.status >= 300) {
        throw new Error(error.response.statusText);
      }
    }
  },
  login: async function (params: LoginParams): Promise<void | User | Response> {
    try {
      const { data } = await authService.login({
        ...params,
        step: this.step,
        token: this.currentStepResponseContent.token,
      });

      this.currentStepResponseContent = {};
      localStorage.setItem('token', data.token || '');
      this.step = AuthStep.Credentials;
      return getUserInfo();
    } catch (e) {
      const error = e as AxiosError;
      if (!error.response) {
        throw new Error('No Server Response!');
      }
      if (error.response.status < 200 || error.response.status >= 300) {
        throw new Error(error.response.statusText);
      }
    }
  },
  changePassword: async function (params: ChangePassData): Promise<void | Response> {
    try {
      const { data } = await authService.changePassword({
        ...params,
        token: this.currentStepResponseContent.token,
      });
      this.currentStepResponseContent = data;
      this.step = AuthStep.SecondFactor;
      this.stepPath = SECOND_FACTOR_PATH;
    } catch (e) {
      const error = e as AxiosError;
      if (!error.response) {
        throw new Error('No Server Response!');
      }
      if (error.response.status < 200 || error.response.status >= 300) {
        return Promise.reject(error.response);
      }
    }
  },
  logout: (): Promise<void> => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: (): Promise<void> => {
    if (!localStorage.getItem('token')) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: (error: Error): Promise<void> => {
    if ([403].includes(error?.status || error?.response?.status)) {
      // eslint-disable-next-line no-console
      console.error('Access denied, probably permissions issue', error?.response?.url);
    }
    if ([401].includes(error?.status || error?.response?.status)) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: (): Promise<UserIdentity> => {
    try {
      return Promise.resolve({ id: '', fullName: '', avatar: '' });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: (): Promise<void> => Promise.resolve(),
  isCredentialsStep: function (): boolean {
    return this.step === AuthStep.Credentials;
  },
};
