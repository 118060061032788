import { ExportTransportTypes } from 'constants/exportFormats';
import { NOTIFICATIONS_REFETCH_INTERVAL } from 'constants/notifications';

import { NotificationsCountResponse, getNotificationsCount } from 'config/requests';
import { getUnixTime, subDays } from 'date-fns';
import { useQuery } from 'react-query';

type UseGetNotificationsCountReturnValue = {
  notificationsCount?: string;
  refetch: () => void;
};

export function useGetNotificationsCount(): UseGetNotificationsCountReturnValue {
  const requestParams = {
    createdAtFrom: getUnixTime(subDays(new Date(), 2)),
    createdAtTo: getUnixTime(new Date()),
    transport: ExportTransportTypes.Admin,
    isViewed: 0,
  };

  const { data, refetch } = useQuery<NotificationsCountResponse>(
    ['notificationsCount'],
    () => getNotificationsCount(requestParams),
    {
      refetchInterval: NOTIFICATIONS_REFETCH_INTERVAL,
    },
  );

  return { notificationsCount: data?.count, refetch };
}
