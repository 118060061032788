import { formatInTimeZone } from 'date-fns-tz';

import { DEFAULT_DATE_MASK, DEFAULT_TIMEZONE } from '../config/defaultValues';

export function formatToMask(
  timestamp: number,
  mask: string = DEFAULT_DATE_MASK,
  timezone: string = DEFAULT_TIMEZONE,
): string {
  return formatInTimeZone(timestamp, timezone, mask);
}
