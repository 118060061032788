export const enum GatewayStatus {
  Active = 'active',
  Disabled = 'disabled',
  Test = 'test',
  Pilot = 'pilot',
}

export const GATEWAY_STATUSES = {
  [GatewayStatus.Active]: {
    name: 'Активен',
    id: '/admin/gateway_statuses/active',
  },
  [GatewayStatus.Disabled]: {
    name: 'Неактивен',
    id: '/admin/gateway_statuses/disabled',
  },
  [GatewayStatus.Test]: {
    name: 'Тест',
    id: '/admin/gateway_statuses/test',
  },
  [GatewayStatus.Pilot]: {
    name: 'Пилот',
    id: '/admin/gateway_statuses/pilot',
  },
};
