import { TypographyProps } from '@mui/material';

import { Typography } from '..';

export type TypographySize = 'large' | 'medium' | 'small';

export type TypographyTitleProps = TypographyProps & {
  size?: TypographySize;
};

const sizeConfig: Record<TypographySize, TypographyProps> = {
  large: {
    variant: 'h4',
    fontSize: '28px',
  },
  medium: {
    variant: 'h5',
    fontSize: '24px',
  },
  small: {
    variant: 'h6',
    fontSize: '20px',
  },
};

export function TypographyTitle({
  children,
  size = 'medium',
  ...restProps
}: TypographyTitleProps): JSX.Element {
  return (
    <Typography {...restProps} {...sizeConfig[size]}>
      {children}
    </Typography>
  );
}
