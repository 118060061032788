import React from 'react';

import { required } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CardHeader } from 'shared/mui/Card';
import { CardAdditionalButtons } from 'shared/mui/Card/CardAdditionalButtons/CardAdditionalButtons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Row } from 'shared/mui/Row/Row';
import { Tab } from 'shared/mui/Tab/Tab';
import { TabPanel } from 'shared/mui/TabPanel/TabPanel';
import { Tabs } from 'shared/mui/Tabs/Tabs';
import { AutocompleteInput, BooleanInput, TextInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { MerchantSpacesEditFormDatagrid } from './MerchantSpacesEditFormDatagrid';
import { fieldWidth } from '../../../themes/variables';

export const MerchantSpacesEditForm: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState<number>(0);
  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <CardAdditionalButtons>
        <BooleanInput label="Активно" source="active" />
      </CardAdditionalButtons>
      <CardContent>
        <Row sx={{ justifyContent: 'flex-start' }}>
          <TextInput
            label="Название"
            size="medium"
            source="spaceName"
            sx={fieldWidth.medium}
            validate={required()}
            variant="outlined"
          />
          <ReferenceInput perPage={200} reference="companies" source="sourceCompany">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Компания"
              optionText="name"
              size="medium"
              sx={fieldWidth.medium}
              validate={required()}
              variant="outlined"
            />
          </ReferenceInput>
        </Row>
        <Row>
          <Box sx={{ width: '100%' }}>
            <Tabs onChange={onTabChange} value={currentTab}>
              <Tab label="Мерчанты" />
            </Tabs>
            <TabPanel index={0} value={currentTab}>
              <Box>
                <CardHeader
                  sx={{ border: '1px solid rgba(0, 0, 0, 0.08)', borderBottom: 'none' }}
                  title="Список мерчантов компании"
                />
                <Box>
                  <MerchantSpacesEditFormDatagrid />
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Row>
      </CardContent>
    </>
  );
};
