import React, { useEffect } from 'react';

import { useRecordModal } from 'hooks/useRecordModal';
import { RecordContextProvider, useNotify, useRedirect } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';
import { SaveButton } from 'shared/react-admin/Buttons';

import { MerchantSpacesUsersEditForm } from './MerchantSpacesUsersEditForm';
import {
  editMerchantSpacesUsersRequest,
  EditMerchantSpacesUsersParams,
} from '../../../config/requests';
import { useMerchantSpacesUsers } from '../../../hooks/queries/useMerchantSpacesUsers';
import { MerchantSpacesUsers, MerchantSpacesUsersSite } from '../../../types';

export type MerchantSpacesUsersFormValues = {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  merchantSpaceId: string;
  sites?: MerchantSpacesUsersSite[];
};

export const MerchantSpacesUsersEdit: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { id } = useParams();
  const { merchantSpacesUsers, isLoadingMerchantSpacesUsers } = useMerchantSpacesUsers(id);
  const form = useForm<MerchantSpacesUsersFormValues>();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<MerchantSpacesUsers>();

  useEffect(() => {
    form.reset({
      firstName: merchantSpacesUsers?.firstName || '',
      lastName: merchantSpacesUsers?.lastName || '',
      role: merchantSpacesUsers?.role || '',
      email: merchantSpacesUsers?.email || '',
      phoneNumber: merchantSpacesUsers?.phoneNumber || '',
      active: merchantSpacesUsers?.active || false,
      merchantSpaceId: merchantSpacesUsers?.merchantSpaceId,
    });
  }, [isLoadingMerchantSpacesUsers]);

  const { mutate: editMerchantSpacesUsers } = useMutation(
    ({ id, data }: { id: string; data: EditMerchantSpacesUsersParams }) =>
      editMerchantSpacesUsersRequest(id, data),
  );

  const onSubmit = (values: MerchantSpacesUsersFormValues) => {
    const sites = values.sites?.filter((site) => site.active)?.map((site) => site.id);

    editMerchantSpacesUsers(
      {
        id: merchantSpacesUsers?.id || '',
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
          email: values.email,
          phoneNumber: values.phoneNumber,
          active: values.active,
          merchantSpaceId: values.merchantSpaceId,
          password: values.password,
          sites,
        },
      },
      {
        onSuccess: () => {
          notify('Пользователь мерчанта успешно отредактирован!', { type: 'success' });
          redirect('/merchant_spaces_users');
        },
        onError: (reason: any) => {
          reason.data.errors.forEach((error: any) => {
            if (error.code === 'validation_error') {
              form.setError(error.source.parameter, { type: 'string', message: error.title });
            } else {
              notify(error.title, { type: 'error' });
            }
          });
        },
      },
    );
  };

  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: 'Редактирование пользователя мерчантов',
          listName: 'Пользователи мерчантов',
        }}
      />
      <Card
        sx={{
          width: '600px',
          border: '1px solid rgba(0, 0, 0, 0.08)',
        }}
      >
        <CardHeader title="Редактирование пользователя мерчантов" />
        <RecordContextProvider value={merchantSpacesUsers}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <MerchantSpacesUsersEditForm />
              <CardActionsButtons
                leftActionsSlot={[
                  <SaveButton key="save-button" label="Сохранить" />,
                  <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
                ]}
                rightActionsSlot={
                  <DeleteButton
                    onClick={() => handleOpenRecordModal(merchantSpacesUsers)}
                    variant="outlined"
                  />
                }
              />
            </form>
          </FormProvider>
        </RecordContextProvider>
      </Card>
      <DeleteDialog
        details={`Пользователь ${merchantSpacesUsers?.firstName + ' ' + merchantSpacesUsers?.lastName || ''} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => redirect('/merchant_spaces_users')}
        open={openRecordModal}
        record={merchantSpacesUsers}
        title="Удаление пользователя"
      />
    </Box>
  );
};
