import { getJsonSchema } from 'config/requests';
import ReactJsonViewCompare from 'react-json-view-compare';
import { useQuery } from 'react-query';
import { Box } from 'shared/mui/Box';
import { Card, CardHeader } from 'shared/mui/Card';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { UserAction } from 'types/userAction';

import { StyledDrawer, StyledJsonViewWrapper } from './UserActionJsonSchema.styled';

type Props = {
  record?: UserAction;
  onClose: () => void;
  open: boolean;
};

export const UserActionJsonSchema: React.FC<Props> = ({ record, onClose, open }) => {
  if (!record) {
    return <></>;
  }
  const { data, isLoading } = useQuery(['schema', record?.originId], () =>
    getJsonSchema(record?.originId),
  );

  return (
    <StyledDrawer anchor="right" onClose={onClose} open={open}>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title={`Json схема события: #${record?.originId}`}
        />
        <StyledJsonViewWrapper>
          {isLoading ? (
            <Box alignItems="center" display="flex" height="100%" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <ReactJsonViewCompare newData={data?.newValue || {}} oldData={data?.oldValue || {}} />
          )}
        </StyledJsonViewWrapper>
      </Card>
    </StyledDrawer>
  );
};
