import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Switch } from 'shared/mui/Switch';
import { DatagridFieldsArrayBody } from 'shared/react-admin/DatagridBodies/DatagridFieldsArrayBody/DatagridFieldsArrayBody';
import { DatagridFieldsArrayRowContextConsumer } from 'shared/react-admin/DatagridRows/DatagridFieldsArrayRow/DatagridFieldsArrayRowContextConsumer';
import { Datagrid } from 'shared/react-admin/Datagrids/Datagrid/Datagrid';
import { TextField } from 'shared/react-admin/Fields';

import { CompanyMerchantsDatagridBulkActionButtons } from './CompanyMerchantsDatagridBulkActionButtons';
import { MerchantSpacesFormValues } from '../../MerchantSpacesCreate/MerchantSpacesCreate';

type Props = {
  fields: FieldArrayWithId<MerchantSpacesFormValues, 'sites', 'id'>[];
};

export const CompanyMerchantsDatagrid: React.FC<Props> = ({ fields }) => {
  const { control } = useFormContext<MerchantSpacesFormValues>();

  return (
    <Datagrid
      body={<DatagridFieldsArrayBody fields={fields} />}
      bulkActionButtons={<CompanyMerchantsDatagridBulkActionButtons />}
      sx={{
        '& .RaDatagrid-headerCell:nth-child(2)': { width: 90 },
      }}
    >
      <TextField label="ID" sortBy="id" source="originId" />
      <DatagridFieldsArrayRowContextConsumer
        label="Доступен"
        render={({ rowIndex }) => {
          return (
            <Controller
              control={control}
              defaultValue={false}
              name={`sites.${rowIndex}.active`}
              render={({ field: { onChange, value } }) => {
                return <Switch checked={value} onChange={onChange} />;
              }}
            />
          );
        }}
      />
      <TextField label="Название" source="name" />
    </Datagrid>
  );
};
