import { UNLIMITED_PAGINATION_PARAMS } from 'constants/unlimitedPaginationParams';

import { ListContextProvider, useGetList, useList, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { MappingItemRecord } from 'types';

import { MappingCardDatagridEmptyStyled } from './EditPayoutControlMappingFieldCard.styled';
import { EditPayoutControlMappingFieldCardHeader } from './EditPayoutControlMappingFieldCardHeader/EditPayoutControlMappingFieldCardHeader';

type Props = {
  children?: React.ReactNode;
  onChoice?: () => void;
  onReplace?: () => void;
  onDelete?: () => void;
};

export const EditPayoutControlMappingFieldCard: React.FC<Props> = ({
  children,
  onChoice,
  onDelete,
  onReplace,
}) => {
  const mappingRecord = useRecordContext<MappingItemRecord>();

  const { data: mappingItems, isLoading } = useGetList<MappingItemRecord>('mapping_items', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
    filter: { mapping: mappingRecord?.id },
  });
  const listContext = useList({ data: mappingItems || [] });

  return (
    <ListContextProvider value={listContext}>
      <Box mb={3} sx={{ width: '100%' }}>
        <EditPayoutControlMappingFieldCardHeader
          isLoading={isLoading}
          mapping={mappingRecord}
          onChoice={onChoice}
          onDelete={onDelete}
          onReplace={onReplace}
        />
        <Datagrid
          bulkActionButtons={false}
          empty={
            <MappingCardDatagridEmptyStyled>Шлюзы не добавлены</MappingCardDatagridEmptyStyled>
          }
          isLoading={isLoading}
          sx={{
            borderRadius: '4px',
            '& .MuiTableCell-root': { width: '850px', padding: '16px' },
            '& .MuiTableCell-root:last-child': { width: '65px' },
          }}
        >
          {children}
        </Datagrid>
      </Box>
    </ListContextProvider>
  );
};
