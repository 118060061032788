import { FilterStyled } from './Filter.styled';
import { Box, BoxProps } from '../../Box';

export type FilterProps = { alert?: React.ReactElement | undefined } & BoxProps;

export const Filter: React.FC<FilterProps> = ({ children, alert, ...restProps }) => {
  return (
    <FilterStyled>
      <Box {...restProps}>{children}</Box>
      {alert}
    </FilterStyled>
  );
};
