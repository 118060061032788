import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': getColumnStyle(168),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
  '& .column-filePath': { width: '100%' },
});
