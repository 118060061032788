import { useEffect, useState } from 'react';

import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useNotify, useEditController } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditDialog } from 'shared/react-admin/Dialogs/EditDialog/EditDialog';
import { MethodLogo, Permission } from 'types';
import { getId } from 'utils';

import { MethodLogosEditForm } from './MethodLogosEditForm';
import { editLogo } from '../../../config/requests';
import { MethodLogosDeleteDialog } from '../components/MethodLogosDeleteDialog/MethodLogosDeleteDialog';

type MethodLogosEditFormValues = {
  file?: FileList;
  filePath: string;
  name: string;
};

export const MethodLogosEdit: React.FC = () => {
  const notify = useNotify();
  const { goBack } = useGoBack();
  const { hasResourcePermissions } = usePermissions();

  const [errors, setErrors] = useState<any>();
  const [isShowImage, setIsShowImage] = useState(false);
  const form = useForm({
    defaultValues: {
      name: '',
      filePath: '',
      file: undefined,
    },
  });

  const { id: currentPath } = useParams();
  const id = getId(currentPath);
  const { record } = useEditController();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<MethodLogo>();

  useEffect(() => {
    if (record?.name) {
      form.setValue('name', record.name);
      form.setValue('filePath', record.filePath);
    }
  }, [record]);

  const submitForm = (data: MethodLogosEditFormValues) => {
    const formData = new FormData();
    if (data?.file?.[0]) {
      formData.append('file', data.file[0]);
    }
    formData.append('filePath', data.filePath);
    formData.append('name', data.name);

    editLogo(id?.toString(), formData)
      .then(() => {
        notify('Логотип изменен!', { type: 'info' });
        goBack();
        queryClient.invalidateQueries(['method_logos']);
      })
      .catch((e) => {
        setErrors(e?.data);
      });
  };

  return (
    <>
      <EditDialog open={!openRecordModal} title="Редактирование логотипа">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitForm)}>
            <MethodLogosEditForm errors={errors} setIsShowImage={setIsShowImage} />
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton
                  disabled={!isShowImage}
                  key="method-logos-edit"
                  label="Сохранить"
                  size="medium"
                  type="submit"
                />,
                <CancelButton key="method-logos-cancel" onClick={goBack} variant="outlined" />,
              ]}
              rightActionsSlot={
                hasResourcePermissions('method_logos', [Permission.Full, Permission.Delete]) ? (
                  <DeleteButton
                    label="Удалить"
                    onClick={() => handleOpenRecordModal()}
                    variant="outlined"
                  />
                ) : undefined
              }
            />
          </form>
        </FormProvider>
      </EditDialog>
      {openRecordModal && (
        <MethodLogosDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </>
  );
};
