import { queryClient } from 'App';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { GatewayConversionListItem } from 'types/orderConversion';

import { useDeleteGatewayConversionSettings } from '../../hooks/useDeleteGatewayConversionSettings';

type Props = {
  onClose: VoidFunction;
  onCancel: (record: GatewayConversionListItem | undefined) => void;
  open: boolean;
  record: GatewayConversionListItem | undefined;
};

export const OrderConversionListGatewayDelete: React.FC<Props> = ({
  onClose,
  onCancel,
  open,
  record,
}) => {
  const { deleteGatewayConversionSettings } = useDeleteGatewayConversionSettings();

  async function onDelete() {
    if (!record?.gatewayConversionSettingId) {
      return;
    }

    await deleteGatewayConversionSettings.mutateAsync({
      id: record?.gatewayConversionSettingId,
    });

    onClose();
    await queryClient.refetchQueries(['monitoring/order_conversion']);
  }

  return (
    <DeleteDialog
      details={`Настройки уведомлений для ${record?.id} ${record?.name} будет удалены без возможности восстановления.`}
      isLoading={deleteGatewayConversionSettings.isLoading}
      onCancel={() => {
        onCancel(record);
      }}
      onClose={onClose}
      onDelete={onDelete}
      onSuccess={onClose}
      open={open}
      title="Удаление настроек уведомления для шлюза"
    />
  );
};
