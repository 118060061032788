import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const LegendWrapperStyled = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '16px',
});

export const LegendItemStyled = styled(Box)({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  fontSize: '14px',
});

export const LegendItemDotStyled = styled(Box)({
  width: '16px',
  height: '16px',
  backgroundColor: 'grey',
});
