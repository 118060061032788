import { useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { queryClient } from 'App';
import { merchantPayoutControlsUpdateActivity } from 'config/requests';
import { usePermissions } from 'hooks/usePermissions';
import { useNotify, useRecordContext } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { IconButton } from 'shared/mui/IconButtons';
import { PageHeader } from 'shared/mui/PageHeader';
import { Switch } from 'shared/mui/Switch';
import { TypographyTitle } from 'shared/mui/Typography';
import { Merchant, Permission } from 'types';

import { EditPayoutControlManageActionsButton } from './EditPayoutControlManageActionsButton/EditPayoutControlManageActionsButton';
import { EditPayoutControlManageAlert } from './EditPayoutControlManageAlert/EditPayoutControlManageAlert';
import { EditPayoutControlManageDeleteDialog } from './EditPayoutControlManageDeleteDialog/EditPayoutControlManageDeleteDialog';
import { EditPayoutControlManageSwitchOffDialog } from './EditPayoutControlManageSwitchOffDialog/EditPayoutControlManageSwitchOffDialog';
import { EditPayoutControlManageSwitchOnDialog } from './EditPayoutControlManageSwitchOnDialog/EditPayoutControlManageSwitchOnDialog';
import { useEditPayoutControlContext } from '../context/EditPayoutControlContext';

export const EditPayoutControlManage: React.FC = () => {
  const record = useRecordContext<Merchant>();
  const notify = useNotify();
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const {
    payoutControl: { active, id },
  } = useEditPayoutControlContext();

  const [isActive, setIsActive] = useState(active);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSwitchOnModal, setIsShowSwitchOnModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const toggleShowAlert = () => setIsShowAlert((prev) => !prev);

  const toggleMerchantPayoutControls = (active: boolean) => {
    setIsLoading(true);
    merchantPayoutControlsUpdateActivity(active, id)
      .then(() => {
        queryClient.refetchQueries(['merchant_payout_controls', record?.originId]);
        setIsActive(active);
        notify('Успешно', { type: 'success' });
        setIsShowSwitchOnModal(false);
      })
      .catch((err) =>
        notify(err.data.detail || 'Что-то пошло не так. Повторите запрос позже.', {
          type: 'error',
        }),
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <PageHeader
        actions={[
          hasResourceAccess('user_actions') && (
            <EditPayoutControlManageActionsButton entityId={id} key="merchant-actions" />
          ),
          hasResourcePermissions('merchant_payout_controls', [
            Permission.Full,
            Permission.Delete,
          ]) && (
            <Button
              color="error"
              key="delete-control"
              label="удалить контроль"
              onClick={() => setIsShowDeleteModal(true)}
              size="small"
              variant="outlined"
            />
          ),
        ]}
        actionsPosition="center"
        description="При включении будет распространяться на все правила созданные для мерчанта!"
        headerTitle={
          <>
            {hasResourcePermissions('merchant_payout_controls', [
              Permission.Full,
              Permission.Patch,
            ]) && <Switch checked={isActive} onClick={() => setIsShowSwitchOnModal(true)} />}
            <TypographyTitle mr={1} size="medium">
              Контроль выводов
            </TypographyTitle>
            <IconButton onClick={toggleShowAlert}>
              <InfoIcon color="disabled" />
            </IconButton>
          </>
        }
      />
      {isShowAlert && (
        <EditPayoutControlManageAlert onClose={toggleShowAlert} sx={{ marginBottom: '24px' }} />
      )}
      {!isActive && isShowSwitchOnModal && (
        <EditPayoutControlManageSwitchOnDialog
          isLoading={isLoading}
          merchantName={record?.name}
          onClose={() => setIsShowSwitchOnModal(false)}
          onSubmit={() => toggleMerchantPayoutControls(true)}
          open={!isActive && isShowSwitchOnModal}
        />
      )}
      {isActive && isShowSwitchOnModal && (
        <EditPayoutControlManageSwitchOffDialog
          isLoading={isLoading}
          merchantName={record?.name}
          onClose={() => setIsShowSwitchOnModal(false)}
          onSubmit={() => toggleMerchantPayoutControls(false)}
          open={isActive && isShowSwitchOnModal}
        />
      )}
      {isShowDeleteModal && (
        <EditPayoutControlManageDeleteDialog
          onClose={() => setIsShowDeleteModal(false)}
          open={isShowDeleteModal}
          payoutControlId={id}
          record={record}
        />
      )}
    </>
  );
};
