import React from 'react';

import { useRedirect } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { BaseDialog, DialogProps } from 'shared/mui/Dialogs';

export const MerchantSpacesUsersInviteRedirectDialog = ({ open, onClose }: DialogProps) => {
  const redirect = useRedirect();

  return (
    <BaseDialog
      message="Вы можете отслеживать статус приглашений в списке пользователей мерчантов."
      onClose={onClose}
      open={open}
      rightActionsSlot={
        <Button
          label="Перейти к списку пользователей"
          onClick={() => redirect('/merchant_spaces_users')}
          variant="outlined"
        />
      }
      title="Приглашение отправлено"
    />
  );
};
