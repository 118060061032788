import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { useGetMany } from 'react-admin';
import { FieldValues } from 'react-hook-form';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { getResourceFromId } from '../../../../../utils';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
  onDelete: (source: string, id: string) => void;
} & Omit<ChipProps, 'onDelete'>;

export function FilterChipsIdArrayField<TFilterValues extends FieldValues>({
  chipValue,
  onDelete,
  ...restProps
}: Props<TFilterValues>) {
  const { data } = useGetMany(getResourceFromId(chipValue.value[0]), {
    ids: chipValue.value,
  });

  if (!chipValue.value) {
    return <></>;
  }

  return (
    <>
      {data?.map((record) => (
        <Chip
          deleteIcon={<CancelIcon />}
          key={record.id}
          label={`${chipValue.label}: ${record?.name}`}
          onDelete={() => onDelete(chipValue.source, record.id)}
          {...restProps}
        />
      ))}
    </>
  );
}
