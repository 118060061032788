import { DEFAULT_NO_LIST_PAGINATION } from 'constants/defaultPagination';

import { usePermissions } from 'hooks/usePermissions';
import { useGetList, useShowController } from 'react-admin';
import { ShowPageTabbed } from 'shared/react-admin/Pages';
import { Tab } from 'shared/react-admin/Tab/Tab';
import { Merchant } from 'types';

import { MerchantsShowAccessList } from './MerchantShowAccess/MerchantShowAccessList';
import { MerchantShowAccounts } from './MerchantShowAccounts/MerchantShowAccounts';
import { MerchantShowCommissionsList } from './MerchantShowCommissions/MerchantShowCommissionsList';
import { MerchantShowRulesList } from './MerchantShowRules/MerchantShowRulesList';
import { MerchantsShowCommonInformation } from './MerchantsShowCommonInformation/MerchantsShowCommonInformation';
import { MerchantsShowCredentials } from './MerchantsShowCredentials/MerchantsShowCredentials';
import { useGetMerchantNotifications } from './MerchantsShowNotifications/hooks/useGetMerchantNotifications';
import { MerchantsShowNotifications } from './MerchantsShowNotifications/MerchantsShowNotifications';
import { MerchantsShowPayoutControls } from './MerchantsShowPayoutControls/MerchantsShowPayoutControls';

export const MerchantsShow = () => {
  const { record } = useShowController<Merchant>();
  const { hasResourceAccess } = usePermissions();

  const { total: totalMerchantRules } = useGetList(
    `merchants/${record?.originId}/gateway_rules`,
    {
      filter: {
        ...DEFAULT_NO_LIST_PAGINATION,
      },
    },
    {
      enabled: !!record?.originId,
    },
  );

  const { total: totalMerchantCredentials } = useGetList('merchant_credentials', {
    filter: {
      ...DEFAULT_NO_LIST_PAGINATION,
      merchant: record?.id,
    },
  });

  const { total: totalMerchantAccounts } = useGetList(
    'merchant_accounts',
    {
      filter: {
        page: 1,
        itemsPerPage: 0,
        merchants: [record?.id],
      },
    },
    { enabled: !!record },
  );

  const { total: totalMerchantAccesses } = useGetList(
    `merchants/${record?.originId}/merchant_accesses`,
    {
      filter: {
        page: 1,
        itemsPerPage: 0,
      },
    },
    { enabled: !!record },
  );

  const { total: totalMerchantCommissions } = useGetList('commissions', {
    filter: {
      ...DEFAULT_NO_LIST_PAGINATION,
      merchant: record?.id,
    },
  });

  const { totalItems: totalNotificationsItems } = useGetMerchantNotifications({
    merchantId: record?.id,
  });

  return (
    <ShowPageTabbed
      headerTitle={{ titleText: record?.name }}
      listName="Мерчанты"
      title="Управление мерчантом"
    >
      <Tab label="Общая информация">
        <MerchantsShowCommonInformation record={record} />
      </Tab>
      {hasResourceAccess('merchant_credentials') && (
        <Tab label={`Реквизиты (${totalMerchantCredentials || 0})`}>
          <MerchantsShowCredentials merchantId={record?.id} merchantName={record?.name} />
        </Tab>
      )}
      {hasResourceAccess('merchant_accounts') && (
        <Tab label={`Счета (${totalMerchantAccounts || 0})`}>
          <MerchantShowAccounts merchantId={record?.id} merchantName={record?.name} />
        </Tab>
      )}
      {hasResourceAccess('commissions') && (
        <Tab label={`Комиссии (${totalMerchantCommissions || 0})`}>
          <MerchantShowCommissionsList merchantId={record?.id} merchantName={record?.name} />
        </Tab>
      )}
      {hasResourceAccess('gateway_rules') && (
        <Tab label={`Правила (${totalMerchantRules || 0})`}>
          <MerchantShowRulesList merchantId={record?.originId} merchantName={record?.name} />
        </Tab>
      )}
      {hasResourceAccess('merchant_payout_controls') && (
        <Tab label="Контроль выводов">
          <MerchantsShowPayoutControls record={record} />
        </Tab>
      )}
      {hasResourceAccess('merchant_notification_settings') && (
        <Tab label={`Уведомления (${totalNotificationsItems || 0})`}>
          <MerchantsShowNotifications record={record} />
        </Tab>
      )}
      {hasResourceAccess('merchant_accesses') && (
        <Tab label={`Доступы (${totalMerchantAccesses || 0})`}>
          <MerchantsShowAccessList merchantId={record?.id} merchantName={record?.name} />
        </Tab>
      )}
    </ShowPageTabbed>
  );
};
