import { CAPTCHA_PUBLIC_KEY } from 'config/entrypoint';

type SubmitFunctionWithData<T> = (captchaToken: string, submitData: T) => void;

export const executeCaptcha = (submit: (captchaToken: string) => void): void => {
  window.grecaptcha.execute(CAPTCHA_PUBLIC_KEY, { action: 'submit' }).then((captchaToken) => {
    submit(captchaToken);
  });
};

export const executeCaptchaWithData = <T>(submit: SubmitFunctionWithData<T>, data: T): void => {
  window.grecaptcha.execute(CAPTCHA_PUBLIC_KEY, { action: 'submit' }).then((captchaToken) => {
    submit(captchaToken, data);
  });
};
