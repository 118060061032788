import { DefaultValues, FieldValues } from 'react-hook-form';

import { ChipsStyled, WrapperStyled } from './FilterChipsToolbar.styled';
import { cleanEmpty } from '../../../../utils/cleanEmpty';
import { BoxProps } from '../../Box';
import { Divider } from '../../Divider/Divider';
import { FilterIdsValues } from '../hooks/useIdFilters';

export type FilterChipsToolbarProps<TFieldValues extends FieldValues> = {
  appliedFilters?: TFieldValues | DefaultValues<TFieldValues>;
  appliedIdFilters?: FilterIdsValues;
} & BoxProps;

export function FilterChipsToolbar<TFieldValues extends FieldValues>({
  children,
  appliedFilters,
  appliedIdFilters = {
    merchantReference: [],
    id: [],
    providerReferenceId: [],
  },
  ...restProps
}: FilterChipsToolbarProps<TFieldValues>) {
  if (
    (!appliedFilters || !Object.values(cleanEmpty(appliedFilters)).length) &&
    (!appliedIdFilters || !Object.values(cleanEmpty(appliedIdFilters)).length)
  ) {
    return;
  }

  return (
    <WrapperStyled {...restProps}>
      <Divider />
      <ChipsStyled>{children}</ChipsStyled>
    </WrapperStyled>
  );
}
