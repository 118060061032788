import { Children, isValidElement, ReactNode, useMemo } from 'react';

export function useExistingChildren(
  children: ReactNode,
  existingFields: string[] | undefined,
): ReactNode {
  return useMemo(
    () =>
      Children.toArray(children)?.filter((child) => {
        if (isValidElement(child)) {
          if (!child.props.source) {
            return true;
          }
          return existingFields?.includes(child?.props?.source);
        }
      }),
    [children, existingFields],
  );
}
