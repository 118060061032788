import { TableBody, TableProps, TableRowWrapper } from 'shared/mui/Table';
import { LinkTextButton } from 'shared/react-admin/Buttons';
import { DateTimeField, FunctionField, TextField } from 'shared/react-admin/Fields';
import { Company } from 'types';

import { StyledTable } from './CompanyShowDialog.styled';
import { CompanyShowDialogConditionChip } from './components/CompanyShowDialogConditionChip/CompanyShowDialogConditionChip';

const dateStyle = { padding: '0 16px' };

export const CompanyShowDialogTable = ({ sx }: TableProps) => (
  <StyledTable sx={sx}>
    <TableBody>
      <TableRowWrapper title="ID:">
        <TextField source="originId" />
      </TableRowWrapper>
      <TableRowWrapper title="Название:">
        <TextField source="name" />
      </TableRowWrapper>
      <TableRowWrapper title="Состояние:">
        <FunctionField
          render={({ archive }: Company) => <CompanyShowDialogConditionChip archive={archive} />}
          source="archive"
        />
      </TableRowWrapper>
      <TableRowWrapper title="Кол-во мерчантов:">
        <FunctionField
          render={({ merchantCount, id }: Company) => (
            <LinkTextButton
              filter={{ companies: id }}
              label={String(merchantCount)}
              pathname="/merchants"
            />
          )}
          source="merchantCount"
        />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Дата создания:">
        <DateTimeField source="createdAt" />
      </TableRowWrapper>
      <TableRowWrapper contentStyle={dateStyle} title="Дата редактир:">
        <DateTimeField source="updatedAt" />
      </TableRowWrapper>
    </TableBody>
  </StyledTable>
);
