import { Typography, TypographyProps } from '@mui/material';

type AppTypographySize = 'large' | 'medium' | 'small';

type Props = TypographyProps & {
  size?: AppTypographySize;
};

export function AppTypography({ children, size, ...restProps }: Props): JSX.Element {
  if (size === 'large') {
    return (
      <Typography
        {...{ ...restProps, component: 'h1', variant: 'h6', fontWeight: '400', fontSize: '28px' }}
      >
        {children}
      </Typography>
    );
  }

  if (size === 'medium') {
    return (
      <Typography
        {...{
          ...restProps,
          component: 'h2',
          variant: 'h6',
          fontSize: '24px',
          fontWeight: '400',
          lineHeight: 1,
        }}
      >
        {children}
      </Typography>
    );
  }

  if (size === 'small') {
    return (
      <Typography
        {...{
          ...restProps,
          component: 'h2',
          variant: 'h6',
          fontSize: '20px',
          fontWeight: '400',
          lineHeight: 1,
        }}
      >
        {children}
      </Typography>
    );
  }

  return <Typography {...restProps}>{children}</Typography>;
}
