import { AxiosError, AxiosResponse } from 'axios';
import { changeMerchantSpaceActivity, ChangeMerchantSpaceActivityParams } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation, UseMutationResult } from 'react-query';

type UseChangeMerchantSpaceActivityReturnValue = {
  changeMerchantSpaceActivityMutation: UseMutationResult<
    AxiosResponse,
    unknown,
    ChangeMerchantSpaceActivityParams,
    unknown
  >;
};

export function useChangeMarchantSpaceActivity(
  refetchMerchantSpaces: () => void,
): UseChangeMerchantSpaceActivityReturnValue {
  const notify = useNotify();

  const mutation = useMutation(
    (data: ChangeMerchantSpaceActivityParams) => changeMerchantSpaceActivity(data),
    {
      onSuccess: async () => {
        notify('Активность успешно изменена', { type: 'success' });
        refetchMerchantSpaces();
      },
      onError: (e) => {
        const error = e as AxiosError;
        notify(`Ошибка: ${error.message}`, { type: 'error' });
      },
    },
  );

  return { changeMerchantSpaceActivityMutation: mutation };
}
