import { Box } from 'shared/mui/Box';

import { CreateDialogStyled } from './CreateDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';
import { Create, CreateProps } from '../../Create/Create';

export type CreateDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  title: string;
} & CreateProps;

export const CreateDialog: React.FC<CreateDialogProps> = ({
  actions,
  title,
  children,
  ...rest
}) => {
  const { goBack } = useGoBack();

  return (
    <CreateDialogStyled open={true}>
      <Create sx={{ margin: 0 }} {...rest}>
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </Create>
    </CreateDialogStyled>
  );
};
