import { Box, BoxProps } from '@mui/material';

type Props = BoxProps;

export function AppPanelHeader({ children, pb = 2, ...restProps }: Props): JSX.Element {
  return (
    <Box {...restProps} pb={pb}>
      {children}
    </Box>
  );
}
