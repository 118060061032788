import { FilterChipType } from 'constants/filterChipType';

import { useFilterChips } from 'hooks/useFilterChips';
import { useFilters } from 'hooks/useFilters';
import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

type MerchantsShowAccountsFilterValues = {
  name: string;
  provider: string;
  currency: string;
  gateways: string[];
};

const INITIAL_VALUES = {
  name: '',
  provider: '',
  currency: '',
  gateways: [],
};

export const MerchantShowAccountsFilters = () => {
  const { filterValues } = useListContext();
  const { form, resetFilter, onSubmit, resetFilterBySource, resetFilterArrayById } =
    useFilters<MerchantsShowAccountsFilterValues>(INITIAL_VALUES, {
      ...filterValues,
      name: filterValues.name || '',
    });

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'name', label: 'Название', type: FilterChipType.String },
      { source: 'provider', label: 'Провайдер', type: FilterChipType.Id },
      { source: 'currency', label: 'Валюта', type: FilterChipType.IdArray },
      { source: 'gateways', label: 'Шлюз', type: FilterChipType.IdArray },
    ],
    appliedFilters: filterValues,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilter} />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById as any}
          resetFilterBySource={resetFilterBySource as any}
        >
          <FilterRow>
            <TextInputShrink
              InputLabelProps={{ shrink: true }}
              helperText={false}
              key="name"
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <ReferenceInput
              isFilter={true}
              name="provider"
              perPage={200}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              isFilter={true}
              name="gateways"
              perPage={200}
              reference="gateways"
              source="gateways"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                name="gateways"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              isFilter={true}
              name="currency"
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                name="currency"
                optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                variant="outlined"
              />
            </ReferenceArrayInput>
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
