import ContentCreate from '@mui/icons-material/Create';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { IconButton, IconButtonProps } from '..';

export type EditLinkIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function EditLinkIconButton(props: EditLinkIconButtonProps): JSX.Element {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  return (
    <IconButton
      component={Link}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record.id)}/edit`,
      }}
      {...props}
    >
      <ContentCreate fontSize="inherit" />
    </IconButton>
  );
}
