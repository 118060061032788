export const enum OrderStatuses {
  Created = 'created',
  Pending = 'pending',
  Failure = 'failure',
  Success = 'success',
  Refund = 'refund',
  Expired = 'expired',
  Chargeback = 'chargeback',
  Prepared = 'prepared',
  Cancelled = 'cancelled',
  WaitingConfirmation = 'waiting_confirmation',
}
