import { ReactElement } from 'react';

import { RecordContextProvider } from 'react-admin';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { Gateway, Order } from '../../../types';

type Props = {
  label?: string | ReactElement | boolean;
};

export const OrderAdjustmentsReportProviderField: React.FC<Props> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={() => {
        return (
          <ReferenceOneField
            label="Провайдер"
            link={false}
            reference="orders"
            sortable={false}
            source="order"
            target="id"
          >
            <FunctionField
              render={(record: Order) => {
                return (
                  <RecordContextProvider value={record}>
                    <ReferenceField link={false} reference="gateways" source="gateway">
                      <FunctionField
                        render={(record: Gateway) => {
                          return (
                            <RecordContextProvider value={record}>
                              <ReferenceField link={false} reference="providers" source="provider">
                                <TextField source="name" />
                              </ReferenceField>
                            </RecordContextProvider>
                          );
                        }}
                        source="provider"
                      />
                    </ReferenceField>
                  </RecordContextProvider>
                );
              }}
            />
          </ReferenceOneField>
        );
      }}
      source="provider"
    />
  );
};
