import React from 'react';

import { FormControlLabel } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Switch } from 'shared/mui/Switch';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { ConversionItem } from 'types/orderConversion';

import {
  ChartWrapperStyled,
  TitleWrapperStyled,
  WrapperStyled,
} from './OrderConversionListConversion.styled';
import { ConversionLegend } from '../components/ConversionLegend/ConversionLegend';
import { defaultOptions } from '../config/defaultOptions';
import { CONVERSION_COLORS } from '../constants/conversionColors';
import { TRANSLATE_LABELS } from '../constants/translateLabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
);

const labels = ['Conversion'];

type Props = {
  items: ConversionItem[];
  autoRefresh: boolean;
  onChangeAutoRefresh: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const OrderConversionListConversion: React.FC<Props> = ({
  items,
  autoRefresh,
  onChangeAutoRefresh,
}) => {
  const datasets = items.map((item) => {
    return {
      label: TRANSLATE_LABELS[item.label],
      data: [item.value],
      backgroundColor: CONVERSION_COLORS[item.label],
    };
  });

  const data: ChartData<'bar', number[], string> = {
    labels,
    datasets,
  };

  return (
    <WrapperStyled>
      <TitleWrapperStyled>
        <Typography component="h2" variant="h5">
          Конверсия{' '}
          <TooltipWithInfoIcon
            iconSize={24}
            tooltipText="Значение общей конверсии по всем выбранным шлюзам в фильтре."
          />
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={autoRefresh} name="autoRefresh" onChange={onChangeAutoRefresh} />
          }
          label="Автообновление"
        />
      </TitleWrapperStyled>
      <ChartWrapperStyled>
        <Bar data={data} options={defaultOptions} />
      </ChartWrapperStyled>
      <ConversionLegend data={data} />
    </WrapperStyled>
  );
};
