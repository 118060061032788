import FilterListIcon from '@mui/icons-material/FilterList';

import { Button, ButtonProps } from '../../../Buttons';

export type FilterButtonProps = ButtonProps;

export const FilterButton: React.FC<FilterButtonProps> = ({
  label = 'Фильтр',
  color = 'secondary',
  ...restProps
}) => {
  return <Button color={color} label={label} startIcon={<FilterListIcon />} {...restProps} />;
};
