import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { ExpressionParam } from 'types';
import { getId } from 'utils';

import { StyledExpressionFormControl } from './GatewayRulesFormRuleExpression.styled';

type Props<T extends FieldValues> = {
  name: Path<T>;
  expressionParams: ExpressionParam[];
};

export function GatewayRulesFormRuleExpressionParams<T extends FieldValues>({
  name,
  expressionParams,
}: Props<T>): JSX.Element {
  const { control } = useFormContext();

  function getCurrentExpressionParam(id: string): ExpressionParam | undefined {
    return expressionParams.find((param) => getId(param.id) === `${id}`);
  }

  return (
    <StyledExpressionFormControl>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Select
              variant="outlined"
              {...field}
              value={getCurrentExpressionParam((field.value as ExpressionParam)?.id) || ''}
            >
              {expressionParams.map((param) => {
                return (
                  <MenuItem key={param.id} value={param as any}>
                    {param.name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
      />
    </StyledExpressionFormControl>
  );
}
