import { green, orange } from '@mui/material/colors';
import { Chip } from 'shared/mui/Chips';

export type ArchiveConditionListChipProps = {
  archive: boolean;
  archiveLabel?: string;
  activeLabel?: string;
};

export const ArchiveConditionListChip: React.FC<ArchiveConditionListChipProps> = ({
  archive,
  archiveLabel = 'В архиве',
  activeLabel = 'Активен',
}) => (
  <Chip
    color="primary"
    label={archive ? archiveLabel : activeLabel}
    size="small"
    sx={{
      background: archive ? orange[800] : green[800],
    }}
  />
);
