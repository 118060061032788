import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useRecordContext } from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { User, WorkTeam } from 'types';

export const ProviderAccountsManagerField: React.FC = () => {
  const record = useRecordContext<WorkTeam>();
  const { data: user, isLoading } = useGetOne<User>('users', { id: record.manager.toString() });

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!user?.firstName && !user?.lastName) {
    return <>{EMPTY_FIELD}</>;
  }

  return <>{`${user?.firstName} ${user?.lastName}`}</>;
};
