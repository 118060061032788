import { useRef, useEffect } from 'react';

export function useFirstRender(): { isFirstRender: boolean } {
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return { isFirstRender: firstRender.current };
}
