import { CardHeaderStyled, CardStyled, FilterDrawerStyled } from './FilterDrawer.styled';
import { DrawerProps } from '../../Drawer/Drawer';
import { CloseIconButton } from '../../IconButtons';

export type FilterDrawerProps = DrawerProps;

export const FilterDrawer: React.FC<FilterDrawerProps> = ({
  anchor = 'right',
  children,
  ...restProps
}) => {
  return (
    <FilterDrawerStyled
      ModalProps={{
        keepMounted: true,
      }}
      anchor={anchor}
      disablePortal={true}
      {...restProps}
    >
      <CardStyled>
        <CardHeaderStyled
          actions={[
            <CloseIconButton
              key="close-button"
              onClose={() => {
                if (restProps.onClose) {
                  restProps.onClose({}, 'backdropClick');
                }
              }}
            />,
          ]}
          title="Фильтр"
        />
        {children}
      </CardStyled>
    </FilterDrawerStyled>
  );
};
