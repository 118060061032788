import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from 'shared/mui/Typography';

import { AlertStyled } from './EditPayoutControlMappingAddDial.styled';

export const EditPayoutControlMappingAddDialogAlert: React.FC = () => {
  return (
    <AlertStyled icon={<InfoOutlinedIcon color="primary" />} variant="filled">
      <Typography variant="body2">
        По вашему запросу нет подходящих маппингов, которые вы можете добавить в исключение.
      </Typography>
    </AlertStyled>
  );
};
