import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { GroupFieldChip } from 'shared/mui/Chips';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { ChipField, FunctionField, SingleFieldList } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { getUserName } from 'utils';

import { StyledDatagrid } from './WorkTeamsList.styled';
import { WorkTeamsListFilter } from './WorkTeamsListFilter';
import { WorkTeamsListMembersAvatars } from './WorkTeamsListMembersAvatars';
import { usePermissions } from '../../../../hooks/usePermissions';
import { AppGroup, Permission, WorkTeam } from '../../../../types';
import { WorkTeamsDeleteDialog } from '../components/WorkTeamsDeleteDialog';

export const WorkTeamsList = () => {
  const { hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<WorkTeam>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('work_teams', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal key="create-comissions" />
        ),
      ]}
      empty={false}
      headerTitle={{ titleText: 'Рабочие группы' }}
    >
      <WorkTeamsListFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <FunctionField
          label="Группа"
          render={(record: AppGroup) => <GroupFieldChip group={record} />}
        />
        <ReferenceArrayField
          label="Страны"
          reference="countries"
          sortable={false}
          source="countries"
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: WorkTeam | undefined) => (
                <ChipField color="default" record={record} source="name" />
              )}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceOneField
          label="Руководитель группы"
          reference="users"
          source="manager"
          target="id"
        >
          <FunctionField render={getUserName} />
        </ReferenceOneField>
        <ReferenceArrayField label="Участники" reference="users" sortable={false} source="members">
          <WorkTeamsListMembersAvatars />
        </ReferenceArrayField>
        <ActionsSlots
          render={(record: WorkTeam) => (
            <>
              <ShowDialogIconButton />
              {hasResourcePermissions('work_teams', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
              {hasResourcePermissions('work_teams', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <WorkTeamsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
    </ListPage>
  );
};
