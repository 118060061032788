import { BooleanField, Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { DateTimeField } from 'shared/react-admin/Fields';

import { OrdersShowTableCard } from './OrdersShowTableCard';
import { usePermissions } from '../../../hooks/usePermissions';

const OrdersShowNotifications = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <>
      {hasResourceAccess('order_notifications') && (
        <OrdersShowTableCard>
          <ReferenceManyField
            label={false}
            reference="order_notifications"
            sort={{ field: 'id', order: 'ASC' }}
            target="order"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField label="ID" source="originId" />
              <BooleanField label="Доставлено" source="delivered" />
              <TextField label="Число попыток" source="attempts" />
              <DateTimeField emptyText="—" label="Следующая попытка" source="nextAttempt" />
            </Datagrid>
          </ReferenceManyField>
        </OrdersShowTableCard>
      )}
    </>
  );
};
export default OrdersShowNotifications;
