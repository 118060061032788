import { EMPTY_FIELD } from 'constants/emptyField';

import { blue } from '@mui/material/colors';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { FunctionField, NumberField, TextField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';

import { StyledDatagrid } from './ProviderWhiteIpAddressesList.styled';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Permission, Provider } from '../../../types';
import { ProviderWhiteIpAddressesDeleteDialog } from '../components/ProviderWhiteIpAddressesDeleteDialog/ProviderWhiteIpAddressesDeleteDialog';
import { ProviderWhiteIpAddressesFilters } from '../ProviderWhiteIpAddressesFilters';

export const ProviderWhiteIpAddressesList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();
  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('provider_white_ip_addresses', [
          Permission.Full,
          Permission.Create,
        ]) && <CreateButton isLinkToModal={true} key="create-provider-white-ip" />,
      ]}
      empty={false}
      headerTitle={{ titleText: 'Белые IP-адреса' }}
    >
      <ProviderWhiteIpAddressesFilters />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <FunctionField
              render={(record: Provider | undefined) => (
                <TextField
                  onClick={() => handleOpenRecordModalProvider(record)}
                  source="name"
                  sx={{
                    cursor: 'pointer',
                    color: blue[700],
                  }}
                />
              )}
            />
          </ReferenceOneField>
        )}
        <TextField label="IP" source="ip" />
        <NumberField
          emptyText={EMPTY_FIELD}
          label="Маска"
          source="mask"
          sx={{
            textAlign: 'left!important',
          }}
        />
        <ActionsSlots
          render={(record: Provider) => (
            <>
              {hasResourceAccess('user_actions') && (
                <UserActionsIconButton entityName="provider_white_ip_address" />
              )}
              <ShowDialogIconButton />
              {hasResourcePermissions('provider_white_ip_addresses', [
                Permission.Full,
                Permission.Update,
              ]) && <EditDialogIconButton />}
              {hasResourcePermissions('provider_white_ip_addresses', [
                Permission.Full,
                Permission.Delete,
              ]) && <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <ProviderWhiteIpAddressesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
    </ListPage>
  );
};
