import { sendTestMerchantNotifications } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';

type UseCreateMerchantNotificationReturnValue = {
  isLoading: boolean;
  sendTestNotification: VoidFunction;
};

export function useSendTestNotification(
  notificationId: string,
  onSuccess: VoidFunction,
): UseCreateMerchantNotificationReturnValue {
  const notify = useNotify();

  const { mutate: sendTestNotification, isLoading } = useMutation(
    () => sendTestMerchantNotifications(notificationId),
    {
      onSuccess: () => {
        onSuccess();
        notify('Тестовое уведомление отправлено успешно!', { type: 'success' });
      },
      onError: () => {
        notify('Ошибка отправки тестового уведомления', { type: 'error' });
      },
    },
  );

  return { isLoading, sendTestNotification };
}
