import { BoxProps } from 'shared/mui/Box';

import { StyledBox } from './FilterFieldGroup.styled';

export type FilterFieldGroupProps = BoxProps;

export const FilterFieldGroup = ({
  children,
  ...restProps
}: FilterFieldGroupProps): JSX.Element => <StyledBox {...restProps}>{children}</StyledBox>;
