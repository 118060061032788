import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(155);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': columnStyle,
  '& .column-alias': columnStyle,
  '& .column-undefined': columnStyle,
  '& .column-active': getColumnStyle(100),
});
