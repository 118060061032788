import { useState } from 'react';

import { useOrdersReportFilterContext } from 'components/Finance/OrdersReport/context/ordersReportContext';
import { useFormContext } from 'react-hook-form';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { IdInputIconButton } from 'shared/mui/IconButtons';
import { IdInputShrink } from 'shared/react-admin/Inputs/IdInputShrink/IdInputShrink';
import { SearchByIdDialog } from 'shared/widgets/SearchByIdDialog/SearchByIdDialog';
import { getIdInputValidate } from 'utils';

type OrdersIdsFilterProps = {
  isIdFiltersDisabled: boolean;
  resetFilters: () => void;
};

export const OrdersReportFilterGroupById: React.FC<OrdersIdsFilterProps> = ({
  isIdFiltersDisabled,
  resetFilters,
}) => {
  const { watch } = useFormContext();
  const [currentIdFilter, setCurrentIdFilter] = useState<
    { field: string; label: string } | undefined
  >();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [id, merchantReference, providerReferenceId] = watch([
    'id',
    'merchantReference',
    'providerReferenceId',
  ]);

  const checkDisabled = (field: string | string[] | undefined): boolean => {
    if (!id && !merchantReference && !providerReferenceId) {
      return false;
    }

    return !field;
  };

  const { notFoundOrdersReportIds } = useOrdersReportFilterContext();

  return (
    <FilterRow>
      <IdInputShrink
        InputProps={{
          endAdornment: (
            <IdInputIconButton
              disabled={isIdFiltersDisabled || checkDisabled(id)}
              onClick={() => {
                setCurrentIdFilter({ field: 'id', label: 'Kubera ID' });
                setIsDialogOpen(true);
              }}
            />
          ),
        }}
        disabled={isIdFiltersDisabled || checkDisabled(id)}
        fullWidth
        helperText={false}
        label="Kubera ID"
        name="id"
        resetFilters={resetFilters}
        resettable={true}
        source="id"
        validate={getIdInputValidate()}
        variant="outlined"
      />
      <IdInputShrink
        InputProps={{
          endAdornment: (
            <IdInputIconButton
              disabled={isIdFiltersDisabled || checkDisabled(merchantReference)}
              onClick={() => {
                setCurrentIdFilter({ field: 'merchantReference', label: 'Мерчант ID' });
                setIsDialogOpen(true);
              }}
            />
          ),
        }}
        disabled={isIdFiltersDisabled || checkDisabled(merchantReference)}
        fullWidth
        helperText={false}
        label="Мерчант ID"
        name="merchantReference"
        resetFilters={resetFilters}
        resettable={true}
        source="merchantReference"
        validate={getIdInputValidate()}
        variant="outlined"
      />
      <IdInputShrink
        InputProps={{
          endAdornment: (
            <IdInputIconButton
              disabled={isIdFiltersDisabled || checkDisabled(providerReferenceId)}
              onClick={() => {
                setCurrentIdFilter({ field: 'providerReferenceId', label: 'Провайдер ID' });
                setIsDialogOpen(true);
              }}
            />
          ),
        }}
        disabled={isIdFiltersDisabled || checkDisabled(providerReferenceId)}
        fullWidth
        helperText={false}
        label="Провайдер ID"
        name="providerReferenceId"
        resetFilters={resetFilters}
        resettable={true}
        source="providerReferenceId"
        validate={getIdInputValidate()}
        variant="outlined"
      />
      {currentIdFilter && (
        <SearchByIdDialog
          currentIdFilter={currentIdFilter}
          notFoundIds={notFoundOrdersReportIds}
          onClose={() => {
            setCurrentIdFilter(undefined);
            setIsDialogOpen(false);
          }}
          open={isDialogOpen}
          source="idDialog"
        />
      )}
    </FilterRow>
  );
};
