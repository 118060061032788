import { OrderStatuses } from 'constants/orderStatuses';

import { Chip } from '@mui/material';

import { StatusIcon } from './StatusIcon';

type Props = {
  name: string;
  alias?: OrderStatuses;
};

export const AppStatusChip = ({ alias, name }: Props): JSX.Element => {
  return <Chip icon={<StatusIcon color={alias} size="l" />} label={name} size="small" />;
};
