import React from 'react';

import { FunctionField, useTranslate } from 'react-admin';
import { MerchantSpacesUsers } from 'types';

import { appColors } from '../../../../themes/variables';
import { ColorChip } from '../../../mui/Chips/ColorChip/ColorChip';

export type InviteStatusChipFieldProps = {
  label?: string;
};

export const InviteStatusChipField: React.FC<InviteStatusChipFieldProps> = ({ label }) => {
  const translate = useTranslate();

  return (
    <FunctionField
      label={label}
      render={(record: MerchantSpacesUsers) => {
        if (!record?.inviteStatus) {
          return '—';
        }
        return (
          <ColorChip
            iconColor={appColors.inviteStatus[record?.inviteStatus]}
            label={translate(`invite_status.${record?.inviteStatus}`)}
          />
        );
      }}
    />
  );
};
