import { usePermissions } from 'hooks/usePermissions';
import { RaRecord, RecordContextProvider } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Commission, Permission } from 'types';

type Props = {
  open: boolean;
  onEdit: (record: Commission) => void;
  onDelete: (record: Commission) => void;
  onClose: () => void;
  record?: Commission;
};

const dateStyle = { padding: '0 16px' };

export const MerchantShowCommissionsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  onDelete,
  record,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <Dialog fullWidth onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <CardHeader
          actions={[
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="commission" key="user-action-link" />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр комиссии"
        />
        <CardTable>
          <TableBody>
            <TableRowWrapper headerStyle={{ width: 160 }} title="ID">
              <TextField label="Id" source="originId" />
            </TableRowWrapper>
            <TableRowWrapper title="Название">
              <TextField label="Название" source="name" />
            </TableRowWrapper>
            {hasResourceAccess('providers') && (
              <TableRowWrapper title="Провайдер">
                <ReferenceOneField
                  label="Провайдер"
                  reference="providers"
                  source="provider"
                  target="id"
                >
                  <TextField source="name" />
                </ReferenceOneField>
              </TableRowWrapper>
            )}
            {hasResourceAccess('currencies') && (
              <TableRowWrapper title="Валюта">
                <ReferenceOneField
                  label="Валюта"
                  reference="currencies"
                  source="currency"
                  target="id"
                >
                  <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
                </ReferenceOneField>
              </TableRowWrapper>
            )}
            {hasResourceAccess('transaction_types') && (
              <TableRowWrapper title="Типы транзакций">
                <ReferenceArrayField
                  label="Типы транзакций"
                  reference="transaction_types"
                  source="transactionTypes"
                >
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </TableRowWrapper>
            )}
            <TableRowWrapper title="Нулевая комиссия">
              <BooleanField label="Нулевая комиссия" source="zeroed" />
            </TableRowWrapper>
            <TableRowWrapper title="Процент">
              <NumberField emptyText="—" label="Процент" source="percent" />
            </TableRowWrapper>
            <TableRowWrapper title="Фиксированная">
              <NumberField emptyText="—" label="Фиксированная" source="fix" />
            </TableRowWrapper>
            <TableRowWrapper title="Максимальная">
              <NumberField emptyText="—" label="Максимальная" source="max" />
            </TableRowWrapper>
            <TableRowWrapper title="Минимальная">
              <NumberField emptyText="—" label="Минимальная" source="min" />
            </TableRowWrapper>
            <TableRowWrapper contentStyle={dateStyle} title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper contentStyle={dateStyle} title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  record && onEdit(record);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  record && onDelete(record);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
