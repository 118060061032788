import { styled } from '@mui/material';

export const StyledRuleList = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  borderRadius: '2px',
  '&.nested:before': {
    position: 'absolute',
    left: '-20px',
    top: '28px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '100%',
  },
  '&.nested:after': {
    position: 'absolute',
    left: '-20px',
    top: '-24px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '53px',
  },
  '&.nested.empty-tree:after': {
    display: 'none',
  },
  '& > li:last-child:before, &.nested:last-child:before,  & > li:nth-last-child(-n+2):after': {
    zIndex: 99,
    borderColor: '#bdd8f2',
  },
  '& > li:last-child .dot-expression': {
    backgroundColor: '#fff',
    border: '2px solid #bdd8f2',
  },
  '& > li:not(:nth-last-child(-n+2)):has(> .nested) > &.nested:before': {
    zIndex: 100,
    borderColor: '#1976D2',
  },
});

export const StyledRuleListItem = styled('li')({
  position: 'relative',
  padding: '16px 0 0 0',
  boxSizing: 'border-box',
  '&:after': {
    position: 'absolute',
    left: '-20px',
    bottom: '0px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '45px',
  },
  '&:before': {
    position: 'absolute',
    left: '-20px',
    top: '0px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '28px',
  },
  '&:last-child:after': {
    display: ' none',
  },
});

export const StyledRuleDot = styled('div')({
  position: 'absolute',
  width: '9px',
  height: '9px',
  borderRadius: '50%',
  backgroundColor: '#1976D2',
  left: '-24px',
  top: '28px',
  zIndex: '999',
});
