import CloseIcon from '@mui/icons-material/Close';
import { Box } from 'shared/mui/Box';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { IconButton } from 'shared/mui/IconButtons';
import { Merchant } from 'types';

type Props = {
  open: boolean;
  onClose: () => void;
  merchant: Merchant;
  onConfirm: () => void;
  originId: string;
};

export const GatewayRuleModalDelete: React.FC<Props> = ({
  merchant,
  open,
  onClose,
  onConfirm,
  originId,
}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <Box
        sx={{
          display: 'flex',
          top: '50%',
          flexDirection: 'column',
          padding: 0,
          minWidth: '600px',
        }}
      >
        <Card>
          <CardHeader
            actions={[
              <IconButton aria-label="close" key="close-button" onClick={onClose}>
                <CloseIcon />
              </IconButton>,
            ]}
            title="Удаление правила"
          />
          <CardContent>
            {`Правило ID:${originId} для мерчанта ${merchant?.name} будет удалено без возможности восстановления.`}
          </CardContent>
          <CardActionsButtons
            rightActionsSlot={[
              <Button key="cancel-button" label="Отменить" onClick={onClose} variant="outlined" />,
              <DeleteButton key="delete-button" onClick={onConfirm} />,
            ]}
          />
        </Card>
      </Box>
    </Dialog>
  );
};
