import { ReactElement } from 'react';

import {
  DatagridFieldsArrayRowContextData,
  useDatagridFieldsArrayRowContext,
} from './context/datagridFieldsArrayRowContext';

export type DatagridFieldsArrayRowContextConsumerProps = {
  render: (context: DatagridFieldsArrayRowContextData) => ReactElement | false | null;
  label?: string;
};

export const DatagridFieldsArrayRowContextConsumer = ({
  render,
}: DatagridFieldsArrayRowContextConsumerProps) =>
  render(useDatagridFieldsArrayRowContext()) || null;
