import { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import { useResourceContext } from 'react-admin';
import { fieldValidateGuesser } from 'utils';

import { Select, SelectProps } from '../Select/Select';

export type SelectIntrospectedProps<T> = SelectProps<T> & { source: string };

const SelectIntrospectedInput = ({ children, fields, validate, ...props }: any): JSX.Element => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });
  if (isError) {
    return <Fragment />;
  }
  return (
    <Select validate={guessedValidate} {...props}>
      {children}
    </Select>
  );
};

export function SelectIntrospected<T>(props: SelectIntrospectedProps<T>): JSX.Element {
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={SelectIntrospectedInput}
      includeDeprecated={true}
      resource={resource}
      {...props}
    />
  );
}
