import { useMemo } from 'react';

import { useExpanded, useStore } from 'react-admin';

type ReturnValue = {
  isExpandedCard: boolean;
  toggleExpanded: () => void;
};

export function useExpandedCard(id: string): ReturnValue {
  const [expanded, toggleExpanded] = useExpanded('gateway_rules', id);
  const [expandedAll] = useStore('gateway_rules.datagrid.view');

  const isExpandedCard = useMemo(
    () => (!expandedAll ? expanded : !expanded),
    [expanded, expandedAll],
  );

  return { isExpandedCard, toggleExpanded };
}
