import { useQuery } from 'react-query';
import { MerchantSpacesUsersSite } from 'types';

import { getMerchantsSpaceUsersSites } from '../../config/requests';

export type UseMerchantSpacesUsersSitesReturnValues = {
  merchantSpacesUsersSites: MerchantSpacesUsersSite[] | undefined;
  isLoadingMerchantSpacesUsersSites: boolean;
};

export function useMerchantSpacesUsersSites(
  id: string | undefined,
): UseMerchantSpacesUsersSitesReturnValues {
  const { data: merchantSpacesUsersSites, isLoading: isLoadingMerchantSpacesUsersSites } = useQuery(
    ['merchant_spaces_users_sites', id],
    () => getMerchantsSpaceUsersSites(id || ''),
    { enabled: !!id, cacheTime: 0 },
  );

  return { merchantSpacesUsersSites, isLoadingMerchantSpacesUsersSites };
}
