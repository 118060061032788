import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { Datagrid, FunctionField, ListContextProvider, TextField, useList } from 'react-admin';
import { DateTimeField } from 'shared/react-admin/Fields';

import { GatewayRuleInfo, OrderMethod } from '../../types';
import { AppReferenceOneField } from '../Common';
import { AppDialogHeader } from '../Common/Dialog';

type Props = {
  orderMethod: OrderMethod | undefined;
  onClose: () => void;
} & DialogProps;

const renderIcon = (ruleInfo: GatewayRuleInfo) =>
  ruleInfo.priority ? (
    <DoneIcon color="success" sx={{ fontSize: '18px' }} />
  ) : (
    <BlockIcon color="warning" sx={{ fontSize: '18px' }} />
  );

const OrderMethodsRulesShowDialog = ({ orderMethod, onClose, open }: Props): JSX.Element => {
  const context = useList({
    data: orderMethod?.gatewayRuleInfo ?? [],
    perPage: 1000,
    sort: { field: 'originId', order: 'ASC' },
  });

  if (!orderMethod) {
    return <></>;
  }

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      open={open}
      sx={{
        '.MuiPaper-root': {
          minWidth: '600px',
        },
      }}
    >
      <Box ml={3} mr={3} mt={2}>
        <AppDialogHeader mb={0} onClose={onClose} title="История подбора метода" />
      </Box>
      <ListContextProvider value={context}>
        <Datagrid
          bulkActionButtons={false}
          sx={{
            border: 'none',
            '& .MuiTableCell-root': { padding: '16px' },
            '& .MuiTableCell-root *': { lineHeight: '1' },
          }}
        >
          <TextField label="ID" source="id" />
          <DateTimeField label="Дата изменения" source="updatedAt" />
          <AppReferenceOneField
            label="Название правила"
            link="show"
            reference="gateway_rules"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
          <FunctionField label="Используется при проверке" render={renderIcon} />
        </Datagrid>
      </ListContextProvider>
      <Box m={3}>
        <Button onClick={onClose} variant="outlined">
          Закрыть
        </Button>
      </Box>
    </Dialog>
  );
};

export default OrderMethodsRulesShowDialog;
