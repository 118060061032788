import { styled } from '@mui/material/styles';
import { Card } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';

export const NotificationsPopperCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '800px',
  width: '600px',
  borderRadius: '4px',
  boxShadow: '0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F, 0px 5px 5px -3px #00000033',
});

export const NotificationsPopperCardContent = styled(CardContent)({
  flex: '1',
  overflow: 'auto',
  padding: '0 !important',
});
