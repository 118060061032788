import { ReactElement } from 'react';

import { Typography } from '@mui/material';
import get from 'lodash/get';
import {
  RaRecord,
  ReferenceOneField,
  ReferenceOneFieldProps,
  useRecordContext,
  useReferenceOneFieldController,
} from 'react-admin';

export interface AppReferenceOneFieldProps extends ReferenceOneFieldProps {
  emptyComponent?: ReactElement;
}

const AppReferenceOneField = (props: AppReferenceOneFieldProps): JSX.Element | null => {
  const { children, emptyComponent, emptyText, filter = {}, source, ...restProps } = props;
  const record = useRecordContext(props);
  const { isLoading, referenceRecord } = useReferenceOneFieldController<RaRecord>({
    filter: { ...filter, groups: ['reference'] },
    record: get(record, source) !== null ? record : undefined,
    source,
    ...restProps,
  });

  function getEmptyComponent(): JSX.Element | null {
    if (emptyComponent) {
      return emptyComponent;
    }

    if (emptyText) {
      return (
        <Typography component="span" variant="body2">
          {emptyText}
        </Typography>
      );
    }

    return null;
  }

  if (!record || (!isLoading && referenceRecord == null)) {
    return getEmptyComponent();
  }

  return <ReferenceOneField {...props}>{children}</ReferenceOneField>;
};

export default AppReferenceOneField;
