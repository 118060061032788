import { RaRecord, useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { StyledTypography } from './TooltipTextLinkField.styled';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type TooltipTextLinkFieldProps = {
  source: string;
  value?: string;
  resource: string;
  type: string;
} & Omit<FunctionFieldProps, 'render'>;

export const TooltipTextLinkField: React.FC<TooltipTextLinkFieldProps> = ({
  source,
  sx,
  resource,
  type,
  value,
  ...rest
}) => {
  const createPath = useCreatePath();

  return (
    <FunctionField
      render={(record: RaRecord) => {
        return (
          <Box>
            <Tooltip title={<Typography fontSize={12}>{value || record[source]}</Typography>}>
              <Link to={createPath({ resource, id: record.id, type })}>
                <StyledTypography sx={sx}>{value || record[source]}</StyledTypography>
              </Link>
            </Tooltip>
          </Box>
        );
      }}
      {...rest}
    />
  );
};
