import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Chip } from 'shared/mui/Chips';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { ChipField, FunctionField, SingleFieldList, TextField } from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Country, User } from 'types';
import { getUserName } from 'utils/getUserName';

export const WorkTeamsShowTable = (): JSX.Element => {
  return (
    <CardTable>
      <TableBody>
        <TableRowWrapper title="Группа">
          <TextField source="name" />
        </TableRowWrapper>
        <TableRowWrapper
          contentStyle={{ paddingBottom: '8px' }}
          headerStyle={{ width: '160px' }}
          title="Страны"
        >
          <ReferenceArrayField
            label="Страны"
            reference="countries"
            sortable={false}
            source="countries"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                render={(record: Country) => (
                  <ChipField
                    record={record}
                    source="name"
                    sx={{ marginRight: '4px', marginBottom: '4px' }}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </TableRowWrapper>
        <TableRowWrapper title="Руководитель группы">
          <ReferenceOneField
            label="Руководитель группы"
            reference="users"
            source="manager"
            target="id"
          >
            <FunctionField render={getUserName} />
          </ReferenceOneField>
        </TableRowWrapper>
        <TableRowWrapper contentStyle={{ paddingBottom: '8px' }} title="Участники">
          <ReferenceArrayField
            label="Участники"
            reference="users"
            sortable={false}
            source="members"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                render={(record: User) => (
                  <Chip
                    label={`${record.firstName} ${record.lastName}`}
                    sx={{ marginRight: '4px', marginBottom: '4px' }}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
