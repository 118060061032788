import { styled } from '@mui/material';
import { Button } from 'shared/mui/Buttons';

export const ButtonStyled = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0!important',
  },
  '&.MuiButtonBase-root': {
    padding: '0!important',
    lineHeight: '1.5',
    minWidth: 0,
  },
});
