import { styled } from '@mui/material/styles';
import { Dialog } from 'shared/mui/Dialogs';

export const EditDialogStyled = styled(Dialog)({
  '.RaEdit-main': {
    margin: '0!important',
  },
  '.MuiCardContent-root': {
    margin: '0!important',
    padding: '0',
  },
});
