import { Delete } from '@mui/icons-material';

import { IconButton, IconButtonProps } from '..';

export type DeleteIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function DeleteIconButton(props: DeleteIconButtonProps): JSX.Element {
  return (
    <IconButton {...props}>
      <Delete fontSize="inherit" />
    </IconButton>
  );
}
