import { useCallback } from 'react';

import qs from 'qs';
import { useListContext } from 'react-admin';

export type UseCreateEntityFilterQueryString = {
  entity: string;
};

type UseCreateEntityFilterQueryStringReturnValue = {
  createEntityFilterQueryString: ({ entity }: UseCreateEntityFilterQueryString) => string;
};

// Формируем query-строку с определенными фильтрами
// Используется для подстановки выбранного фильтра на страницах редактирования, создания
export function useCreateEntityFilterQueryString(): UseCreateEntityFilterQueryStringReturnValue {
  const { filterValues } = useListContext();

  const createEntityFilterQueryString = useCallback(
    ({ entity }: UseCreateEntityFilterQueryString) => {
      return Object.keys(filterValues).length !== 0
        ? `?${qs.stringify({ [entity]: filterValues[entity] })}`
        : '';
    },
    [filterValues],
  );

  return { createEntityFilterQueryString };
}
