import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { ListPage } from 'shared/react-admin/Pages';

import { GatewayDeactivationReasonsListDatagrid } from './GatewayDeactivationReasonsListDatagrid';
import { GatewayDeactivationReasonsListFilter } from './GatewayDeactivationReasonsListFilter';
import { usePermissions } from '../../../hooks/usePermissions';
import { GatewayDeactivationReason, Permission } from '../../../types';
import { GatewayDeactivationReasonsDeleteDialog } from '../components/GatewayDeactivationReasonsDeleteDialog';
import { GatewayDeactivationReasonsGatewayCountField } from '../components/GatewayDeactivationReasonsGatewayCountField';

export const GatewayDeactivationReasonsList: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<GatewayDeactivationReason>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('gateway_deactivation_reasons', [
          Permission.Full,
          Permission.Create,
        ]) && <CreateButton isLinkToModal={true} key="create_gateway_deactivation_reasons" />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Причины деактивации шлюза',
        tooltipText: 'Причина на основании которой осуществляется отключение активного шлюза.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <GatewayDeactivationReasonsListFilter />
      <GatewayDeactivationReasonsListDatagrid
        handleOpenRecordModalDelete={handleOpenRecordModalDelete}
      />
      <GatewayDeactivationReasonsGatewayCountField />
      {openRecordModalDelete && (
        <GatewayDeactivationReasonsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
    </ListPage>
  );
};
