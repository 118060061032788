import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNotify } from 'react-admin';
import { IconButton } from 'shared/mui/IconButtons';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Notification } from 'types';

import { NotificationPopperMenu } from './NotificationPopperMenu.styled';
import { useChangeViewedStatus } from '../hooks/useChangeViewedStatus';

type NotificationPopperMenuButtonProps = {
  notifications?: Notification[];
  onSuccess: () => void;
};

export const NotificationPopperMenuButton = ({
  notifications,
  onSuccess,
}: NotificationPopperMenuButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const notify = useNotify();

  const { changeViewedStatus } = useChangeViewedStatus({
    ids: notifications?.map((notification) => notification.id),
    onSuccess,
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <NotificationPopperMenu anchorEl={anchorEl} onClose={handleClose} open={!!anchorEl}>
        <MenuItem onClick={changeViewedStatus}>Отметить все как прочитанные</MenuItem>
      </NotificationPopperMenu>
    </>
  );
};
