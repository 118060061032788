import { styled } from '@mui/material';
import { appColors } from 'themes/variables';

export const StyledCumulativeReportPeriod = styled('div')({
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${appColors.divider}`,
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
  borderBottom: 'none',
  padding: '0 32px',
  backgroundColor: appColors.background,
});
