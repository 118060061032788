import { EMPTY_FIELD } from 'constants/emptyField';

import { FieldProps, useGetOne, useListContext } from 'react-admin';

import { OrderDispute } from '../../../../types';

export function OrderDisputeField(props: FieldProps): JSX.Element {
  const { data } = useListContext<OrderDispute>(props);
  const lastDispute = data[data.length - 1];

  const { data: status } = useGetOne('order_dispute_statuses', {
    id: lastDispute?.status || '',
  });

  return <span>{status?.name || EMPTY_FIELD}</span>;
}
