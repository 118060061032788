import { useState } from 'react';

import { AppNewEdit } from 'components/Common/Edit';
import { BankOperationsForm } from 'components/Finance/BankOperations/components/BankOperationsForm';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, SimpleForm, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { BankOperation, Permission } from 'types';

export const BankOperationsEdit = (): JSX.Element => {
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [provider, setProvider] = useState<string | undefined>(undefined);
  const transform = (data: BankOperation): RaRecord => {
    const date = data.dateOfOperation;
    return {
      ...data,
      dateOfOperation:
        date instanceof Date ? date.getTime() / 1000 : new Date(date).getTime() / 1000,
      currency,
      provider,
    };
  };

  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: bankOperation,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<BankOperation>();

  return (
    <AppNewEdit
      listName="Банковские операции"
      sx={{
        '& .RaEdit-main': {
          width: 600,
        },
      }}
      title="Редактирование банковской операции"
      transform={transform}
    >
      <DeleteDialog
        details="Заявка будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={bankOperation}
        title="Удаление заявки"
      />
      <SimpleForm
        toolbar={
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
              <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) && (
                <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
              )
            }
          />
        }
      >
        <BankOperationsForm
          currency={currency}
          provider={provider}
          setCurrency={setCurrency}
          setProvider={setProvider}
        />
      </SimpleForm>
    </AppNewEdit>
  );
};
