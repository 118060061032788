import { queryClient } from 'App';
import { archiveMerchant } from 'config/requests';
import { useGoBack } from 'hooks/useGoBack';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { Box } from 'shared/mui/Box';
import { CancelButton } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { DeleteStageDialogForm } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogForm';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Merchant } from 'types';

type MerchantsArchiveDialogProps = {
  record?: Merchant;
  open: boolean;
  onClose: VoidFunction;
  isToRootOnSuccess?: boolean;
};

export const MerchantsArchiveDialog: React.FC<MerchantsArchiveDialogProps> = ({
  record,
  open,
  onClose,
  isToRootOnSuccess,
}) => {
  const { goBack } = useGoBack();
  const { deleteOneRecord, denyDeleteReason, stage } = useStagedDelete({
    actionFn: (id: string) => archiveMerchant(id, true),
    record,
    onSuccess: () => {
      onClose();
      isToRootOnSuccess && goBack();
      queryClient.refetchQueries(['merchants']);
    },
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <Box
        sx={{
          display: 'flex',
          top: '50%',
          flexDirection: 'column',
          padding: 0,
          width: '600px',
        }}
      >
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Архивирование мерчанта"
        />
        <DeleteStageDialogForm
          deleteLabel="В архив"
          deleteOneRecord={deleteOneRecord}
          details={`Мерчант ${record?.name} будет переведен в архив и перестанет быть доступен для настроек в системе и формирования финансовой отчетности. При необходимости, функциональность данного мерчанта может быть восстановлена.`}
          onClose={onClose}
          open={true}
          stage={stage}
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton
              label="Вернуться к списку мерчантов"
              onClick={isToRootOnSuccess ? goBack : onClose}
              variant="outlined"
            />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialogForm>
      </Box>
    </Dialog>
  );
};
