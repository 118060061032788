import React, { Fragment } from 'react';

import { Chip, List } from '@mui/material';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Divider } from 'shared/mui/Divider/Divider';

import { DEFAULT_SHORTCUTS, Shortcuts } from './constants/shortcuts';
import { ListItemStyled } from './DatePickerShortcuts.styled';

export type Shortcut = {
  label: string;
  getValue: () => [number | null, number | null];
};

export type ShortcutsListProps = {
  shortcuts?: Shortcuts;
  onClick?: (value: [number | null, number | null]) => void;
  timezone?: string;
};

export const DatePickerShortcuts: React.FC<ShortcutsListProps> = ({
  onClick,
  shortcuts = DEFAULT_SHORTCUTS,
  timezone = DEFAULT_TIMEZONE,
}) => {
  return (
    <List>
      {shortcuts(timezone).map((shortcutSection, index) => (
        <Fragment key={index}>
          {index !== 0 && <Divider sx={{ marginY: '8px' }} />}

          {shortcutSection.map((shortcut) => (
            <ListItemStyled key={shortcut.label}>
              <Chip
                key={shortcut.label}
                label={shortcut.label}
                onClick={() => {
                  if (!onClick) {
                    return;
                  }
                  onClick(shortcut.getValue());
                }}
              />
            </ListItemStyled>
          ))}
        </Fragment>
      ))}
    </List>
  );
};
