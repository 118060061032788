import { Button } from '../../../shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from '../../../shared/mui/Card';
import { CardContent } from '../../../shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from '../../../shared/mui/Dialogs';
import { MerchantSpacesSiteFieldValues } from '../MerchantSpacesCreate/MerchantSpacesCreate';

type ConfirmSitesAccessDialogProps = DialogProps & {
  sites: MerchantSpacesSiteFieldValues[];
  onConfirm: () => void;
  selectedIds: string[] | undefined;
};

export const ConfirmDisableSitesAccessDialog: React.FC<ConfirmSitesAccessDialogProps> = ({
  selectedIds,
  onConfirm,
  sites,
  ...restProps
}) => {
  function renderSites() {
    return (
      <ul>
        {sites.map((site) => {
          if (selectedIds?.includes(site?.record?.id)) {
            return <li key={site.sourceMerchantId}>{site.record.name}</li>;
          }
        })}
      </ul>
    );
  }

  return (
    <Dialog {...restProps}>
      <Card>
        <CardHeader title="Отключение доступа" />
        <CardContent>
          После подтверждения, у пользователей кабинета пропадёт доступ к управлению следующими
          мерчантами:
          {renderSites()}
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              key="confirm-button"
              label="Подтвердить"
              onClick={onConfirm}
              variant="contained"
            />,
            <Button key="cancel-button" label="Отменить" onClick={restProps.onClose} />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
