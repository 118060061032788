import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';

import { ReactComponent as Logo } from './images/vector.svg';

export const BoxStyled = styled(Box)({
  position: 'relative',
  width: '140px',
});

export const StyledLogo = styled(Logo)({
  path: {
    fill: blue[700],
  },
  width: '69px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
