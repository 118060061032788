import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from 'react-admin';

const StyledButton = styled(Button)({
  height: '36px',
  minWidth: '36px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  padding: '3px 16px',
});

export function AppButton({ variant = 'outlined', ...restProps }: ButtonProps): JSX.Element {
  return <StyledButton variant={variant} {...restProps} />;
}
