import InfoIcon from '@mui/icons-material/Info';

import { StyledIconButton } from './TooltipWithInfoIcon.styled';
import { Tooltip, TooltipProps } from '..';
import { IconButtonProps } from '../../IconButtons';
import { Typography } from '../../Typography';

export type TooltipWithInfoIconProps = {
  tooltipText: string;
  iconSize?: number;
} & IconButtonProps &
  Pick<TooltipProps, 'componentsProps'>;

export const TooltipWithInfoIcon = ({
  componentsProps,
  tooltipText,
  ...restProps
}: TooltipWithInfoIconProps): JSX.Element => (
  <Tooltip
    componentsProps={componentsProps}
    placement="bottom-start"
    sx={{ maxWidth: '247px' }}
    title={<Typography fontSize={12}>{tooltipText}</Typography>}
  >
    <StyledIconButton {...restProps}>
      <InfoIcon color="secondary" />
    </StyledIconButton>
  </Tooltip>
);
