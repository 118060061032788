import {
  SaveButton as AdminSaveButton,
  SaveButtonProps as AdminSaveButtonProps,
} from 'react-admin';
import { useFormState } from 'react-hook-form';

export type SaveButtonValidatedProps = AdminSaveButtonProps;

export function SaveButtonValidated({
  variant = 'contained',
  size = 'medium',
  icon = <></>,
  label = 'Создать',
  ...restProps
}: SaveButtonValidatedProps): JSX.Element {
  const { isValid } = useFormState();

  return (
    <AdminSaveButton
      icon={icon}
      label={label}
      size={size}
      variant={variant}
      {...restProps}
      disabled={!isValid}
    />
  );
}
