import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { blue, grey } from '@mui/material/colors';

import { StyledBoxTextStateWrapper } from './ImageUploader.styled';
import { Avatar } from '../Avatars/Avatar/Avatar';
import { Box } from '../Box';
import { Link } from '../Link/Link';
import { Typography } from '../Typography';

type ImageUploaderTextStateProps = {
  handleImageClick: () => void;
};

export const ImageUploaderTextState = ({ handleImageClick }: ImageUploaderTextStateProps) => (
  <StyledBoxTextStateWrapper onClick={handleImageClick}>
    <Avatar sx={{ bgcolor: blue[50] }}>
      <AddPhotoAlternateOutlinedIcon sx={{ color: blue[700] }} />
    </Avatar>
    <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
      <Link sx={{ lineHeight: '28px' }}>Нажмите, чтобы загрузить</Link>
      <Typography sx={{ lineHeight: '28px', margin: '1px 0 0 4px' }} variant="subtitle1">
        или перетащите
      </Typography>
    </Box>
    <Typography sx={{ marginTop: '8px', color: grey[700] }} variant="body2">
      PNG, JPG (max. 3MB)
    </Typography>
  </StyledBoxTextStateWrapper>
);
