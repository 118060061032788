import { ChipProps as MuiChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export type ChipProps = MuiChipProps & { state?: 'default' };

export const StyledChip = styled(Chip)<ChipProps>(({ color, state }) => ({
  width: 'fit-content',
  '& .MuiChip-label': {
    ...(state !== 'default' && { color: `${color === 'primary' ? '#FFF' : grey[900]}` }),
  },
}));
