import React, { useEffect, useMemo } from 'react';

import { useRecordModal } from 'hooks/useRecordModal';
import { RecordContextProvider, useNotify, useRedirect } from 'react-admin';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { Button, CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';

import { MerchantSpacesEditForm } from './MerchantSpacesEditForm';
import { EditMerchantSpaceParams, editMerchantSpaceRequest } from '../../../config/requests';
import { useMerchantSpaces } from '../../../hooks/queries/useMerchantSpaces';
import { Merchant, MerchantSpaces } from '../../../types';

type MerchantSpacesSiteFieldValues = {
  record: Merchant;
  sourceMerchantId: string;
  active: boolean;
};

export interface MerchantSpacesFormValues {
  active: boolean;
  spaceName: string;
  sourceCompany: string;
  sites: MerchantSpacesSiteFieldValues[];
}

export const MerchantSpacesEdit: React.FC = () => {
  const redirect = useRedirect();
  const { id } = useParams();
  const { merchantSpaces } = useMerchantSpaces(id);
  const navigate = useNavigate();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<MerchantSpaces>();

  const defaultValues = useMemo(() => {
    return {
      spaceName: merchantSpaces?.spaceName || '',
      active: merchantSpaces?.active || false,
      sourceCompany: merchantSpaces?.sourceCompany || '',
    };
  }, [merchantSpaces]);

  const form = useForm<MerchantSpacesFormValues>({
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const { mutate: editMerchantSpace } = useMutation(
    ({ id, data }: { id: number; data: EditMerchantSpaceParams }) =>
      editMerchantSpaceRequest(id, data),
  );
  const notify = useNotify();

  const onSubmit: SubmitHandler<MerchantSpacesFormValues> = (values) => {
    const sites = values.sites.filter((site) => site.active)?.map((site) => site.sourceMerchantId);

    editMerchantSpace(
      {
        id: merchantSpaces?.id || -1,
        data: {
          active: values.active,
          spaceName: values.spaceName,
          sourceCompanyId: values.sourceCompany,
          sites,
        },
      },
      {
        onSuccess: () => {
          notify('Кабинет мерчанта успешно отредактирован!', { type: 'success' });
          redirect('/merchant_spaces');
        },
        onError: (error: any) => {
          notify(error.data.errors[0].title || 'Something went wrong', { type: 'error' });
        },
      },
    );
  };

  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: 'Управление кабинетом мерчантов',
          listName: 'Кабинеты мерчантов',
        }}
      />
      <Card
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.08)',
        }}
      >
        <DeleteDialog
          details={`Кабинет мерчанта ${merchantSpaces?.spaceName} будет удален без возможности восстановления.`}
          onClose={handleCloseRecordModal}
          onSuccess={() => navigate(-1)}
          open={openRecordModal}
          record={merchantSpaces}
          title="Удаление кабинета мерчанта"
        />
        <CardHeader title="Управление кабинетом мерчантов" />
        <RecordContextProvider value={merchantSpaces}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <MerchantSpacesEditForm />
              <CardActionsButtons
                leftActionsSlot={[
                  <Button key="save-button" label="Сохранить" type="submit" variant="contained" />,
                  <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
                ]}
                rightActionsSlot={
                  <DeleteButton
                    onClick={() => handleOpenRecordModal(merchantSpaces)}
                    variant="outlined"
                  />
                }
              />
            </form>
          </FormProvider>
        </RecordContextProvider>
      </Card>
    </Box>
  );
};
