import React from 'react';

import { Box } from 'shared/mui/Box';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { PageHeader } from 'shared/mui/PageHeader';
import { CreateGuesserProps } from 'shared/react-admin/Guessers';

import { StyledCreateGuesser } from './CreateGuesserPage.styled';

export type CreateGuesserPageProps = {
  title: string;
  children: React.ReactNode;
  leftActionsSlot?: JSX.Element | JSX.Element[];
  listName: string;
  rightActionsSlot?: JSX.Element | JSX.Element[];
} & CreateGuesserProps;

export function CreateGuesserPage({
  title,
  leftActionsSlot,
  listName,
  rightActionsSlot,
  children,
  ...props
}: CreateGuesserPageProps): JSX.Element {
  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: title,
          listName,
        }}
      />
      <StyledCreateGuesser actions={false} toolbar={false} {...props}>
        <CardHeader title={title} />
        {children}
        <CardActionsButtons leftActionsSlot={leftActionsSlot} rightActionsSlot={rightActionsSlot} />
      </StyledCreateGuesser>
    </Box>
  );
}
