import { useContext, useEffect, useState } from 'react';

import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCaptcha } from 'hooks/useCaptcha';
import { defaultTheme } from 'react-admin';
import { executeCaptcha } from 'utils';

import { AppCredentialsForm, AppSecondFactorForm } from './';
import { AppTempPasswordForm } from './AppTempPasswordForm';
import { AuthStep, DEFAULT_USER } from '../../constants';
import { APIContext } from '../../context/apiContext';

const AppLoginPage = (): JSX.Element => {
  const [step, setStep] = useState<AuthStep>(AuthStep.Credentials);
  const { setUser } = useContext(APIContext);

  useEffect(() => {
    setUser(DEFAULT_USER);
  }, []);

  useCaptcha();

  const updateStep = (step: AuthStep) => setStep(step);
  const componentConfig: Record<AuthStep, JSX.Element> = {
    [AuthStep.Credentials]: <AppCredentialsForm updateStep={updateStep} />,
    [AuthStep.ChangePassword]: <AppTempPasswordForm updateStep={updateStep} />,
    [AuthStep.SecondFactor]: <AppSecondFactorForm callback={executeCaptcha} />,
  };

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Container component="main" maxWidth="xs">
        {componentConfig[step]}
      </Container>
    </ThemeProvider>
  );
};

export default AppLoginPage;
