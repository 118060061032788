import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

type OptionsType = {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

type DateFunction = (date: Date | number, options?: OptionsType) => Date;

const calcZonedDate = (
  date: Date | number,
  tz: string,
  fn: DateFunction,
  options?: OptionsType,
) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

export const getZonedEndOfDay = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, endOfDay);
};

export const getZonedEndOfWeek = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, endOfWeek, { weekStartsOn: 1 });
};

export const getZonedEndOfMonth = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, endOfMonth);
};

export const getZonedStartOfDay = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, startOfDay);
};

export const getZonedStartOfWeek = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, startOfWeek, { weekStartsOn: 1 });
};

export const getZonedStartOfMonth = (date: Date | number, timeZone: string) => {
  return calcZonedDate(date, timeZone, startOfMonth);
};
