import React from 'react';

import { Typography } from 'shared/mui/Typography';

type Props = {
  convertedValue: string;
  originalValue?: string;
  color: string;
};

export const AmountDataField: React.FC<Props> = ({ convertedValue, originalValue, color }) => {
  return (
    <>
      <Typography color={color} gutterBottom variant="body2">
        {convertedValue}
      </Typography>
      {originalValue && (
        <Typography color="#00000061" gutterBottom variant="body2">
          {originalValue}
        </Typography>
      )}
    </>
  );
};
