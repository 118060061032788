import {
  PageHeaderBreadcrumbs,
  PageHeaderBreadcrumbsProps,
  PageHeaderActions,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderTitleProps,
} from '.';
import { Box, BoxProps } from '../Box';

export type ActionsPosition = 'flex-start' | 'center' | 'flex-end';

export type PageHeaderProps = {
  breadCrumbs?: PageHeaderBreadcrumbsProps;
  headerTitle?: PageHeaderTitleProps | React.ReactNode;
  description?: string;
  actions?: (React.ReactElement | false)[] | false;
  actionsPosition?: ActionsPosition;
} & BoxProps;

const checkHeaderTitleType = (obj: any): obj is PageHeaderTitleProps => {
  return typeof obj === 'object' && 'titleText' in obj;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  breadCrumbs,
  headerTitle,
  description,
  actions,
  actionsPosition = 'flex-start',
  ...restProps
}) => (
  <Box alignItems="flex-start" display="flex" justifyContent="space-between" pb={3} {...restProps}>
    <Box flexDirection="column">
      {breadCrumbs && <PageHeaderBreadcrumbs {...breadCrumbs} />}
      <Box alignItems="center" display="flex">
        {checkHeaderTitleType(headerTitle) ? (
          <PageHeaderTitle {...headerTitle} />
        ) : (
          <>{headerTitle}</>
        )}
      </Box>
      {description && <PageHeaderDescription description={description} />}
    </Box>
    {actions && <PageHeaderActions actionsPosition={actionsPosition}>{actions}</PageHeaderActions>}
  </Box>
);
