import { queryClient } from 'App';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { useGetOne } from 'react-admin';
import { DeleteDialog, DeleteDialogProps } from 'shared/mui/Dialogs';
import { Currency } from 'types';

export const LimitsDeleteDialog: React.FC<Omit<DeleteDialogProps, 'details' | 'title'>> = (
  props,
) => {
  const { toRoot } = useNavigateToRoot();
  const currency = useGetOne<Currency>('currencies', { id: props.record?.currency });
  const gateway = useGetOne<Currency>('gateways', { id: props.record?.gateway });

  const onSuccess = async () => {
    toRoot();
    await queryClient.invalidateQueries(['limits']);
  };

  return (
    <DeleteDialog
      {...props}
      details={`Лимит в валюте ${currency.data?.name} для шлюза ${gateway.data?.name} будет удален без возможности восстановления.`}
      onSuccess={onSuccess}
      title="Удаление лимита"
    />
  );
};
