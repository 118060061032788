import { Dispatch, SetStateAction, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { TextField } from 'shared/mui/TextField/TextField';
import { GatewayMethod, Method } from 'types';

import { ChipStyled, FormControlStyled } from './GatewaysEditFormSettingsGateway.styled';
import { useGatewayEditFormContext } from '../context/GatewayEditFormContext';

type Props = {
  currentMethods?: Method[];
  setCurrentMethods: Dispatch<SetStateAction<Method[] | undefined>>;
  onAddGatewayMethod: (methodId: string, methodName: string) => void;
  onRemoveGatewayMethod: (index: number) => void;
};

export const GatewaysEditFormSettingsGateway = ({
  currentMethods,
  setCurrentMethods,
  onAddGatewayMethod,
  onRemoveGatewayMethod,
}: Props): JSX.Element => {
  const form = useFormContext();
  const { allMethods } = useGatewayEditFormContext();

  const pcidss = form.watch('pcidss');

  const changeHandler = (newMethods: Method[]) => {
    if (!currentMethods) {
      //если при создании шлюза не были выбраны методы
      const newMethod = newMethods.at(-1);
      setCurrentMethods([newMethod as Method]);
      onAddGatewayMethod((newMethod as Method).id, (newMethod as Method).name);
      return;
    }
    if (newMethods.length > currentMethods.length) {
      const newMethod = newMethods.at(-1);
      setCurrentMethods([...(currentMethods as Method[]), newMethod as Method]);
      onAddGatewayMethod((newMethod as Method).id, (newMethod as Method).name);
    }
    if (newMethods.length < currentMethods.length) {
      const deletedMethod = currentMethods?.filter((currentMethod) => {
        return !newMethods.some((newMethod) => currentMethod.name === newMethod.name);
      })[0];
      const deletedMethodIndex = form
        .getValues('gatewayMethods')
        .findIndex((method: GatewayMethod) => method.methodName === deletedMethod.name);
      onRemoveGatewayMethod(deletedMethodIndex);
      setCurrentMethods(newMethods);
    }
  };

  useEffect(() => {
    setCurrentMethods([]);
    form.setValue('gatewayMethods', []);
  }, [pcidss]);

  return (
    <FormControlStyled>
      <Autocomplete
        clearIcon={false}
        disableCloseOnSelect
        getOptionLabel={(value) => value.name}
        id="gatewayMethods"
        isOptionEqualToValue={(option, value) => option.alias === value.alias}
        multiple
        onChange={(_event, newMethods) => changeHandler([...newMethods])}
        options={allMethods || []}
        renderInput={(params) => <TextField {...params} label="Методы" variant="outlined" />}
        renderTags={(currentMethods, getTagProps) =>
          currentMethods?.map((method, index) => (
            <ChipStyled
              label={method.name}
              variant="outlined"
              {...getTagProps({ index })}
              key={method.name}
            />
          ))
        }
        value={currentMethods || []}
      />
    </FormControlStyled>
  );
};
