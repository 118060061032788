import { queryClient } from 'App';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { CancelButton } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { DeleteStageDialogForm } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogForm';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Method } from 'types';

import { StyledBox } from './MethodsDeleteDialog.styled';

type MethodsDeleteDialogProps = {
  record?: Method;
  open: boolean;
  onClose: () => void;
};

export const MethodsDeleteDialog: React.FC<MethodsDeleteDialogProps> = ({
  record,
  open,
  onClose,
}) => {
  const { toRoot } = useNavigateToRoot();
  const closeAndReturn = () => {
    onClose();
    toRoot();
  };
  const { deleteOneRecord, denyDeleteReason, stage } = useStagedDelete({
    record,
    onSuccess: async () => {
      closeAndReturn();
      await queryClient.refetchQueries(['methods']);
    },
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Удаление метода"
        />
        <DeleteStageDialogForm
          deleteOneRecord={deleteOneRecord}
          details={`Метод ${record?.name} будет удален без возможности восстановления.`}
          onClose={onClose}
          open={true}
          stage={stage}
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton
              label="Вернуться к списку методов"
              onClick={closeAndReturn}
              variant="outlined"
            />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialogForm>
      </StyledBox>
    </Dialog>
  );
};
