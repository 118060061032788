import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const WrapperStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '16px 24px',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  border: '1px solid #00000014',
  gap: '16px',
});

export const TitleWrapperStyled = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ChartWrapperStyled = styled(Box)({
  height: '16px',
  width: '100%',
  display: 'flex',
});
