import { BoxProps } from '@mui/material';

import { StyledActions, StyledCardActionsButtons } from './CardActionsButtons.styled';

export type CardActionsButtonsProps = {
  leftActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  rightActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
} & Omit<BoxProps, 'children'>;

export const CardActionsButtons: React.FC<CardActionsButtonsProps> = ({
  leftActionsSlot,
  rightActionsSlot,
  ...props
}) => {
  return (
    <StyledCardActionsButtons isOnlyRightSlot={!leftActionsSlot} {...props}>
      {leftActionsSlot && <StyledActions>{leftActionsSlot}</StyledActions>}
      {rightActionsSlot && <StyledActions>{rightActionsSlot}</StyledActions>}
    </StyledCardActionsButtons>
  );
};
