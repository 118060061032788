export const defaultOptions = {
  indexAxis: 'y' as const,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      display: false,
      stacked: true,
      grid: {
        display: false,
      },
    },
    x: {
      display: false,
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
    },
  },
};
