import { styled } from '@mui/material/styles';
import { Create } from 'react-admin';

import { appColors } from '../../../../themes/variables';
import { CreateProps } from '../../Create/Create';

export const CreateStyled = styled(Create)<CreateProps & { fullWidth?: boolean }>((props) => ({
  '& .RaCreate-main': {
    width: props.fullWidth ? '100%' : '600px',
    margin: 0,
    border: `1px solid ${appColors.divider}`,
    borderRadius: '4px',
  },
}));
