import { styled } from '@mui/material/styles';
import { Dialog } from 'shared/mui/Dialogs';

type StyledDialogProps = { isEmpty?: boolean };

export const DialogStyled = styled(Dialog)(({ isEmpty }: StyledDialogProps) => ({
  '.MuiCardContent-root': {
    ...(!isEmpty && { height: 'calc(100% - 64px)' }),
    overflow: 'auto',
    padding: 0,
    paddingBottom: '!important',
    ':&last-child': {
      paddingBottom: '0!important',
    },
  },
  '.MuiPaper-root': {
    width: '1200px',
    ...(!isEmpty && { height: '90%' }),
  },
}));
