import React from 'react';

import { useNotify, useRedirect } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Box } from 'shared/mui/Box';
import { CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';

import { MerchantSpacesUsersCreateForm } from './MerchantSpacesUsersCreateForm';
import { createMerchantSpacesUsersRequest } from '../../../config/requests';
import { PageHeader } from '../../../shared/mui/PageHeader';
import { Site } from '../../../types';

export type MerchantSpacesUsersFormValues = {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  merchantSpaceId: number;
  sites?: Site[];
};

export const MerchantSpacesUsersCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const form = useForm<MerchantSpacesUsersFormValues>({});

  const { mutate: createMerchantSpacesUsers } = useMutation(createMerchantSpacesUsersRequest);

  const onSubmit = (values: MerchantSpacesUsersFormValues) => {
    const sites = values.sites?.filter((site) => site.active)?.map((site) => site.id);

    createMerchantSpacesUsers(
      {
        active: values.active,
        email: values.email,
        merchantSpaceId: values.merchantSpaceId,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        role: values.role,
        phoneNumber: values.phoneNumber,
        sites,
      },
      {
        onSuccess: () => {
          notify('Пользователь мерчанта успешно создан!', { type: 'success' });
          redirect('/merchant_spaces_users');
        },
        onError: (reason: any) => {
          reason.data.errors.forEach((error: any) => {
            if (error.code === 'validation_error') {
              form.setError(error.source.parameter, { type: 'string', message: error.title });
            } else {
              notify(error.title, { type: 'error' });
            }
          });
        },
      },
    );
  };

  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: 'Создание пользователя мерчантов',
          listName: 'Пользователи мерчантов',
        }}
      />
      <Card
        sx={{
          width: '600px',
          border: '1px solid rgba(0, 0, 0, 0.08)',
        }}
      >
        <CardHeader title="Создание пользователя мерчантов" />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <MerchantSpacesUsersCreateForm />
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton key="save-button" />,
                <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
              ]}
            />
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};
