import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigateToRoot } from '../../../../hooks/useNavigateToRoot';

type Props = {
  title: React.ReactNode;
  onClose?: () => void;
  actions?: (React.ReactElement | false)[];
} & Pick<BoxProps, 'mb' | 'sx'>;

const StyledBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  width: '100%',
});

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Actions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '10px',
  whiteSpace: 'nowrap',
  flex: '0 1 auto',
});

const CloseButton = styled(IconButton)({
  width: '32px',
  height: '32px',
  marginLeft: '8px',
});

export function AppDialogHeader({ title, actions, onClose, mb = 3, sx }: Props): JSX.Element {
  const { toRoot } = useNavigateToRoot();

  return (
    <StyledBox mb={mb} sx={sx}>
      <Typography component="h2" variant="h5">
        {title}
      </Typography>
      <Wrapper>
        <Actions>{actions}</Actions>
        <CloseButton aria-label="close" onClick={onClose || toRoot}>
          <CloseIcon />
        </CloseButton>
      </Wrapper>
    </StyledBox>
  );
}
