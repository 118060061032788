import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Card, CardHeader } from '../../Card';
import { Drawer } from '../../Drawer/Drawer';

export const FilterDrawerStyled = styled(Drawer)({
  '.MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '.MuiDrawer-paper': {
    top: '48px',
    width: '444px',
    height: 'calc(100% - 48px)',
    boxShadow:
      '0px 9px 46px 8px #0000001F, 0px 24px 38px 3px #00000024, 0px 11px 15px -7px #00000033',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

export const CardHeaderStyled = styled(CardHeader)({
  borderBottom: `1px solid ${appColors.divider}`,
});

export const CardStyled = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
});
