import { useRecordModal } from 'hooks/useRecordModal';
import { useRecordContext } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { MerchantNotification } from 'types';

import { MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOff } from './MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOff';
import { MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOn } from './MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOn';

type Props = {
  notificationTransportName: string;
};

export const MerchantsShowNotificationsDeliveryChannelFieldSwitch: React.FC<Props> = ({
  notificationTransportName,
}) => {
  const {
    handleOpenRecordModal: handleOpenRecordModalOff,
    handleCloseRecordModal: handleCloseRecordModalOff,
    openRecordModal: openRecordModalOff,
  } = useRecordModal();
  const {
    handleOpenRecordModal: handleOpenRecordModalOn,
    handleCloseRecordModal: handleCloseRecordModalOn,
    openRecordModal: openRecordModalOn,
  } = useRecordModal();
  const record = useRecordContext<MerchantNotification>();

  const toggleActiveGatewayRule = () => {
    record.active ? handleOpenRecordModalOff(record) : handleOpenRecordModalOn(record);
  };

  return (
    <>
      <MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOff
        notification={record}
        notificationTransportName={notificationTransportName}
        onClose={handleCloseRecordModalOff}
        open={openRecordModalOff}
      />
      <MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOn
        notification={record}
        notificationTransportName={notificationTransportName}
        onClose={handleCloseRecordModalOn}
        open={openRecordModalOn}
      />
      <Switch
        checked={record.active}
        onClick={toggleActiveGatewayRule}
        size="medium"
        sx={{ marginRight: '16px' }}
      />
    </>
  );
};
