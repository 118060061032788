import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { TextField } from 'shared/react-admin/Fields';

import { RoleNameField } from '../components/RoleNameField';
import { RolesStatusesChipsField } from '../components/RolesStatusesChipsField';

export const RolesShowTable: React.FC = () => (
  <CardTable>
    <TableBody>
      <TableRowWrapper headerStyle={{ width: '112px' }} title="ID">
        <TextField source="originId" />
      </TableRowWrapper>
      <TableRowWrapper title="Название">
        <RoleNameField />
      </TableRowWrapper>
      <TableRowWrapper title="Алиас">
        <TextField source="alias" />
      </TableRowWrapper>
      <TableRowWrapper title="Статусы заказа">
        <RolesStatusesChipsField label="Статусы заказа" />
      </TableRowWrapper>
    </TableBody>
  </CardTable>
);
