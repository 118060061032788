import { Button, CancelButton } from 'shared/mui/Buttons';
import { BaseDialog, DialogProps } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { MerchantSpacesUsers } from 'types';

import { MerchantSpacesUsersInviteList } from '../MerchantSpacesUsersInviteList';

type Props = {
  users?: MerchantSpacesUsers[];
  onClose: () => void;
  onSubmit: () => void;
} & DialogProps;

export const MerchantSpacesUsersInviteDialog = ({ users, onSubmit, open, onClose }: Props) => {
  return (
    <BaseDialog
      leftActionsSlot={[
        <Button key="accept-button" label="Пригласить" onClick={onSubmit} variant="contained" />,
        <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
      ]}
      message={
        <>
          <Typography component="span">
            После подтверждения приглашение будет отправлено:
          </Typography>
          <MerchantSpacesUsersInviteList users={users} />
        </>
      }
      onClose={onClose}
      open={open}
      title="Отправка приглашения"
    />
  );
};
