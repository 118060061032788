import * as React from 'react';

import { Card } from '@mui/material';
import { axios } from 'config/axios';
import { useRecordModal } from 'hooks/useRecordModal';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { BankOperation } from 'types';

import { ApprovalConfirmDone } from './ApprovalConfirmDone';

export const ApprovalConfirm: React.FC<DialogProps> = (props) => {
  const bankOperationRecord = useRecordContext<BankOperation>();
  const { handleOpenRecordModal, openRecordModal, handleCloseRecordModal } = useRecordModal();
  const refresh = useRefresh();
  const notify = useNotify();

  function onSubmit() {
    axios
      .post(`bank_operations/${bankOperationRecord.originId}/approve`)
      .then(() => {
        handleOpenRecordModal(bankOperationRecord);
        refresh();
      })
      .catch((reason) => {
        notify(reason?.response?.data?.detail, { type: 'error' });
      });
  }

  return (
    <>
      <Dialog sx={{ display: !openRecordModal ? 'block' : 'none' }} {...props} fullWidth>
        <Card>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={props.onClose} />]}
            title="Согласование заявки"
          />
          <CardContent>
            <Typography>
              После подтверждения заявка будет согласована без возможности редактирования и
              удаления.
            </Typography>
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button
                key="save-button"
                label="Подтвердить"
                onClick={onSubmit}
                variant="contained"
              />,
              <CancelButton
                key="cancel-button"
                onClick={(e) => {
                  if (props.onClose) {
                    props.onClose(e, 'backdropClick');
                  }
                }}
                variant="text"
              />,
            ]}
          />
        </Card>
      </Dialog>
      <ApprovalConfirmDone
        onClose={() => {
          props.onClose();
          handleCloseRecordModal();
        }}
        open={openRecordModal}
      />
    </>
  );
};
