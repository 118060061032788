import { ROLES_ICONS } from 'constants/roles';

import { useEffect, useState } from 'react';

import { FormControlLabel, MenuItem } from '@mui/material';
import { FormDataConsumer, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { Switch } from 'shared/mui/Switch';
import {
  AutocompleteInputIntrospected,
  BooleanInput,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { SelectIntrospected } from 'shared/react-admin/Selects';
import { SelectItem } from 'shared/react-admin/Selects/Select/Select';

import { Role } from '../../../constants/roles';
import { AutocompleteArrayInputIntrospected } from '../components/AutocompleteArrayInputIntrospected';
import { NewSchemeInfoTooltip } from '../components/NewSchemeInfoTooltip';
import { ACCESS_SELECT_ITEMS } from '../constants/accessSelectItems';

export const ApiClientsCreateForm = () => {
  const [isNewAuthScheme, setIsNewAuthScheme] = useState(false);
  const { watch, unregister } = useFormContext();
  const roles = watch('roles');

  const isShowNewAuthScheme = roles?.includes(Role.Merchant);

  useEffect(() => {
    unregister('merchant');
    unregister('merchants');
  }, [isNewAuthScheme, roles]);

  const handleChange = () => setIsNewAuthScheme((prev) => !prev);

  return (
    <>
      <BooleanInput label="Активно" source="active" sx={{ marginBottom: 4 }} />
      <TextInputIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Название"
        source="name"
        variant="outlined"
      />
      <TextInputWithAliasIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Алиас"
        source="alias"
        variant="outlined"
      />
      <SelectIntrospected
        fullWidth
        items={ACCESS_SELECT_ITEMS}
        label="Доступ"
        renderValue={({ label, value }: SelectItem<Role>) => (
          <Chip icon={ROLES_ICONS[value]} label={label} />
        )}
        required
        shrink={true}
        source="roles"
      >
        {ACCESS_SELECT_ITEMS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <Chip icon={ROLES_ICONS[value]} label={label} />
          </MenuItem>
        ))}
      </SelectIntrospected>
      {isShowNewAuthScheme && (
        <>
          <Box marginTop={3}>
            <FormControlLabel
              control={<Switch checked={isNewAuthScheme} name="scheme" onChange={handleChange} />}
              label="Новая схема аутентификации"
              sx={{ marginRight: '0' }}
            />
            <NewSchemeInfoTooltip />
          </Box>
          {isNewAuthScheme ? (
            <>
              <Alert severity="warning" sx={{ marginTop: 3 }}>
                После создания клиента API отредактировать схему аутентификации будет невозможно.
              </Alert>
              <ReferenceArrayInput reference="merchants" source="merchants">
                <FormDataConsumer>
                  {({ formData }) => {
                    const disabled = (formData.roles || []).find(
                      (role: Role) => role === Role.Service,
                    );

                    return (
                      <AutocompleteArrayInputIntrospected
                        disabled={disabled}
                        filterToQuery={(searchText: string) => ({ name: searchText })}
                        label="Мерчанты"
                        optionText="name"
                        size="medium"
                        source="name"
                        sx={{ marginTop: '24px', maxWidth: '394px' }}
                        validate={required()}
                        variant="outlined"
                      />
                    );
                  }}
                </FormDataConsumer>
              </ReferenceArrayInput>
            </>
          ) : (
            <ReferenceInput reference="merchants" source="merchant">
              <AutocompleteInputIntrospected
                filterToQuery={(searchText: string) => ({ name: searchText })}
                label="Мерчант"
                optionText="name"
                size="medium"
                source="name"
                sx={{ marginTop: '24px', maxWidth: '394px' }}
                variant="outlined"
              />
            </ReferenceInput>
          )}
        </>
      )}
    </>
  );
};
