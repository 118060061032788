import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController, useNotify } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';

import { LimitsEditForm } from './LimitsEditForm';
import { Limit, Permission } from '../../../types';
import { LimitsDeleteDialog } from '../components/LimitsDeleteDialog';

const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
  return {
    ...data,
    paymentMin: data.paymentMin ? data.paymentMin + '' : null,
    paymentMax: data.paymentMax ? data.paymentMax + '' : null,
    payoutMin: data.payoutMin ? data.payoutMin + '' : null,
    payoutMax: data.payoutMax ? data.payoutMax + '' : null,
    min: data.paymentMin ? data.paymentMin + '' : null,
    max: data.paymentMax ? data.paymentMax + '' : null,
  };
};

export const LimitsEdit = (): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();
  const { goBack } = useGoBack();
  const notify = useNotify();
  const { record } = useEditController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Limit>();

  const onError = () => {
    notify('Форма заполнена неверно, проверьте, пожалуйста, ошибки', { type: 'error' });
  };

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError }}
        open={!openRecordModalDelete}
        title="Редактирование лимита"
        transform={transform}
      >
        <CardContent>
          <LimitsEditForm />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('limits', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <LimitsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
