import { usePermissions } from 'hooks/usePermissions';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { ArchiveConditionListChip } from 'shared/mui/Chips';
import { TableBody } from 'shared/mui/Table';
import { TableRowWrapper } from 'shared/mui/Table/TableRowWrapper/TableRowWrapper';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { Company, Merchant } from 'types';

import { MerchantPlatformField } from '../../components/MerchantPlatformField/MerchantPlatformField';
import { MerchantsPartnersField } from '../../components/MerchantsPartnersField/MerchantsPartnersField';
import { NotificationKeyAccessDenied } from '../../components/NotificationKeyAccessDenied/NotificationKeyAccessDenied';
import { NotificationKeyTooltip } from '../../components/NotificationKeyTooltip/NotificationKeyTooltip';

type MerchantsShowCommonInformationTableProps = {
  handleOpenCompanyModal: (record?: Company) => void;
  record?: Merchant;
};

const dateStyle = { padding: '0 16px' };

export const MerchantsShowCommonInformationTable: React.FC<
  MerchantsShowCommonInformationTableProps
> = ({ handleOpenCompanyModal, record }) => {
  const { hasResourceAccess } = usePermissions();

  return (
    <CardTable>
      <TableBody>
        <TableRowWrapper headerStyle={{ width: '160px' }} title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField source="name" />
        </TableRowWrapper>
        <TableRowWrapper title="Алиас">
          <TextField source="alias" />
        </TableRowWrapper>
        <TableRowWrapper title="Состояние">
          <FunctionField
            render={(record: Merchant) => <ArchiveConditionListChip archive={record.archive} />}
          />
        </TableRowWrapper>
        {hasResourceAccess('companies') && (
          <TableRowWrapper title="Компания">
            <ReferenceArrayField
              label="Компания"
              reference="companies"
              sortable={false}
              source="companies"
            >
              <SingleFieldList linkType={false}>
                <FunctionField
                  render={(record: Company) => (
                    <ChipField
                      color="primary"
                      onClick={() => handleOpenCompanyModal(record)}
                      source="name"
                      sx={{
                        '.MuiChip-label': {
                          cursor: 'pointer',
                          lineHeight: '12px',
                        },
                      }}
                    />
                  )}
                />
              </SingleFieldList>
            </ReferenceArrayField>
          </TableRowWrapper>
        )}
        <TableRowWrapper title="Партнерский">
          <MerchantsPartnersField />
        </TableRowWrapper>
        <TableRowWrapper title="Платформа">
          <MerchantPlatformField />
        </TableRowWrapper>
        <TableRowWrapper
          contentStyle={{
            '& .MuiTooltip-tooltip': {
              backgroundColor: appColors.background,
              padding: '0!important',
              margin: '0!important',
            },
          }}
          title="Ключ нотификации"
        >
          {record?.notificationKey ? (
            <NotificationKeyTooltip value={record.notificationKey} />
          ) : (
            <NotificationKeyAccessDenied />
          )}
        </TableRowWrapper>
        <TableRowWrapper contentStyle={dateStyle} title="Дата создания">
          <DateTimeField source="createdAt" />
        </TableRowWrapper>
        <TableRowWrapper contentStyle={dateStyle} title="Дата редактир.">
          <DateTimeField source="updatedAt" />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
