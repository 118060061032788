import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Alert } from 'shared/mui/Alert/Alert';

export const AlertStyled = styled(Alert)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: blue[50],
  color: 'black',
  height: '62px!important',
  width: 'calc(100% - 32px)!important',
  padding: '0 16px',
});
