import { useState } from 'react';

import { useShowController } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { DateTimeField } from 'shared/react-admin/Fields/DateTimeField/DateTimeField';
import { ShowPage } from 'shared/react-admin/Pages';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { User } from 'types';
import { UserAction } from 'types/userAction';

import { UserActionJsonSchema } from './components/UserActionJsonSchema/UserActionJsonSchema';
import { UserActionsTypeChipField } from './components/UserActionTypeChipField/UserActionTypeChipField';
import { UserRoleChipField } from './components/UserRoleChipField';

export const UserActionsShow: React.FC = () => {
  const { record } = useShowController<UserAction>();
  const [open, setOpen] = useState(false);

  return (
    <ShowPage
      leftActionsSlot={
        <Button label="JSON схема" onClick={() => setOpen(true)} variant="outlined" />
      }
      listName="Пользовательские действия"
      title="Просмотр действия"
    >
      <CardTable sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRowWrapper title="ID">
            <TextField source="originId" />
          </TableRowWrapper>
          <TableRowWrapper title="Дата редактир.">
            <DateTimeField source="createdAt" />
          </TableRowWrapper>
          <TableRowWrapper title="Сущность">
            <TextField source="translatedEntityName" />
          </TableRowWrapper>
          <TableRowWrapper title="ID сущности">
            <Link
              style={{ textDecoration: 'none', color: appColors.primary.main }}
              to={{
                pathname: '/user_actions',
                search: `filter=${encodeURIComponent(
                  JSON.stringify({
                    entityId: record?.entityId,
                    entityName: record?.entityName,
                  }),
                )}`,
              }}
            >
              {record?.entityId}
            </Link>
          </TableRowWrapper>
          <TableRowWrapper title="Пользователь">
            <ReferenceField emptyText="-" link={false} reference="users" source="user">
              <FunctionField render={(record: User) => `${record.firstName} ${record.lastName}`} />
            </ReferenceField>
          </TableRowWrapper>
          <TableRowWrapper title="Электронная почта">
            <ReferenceField link={false} reference="users" source="user">
              <FunctionField render={(record: User) => record?.corporateEmail || record?.email} />
            </ReferenceField>
          </TableRowWrapper>
          <TableRowWrapper title="Роль">
            <ReferenceField link={false} reference="users" source="user">
              <UserRoleChipField />
            </ReferenceField>
          </TableRowWrapper>
          <TableRowWrapper title="IP">
            <TextField source="ip" />
          </TableRowWrapper>
          <TableRowWrapper title="Тип события">
            <ReferenceField reference="user_event_types" source="eventType">
              <UserActionsTypeChipField source="description" />
            </ReferenceField>
          </TableRowWrapper>
        </TableBody>
      </CardTable>
      <UserActionJsonSchema onClose={() => setOpen(false)} open={open} record={record} />
    </ShowPage>
  );
};
