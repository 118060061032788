import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import { GatewayDeactivationReason, Permission } from 'types';

import { GatewayDeactivationReasonsEditForm } from './GatewayDeactivationReasonsEditForm';
import { GatewayDeactivationReasonsDeleteDialog } from '../components/GatewayDeactivationReasonsDeleteDialog';

export const GatewayDeactivationReasonsEdit = (): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();
  const { goBack } = useGoBack();
  const { record } = useEditController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<GatewayDeactivationReason>();

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError: () => undefined }}
        open={!openRecordModalDelete}
        sx={{ width: '444px' }}
        title="Редактирование причины"
      >
        <GatewayDeactivationReasonsEditForm />
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="SaveButton" label="Сохранить" />,
            <CancelButton key="CancelButton" onClick={goBack} variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('gateway_deactivation_reasons', [
              Permission.Full,
              Permission.Delete,
            ]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
          sx={{ paddingTop: '32px' }}
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <GatewayDeactivationReasonsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
