import { styled } from '@mui/material/styles';

export const StyledLi = styled('li', {
  name: 'FieldToggle',
  overridesResolver: (_props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 0,
  '& svg': {
    cursor: 'move',
  },
  '&.drag-active': {
    background: 'transparent',
    color: 'transparent',
    outline: `1px solid ${theme.palette.action.selected}`,
    '& .MuiSwitch-root, & svg': {
      visibility: 'hidden',
    },
  },
}));
