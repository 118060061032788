import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

type UseGatewayRulesFormReturnValues = {
  form: UseFormReturn<GatewayRulesFormValues, any>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

type Props = {
  defaultValues?: GatewayRulesFormValues;
  onSubmit: (data: GatewayRulesFormValues) => void;
};

const expressionSchema = yup.object().shape({
  comparisonOperator: yup.lazy((value) =>
    typeof value === 'object'
      ? yup.object().required().typeError('Обязательное поле')
      : yup.string().required('Обязательное поле'),
  ),
  expressionParam: yup
    .object()
    .shape({ id: yup.number(), name: yup.string(), alias: yup.string(), fieldType: yup.string() }),
  value: yup.array().of(yup.string().min(1, 'Обязательное поле')).min(1, 'Обязательное поле'),
});

const conditionGroupSchema: any = yup.object({
  children: yup.array().of(yup.lazy(() => conditionGroupSchema.default(undefined))),
  expressions: yup.array().of(expressionSchema),
  isAnd: yup.boolean(),
});

const mappingItemSchema = yup.object({
  gateway: yup.string().required('Обязательное поле'),
  merchantAccount: yup.string().required('Обязательное поле'),
  commissions: yup.array(yup.string()).min(1, 'Обязательное поле').required('Обязательное поле'),
  value: yup.number().required('Обязательное поле'),
});

const mappingSchemaDirection = yup.object().shape({
  active: yup.boolean(),
  method: yup.string().required('Обязательное поле'),
  direction: yup.string().required('Обязательное поле'),
  mappingItems: yup.array().of(mappingItemSchema).required(),
});

const formSchemaDirection = yup.object().shape({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Значение слишком длинное. Должно быть равно 255 символам или меньше.')
    .min(3, 'Значение слишком короткое. Должно быть равно 3 символам или больше.')
    .nullable(),
  merchant: yup.string().required('Обязательное поле'),
  active: yup.boolean().required('Обязательное поле'),
  conditionGroup: conditionGroupSchema.notRequired(),
  mappings: yup.array().of(mappingSchemaDirection).required(),
});

export type MappingFormValues = yup.InferType<typeof mappingSchemaDirection>;
export type GatewayRulesFormValues = yup.InferType<typeof formSchemaDirection>;
export type MappingItemFormValues = yup.InferType<typeof mappingItemSchema>;

export function useGatewayRulesForm({
  defaultValues,
  onSubmit,
}: Props): UseGatewayRulesFormReturnValues {
  const form = useForm<GatewayRulesFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchemaDirection),
    defaultValues,
  });

  function onSubmitHandler(data: GatewayRulesFormValues): void {
    onSubmit(data);
  }

  return {
    form,
    onSubmit: form.handleSubmit(onSubmitHandler),
  };
}
