import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Card } from 'shared/mui/Card';

export const StyledExpressionFormControl = styled(FormControl)({
  margin: 0,
  padding: 0,
});

export const StyledExpressionCard = styled(Card)({
  display: 'grid',
  alignItems: 'top',
  gap: '10px',
  height: '64px',
  gridTemplateColumns: '1fr 1fr 3fr auto',
});
