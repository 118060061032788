import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGatewayEditFormContext } from '../context/GatewayEditFormContext';

type UseGatewayMethodsFieldArrayReturnValues = {
  gatewayMethodFields: Record<'id', string>[];
  onAddGatewayMethod: (methodId: string, methodName: string) => void;
  onRemoveGatewayMethod: (index: number) => void;
};

export function useGatewayMethodsFieldArray(): UseGatewayMethodsFieldArrayReturnValues {
  const { control } = useFormContext();
  const { id } = useParams();
  const { gatewayMethods } = useGatewayEditFormContext();

  const {
    fields: gatewayMethodFields,
    append: appendGatewayMethod,
    remove: removeGatewayMethod,
  } = useFieldArray({
    name: 'gatewayMethods',
    control,
  });

  const onAddGatewayMethod = (methodId: string, methodName: string): void => {
    // если gatewayMethod был удален и снова добавлен пользователем до сохранения формы возвращаем его обратно
    const existingGatewayMethod = gatewayMethods?.find(
      (gatewayMethod) => gatewayMethod.method === methodId,
    );
    if (existingGatewayMethod) {
      appendGatewayMethod(existingGatewayMethod);
      return;
    }
    appendGatewayMethod({
      active: true,
      autoDeactivation: false,
      pilotMode: false,
      gateway: id,
      method: methodId,
      methodName: methodName,
    });
  };

  const onRemoveGatewayMethod = (index: number) => {
    removeGatewayMethod(index);
  };

  return {
    gatewayMethodFields,
    onAddGatewayMethod,
    onRemoveGatewayMethod,
  };
}
