import { useMemo } from 'react';

import { useGetList, useGetOne } from 'react-admin';

import { Order, OrderDispute } from '../../../types';

type Result = {
  hasOpenDispute: boolean;
  lastDispute: OrderDispute | undefined;
};

export function useOrdersDispute(order: Order | undefined): Result {
  const { data: orderDisputeStatuses } = useGetList('order_dispute_statuses');

  const receivedDisputeStatus = useMemo(
    () => orderDisputeStatuses?.find((status) => status.alias === 'received'),
    [orderDisputeStatuses],
  );

  const lastDisputeId = useMemo(() => order?.orderDisputes.at(-1) || '', [order]);

  const { data: lastDispute } = useGetOne<OrderDispute>('order_disputes', {
    id: lastDisputeId,
  });

  const hasOpenDispute = useMemo(() => {
    if (!lastDisputeId) {
      return false;
    }
    return lastDispute?.status === receivedDisputeStatus?.id;
  }, [lastDispute]);

  return { hasOpenDispute, lastDispute: hasOpenDispute ? lastDispute : undefined };
}
