import { Introspecter } from '@api-platform/admin';
import { useResourceContext } from 'react-admin';

import { NumberInputWithGuesser } from './NumberInputWithGuesser';
import { NumberInputProps } from '..';

export type NumberInputIntrospectedProps = NumberInputProps;

export const NumberInputIntrospected = (props: NumberInputProps): JSX.Element => {
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={NumberInputWithGuesser as any}
      includeDeprecated={true}
      resource={resource}
      {...props}
    />
  );
};
