import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { DeleteButton, CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';
import { ApiClient, Permission } from 'types';

import { ApiClientsEditForm } from './ApiClientsEditForm';
import { ApiClientsDeleteDialog } from '../components/ApiClientsDeleteDialog/ApiClientsDeleteDialog';

const transform = (data: ApiClient): ApiClient => {
  return {
    ...data,
    merchant: data.merchant || null,
  };
};

export const ApiClientsEdit = () => {
  const { hasResourcePermissions } = usePermissions();
  const navigate = useNavigate();
  const notify = useNotify();
  const { record } = useEditController();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<ApiClient>();

  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Клиенты API"
      mutationOptions={{
        onError: (error) => {
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, {
            type: 'error',
            autoHideDuration: 3000,
          });
        },
      }}
      rightActionsSlot={
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
        ) : undefined
      }
      title="Редактирование клиента API"
      transform={transform}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <ApiClientsEditForm />
      </CardContent>
      <ApiClientsDeleteDialog
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={record}
      />
    </EditGuesserPage>
  );
};
