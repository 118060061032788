import { styled } from '@mui/material/styles';
import { DeleteButton } from 'react-admin';

import { appColors } from '../../../../themes/variables';

export const StyledDeleteIconButton = styled(DeleteButton)({
  color: appColors.secondary.main,
  height: '36px',
  minWidth: '36px',
  borderColor: 'red',
  padding: 0,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});
