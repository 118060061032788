import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';

export const CumulativeReportPeriodAlert: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <Alert onClose={onClose} severity="error" sx={{ marginTop: '16px' }}>
      <Typography>Период формирования отчета не должен превышать 31 календарный день!</Typography>
    </Alert>
  );
};
