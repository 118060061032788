import { NOTIFICATION_STATUSES } from 'constants/notifications';

import { format, fromUnixTime } from 'date-fns';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

type NotificationPopperListItemSecondaryTextProps = {
  createdAt: number;
  fileExtension: string;
  status: string;
  isSuccess: boolean;
  isFailure: boolean;
};

export const NotificationPopperListItemSecondaryText: React.FC<
  NotificationPopperListItemSecondaryTextProps
> = ({ status, fileExtension, createdAt, isSuccess, isFailure }) => (
  <Stack flexDirection="row" gap={1}>
    <Typography>{format(fromUnixTime(createdAt), 'dd.MM.yyyy HH:mm')}</Typography>
    <Typography>&bull;</Typography>
    <Typography>{fileExtension}</Typography>
    <Typography>&bull;</Typography>
    <Typography
      color={isSuccess ? appColors.success.main : isFailure ? appColors.error.main : undefined}
    >
      {NOTIFICATION_STATUSES[status as keyof typeof NOTIFICATION_STATUSES]}
    </Typography>
  </Stack>
);
