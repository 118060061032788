import { styled } from '@mui/material';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { appColors } from 'themes/variables';

export const StyledCumulativeReportDatagrid = styled(Datagrid)({
  borderTop: 'none',
  borderBottom: `1px solid ${appColors.divider}`,
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  width: '100%',
  marginBottom: '24px',
  '& .MuiTableCell-body': {
    verticalAlign: 'top',
    padding: '16px',
  },
});
