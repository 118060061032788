import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { Link } from 'react-router-dom';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { MerchantAccount } from 'types';

import { EMPTY_FIELD } from '../../../../../../constants';

type Props = {
  label: string;
  source?: string;
  merchantId?: string;
};
type Colors = 'red' | 'orange' | 'green';
type Field = 'icon' | 'text';

const colorsMap: Record<Colors, Record<Field, string>> = {
  green: { icon: appColors.success.light, text: appColors.success.main },
  orange: { icon: appColors.warning.light, text: appColors.warning.main },
  red: { icon: appColors.error.light, text: appColors.error.main },
};

const getColor = (field: Field, color?: Colors) =>
  color ? colorsMap[color][field] : appColors.text.disabled;

export const MerchantShowAccountsActivityField: React.FC<Props> = ({ label, merchantId }) => {
  return (
    <FunctionField
      label={label}
      render={({
        balanceUpdatedAtColor,
        balanceUpdatedAtMessage,
        provider,
        gateways,
      }: MerchantAccount) => {
        const encodeFilter = encodeURIComponent(
          JSON.stringify({ merchant: merchantId, provider: [provider], gateway: gateways }),
        );
        return (
          <Link
            style={{ textDecoration: 'none' }}
            target="_blank"
            to={{
              pathname: '/monitoring/order_conversion',
              search: `filter=${encodeFilter}`,
            }}
          >
            <Stack
              alignItems="start"
              direction="row"
              gap={1}
              sx={{ cursor: balanceUpdatedAtMessage ? 'pointer' : 'auto' }}
            >
              <MonitorHeartOutlinedIcon htmlColor={getColor('icon', balanceUpdatedAtColor)} />
              <Typography color={getColor('text', balanceUpdatedAtColor)} component="span">
                {balanceUpdatedAtMessage || EMPTY_FIELD}
              </Typography>
            </Stack>
          </Link>
        );
      }}
    />
  );
};
