import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { Permission, WorkTeam } from 'types';

import { WorkTeamsShowTable } from './WorkTeamsShowTable';
import { WorkTeamsDeleteDialog } from '../components/WorkTeamsDeleteDialog';

export const WorkTeamsShow = (): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<WorkTeam>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="work_team" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModalDelete}
        title="Рабочая группа"
      >
        <WorkTeamsShowTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('work_teams', [Permission.Full, Permission.Update]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('work_teams', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <WorkTeamsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
