import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/system';
import { AccordionDetails } from 'shared/mui/Accordion';

import { StyledAccordion, StyledAccordionSummary } from './BalanceReportAccordion.styled';

type Props = {
  paddingFirstCell: number;
  row: React.ReactNode;
  sx?: SxProps;
  children: React.ReactNode[];
};

export const BalanceReportAccordion: React.FC<Props> = ({
  children,
  paddingFirstCell = 0,
  row,
  sx,
}) => {
  return (
    <StyledAccordion
      sx={{
        '&.Mui-expanded': { margin: 0 },
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          paddingLeft: `${paddingFirstCell ? paddingFirstCell : 16}px`,

          ...sx,
        }}
      >
        {row}
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
