import React from 'react';

import { Box } from 'shared/mui/Box';
import { Edit } from 'shared/react-admin/Edit/Edit';

import { StyledDialog } from './DeleteDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';

export type DeleteDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  title: string;
  width?: string;
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({ actions, title, children, width }) => {
  const { goBack } = useGoBack();

  return (
    <StyledDialog open={true}>
      <Edit actions={false} sx={{ width: width || '600px' }}>
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </Edit>
    </StyledDialog>
  );
};
