import { DateObject } from 'react-multi-date-picker';

import { setCurrentTime } from './setCurrentTime';
import { DateRangePickerValue } from '../DateTimeRangePicker/DateTimeRangePicker';

export function getValidCalendarDate(
  date: DateObject | null,
  time: DateRangePickerValue,
): number | null {
  if (date) {
    return time ? setCurrentTime(date.valueOf(), time) : date.valueOf();
  }

  return null;
}
