import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordContext } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { Typography } from 'shared/mui/Typography';
import { Merchant, Permission } from 'types';

import { AlertStyled } from './CreatePayoutControl.styled';

type CreatePayoutControlProps = {
  onCreate: () => void;
};

export const CreatePayoutControl: React.FC<CreatePayoutControlProps> = ({ onCreate }) => {
  const record = useRecordContext<Merchant>();
  const { hasResourcePermissions } = usePermissions();
  return (
    <AlertStyled
      action={
        hasResourcePermissions('merchant_payout_controls', [
          Permission.Full,
          Permission.Create,
        ]) && <Button label="СОЗДАТЬ КОНТРОЛЬ" onClick={onCreate} variant="contained" />
      }
      icon={<InfoOutlinedIcon color="primary" />}
      variant="filled"
    >
      <Typography variant="body2">
        Контроль выводов для мерчанта {record.name} не настроен. Перед включением необходимо создать
        контроль выводов.
      </Typography>
    </AlertStyled>
  );
};
