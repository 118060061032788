import { fromUnixTime } from 'date-fns';
import { FilterChipValue } from 'hooks/useFilterChips';
import { FieldValues } from 'react-hook-form';

import { Chip, ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsDateIntervalField<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  return (
    <Chip
      label={`${chipValue.label}: ${fromUnixTime(chipValue.value)
        .toLocaleDateString()
        .replaceAll('.', '/')}`}
      size="medium"
      {...restProps}
    />
  );
}
