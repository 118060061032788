import { IconButton as MDIconButton, IconButtonProps as MDIconButtonProps } from '@mui/material';
import { LocationDescriptor } from 'react-admin';
import { getLinkParams } from 'utils';

interface Props {
  to?: string | LocationDescriptor;
  target?: string;
}

export type IconButtonProps = Props & MDIconButtonProps;

export const IconButton = ({
  size = 'small',
  to: locationDescriptor,
  ...rest
}: IconButtonProps) => {
  if (!locationDescriptor) {
    return <MDIconButton size={size} {...rest} />;
  }

  const linkParams = getLinkParams(locationDescriptor);

  return <MDIconButton size={size} {...rest} {...linkParams} />;
};
