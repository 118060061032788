import { useDelete, useNotify } from 'react-admin';
import { convertReactAdminIdToHydraId } from 'utils';

type UseDeleteMerchantNotificationReturnValue = {
  isLoading: boolean;
  deleteMerchantNotification: VoidFunction;
};

export function useDeleteMerchantNotification(
  notificationId: string,
  onSuccess: VoidFunction,
): UseDeleteMerchantNotificationReturnValue {
  const notify = useNotify();
  const [deleteOne, { isLoading }] = useDelete(
    'merchant_notification_settings',
    {
      id: convertReactAdminIdToHydraId(notificationId, 'merchant_notification_settings'),
    },
    {
      onError: (error) => {
        notify(`Ошибка: ${error as string}` || 'Ошибка удаления настройки уведомлений', {
          type: 'error',
        });
      },
      onSuccess: () => {
        onSuccess();
        notify('Настройка уведомлений успешно удалена!', { type: 'success' });
      },
    },
  );

  return { isLoading, deleteMerchantNotification: deleteOne };
}
