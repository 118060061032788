import { useQuery } from 'react-query';

import { getMerchantSpacesUsersList, MerchantSpacesUsersResponse } from '../../../config/requests';
import { MerchantSpacesUsersFilter } from '../MerchantSpacesUsersList/MerchantSpacesUsersList';

type UseGetMerchantSpacesReturnValue = {
  merchantSpacesUsers: MerchantSpacesUsersResponse | undefined;
  isLoading: boolean;
  refetch: () => void;
};

export function useGetMerchantSpacesUsers(
  filters: MerchantSpacesUsersFilter,
): UseGetMerchantSpacesReturnValue {
  const {
    data: merchantSpacesUsers,
    isLoading,
    refetch,
  } = useQuery<MerchantSpacesUsersResponse>(
    ['merchant_spaces', filters],
    () => getMerchantSpacesUsersList(filters),
    { enabled: !!filters },
  );

  return { merchantSpacesUsers, isLoading, refetch };
}
