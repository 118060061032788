import { Box } from 'shared/mui/Box';
import { FunctionField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Company, Provider } from 'types';

export const ProviderAccountsName = (): JSX.Element => (
  <Box>
    <ReferenceOneField
      emptyText="—"
      label="Компания"
      link={false}
      reference="companies"
      source="company"
      target="id"
    >
      <FunctionField
        render={({ name }: Company) => `${name} | `}
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      />
    </ReferenceOneField>
    <ReferenceOneField label="Провайдер" reference="providers" source="provider" target="id">
      <FunctionField
        render={({ name }: Provider) => name}
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      />
    </ReferenceOneField>
  </Box>
);
