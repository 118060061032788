import React from 'react';

import { DialogProps } from '@mui/material/Dialog/Dialog';
import { CommissionsShowTable } from 'components/Commissions/CommissionsShow/CommissionsShowTable';
import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Commission } from 'types';

type Props = {
  commission: Commission;
  onClose: () => void;
} & DialogProps;

export const CommissionShowDialog: React.FC<Props> = ({ open, commission, onClose }) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open}>
      <RecordContextProvider value={commission}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Просмотр комиссии"
        />
        <CommissionsShowTable />
      </RecordContextProvider>
    </Dialog>
  );
};
