import { Validator } from 'react-admin';

type UseNotificationKeyValidationReturn = {
  notificationKeyValidation: Validator;
};

export function useNotificationKeyValidation(): UseNotificationKeyValidationReturn {
  const notificationKeyValidation = (value: string) => {
    if (value?.length !== 32) {
      return 'Значение должно быть равно 32 символам.';
    }
    return undefined;
  };

  return { notificationKeyValidation };
}
