import { Box } from 'shared/mui/Box';

import { EditDialogStyled } from './EditDialog.styled';
import { useGoBack } from '../../../../hooks/useGoBack';
import { CardHeader } from '../../../mui/Card';
import { CloseIconButton } from '../../../mui/IconButtons';
import { Edit, EditProps } from '../../Edit/Edit';

export type EditDialogProps = {
  actions?: React.ReactElement[] | null;
  children: React.ReactNode;
  open?: boolean;
  title: string;
} & EditProps;

export const EditDialog: React.FC<EditDialogProps> = ({
  actions,
  title,
  children,
  open = true,
  ...props
}) => {
  const { goBack } = useGoBack();

  return (
    <EditDialogStyled open={open}>
      <Edit actions={false} {...props}>
        <CardHeader
          actions={
            <Box>
              {[...(actions || []), <CloseIconButton key="close-button" onClose={goBack} />]}
            </Box>
          }
          title={title}
        />
        {children}
      </Edit>
    </EditDialogStyled>
  );
};
