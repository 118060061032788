import { useCallback } from 'react';

import { useRecordContext } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { MerchantSpaces } from 'types';

import { useChangeMarchantSpaceActivity } from '../hooks/useChangeMarchantSpaceActivity';

export function MerchantSpacesActivitySwitch({
  refetchMerchantSpaces,
}: {
  refetchMerchantSpaces: () => void;
}): JSX.Element {
  const record = useRecordContext<MerchantSpaces>();

  const { changeMerchantSpaceActivityMutation } =
    useChangeMarchantSpaceActivity(refetchMerchantSpaces);

  const toggleActivity = useCallback(() => {
    if (!record) {
      return;
    }
    changeMerchantSpaceActivityMutation.mutate({
      id: record.id,
      active: !record.active,
      sourceCompanyId: record.sourceCompany,
      spaceName: record.spaceName,
    });
  }, [record.active]);

  return <Switch checked={record?.active} onChange={toggleActivity} size="small" />;
}
