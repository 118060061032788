import { styled } from '@mui/material/styles';

import { Chip } from '..';

export const StyledChip = styled(Chip)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  background: '#' + backgroundColor,
  border: 0,
  margin: '4px',
  '& span': {
    marginTop: '1px',
  },
}));
