import { EMPTY_FIELD } from 'constants/emptyField';
import { OrderStatuses } from 'constants/orderStatuses';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getOrderStatuses } from 'config/requests';
import { useQuery } from 'react-query';
import { getId } from 'utils';

import { AppStatusChip } from './AppStatusChip';

const HEIGHT = '24px';

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '8px',
});

const Arrow = styled(Box)({
  whiteSpace: 'nowrap',
  height: HEIGHT,
  margin: 'auto 8px',
  lineHeight: `${HEIGHT}!important`,
  textAlign: 'center',
});

// временный фикс для поддержания статусов старых заказов
const OLD_STATUSES_CORRELATION = {
  '/admin/order_statuses/1': '/admin/order_statuses/created',
  '/admin/order_statuses/2': '/admin/order_statuses/pending',
  '/admin/order_statuses/3': '/admin/order_statuses/failure',
  '/admin/order_statuses/4': '/admin/order_statuses/success',
  '/admin/order_statuses/5': '/admin/order_statuses/refund',
  '/admin/order_statuses/6': '/admin/order_statuses/expired',
  '/admin/order_statuses/7': '/admin/order_statuses/chargeback',
  '/admin/order_statuses/11': '/admin/order_statuses/prepared',
  '/admin/order_statuses/13': '/admin/order_statuses/cancelled',
  '/admin/order_statuses/15': '/admin/order_statuses/waiting_confirmation',
};

export const AppOrderChangeStatusesChips = ({
  statusesIds,
}: {
  statusesIds: string[];
}): JSX.Element => {
  const { data: orderStatuses } = useQuery('new/order_statuses', getOrderStatuses);

  if (!orderStatuses) {
    return <div>{EMPTY_FIELD}</div>;
  }

  const formattedStatusesIds = Number(getId(statusesIds[0]))
    ? statusesIds.map(
        (oldStatus) => OLD_STATUSES_CORRELATION[oldStatus as keyof typeof OLD_STATUSES_CORRELATION],
      )
    : statusesIds;

  const statusesList = formattedStatusesIds.map((id) =>
    orderStatuses.find((status) => status.value === getId(id)),
  );

  const [first, second] = statusesList;

  return (
    <StyledBox>
      <AppStatusChip alias={first?.value as OrderStatuses} name={first?.description as string} />
      {second && (
        <>
          <Arrow>{'->'}</Arrow>
          <AppStatusChip
            alias={second.value as OrderStatuses}
            name={second.description as string}
          />
        </>
      )}
    </StyledBox>
  );
};
