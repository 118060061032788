import { styled } from '@mui/material';
import { appColors } from 'themes/variables';

type Size = 'm' | 'l';

const colors = appColors.orderStatus;

export type IconProps = {
  size: Size;
  color?: keyof typeof colors;
};

const sizeConfig: Record<Size, string> = {
  m: '16px',
  l: '18px',
};

export const StatusIcon = styled('div')<IconProps>(({ size, color }) => ({
  height: sizeConfig[size],
  width: sizeConfig[size],
  backgroundColor: color ? colors[color] : appColors.divider,
  borderRadius: '100%',
}));
