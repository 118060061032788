import React from 'react';

import { useMerchantSpaces } from 'hooks/queries/useMerchantSpaces';
import { useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';

export const MerchantSpacesNameField: React.FC = () => {
  const record = useRecordContext();
  const { merchantSpaces } = useMerchantSpaces(record?.merchantSpaceId);

  return <Chip label={merchantSpaces?.spaceName} />;
};
