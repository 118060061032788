import { Typography } from 'shared/mui/Typography';

import { OrdersItemStyled } from './OrderConversionListOrdersItem.styled';

type Props = {
  label: string;
  value: number;
};

export const OrderConversionListOrdersItem: React.FC<Props> = ({ label, value }) => {
  return (
    <OrdersItemStyled>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="h6">
        {value}
        {/*<IconButton size="small">*/}
        {/*  <OpenInNewIcon sx={{ fontSize: '20px' }} />*/}
        {/*</IconButton>*/}
      </Typography>
    </OrdersItemStyled>
  );
};
