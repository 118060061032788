import { formatInTimeZone } from 'date-fns-tz';
import { RaRecord } from 'react-admin';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { EMPTY_FIELD } from '../../../../constants';

export type DateTimeFieldProps = {
  label?: string | React.ReactElement;
  source: string;
  timezone?: string;
} & Omit<FunctionFieldProps, 'render'>;

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  label,
  timezone = DEFAULT_TIMEZONE,
  source,
  ...rest
}) => {
  return (
    <FunctionField
      label={label}
      render={(record: RaRecord) => {
        if (!record[source]) {
          return EMPTY_FIELD;
        }
        const date = new Date(record[source]);
        return (
          <Stack>
            <Typography variant="body2">{`${formatInTimeZone(date, timezone, 'dd/MM/yyyy')}`}</Typography>
            <Typography color={appColors.secondary.main} variant="body2">
              {`${formatInTimeZone(date, timezone, 'HH:mm')}`}
            </Typography>
          </Stack>
        );
      }}
      {...rest}
    />
  );
};
