import { grey } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';
import { Country, Currency, ExpressionParam } from 'types';

import { GatewayRuleFieldChip } from './GatewayRuleFieldChip';
import { GatewayRuleFieldExpressionParam } from './GatewayRuleFieldExpressionParam';
import { Expression } from '../GatewayRulesForm/GatewayRulesForm';

type Props = {
  expression: Expression;
  currencies: Currency[];
  countries: Country[];
};

export function GatewayRuleFieldExpression({
  expression,
  currencies,
  countries,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        margin: '6px 0',
        padding: '4px',
        boxSizing: 'border-box',
        borderColor: grey[300],
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: '#fff',
        width: '100%',
        display: 'inline-flex',
      }}
    >
      <GatewayRuleFieldChip label={expression.expressionParam?.name || ''} />
      <GatewayRuleFieldChip label={expression.comparisonOperator?.description || ''} />
      <GatewayRuleFieldExpressionParam
        countries={countries}
        currencies={currencies}
        param={expression?.expressionParam || ({} as ExpressionParam)}
        value={expression?.value || []}
      />
    </Box>
  );
}
