import { useState } from 'react';

import { getOrdersReportSummary } from 'config/requests';
import { format, fromUnixTime } from 'date-fns';
import { useQuery } from 'react-query';
import { Button } from 'shared/mui/Buttons';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { OrdersReportListShortReportAlertLi } from './components/OrdersReportListShortReportAlertLi';
import { OrdersReportListShortReportAlertTypography } from './components/OrdersReportListShortReportAlertTypography';
import { summaryConfig } from './constants/summaryConfig';
import { AlertStyled, BoxStyled, UlStyled } from './OrdersReportListShortReportAlertStyled';
import { OrdersReportFilterFormValues } from '../../../OrdersReportFilter';

type Props = {
  isShow: boolean;
  filterValues: OrdersReportFilterFormValues;
};

export const OrdersReportListShortReportAlert: React.FC<Props> = ({ isShow, filterValues }) => {
  const [isShowAlert, setIsShowAlert] = useState(true);
  const {
    data: summaryData,
    refetch: getSummary,
    isFetching,
  } = useQuery(
    ['getOrdersReportSummary', filterValues],
    () => getOrdersReportSummary(filterValues),
    {
      enabled: false,
    },
  );

  const dateGte = filterValues?.createdAt?.gte;
  const dateLte = filterValues?.createdAt?.lte;

  if (!isShowAlert || (!dateGte && !dateLte) || !isShow) {
    return null;
  }

  if (!summaryData) {
    return (
      <AlertStyled severity="warning">
        <BoxStyled>
          <Typography>
            Сформировать сводку по неисполненным транзакциям за указанный период создания?
          </Typography>
          <Button
            color="inherit"
            isLoading={isFetching}
            label="Сформировать"
            onClick={() => getSummary()}
            size="small"
            sx={{
              lineHeight: 1,
            }}
            variant="text"
          />
        </BoxStyled>
      </AlertStyled>
    );
  }

  return (
    <AlertStyled severity="warning">
      <BoxStyled>
        <Typography fontSize={16} fontWeight={500}>
          Сводка по запрошенному периоду создания заказов:
        </Typography>
        <CloseIconButton key="close-button" onClose={() => setIsShowAlert(false)} />
      </BoxStyled>
      <Typography variant="body2">{`За период отчета ${format(fromUnixTime(filterValues.createdAt.gte), 'dd/MM/yyyy HH:mm')} - ${format(fromUnixTime(filterValues.createdAt.lte), 'dd/MM/yyyy HH:mm')} (GMT +3) есть неисполненные транзакции в следующих статусах:`}</Typography>
      <UlStyled>
        {summaryConfig.map(({ link, name, status }) => {
          return (
            <OrdersReportListShortReportAlertLi
              filterValues={filterValues}
              key={status}
              link={link}
              name={name}
              status={status}
              value={summaryData[status]}
            />
          );
        })}
      </UlStyled>
      <OrdersReportListShortReportAlertTypography label="Итого: " />
      Исполнено:{' '}
      <OrdersReportListShortReportAlertTypography label={summaryData.executedOrderCount} /> / Не
      исполнено:{' '}
      <OrdersReportListShortReportAlertTypography label={summaryData.notExecutedOrderCount} />
    </AlertStyled>
  );
};
