import React from 'react';

import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';

export type DatePickerTimeProps = TimePickerProps<any>;

export const DatePickerTime: React.FC<DatePickerTimeProps> = (props) => {
  return (
    <TimePicker
      ampm={false}
      slotProps={{
        popper: {
          sx: {
            '.Mui-selected': {
              color: '#ffffff!important',
            },
            '.MuiPickersLayout-root': {
              boxShadow:
                '0 3px 14px 2px rgba(0, 0, 0, 0.1215686275), 0 8px 10px 1px rgba(0, 0, 0, 0.1411764706), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
            },
          },
        },
        textField: { variant: 'outlined', InputLabelProps: { shrink: true } },
      }}
      {...props}
    />
  );
};
