import ImageEye from '@mui/icons-material/RemoveRedEye';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { IconButton, IconButtonProps } from '..';

export type ShowLinkIconButtonProps = Omit<IconButtonProps, 'icon'>;

export function ShowLinkIconButton(props: ShowLinkIconButtonProps): JSX.Element {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);

  return (
    <IconButton
      component={Link}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record.id)}/show`,
      }}
      {...props}
    >
      <ImageEye fontSize="inherit" sx={{ marginLeft: '1px', marginTop: '1px' }} />
    </IconButton>
  );
}
