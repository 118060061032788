import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getUnixTime } from 'date-fns';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { cleanEmpty } from 'utils/cleanEmpty';
import * as yup from 'yup';

type UseFiltersReturnValues = {
  form: UseFormReturn<any>;
  onSubmit: (values: any) => void;
  resetFilter: () => void;
};

const schema = yup.object().shape({
  periodTo: yup.date().required(),
  periodFrom: yup.date().required(),
  currency: yup.string().required(),
  company: yup.string().required(),
  merchant: yup.string().required(),
  provider: yup.string().nullable().optional(),
  merchantAccounts: yup.array().of(yup.string()).optional(),
  accountsSummary: yup.boolean().required(),
});

export function useCumulativeReportFilters(): UseFiltersReturnValues {
  const form = useForm({ resolver: yupResolver(schema) });
  const [, setSearchParams] = useSearchParams();

  const resetFilter = useCallback(() => {
    form.reset({
      periodTo: undefined,
      periodFrom: undefined,
      currency: undefined,
      company: '',
      merchant: '',
      provider: '',
      merchantAccounts: [],
      accountsSummary: false,
    });
    setSearchParams({});
  }, [form]);

  const onSubmit = (values: any) => {
    setSearchParams(
      cleanEmpty({
        periodFrom: getUnixTime(values.periodFrom).toString(),
        periodTo: getUnixTime(values.periodTo).toString(),
        accountsSummary: values.accountsSummary,
        merchantAccounts: values.merchantAccounts,
        currency: values.currency,
        merchant: values.merchant,
        provider: values.provider,
        company: values.company,
      }) as any,
    );
  };

  return {
    form,
    onSubmit,
    resetFilter,
  };
}
