import { Introspecter } from '@api-platform/admin';
import { TextInputProps, useResourceContext } from 'react-admin';

import { TextInputComponent } from './TextInputComponent/TextInputComponent';

export type TextInputIntrospectedProps = TextInputProps;

export const TextInputIntrospected = (props: TextInputIntrospectedProps): JSX.Element => {
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={TextInputComponent as any}
      includeDeprecated={true}
      resource={resource}
      {...props}
    />
  );
};
