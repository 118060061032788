import { ChipFieldProps, useRecordContext } from 'react-admin';

import { ChipFieldStyled } from './AdjustmentStatusChipField.styled';
import { AdjustmentStatus } from '../../../../types/adjustmentStatus';

export type AdjustmentStatusChipFieldProps = ChipFieldProps;

export const AdjustmentStatusChipField = (props: AdjustmentStatusChipFieldProps): JSX.Element => {
  const record = useRecordContext<AdjustmentStatus>();

  return <ChipFieldStyled {...props} status={record?.value} />;
};
