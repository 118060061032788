import { AppDialogCircleDeleteButton } from 'components/Common/Buttons/DeleteButton/AppBaseDeleteButton';
import { useBankOperationEditable } from 'components/Finance/BankOperations/hooks/useBankOperationEditable';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { FunctionField, RaRecord, TextField } from 'react-admin';
import { CreateButton } from 'shared/mui/Buttons';
import { ColorChip } from 'shared/mui/Chips/ColorChip/ColorChip';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { DateTimeField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { MerchantShowAccountsShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { BankOperation, BankOperationStatus, MerchantAccount, Permission } from 'types';

import BankOperationsFilter from './BankOperationsFilter/BankOperationsFilter';
import { AppReferenceField, AppReferenceOneField } from '../../Common';
import { AppListEditButton, AppListShowButton } from '../../Common/Buttons';
import { AppChipField } from '../../Common/Fields';

export const BankOperationsList = (): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<BankOperation>();

  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  const { checkBankOperationEditable } = useBankOperationEditable();

  // данная функциональность была связана с удаленными сущностями userGroup userGroupMember
  // const { isUserFinanceHead, isLoading } = useIsUserFinanceHead();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-button" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Банковские операции',
        tooltipText:
          'Ручная операция, связанная с созданием заявки на движение денежных средств (с направлением на ввод или вывод).',
      }}
    >
      <DeleteDialog
        details="Заявка будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={record}
        title="Удаление заявки"
      />
      <BankOperationsFilter />
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <DateTimeField
          label={
            <span>
              Дата
              <br />
              проведения
            </span>
          }
          sortBy="dateOfOperation"
          source="dateOfOperation"
        />
        {hasResourceAccess('merchants') && (
          <AppReferenceOneField
            label="Мерчант"
            link="show"
            reference="merchants"
            source="merchant"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        <ReferenceField
          label="Счета мерчанта"
          reference="merchant_accounts"
          source="merchantAccount"
        >
          <FunctionField
            render={(record: MerchantAccount) => (
              <Typography
                color="secondary"
                onClick={() => handleOpenMerchantAccountModal(record)}
                sx={{ color: appColors.primary.main, cursor: 'pointer' }}
                variant="body2"
              >
                {record.name}
              </Typography>
            )}
          />
        </ReferenceField>
        <AppReferenceField
          emptyText="—"
          label="Направление"
          reference="directions"
          source="direction"
        >
          <AppChipField source="description" sx={{ margin: '0 !important' }} />
        </AppReferenceField>
        <AppReferenceField
          emptyText="—"
          label="Тип операции"
          reference="bank_operation_types"
          source="type"
        >
          <AppChipField source="name" sx={{ margin: '0 !important' }} />
        </AppReferenceField>
        <AppReferenceField
          label="Статус заявки"
          reference="bank_operation_statuses"
          source="status"
        >
          <FunctionField
            render={(record: BankOperationStatus) => {
              return (
                <ColorChip
                  iconColor={appColors.bankOperationStatus[record.value]}
                  label={record.description}
                />
              );
            }}
          />
        </AppReferenceField>
        <TextField label="Сумма" source="amountPenny" />
        {hasResourceAccess('currencies') && (
          <AppReferenceOneField
            label="Валюта"
            link={false}
            reference="currencies"
            source="currency"
            target="id"
          >
            <TextField source="alphaCode" />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('gateways') && (
          <AppReferenceOneField
            label="Шлюз"
            link="show"
            reference="gateways"
            source="gateway"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('providers') && (
          <AppReferenceOneField
            label="Провайдер"
            link="show"
            reference="providers"
            source="provider"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        <ActionsSlots
          render={(record: RaRecord) => (
            <>
              <AppListShowButton />
              {hasResourcePermissions('bank_operations', [Permission.Full, Permission.Update]) &&
                checkBankOperationEditable(record as BankOperation) && <AppListEditButton />}
              {hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) &&
                checkBankOperationEditable(record as BankOperation) && (
                  <AppDialogCircleDeleteButton
                    color="secondary"
                    onClick={() => handleOpenRecordModal(record as BankOperation)}
                    variant="text"
                  />
                )}
            </>
          )}
          sx={{ justifyContent: 'flex-start' }}
        />
      </Datagrid>
      {merchantAccount && (
        <MerchantShowAccountsShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </ListPage>
  );
};
