import { Typography } from 'shared/mui/Typography/Typography';

import { useFilterChipsContext } from './context/filterChipsContext';
import { FilterChipsStyled } from './FilterChips.styled';
import { FilterChipsSwitcher } from './FilterChipsSwitcher/FilterChipsSwitcher';
import { ScrollableBox } from '../../ScrollableBox/ScrollableBox';

export const FilterChips: React.FC = () => {
  const { filterChipValues, resetFilterBySource, resetFilterArrayById } = useFilterChipsContext();

  if (filterChipValues.length === 0) {
    return <Typography>Применённых фильтров нет</Typography>;
  }

  return (
    <FilterChipsStyled>
      <Typography sx={{ marginRight: '10px' }}>Фильтры:</Typography>
      <ScrollableBox>
        {filterChipValues.map((chipValue) => (
          <FilterChipsSwitcher
            chipValue={chipValue}
            key={chipValue.source}
            resetFilterArrayById={resetFilterArrayById}
            resetFilterBySource={resetFilterBySource}
          />
        ))}
      </ScrollableBox>
    </FilterChipsStyled>
  );
};
