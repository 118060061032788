import React from 'react';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { TextField } from 'shared/mui/Fields';

import { StyledExpressionFormControl } from './GatewayRulesFormRuleExpression.styled';

type Props<T extends FieldValues> = {
  name: Path<T>;
};

export function GatewayRulesFormRuleExpressionInput<T extends FieldValues>({
  name,
}: Props<T>): JSX.Element {
  const { control } = useFormContext();

  return (
    <StyledExpressionFormControl>
      <Controller
        control={control}
        defaultValue={'' as any}
        name={name}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={(e) => {
                field.onChange([e.target.value]);
              }}
              sx={{ margin: 0 }}
              value={field.value}
              variant="outlined"
            />
          );
        }}
      />
    </StyledExpressionFormControl>
  );
}
