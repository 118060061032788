import { getGatewayConversionSettings } from 'config/requests';
import { useQuery } from 'react-query';

export function useGatewayConversionSettings(id: number | null) {
  return useQuery(
    ['gateway_conversion_settings', id],
    () => getGatewayConversionSettings(id || 0),
    {
      retry: false,
      enabled: Boolean(id),
    },
  );
}
