import React from 'react';

import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';
import { format, fromUnixTime } from 'date-fns';
import { useGetList } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

type Props = {
  onClose: () => void;
  filters: BalanceReportFilterValues;
};

export const BalanceReportSuccessAlert: React.FC<Props> = ({ onClose, filters }) => {
  const { currency, periodFrom, periodTo, conversionRateType } = filters;
  const { data: currencyData } = useGetList<Currency>('currencies', {
    filter: { alphaCode: currency },
    sort: { field: 'id', order: 'ASC' },
  });
  const currentFilterCurrency = currencyData?.[0]
    ? `${currencyData?.[0].alphaCode}, ${currencyData?.[0].name}`
    : '';

  return (
    <Alert onClose={onClose} severity="success" sx={{ marginTop: '16px' }}>
      <Typography fontWeight={500}>
        {conversionRateType === 'actual' ? 'Актуальный курс' : 'Исторический курс'}
      </Typography>
      <Typography>
        {`Отчет сформирован за период ${periodFrom ? format(fromUnixTime(periodFrom), 'dd.MM.yyyy HH:mm') : ''} -
          ${periodTo ? format(fromUnixTime(periodTo), 'dd.MM.yyyy HH:mm') : ''} и сконвертирован в валюте
          "${currentFilterCurrency}" на ${conversionRateType === 'actual' ? 'текущую' : 'конечную'} дату запроса.`}
      </Typography>
    </Alert>
  );
};
