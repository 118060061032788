import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import { TextField } from 'shared/mui/TextField/TextField';

import { AutocompleteArrayInputShrinkStyled } from './AutocompleteArrayInputShrink.styled';
import { AutocompleteArrayInputProps } from '../AutocompleteArrayInput/AutocompleteArrayInput';

export type AutocompleteArrayInputShrinkProps = AutocompleteArrayInputProps;

export const AutocompleteArrayInputShrink: React.FC<AutocompleteArrayInputShrinkProps> = ({
  isRequired: isRequiredOverride,
  ...props
}) => {
  const {
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    source: props.source || '',
    isRequired: isRequiredOverride,
  });

  return (
    <AutocompleteArrayInputShrinkStyled
      renderInput={(params) => {
        const mergedTextFieldProps = {
          ...params.InputProps,
          ...props.TextFieldProps?.InputProps,
        };

        return (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={mergedTextFieldProps}
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              <InputHelperText
                error={error?.message}
                helperText={props.helperText}
                touched={isTouched || isSubmitted}
              />
            }
            label={
              <FieldTitle
                isRequired={(props as any)?.validate?.isRequired}
                label={props.label}
                resource={props.resource}
                source={props.source}
              />
            }
            size={props.size}
            variant={props.variant}
          />
        );
      }}
      {...props}
    />
  );
};
