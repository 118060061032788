import { styled } from '@mui/material/styles';
import { DeleteButton, DeleteButtonProps } from 'react-admin';

import { appColors } from '../../../../themes/variables';

const StyledDeleteButton = styled(DeleteButton)({
  color: appColors.secondary.main,
  height: '36px',
  minWidth: '36px',
  borderColor: 'red',
  padding: 0,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});

export function AppListDeleteButton({
  label = '',
  variant,
  ...restProps
}: DeleteButtonProps): JSX.Element {
  return <StyledDeleteButton label={label} variant={variant} {...restProps} />;
}
