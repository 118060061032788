import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, EditProps } from 'react-admin';

import { appColors } from '../../../themes/variables';
import { AppBreadCrumbs } from '../BreadCrumbs';
import { AppTypography } from '../Typography';

type Props = {
  title: string;
  listName: string;
  children: React.ReactNode;
} & EditProps;

const StyledEdit = styled(Edit)({
  '& .RaEdit-main': {
    width: 'fit-content',
    margin: '24px 0',
    border: `1px solid ${appColors.divider}`,
    borderRadius: '4px',
  },
});

export function AppNewEdit({
  title,
  listName,
  children,
  actions = false,
  ...props
}: Props): JSX.Element {
  return (
    <Box>
      <AppBreadCrumbs currentPage={title} listName={listName} />
      <StyledEdit actions={actions} {...props}>
        <Box mt={3} pb={3} px={3}>
          <AppTypography size="medium">{title}</AppTypography>
        </Box>
        {children}
      </StyledEdit>
    </Box>
  );
}
