import { useEffect } from 'react';

import { useMerchantSpaces } from 'hooks/queries/useMerchantSpaces';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import { MerchantSpacesUsersFormValues } from '../MerchantSpacesUsersCreate';

type UseCreateSitesFieldsReturnValues = {
  fields: FieldArrayWithId<MerchantSpacesUsersFormValues, 'sites', 'id'>[];
  isLoadingFields: boolean;
};

export function useCreateSitesFields(): UseCreateSitesFieldsReturnValues {
  const { watch, resetField } = useFormContext();
  const watchedMerchantSpaceId = watch('merchantSpaceId');

  const { merchantSpaces, isLoadingMerchantSpaces } = useMerchantSpaces(
    watchedMerchantSpaceId ? `${watchedMerchantSpaceId}` : '',
  );

  const { fields, append } = useFieldArray<MerchantSpacesUsersFormValues>({
    name: 'sites',
  });

  useEffect(() => {
    resetField('sites', {
      defaultValue: [],
    });
  }, [watchedMerchantSpaceId]);

  useEffect(() => {
    merchantSpaces?.sites.forEach((site) => append(site));
  }, [merchantSpaces]);

  return { fields, isLoadingFields: isLoadingMerchantSpaces };
}
