import React from 'react';

import { queryClient } from 'App';
import { useRecordModal } from 'hooks/useRecordModal';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { Switch } from 'shared/mui/Switch';
import { GatewayConversionSetting } from 'types/gatewayConversionSetting';

import { OrderConversionListGatewaySwitchDisable } from './OrderConversionListGatewaySwitchDisable/OrderConversionListGatewaySwitchDisable';
import { OrderConversionListGatewaySwitchEnable } from './OrderConversionListGatewaySwitchEnable/OrderConversionListGatewaySwitchEnable';
import { useUpdateGatewayConversionSettingsNotify } from '../../hooks/useUpdateGatewayConversionSettingsNotify';

type Props = {
  disabled?: boolean;
  record: GatewayConversionSetting | undefined;
};

export const OrderConversionListGatewaySwitch: React.FC<Props> = ({ record, disabled }) => {
  const {
    record: recordGatewayEnable,
    handleOpenRecordModal: handleOpenGatewayEnableModal,
    openRecordModal: openGatewayEnableModal,
    handleCloseRecordModal: handleCloseGatewayEnableModal,
  } = useRecordModal<GatewayConversionSetting>();

  const {
    record: recordGatewayDisable,
    handleOpenRecordModal: handleOpenGatewayDisableModal,
    openRecordModal: openGatewayDisableModal,
    handleCloseRecordModal: handleCloseGatewayDisableModal,
  } = useRecordModal<GatewayConversionSetting>();

  const { updateGatewayConversionSettingsNotify } = useUpdateGatewayConversionSettingsNotify();

  function onChangeNotify(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    checked ? handleOpenGatewayEnableModal(record) : handleOpenGatewayDisableModal(record);
  }

  async function onConfirmEnable() {
    if (!record?.id) {
      return;
    }

    await updateGatewayConversionSettingsNotify.mutateAsync({
      id: record?.id,
      data: {
        notify: true,
      },
    });
    await queryClient.refetchQueries(['gateway_conversion_settings', record?.id]);
  }

  async function onConfirmDisable() {
    if (!record?.id) {
      return;
    }

    await updateGatewayConversionSettingsNotify.mutateAsync({
      id: record?.id,
      data: {
        notify: false,
      },
    });
    await queryClient.refetchQueries(['gateway_conversion_settings', record?.id]);
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={record?.notify} name="notification" onChange={onChangeNotify} />}
        disabled={disabled}
        label="Уведомление для шлюза"
      />
      <OrderConversionListGatewaySwitchEnable
        onClose={handleCloseGatewayEnableModal}
        onConfirm={onConfirmEnable}
        open={openGatewayEnableModal}
        record={recordGatewayEnable}
      />
      <OrderConversionListGatewaySwitchDisable
        onClose={handleCloseGatewayDisableModal}
        onConfirm={onConfirmDisable}
        open={openGatewayDisableModal}
        record={recordGatewayDisable}
      />
    </>
  );
};
