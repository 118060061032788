import { styled } from '@mui/material';

import { Box } from '../../Box';

export const WrapperStyled = styled(Box)<{ isOnlyRightSlot: boolean }>(({ isOnlyRightSlot }) => ({
  width: '100%',
  padding: '12px 0px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: isOnlyRightSlot ? 'flex-end' : 'space-between',
}));

export const ActionsStyled = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
});
