import { Alert } from 'shared/mui/Alert/Alert';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ApiClient } from 'types';

type Props = {
  record?: ApiClient;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
  open: boolean;
};

export const ApiClientsDeleteDialog: React.FC<Props> = ({ record, onClose, onSuccess, open }) => {
  return (
    <DeleteDialog
      alert={
        <Alert severity="warning">{`В случае удаления клиента API с признаком "Активен", доступ к ресурсам public API для клиента ${record?.name} будет приостановлен.`}</Alert>
      }
      details={`Клиент API ${record?.name} будет удален без возможности восстановления.`}
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      record={record}
      title="Удаление клиента API"
    />
  );
};
