import { useEffect } from 'react';

import { useGetList, useRecordContext } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { MappingRecord, Merchant, MappingItemRecord } from 'types';

import { DialogStyled } from './EditPayoutControlMappingReplaceDialog.styled';
import { useCurrentMappingDataContext } from '../../context/CurrentMappingContext';
import { EditPayoutControlMappingField } from '../EditPayoutControlMappingField/EditPayoutControlMappingField';

type Props = {
  mapping: MappingItemRecord;
  onOpenConfirmDialog: () => void;
} & DialogProps;

export const EditPayoutControlMappingReplaceDialog: React.FC<Props> = ({
  mapping,
  open,
  onClose,
  onOpenConfirmDialog,
}) => {
  const record = useRecordContext<Merchant>();
  const { setMappingChosen } = useCurrentMappingDataContext();

  useEffect(() => {
    if (mapping) {
      setMappingChosen(mapping);
    }
  }, [mapping]);

  const handleChoice = () => {
    onClose();
    onOpenConfirmDialog();
  };

  const { data: mappings, isLoading: isLoadingMappings } = useGetList<MappingRecord>('mappings', {
    filter: {
      active: true,
      direction: 'payout',
      'gatewayRule.active': true,
      'gatewayRule.merchant': record.id,
    },
  });

  return (
    <DialogStyled maxWidth="lg" onClose={onClose} open={open}>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}
        title="Замена маппинга исключения"
      />
      <CardContent>
        {isLoadingMappings ? (
          <LinearProgress />
        ) : (
          mappings?.map((mapping, index) => (
            <EditPayoutControlMappingField key={index} mapping={mapping} onChoice={handleChoice} />
          ))
        )}
      </CardContent>
    </DialogStyled>
  );
};
