import { EMPTY_FIELD } from 'constants/emptyField';

import {
  SingleFieldList as RaSingleFieldList,
  SingleFieldListProps as RaSingleFieldListProps,
  useListContext,
} from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';

export type SingleFieldListProps = RaSingleFieldListProps;

export const SingleFieldList = (props: SingleFieldListProps) => {
  const { data, isLoading } = useListContext();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data?.length) {
    return EMPTY_FIELD;
  }

  return <RaSingleFieldList {...props} />;
};
