import { Box } from '@mui/material';
import 'components/Login/styles.scss';
import { QrCode } from 'types';

const AppSecondFactorQrCode = (props: QrCode): JSX.Element => {
  return (
    <Box>
      <p>1. Установите на телефон приложение Google Authenticator</p>
      <div className="twoFactor__app">
        <a
          className="twoFactor__app-link twoFactor__app-link_google"
          href="//play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru"
          target="_parent"
        >
          <span className="twoFactor__app-text">GET IT ON</span>
          <span className="twoFactor__app-name">Google Play</span>
        </a>
        <a
          className="twoFactor__app-link twoFactor__app-link_apple"
          href="//itunes.apple.com/ru/app/google-authenticator/id388497605?mt=8"
          target="_parent"
        >
          <span className="twoFactor__app-text">Загрузите в</span>
          <span className="twoFactor__app-name">App Store</span>
        </a>
      </div>
      <p>2. Добавьте в приложение свою учетную запись, отсканировав QR-код</p>
      <div className="twoFactor__qr">
        <img alt="" className="align-center" src={props.qrCode} />
      </div>
      <p>3. Введите полученный проверочный код</p>
    </Box>
  );
};

export default AppSecondFactorQrCode;
