import Axios, { AxiosInstance } from 'axios';

import { API_PATH, UNAUTHORIZED_API_PATH } from './entrypoint';
import { AxiosParams } from '../types';

const createAxios = (axiosParams: AxiosParams): AxiosInstance => {
  const { contentType, accept, unauthorized } = axiosParams;

  const instance = Axios.create({
    baseURL: unauthorized ? UNAUTHORIZED_API_PATH : API_PATH,
    headers: {
      Accept: accept ?? 'application/json',
      'Content-type': contentType,
    },
  });

  instance.interceptors.request.use(function (config) {
    if (config.headers) {
      config.headers.Authorization = localStorage.getItem('token') || '';
    }
    return config;
  });

  return instance;
};

export const axios = createAxios({ contentType: 'application/json' });
export const axiosCSV = createAxios({ contentType: 'application/json', accept: 'text/csv' });
export const axiosMultipart = createAxios({ contentType: 'multipart/form-data' });
export const axiosMerge = createAxios({ contentType: 'application/merge-patch+json' });
export const axiosUnauthorized = createAxios({
  contentType: 'application/json',
  unauthorized: true,
});
