import { ROLES_SELECT_ITEMS, ROLES_ICONS } from 'constants/roles';

import { usePermissions } from 'hooks/usePermissions';
import { ReferenceArrayField, TextField, useShowController } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Chip } from 'shared/mui/Chips';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { ChipField, DateTimeField, ListField, SingleFieldList } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

const dateStyle = { padding: '0 16px' };

export const ApiClientsShowCommonInfoTable = () => {
  const { hasResourceAccess } = usePermissions();
  const {
    record: { merchant },
  } = useShowController();

  return (
    <CardTable>
      <TableBody>
        <TableRowWrapper headerStyle={{ width: '200px' }} title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField source="name" />
        </TableRowWrapper>
        <TableRowWrapper title="Алиас">
          <TextField source="alias" />
        </TableRowWrapper>
        <TableRowWrapper title="Доступ">
          <ListField
            array={ROLES_SELECT_ITEMS}
            label="Доступ"
            renderItem={({ id, name }) => <Chip icon={ROLES_ICONS[id]} key={id} label={name} />}
            sortable={false}
            source="roles"
          />
        </TableRowWrapper>
        {hasResourceAccess('merchants') && (
          <TableRowWrapper title={`Мерчант${merchant ? '' : 'ы'}`}>
            {merchant ? (
              <ReferenceOneField
                label="Мерчант"
                link="show"
                reference="merchants"
                source="merchant"
                target="id"
              >
                <ChipField source="name" sx={{ color: 'black!important', margin: '0!important' }} />
              </ReferenceOneField>
            ) : (
              <ReferenceArrayField
                label="Мерчанты"
                reference="merchants"
                sortBy="merchants.name"
                source="merchants"
              >
                <SingleFieldList sx={{ display: 'flex', gap: '4px' }}>
                  <ChipField source="name" sx={{ color: 'black!important' }} />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
          </TableRowWrapper>
        )}
        <TableRowWrapper contentStyle={dateStyle} title="Дата создания">
          <DateTimeField source="createdAt" />
        </TableRowWrapper>
        <TableRowWrapper contentStyle={dateStyle} title="Дата редактир.">
          <DateTimeField source="updatedAt" />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
