import { useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteArrayInputShrink } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

export type ProviderReportsListFilterType = {
  gatewayAlias: string[];
  providerAlias: string[];
  page: number;
  itemsPerPage: number;
};

type Props = {
  onSubmit: (filter: ProviderReportsListFilterType) => void;
  onReset: () => void;
};

export const ProviderReportsListFilter: React.FC<Props> = ({ onSubmit, onReset }) => {
  const [searchParams] = useSearchParams();
  const form = useForm<ProviderReportsListFilterType>({
    defaultValues: {
      providerAlias: [],
      gatewayAlias: [],
    },
  });

  const resetFilter = (): void => {
    form.reset({});
    onReset();
  };

  useEffect(() => {
    form.setValue('gatewayAlias', searchParams.getAll('gatewayAlias'), { shouldDirty: true });
    form.setValue('providerAlias', searchParams.getAll('providerAlias'), { shouldDirty: true });
    form.handleSubmit(onSubmit);
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <ReferenceArrayInput
              isFilter={true}
              key="providerAlias"
              name="providerAlias"
              perPage={200}
              reference="providers"
              resource="providers"
              source="providerAlias"
            >
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="providerAlias"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              isFilter={true}
              key="gatewayAlias"
              name="gatewayAlias"
              perPage={200}
              reference="gateways"
              resource="gateways"
              source="gatewayAlias"
            >
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                name="gatewayAlias"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
