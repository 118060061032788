import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Typography } from 'shared/mui/Typography';

export const StyledTypography = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  color: blue[700],
  textDecoration: 'underline',
});
