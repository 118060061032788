import React, { ReactElement } from 'react';

import { Box, Button } from '@mui/material';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from 'components/Common/Dialog';

import { AppTypography } from '../../../Common/Typography';

type Props = {
  open: boolean;
  title: string;
  text: string;
  actionButton: ReactElement;
  onClose: () => void;
};

export const OrderConfirmationActionDialog = ({
  title,
  text,
  open,
  onClose,
  actionButton,
}: Props): JSX.Element => (
  <AppDialogBase open={open}>
    <AppDialogHeader onClose={onClose} title={title} />
    <AppTypography mb={5}>{text}</AppTypography>
    <AppDialogActions
      sx={{
        padding: 0,
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      <Box display="flex" gap={1}>
        {actionButton}
        <Button onClick={onClose} sx={{ height: 36 }} variant="outlined">
          отменить
        </Button>
      </Box>
    </AppDialogActions>
  </AppDialogBase>
);
