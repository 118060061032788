export type Language = {
  id: string;
  title: string;
  code: string;
};
export const LANGUAGES: Language[] = [
  {
    id: 'aa',
    title: 'Badînî',
    code: 'am',
  },
  {
    id: 'al',
    title: 'Shqip',
    code: 'al',
  },
  {
    id: 'am',
    title: 'አማርኛ',
    code: 'aa',
  },
  {
    id: 'ar',
    title: 'العربية',
    code: 'ar',
  },
  {
    id: 'au',
    title: 'Australian English',
    code: 'au',
  },
  {
    id: 'az',
    title: 'Azərbaycan dili',
    code: 'az',
  },
  {
    id: 'bg',
    title: 'Български',
    code: 'bg',
  },
  {
    id: 'bn',
    title: 'বাংলা',
    code: 'bd',
  },
  {
    id: 'br',
    title: 'Português (Brasil)',
    code: 'br',
  },
  {
    id: 'bs',
    title: 'Bosanski jezik',
    code: 'bs',
  },
  {
    id: 'cn',
    title: '汉语',
    code: 'cn',
  },
  {
    id: 'da',
    title: 'Dansk',
    code: 'dk',
  },
  {
    id: 'de',
    title: 'Deutsch',
    code: 'de',
  },
  {
    id: 'el',
    title: 'Ελληνικά',
    code: 'gr',
  },
  {
    id: 'en',
    title: 'English',
    code: 'en',
  },
  {
    id: 'es',
    title: 'Español',
    code: 'es',
  },
  {
    id: 'et',
    title: 'Eesti keel',
    code: 'et',
  },
  {
    id: 'fa',
    title: 'زبان فارسی',
    code: 'ir',
  },
  {
    id: 'fi',
    title: 'Suomi',
    code: 'fi',
  },
  {
    id: 'fr',
    title: 'Français',
    code: 'fr',
  },
  {
    id: 'he',
    title: 'עברית',
    code: 'he',
  },
  {
    id: 'hi',
    title: 'हिन्दी',
    code: 'hi',
  },
  {
    id: 'hk',
    title: '廣東話',
    code: 'hk',
  },
  {
    id: 'hr',
    title: 'Hrvatski jezik',
    code: 'hr',
  },
  {
    id: 'ht',
    title: 'Kreyòl ayisyen',
    code: 'ht',
  },
  {
    id: 'hu',
    title: 'Magyar nyelv',
    code: 'hu',
  },
  {
    id: 'hy',
    title: 'հայերեն',
    code: 'hy',
  },
  {
    id: 'id',
    title: 'Bahasa Indonesia',
    code: 'id',
  },
  {
    id: 'iq',
    title: 'العربية',
    code: 'iq',
  },
  {
    id: 'is',
    title: 'íslenska',
    code: 'is',
  },
  {
    id: 'it',
    title: 'Italiano',
    code: 'it',
  },
  {
    id: 'ja',
    title: '日本語',
    code: 'jp',
  },
  {
    id: 'ka',
    title: 'ქართული ენა',
    code: 'ge',
  },
  {
    id: 'km',
    title: 'ភាសាខ្មែរ',
    code: 'km',
  },
  {
    id: 'ko',
    title: '한국어',
    code: 'kr',
  },
  {
    id: 'ku',
    title: 'سۆرانی',
    code: 'ku',
  },
  {
    id: 'kz',
    title: 'Қазақ тілі',
    code: 'kz',
  },
  {
    id: 'ln',
    title: 'Lingála',
    code: 'ln',
  },
  {
    id: 'lo',
    title: 'ພາສາລາວ',
    code: 'lo',
  },
  {
    id: 'lt',
    title: 'Lietuvių kalba',
    code: 'lt',
  },
  {
    id: 'lv',
    title: 'Latviešu valoda',
    code: 'lv',
  },
  {
    id: 'mk',
    title: 'Македонски јазик',
    code: 'mk',
  },
  {
    id: 'mn',
    title: 'Монгол хэл',
    code: 'mn',
  },
  {
    id: 'ms',
    title: 'Bahasa Melayu',
    code: 'ms',
  },
  {
    id: 'mx',
    title: 'Español mexicano',
    code: 'mx',
  },
  {
    id: 'my',
    title: 'မြန်မာဘာသာစကား',
    code: 'my',
  },
  {
    id: 'nb',
    title: 'Norsk',
    code: 'nb',
  },
  {
    id: 'ne',
    title: 'Kurmancî',
    code: 'ne',
  },
  {
    id: 'pl',
    title: 'Polski',
    code: 'pl',
  },
  {
    id: 'pt',
    title: 'Português',
    code: 'pt',
  },
  {
    id: 'ro',
    title: 'Română',
    code: 'ro',
  },
  {
    id: 'ru',
    title: 'Русский',
    code: 'ru',
  },
  {
    id: 'sd',
    title: 'नेपाली',
    code: 'sd',
  },
  {
    id: 'si',
    title: 'සිංහල',
    code: 'si',
  },
  {
    id: 'sk',
    title: 'Slovenčin',
    code: 'sk',
  },
  {
    id: 'sl',
    title: 'Slovenščina',
    code: 'sl',
  },
  {
    id: 'so',
    title: 'Af Soomaali',
    code: 'so',
  },
  {
    id: 'sr',
    title: 'Српски',
    code: 'rs',
  },
  {
    id: 'sv',
    title: 'Svenska',
    code: 'se',
  },
  {
    id: 'sw',
    title: 'Kiswahili',
    code: 'sw',
  },
  {
    id: 'th',
    title: 'ภาษาไทย',
    code: 'th',
  },
  {
    id: 'tj',
    title: 'Tоҷикӣ',
    code: 'tj',
  },
  {
    id: 'tl',
    title: 'Tagalog',
    code: 'tl',
  },
  {
    id: 'tr',
    title: 'Türkçe',
    code: 'tr',
  },
  {
    id: 'tw',
    title: '漢語',
    code: 'tw',
  },
  {
    id: 'ua',
    title: 'Українська мова',
    code: 'ua',
  },
  {
    id: 'ur',
    title: 'اُردُو‎',
    code: 'ur',
  },
  {
    id: 'us',
    title: 'American',
    code: 'us',
  },
  {
    id: 'uz',
    title: 'Oʻzbek tili',
    code: 'uz',
  },
  {
    id: 'vi',
    title: 'Tiếng Việt',
    code: 'vi',
  },
  {
    id: 'zu',
    title: 'Zazaki',
    code: 'zu',
  },
];
