import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

export const NotificationCounter = styled(Box)({
  position: 'absolute',
  top: '-2px',
  right: '-4px',
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  borderRadius: '100%',
  background: appColors.primary.main,
});
