import { useRecordModal } from 'hooks/useRecordModal';
import { useRecordContext } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { ApiClient } from 'types';

import { ApiClientsListActiveFieldSwitchConfirmOff } from './ApiClientsListActiveFieldSwitchConfirmOff';
import { ApiClientsListActiveFieldSwitchConfirmOn } from './ApiClientsListActiveFieldSwitchConfirmOn';

export const ApiClientsListActiveFieldSwitch = () => {
  const {
    handleOpenRecordModal: handleOpenRecordModalOff,
    handleCloseRecordModal: handleCloseRecordModalOff,
    openRecordModal: openRecordModalOff,
  } = useRecordModal();
  const {
    handleOpenRecordModal: handleOpenRecordModalOn,
    handleCloseRecordModal: handleCloseRecordModalOn,
    openRecordModal: openRecordModalOn,
  } = useRecordModal();
  const record = useRecordContext<ApiClient>();

  const toggleActiveGatewayRule = () => {
    record.active ? handleOpenRecordModalOff(record) : handleOpenRecordModalOn(record);
  };

  return (
    <>
      <ApiClientsListActiveFieldSwitchConfirmOff
        apiClient={record}
        onClose={handleCloseRecordModalOff}
        open={openRecordModalOff}
      />
      <ApiClientsListActiveFieldSwitchConfirmOn
        apiClient={record}
        onClose={handleCloseRecordModalOn}
        open={openRecordModalOn}
      />
      <Switch
        checked={record.active}
        onClick={toggleActiveGatewayRule}
        size="medium"
        sx={{ marginRight: '16px' }}
      />
    </>
  );
};
