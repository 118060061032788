import { useEffect } from 'react';

import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { ImageUploader } from 'shared/mui/ImageUploader/ImageUploader';
import { TextInput } from 'shared/react-admin/Inputs';

type FormProps = {
  errors: any;
};

export function MethodLogosCreateForm({ errors }: FormProps): JSX.Element {
  const { setError } = useFormContext();

  useEffect(() => {
    if (errors) {
      errors?.violations.forEach((error: any) => {
        setError(error?.propertyPath, { type: 'string', message: error.message });
      });
    }
  }, [errors]);

  return (
    <CardContent sx={{ width: '396px', paddingTop: '0important' }}>
      <TextInput
        InputLabelProps={{ shrink: true }}
        fullWidth={true}
        helperText={false}
        key="name"
        label="Название"
        resettable
        size="medium"
        source="name"
        validate={required()}
        variant="outlined"
      />
      <ImageUploader />
    </CardContent>
  );
}
