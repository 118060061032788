import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type CardAdditionalButtonsProps = BoxProps;

export const CardAdditionalButtons: React.FC<CardAdditionalButtonsProps> = styled(Box)({
  width: '100%',
  padding: '0px 24px 16px 24px',
  boxSizing: 'border-box',
  height: '46px',
});
