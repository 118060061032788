import { useState } from 'react';

import { queryClient } from 'App';
import { deleteGatewayRules } from 'config/requests';
import { useGoBack } from 'hooks/useGoBack';
import { useGetOne, useNotify } from 'react-admin';

import { GatewayRuleModalDelete } from './GatewayRuleModalDelete';

type RenderProps = {
  onClick: () => void;
};

type Props = {
  id: string;
  merchantId: string;
  originId: string;
  render: (props: RenderProps) => React.ReactElement;
};

export const GatewayRuleRemoveButton: React.FC<Props> = ({ id, merchantId, originId, render }) => {
  const notify = useNotify();
  const { goBack } = useGoBack();
  const { data: merchant } = useGetOne('merchants', { id: merchantId });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const deleteRule = async () => {
    try {
      await deleteGatewayRules(id);
      notify('Правило успешно удалено', { type: 'success' });
      goBack();
    } catch (error) {
      notify(`Ошибка: ${error as string}` || 'Ошибка удаления правила', { type: 'error' });
    }
  };

  function toggleModalDelete(): void {
    setOpenModalDelete((prev) => !prev);
  }

  const onConfirm = async () => {
    await deleteRule();
    toggleModalDelete();
    await queryClient.refetchQueries(['gateway_rules']);
  };

  return (
    <>
      <GatewayRuleModalDelete
        merchant={merchant}
        onClose={toggleModalDelete}
        onConfirm={onConfirm}
        open={openModalDelete}
        originId={originId}
      />
      {render({ onClick: toggleModalDelete })}
    </>
  );
};
