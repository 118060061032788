import { DeleteStages } from 'constants/deleteStages';

import { Box } from 'shared/mui/Box';
import { DeleteStageDialogForm } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogForm';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';

type DeleteStageDialogProps = {
  title: string;
  details: string;
  children: React.ReactNode;
  stage: DeleteStages;
  deleteOneRecord: () => void;
} & DialogProps;

export const DeleteStageDialog: React.FC<DeleteStageDialogProps> = ({
  open,
  onClose,
  title,
  details,
  deleteOneRecord,
  children,
  stage,
}) => {
  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <Box
        sx={{
          display: 'flex',
          top: '50%',
          flexDirection: 'column',
          padding: 0,
          width: '600px',
        }}
      >
        <DeleteStageDialogForm
          deleteOneRecord={deleteOneRecord}
          details={details}
          onClose={onClose}
          open={open}
          stage={stage}
          title={title}
        >
          {children}
        </DeleteStageDialogForm>
      </Box>
    </Dialog>
  );
};
