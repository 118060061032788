import { ReactElement } from 'react';

import { FunctionField } from 'shared/react-admin/Fields';

import { OrderAdjustmentsReportAmountFieldContent } from './OrderAdjustmentsReportAmountFieldContent';
import { OrderAdjustments } from '../../../types/orderAdjustments';

export type Props = {
  label?: string | ReactElement | boolean;
  source?: string;
};

export const OrderAdjustmentsReportAmountField: React.FC<Props> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      render={(record: OrderAdjustments) => {
        return <OrderAdjustmentsReportAmountFieldContent record={record} />;
      }}
      source={source}
    />
  );
};
