import { SwitchProps } from '@mui/material/Switch';
import {
  BooleanInput as RaBooleanInput,
  BooleanInputProps as RaBooleanInputProps,
} from 'react-admin';

export type BooleanInputProps = { options?: SwitchProps } & Omit<RaBooleanInputProps, 'options'>;

const defaultSx = {
  '.MuiSwitch-thumb': {
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  },
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
};

export const BooleanInput: React.FC<BooleanInputProps> = ({
  options = {},
  sx,
  ...restProps
}: BooleanInputProps) => (
  <RaBooleanInput options={options} sx={{ ...sx, ...defaultSx }} {...restProps} />
);
