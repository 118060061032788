import { RaRecord } from 'react-admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { TextField } from 'shared/mui/TextField/TextField';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { GatewaysEditFormSettingsGatewayInfoBox } from './GatewaysEditFormSettingsGatewayInfoBox';
import { GatewaysEditFormSettingsGatewayTable } from './GatewaysEditFormSettingsGatewayTable';

type Props = {
  gatewayMethodFields: RaRecord[];
  isPilotModeSchedule: boolean;
};

export const GatewaysEditFormSettingsGateway = ({
  gatewayMethodFields,
  isPilotModeSchedule,
}: Props): JSX.Element => {
  const form = useFormContext();

  return (
    <>
      <Box mb={3}>
        <Typography variant="h6">Настройки шлюза для пилотного режима</Typography>
      </Box>
      {gatewayMethodFields?.length ? (
        <GatewaysEditFormSettingsGatewayTable gatewayMethodFields={gatewayMethodFields} />
      ) : (
        <GatewaysEditFormSettingsGatewayInfoBox />
      )}
      <Controller
        control={form.control}
        name="gatewayPilotModeSchedule.schedule"
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={
              <>
                <span style={{ marginRight: '4px' }}>Тестирование по расписанию</span>
                <TooltipWithInfoIcon tooltipText="Определяет период работы шлюза. В иные интервалы шлюз будет недоступен." />
              </>
            }
            sx={{ marginBottom: '24px' }}
          />
        )}
      />
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <Controller
            control={form.control}
            name="gatewayPilotModeSchedule.periodStart"
            render={({ field }) => (
              <DatePicker
                className="gatewayMethodsDatePicker"
                customInput={
                  <TextField
                    fullWidth
                    label="Начало периода"
                    size="small"
                    value={field.value}
                    variant="outlined"
                  />
                }
                dateFormat="Pp"
                disabled={!isPilotModeSchedule}
                endDate={form.getValues('gatewayPilotModeSchedule.periodEnd')}
                locale="ru"
                onChange={(date) => {
                  field.onChange(date);
                }}
                popperClassName="gatewayMethodsDatePickerPopper"
                selected={field.value}
                selectsStart
                showTimeSelect
                startDate={field.value}
                timeFormat="p"
                timeIntervals={5}
              />
            )}
          />
          <Controller
            control={form.control}
            name="gatewayPilotModeSchedule.timeStart"
            render={({ field }) => (
              <DatePicker
                className="gatewayMethodsDatePicker"
                customInput={
                  <TextField
                    fullWidth
                    label="Рабочее время с"
                    size="small"
                    value={field.value}
                    variant="outlined"
                  />
                }
                dateFormat="HH:mm"
                disabled={!isPilotModeSchedule}
                locale="ru"
                onChange={(date) => {
                  field.onChange(date);
                }}
                selected={field.value}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                timeIntervals={5}
              />
            )}
          />
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Controller
            control={form.control}
            name="gatewayPilotModeSchedule.periodEnd"
            render={({ field }) => (
              <DatePicker
                className="gatewayMethodsDatePicker"
                customInput={
                  <TextField
                    fullWidth
                    label="Конец периода"
                    size="small"
                    value={field.value}
                    variant="outlined"
                  />
                }
                dateFormat="Pp"
                disabled={!isPilotModeSchedule}
                endDate={field.value}
                locale="ru"
                onChange={(date) => {
                  field.onChange(date);
                }}
                popperClassName="gatewayMethodsDatePickerPopper"
                selected={field.value}
                selectsEnd
                showTimeSelect
                startDate={form.getValues('gatewayPilotModeSchedule.periodStart')}
                timeFormat="p"
                timeIntervals={5}
              />
            )}
          />
          <Controller
            control={form.control}
            name="gatewayPilotModeSchedule.timeEnd"
            render={({ field }) => (
              <DatePicker
                className="gatewayMethodsDatePicker"
                customInput={
                  <TextField
                    fullWidth
                    label="Рабочее время по"
                    size="small"
                    value={field.value}
                    variant="outlined"
                  />
                }
                dateFormat="HH:mm"
                disabled={!isPilotModeSchedule}
                locale="ru"
                onChange={(date) => {
                  field.onChange(date);
                }}
                selected={field.value}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                timeIntervals={5}
              />
            )}
          />
        </div>
      </Box>
    </>
  );
};
