import ContentAdd from '@mui/icons-material/Add';
import { useHistoryContext } from 'hooks/useHistoryContext';
import { useCreatePath, useResourceContext } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import { Button, ButtonProps } from 'shared/mui/Buttons';

type Props = {
  isLinkToModal?: boolean;
  target?: string;
};

export type CreateButtonProps = ButtonProps & Props;

export function CreateButton(props: CreateButtonProps): JSX.Element {
  const {
    isLinkToModal,
    label = 'Добавить',
    startIcon = <ContentAdd />,
    size = 'medium',
    variant = 'contained',
    ...rest
  } = props;

  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  const { baseRoute } = useHistoryContext();
  const location = useLocation();

  return (
    <Button
      component={Link}
      label={label}
      size={size}
      startIcon={startIcon}
      to={{
        pathname: createPath({ resource, type: 'create' }),
        ...(isLinkToModal && { state: { backgroundLocation: baseRoute || location } }),
      }}
      variant={variant}
      {...rest}
    />
  );
}
