import { AuthStep } from 'constants/authStep';

import { TextField, Button } from '@mui/material';
import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppTypography } from 'components/Common/Typography';
import { useCaptcha } from 'hooks/useCaptcha';
import { defaultTheme, useAuthProvider, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { executeCaptchaWithData } from 'utils';

export type AppTempPasswordFormProps = {
  updateStep: (step: AuthStep) => void;
};

export type TempPasswordFormData = {
  newPassword: string;
  repeatPassword: string;
};

export const AppTempPasswordForm = ({ updateStep }: AppTempPasswordFormProps): JSX.Element => {
  useCaptcha();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      newPassword: '',
      repeatPassword: '',
    },
  });

  const authProvider = useAuthProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const submit = (captchaToken: string, { newPassword, repeatPassword }: TempPasswordFormData) => {
    authProvider
      .changePassword({
        captchaToken,
        new_password: newPassword,
        repeat_password: repeatPassword,
      })
      .then(() => {
        navigate('/login');
        updateStep(authProvider.step);
      })
      .catch((reason: any) => {
        reason.data.errors.forEach((error: any) => {
          if (error.code === 'validation_error') {
            setError(error.source.parameter, { type: 'string', message: error.title });
          } else {
            notify(error.title, { type: 'error' });
          }
        });
      });
  };

  const proxySubmit = (data: TempPasswordFormData) => {
    executeCaptchaWithData(submit, data);
  };

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Container component="main" maxWidth="xs">
        <form onSubmit={handleSubmit(proxySubmit)}>
          <AppTypography size="small" sx={{ marginTop: '32px' }}>
            Придумайте новый пароль
          </AppTypography>
          <TextField
            type="password"
            {...register('newPassword', { required: 'Пароль обязателен для заполнения' })}
            autoComplete="current-password"
            error={Boolean(errors.newPassword)}
            fullWidth
            helperText={errors.newPassword?.message}
            label="Новый пароль"
            margin="normal"
            name="newPassword"
            required
            variant="outlined"
          />
          <TextField
            type="password"
            {...register('repeatPassword', { required: 'Подтверждение пароля обязательно' })}
            error={Boolean(errors.repeatPassword)}
            fullWidth
            helperText={errors.repeatPassword?.message}
            id="repeatPassword"
            label="Подтвердите пароль"
            margin="normal"
            name="repeatPassword"
            required
            variant="outlined"
          />
          <Button
            color="primary"
            fullWidth
            sx={{ marginTop: '16px' }}
            type="submit"
            variant="contained"
          >
            Войти
          </Button>
        </form>
      </Container>
    </ThemeProvider>
  );
};
