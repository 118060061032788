import { useEffect } from 'react';

import { useFirstRender } from 'hooks/useFirstRender';
import { FormDataConsumer, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Divider } from 'shared/mui/Divider/Divider';
import { SelectInput } from 'shared/react-admin/Inputs';
import { AutocompleteArrayInput, AutocompleteInput, BooleanInput } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { ProviderAccountsChip } from './ProviderAccountsChip';

export const ProviderAccountsForm = () => {
  const { isFirstRender } = useFirstRender();
  const { watch, setValue } = useFormContext();
  const [merchants, company, provider] = watch(['merchants', 'company', 'provider']);

  useEffect(() => {
    if (!isFirstRender) {
      setValue('merchants', []);
      setValue('merchantAccounts', []);
    }
  }, [company]);

  useEffect(() => {
    if (!isFirstRender) {
      setValue('merchantAccounts', []);
    }
  }, [provider, merchants?.length]);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <BooleanInput
            label="Партнерский"
            name="partner"
            source="partner"
            sx={{ marginBottom: '16px' }}
            variant="outlined"
          />
          <ReferenceInput
            filter={{ archive: false }}
            perPage={200}
            reference="companies"
            source="company"
          >
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Компания"
              optionText="name"
              size="medium"
              validate={required()}
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceInput perPage={200} reference="providers" source="provider">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Провайдер"
              optionText="name"
              size="medium"
              validate={required()}
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceArrayInput
            filter={
              formData.company
                ? { companies: [formData.company], archive: false }
                : { exists: { companies: false }, archive: false }
            }
            reference="merchants"
            source="merchants"
          >
            <AutocompleteArrayInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Мерчанты"
              optionText="name"
              size="medium"
              source="name"
              validate={required()}
              variant="outlined"
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            filter={{ merchants: formData?.merchants, provider: formData?.provider }}
            queryOptions={{ enabled: Boolean(formData?.merchants?.length && formData?.provider) }}
            reference="merchant_accounts"
            source="merchantAccounts"
          >
            <AutocompleteArrayInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Счета мерчантов"
              optionText="name"
              size="medium"
              source="name"
              validate={required()}
              variant="outlined"
            />
          </ReferenceArrayInput>
          <Divider sx={{ marginBottom: '24px', width: '100%' }} />
          <ReferenceInput reference="work_teams" source="workTeam">
            <SelectInput
              fullWidth
              label="Рабочая группа"
              optionText={<ProviderAccountsChip />}
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceInput>
        </>
      )}
    </FormDataConsumer>
  );
};
