import React from 'react';

import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { MenuItem, MenuItemProps, Tooltip } from '@mui/material';
import { useSidebarState } from 'react-admin';

export function AppSidebarButton(props: MenuItemProps): JSX.Element {
  const [openSidebar] = useSidebarState();

  return (
    <>
      {openSidebar ? (
        <MenuItem {...props}>
          <KeyboardDoubleArrowLeft sx={{ marginRight: '32px', color: 'rgba(0, 0, 0, 0.6)' }} />
          Свернуть
        </MenuItem>
      ) : (
        <Tooltip placement="right" title="Развернуть">
          <MenuItem {...props}>
            <KeyboardDoubleArrowRight sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </MenuItem>
        </Tooltip>
      )}
    </>
  );
}
