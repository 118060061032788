import React, { forwardRef } from 'react';

import { DatagridRow, DatagridRowProps } from '../DatagridRow/DatagridRow';

export type DatagridFieldsArrayRowProps = DatagridRowProps;

export const DatagridFieldsArrayRow: React.FC<DatagridFieldsArrayRowProps> = forwardRef(
  (props, ref) => {
    return <DatagridRow ref={ref} {...props} />;
  },
);

DatagridFieldsArrayRow.displayName = 'DatagridFieldsArrayRow';
