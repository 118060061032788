import { InviteStatuses } from 'constants/inviteStatuses';

import { useState } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import { SitesAvailabilityTable } from 'components/MerchantSpacesUsers/components/SiteAvailabilityTable/SitesAvailabilityTable';
import { useMerchantSpacesUsers } from 'hooks/queries/useMerchantSpacesUsers';
import { useRecordModal } from 'hooks/useRecordModal';
import { FunctionField, RecordContextProvider, TextField, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { Button, DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';
import { TableBody, TableCell, TableCellHeader, TableRow } from 'shared/mui/Table';
import { MerchantSpacesUsers } from 'types';
import { getId } from 'utils';

import { MerchantSpacesNameField } from './components/MerchantSpacesNameField';
import { MerchantSpacesRoleChip } from './components/MerchantSpacesRoleChip';
import { useCreateMerchantSpaceUsersInvitation } from './hooks/useCreateMerchantSpaceUsersInvitation';
import { BooleanField, InviteStatusChipField } from '../../shared/react-admin/Fields';
import { MerchantSpacesUsersInviteDialog } from '../../shared/widgets/MerchantSpacesUsersDatagrid/components/MerchantSpacesUsersDialogs/MerchantSpacesUsersInviteDialog';
import { MerchantSpacesUsersInviteRedirectDialog } from '../../shared/widgets/MerchantSpacesUsersDatagrid/components/MerchantSpacesUsersDialogs/MerchantSpacesUsersInviteRedirectDialog';

export const MerchantSpacesUsersShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [isRedirectDialogOpen, setIsRedirectDialogOpen] = useState(false);
  const [isInvitationCreated, setIsInvitationCreated] = useState(false);

  const { merchantSpacesUsers } = useMerchantSpacesUsers(id);

  const { createMerchantSpaceUsersInvitation } = useCreateMerchantSpaceUsersInvitation(() => {
    setIsInvitationCreated(true);
    setIsNotifyDialogOpen(false);
    setIsRedirectDialogOpen(true);
  });

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<MerchantSpacesUsers>();

  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: 'Просмотр пользователя мерчантов',
          listName: 'Пользователи мерчантов',
        }}
      />
      <Card
        sx={{
          width: '600px',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          marginBottom: 3,
        }}
      >
        <CardHeader title="Пользователь мерчантов" />
        <RecordContextProvider value={merchantSpacesUsers}>
          {!isInvitationCreated &&
            (!merchantSpacesUsers?.inviteStatus ||
              merchantSpacesUsers?.inviteStatus === InviteStatuses.Expired) && (
              <div
                style={{
                  padding: '0 24px 16px 24px',
                }}
              >
                <Button
                  label="Пригласить"
                  onClick={() => setIsNotifyDialogOpen(true)}
                  startIcon={<EmailIcon />}
                  sx={{
                    padding: '3px 14px',
                  }}
                  variant="contained"
                />
              </div>
            )}
          <CardTable>
            <TableBody>
              <TableRow>
                <TableCellHeader title="ID" width={165} />
                <TableCell>
                  <FunctionField render={(record: MerchantSpacesUsers) => getId(record?.id)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="Активен" />
                <TableCell>
                  <BooleanField source="active" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="Имя" />
                <TableCell>
                  <TextField source="firstName" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="Фамилия" />
                <TableCell>
                  <TextField source="lastName" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="E-mail" />
                <TableCell>
                  <TextField source="email" />
                </TableCell>
              </TableRow>
              {merchantSpacesUsers?.inviteStatus && (
                <TableRow>
                  <TableCellHeader title="Статус приглашения" />
                  <TableCell>
                    <InviteStatusChipField />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCellHeader title="Номер телефона" />
                <TableCell>
                  <TextField source="phoneNumber" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="Личный кабинет" />
                <TableCell>
                  <FunctionField
                    label="Личный кабинет"
                    render={() => {
                      return <MerchantSpacesNameField />;
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader title="Доступ" />
                <TableCell>
                  <FunctionField
                    render={() => {
                      return <MerchantSpacesRoleChip />;
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </CardTable>
          <CardHeader title="Доступность мерчантов" titleSize="small" />
          <SitesAvailabilityTable merchantSpaceUserId={merchantSpacesUsers?.id} />
          <CardActionsButtons
            leftActionsSlot={<EditLinkButton key="edit-button" variant="outlined" />}
            rightActionsSlot={
              <DeleteButton
                onClick={() => handleOpenRecordModal(merchantSpacesUsers)}
                variant="outlined"
              />
            }
          />
        </RecordContextProvider>
      </Card>
      <MerchantSpacesUsersInviteDialog
        onClose={() => setIsNotifyDialogOpen(false)}
        onSubmit={() => createMerchantSpaceUsersInvitation.mutate({ users: [Number(getId(id))] })}
        open={isNotifyDialogOpen}
        users={[merchantSpacesUsers as MerchantSpacesUsers]}
      />
      <MerchantSpacesUsersInviteRedirectDialog
        onClose={() => setIsRedirectDialogOpen(false)}
        open={isRedirectDialogOpen}
      />
      <DeleteDialog
        details={`Пользователь ${merchantSpacesUsers?.firstName + ' ' + merchantSpacesUsers?.lastName || ''} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => redirect('/merchant_spaces_users')}
        open={openRecordModal}
        record={merchantSpacesUsers}
        title="Удаление пользователя"
      />
    </Box>
  );
};
