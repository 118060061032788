import React from 'react';

import { Box } from '@mui/material';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { PageHeader } from 'shared/mui/PageHeader';
import { EditGuesserProps } from 'shared/react-admin/Guessers';

import { StyledEditGuesser } from './EditGuesserPage.styled';

export type EditGuesserPageProps = {
  children: React.ReactNode;
  leftActionsSlot?: JSX.Element | JSX.Element[];
  listName: string;
  rightActionsSlot?: JSX.Element | JSX.Element[];
  title: string;
} & EditGuesserProps;

export function EditGuesserPage({
  children,
  leftActionsSlot,
  listName,
  rightActionsSlot,
  title,
  ...props
}: EditGuesserPageProps): JSX.Element {
  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: title,
          listName,
        }}
      />
      <StyledEditGuesser actions={false} toolbar={false} {...props}>
        <CardHeader title={title} />
        {children}
        <CardActionsButtons leftActionsSlot={leftActionsSlot} rightActionsSlot={rightActionsSlot} />
      </StyledEditGuesser>
    </Box>
  );
}
