import { useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { JsonEditor, JsonEditorProps } from 'json-edit-react';
import { Labeled, useInput } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip';

const jsonValueColor = 'rgba(25, 118, 210, 1)';

const theme = [
  'monoLight',
  {
    container: {
      backgroundColor: '#f6f6f6',
      fontFamily: 'Roboto, monospace',
      fontSize: '16px',
    },
    string: jsonValueColor,
    number: jsonValueColor,
    boolean: { color: jsonValueColor, fontStyle: 'normal' },
    null: { color: jsonValueColor, fontVariant: 'normal', fontWeight: 'normal' },
    iconCollection: 'rgb(0, 43, 54)',
    iconEdit: 'edit',
    iconDelete: 'rgb(203, 75, 22)',
    iconAdd: 'green',
    iconCopy: 'rgb(38, 139, 210)',
    iconOk: 'green',
    iconCancel: 'rgb(203, 75, 22)',
  },
] as JsonEditorProps['theme'];

export const JsonInput = (props: any): JSX.Element => {
  const { field, isRequired } = useInput(props);
  const [data, setData] = useState({});
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    if (field.value) {
      setData(field.value);
    }
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 2000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  const isEmptyData = !Object.keys(data).length;

  return (
    <Labeled {...props} isRequired={isRequired}>
      <Box sx={{ position: 'relative' }}>
        <JsonEditor
          data={data}
          onUpdate={({ newData }) => {
            setData(newData);
            field.onChange(newData);
          }}
          stringTruncate={1000000}
          theme={theme}
        />
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={!!tooltipText}
          sx={{ position: 'absolute', top: '8px', right: '24px' }}
          title={tooltipText}
        >
          <IconButton disabled={isEmptyData} onClick={handleCopy}>
            <ContentCopyIcon color={isEmptyData ? 'secondary' : 'primary'} />
          </IconButton>
        </Tooltip>
      </Box>
    </Labeled>
  );
};
