import { useEffect, useState } from 'react';

import UploadIcon from '@mui/icons-material/Upload';
import { useRecordModal } from 'hooks/useRecordModal';
import { useGetOne, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import {
  BooleanInput,
  SelectInputIntrospected,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';
import {
  ReferenceArrayInputIntrospected,
  ReferenceInput,
} from 'shared/react-admin/ReferenceInputs';
import { MethodLogo } from 'types';

import { MethodLogosCreateLogoModal } from './MethodLogosCreateLogoModal';
import { MethodsImageField } from './MethodsImageField';
import { MethodsLogoAutocompleteInput } from './MethodsLogoAutocompleteInput';

export const MethodsForm: React.FC = () => {
  const record = useRecordContext();
  const [newLogoId, setNewLogoId] = useState<string | null | undefined>(null);
  const [logoImageUrl, setLogoImageUrl] = useState<string | null | undefined>(null);
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } = useRecordModal<null>();

  const { data: logo } = useGetOne<MethodLogo>(
    'method_logos',
    {
      id: newLogoId || record?.logo,
    },
    {
      enabled: !!newLogoId || !!record?.logo,
    },
  );

  useEffect(() => {
    if (logo) {
      setLogoImageUrl(logo?.filePath);
    }
  }, [logo?.filePath]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        sx={{
          width: '328px',
          paddingRight: '24px',
          borderRight: '1px solid rgba(0, 0, 0, 0.08)',
        }}
      >
        <TextInputIntrospected
          fullWidth
          label="Название"
          size="medium"
          source="name"
          variant="outlined"
        />
        <TextInputWithAliasIntrospected
          fullWidth
          label="Алиас"
          size="medium"
          source="alias"
          variant="outlined"
        />
        <ReferenceInput reference="method_types" source="methodType">
          <SelectInputIntrospected
            fullWidth
            label="Тип метода"
            optionText="name"
            size="medium"
            source="name"
            variant="outlined"
          />
        </ReferenceInput>
        <BooleanInput label="Активен" source="active" />
        <Box sx={{ paddingTop: '16px', borderTop: '1px solid rgba(0, 0, 0, 0.08)' }}>
          <ReferenceArrayInputIntrospected
            isFilter={true}
            perPage={50}
            reference="method_logos"
            source="logo"
          >
            <MethodsLogoAutocompleteInput
              defaultValue={logo}
              label="Лого"
              name="logo"
              onChangeLogo={setLogoImageUrl}
            />
          </ReferenceArrayInputIntrospected>
        </Box>
        <Button
          fullWidth
          label="Загрузить новый"
          onClick={() => handleOpenRecordModal(null)}
          size="large"
          startIcon={<UploadIcon />}
          sx={{ marginTop: '16px' }}
          variant="outlined"
        />
      </Box>
      <MethodsImageField imgSrc={logoImageUrl} />
      <MethodLogosCreateLogoModal
        closeModal={handleCloseRecordModal}
        onSetLogo={setNewLogoId}
        openModal={openRecordModal}
      />
    </Box>
  );
};
