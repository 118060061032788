import React from 'react';

import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';
import { MerchantSpacesUsers } from 'types';

type Props = {
  users?: MerchantSpacesUsers[];
};

export const MerchantSpacesUsersInviteList = ({ users }: Props) => {
  return (
    <ul style={{ paddingLeft: '20px', marginTop: 1 }}>
      {users?.map((user) => (
        <li key={user.id}>
          {`${user?.firstName} ${user?.lastName} | `}
          <Typography color={appColors.primary.main} component="span">
            {user?.email}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
