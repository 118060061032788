import { usePermissions } from 'hooks/usePermissions';
import { required } from 'react-admin';
import { Divider } from 'shared/mui/Divider/Divider';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  TextInput,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { TextInputWithAlias } from 'shared/react-admin/Inputs/TextInputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency, Permission } from 'types';

import { GatewaysEditFormSettingsGateway } from './GatewaysEditFormSettingsGateway/GatewaysEditFormSettingsGatewayAutocomplete';
import { useGatewayMethodsFieldArray } from './hooks/useGatewayMethodsFieldArray';
import { useMethodsState } from './hooks/useMethodsState';
import { GatewaysChip } from '../components/GatewaysChip/GatewaysChip';
import { GatewayStatusSelectInput } from '../components/GatewayStatusSelectInput/GatewayStatusSelectInput';

const booleanInputStyle = { marginBottom: '24px' };

type Props = {
  isDisabledGatewayStatus: boolean;
};

export const GatewaysEditFormMain: React.FC<Props> = ({ isDisabledGatewayStatus }) => {
  const { hasResourcePermissions } = usePermissions();
  const { onAddGatewayMethod, onRemoveGatewayMethod } = useGatewayMethodsFieldArray();
  const { currentMethods, setCurrentMethods } = useMethodsState();

  return (
    <>
      <TextInputIntrospected
        fullWidth
        isRequired
        label="Название"
        size="medium"
        source="name"
        variant="outlined"
      />
      <TextInputWithAlias fullWidth isRequired label="Алиас" source="alias" variant="outlined" />
      <GatewayStatusSelectInput />
      {isDisabledGatewayStatus && (
        <ReferenceInput
          perPage={200}
          reference="gateway_deactivation_reasons"
          source="deactivationReason"
        >
          <AutocompleteInput
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            isRequired
            label="Причина деактивации"
            optionText="reason"
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      )}
      <BooleanInput label="Требуется сумма" source="requireAmount" sx={booleanInputStyle} />
      <BooleanInput label="PCI DSS" source="pcidss" sx={booleanInputStyle} />
      {hasResourcePermissions('providers', [Permission.Full, Permission.Read]) && (
        <ReferenceInput perPage={200} reference="providers" source="provider">
          <AutocompleteInput
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            isRequired
            label="Провайдер"
            optionText="name"
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      )}
      <ReferenceInput isRequired reference="gateway_directions" source="direction">
        <SelectInput
          fullWidth
          isRequired
          label="Направления"
          optionText={<GatewaysChip />}
          size="medium"
          source="value"
          variant="outlined"
        />
      </ReferenceInput>
      <GatewaysEditFormSettingsGateway
        currentMethods={currentMethods}
        onAddGatewayMethod={onAddGatewayMethod}
        onRemoveGatewayMethod={onRemoveGatewayMethod}
        setCurrentMethods={setCurrentMethods}
      />
      {hasResourcePermissions('currencies', [Permission.Full, Permission.Read]) && (
        <ReferenceInput
          filter={{ active: true }}
          perPage={1000}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="defaultCurrency"
        >
          <AutocompleteInput
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            isRequired
            label="Валюта по умолчанию"
            matchSuggestion={(filter, choice) => {
              return choice.name.match(filter);
            }}
            optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      )}
      {hasResourcePermissions('currencies', [Permission.Full, Permission.Read]) && (
        <ReferenceArrayInput
          filter={{ active: true }}
          perPage={1000}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="supportedCurrencies"
        >
          <AutocompleteArrayInput
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            label="Поддерживаемые валюты"
            optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
            size="medium"
            source="name"
            variant="outlined"
          />
        </ReferenceArrayInput>
      )}
      <NumberInput
        InputLabelProps={{ shrink: true }}
        format={(value) => value || 0}
        fullWidth
        label="Срок действия заказа (сек)"
        name="orderExpirationTimeSec"
        parse={(value) => value || 0}
        size="medium"
        source="orderExpirationTimeSec"
        validate={required()}
        variant="outlined"
      />
      <BooleanInput
        label="Сохранить данные метода пользователя"
        name="saveCustomerMethodData"
        source="saveCustomerMethodData"
        sx={booleanInputStyle}
      />
      <Divider flexItem key="divider" orientation="horizontal" sx={{ marginBottom: 3 }} />
      <BooleanInput
        label="Опрос статуса заказа"
        name="statusRequestEnabled"
        source="statusRequestEnabled"
        sx={booleanInputStyle}
      />
      <NumberInput
        InputLabelProps={{ shrink: true }}
        defaultValue={50}
        format={(value) => value || 0}
        fullWidth
        label="Макс. количество попыток опроса"
        name="transactionRequestMaxAttempts"
        parse={(value) => value || 0}
        size="medium"
        source="transactionRequestMaxAttempts"
        variant="outlined"
      />
      <TextInput
        defaultValue="5, 10, 15, 20"
        fullWidth
        isRequired
        label="Интервал опроса статуса"
        name="attemptIntervalConfig"
        size="medium"
        source="attemptIntervalConfig"
        variant="outlined"
      />
    </>
  );
};
