import { StyledTableCellHeader } from './TableCellHeader.styled';
import { TableCellProps } from '..';

export type TableCellHeaderProps = TableCellProps;

export const TableCellHeader = ({ title, ...props }: TableCellHeaderProps) => (
  <StyledTableCellHeader {...props} variant="head">
    {title}
  </StyledTableCellHeader>
);
