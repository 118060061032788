import { getExpressionParamWithData } from 'config/requests';
import { useQuery } from 'react-query';
import { ExpressionParamWithData } from 'types';

export type UseExpressionParamWithDataReturnValue = {
  expressionParamWithData: ExpressionParamWithData | undefined;
  loadingExpressionParamWithData: boolean;
};

export function useExpressionParamWithData(
  expressionAlias: string,
): UseExpressionParamWithDataReturnValue {
  const { data: expressionParamWithData, isLoading: loadingExpressionParamWithData } =
    useQuery<ExpressionParamWithData>(
      ['expression_param_with_data', expressionAlias],
      () => getExpressionParamWithData(expressionAlias),
      { enabled: !!expressionAlias },
    );

  return { expressionParamWithData, loadingExpressionParamWithData };
}
