import { Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
} & Omit<DialogProps, 'open'>;

const StyledDialog = styled(Dialog)({
  '.MuiBackdrop-root': {
    transition: 'none !important',
  },
  '.MuiCardContent-root': {
    padding: '0!important',
  },
  '.RaCreate-main': {
    marginTop: '0!important',
  },
  '.MuiDialog-root': {
    marginTop: '0!important',
  },
});

export function AppCreateDialog({
  children,
  maxWidth = 'sm',
  fullWidth = true,
  ...restProps
}: Props): JSX.Element {
  return (
    <StyledDialog
      TransitionComponent={({ children }) => children}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={true}
      {...restProps}
    >
      {children}
    </StyledDialog>
  );
}
