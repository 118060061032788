import { OrderStatuses } from 'constants/orderStatuses';

import { useState } from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '@mui/material';
import { queryClient } from 'App';
import { orderAccept, orderDecline } from 'config/requests';
import { useGetOne, useNotify, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { appColors } from 'themes/variables';

import { OrderConfirmationActionDialog } from './OrderConfirmationActionDialog';
import { OrderConfirmationRedirectDialog } from './OrderConfirmationRedirectDialog';
import { Order } from '../../../../types';
import { AppTypography } from '../../../Common/Typography';

export const OrderConfirmationPanel = () => {
  const { originId, gateway } = useRecordContext<Order>();
  const notify = useNotify();
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const [isRedirectAcceptDialogOpen, setIsRedirectAcceptDialogOpen] = useState(false);
  const [isRedirectDeclineDialogOpen, setIsRedirectDeclineDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data: gatewayData } = useGetOne('gateways', { id: gateway });

  const handleOrderAccept = () => {
    setIsAcceptDialogOpen(false);
    orderAccept(originId)
      .then(() => {
        notify('Заказ успешно подтвержден.', { type: 'success' });
        setIsRedirectAcceptDialogOpen(true);
      })
      .catch((response) => {
        const error = response.data.errors?.[0]?.title || 'Не удалось подтвердить заказ.';
        notify(`Ошибка: ${error}`, { type: 'error' });
      });
  };

  const handleOrderDecline = () => {
    setIsDeclineDialogOpen(false);
    orderDecline(originId)
      .then(() => {
        notify('Заказ успешно отклонен.', { type: 'success' });
        setIsRedirectDeclineDialogOpen(true);
      })
      .catch((response) => {
        const error = response.data.errors?.[0]?.title || 'Не удалось отклонить заказ.';
        notify(`Ошибка: ${error}`, { type: 'error' });
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        padding: '14px 16px',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), #ED6C02',
        borderRadius: '4px',
      }}
    >
      <OrderConfirmationActionDialog
        actionButton={
          <Button
            color="success"
            onClick={handleOrderAccept}
            sx={{ height: 36 }}
            variant="contained"
          >
            подтвердить
          </Button>
        }
        onClose={() => {
          setIsAcceptDialogOpen(false);
        }}
        open={isAcceptDialogOpen}
        text={`Заказ #${originId} был создан через шлюз ${gatewayData?.name} работающий в тестовом режиме.
          Подтверждение заказа должно выполняться после его полной и успешной проверки.`}
        title="Подтверждение заказа"
      />
      <OrderConfirmationActionDialog
        actionButton={
          <Button
            color="error"
            onClick={handleOrderDecline}
            sx={{ height: 36 }}
            variant="contained"
          >
            отклонить
          </Button>
        }
        onClose={() => {
          setIsDeclineDialogOpen(false);
        }}
        open={isDeclineDialogOpen}
        text={`Заказ #${originId} был создан через шлюз ${gatewayData?.name} работающий в тестовом режиме.
        Отклонение заказа должно выполняться после его полной проверки, по итогам которой были найдены ошибки.`}
        title="Отклонение заказа"
      />
      <OrderConfirmationRedirectDialog
        onClick={() => {
          navigate(`/gateways/${encodeURIComponent(gatewayData ? gatewayData.id : '')}`);
        }}
        onClose={async () => {
          await queryClient.invalidateQueries(['orders']);
          setIsRedirectAcceptDialogOpen(false);
        }}
        open={isRedirectAcceptDialogOpen}
        text={`Заказ #${originId} успешно подтверждён.
        Вы можете перейти к настройкам шлюза ${gatewayData?.name} чтобы перевести его в стандартный режим работы.`}
        title="Заказ подвержден"
      />
      <OrderConfirmationRedirectDialog
        onClick={() => {
          navigate(`/gateways/${encodeURIComponent(gatewayData ? gatewayData.id : '')}`);
        }}
        onClose={async () => {
          await queryClient.invalidateQueries(['orders']);
          setIsRedirectDeclineDialogOpen(false);
        }}
        open={isRedirectDeclineDialogOpen}
        text={`Заказ #${originId} отклонён.
        Вы можете перейти к настройкам шлюза ${gatewayData?.name} чтобы изменить их.`}
        title="Заказ отклонен"
      />
      <div>
        <WarningAmberIcon
          sx={{
            width: '20px',
            height: '18px',
            marginTop: '3px',
            color: appColors.orderStatus[OrderStatuses.WaitingConfirmation],
          }}
        />
      </div>
      <div style={{ marginLeft: '12px', marginRight: '16px' }}>
        <AppTypography sx={{ fontWeight: 500, marginBottom: '6px' }}>Проверка заказа</AppTypography>
        <AppTypography>
          {`Заказ #${originId} был создан через шлюз ${gatewayData?.name} который работает в тестовом режиме.
          Необходимо выполнить проверку заказа и подтвердить или отклонить его.`}
        </AppTypography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Button
          color="success"
          onClick={() => {
            setIsAcceptDialogOpen(true);
          }}
          sx={{ height: '30px' }}
          variant="contained"
        >
          подтвердить
        </Button>
        <Button
          color="error"
          onClick={() => setIsDeclineDialogOpen(true)}
          sx={{ height: '30px' }}
          variant="contained"
        >
          отклонить
        </Button>
      </div>
    </div>
  );
};
