import { ReactElement } from 'react';

import { RecordContextProvider } from 'react-admin';
import { FunctionField } from 'shared/react-admin/Fields';
import { AdjustmentStatusChipField } from 'shared/react-admin/Fields/AdjustmentStatusChipField/AdjustmentStatusChipField';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { AdjustmentStatus } from '../../../types/adjustmentStatus';

type Props = {
  label?: string | ReactElement | boolean;
};

export const OrderAdjustmentsReportStatusField: React.FC<Props> = ({ label }) => {
  return (
    <ReferenceField
      label={label}
      link={false}
      reference="adjustment_statuses"
      source="adjustmentStatus"
    >
      <FunctionField
        render={(record: AdjustmentStatus) => (
          <RecordContextProvider value={record}>
            <AdjustmentStatusChipField source="description" />
          </RecordContextProvider>
        )}
      />
    </ReferenceField>
  );
};
