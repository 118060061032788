import { styled } from '@mui/material/styles';
import { Chip } from 'shared/mui/Chips';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { appColors } from 'themes/variables';

export const StyledChip = styled(Chip)({
  marginRight: '12px',
});

export const StyledDatagrid = styled(Datagrid)({
  width: '600px',
  padding: 0,
  borderRadius: 0,
  border: 'none',
  borderBottom: `1px solid ${appColors.divider}`,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: '4px 24px',
    height: '48px',
  },
  '& .MuiTableCell-head': {
    verticalAlign: 'top',
    paddingTop: '12px',
  },
  '& .column-methodName': {
    width: '164px',
    maxWidth: '164px',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  '& .column-active': {
    width: '140px',
  },
  '& .column-autoDeactivation': {
    paddingRight: '16px',
  },
});
