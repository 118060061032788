import { createContext, useContext, useState } from 'react';

import { MappingItemRecord, Method } from 'types';

type MappingData = {
  method?: Method;
  mapping?: MappingItemRecord;
};

type CurrentMappingContextType = {
  mappingData?: MappingData;
  setMappingData: (data: MappingData) => void;
  mappingChosen?: MappingItemRecord;
  setMappingChosen: (data: MappingItemRecord | undefined) => void;
};

export const CurrentMappingContext = createContext<CurrentMappingContextType>({
  mappingData: undefined,
  setMappingData: () => undefined,
  mappingChosen: undefined,
  setMappingChosen: () => undefined,
});

type Props = {
  children: React.ReactNode;
};

export const CurrentMappingContextProvider: React.FC<Props> = ({ children }) => {
  const [mappingData, setMappingData] = useState<MappingData | undefined>(undefined);
  const [mappingChosen, setMappingChosen] = useState<MappingItemRecord | undefined>(undefined);

  return (
    <CurrentMappingContext.Provider
      value={{ mappingData, setMappingData, mappingChosen, setMappingChosen }}
    >
      {children}
    </CurrentMappingContext.Provider>
  );
};

export const useCurrentMappingDataContext = () => {
  const context = useContext(CurrentMappingContext);

  if (!context) {
    throw new Error(
      'Can not `useCurrentMappingDataContext` outside of the `CurrentMappingContextProvider`',
    );
  }

  return context;
};
