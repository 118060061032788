import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type Props = {
  value: string;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: ConversionPeriod) => void;
};

export const enum ConversionPeriod {
  Minutes60 = '60',
  Hours3 = '3',
  Hours5 = '5',
  Hours7 = '7',
}

export const OrderConversionListPeriodToggle: React.FC<Props> = ({ value, onChange }) => {
  return (
    <ToggleButtonGroup
      aria-label="Platform"
      color="primary"
      exclusive
      onChange={onChange}
      value={value}
    >
      <ToggleButton value={ConversionPeriod.Minutes60}>60 минут</ToggleButton>
      <ToggleButton value={ConversionPeriod.Hours3}>3 часа</ToggleButton>
      <ToggleButton value={ConversionPeriod.Hours5}>5 часов</ToggleButton>
      <ToggleButton value={ConversionPeriod.Hours7}>7 часов</ToggleButton>
    </ToggleButtonGroup>
  );
};
