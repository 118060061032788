import { styled } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Chip, ChipProps } from '..';

type ColorIconProps = {
  color: string;
};

const ColorIcon = styled('div')<ColorIconProps>(({ color }) => ({
  backgroundColor: color,
  borderRadius: '100%',
  width: '16px',
  height: '16px',
}));

type Props = {
  iconColor?: string;
} & ChipProps;

export const ColorChip = ({ iconColor, ...rest }: Props) => {
  return <Chip icon={<ColorIcon color={iconColor || grey[500]} />} {...rest} />;
};
