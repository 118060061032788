import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { ACCESS_SELECT_ITEMS } from '../constants/accessSelectItems';
import { SECURITY_ISSUES_SELECT_ITEMS } from '../constants/securityIssues';

export const ApiClientsListFilter: React.FC = () => {
  return (
    <>
      <TextInputShrink
        helperText={false}
        key="name"
        label="Название"
        resettable
        source="name"
        variant="outlined"
      />
      <SelectInputShrink
        choices={ACCESS_SELECT_ITEMS}
        defaultValue={null}
        fullWidth
        helperText={false}
        id="roles"
        label="Доступ"
        name="roles"
        optionText="label"
        optionValue="value"
        source="roles"
        variant="outlined"
      />
      <ReferenceInput perPage={200} reference="merchants" source="merchants">
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Мерчанты"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <SelectInputShrink
        choices={SECURITY_ISSUES_SELECT_ITEMS}
        defaultValue={null}
        fullWidth
        helperText={false}
        id="securityIssues"
        label="Уведомление безопасности"
        name="securityIssues"
        source="securityIssues"
        variant="outlined"
      />
    </>
  );
};
