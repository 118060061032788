import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { useGetOne, useNotify } from 'react-admin';
import { DeleteDialog, DeleteDialogProps } from 'shared/mui/Dialogs';
import { Provider } from 'types';

export const ProviderWhiteIpAddressesDeleteDialog: React.FC<
  Omit<DeleteDialogProps, 'details' | 'title'>
> = (props) => {
  const notify = useNotify();
  const { goBack } = useGoBack();
  const providerCurrent = useGetOne<Provider>('providers', { id: props.record?.provider });

  const onSuccess = async () => {
    await queryClient.invalidateQueries(['commissions']);
    notify('Белый IP успешно удален!', {
      type: 'success',
      autoHideDuration: 3000,
    });
    goBack();
  };
  return (
    <DeleteDialog
      {...props}
      details={`Белый IP ${props.record?.ip || ''} для провайдера ${providerCurrent?.data?.name || ''} будет удален без возможности восстановления.`}
      onSuccess={onSuccess}
      title="Удаление белого IP"
    />
  );
};
