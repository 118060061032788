import React, { useEffect, useState } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

import { AlertWrapperStyled } from './FilterIdDialog.styled';
import { getIdsArray } from '../../../../../utils';
import { Alert } from '../../../Alert/Alert';
import { CardActionsButtons, CardHeader } from '../../../Card';
import { CardAdditionalButtons } from '../../../Card/CardAdditionalButtons/CardAdditionalButtons';
import { CardContent } from '../../../Card/CardContent/CardContent';
import { Dialog } from '../../../Dialogs';
import { CloseIconButton } from '../../../IconButtons';
import { FilterApplyButton } from '../../FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from '../../FilterButtons/FilterResetButton/FilterResetButton';
import { FilterIdsValues, FilterIdTypes } from '../../hooks/useIdFilters';

type FilterIdDialogProps = {
  currentIdFilter: FilterIdTypes | undefined;
  onClose: () => void;
  onReset: (currentIdFilter: FilterIdTypes) => void;
  notFoundIds: string[] | undefined;
  notFoundProviderIds: string[] | undefined;
  notFoundMerchantIds: string[] | undefined;
  onSubmit: SubmitHandler<FilterIdsValues>;
  open: boolean;
  omit?: FilterIdTypes[];
  onChange?: (currentFilter: FilterIdTypes) => void;
};

export const FilterIdDialog: React.FC<FilterIdDialogProps> = ({
  open,
  omit,
  currentIdFilter,
  onClose,
  onReset,
  onChange,
  onSubmit,
  notFoundIds,
  notFoundProviderIds,
  notFoundMerchantIds,
}) => {
  const { resetField, handleSubmit, control, watch } = useFormContext();
  const [currentFilter, setCurrentFilter] = useState(FilterIdTypes.Id);

  const idWatched = watch(FilterIdTypes.Id);
  const idMerchantWatched = watch(FilterIdTypes.Merchant);
  const idProviderWatched = watch(FilterIdTypes.Provider);

  useEffect(() => {
    setCurrentFilter(currentIdFilter || FilterIdTypes.Id);
  }, [currentIdFilter]);

  const onChangeHandler = (_: React.MouseEvent<HTMLElement>, newFilter: FilterIdTypes) => {
    if (!newFilter) {
      return;
    }

    onChange && onChange(newFilter);
    setCurrentFilter(newFilter);
  };

  const onSubmitHandler = (values: FilterIdsValues) => {
    onSubmit(values);
    onClose();
  };

  const onResetHandler = () => {
    onReset(currentFilter);
    resetField(currentFilter);
    onClose();
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open} transitionDuration={0}>
      <form onSubmit={handleSubmit(onSubmitHandler as SubmitHandler<FieldValues>)}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Поиск по ID"
        />
        <CardAdditionalButtons sx={{ height: 'auto' }}>
          <ToggleButtonGroup
            aria-label="Platform"
            color="primary"
            exclusive
            onChange={onChangeHandler}
            value={currentFilter}
          >
            {!omit?.includes(FilterIdTypes.Id) && (
              <ToggleButton
                disabled={idProviderWatched?.length > 0 || idMerchantWatched?.length > 0}
                value={FilterIdTypes.Id}
              >
                Kubera
              </ToggleButton>
            )}
            {!omit?.includes(FilterIdTypes.Merchant) && (
              <ToggleButton
                disabled={idWatched?.length > 0 || idProviderWatched?.length > 0}
                value={FilterIdTypes.Merchant}
              >
                Мерчант
              </ToggleButton>
            )}
            {!omit?.includes(FilterIdTypes.Provider) && (
              <ToggleButton
                disabled={idWatched?.length > 0 || idMerchantWatched?.length > 0}
                value={FilterIdTypes.Provider}
              >
                Провайдер
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </CardAdditionalButtons>
        {Boolean(notFoundIds?.length) && (
          <AlertWrapperStyled>
            <Alert severity="warning">Не обнаружены id: {`${notFoundIds?.join(' ')}`}</Alert>
          </AlertWrapperStyled>
        )}
        {Boolean(notFoundMerchantIds?.length) && (
          <AlertWrapperStyled>
            <Alert severity="warning">
              Не обнаружены id: {`${notFoundMerchantIds?.join(' ')}`}
            </Alert>
          </AlertWrapperStyled>
        )}
        {Boolean(notFoundProviderIds?.length) && (
          <AlertWrapperStyled>
            <Alert severity="warning">
              Не обнаружены id: {`${notFoundProviderIds?.join(' ')}`}
            </Alert>
          </AlertWrapperStyled>
        )}
        {Boolean(
          !idWatched?.length && !idMerchantWatched?.length && !idProviderWatched?.length,
        ) && (
          <AlertWrapperStyled>
            <Alert severity="info">Укажите список ID заказов через пробел.</Alert>{' '}
          </AlertWrapperStyled>
        )}

        <CardContent>
          {currentFilter === FilterIdTypes.Id && (
            <Controller
              control={control}
              name={FilterIdTypes.Id}
              render={({ field, fieldState }) => (
                <MuiChipsInput
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Kubera ID"
                  placeholder="Введите ID и нажмите Enter"
                  {...field}
                  error={fieldState.invalid}
                  hideClearAll
                  onPaste={(e) => {
                    e.preventDefault();
                    const newValue = getIdsArray(e.clipboardData.getData('Text')) || [];
                    field.onChange([...field.value, ...newValue]);
                  }}
                  renderChip={(Component, key, props) => {
                    return (
                      <Component
                        {...props}
                        color={notFoundIds?.includes(props.label) ? 'warning' : undefined}
                        key={key}
                      />
                    );
                  }}
                  variant="outlined"
                />
              )}
            />
          )}
          {currentFilter === FilterIdTypes.Merchant && (
            <Controller
              control={control}
              name={FilterIdTypes.Merchant}
              render={({ field, fieldState }) => (
                <MuiChipsInput
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="ID мерчантов"
                  placeholder="Введите ID и нажмите Enter"
                  {...field}
                  error={fieldState.invalid}
                  hideClearAll
                  onPaste={(e) => {
                    e.preventDefault();
                    const newValue = getIdsArray(e.clipboardData.getData('Text')) || [];
                    field.onChange([...field.value, ...newValue]);
                  }}
                  renderChip={(Component, key, props) => {
                    return (
                      <Component
                        {...props}
                        color={notFoundMerchantIds?.includes(props.label) ? 'warning' : undefined}
                        key={key}
                      />
                    );
                  }}
                  variant="outlined"
                />
              )}
            />
          )}
          {currentFilter === FilterIdTypes.Provider && (
            <Controller
              control={control}
              name={FilterIdTypes.Provider}
              render={({ field, fieldState }) => (
                <MuiChipsInput
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="ID провайдеров"
                  placeholder="Введите ID и нажмите Enter"
                  {...field}
                  error={fieldState.invalid}
                  hideClearAll
                  onPaste={(e) => {
                    e.preventDefault();
                    const newValue = getIdsArray(e.clipboardData.getData('Text')) || [];
                    field.onChange([...field.value, ...newValue]);
                  }}
                  renderChip={(Component, key, props) => {
                    return (
                      <Component
                        {...props}
                        color={notFoundProviderIds?.includes(props.label) ? 'warning' : undefined}
                        key={key}
                      />
                    );
                  }}
                  variant="outlined"
                />
              )}
            />
          )}
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <FilterApplyButton key="apply" type="submit" />,
            <FilterResetButton key="reset" label="Очистить" onClick={onResetHandler} />,
          ]}
        />
      </form>
    </Dialog>
  );
};
