import ImageEye from '@mui/icons-material/RemoveRedEye';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ShowButtonProps } from 'react-admin';

import { StyledShowIconButton } from './ShowIconButton.styled';

export type ShowIconButtonProps = {
  iconSx?: SxProps<Theme>;
} & ShowButtonProps;

export function ShowIconButton({
  label = '',
  variant,
  iconSx,
  ...restProps
}: ShowIconButtonProps): JSX.Element {
  const icon = restProps.icon || <ImageEye sx={iconSx} />;

  return <StyledShowIconButton icon={icon} label={label} variant={variant} {...restProps} />;
}
