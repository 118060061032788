import { queryClient } from 'App';
import { deleteMerchantPayoutControls } from 'config/requests';
import { useNotify, useRecordContext } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { Merchant, MerchantPayoutControl } from 'types';

type EditPayoutControlManageDeleteDialogProps = {
  record: Merchant;
  payoutControlId: number;
} & DialogProps;

export const EditPayoutControlManageDeleteDialog: React.FC<
  EditPayoutControlManageDeleteDialogProps
> = ({ record, payoutControlId, onClose, ...props }) => {
  const merchantPayoutControl = useRecordContext<MerchantPayoutControl>();
  const notify = useNotify();

  const onDelete = async () => {
    try {
      await deleteMerchantPayoutControls(payoutControlId);
      await queryClient.resetQueries(['merchant_payout_controls', merchantPayoutControl.originId]);

      onClose();
      notify('Контроль выводов успешно удален', { type: 'success' });
    } catch (err: unknown) {
      notify('Ошибка удаления контроля выводов', { type: 'error' });
    }
  };

  return (
    <Dialog {...props} fullWidth>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Удаление контроля выводов"
        />
        <CardContent>
          <Typography>
            Контроль выводов для мерчанта {record?.name} будет удален без возможности
            восстановления.
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            <DeleteButton key="delete-button" onClick={onDelete} />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
