import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const trimmedStrStyle = {
  p: {
    width: '175px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const columnStyle120 = getColumnStyle(120);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-undefined': getColumnStyle(200),
  '& .userInfo': {
    ...getColumnStyle(240),
    ...trimmedStrStyle,
  },
  '& .column-enabled': getColumnStyle(100),
  '& .column-createdAt': columnStyle120,
  '& .column-updatedAt': columnStyle120,
  '& .column-reddyNumber': getColumnStyle(150),
  '& .column-corporateEmail': getColumnStyle(110),
  '& .column-twoFactor': getColumnStyle(80),
});
