import { createContext, useContext } from 'react';

type OrdersReportFilterContextData = {
  notFoundOrdersReportIds?: string[];
};

const OrdersReportFilterContext = createContext<OrdersReportFilterContextData | null>(null);
export const OrdersReportFilterContextProvider = OrdersReportFilterContext.Provider;

export function useOrdersReportFilterContext(): OrdersReportFilterContextData {
  const context = useContext(OrdersReportFilterContext);

  if (!context) {
    throw new Error(
      'Can not `useOrdersReportFilterContext` outside of the `OrdersReportFilterContextProvider`',
    );
  }

  return context;
}
