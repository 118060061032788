import {
  getMerchantNotifications,
  GetMerchantNotificationsResponse,
  MerchantNotificationsFilters,
} from 'config/requests';
import { useQuery } from 'react-query';

type UseGetMerchantNotificationsReturnValue = {
  isLoading: boolean;
  isFetching: boolean;
} & Partial<GetMerchantNotificationsResponse>;

type Props = {
  filters?: MerchantNotificationsFilters;
  merchantId?: string;
};

export function useGetMerchantNotifications({
  filters,
  merchantId,
}: Props): UseGetMerchantNotificationsReturnValue {
  const currentFilters = { ...filters, 'merchant.id': merchantId };
  const { page, itemsPerPage } = currentFilters;

  const { data, isLoading, isFetching } = useQuery(
    ['merchant_notification_settings', page, itemsPerPage, merchantId],
    () => getMerchantNotifications(currentFilters),
    {
      enabled: !!merchantId,
    },
  );

  return {
    merchantNotifications: data?.merchantNotifications,
    totalItems: data?.totalItems,
    isLoading,
    isFetching,
  };
}
