import { useListContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { FunctionField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { CumulativeReportSummary } from 'types';

import { CumulativeReportSummaryField } from './CumulativeReportSummaryField';

export const CumulativeReportSummaryDatagrid = (): JSX.Element => {
  const { data: cumulativeReportSummary, isLoading } = useListContext();

  return (
    <Datagrid
      bulkActionButtons={false}
      data={cumulativeReportSummary}
      isLoading={isLoading}
      sx={{
        width: '100%',
        borderBottom: `1px solid ${appColors.divider}`,
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTop: 'none',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        '& .MuiTableCell-body': {
          verticalAlign: 'top',
          padding: '16px',
        },
      }}
    >
      <FunctionField
        render={(record: CumulativeReportSummary) => cumulativeReportSummary.indexOf(record) + 1}
      />
      <FunctionField
        label="Счет мёрчанта"
        render={(record: CumulativeReportSummary) => (
          <Chip color="primary" label={record.merchantAccountName} />
        )}
      />
      <FunctionField
        label="Валюта счета"
        render={(record: CumulativeReportSummary) =>
          `${record.merchantAccountCurrencyCode}, ${record.merchantAccountCurrencyName}`
        }
      />
      <CumulativeReportSummaryField
        label="Пополнения"
        originalValueKey="originalPaymentAmount"
        summaryColor={appColors.success.main}
        valueKey="paymentAmount"
      />
      <CumulativeReportSummaryField
        label="Выводы"
        originalValueKey="originalPayoutAmount"
        summaryColor={appColors.error.main}
        valueKey="payoutAmount"
      />
      <CumulativeReportSummaryField
        label="Рефанды"
        originalValueKey="originalRefundAmount"
        valueKey="refundAmount"
      />
      <CumulativeReportSummaryField
        label="Чарджбеки"
        originalValueKey="originalChargebackAmount"
        valueKey="chargebackAmount"
      />
      <CumulativeReportSummaryField
        label="Комиссия"
        originalValueKey="originalCommissionValue"
        valueKey="commissionValue"
      />
      <CumulativeReportSummaryField
        label="Итого за период"
        originalValueKey="originalTotalAmount"
        valueKey="totalAmount"
      />
    </Datagrid>
  );
};
