import { useGoBack } from 'hooks/useGoBack';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import {
  BooleanInput,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';

import { ProvidersDeleteDialog } from './components/ProvidersDeleteDialog/ProvidersDeleteDialog';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission, Provider } from '../../types';

const transform = (data: RaRecord): RaRecord => {
  return {
    ...data,
    comment: data.comment || null,
  };
};

export const ProvidersEdit = (): JSX.Element => {
  const { goBack } = useGoBack();
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError: () => undefined }}
        open={!openRecordModalDelete}
        title="Редактирование провайдера"
        transform={transform}
      >
        <CardContent sx={{ paddingTop: '0!important', width: '552px' }}>
          <BooleanInput
            defaultValue={true}
            label="Активен"
            source="active"
            sx={{
              marginBottom: '16px',
            }}
          />
          <TextInputIntrospected fullWidth label="Название" source="name" variant="outlined" />
          <TextInputWithAliasIntrospected
            fullWidth
            label="Алиас"
            source="alias"
            variant="outlined"
          />
          <TextInputIntrospected
            fullWidth
            label="Комментарий"
            source="comment"
            variant="outlined"
          />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButtonValidated key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('providers', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <ProvidersDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
