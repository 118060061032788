import { RaRecord } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';

import { RolesForm } from '../components/RolesForm/RolesForm';

const transform = ({ roleCategories, ...data }: RaRecord): RaRecord => ({
  ...data,
  roleCategories: typeof roleCategories === 'string' ? roleCategories : roleCategories[0],
});

export const RolesCreate: React.FC = () => (
  <CreateGuesserDialog
    mutationOptions={{ onError: () => undefined }}
    sx={{ width: '444px', maxWidth: '444px' }}
    title="Cоздание роли"
    transform={transform}
  >
    <RolesForm />
    <CardActionsButtons
      leftActionsSlot={[
        <SaveButtonValidated key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
    />
  </CreateGuesserDialog>
);
