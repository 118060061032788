import { usePermissions } from 'hooks/usePermissions';
import { useGetOne, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { TextField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MappingRecord, Method, Permission } from 'types';

import { GatewayRuleMappingMethod } from './GatewayRuleMappingMethod';
import { GatewayRuleMappingMethodSwitch } from './GatewayRuleMappingMethodSwitch';

export const GatewayRuleMappingInfo: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const mappingRecord = useRecordContext<MappingRecord>();
  const { data: method, isLoading: isLoadingMethod } = useGetOne<Method>(
    'methods',
    {
      id: mappingRecord.method,
    },
    { enabled: !!mappingRecord.method },
  );

  return (
    <Box
      mb={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <GatewayRuleMappingMethod isLoadingMethod={isLoadingMethod} methodLogoId={method?.logo} />
      <Box sx={{ marginRight: '16px' }}>
        <Typography component="span" mr={1} variant="subtitle2">
          Метод:
        </Typography>
        {hasResourceAccess('methods') && (
          <ReferenceOneField link="show" reference="methods" source="method" target="id">
            <TextField source="name" />
          </ReferenceOneField>
        )}
      </Box>
      <Box>
        <Typography component="span" mr={1} variant="subtitle2">
          Направление:
        </Typography>
        <ReferenceField label="Направление" reference="directions" source="direction">
          <TextField source="description" />
        </ReferenceField>
      </Box>
      {hasResourcePermissions('mappings', [Permission.Full, Permission.Patch]) && (
        <Box sx={{ marginLeft: 'auto' }}>
          <GatewayRuleMappingMethodSwitch />
        </Box>
      )}
    </Box>
  );
};
