import { styled } from '@mui/material/styles';
import { ChipField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { Datagrid } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': {
    width: '50%',
    maxWidth: '310px',
    minWidth: '310px',
  },
  '& .column-merchants': getColumnStyle(250),
  '& .column-balanceUpdatedAtMessage': getColumnStyle(120),
  '& .column-credentials': getColumnStyle(160),
  '& .column-merchantCredentials': getColumnStyle(200),
  '& .column-provider': getColumnStyle(140),
  '& .column-currency': getColumnStyle(100),
  '& .column-gateways': {
    ...getColumnStyle(180),
    a: {
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});

export const StyledChipFieldLink = styled(ChipField)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
