import { useResourceContext } from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { Merchant } from 'types';

import { StyledBox } from './CreatedSuccessDialog.styled';

type CreatedSuccessDialogProps = { merchant: Merchant };

export const CreatedSuccessDialog: React.FC<CreatedSuccessDialogProps> = ({ merchant }) => {
  const navigate = useNavigate();
  const onClose = () => navigate(-1);
  const resource = useResourceContext();

  return (
    <Dialog maxWidth="md" onClose={() => navigate(-1)} open={!!merchant}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Мерчант создан"
        />
        <Typography sx={{ padding: '16px 24px' }} variant="body1">
          Мерчант {merchant.name} создан вы можете перейти к настройке мерчанта.
        </Typography>
        <CardActionsButtons
          rightActionsSlot={
            <Button
              component={Link}
              label="перейти к настройке"
              to={{
                pathname: `/${resource}/${encodeURIComponent(merchant.id)}/show`,
              }}
              variant="outlined"
            />
          }
        />
      </StyledBox>
    </Dialog>
  );
};
