import { styled } from '@mui/material';

export const GatewayRuleConditionFieldList = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  borderRadius: '2px',
  '&:first-child > li:after': {
    display: 'none',
  },
  '&:first-child > li:before': {
    display: 'none',
  },
  '&.nested': {
    padding: '12px',
    boxSizing: 'border-box',
  },
  '&.nested:before': {
    position: 'absolute',
    left: '-16px',
    top: '17px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '100%',
  },
  '&.tree > li:last-child > ul:before': {
    display: 'none',
  },
  '&.nested > li:last-child > ul:before': {
    display: 'none',
  },
  '&.nested:after': {
    position: 'absolute',
    left: '-16px',
    top: '-30px',
    borderLeft: '2px solid #1976D2',
    content: '""',
    width: '8px',
    height: '53px',
  },
  '&.tree > li:first-child:before': {
    display: 'none',
  },
  '&.tree > li:first-child:after': {
    display: 'none',
  },
  '&.tree.empty-tree > li > ul:first-child:after': {
    display: 'none',
  },
});
