import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Method } from 'types';

import { useGatewayEditFormContext } from '../context/GatewayEditFormContext';

type UseMethodsStateReturnValues = {
  currentMethods?: Method[];
  initialMethods?: Method[];
  setCurrentMethods: Dispatch<SetStateAction<Method[] | undefined>>;
};

export function useMethodsState(): UseMethodsStateReturnValues {
  const [currentMethods, setCurrentMethods] = useState<Method[]>();

  const { initialMethods } = useGatewayEditFormContext();

  useEffect(() => {
    setCurrentMethods(initialMethods);
  }, [initialMethods]);

  return {
    currentMethods,
    initialMethods,
    setCurrentMethods,
  };
}
