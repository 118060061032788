import { ActionsStyled, WrapperStyled } from './FilterDrawerActionsButtons.styled';
import { BoxProps } from '../../../Box';

export type FilterActionsButtonsProps = BoxProps;

export const FilterDrawerActionsButtons: React.FC<FilterActionsButtonsProps> = ({
  children,
  ...restProps
}) => {
  return (
    <WrapperStyled {...restProps}>
      <ActionsStyled>{children}</ActionsStyled>
    </WrapperStyled>
  );
};
