import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)({
  fontWeight: '500',
  lineHeight: '1',
  padding: '4px 24px',
  height: '48px',
  '& *': {
    lineHeight: '1',
  },
});

export function AppNewShowHeadingTableCell({
  children,
  ...restProps
}: TableCellProps): JSX.Element {
  return <StyledTableCell {...restProps}>{children}</StyledTableCell>;
}
