import { useCallback } from 'react';

import { downloadCSV } from 'react-admin';

import { getProvidersReportCSV } from '../../../config/requests';
import { ProviderReportsListFilterType } from '../ProviderReportsListFilter';

type Result = {
  exportReport: () => void;
};

export function useExportProviderReports(formattedFilters: ProviderReportsListFilterType): Result {
  const { gatewayAlias, providerAlias } = formattedFilters;

  const exportReport = useCallback(() => {
    getProvidersReportCSV({ gatewayAlias, providerAlias }).then((res) =>
      downloadCSV(res, 'providers_report'),
    );
  }, [formattedFilters]);

  return { exportReport };
}
