import { useQuery } from 'react-query';

import { getMerchantSpacesUsersRequest } from '../../config/requests';
import { MerchantSpacesUsers } from '../../types';

export type UseMerchantSpacesUsersReturnValues = {
  merchantSpacesUsers: MerchantSpacesUsers | undefined;
  isLoadingMerchantSpacesUsers: boolean;
  refetch: () => void;
};

export function useMerchantSpacesUsers(id: string | undefined): UseMerchantSpacesUsersReturnValues {
  const {
    data: merchantSpacesUsers,
    isLoading: isLoadingMerchantSpacesUsers,
    refetch,
  } = useQuery<MerchantSpacesUsers>(
    ['merchant_spaces_users', id],
    () => getMerchantSpacesUsersRequest(id || ''),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  return { merchantSpacesUsers, isLoadingMerchantSpacesUsers, refetch };
}
