import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useCallback } from 'react';

import { FieldValues, Path, PathValue, useForm, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getId, updateObjectPropByPath } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantSpacesUsersFilter } from './../MerchantSpacesUsersList/MerchantSpacesUsersList';

export type ResetFilterBySource<TFilterValues extends FieldValues> = (
  source: Path<TFilterValues>,
) => void;

type UseMerchantSpacesUsersFiltersReturnValues = {
  form: UseFormReturn<MerchantSpacesUsersFilter>;
  onSubmit: (values: MerchantSpacesUsersFilter) => void;
  resetFilter: () => void;
  resetFilterBySource: ResetFilterBySource<FieldValues>;
};

export function useMerchantSpacesUsersFilters(): UseMerchantSpacesUsersFiltersReturnValues {
  const form = useForm({
    defaultValues: DEFAULT_PAGINATION as MerchantSpacesUsersFilter,
  });
  const [, setSearchParams] = useSearchParams();

  const onSubmit = (values: MerchantSpacesUsersFilter) => {
    setSearchParams({
      ...cleanEmpty({
        ...values,
        active: values?.active?.toString(),
        firstName: values.fullName?.split(' ')[0],
        lastName: values.fullName?.split(' ')[1],
        fullName: undefined,
        merchantSpaceId: getId(values.merchantSpaceId) || undefined,
      }),
      page: DEFAULT_PAGINATION.page.toString(),
      itemsPerPage: values?.itemsPerPage?.toString(),
    });
  };

  const resetFilter = useCallback(() => {
    form.reset(DEFAULT_PAGINATION);
    setSearchParams({});
  }, [form]);

  const resetFilterBySource = useCallback((source: Path<FieldValues>) => {
    form.setValue(
      source as keyof MerchantSpacesUsersFilter,
      undefined as PathValue<MerchantSpacesUsersFilter, any>,
    );
    setSearchParams({
      ...cleanEmpty(updateObjectPropByPath(form.getValues(), source, undefined)),
      page: DEFAULT_PAGINATION.page.toString(),
      itemsPerPage: form.getValues()?.itemsPerPage?.toString(),
    });
  }, []);

  return {
    form,
    onSubmit,
    resetFilter,
    resetFilterBySource,
  };
}
