import { useCallback } from 'react';

import { useRecordModal } from 'hooks/useRecordModal';
import { useRecordContext } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { MappingRecord } from 'types';

import { GatewayRuleMappingDeactivateConfirm } from './GatewayRuleMappingDeactivateConfirm';
import { useChangeMappingActivity } from './hooks/useChangeMappingActivity';

export function GatewayRuleMappingMethodSwitch(): JSX.Element {
  const record = useRecordContext<MappingRecord>();
  const { handleOpenRecordModal, handleCloseRecordModal, openRecordModal } = useRecordModal();

  const { changeMappingActivityMutation } = useChangeMappingActivity();

  const toggleMappingActive = useCallback(() => {
    handleOpenRecordModal(record);
  }, [record]);

  const onConfirm = useCallback(() => {
    changeMappingActivityMutation.mutate({ id: record.originId, active: !record?.active });
  }, [record.active, record]);

  return (
    <>
      <GatewayRuleMappingDeactivateConfirm
        onClose={handleCloseRecordModal}
        onConfirm={onConfirm}
        open={openRecordModal}
      />
      <Switch
        checked={record?.active}
        defaultChecked={record?.active}
        onChange={toggleMappingActive}
      />
    </>
  );
}
