import { styled } from '@mui/material';

export const GatewayRuleConditionFieldListItemDot = styled('div')({
  position: 'absolute',
  width: '9px',
  height: '9px',
  borderRadius: '50%',
  backgroundColor: '#1976D2',
  left: '-20px',
  top: '21px',
});
