import { useRecordContext } from 'react-admin';
import { ChipFieldProps } from 'shared/react-admin/Fields';

import {
  UserActionTypeChipFieldStyled,
  UserActionTypeIcon,
} from './UserActionTypeChipField.styled';

export type UserActionTypeChipFieldProps = ChipFieldProps;

export const UserActionsTypeChipField: React.FC<UserActionTypeChipFieldProps> = (props) => {
  const record = useRecordContext();

  return (
    <UserActionTypeChipFieldStyled
      icon={<UserActionTypeIcon color={record?.value} size="m" />}
      {...props}
    />
  );
};
