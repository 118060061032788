import { createContext, useContext } from 'react';

export type DatagridFieldsArrayRowContextData = {
  rowIndex: number;
};

export const DatagridFieldsArrayRowContext =
  createContext<DatagridFieldsArrayRowContextData | null>(null);

export const DatagridFieldsArrayRowContextProvider = DatagridFieldsArrayRowContext.Provider;

export function useDatagridFieldsArrayRowContext(): DatagridFieldsArrayRowContextData {
  const context = useContext(DatagridFieldsArrayRowContext);

  if (!context) {
    throw new Error(
      'Can not `DatagridFieldsArrayRowContext` outside of the `DatagridFieldsArrayRowContextProvider`',
    );
  }

  return context;
}
