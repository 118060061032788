import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { List, ListProps } from 'react-admin';

const StyledList = styled(List)({
  marginTop: '24px',
  marginBottom: 0,
  backgroundColor: '#FAFAFA',
});

export function AppList({ children, actions = false, ...restProps }: ListProps): JSX.Element {
  return (
    <StyledList actions={actions} {...restProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 125px)',
        }}
      >
        {children}
      </Box>
    </StyledList>
  );
}
