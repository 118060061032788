import { useEffect } from 'react';

import { RaRecord, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Row } from 'shared/mui/Row/Row';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputShrink, BooleanInput, WatchedTextInput } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { CommissionsNameInput } from '../components/CommissionsNameInput';

type Props = {
  errors: string[];
};

export const CommissionEditForm: React.FC<Props> = ({ errors }) => {
  const { formState, watch, trigger, setError } = useFormContext();
  const zeroed = watch('zeroed');
  const triggerPercentValidation = () => trigger(['percent', 'fix', 'max', 'min']);

  useEffect(() => {
    errors.forEach((error: string) => {
      const [field, message] = error.split(':');
      setError(field, { type: 'custom', message });
    });
  }, [errors]);

  return (
    <CardContent>
      <CommissionsNameInput
        fullWidth
        helperText={false}
        label="Название"
        size="medium"
        source="name"
        validate={required()}
        variant="outlined"
      />
      <Row>
        <ReferenceInput perPage={100} reference="providers" source="provider">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            isRequired
            label="Провайдер"
            optionText="name"
            size="medium"
            source="provider"
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput
          filter={{ archive: false }}
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            isRequired
            label="Мерчант"
            optionText="name"
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      </Row>
      <ReferenceArrayInput
        isFilter={true}
        perPage={100}
        reference="transaction_types"
        source="transactionTypes"
      >
        <AutocompleteMultiselectInputShrink
          isRequired
          label="Типы транзакций"
          name="transactionTypes"
          optionText="name"
          size="medium"
          sx={{
            '.MuiTextField-root': {
              marginTop: 0,
            },
          }}
        />
      </ReferenceArrayInput>
      <Row>
        <ReferenceInput
          filter={{ active: true }}
          perPage={100}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            isRequired
            label="Валюта"
            optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                error={!!formState.errors?.currency}
                helperText={(formState.errors?.currency?.message as string) || ' '}
                label="Валюта"
                size="medium"
                variant="outlined"
              />
            )}
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
        <BooleanInput
          label="Нулевая комиссия"
          source="zeroed"
          sx={{ width: '100%', marginTop: '9px' }}
          variant="outlined"
        />
      </Row>
      <Row>
        <WatchedTextInput
          disabled={zeroed}
          fullWidth
          label="Процент"
          onChange={triggerPercentValidation}
          source="percent"
          variant="outlined"
          watchField="zeroed"
        />
        <WatchedTextInput
          disabled={zeroed}
          fullWidth
          label="Фиксированная"
          onChange={triggerPercentValidation}
          source="fix"
          variant="outlined"
          watchField="zeroed"
        />
      </Row>
      <Row>
        <WatchedTextInput
          disabled={zeroed}
          fullWidth
          label="Максимальная"
          onChange={triggerPercentValidation}
          source="max"
          variant="outlined"
          watchField="zeroed"
        />
        <WatchedTextInput
          disabled={zeroed}
          fullWidth
          label="Минимальная"
          onChange={triggerPercentValidation}
          source="min"
          variant="outlined"
          watchField="zeroed"
        />
      </Row>
    </CardContent>
  );
};
