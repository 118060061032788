import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import {
  FilterDateTimeRangeChip,
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterSelectChoicesChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips/FilterSelectChoicesChip/FilterSelectChoicesChip';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { TextInputShrink } from '../../../shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { User } from '../../../types';
import { IMPACT_TO_BALANCE_SELECT_ITEMS } from '../constants/impactToBalance';

export const OrderAdjustmentsReportListFilterChips: React.FC = () => {
  const { control } = useFormContext();

  return (
    <>
      <FilterDateTimeRangeChip label="Время" name="appliedDate">
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeRangePicker
              label="Время создания / период"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeChip>
      <FilterMultiselectChip
        label="Тип операции"
        limitTags={1}
        name="adjustmentType"
        optionText="description"
        resource="adjustment_types"
      >
        <ReferenceArrayInput
          name="adjustmentType"
          perPage={100}
          reference="adjustment_types"
          source="adjustmentType"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Тип операции"
            limitTags={1}
            name="adjustmentType"
            optionText="description"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterSelectChoicesChip
        choices={IMPACT_TO_BALANCE_SELECT_ITEMS}
        label="Движение по балансу"
        name="impactToBalance"
      >
        <SelectInputShrink
          choices={IMPACT_TO_BALANCE_SELECT_ITEMS}
          defaultValue={null}
          fullWidth
          helperText={false}
          id="impactToBalance"
          label="Движение по балансу"
          name="impactToBalance"
          optionText="label"
          source="impactToBalance"
          variant="outlined"
        />
      </FilterSelectChoicesChip>
      <FilterMultiselectChip
        label="Статус операции"
        limitTags={1}
        name="adjustmentStatus"
        optionText="description"
        resource="adjustment_statuses"
      >
        <ReferenceArrayInput
          name="adjustmentStatus"
          perPage={100}
          reference="adjustment_statuses"
          source="adjustmentStatus"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Статус операции"
            limitTags={1}
            name="adjustmentStatus"
            optionText="description"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip label="Автор / Инициатор" limitTags={1} name="user" resource="users">
        <ReferenceArrayInput name="user" perPage={200} reference="users" source="user">
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({
              fullName: searchText,
            })}
            helperText={false}
            label="Автор / Инициатор"
            name="user"
            optionText="name"
            renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterTextChip label="Request Id" name="requestId">
        <TextInputShrink
          fullWidth
          helperText={false}
          label="Request Id"
          source="requestId"
          variant="outlined"
        />
      </FilterTextChip>
    </>
  );
};
