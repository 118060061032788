import { createContext, useState } from 'react';

import { Location } from 'react-router-dom';

export type HistoryContextType = {
  baseRoute?: Location;
  getBackUrl: () => string;
  setBaseRoute: React.Dispatch<React.SetStateAction<Location | undefined>>;
};

export const HistoryContext = createContext<HistoryContextType>({
  getBackUrl: () => '',
  setBaseRoute: () => undefined,
});

type Props = {
  children: React.ReactNode;
};

export const HistoryContextProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [baseRoute, setBaseRoute] = useState<Location>();

  // Данная функция предназначена для отслеживания источника перехода на новое модальное окно.
  // отсюда берется подложка под модалки и роут, куда надо вернуться назад в случае закрытия модального окна
  const getBackUrl = () => (baseRoute ? `${baseRoute.pathname}${baseRoute.search}` : '');

  return (
    <HistoryContext.Provider value={{ getBackUrl, setBaseRoute, baseRoute }}>
      {children}
    </HistoryContext.Provider>
  );
};
