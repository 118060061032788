import { useListContext } from 'react-admin';
import { Avatar } from 'shared/mui/Avatars';
import { Box } from 'shared/mui/Box';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Tooltip } from 'shared/mui/Tooltip';
import { User } from 'types';

import { StyledBox } from './ProviderAccountsMembers.styled';
import { StyledAvatarGroup } from '../../ProviderAccountsList/ProviderAccountsList.styled';

type ProviderAccountsMembersProps = {
  addSlot?: React.ReactNode;
};

export const ProviderAccountsMembers = ({ addSlot }: ProviderAccountsMembersProps): JSX.Element => {
  const { data: users, isLoading } = useListContext<User>();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box display="flex">
      <Tooltip arrow placement="bottom" title="Добавить ответственных">
        <StyledAvatarGroup sx={{ zIndex: 1 }}>{addSlot}</StyledAvatarGroup>
      </Tooltip>
      <Tooltip
        arrow
        placement="right"
        title={
          <Box flexDirection="column">
            {users?.map(({ firstName, lastName, id }) => (
              <StyledBox key={id}>{`${firstName} ${lastName}`}</StyledBox>
            ))}
          </Box>
        }
      >
        <StyledAvatarGroup max={4} sx={{ marginLeft: '-8px' }}>
          {users?.map((user) => (
            <Avatar
              key={user.id}
              sx={{ width: 24, height: 24, fontSize: '8px' }}
              title={`${user.firstName} ${user.lastName}`}
            >
              {`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()}
            </Avatar>
          ))}
        </StyledAvatarGroup>
      </Tooltip>
    </Box>
  );
};
