import { styled } from '@mui/material';
import { Box, BoxProps } from 'shared/mui/Box';

export const StyledBox = styled(Box)({
  position: 'relative',
  background: '#FFF',
  borderBottom: 0,
  overflowX: 'auto',
  overflowY: 'auto',
});

export const StyledBoxCell = styled(Box)({
  padding: '16px',
});

export const StyledRowBox = styled(Box)<BoxProps & { firstCellWidth: number }>(
  ({ firstCellWidth }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: `${firstCellWidth}px repeat(10, minmax(160px, 1fr))`,
    width: '100%',
    borderLeft: 0,
    borderRight: 0,
  }),
);
