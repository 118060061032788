import { CardHeader } from 'shared/mui/Card';
import { PageHeader } from 'shared/mui/PageHeader';

import { CreateStyled } from './CreatePage.styled';
import { Box } from '../../../mui/Box';
import { CreateProps } from '../../Create/Create';

export type CreatePageProps = {
  title: string;
  listName: string;
  children: React.ReactNode;
  fullWidth?: boolean;
} & CreateProps;

export const CreatePage: React.FC<CreatePageProps> = ({ title, listName, children, ...props }) => {
  return (
    <Box>
      <PageHeader
        breadCrumbs={{
          currentPage: title,
          listName,
        }}
      />
      <CreateStyled actions={false} {...props}>
        <CardHeader title={title} />
        {children}
      </CreateStyled>
    </Box>
  );
};
