import { LIST_IDS_REGEX } from 'constants/regexp';

import React, { useCallback, useEffect, useState } from 'react';

import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { IdDialogInput } from 'shared/react-admin/Inputs';
import { getIdsArray, getIdsString, getIdInputValidate } from 'utils';

import { StyledAlert } from './SearchByIdDialog.styled';

type Props = {
  open: boolean;
  onClose: () => void;
  currentIdFilter: { field: string; label: string };
  resetFilters?: () => void;
  notFoundIds: string[] | undefined;
  source: string;
};

export function SearchByIdDialog({ open, currentIdFilter, onClose, notFoundIds, source }: Props) {
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const [value, setValue] = useState('');
  const form = useFormContext();

  const isFilterDirty = !!filterValues[currentIdFilter.field];

  const submit = useCallback(() => {
    const currentIdFilterStringValue = getIdsString(value);
    if (!currentIdFilterStringValue.match(LIST_IDS_REGEX) && !!value.length) {
      form.setError(source, {
        message: 'Введите список id разделенных пробелом или переносом строки',
      });
      return;
    }

    const currentIdFilterFormattedValue = getIdsArray(value);
    setFilters(
      { ...filterValues, [currentIdFilter.field]: currentIdFilterFormattedValue },
      displayedFilters,
    );
    form.setValue(currentIdFilter.field, currentIdFilterFormattedValue);
    onClose();
  }, [value, filterValues]);

  useEffect(() => {
    const currentValue = isFilterDirty ? filterValues[currentIdFilter.field].join(' ') : '';

    setValue(currentValue);
    form.setValue(source, currentValue);
    form.clearErrors();
  }, []);

  const resetValue = () => {
    setValue('');
    form.setValue(source, '');
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title={`Поиск по списку ${currentIdFilter.label}`}
      />
      {notFoundIds?.length ? (
        <StyledAlert severity="warning">
          {`${notFoundIds.length} ID заказов не найдены в системе.`}
        </StyledAlert>
      ) : (
        <StyledAlert severity="info">Укажите список заказов через пробел.</StyledAlert>
      )}
      <IdDialogInput
        InputLabelProps={{ shrink: true }}
        label={currentIdFilter.label}
        multiline
        notFoundIds={notFoundIds}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
        rows={4}
        source={source}
        sx={{ marginX: 3 }}
        validate={getIdInputValidate()}
        value={value}
        variant="outlined"
      />
      <CardActionsButtons
        leftActionsSlot={[
          <Button key="save-button" label="Применить" onClick={submit} variant="contained" />,
          isFilterDirty ? (
            <Button key="reset-button" label="Сбросить" onClick={resetValue} variant="outlined" />
          ) : (
            <Button key="cancel-button" label="Отменить" onClick={onClose} variant="outlined" />
          ),
        ]}
      />
    </Dialog>
  );
}
