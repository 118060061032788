import { ExportFormatNames } from 'constants/exportFormats';

import { blue } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { Typography } from 'shared/mui/Typography';

export type CumulativeReportExportDialogMessageProps = {
  exportFormat: ExportFormatNames;
  isSendReportOnEmail: boolean;
  onChange: () => void;
  userEmail?: string;
};

export const ReportExportDialogMessage = ({
  exportFormat,
  isSendReportOnEmail,
  onChange,
  userEmail,
}: CumulativeReportExportDialogMessageProps): JSX.Element => {
  return (
    <Box>
      <Typography component="span">{`После подтверждения, отчет будет конвертирован в формат ${exportFormat} и доступен в "Мои загрузки".`}</Typography>
      <FormControlLabel
        control={<Checkbox checked={isSendReportOnEmail} onChange={onChange} />}
        label={
          <>
            <Typography component="span">Дублировать отправку на почту </Typography>
            <Typography color={blue[500]} component="span">
              {userEmail}
            </Typography>
          </>
        }
        sx={{ marginRight: 0 }}
      />
    </Box>
  );
};
