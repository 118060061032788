import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { TableBody } from 'shared/mui/Table';

export const StyledBox = styled(Box)({
  whiteSpace: 'nowrap',
  marginLeft: '8px',
});

export const StyledTableBody = styled(TableBody)({
  td: {
    width: '180px',
  },
});
