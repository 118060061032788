import { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { flatObject } from 'utils/flatObject';

import { FilterResetButtonStyled } from './FilterResetButton.styled';
import { ButtonProps } from '../../../Buttons';

export type FilterResetButtonButtonProps = ButtonProps & {
  onReset?: () => void;
};

export const FilterResetButton: React.FC<FilterResetButtonButtonProps> = ({
  color = 'primary',
  variant = 'outlined',
  label = 'Сбросить',
  onReset,
  ...restProps
}) => {
  const {
    reset,
    formState: { dirtyFields, defaultValues, isDirty },
  } = useFormContext();

  const hasDirtyFields = useMemo<boolean>(() => {
    if (typeof defaultValues !== 'object') {
      return false;
    }

    return Object.values(defaultValues).some((value) => {
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }
      if (typeof value === 'object' && value) {
        return Object.values(flatObject(value))?.every((val) => Boolean(val));
      }
      return Boolean(value);
    });
  }, [defaultValues]);

  const disabled = !(isDirty || hasDirtyFields) || Object.keys(dirtyFields).length === 0;

  function onClick() {
    reset();
    if (onReset) {
      onReset();
    }
  }

  return (
    <FilterResetButtonStyled
      color={color}
      disabled={disabled}
      label={label}
      onClick={onClick}
      variant={variant}
      {...restProps}
    />
  );
};
