import { Chip } from '@mui/material';
import { FieldValues } from 'react-hook-form';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsDefaultDateField<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  return (
    <Chip
      label={`${chipValue.label}: ${new Date(chipValue.value)
        .toLocaleDateString()
        .replaceAll('.', '/')}`}
      {...restProps}
    />
  );
}
