import CloseIcon from '@mui/icons-material/Close';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress/CircularProgress';
import { IconButton } from '../IconButtons';

type ImageUploaderImageStateProps = {
  preview?: string;
  handleImageClick: () => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const ImageUploaderImageState = ({
  handleImageClick,
  onClick,
  preview,
}: ImageUploaderImageStateProps) => (
  <Box sx={{ position: 'relative', display: 'inline-block' }}>
    {preview ? (
      <>
        <img
          alt="Click to upload"
          onClick={handleImageClick}
          src={preview}
          style={{ cursor: 'pointer', maxWidth: '200px', maxHeight: '200px' }}
        />
        <IconButton
          onClick={onClick}
          sx={{
            position: 'absolute',
            top: 0,
            right: '-24px',
            cursor: 'pointer',
            width: '24px',
            height: '24px',
          }}
        >
          <CloseIcon sx={{ color: '#D32F2F' }} />
        </IconButton>
      </>
    ) : (
      <CircularProgress />
    )}
  </Box>
);
