import { cloneElement, forwardRef } from 'react';
import * as React from 'react';

import { DatagridClasses, RaRecord } from 'react-admin';
import { FieldArrayWithId, FieldValues, FieldArrayPath } from 'react-hook-form';
import { TableBody } from 'shared/mui/Table';

import { DatagridFieldsArrayRowContextProvider } from '../../DatagridRows/DatagridFieldsArrayRow/context/datagridFieldsArrayRowContext';
import { DatagridFieldsArrayRow } from '../../DatagridRows/DatagridFieldsArrayRow/DatagridFieldsArrayRow';
import { DatagridBodyProps } from '../DatagridBody/DatagridBody';

export type Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = Omit<DatagridBodyProps, 'className' | 'data'> & {
  fields: (FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName> & {
    record: RaRecord;
  })[];
};

export const DatagridFieldsArrayBody: React.FC<Props> = forwardRef(
  (
    {
      expand,
      hasBulkActions = false,
      hover,
      onToggleItem,
      resource,
      rowClick,
      rowSx,
      row = <DatagridFieldsArrayRow />,
      children,
      rowStyle,
      selectedIds,
      isRowSelectable,
      fields,
      ...restProps
    },
    ref,
  ) => {
    return (
      <TableBody className={DatagridClasses.tbody} ref={ref} {...restProps}>
        {fields.map(({ id, record }, rowIndex) => {
          return (
            <DatagridFieldsArrayRowContextProvider key={id} value={{ rowIndex }}>
              {cloneElement(
                row,
                {
                  className: DatagridClasses.row,
                  expand,
                  hasBulkActions: hasBulkActions && !!selectedIds,
                  hover,
                  id: record?.id ?? `row${rowIndex}`,
                  onToggleItem,
                  record,
                  resource,
                  key: id,
                  rowClick,
                  selectable: !isRowSelectable || isRowSelectable(record),
                  selected: selectedIds?.includes(record?.id),
                  sx: rowSx?.(record, rowIndex),
                  style: rowStyle?.(record, rowIndex),
                },
                children,
              )}
            </DatagridFieldsArrayRowContextProvider>
          );
        })}
      </TableBody>
    );
  },
);

DatagridFieldsArrayBody.displayName = 'DatagridFieldsArrayBody';
