// localStorage isn't available in incognito mode. We need to detect it
export const testLocalStorage = (): boolean => {
  // eslint-disable-next-line eqeqeq
  if (typeof window === 'undefined' || window.localStorage == undefined) {
    return false;
  }

  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

export const tryParse = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};
