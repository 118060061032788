import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useCallback } from 'react';

import { FieldValues, Path, PathValue, useForm, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantSpacesFilter } from '../MerchantSpacesList';

export type ResetFilterBySource<TFilterValues extends FieldValues> = (
  source: Path<TFilterValues>,
) => void;
export type ResetFilterArrayById<TFilterValues extends FieldValues> = (
  source: Path<TFilterValues>,
  id: string,
) => void;

type UseFiltersReturnValues = {
  form: UseFormReturn<MerchantSpacesFilter>;
  onSubmit: (values: MerchantSpacesFilter) => void;
  resetFilter: () => void;
  resetFilterBySource: ResetFilterBySource<FieldValues>;
  resetFilterArrayById: ResetFilterArrayById<FieldValues>;
};

export function useMerchantSpacesFilters(): UseFiltersReturnValues {
  const form = useForm({
    defaultValues: DEFAULT_PAGINATION as MerchantSpacesFilter,
  });
  const [, setSearchParams] = useSearchParams();

  const resetFilter = useCallback(() => {
    form.reset(DEFAULT_PAGINATION);
    setSearchParams({});
  }, [form]);

  const onSubmit = (values: MerchantSpacesFilter) => {
    setSearchParams({
      ...cleanEmpty({ ...values, active: values?.active?.toString() }),
      page: DEFAULT_PAGINATION.page.toString(),
      itemsPerPage: values?.itemsPerPage?.toString(),
    });
  };

  const resetFilterBySource = useCallback((source: Path<FieldValues>) => {
    form.setValue(
      source as keyof MerchantSpacesFilter,
      undefined as PathValue<MerchantSpacesFilter, any>,
    );
  }, []);

  const resetFilterArrayById = useCallback((source: Path<FieldValues>, id: string) => {
    function getValuesWithoutId<T>(oldValues: T) {
      // @ts-ignore
      return oldValues.filter((item: string) => item !== id);
    }

    form.setValue(
      source as keyof MerchantSpacesFilter,
      getValuesWithoutId(form.getValues(source as keyof MerchantSpacesFilter)),
    );
  }, []);

  return {
    form,
    onSubmit,
    resetFilter,
    resetFilterBySource,
    resetFilterArrayById,
  };
}
