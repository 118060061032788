import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { FilterChipType } from 'constants/filterChipType';
import { MERCHANT_SPACES_ROLES_SELECT_ITEMS } from 'constants/roles';

import { useMemo } from 'react';

import { useMerchantSpaces } from 'components/MerchantSpacesUsers/hooks/useMerchantSpaces';
import { useFilterChips } from 'hooks/useFilterChips';
import { ResetFilterBySource } from 'hooks/useFilters';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantSpacesUsersFilter } from '../MerchantSpacesUsersList';

type Props = {
  form: UseFormReturn<MerchantSpacesUsersFilter>;
  resetFilter: () => void;
  onSubmit: (values: MerchantSpacesUsersFilter) => void;
  resetFilterBySource: ResetFilterBySource<FieldValues>;
};

export const MerchantSpacesUsersListFilter: React.FC<Props> = ({
  form,
  resetFilter,
  resetFilterBySource,
  onSubmit,
}) => {
  const { merchantSpaces, isLoading } = useMerchantSpaces();

  const isResetDisabled = useMemo(() => {
    const hasDirtyFields = form
      .watch(['merchantSpaceId', 'active', 'role', 'fullName', 'email'])
      .some((value) => {
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        return Boolean(value);
      });
    return !(hasDirtyFields || form.formState.isDirty);
  }, [form, onSubmit]);

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'role', label: 'Роль', type: FilterChipType.String },
      { source: 'merchantSpaceId', label: 'Личный кабинет', type: FilterChipType.MerchantSpaceId },
      { source: 'active', label: 'Активен', type: FilterChipType.Boolean },
      { source: 'fullName', label: 'Имя, фамилия', type: FilterChipType.String },
      { source: 'email', label: 'Email', type: FilterChipType.String },
    ],
    appliedFilters: cleanEmpty(form.getValues()),
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton
              disabled={isResetDisabled}
              key="reset-button"
              onClick={resetFilter}
            />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterBySource={resetFilterBySource}
        >
          <FilterRow sx={{ mb: 2 }}>
            <TextInputShrink
              fullWidth
              helperText={false}
              label="Имя, фамилия"
              name="fullName"
              source="fullName"
              variant="outlined"
            />
            <TextInputShrink
              fullWidth
              helperText={false}
              label="Email"
              name="email"
              source="email"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={merchantSpaces?.items}
              filterToQuery={(searchText: string) => {
                return { spaceName: searchText };
              }}
              fullWidth
              helperText={false}
              isLoading={isLoading}
              label="Личный кабинет"
              optionText="spaceName"
              optionValue="id"
              size="small"
              source="merchantSpaceId"
              sx={{
                '& .MuiFormHelperText-root': {
                  lineHeight: 1,
                },
              }}
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={MERCHANT_SPACES_ROLES_SELECT_ITEMS}
              filterToQuery={(searchText: string) => {
                return { name: searchText };
              }}
              fullWidth
              helperText={false}
              label="Роль"
              optionText="name"
              optionValue="id"
              size="small"
              source="role"
              sx={{
                '& .MuiFormHelperText-root': {
                  lineHeight: 1,
                },
              }}
              variant="outlined"
            />
          </FilterRow>
          <FilterRow>
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              label="Активен"
              name="active"
              source="active"
              variant="outlined"
            />
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
