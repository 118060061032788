import { DeleteStages } from 'constants/deleteStages';

import { useStagedDelete } from 'hooks/useStagedDelete';
import { RaRecord } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';

type Props = {
  record: RaRecord;
  openRecordModal: boolean;
  handleCloseRecordModal: () => void;
};

export const GatewaysEditFormDeleteDialog = ({
  handleCloseRecordModal,
  openRecordModal,
  record,
}: Props) => {
  const navigate = useNavigate();
  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record,
    onSuccess: () => {
      handleCloseRecordModal();
      setStage(DeleteStages.Initial);
      navigate(-1);
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModal();
  };

  return (
    <DeleteStageDialog
      deleteOneRecord={deleteOneRecord}
      details={`Шлюз ${record?.name} будет удален без возможности восстановления.`}
      onClose={handleCloseDeleteDialog}
      open={openRecordModal}
      stage={stage}
      title="Удаление шлюза"
    >
      <DeleteStageDialogDenyReason reason={denyDeleteReason}>
        <CancelButton
          key="history-back-button"
          label="Вернуться к списку пользователей"
          onClick={handleCloseDeleteDialog}
        />
      </DeleteStageDialogDenyReason>
    </DeleteStageDialog>
  );
};
