import { format, fromUnixTime } from 'date-fns';

import { StyledCumulativeReportPeriod } from './CumulativeReportPeriod.styled';
import { CumulativeReportFilterValues } from '../../CumulativeReportList';

type CumulativeReportPeriodProps = {
  filters: CumulativeReportFilterValues;
};

export const CumulativeReportPeriod: React.FC<CumulativeReportPeriodProps> = ({ filters }) => (
  <StyledCumulativeReportPeriod>{`Нарастающий итог по всем счетам за период ${format(
    fromUnixTime(filters?.periodFrom as number),
    'dd.MM.yyyy',
  )} — ${format(
    fromUnixTime(filters?.periodTo as number),
    'dd.MM.yyyy',
  )}`}</StyledCumulativeReportPeriod>
);
