import { useState } from 'react';

import GavelIcon from '@mui/icons-material/Gavel';
import { Button, Card } from '@mui/material';
import { useShowContext } from 'react-admin';

import OrdersShowDisputeInfo from './OrdersShowDisputeInfo';
import { Order } from '../../../types';
import OrdersDisputeCreateDialog from '../components/OrderDispute/OrdersDisputeCreateDialog';
import { useOrdersDispute } from '../hooks/useOrdersDispute';

const OrdersShowDisputes = (): JSX.Element => {
  const { record } = useShowContext<Order>();
  const { hasOpenDispute } = useOrdersDispute(record);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card>
      <OrdersDisputeCreateDialog onClose={() => setIsOpen(false)} open={isOpen} order={record} />
      <Button
        disabled={hasOpenDispute}
        onClick={() => setIsOpen(true)}
        startIcon={<GavelIcon />}
        sx={{ marginBottom: '24px' }}
        variant="contained"
      >
        Открыть диспут
      </Button>
      {record?.orderDisputes.map((item) => {
        return <OrdersShowDisputeInfo key={item} orderDisputeId={item} />;
      })}
    </Card>
  );
};

export default OrdersShowDisputes;
