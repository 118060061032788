import { UNLIMITED_PAGINATION_PARAMS } from 'constants/unlimitedPaginationParams';

import { ListContextProvider, useGetList, useList, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { MappingItemRecord } from 'types';

import { GatewayRuleMappingDatagrid } from './GatewayRuleMappingDatagrid';
import { GatewayRuleMappingInfo } from './GatewayRuleMappingInfo';

type Props = {
  children?: React.ReactNode;
};

export const GatewayRuleMapping: React.FC<Props> = ({ children }) => {
  const mappingRecord = useRecordContext();

  const { data: mappingItems, isLoading } = useGetList<MappingItemRecord>('mapping_items', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
    filter: { mapping: mappingRecord?.id },
  });
  const listContext = useList({ data: mappingItems || [] });

  return (
    <ListContextProvider value={listContext}>
      <Box mb={3}>
        <GatewayRuleMappingInfo />
        <GatewayRuleMappingDatagrid isLoading={isLoading}>{children}</GatewayRuleMappingDatagrid>
      </Box>
    </ListContextProvider>
  );
};
