import React from 'react';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/Fields';
import { ExpressionParamWithDataSelectItem } from 'types';

import { StyledExpressionFormControl } from './GatewayRulesFormRuleExpression.styled';

type Props<T extends FieldValues> = {
  name: Path<T>;
  options: ExpressionParamWithDataSelectItem[];
};

export function GatewayRulesFormRuleExpressionArrayInput<T extends FieldValues>({
  options,
  name,
}: Props<T>): JSX.Element {
  const { control } = useFormContext();

  return (
    <StyledExpressionFormControl>
      <Controller
        control={control}
        defaultValue={[] as any}
        name={name}
        render={({ field, fieldState }) => {
          return (
            <Autocomplete
              {...field}
              getOptionLabel={(value) => {
                return options?.find((option) => option.value === value)?.name || '';
              }}
              multiple
              onChange={(e, data) => field.onChange(data)}
              options={options.map((option) => option.value)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ margin: 0 }}
                    variant="outlined"
                  />
                );
              }}
              renderTags={(values, getTagProps) => {
                return values.map((value, index) => {
                  const currentOption = options?.find((option) => option.value === value);
                  return (
                    <Chip
                      color={currentOption?.active === false ? 'error' : 'default'}
                      size="small"
                      {...getTagProps({ index })}
                      key={value}
                      label={currentOption?.name}
                      variant="outlined"
                    />
                  );
                });
              }}
            />
          );
        }}
      />
    </StyledExpressionFormControl>
  );
}
