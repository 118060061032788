import React from 'react';

import { queryClient } from 'App';
import { deleteMerchantAccesses } from 'config/requests';
import { useGetMany, useNotify } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { MerchantAccess, User } from 'types';
import { getId } from 'utils';

import {
  StyledBox,
  StyledDialog,
  StyledFormBox,
  StyledAlertBox,
  StyledList,
} from './MerchantShowAccessDisableDialog.styled';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedAccesses: MerchantAccess[];
  merchantName: string | undefined;
  merchantId: string | undefined;
};

export const MerchantShowAccessDisableDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
  merchantName,
  selectedAccesses,
}) => {
  const notify = useNotify();
  const form = useForm();
  const { data = [] } = useGetMany<User>(
    'users',
    { ids: selectedAccesses?.map((access) => access?.user) },
    { enabled: !!selectedAccesses.length },
  );

  const { mutate } = useMutation(
    ({ merchant, users }: { merchant: number; users: number[] }) =>
      deleteMerchantAccesses(merchant, users),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`merchants/${getId(merchantId)}/merchant_accesses`);
        onClose();
      },
      onError: (error: any) => {
        notify(error.data.errors[0].title || 'Что-то пошло не так. Повторите запрос позже.', {
          type: 'error',
        });
      },
    },
  );

  const onSubmit = () => {
    mutate({
      merchant: Number(getId(merchantId)),
      users: data.map((user) => Number(getId(user.id))),
    });
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <StyledBox>
            <CardHeader
              actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
              title="Отключить доступ"
            />
            <StyledAlertBox>
              <Alert severity="warning">
                {`ВНИМАНИЕ! После отключения доступа, мерчант ${merchantName} будет недоступен для выбранных
                пользователей!`}
              </Alert>
            </StyledAlertBox>
            <CardContent>
              <StyledFormBox>
                <Typography>Пользователи:</Typography>
                <StyledList>
                  {data?.map((user) => {
                    return <li key={user.id}>{`${user.firstName} ${user.lastName}`}</li>;
                  })}
                </StyledList>
              </StyledFormBox>
            </CardContent>
            <CardActionsButtons
              leftActionsSlot={[
                <Button
                  color="warning"
                  key="save-button"
                  label="Отключить"
                  type="submit"
                  variant="contained"
                />,
                <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
              ]}
            />
          </StyledBox>
        </form>
      </FormProvider>
    </StyledDialog>
  );
};
