import { EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { Permission } from 'types';

import { RolesShowTable } from './RolesShowTable';
import { usePermissions } from '../../../hooks/usePermissions';

export const RolesShow: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <ShowDialog title="Просмотр роли">
      <RolesShowTable />
      <CardActionsButtons
        leftActionsSlot={
          hasResourcePermissions('roles', [Permission.Full, Permission.Update]) && <EditButton />
        }
      />
    </ShowDialog>
  );
};
