import { useEffect } from 'react';

import { useListContext } from 'react-admin';
import { DefaultValues } from 'react-hook-form';
import { FilterIdsValues } from 'shared/mui/NewFilter/hooks/useIdFilters';

type Props<TFieldValues> = {
  appliedFilters: DefaultValues<TFieldValues>;
  appliedIdFilters?: FilterIdsValues;
  transform?: (appliedFilters: DefaultValues<TFieldValues>) => any;
};

export function useSyncFilter<TFieldValues>({
  appliedFilters,
  appliedIdFilters,
  transform,
}: Props<TFieldValues>) {
  const { setFilters, displayedFilters } = useListContext();
  useEffect(() => {
    const combinedFilters = {
      ...(transform?.(appliedFilters) || appliedFilters),
      ...appliedIdFilters,
    };

    setFilters(combinedFilters, displayedFilters);
  }, [appliedFilters, appliedIdFilters]);
}
