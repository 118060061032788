import { AxiosResponse } from 'axios';
import { CreateMerchantUserInvitationParams, createMerchantUserInvitation } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation, UseMutationResult } from 'react-query';

type UseCreateMerchantSpaceUsersInvitationReturnValue = {
  createMerchantSpaceUsersInvitation: UseMutationResult<
    AxiosResponse,
    unknown,
    CreateMerchantUserInvitationParams,
    unknown
  >;
};

export function useCreateMerchantSpaceUsersInvitation(
  onSuccess: () => void,
): UseCreateMerchantSpaceUsersInvitationReturnValue {
  const notify = useNotify();

  const mutation = useMutation(
    ({ users }: CreateMerchantUserInvitationParams) => createMerchantUserInvitation({ users }),
    {
      onSuccess,
      onError: (error: any) => {
        notify(error.data.errors[0].title || 'Something went wrong', { type: 'error' });
      },
    },
  );

  return { createMerchantSpaceUsersInvitation: mutation };
}
