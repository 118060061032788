import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledGatewayDeactivationReasonsListDatagrid = styled(Datagrid)({
  padding: 0,
  '& .MuiToolbar-root': { paddingRight: '16px' },
  '& .MuiTypography-root': {
    lineHeight: '1.9',
  },
  '& .RaDatagrid-headerCell': {
    padding: '16px 16px 12px 16px',
    lineHeight: '20px',
  },
  '& th.MuiTableCell-paddingCheckbox': {
    paddingTop: '14px !important',
    paddingRight: 0,
  },
  '& td.MuiTableCell-paddingCheckbox': {
    paddingTop: '13px !important',
    paddingRight: 0,
  },
  '& .column-reason': { width: '70%' },
  '& .column-gatewayCount': getColumnStyle(100),
});
