import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { JsonInput } from 'shared/react-admin/Inputs/JsonInputIntrospected/JsonInput';
import { appColors } from 'themes/variables';

export const CardContentStyled = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  '&:first-child': {
    borderRight: `1px solid ${appColors.divider}`,
  },
});

export const JsonInputStyled = styled(JsonInput)({
  '& .jer-editor-container': {
    background: '#fff !important',
    border: `1px solid ${appColors.inputBorder}`,
    maxWidth: '707px !important',
    height: '151px',
    overflow: 'auto',
  },
});
