import { useMemo } from 'react';

import { FieldValues } from 'react-hook-form';

export function useGetExistingIdFilterName(filterValues: FieldValues) {
  return useMemo(() => {
    switch (Object.keys(filterValues)[0]) {
      case 'id':
        return 'Kubera ID';
      case 'merchantReference':
        return 'Мерчант ID';
      case 'providerReferenceId':
        return 'Провайдер ID';
      default:
        return '';
    }
  }, [filterValues]);
}
