import { useCallback, useEffect } from 'react';

import GavelIcon from '@mui/icons-material/Gavel';
import { BulkActionProps, Button, useGetOne } from 'react-admin';

import { Order, OrderDispute } from '../../../types';
import { useOrdersDispute } from '../hooks/useOrdersDispute';

type OrdersBulkActionProps = {
  setSelectedOrder: (order: Order) => void;
  setIsOpen: (open: boolean) => void;
  setSelectedDispute: (dispute: OrderDispute | undefined) => void;
} & BulkActionProps;

export const OrdersListBulkActions = (props: OrdersBulkActionProps): JSX.Element => {
  const { selectedIds, setSelectedOrder, setSelectedDispute, setIsOpen } = props;
  const currentOrderId = selectedIds && selectedIds?.length > 0 ? selectedIds[0].toString() : '';

  const { data: currentOrder } = useGetOne<Order>(
    'orders',
    {
      id: currentOrderId,
    },
    { enabled: !!currentOrderId },
  );

  const { hasOpenDispute, lastDispute } = useOrdersDispute(currentOrder);

  const handleDispute = useCallback(() => {
    setIsOpen(true);
  }, [currentOrder]);

  useEffect(() => {
    if (currentOrder) {
      setSelectedOrder(currentOrder);
      setSelectedDispute(lastDispute);
    }
  }, [lastDispute, currentOrder]);

  return (
    <>
      {selectedIds?.length === 1 && (
        <Button
          label={hasOpenDispute ? 'Закрыть диспут' : 'Открыть диспут'}
          onClick={handleDispute}
          startIcon={<GavelIcon />}
        />
      )}
    </>
  );
};
