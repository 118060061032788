import { styled } from '@mui/material/styles';

import { Box, BoxProps } from '../../Box';

export type FilterActionsProps = BoxProps;

export const FilterActions: React.FC<FilterActionsProps> = styled(Box)({
  display: 'flex',
  gap: '16px',
});
