import { EMPTY_FIELD } from 'constants/emptyField';

import { getOrderStatuses } from 'config/requests';
import { useQuery } from 'react-query';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { FunctionField } from 'shared/react-admin/Fields';
import { RoleInfo } from 'types';

type RolesStatusesChipsFieldProps = {
  label?: string;
  record?: RoleInfo;
};

export const RolesStatusesChipsField: React.FC<RolesStatusesChipsFieldProps> = ({ label }) => {
  const { data: roleOrderStatuses, isLoading } = useQuery('new/order_statuses', getOrderStatuses);

  return (
    <FunctionField
      label={label}
      render={(record: RoleInfo) => {
        const filtered = roleOrderStatuses?.filter(({ value }) =>
          record.allowedStatusesEditing.find((status) => value === status),
        );

        if (isLoading) {
          return <LinearProgress />;
        }

        return filtered?.length ? (
          <Box sx={{ gap: '8px', display: 'flex', flexWrap: 'wrap' }}>
            {filtered.map(({ description }) => (
              <Chip key={description} label={description} />
            ))}
          </Box>
        ) : (
          EMPTY_FIELD
        );
      }}
    />
  );
};
