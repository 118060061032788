import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

import { appColors, sidebarWidth } from './variables';

export const baseTheme = createTheme(
  merge({}, defaultTheme, {
    shadows: Array(25).fill('none'),
    sidebar: { width: sidebarWidth },
    palette: {
      primary: {
        main: appColors.primary.main,
        dark: appColors.primary.dark,
      },
      error: {
        main: appColors.error.main,
      },
      secondary: {
        main: appColors.secondary.main,
        dark: appColors.secondary.dark,
      },
      header: {
        main: appColors.background,
      },
      background: {
        default: appColors.background,
        paper: appColors.background,
      },
    },
  }),
);
