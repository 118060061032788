import { Box, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)({
  marginTop: '32px',
  marginBottom: '32px',
  backgroundColor: 'inherit',
});

export function AppListContextProviderCard({ children, ...restProps }: CardProps): JSX.Element {
  return <StyledBox {...restProps}>{children}</StyledBox>;
}
