import { styled } from '@mui/material/styles';

import { Box, BoxProps } from '../../Box';

export type FilterRowProps = BoxProps & {
  columnsCount?: number;
};

export const FilterRow = styled(Box)<FilterRowProps>(({ columnsCount = 4 }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: '0 16px ',
  gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
}));
