import React from 'react';

import { useMerchantSpacesUsersSites } from 'hooks/queries/useMerchantSpacesUsersSites';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { TableBody } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';

import { SiteAvailabilityRow } from './SiteAvailabilityRow';

export const SitesAvailabilityTable: React.FC<{
  merchantSpaceUserId: string | undefined;
}> = ({ merchantSpaceUserId }) => {
  const { merchantSpacesUsersSites, isLoadingMerchantSpacesUsersSites } =
    useMerchantSpacesUsersSites(merchantSpaceUserId || '');

  if (isLoadingMerchantSpacesUsersSites) {
    return <LinearProgress />;
  }

  if (merchantSpacesUsersSites?.length === 0) {
    return <Typography>Мерчанты не найдены.</Typography>;
  }
  return (
    <CardTable>
      <TableBody>
        {merchantSpacesUsersSites?.map((merchantSpacesUsersSite, index) => {
          return (
            <SiteAvailabilityRow key={index} merchantSpacesUsersSite={merchantSpacesUsersSite} />
          );
        })}
      </TableBody>
    </CardTable>
  );
};
