import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { DeleteDialog, DeleteDialogProps } from 'shared/mui/Dialogs';

export const GatewayDeactivationReasonsDeleteDialog: React.FC<
  Omit<DeleteDialogProps, 'details' | 'title'>
> = (props) => {
  const { goBack } = useGoBack();

  const onSuccess = async () => {
    goBack();
    await queryClient.invalidateQueries(['gateway_deactivation_reasons']);
  };

  return (
    <DeleteDialog
      {...props}
      details={`Причина "${props.record?.reason}" будет удалена без возможности восстановления.`}
      onSuccess={onSuccess}
      title="Удаление причины"
    />
  );
};
