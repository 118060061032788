import { useMemo } from 'react';

import { FieldValues } from 'react-hook-form';
import { Order } from 'types';

export function useGetNotFoundOrderIds(
  orders: Order[] | undefined,
  filterValues: FieldValues,
): string[] {
  return useMemo(() => {
    const firstExistFilterById =
      filterValues.id || filterValues.merchantReference || filterValues.providerReferenceId;

    if (!Array.isArray(firstExistFilterById)) {
      return [];
    }

    return firstExistFilterById?.filter(
      (id: string) =>
        !orders?.some((order) =>
          [order.originId, order.merchantReference, order.providerReferenceId].includes(id),
        ),
    );
  }, [orders, filterValues]);
}
