import React from 'react';

import { Box, Button } from '@mui/material';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from 'components/Common/Dialog';

import { AppTypography } from '../../../Common/Typography';

type Props = {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
  onClick: () => void;
};

export const OrderConfirmationRedirectDialog = ({
  title,
  text,
  open,
  onClose,
  onClick,
}: Props): JSX.Element => (
  <AppDialogBase open={open}>
    <AppDialogHeader onClose={onClose} title={title} />
    <AppTypography mb={5}>{text}</AppTypography>
    <AppDialogActions
      sx={{
        padding: 0,
        width: '100%',
      }}
    >
      <Box display="flex">
        <Button onClick={onClick} sx={{ height: 36 }} variant="outlined">
          перейти к настройкам шлюза
        </Button>
      </Box>
    </AppDialogActions>
  </AppDialogBase>
);
