import { ActionsStyled, WrapperStyled } from './FilterToolbar.styled';

export type FilterToolbarProps = {
  leftActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  rightActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
};

export const FilterToolbar: React.FC<FilterToolbarProps> = ({
  leftActionsSlot,
  rightActionsSlot,
}) => {
  return (
    <WrapperStyled isOnlyRightSlot={!leftActionsSlot}>
      {leftActionsSlot && <ActionsStyled>{leftActionsSlot}</ActionsStyled>}
      {rightActionsSlot && <ActionsStyled>{rightActionsSlot}</ActionsStyled>}
    </WrapperStyled>
  );
};
