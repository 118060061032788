import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { ProviderShowTable } from 'shared/widgets/ProviderShowTable/ProviderShowTable';

import { ProvidersDeleteDialog } from './components/ProvidersDeleteDialog/ProvidersDeleteDialog';
import { usePermissions } from '../../hooks/usePermissions';
import { UserActionsIconButton } from '../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Permission, Provider } from '../../types';

export const ProvidersShow = (): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();
  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Provider>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="provider" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModalDelete}
        title="Просмотр провайдера"
      >
        <ProviderShowTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('companies', [Permission.Full, Permission.Update]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('providers', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <ProvidersDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
