import { styled } from '@mui/material';
import { Box } from 'shared/mui/Box';

export const ImgStyled = styled('img')({
  width: '56px',
  height: '56px',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
  objectFit: 'contain',
});

export const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
