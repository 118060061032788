import { queryClient } from 'App';
import { merchantPayoutControlsCustomersCreate } from 'config/requests';
import { SimpleForm, useNotify, useRecordContext } from 'react-admin';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { Merchant } from 'types';

import {
  StyledAlert,
  StyledBox,
  StyledDialog,
} from './EditPayoutControlTestClientsExceptionsCreateDialog.styled';
import { useEditPayoutControlContext } from '../../context/EditPayoutControlContext';

export const EditPayoutControlTestClientsExceptionsCreateDialog: React.FC<DialogProps> = ({
  open,
  onClose,
}) => {
  const { payoutControl } = useEditPayoutControlContext();
  const record = useRecordContext<Merchant>();
  const notify = useNotify();

  const handleSubmit: SubmitHandler<FieldValues> = async ({ customerId }) => {
    merchantPayoutControlsCustomersCreate(payoutControl?.id, record?.id, customerId)
      .then(() => {
        queryClient.refetchQueries(['merchant_payout_controls', record?.originId]);
        onClose();
      })
      .catch((err) => {
        notify(err.data.detail || 'Ошибка сервера', { type: 'error' });
      });
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<></>}>
        <StyledBox>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание тестового клиента"
          />
          <StyledAlert severity="warning">
            Тестовый клиент будет создан и сразу добавлен в список исключений
          </StyledAlert>
          <CardContent>
            <TextInputShrink
              fullWidth
              label="Внешний ID"
              name="customerId"
              size="medium"
              source="customerId"
              variant="outlined"
            />
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="save-button" label="Сохранить" type="submit" variant="contained" />,
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            ]}
          />
        </StyledBox>
      </SimpleForm>
    </StyledDialog>
  );
};
