export const enum ConversionItemStatus {
  Success = 'success',
  Failure = 'failure',
  Expired = 'expired',
  Canceled = 'canceled',
  Pending = 'pending',
}

export type ConversionItem = {
  label: ConversionItemStatus;
  value: number;
};

export type TotalOrders = {
  created: number;
  prepared: number;
  pending: number;
  expired: number;
  failure: number;
  canceled: number;
  success: number;
  refund: number;
  chargeback: number;
  orderCount: number;
};

export type GatewayConversionListItem = {
  id: number;
  name: string;
  messages: string[];
  conversionItems: ConversionItem[];
  gatewayConversionSettingId: number | null;
};
