import { fromUnixTime } from 'date-fns';

type DateInterval = {
  gte?: number | undefined;
  lte?: number | undefined;
};

export function getDateRangeDefaultValues(dateInterval: DateInterval): {
  gte: Date | string;
  lte: Date | string;
} {
  return {
    gte: dateInterval?.gte ? fromUnixTime(dateInterval?.gte) : '',
    lte: dateInterval?.lte ? fromUnixTime(dateInterval?.lte) : '',
  };
}
