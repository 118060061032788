import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { TextField } from 'shared/react-admin/Fields';
import { GatewayDeactivationReason, Permission } from 'types';

import { GatewayDeactivationReasonsDeleteDialog } from './components/GatewayDeactivationReasonsDeleteDialog';
import { GatewayDeactivationReasonsGatewayCountField } from './components/GatewayDeactivationReasonsGatewayCountField';
import { UserActionsIconButton } from '../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

export const GatewayDeactivationReasonsShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();
  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<GatewayDeactivationReason>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [
                <UserActionsIconButton
                  entityName="gateway_deactivation_reason"
                  key="user-action-link"
                />,
              ]
            : undefined
        }
        open={!openRecordModalDelete}
        title="Просмотр причины"
      >
        <CardTable sx={{ width: '600px' }}>
          <TableBody>
            <TableRowWrapper headerStyle={{ width: '120px' }} title="ID">
              <TextField label="Id" source="originId" />
            </TableRowWrapper>
            <TableRowWrapper title="Кол-во шлюзов">
              <GatewayDeactivationReasonsGatewayCountField />
            </TableRowWrapper>
            <TableRowWrapper contentStyle={{ wordBreak: 'break-word' }} title="Причина">
              <TextField label="Причина" source="reason" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('gateway_deactivation_reasons', [
              Permission.Full,
              Permission.Update,
            ]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('gateway_deactivation_reasons', [
              Permission.Full,
              Permission.Delete,
            ]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <GatewayDeactivationReasonsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
