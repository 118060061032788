import { useGetList } from 'react-admin';

import { Merchant } from '../../../types';

type UseMerchantsReturnValues = {
  merchants: Merchant[];
  isLoadingMerchants: boolean;
};

export function useMerchants(sourceCompany: string | undefined): UseMerchantsReturnValues {
  const { data: merchants = [], isLoading: isLoadingMerchants } = useGetList<Merchant>(
    'merchants',
    {
      filter: {
        companies: [sourceCompany],
      },
    },
    {
      enabled: Boolean(sourceCompany),
    },
  );

  return {
    merchants,
    isLoadingMerchants,
  };
}
