import { useRecordContext, useResourceContext } from 'react-admin';
import { Link } from 'react-router-dom';

import { Button, ButtonProps } from '..';

export type EditLinkButtonProps = ButtonProps;

export const EditLinkButton: React.FC<EditLinkButtonProps> = ({
  label = 'Редактировать',
  color = 'primary',
  variant = 'outlined',
  size = 'medium',
  ...restProps
}) => {
  const resource = useResourceContext(restProps);
  const record = useRecordContext(restProps);

  return (
    <Button
      color={color}
      component={Link}
      label={label}
      size={size}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record?.id)}/edit`,
      }}
      variant={variant}
      {...restProps}
    />
  );
};
