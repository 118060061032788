import React from 'react';

import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';

export const BalanceReportRequiredFieldsAlert: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <Alert onClose={onClose} severity="error" sx={{ marginTop: '16px' }}>
      <Typography>Необходимо заполнить все обязательные поля.</Typography>
    </Alert>
  );
};
