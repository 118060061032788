import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { TextField } from 'shared/mui/Fields';

export const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '24px',
  marginTop: 0,
  '& fieldset': {
    borderStyle: 'dotted',
  },
});

export const StyledBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr auto',
  gap: '16px',
  marginBottom: '24px',
});
