import { styled } from '@mui/material';

import { AutocompleteMultiselectInput } from '../AutocompleteMultiselectInput/AutocompleteMultiselectInput';

export const StyledAutocompleteMultiselectInput = styled(AutocompleteMultiselectInput)({
  '.MuiFormControl-root': {
    marginBottom: 0,
    marginTop: 0,
  },
});
