import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const OrdersWrapperStyled = styled(Box)({
  width: '100%',

  backgroundColor: '#fff',
  border: '1px solid #00000014',
  display: 'flex',
  flexDirection: 'column',
});

export const OrdersLineStyled = styled(Box)({
  width: '100%',
  display: 'grid',
  gap: '1px',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
});
