import { Dispatch, DragEvent, SetStateAction, useEffect, useRef, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { StyledBoxWrapper } from './ImageUploader.styled';
import { ImageUploaderImageState } from './ImageUploaderImageState';
import { ImageUploaderTextState } from './ImageUploaderTextState';

type ImageUploaderProps = {
  filePath?: string;
  setIsShowImage?: Dispatch<SetStateAction<boolean>>;
};

export function ImageUploader({ filePath, setIsShowImage }: ImageUploaderProps): JSX.Element {
  const { control, watch, setValue } = useFormContext();
  const selectedFile = watch('file');
  const selectedFilePath = watch('filePath');
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isShowImage = !!selectedFile || !!selectedFilePath;

  useEffect(() => {
    if (selectedFile && selectedFile[0]) {
      const reader = new FileReader();

      reader.onload = (e) => setPreview(e?.target?.result as string);
      reader.readAsDataURL(selectedFile[0]);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (filePath) {
      setPreview(filePath);
    }
  }, [filePath]);

  useEffect(() => {
    setIsShowImage?.(isShowImage);
  }, [isShowImage]);

  const handleImageClick = () => {
    fileInputRef?.current?.click();
  };

  const updatePreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => setPreview(e.target?.result as string);
    reader.readAsDataURL(file);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer?.files[0]) {
      const file = e.dataTransfer.files[0];
      setValue('file', [file]);
      updatePreview(file);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Controller
      control={control}
      defaultValue=""
      name="file"
      render={({ field: { onChange } }) => (
        <>
          <input
            accept="image/*"
            onChange={(e) => {
              onChange(e.target.value);
              setValue('file', e.target.files);
            }}
            ref={fileInputRef}
            style={{ display: 'none' }}
            type="file"
          />
          <StyledBoxWrapper
            onDragOver={handleDragOver}
            onDrop={(e) => {
              const target = e.target as HTMLDivElement;
              const srcValue = target.getAttribute('src');
              if (srcValue) {
                onChange(srcValue);
              }
              handleDrop(e);
            }}
          >
            {isShowImage ? (
              <ImageUploaderImageState
                handleImageClick={handleImageClick}
                onClick={() => {
                  onChange('');
                  setValue('file', '');
                  setValue('filePath', '');
                }}
                preview={preview}
              />
            ) : (
              <ImageUploaderTextState handleImageClick={handleImageClick} />
            )}
          </StyledBoxWrapper>
        </>
      )}
    />
  );
}
