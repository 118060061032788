import { styled } from '@mui/material/styles';

import { Box } from '../Box';

export const ScrollableBoxStyled = styled(Box)({
  display: 'flex',
  gap: '5px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
