import { Fragment } from 'react';

import { fieldValidateGuesser } from 'utils';

import { NumberInput } from '..';

export const NumberInputWithGuesser = ({ fields, validate, ...props }: any): JSX.Element => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });

  if (isError) {
    return <Fragment />;
  }
  return <NumberInput validate={guessedValidate} {...props} />;
};
