import React from 'react';

import { FieldProps, useRecordContext } from 'react-admin';
import ReactJsonViewCompare from 'react-json-view-compare';

export type JsonCompareFieldProps = {
  oldValueSource: string;
  newValueSource: string;
} & FieldProps;

export const JsonCompareField: React.FC<JsonCompareFieldProps> = ({
  oldValueSource,
  newValueSource,
  ...restProps
}) => {
  const record = useRecordContext();
  return (
    <ReactJsonViewCompare
      newData={record[newValueSource] || {}}
      oldData={record[oldValueSource] || {}}
      {...restProps}
    />
  );
};
