import { Alert } from 'shared/mui/Alert/Alert';

type Props = {
  isShowAlert: boolean;
  onClose: VoidFunction;
};

export const OrdersReportListLimitAlert: React.FC<Props> = ({ isShowAlert, onClose }) => {
  if (!isShowAlert) {
    return <></>;
  }

  return (
    <Alert onClose={onClose} severity="warning" sx={{ margin: '15px 0 10px 0' }}>
      Применённый фильтр слишком широкий, поэтому показано только 1000 записей. Чтобы получить все
      результаты фильтрации, сделайте экспорт.
    </Alert>
  );
};
