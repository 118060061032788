import { styled } from '@mui/material/styles';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Dialog } from 'shared/mui/Dialogs';

export const StyledDialog = styled(Dialog)({
  '.MuiCardContent-root': {
    padding: 0,
    paddingBottom: '0!important',
    ':&last-child': {
      paddingBottom: '0!important',
    },
  },
});

export const StyledBox = styled(Box)({
  width: '900px',
});

export const StyledAlert = styled(Alert)({
  margin: '8px 24px',
});
