import { useHistoryContext } from 'hooks/useHistoryContext';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { Link } from 'react-router-dom';

import { ButtonProps, Button } from '..';

export type CancelButtonProps = ButtonProps & { linkType?: 'back' | 'exit' };

export const CancelButton: React.FC<CancelButtonProps> = ({
  label = 'Отменить',
  linkType = 'exit',
  variant = 'text',
  size = 'medium',
  onClick,
  ...restProps
}) => {
  const { baseRoute } = useHistoryContext();
  const { toRoot } = useNavigateToRoot();

  return linkType === 'back' && !!baseRoute ? (
    <Button
      component={Link}
      label={label}
      size={size}
      variant={variant}
      {...restProps}
      {...(onClick ? { onClick } : { to: baseRoute })}
    />
  ) : (
    <Button
      label={label}
      onClick={onClick || toRoot}
      size={size}
      variant={variant}
      {...restProps}
    />
  );
};
