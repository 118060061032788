import DatePicker from 'react-datepicker';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { CalendarTodayIcon } from 'shared/mui/Icons';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { getEndOfDate } from 'utils';

type Props = {
  isRestFiltersDisabled: boolean;
  form: UseFormReturn<any, any>;
};

export const OrdersReportDateFilter: React.FC<Props> = ({ form, isRestFiltersDisabled }) => {
  return (
    <FilterRow>
      <Controller
        control={form.control}
        name="createdAt.gte"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextFieldShrink
                InputProps={{
                  endAdornment: <CalendarTodayIcon />,
                }}
                fullWidth
                label="Дата создания заказа от"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            disabled={isRestFiltersDisabled}
            endDate={form.watch('createdAt.lte')}
            locale="ru"
            maxDate={form.watch('createdAt.lte') || new Date()}
            onChange={(date) => {
              field.onChange(date);
            }}
            selected={field.value}
            selectsStart
            startDate={field.value}
          />
        )}
      />
      <Controller
        control={form.control}
        name="createdAt.lte"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextFieldShrink
                InputProps={{
                  endAdornment: <CalendarTodayIcon />,
                }}
                fullWidth
                label="Дата создания заказа до"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            disabled={isRestFiltersDisabled}
            endDate={field.value}
            locale="ru"
            maxDate={new Date()}
            minDate={form.watch('createdAt.gte')}
            onChange={(date: Date) => {
              field.onChange(getEndOfDate(date));
            }}
            selected={field.value}
            selectsEnd
            startDate={form.watch('createdAt.gte')}
          />
        )}
      />
      <Controller
        control={form.control}
        name="transactions.createdAt.gte"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextFieldShrink
                InputProps={{
                  endAdornment: <CalendarTodayIcon />,
                }}
                fullWidth
                label="Дата исполнения заказа от"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            disabled={isRestFiltersDisabled}
            endDate={form.watch('transactions.createdAt.lte')}
            locale="ru"
            maxDate={form.watch('transactions.createdAt.lte') || new Date()}
            onChange={(date) => {
              field.onChange(date);
            }}
            selected={field.value}
            selectsStart
            startDate={field.value}
          />
        )}
      />
      <Controller
        control={form.control}
        name="transactions.createdAt.lte"
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextFieldShrink
                InputProps={{
                  endAdornment: <CalendarTodayIcon />,
                }}
                fullWidth
                label="Дата исполнения заказа до"
                size="small"
                value={field.value}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy"
            disabled={isRestFiltersDisabled}
            endDate={field.value}
            locale="ru"
            maxDate={new Date()}
            minDate={form.watch('transactions.createdAt.gte')}
            onChange={(date: Date) => {
              field.onChange(getEndOfDate(date));
            }}
            selected={field.value}
            selectsEnd
            startDate={form.watch('transactions.createdAt.gte')}
          />
        )}
      />
    </FilterRow>
  );
};
