import * as React from 'react';

import { Card } from '@mui/material';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

export const ApprovalConfirmDone: React.FC<DialogProps> = (props) => {
  const { toRoot } = useNavigateToRoot();
  return (
    <Dialog {...props} fullWidth>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={props.onClose} />]}
          title="Заявка согласована"
        />
        <CardContent>
          <Typography>Вы можете отслеживать статус заявок в списке банковских операций.</Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton
              key="back-button"
              label="Перейти к списку операций"
              onClick={toRoot}
              variant="outlined"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
