import { SingleFieldList, SingleFieldListProps } from 'shared/react-admin/Fields';

export const GatewaysShowTableSingleFieldList = ({
  children,
  ...restProps
}: SingleFieldListProps): JSX.Element => (
  <SingleFieldList
    sx={{
      marginTop: 0,
      marginBottom: 0,
      '& .MuiChip-root:first-child': {
        marginLeft: 0,
      },
    }}
    {...restProps}
  >
    {children}
  </SingleFieldList>
);
