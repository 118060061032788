import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  SelectInput as AdminSelectInput,
  SelectInputProps as AdminSelectInputProps,
} from 'react-admin';

import { fieldWidth } from '../../../../themes/variables';

export type SelectInputProps = AdminSelectInputProps;

export const SelectInput: React.FC<SelectInputProps> = ({ sx, ...restProps }) => {
  const defaultSx: SxProps<Theme> = {
    '&.MuiFormControl-root': {
      marginTop: 0,
    },
    ...fieldWidth.medium,
  };
  return <AdminSelectInput sx={{ ...defaultSx, ...sx }} {...restProps} />;
};
