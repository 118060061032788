import EventNoteIcon from '@mui/icons-material/EventNote';
import { ListButton } from 'shared/react-admin/Buttons';

type Props = {
  entityId?: number;
};

export const EditPayoutControlManageActionsButton: React.FC<Props> = ({ entityId }) => {
  const filter = { entityName: 'merchant_payout_control', entityId };

  return (
    <ListButton
      color="secondary"
      filter={filter}
      icon={<EventNoteIcon style={{ marginTop: '1px' }} />}
      label=""
      pathname="/user_actions"
      sx={{
        minWidth: 0,
        span: {
          marginRight: '0!important',
        },
        '&:hover': {
          borderRadius: '50%',
        },
        svg: {
          marginLeft: '2px',
        },
        '&.MuiTypography-root': {
          height: '22px',
        },
      }}
    />
  );
};
