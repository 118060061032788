export const API_PATH: string = process.env.REACT_APP_API_URL_PATH ?? '';
export const LOGIN_PATH: string = process.env.REACT_APP_LOGIN_URL_PATH ?? '';
export const SECOND_FACTOR_PATH: string = process.env.REACT_APP_SECOND_FACTOR_URL_PATH ?? '';
export const CHANGE_PASSWORD_PATH: string = process.env.REACT_APP_CHANGE_PASSWORD_URL_PATH ?? '';
export const CAPTCHA_PUBLIC_KEY: string = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY ?? '';
export const ENVIRONMENT_NAME: string = process.env.REACT_APP_ENVIRONMENT_NAME ?? '';
export const UNAUTHORIZED_API_PATH: string = process.env.REACT_APP_UNAUTHORIZED_API_URL_PATH ?? '';
export const UNLEASH_PROXY_URL: string = process.env.REACT_APP_UNLEASH_PROXY_URL ?? '';
export const UNLEASH_PROXY_CLIENT_KEY: string =
  process.env.REACT_APP_UNLEASH_PROXY_CLIENT_KEY ?? '';
export const UNLEASH_PROXY_APP_NAME: string = process.env.REACT_APP_UNLEASH_PROXY_APP_NAME ?? '';
export const UNLEASH_PROXY_ENVIRONMENT: string =
  process.env.REACT_APP_UNLEASH_PROXY_ENVIRONMENT ?? '';
