import { SyntheticEvent } from 'react';

import { TextField, Button, Checkbox, FormControlLabel, Box } from '@mui/material';
import { useAuthProvider, useNotify } from 'react-admin';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthStepOneProps } from 'types';
import { executeCaptchaWithData } from 'utils';

const AppCredentialsForm = ({ updateStep }: AuthStepOneProps): JSX.Element => {
  const { register, handleSubmit } = useForm();
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const submit = (captchaToken: string, { email, password, remember }: FieldValues) => {
    authProvider
      .preLogin({ captchaToken, email, password, remember })
      .then(() => {
        updateStep(authProvider.step);
        return navigate('/login');
      })
      .catch(() => notify('Invalid email or password', { type: 'error' }));
  };

  const proxySubmit = (data: FieldValues) => {
    executeCaptchaWithData(submit, data);
  };

  const navigateToRestorePassword = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate('/restore_password');
  };

  return (
    <form onSubmit={handleSubmit(proxySubmit)}>
      <TextField
        {...register('email')}
        autoComplete="email"
        autoFocus
        fullWidth
        id="email"
        label="Email Address"
        margin="normal"
        name="email"
        required
        variant="outlined"
      />
      <TextField
        {...register('password')}
        autoComplete="current-password"
        fullWidth
        id="password"
        label="Password"
        margin="normal"
        name="password"
        required
        type="password"
        variant="outlined"
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={<Checkbox {...register('remember')} color="primary" name="remember" />}
          label="Remember me"
        />
        <Button onClick={navigateToRestorePassword} size="small">
          Забыли пароль?
        </Button>
      </Box>
      <Button color="primary" fullWidth type="submit" variant="contained">
        Войти
      </Button>
    </form>
  );
};

export default AppCredentialsForm;
