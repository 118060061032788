import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(159);
const textAlignStyle = getColumnStyle(180);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-originId': {
    width: '50px',
    maxWidth: '120px',
  },
  '& .column-zeroed': getColumnStyle(),
  '& .column-name': columnStyle,
  '& .column-provider': columnStyle,
  '& .column-merchant': columnStyle,
  '& .column-currency': columnStyle,
  '& .column-transactionTypes': columnStyle,
  '& .column-percent': textAlignStyle,
  '& .column-fix': textAlignStyle,
  '& .column-max': textAlignStyle,
  '& .column-min': textAlignStyle,
});
