import qs from 'qs';
import { useNotify } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';

import { LimitsCreateForm } from './LimitsCreateForm';

type GatewayDefaultValueType = {
  gateway: string;
};

export const LimitsCreate = (): JSX.Element => {
  const location = useLocation();

  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as GatewayDefaultValueType;

  const notify = useNotify();

  const onError = () => {
    notify('Форма заполнена неверно, проверьте, пожалуйста, ошибки', { type: 'error' });
  };

  return (
    <CreateGuesserDialog mutationOptions={{ onError }} title="Cоздание лимита">
      <CardContent>
        <LimitsCreateForm gatewayDefaultValue={defaultValues.gateway} />
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButton key="save-button" />,
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
        ]}
      />
    </CreateGuesserDialog>
  );
};
