import { RaRecord, useCreatePath } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow } from 'shared/mui/Table';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { BooleanInput } from 'shared/react-admin/Inputs';
import { appColors } from 'themes/variables';

import { StyledTable } from './GatewaysEditFormSettingsGateway.styled';

type Props = {
  gatewayMethodFields: RaRecord[];
};

export const GatewaysEditFormSettingsGatewayTable = ({
  gatewayMethodFields,
}: Props): JSX.Element => {
  const form = useFormContext();
  const createPath = useCreatePath();

  return (
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <TableCell width={247}>Метод</TableCell>
          <TableCell width={140}>Активен</TableCell>
          <TableCell width={140}>Тестирование</TableCell>
          <TableCell>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                paddingTop: '5px',
              }}
            >
              <span style={{ marginRight: '4px' }}>Авто-отключение</span>
              <span style={{ marginTop: '-6px' }}>
                <TooltipWithInfoIcon tooltipText="Режим тестирования будет отключён при создании первого заказа в системе." />
              </span>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {gatewayMethodFields.map((field, index) => (
          <TableRow key={field.id}>
            <TableCell>
              <Link
                style={{ textDecoration: 'none', color: appColors.primary.main }}
                target="_blank"
                to={createPath({ resource: 'methods', id: field.method, type: 'show' })}
              >
                {field.methodName}
              </Link>
            </TableCell>
            <TableCell>
              <BooleanInput
                label={form.getValues('gatewayMethods')[index]?.active ? 'вкл.' : 'выкл.'}
                size="small"
                source={`gatewayMethods.${index}.active`}
              />
            </TableCell>
            <TableCell>
              <BooleanInput
                label={form.getValues('gatewayMethods')[index]?.pilotMode ? 'вкл.' : 'выкл.'}
                size="small"
                source={`gatewayMethods.${index}.pilotMode`}
              />
            </TableCell>
            <TableCell>
              <BooleanInput
                label={form.getValues('gatewayMethods')[index]?.autoDeactivation ? 'вкл.' : 'выкл.'}
                size="small"
                source={`gatewayMethods.${index}.autoDeactivation`}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
