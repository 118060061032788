import React from 'react';

import { deleteRecord } from 'config/requests';
import { Form, useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { queryClient } from '../../../../../App';
import { MerchantCredentials } from '../../../../../types';

type Props = {
  open: boolean;
  onClose: () => void;
  record: MerchantCredentials | undefined;
};

export const MerchantsShowCredentialsDeleteDialog: React.FC<Props> = ({
  open,
  onClose,
  record,
}) => {
  const notify = useNotify();

  const { mutate } = useMutation(() => deleteRecord(record?.id || ''), {
    onSuccess: async () => {
      notify('Реквизит успешно удален!', {
        type: 'success',
      });
      await queryClient.invalidateQueries(['merchant_credentials']);
      onClose();
    },
    onError: (error: any) => {
      notify(`Ошибка: ${error?.data?.detail}`, { type: 'error' });
    },
  });

  const onSubmit = () => {
    mutate();
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <Form onSubmit={onSubmit as any}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Удаление реквизитов доступа"
        />
        <CardContent>
          Реквизиты доступа {record?.name} будут удалены без возможности восстановления.
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            <Button color="error" key="delete" label="Удалить" type="submit" variant="contained" />,
          ]}
        />
      </Form>
    </Dialog>
  );
};
