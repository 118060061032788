import React from 'react';

import { useInput } from 'react-admin';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/TextField/TextField';
import { TextInput, TextInputProps } from 'shared/react-admin/Inputs/TextInputs';

export type IdInputProps = TextInputProps & { resetFilters: () => void };

export const IdInput: React.FC<IdInputProps> = (props: IdInputProps) => {
  const { field } = useInput(props);
  const { resetFilters, ...restProps } = props;

  if (Array.isArray(field.value) && field.value?.length) {
    return (
      <Autocomplete
        disableClearable={true}
        freeSolo
        multiple
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            InputProps={{ ...params.InputProps, ...props.InputProps }}
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '4px 14px',
              },
            }}
          />
        )}
        renderTags={(value: string[]) => {
          const firstId = value[0];
          const restIdsAmount = value.length - 1;
          return (
            <>
              <Chip
                label={firstId}
                onDelete={() => resetFilters()}
                size="small"
                sx={{ marginRight: 1, maxWidth: 100 }}
              />
              {value.length > 1 && <Chip label={`+${restIdsAmount}`} size="small" />}
            </>
          );
        }}
        value={field.value}
      />
    );
  }
  return (
    <TextInput
      sx={{
        '&.MuiFormControl-root': {
          marginTop: 1,
        },
        '&.MuiFormHelperText-root': {
          marginTop: 1,
        },
      }}
      {...restProps}
    />
  );
};
