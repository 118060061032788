import ImageIcon from '@mui/icons-material/Image';

type MethodsImageFieldProps = {
  imgSrc?: string | null;
};

const size = '200px';
const imgStyle = { width: size, height: size, objectFit: 'contain' };

export const MethodsImageField: React.FC<MethodsImageFieldProps> = ({ imgSrc }) =>
  imgSrc ? (
    <img alt={imgSrc} src={imgSrc} style={imgStyle as React.CSSProperties} />
  ) : (
    <ImageIcon color="disabled" sx={imgStyle} />
  );
