import { styled } from '@mui/material/styles';
import { Dialog } from 'shared/mui/Dialogs';

export const CreateDialogStyled = styled(Dialog)({
  '.RaCreate-main': {
    margin: '0!important',
  },
  '.MuiCardContent-root': {
    padding: 0,
    paddingBottom: '0!important',
  },
});
