import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';
import { MethodTypes } from 'constants/methodTypes';

import { useEffect } from 'react';

import { FormDataConsumer, required } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Divider } from 'shared/mui/Divider/Divider';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  TextInput,
} from 'shared/react-admin/Inputs';
import { TextInputWithAlias } from 'shared/react-admin/Inputs/TextInputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency, ErrorData } from 'types';

import { GatewaysChip } from '../components/GatewaysChip/GatewaysChip';
import { GatewayStatusSelectInput } from '../components/GatewayStatusSelectInput/GatewayStatusSelectInput';

const booleanInputStyle = { marginBottom: '24px' };

type GatewaysCreateFormProps = {
  error?: ErrorData | null;
};

export const GatewaysCreateForm: React.FC<GatewaysCreateFormProps> = ({ error }) => {
  const { control, watch, setValue, setError, clearErrors } = useFormContext();

  useEffect(() => {
    if (!error) {
      clearErrors();

      return;
    }

    error.violations?.forEach(({ message, propertyPath }) => {
      setError(propertyPath, { type: 'string', message });
    });
  }, [error]);

  const pcidss = watch('pcidss');

  const isGatewayDisabled =
    useWatch({ control, name: 'status' }) === GATEWAY_STATUSES[GatewayStatus.Disabled].id;

  useEffect(() => {
    setValue('gatewayMethodsFromMethods', []);
  }, [pcidss]);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <TextInput
            fullWidth
            isRequired
            label="Название"
            size="medium"
            source="name"
            variant="outlined"
          />
          <TextInputWithAlias
            fullWidth
            isRequired
            label="Алиас"
            source="alias"
            variant="outlined"
          />
          <GatewayStatusSelectInput />
          {isGatewayDisabled && (
            <ReferenceInput
              perPage={200}
              reference="gateway_deactivation_reasons"
              source="deactivationReason"
            >
              <AutocompleteInput
                filterToQuery={(searchText: string) => ({ name: searchText })}
                fullWidth
                isRequired
                label="Причина деактивации"
                optionText="reason"
                size="medium"
                variant="outlined"
              />
            </ReferenceInput>
          )}
          <BooleanInput label="Требуется сумма" source="requireAmount" sx={booleanInputStyle} />
          <BooleanInput label="PCI DSS" source="pcidss" sx={booleanInputStyle} />
          <ReferenceInput perPage={200} reference="providers" source="provider">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              isRequired
              label="Провайдер"
              optionText="name"
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceInput reference="gateway_directions" source="direction">
            <SelectInput
              fullWidth
              isRequired
              label="Направления"
              optionText={<GatewaysChip />}
              size="medium"
              source="value"
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceArrayInput
            filter={formData.pcidss ? { methodType: { alias: MethodTypes.PCIDSSBankCard } } : {}}
            perPage={200}
            reference="methods"
            source="gatewayMethodsFromMethods"
          >
            <AutocompleteArrayInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Методы"
              optionText="name"
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            filter={{ active: true }}
            perPage={1000}
            reference="currencies"
            sort={{ field: 'id', order: 'ASC' }}
            source="defaultCurrency"
          >
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ currency: searchText })}
              fullWidth
              isRequired
              label="Валюта по умолчанию"
              optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            filter={{ active: true }}
            perPage={1000}
            reference="currencies"
            sort={{ field: 'id', order: 'ASC' }}
            source="supportedCurrencies"
          >
            <AutocompleteArrayInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ currency: searchText })}
              fullWidth
              label="Поддерживаемые валюты"
              optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
              size="medium"
              source="name"
              variant="outlined"
            />
          </ReferenceArrayInput>
          <NumberInput
            InputLabelProps={{ shrink: true }}
            defaultValue={600}
            format={(value) => value || 0}
            fullWidth
            label="Срок действия заказа (сек)"
            name="orderExpirationTimeSec"
            parse={(value) => value || 0}
            size="medium"
            source="orderExpirationTimeSec"
            validate={required()}
            variant="outlined"
          />
          <BooleanInput
            label="Сохранить данные метода пользователя"
            name="saveCustomerMethodData"
            source="saveCustomerMethodData"
            sx={booleanInputStyle}
          />
          <Divider flexItem key="divider" orientation="horizontal" sx={{ marginBottom: 3 }} />
          <BooleanInput
            label="Опрос статуса заказа"
            name="statusRequestEnabled"
            source="statusRequestEnabled"
            sx={booleanInputStyle}
          />
          <NumberInput
            InputLabelProps={{ shrink: true }}
            defaultValue={50}
            format={(value) => value || 0}
            fullWidth
            label="Макс. количество попыток опроса"
            name="transactionRequestMaxAttempts"
            parse={(value) => value || 0}
            size="medium"
            source="transactionRequestMaxAttempts"
            variant="outlined"
          />
          <TextInput
            defaultValue="5, 10, 15, 20"
            fullWidth
            label="Интервал опроса статуса"
            name="attemptIntervalConfig"
            size="medium"
            source="attemptIntervalConfig"
            validate={required()}
            variant="outlined"
          />
        </>
      )}
    </FormDataConsumer>
  );
};
