import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import { Box } from '../Box';

export const StyledBoxWrapper = styled(Box)({
  border: `1px dashed ${grey[300]}`,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '24px',
  cursor: 'pointer',
});

export const StyledBoxTextStateWrapper = styled(Box)({
  cursor: 'pointer',
  height: '200px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
