import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { SelectItem } from 'types';

import { ReactComponent as ObserverIcon } from '../images/observer.svg';

export const enum Role {
  User = 'ROLE_USER',
  Manager = 'ROLE_MANAGER',
  Merchant = 'ROLE_MERCHANT',
  Admin = 'ROLE_ADMIN',
  Service = 'ROLE_SERVICE',
  Support = 'ROLE_SUPPORT',
  Finance = 'ROLE_FINANCE',
  FinanceLead = 'ROLE_FINANCE_LEAD',
  Payment = 'ROLE_PAYMENT',
  Risk = 'ROLE_RISK',
  PaymentLead = 'ROLE_PAYMENT_LEAD',
  PaymentDuty = 'ROLE_PAYMENT_DUTY',
  Tech = 'ROLE_TECH',
}

export const FINANCE_ROLES = [Role.Finance, Role.FinanceLead];
export const PAYMENT_ROLES = [Role.PaymentLead, Role.Payment, Role.PaymentDuty];

export const ROLES_SELECT_ITEMS: SelectItem<Role>[] = [
  { id: Role.Admin, name: 'Админ' },
  { id: Role.User, name: 'Пользователь' },
  { id: Role.Merchant, name: 'Мерчант' },
  { id: Role.Service, name: 'Сервис' },
  { id: Role.Support, name: 'Поддержка' },
  { id: Role.Finance, name: 'Фин. отдел' },
  { id: Role.FinanceLead, name: 'Рук. фин. отдел' },
  { id: Role.Payment, name: 'Плат. отдел' },
  { id: Role.Risk, name: 'Риск. отдел' },
  { id: Role.PaymentLead, name: 'Старший менеджер плат. отдела' },
  { id: Role.PaymentDuty, name: 'Дежурный сотрудник плат. отдела' },
  { id: Role.Tech, name: 'Разработчик системы' },
];

export const ROLES_ICONS: { [key in Role]?: React.ReactElement } = {
  [Role.Merchant]: <SupervisedUserCircleIcon color="secondary" />,
  [Role.Service]: <SettingsIcon color="secondary" />,
};

export const enum MerchantSpacesRole {
  HeadManager = 'Head manager',
  FinanceManager = 'Finance manager',
  PaymentManager = 'Payment manager',
  RiskManager = 'Risk manager',
  Support = 'Support',
  Observer = 'Observer',
}

export const MERCHANT_SPACES_ROLES_ICONS: { [key in MerchantSpacesRole]?: React.ReactElement } = {
  [MerchantSpacesRole.HeadManager]: <SupervisedUserCircleIcon color="primary" />,
  [MerchantSpacesRole.FinanceManager]: <MonetizationOnIcon color="primary" />,
  [MerchantSpacesRole.PaymentManager]: <PlaylistAddCheckCircleIcon color="primary" />,
  [MerchantSpacesRole.RiskManager]: <OfflineBoltIcon color="primary" />,
  [MerchantSpacesRole.Support]: <BuildCircleIcon color="primary" />,
  [MerchantSpacesRole.Observer]: <ObserverIcon />,
};

export const MERCHANT_SPACES_ROLES_SELECT_ITEMS: SelectItem<MerchantSpacesRole>[] = [
  { id: MerchantSpacesRole.HeadManager, name: 'Главный менеджер' },
  { id: MerchantSpacesRole.FinanceManager, name: 'Фин. менеджер' },
  { id: MerchantSpacesRole.PaymentManager, name: 'Плат. менеджер' },
  { id: MerchantSpacesRole.RiskManager, name: 'Риск менеджер' },
  { id: MerchantSpacesRole.Support, name: 'Поддержка' },
  { id: MerchantSpacesRole.Observer, name: 'Наблюдатель' },
];
