import { ChartData } from 'chart.js';

import {
  LegendItemDotStyled,
  LegendItemStyled,
  LegendWrapperStyled,
} from './ConversionLegend.styled';

type ConversionLegendProps = {
  data: ChartData<'bar', number[], string>;
};

export const ConversionLegend: React.FC<ConversionLegendProps> = ({ data }) => {
  return (
    <LegendWrapperStyled>
      {data.datasets?.map((item, index) => {
        const percent = item?.data[0];

        return (
          <LegendItemStyled key={`${percent}-${index}`}>
            <LegendItemDotStyled
              sx={{
                backgroundColor:
                  typeof item?.backgroundColor === 'string' ? item?.backgroundColor : 'grey',
              }}
            />
            {percent}% {item.label}
          </LegendItemStyled>
        );
      })}
    </LegendWrapperStyled>
  );
};
