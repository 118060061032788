export function sortBy<T>({
  array,
  field,
  order,
}: {
  array: T[];
  field: keyof T;
  order: string;
}): T[] {
  return [...array].sort((a, b) => {
    if (order === 'ASC') {
      return a[field] > b[field] ? -1 : 1;
    }
    return a[field] > b[field] ? 1 : -1;
  });
}
