import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';
import { cleanEmpty } from 'utils/cleanEmpty';
import { getBooleanFromString } from 'utils/getBooleanFromString';

import { CumulativeReportFilterValues } from '../CumulativeReportList';

type SearchParamsFilter = NonNullable<CumulativeReportFilterValues>;

export function useCumulativeReportSearchParamsFilters(): SearchParamsFilter {
  const [searchParams] = useSearchParams();

  const searchParamsFilter: CumulativeReportFilterValues = useMemo(() => {
    return cleanEmpty({
      periodFrom: Number(searchParams.get('periodFrom')) || undefined,
      periodTo: Number(searchParams.get('periodTo')) || undefined,
      accountsSummary: getBooleanFromString(searchParams.get('accountsSummary')),
      merchantAccounts: searchParams.getAll('merchantAccounts'),
      currency: searchParams.get('currency'),
      merchant: searchParams.get('merchant'),
      provider: searchParams.get('provider'),
      company: searchParams.get('company'),
    }) as CumulativeReportFilterValues;
  }, [searchParams]);

  return searchParamsFilter;
}
