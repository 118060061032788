import { TabPanelStyled } from './TabPanel.styled';
import { BoxProps } from '../Box';

export type TabPanelProps = BoxProps & {
  value: number;
  index: number;
};

export const TabPanel: React.FC<TabPanelProps> = ({ children, ...restProps }) => {
  return (
    <TabPanelStyled {...restProps}>
      {restProps.value === restProps.index && children}
    </TabPanelStyled>
  );
};
