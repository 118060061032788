import { styled } from '@mui/material/styles';
import { Avatar } from 'shared/mui/Avatars';
import { appColors } from 'themes/variables';

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  backgroundColor: appColors.background,
}));
