import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Card } from '../../Card';

export const FilterStyled = styled(Card)({
  padding: '16px',
  marginBottom: '16px',
  border: `1px solid ${appColors.divider}`,
});
