import { useNavigate } from 'react-router-dom';

import { useHistoryContext } from './useHistoryContext';
import { useNavigateToRoot } from './useNavigateToRoot';

export function useGoBack(): { goBack: () => void } {
  const navigate = useNavigate();
  const { getBackUrl } = useHistoryContext();
  const { toRoot } = useNavigateToRoot();

  function goBack(): void {
    const url = getBackUrl();

    if (url) {
      navigate(url);

      return;
    }

    toRoot();
  }

  return { goBack };
}
