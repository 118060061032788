import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { merchantPayoutControlsUpdateMapping } from 'config/requests';
import { useGetOne, useNotify, useRecordContext } from 'react-admin';
import { useMutation } from 'react-query';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { MappingItemRecord, Method } from 'types';

import { useCurrentMappingDataContext } from '../../context/CurrentMappingContext';
import { useEditPayoutControlContext } from '../../context/EditPayoutControlContext';

type MappingExceptionsDeleteDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  mapping?: MappingItemRecord;
};

export const EditPayoutControlMappingDeleteDialog: React.FC<MappingExceptionsDeleteDialogProps> = ({
  onClose,
  isOpen,
  mapping,
}) => {
  const record = useRecordContext();
  const notify = useNotify();
  const { setMappingChosen, setMappingData } = useCurrentMappingDataContext();
  const {
    payoutControl: { id: payoutControlId },
  } = useEditPayoutControlContext();

  const { data: method } = useGetOne<Method>('methods', { id: mapping?.method });

  const { mutate, isLoading } = useMutation(merchantPayoutControlsUpdateMapping, {
    onSuccess: () => {
      notify('Маппинг успешно удален', { type: 'success' });
      setMappingData({});
      setMappingChosen(undefined);
      queryClient.refetchQueries(['merchant_payout_controls', record?.originId]);
      onClose();
    },
    onError: (error: AxiosError<{ detail: string }>) => {
      notify(error?.response?.data?.detail || 'Что-то пошло не так. Повторите запрос позже.', {
        type: 'error',
      });
    },
  });

  const onDelete = () => {
    mutate({ payoutControlId });
  };

  return (
    <DeleteDialog
      details={`Маппинг для метода ${method?.name || ''} будет удален из исключений.`}
      isLoading={isLoading}
      onClose={onClose}
      onDelete={onDelete}
      onSuccess={onClose}
      open={isOpen}
      record={mapping}
      title="Удаление маппинга из исключения"
    />
  );
};
