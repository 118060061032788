import ContentCreate from '@mui/icons-material/Create';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { EditButtonProps, useCreatePath, useRecordContext, useResourceContext } from 'react-admin';

import { StyledEditIconButton } from './EditIconButton.styled';
import { useCreateEntityFilterQueryString } from '../../../../hooks/useCreateEntityFilterQueryString';

export type EditIconButtonProps = {
  iconSx?: SxProps<Theme>;
  entity?: string;
  target?: string;
} & EditButtonProps;

export function EditIconButton({
  label = '',
  variant,
  entity,
  iconSx,
  ...restProps
}: EditIconButtonProps): JSX.Element {
  const createPath = useCreatePath();
  const resource = useResourceContext(restProps);
  const record = useRecordContext(restProps);

  const { createEntityFilterQueryString } = useCreateEntityFilterQueryString();
  const search = entity ? createEntityFilterQueryString({ entity }) : '';
  const icon = restProps.icon || <ContentCreate sx={iconSx} />;

  return (
    <StyledEditIconButton
      icon={icon}
      label={label}
      to={{ pathname: createPath({ type: 'edit', resource, id: record.id }), search }}
      variant={variant}
      {...restProps}
    />
  );
}
