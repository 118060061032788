import { RaRecord } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';
import {
  BooleanInput,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';

const transform = (data: RaRecord): RaRecord => {
  return {
    ...data,
    comment: data.comment || null,
  };
};

export const ProvidersCreate = () => (
  <CreateGuesserDialog
    mutationOptions={{ onError: () => undefined }}
    title="Cоздание провайдера"
    transform={transform}
  >
    <CardContent sx={{ paddingTop: '0!important', width: '552px' }}>
      <BooleanInput
        defaultValue={true}
        label="Активен"
        source="active"
        sx={{
          marginBottom: '16px',
        }}
      />
      <TextInputIntrospected fullWidth label="Название" source="name" variant="outlined" />
      <TextInputWithAliasIntrospected fullWidth label="Алиас" source="alias" variant="outlined" />
      <TextInputIntrospected fullWidth label="Комментарий" source="comment" variant="outlined" />
    </CardContent>
    <CardActionsButtons
      leftActionsSlot={[
        <SaveButtonValidated key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
    />
  </CreateGuesserDialog>
);
