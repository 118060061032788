import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { Table } from 'shared/mui/Table';
import { appColors } from 'themes/variables';

export const FormControlStyled = styled(FormControl)({
  width: '100%',
  margin: 0,
  marginBottom: '24px',
  '& .MuiFormControl-root': {
    margin: 0,
  },
  '& .MuiInputBase-root': {
    padding: '16px 24px 16px 12px !important',
  },
  '& .MuiInputBase-input': {
    height: 24,
    padding: '0 !important',
  },
  '& .MuiFormHelperText-root': {
    height: 24,
    marginTop: 0,
  },
  '& .MuiFormLabel-root': {
    top: 8,
  },
  '& .MuiInputLabel-shrink': {
    top: 0,
  },
});

export const ChipStyled = styled(Chip)({
  height: 24,
  backgroundColor: appColors.divider,
  border: 'none',
  '& .MuiChip-label': {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: '16px',
  },
});

export const StyledTable = styled(Table)({
  marginBottom: '24px',
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  '& .MuiTableCell-head': {
    verticalAlign: 'top',
    height: 64,
    padding: '16px',
  },
  '& .MuiTableCell-head span': {
    lineHeight: 1,
  },
  '& .MuiTableRow-root': {
    height: 56,
  },
  '& tr:has(.Mui-checked)': {
    background: 'transparent',
  },
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
});

export const StyledBox = styled(Box)({
  display: 'flex',
  width: '100%',
  padding: '15px 16px',
  marginBottom: '24px',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
  borderRadius: '4px',
});
