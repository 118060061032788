import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { CancelButton } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { DeleteStageDialogForm } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogForm';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Company } from 'types';

import { StyledBox } from './CompaniesDeleteDialog.styled';

type CompaniesDeleteDialogProps = {
  record?: Company;
  open: boolean;
  onClose: () => void;
};

export const CompaniesDeleteDialog: React.FC<CompaniesDeleteDialogProps> = ({
  record,
  open,
  onClose,
}) => {
  const { goBack } = useGoBack();
  const { deleteOneRecord, denyDeleteReason, stage } = useStagedDelete({
    record,
    onSuccess: async () => {
      onClose();
      goBack();
      await queryClient.refetchQueries(['companies']);
    },
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Удаление компании"
        />
        <DeleteStageDialogForm
          deleteOneRecord={deleteOneRecord}
          details={`После удаления компания ${record?.name} будет недоступна для восстановления.`}
          onClose={onClose}
          open={true}
          stage={stage}
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton label="Вернуться к списку компаний" linkType="exit" variant="outlined" />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialogForm>
      </StyledBox>
    </Dialog>
  );
};
