import { DeleteStages } from 'constants/deleteStages';

import { queryClient } from 'App';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { CancelButton } from 'shared/mui/Buttons';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { GatewayRuleRecord } from 'types';

type Props = {
  record?: GatewayRuleRecord;
  queryKey: string;
  open: boolean;
  onClose: () => void;
};

export const MerchantShowRulesDeleteDialog = ({ onClose, open, record, queryKey }: Props) => {
  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record,
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKey]);
      setStage(DeleteStages.Initial);
      onClose();
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    onClose();
  };

  return (
    <DeleteStageDialog
      deleteOneRecord={deleteOneRecord}
      details={`Правило ${record?.name} будет удалено без возможности восстановления.`}
      onClose={handleCloseDeleteDialog}
      open={open}
      stage={stage}
      title="Удаление правила"
    >
      <DeleteStageDialogDenyReason reason={denyDeleteReason}>
        <CancelButton
          key="history-back-button"
          label="Закрыть"
          onClick={handleCloseDeleteDialog}
          variant="outlined"
        />
      </DeleteStageDialogDenyReason>
    </DeleteStageDialog>
  );
};
