import {
  ApiDocumentationParserResponse,
  ApiPlatformAdminDataProvider,
  hydraDataProvider as baseHydraDataProvider,
  HydraHttpClientResponse,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';

import { API_PATH } from '../config/entrypoint';

function getHeaders(): HeadersInit {
  const token = localStorage.getItem('token');

  if (!token) {
    return {};
  }

  return {
    Authorization: `${token}`,
    'Content-Type': 'application/json',
  };
}

function fetchHydra(url: URL, options = {}): Promise<HydraHttpClientResponse> {
  return baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
}

async function apiDocumentationParser(): Promise<ApiDocumentationParserResponse> {
  try {
    return await parseHydraDocumentation(API_PATH, { headers: getHeaders });
  } catch (result: any) {
    const { api, response, status, error } = result;

    if (status === 401 || status === 403) {
      localStorage.removeItem('token');

      return {
        api,
        response,
        status,
      };
    }

    if (error.response) {
      const errorResponse = await error.response.json();
      const currentError = errorResponse.errors[0];

      throw new Error(`${currentError.title}
        Status: ${currentError.code}
        class: ${currentError.exception.class}
        file: ${currentError.exception.file}
        line: ${currentError.exception.line}`);
    }

    throw result;
  }
}

export const dataProvider: ApiPlatformAdminDataProvider = baseHydraDataProvider({
  entrypoint: API_PATH,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser,
  mercure: false,
  useEmbedded: false,
});
