import { useState } from 'react';

import ContentAdd from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useRecordModal } from 'hooks/useRecordModal';
import { WithListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { ApiClient, ApiKey } from 'types';
import { Permission } from 'types';

import { StyledDatagrid } from './ApiClientsShowApiKeys.styled';
import { ApiClientsShowApiKeysCreateDialog } from './ApiClientsShowApiKeysCreateDialog/ApiClientsShowApiKeysCreateDialog';
import { ApiClientsShowApiKeysField } from './ApiClientsShowApiKeysField/ApiClientsShowApiKeysField';
import { ApiClientsShowApiKeysStatusField } from './ApiClientsShowApiKeysStatusField/ApiClientsShowApiKeysStatusField';
import { usePermissions } from '../../../../hooks/usePermissions';
import { DateTimeField, TextField } from '../../../../shared/react-admin/Fields';

type Props = {
  record?: ApiClient;
};

export const ApiClientsShowApiKeys: React.FC<Props> = ({ record }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenModalDelete,
    openRecordModal: openModalDelete,
    handleCloseRecordModal: handleCloseModalDelete,
  } = useRecordModal<ApiKey>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('api_keys', [Permission.Full, Permission.Create]) && (
          <Button
            key="create"
            label="Добавить"
            onClick={() => setIsOpenCreateDialog(true)}
            startIcon={<ContentAdd />}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
            variant="contained"
          />
        ),
      ]}
      empty={false}
      filter={{ apiClient: record?.id }}
      headerTitle={{
        titleText: 'Список ключей API',
        titleSize: 'small',
      }}
      resource="api_keys"
      sort={{ field: 'id', order: 'ASC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <WithListContext
        render={({ data, isLoading }) => {
          const showAlert = !data?.length && !isLoading;

          return (
            <>
              {showAlert ? (
                <Alert severity="info">
                  <Typography fontWeight={500}>Здесь пока ключей API.</Typography>
                  <Typography fontSize="14px">
                    Чтобы добавить ключи API, нажмите кнопку “Добавить”.
                  </Typography>
                </Alert>
              ) : (
                <>
                  <StyledDatagrid bulkActionButtons={false}>
                    <TextField label="ID" sortBy="id" source="originId" />
                    {hasResourceAccess('api_keys') && (
                      <ApiClientsShowApiKeysField label="Ключ API" />
                    )}
                    <ApiClientsShowApiKeysStatusField label="Состояние" />
                    <DateTimeField label="Дата создания" source="createdAt" />
                    <ActionsSlots
                      render={(record: ApiKey) => (
                        <>
                          {hasResourcePermissions('api_keys', [
                            Permission.Full,
                            Permission.Delete,
                          ]) && <DeleteIconButton onClick={() => handleOpenModalDelete(record)} />}
                        </>
                      )}
                    />
                  </StyledDatagrid>
                  {openModalDelete && (
                    <DeleteDialog
                      alert={
                        <Alert severity="warning">{`В случае удаления клиента API с признаком ${record?.active ? '"Активен"' : '"Не активен"'}, доступ к ресурсам public API для клиента ${record?.name} будет приостановлен.`}</Alert>
                      }
                      details="Восстановить значение ключа API, после его удаления будет невозможно."
                      onClose={handleCloseModalDelete}
                      open={openModalDelete}
                      record={recordDelete}
                      title="Удаление ключа API"
                    />
                  )}
                </>
              )}
              {isOpenCreateDialog && (
                <ApiClientsShowApiKeysCreateDialog
                  apiClient={record}
                  onClose={() => setIsOpenCreateDialog(false)}
                  open={isOpenCreateDialog}
                />
              )}
            </>
          );
        }}
      />
    </ListPage>
  );
};
