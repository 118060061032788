import { useCreate, useNotify } from 'react-admin';

import { useGetMerchantNotificationsEventNames } from './useGetMerchantNotificationsEventNames';
import { MerchantsShowNotificationsFormValues } from '../components/MerchantsShowNotificationsForm/MerchantsShowNotificationsForm';
import { getMerchantNotificationData } from '../utils/getMerchantNotificationData';

type UseCreateMerchantNotificationReturnValue = {
  isLoading: boolean;
  createMerchantNotification: (data: MerchantsShowNotificationsFormValues) => void;
};

export function useCreateMerchantNotification(
  merchantId: string,
  onSuccess: VoidFunction,
): UseCreateMerchantNotificationReturnValue {
  const { eventNames } = useGetMerchantNotificationsEventNames();

  const [create, { isLoading }] = useCreate();
  const notify = useNotify();

  const createMerchantNotification = (values: MerchantsShowNotificationsFormValues) => {
    const data = getMerchantNotificationData(values, merchantId, eventNames);
    create(
      'merchant_notification_settings',
      { data },
      {
        onSuccess: () => {
          onSuccess();
          notify('Настройка уведомлений успешно создана!', { type: 'success' });
        },
        onError: (error) => {
          notify(`Ошибка: ${error as string}` || 'Ошибка создания настройки уведомлений', {
            type: 'error',
          });
        },
      },
    );
  };

  return { isLoading, createMerchantNotification };
}
