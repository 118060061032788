export function flatObject<T extends Record<string, unknown>>(obj: T, parentKey?: string): T {
  let result = {} as T;
  Object.keys(obj).forEach((key) => {
    const value = obj[key as keyof T];
    const resultKey = parentKey ? parentKey + '.' + key : key;
    if (value && typeof value === 'object' && !(value instanceof Date) && !Array.isArray(value)) {
      result = { ...result, ...flatObject(value as T, resultKey) };
    } else {
      result[resultKey as keyof T] = value;
    }
  });

  return result;
}
