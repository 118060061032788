import { createContext, useContext } from 'react';

import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';

const BalanceReportContext = createContext<{ filters: BalanceReportFilterValues }>({
  filters: {},
});

export const BalanceReportContextProvider = BalanceReportContext.Provider;

export function useBalanceReportContext(): { filters: BalanceReportFilterValues } {
  const context = useContext(BalanceReportContext);

  if (!context) {
    throw new Error(
      'Can not `useBalanceReportContext` outside of the `BalanceReportContextProvider`',
    );
  }

  return context;
}
