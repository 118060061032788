import { styled } from '@mui/material';
import { Box } from 'shared/mui/Box';

export const GatewayRulesShowCardHeader = styled(Box)({
  height: '92px',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  padding: '16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
});
