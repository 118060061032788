import { FilterChipType } from 'constants/filterChipType';

import { useMemo } from 'react';

import { FieldValues, Path } from 'react-hook-form';

import { flatObject } from '../utils/flatObject';

type Filter = {
  source: string;
  label: string;
  type: FilterChipType;
  options?: Record<string, string>[];
};

export type FilterChipValue<TFilterValues extends FieldValues> = {
  value: any;
  source: Path<TFilterValues>;
} & Filter;

type UseFilterChipsProps = {
  filters: Filter[];
  appliedFilters: Record<string, any>;
};

type UseFilterChipsReturnValues<TFilterValues extends FieldValues> = {
  filterChipValues: FilterChipValue<TFilterValues>[];
};

export function useFilterChips<TFilterValues extends FieldValues>({
  filters,
  appliedFilters,
}: UseFilterChipsProps): UseFilterChipsReturnValues<TFilterValues> {
  const filterChipValues = useMemo<FilterChipValue<TFilterValues>[]>(() => {
    const flatAppliedFilters = flatObject(appliedFilters);

    return Object.keys(flatAppliedFilters).map((source) => {
      const currentFilter = filters.find((filter) => filter.source === source);

      if (!currentFilter?.label || !currentFilter.type) {
        console.warn(`Applied filter '${source}' does not exist on 'filters' property`);
      }

      return {
        label: currentFilter?.label,
        type: currentFilter?.type,
        value: flatAppliedFilters[source],
        options: currentFilter?.options,
        source,
      } as FilterChipValue<TFilterValues>;
    });
  }, [appliedFilters]);

  return {
    filterChipValues,
  };
}
