import { createContext, useContext } from 'react';

import { ExpressionParam } from 'types';

type GatewayRulesFormContextData = {
  loadingExpressionParams: boolean;
  expressionParams: ExpressionParam[];
};

const GatewayRulesFormContext = createContext<GatewayRulesFormContextData | null>(null);
export const GatewayRulesFormProvider = GatewayRulesFormContext.Provider;

export function useGatewayRulesFormContext(): GatewayRulesFormContextData {
  const context = useContext(GatewayRulesFormContext);

  if (!context) {
    throw new Error(
      'Can not `useGatewayRulesFormContext` outside of the `GatewayRulesFormProvider`',
    );
  }

  return context;
}
