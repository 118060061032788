import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

import { useFilters } from '../../../hooks/useFilters';

type ProvidersFilterValues = {
  name: string;
  active: boolean | null;
};

export const ProvidersFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<ProvidersFilterValues>({
    active: null,
    name: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={BOOLEAN_CHOICES}
              fullWidth
              helperText={false}
              label="Активен"
              name="active"
              source="active"
              variant="outlined"
            />
            <FilterActions sx={{ mt: 0 }}>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
