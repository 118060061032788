import { Delete } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)<ButtonProps>(({ title }) => ({
  height: '36px',
  minWidth: '36px',
  lineHeight: '1.75!important',
  padding: !title ? '0' : '12px 16px',
  '& .MuiButton-startIcon': {
    padding: 0,
    margin: 0,
  },
}));

export function AppBaseDeleteButton({
  variant = 'outlined',
  color = 'error',
  title = '',
  startIcon = <Delete />,
  ...restProps
}: ButtonProps): JSX.Element {
  return (
    <StyledButton
      color={color}
      startIcon={startIcon}
      title={title}
      variant={variant}
      {...restProps}
    >
      {title}
    </StyledButton>
  );
}

export const AppDialogCircleDeleteButton = styled(AppBaseDeleteButton)({
  padding: '0',
  minWidth: '28px',
  height: '28px',
  '&:hover': { borderRadius: '50%' },
});
