import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserDialog } from 'shared/react-admin/Dialogs/CreateGuesserDialog/CreateGuesserDialog';
import { TextInputIntrospected } from 'shared/react-admin/Inputs';

export const GatewayDeactivationReasonsCreate = () => {
  return (
    <CreateGuesserDialog
      mutationOptions={{
        onError: () => undefined,
      }}
      sx={{ width: '444px' }}
      title="Добавление причины"
    >
      <CardContent sx={{ width: 'calc(100% - 48px)' }}>
        <TextInputIntrospected
          InputLabelProps={{ shrink: true }}
          fullWidth
          helperText={false}
          label="Причина"
          multiline
          size="medium"
          source="reason"
          variant="outlined"
        />
      </CardContent>
      <CardActionsButtons
        leftActionsSlot={[
          <SaveButton key="save-button" />,
          <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
        ]}
        sx={{ paddingTop: '32px' }}
      />
    </CreateGuesserDialog>
  );
};
