import { getMerchantSpacesRequest } from 'config/requests';
import { useCreatePath, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';
import { LinkChip } from 'shared/mui/Chips';
import { MerchantSpacesUsers } from 'types';
import { getId } from 'utils';

export function MerchantSpacesUsersSpaceField(): JSX.Element {
  const record = useRecordContext<MerchantSpacesUsers>();

  const { data: merchantSpace } = useQuery(
    ['merchant_spaces', record?.merchantSpaceId],
    () => getMerchantSpacesRequest(record?.merchantSpaceId),
    { enabled: !!record?.merchantSpaceId },
  );

  const createPath = useCreatePath();

  if (!record?.merchantSpaceId) {
    return <span>—</span>;
  }

  return (
    <LinkChip
      href={createPath({ resource: 'merchant_spaces', id: getId(merchantSpace?.id), type: 'edit' })}
      label={merchantSpace?.spaceName}
      size="small"
    />
  );
}
