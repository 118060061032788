import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { AvatarGroup } from 'shared/mui/AvatarGroup/AvatarGroup';
import { Chip } from 'shared/mui/Chips';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { ChipField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

const trimmedStrStyle = {
  a: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const columnStyle160 = getColumnStyle(160);

export const StyledDatagrid = styled(Datagrid)({
  '& .column-undefined': getColumnStyle(200),
  '& .column-company': columnStyle160,
  '& .column-provider': getColumnStyle(140),
  '& .column-members': columnStyle160,
  '& .column-workTeam': columnStyle160,
  '& .column-merchants': {
    ...getColumnStyle(240),
    ...trimmedStrStyle,
  },
  '& .column-merchantAccounts': {
    ...getColumnStyle(300),
    ...trimmedStrStyle,
  },
});

export const StyledChipField = styled(ChipField)({
  background: blue[700],
  cursor: 'pointer!important',
  '&:hover': {
    background: blue[700],
  },
  '& .MuiChip-label': {
    color: appColors.text.light,
  },
});

export const StyledChipFieldLink = styled(ChipField)({
  '& .MuiChip-label': {
    color: blue[700],
  },
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  width: 'fit-content',
  '& .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: '11px',
    fontWeight: 500,
  },
});

export const StyledChip = styled(Chip)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
