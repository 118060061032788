import { styled } from '@mui/material';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

export const ApiClientsShowTopPanelWrapper = styled(Box)({
  height: '54px',
  width: '100%',
  display: 'flex',
  backgroundColor: appColors.background,
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
});

export const ApiClientsShowTopPanelItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  '&:not(:last-child)': {
    borderRight: `1px solid ${appColors.divider}`,
  },
});
