import { Dispatch, useEffect } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, TextField } from '@mui/material';
import { useFirstRender } from 'hooks/useFirstRender';
import { required, FormDataConsumer, number, useGetOne, minValue } from 'react-admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext, Controller } from 'react-hook-form';
import { AutocompleteInput, TextInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { appColors } from 'themes/variables';
import { Gateway, MerchantAccount } from 'types';

import { AppReferenceArrayInput } from '../../../Common';

type Props = {
  currency?: string;
  provider?: string;
  setCurrency: Dispatch<React.SetStateAction<string | undefined>>;
  setProvider: Dispatch<React.SetStateAction<string | undefined>>;
};

export const BankOperationsForm = ({
  currency,
  provider,
  setCurrency,
  setProvider,
}: Props): JSX.Element => {
  const { watch, setValue, control } = useFormContext();
  const [
    merchantId,
    merchantAccountId,
    gatewayId,
    amountPennyId,
    commentId,
    dateOfOperationId,
    directionId,
    typeId,
  ] = watch([
    'merchant',
    'merchantAccount',
    'gateway',
    'amountPenny',
    'comment',
    'currency',
    'dateOfOperation',
    'direction',
    'provider',
    'type',
  ]);
  const { isFirstRender } = useFirstRender();

  function formatDate(date: Date | string): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  }

  useEffect(() => {
    if (!merchantAccountId) {
      setCurrency(undefined);
      setValue('currency', undefined);
      setValue('gateway', undefined);
    }
  }, [merchantAccountId]);

  useEffect(() => {
    if (!gatewayId) {
      setProvider(undefined);
      setValue('provider', undefined);
    }
  }, [gatewayId]);

  useEffect(() => {
    if (!merchantId) {
      setCurrency(undefined);
      setValue('currency', undefined);
      setValue('merchantAccount', undefined);
      setValue('gateway', undefined);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantAccountId) {
      setValue('currency', currency);
    }

    if (gatewayId) {
      setValue('provider', provider);
    }
  }, [
    merchantId,
    merchantAccountId,
    gatewayId,
    amountPennyId,
    commentId,
    dateOfOperationId?.toString(),
    directionId,
    typeId,
  ]);

  useGetOne<MerchantAccount>(
    'merchant_accounts',
    { id: merchantAccountId },
    {
      enabled: !!merchantAccountId && !isFirstRender,
      onSuccess: (merchantAccount) => {
        const newCurrency = merchantAccount?.currency;
        setCurrency(newCurrency); // Обновляем локальное состояние
        setValue('currency', newCurrency); // Обновляем значение в форме
      },
    },
  );

  useGetOne<Gateway>(
    'gateways',
    { id: gatewayId },
    {
      enabled: !!gatewayId && !isFirstRender,
      onSuccess: (gateway) => {
        const newProvider = gateway?.provider;
        setProvider(newProvider);
        setValue('provider', newProvider);
      },
    },
  );

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <AppReferenceArrayInput reference="merchants" source="merchant">
            <AutocompleteInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Мерчант"
              optionText="name"
              size="medium"
              source="name"
              validate={required()}
              variant="outlined"
            />
          </AppReferenceArrayInput>
          <AppReferenceArrayInput
            filter={{ merchant: formData.merchant || '' }}
            reference="merchant_accounts"
            source="merchantAccount"
          >
            <AutocompleteInput
              clearOnBlur={false}
              disabled={!formData.merchant || undefined}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Счет мерчанта"
              optionText="name"
              size="medium"
              source="name"
              validate={required()}
              variant="outlined"
            />
          </AppReferenceArrayInput>
          <Stack direction="row" spacing={2} width="100%">
            <Stack direction="column" flex={1}>
              <Controller
                control={control}
                name="dateOfOperation"
                render={({ field, fieldState }) => (
                  <DatePicker
                    customInput={
                      <TextField
                        InputProps={{
                          endAdornment: <ArrowDropDownIcon htmlColor={appColors.secondary.main} />,
                        }}
                        error={!!fieldState.error}
                        fullWidth
                        helperText={fieldState.error?.message}
                        label="Дата проведения *"
                        sx={{
                          margin: 0,
                          marginBottom: '24px',
                          '& .MuiInputBase-input': { height: 24, padding: '16px 12px' },
                          '& .MuiFormLabel-root': { top: 6 },
                          '& .MuiInputLabel-shrink': {
                            top: 0,
                          },
                        }}
                        value={formatDate(field.value)}
                        variant="outlined"
                      />
                    }
                    dateFormat="dd/MM/yyyy HH:mm"
                    locale="ru"
                    maxDate={new Date()}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    selected={formatDate(field.value)}
                    showTimeInput
                    timeInputLabel="Time:"
                  />
                )}
                rules={{
                  required: 'Обязательно для заполнения',
                  validate: (value) =>
                    new Date(value).getTime() <= new Date().getTime() ||
                    'Указанное время больше текущего',
                }}
              />
            </Stack>
            <Stack direction="column" flex={1}>
              <ReferenceInput reference="directions" source="direction">
                <AutocompleteInput
                  clearOnBlur={false}
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  fullWidth
                  label="Направлениe"
                  optionText="description"
                  size="medium"
                  source="value"
                  validate={required()}
                  variant="outlined"
                />
              </ReferenceInput>
            </Stack>
          </Stack>
          <ReferenceInput reference="bank_operation_types" source="type">
            <AutocompleteInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Тип операции"
              optionText="name"
              size="medium"
              source="type"
              validate={required()}
              variant="outlined"
            />
          </ReferenceInput>
          <Stack direction="row" spacing={2} width="100%">
            <Stack direction="column" flex={1}>
              <TextInputIntrospected
                label="Сумма"
                source="amountPenny"
                validate={[
                  required(),
                  number('Введите число. Например 5 или 0.5'),
                  minValue(Number.MIN_VALUE, 'Значение должно быть больше нуля'),
                ]}
                variant="outlined"
              />
              <ReferenceInput
                filter={{ merchantAccounts: formData.merchantAccount || '' }}
                perPage={200}
                reference="gateways"
                source="gateway"
              >
                <AutocompleteInput
                  disabled={!formData.merchantAccount || undefined}
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  fullWidth
                  label="Шлюз"
                  optionText="name"
                  size="medium"
                  source="name"
                  validate={required()}
                  variant="outlined"
                />
              </ReferenceInput>
            </Stack>
            <Stack direction="column" flex={1}>
              <ReferenceInput reference="currencies" source="currency">
                <AutocompleteInput
                  disabled
                  fullWidth
                  label="Валюта"
                  optionText="name"
                  size="medium"
                  validate={required()}
                  variant="outlined"
                />
              </ReferenceInput>
              <ReferenceInput perPage={200} reference="providers" source="provider">
                <AutocompleteInput
                  disabled
                  fullWidth
                  label="Провайдер"
                  optionText="name"
                  size="medium"
                  validate={required()}
                  variant="outlined"
                />
              </ReferenceInput>
            </Stack>
          </Stack>
          <TextInputIntrospected
            fullWidth
            label="Комментарий"
            multiline
            size="medium"
            source="comment"
            variant="outlined"
          />
        </>
      )}
    </FormDataConsumer>
  );
};
