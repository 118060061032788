import { Button, CancelButton } from 'shared/mui/Buttons';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';
import { DialogProps } from 'shared/mui/Dialogs';

import { BaseDialog } from '../BaseDialog/BaseDialog';

export type NotifyDialogProps = {
  isLoading?: boolean;
  title: React.ReactNode;
  message: JSX.Element;
  onSubmit: () => void;
  acceptButtonTitle?: string;
  onClose: () => void;
} & Omit<DialogProps, 'title'>;

export const NotifyDialog = ({
  acceptButtonTitle,
  isLoading,
  open,
  onClose,
  message,
  onSubmit,
  title,
}: NotifyDialogProps): JSX.Element => (
  <BaseDialog
    leftActionsSlot={[
      <Button
        disabled={isLoading}
        key="accept-button"
        label={isLoading ? 'Загрузка' : acceptButtonTitle || 'Подтвердить'}
        onClick={onSubmit}
        startIcon={isLoading && <CircularProgress color="primary" size={25} />}
        variant="contained"
      />,
      <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
    ]}
    message={message}
    onClose={onClose}
    open={open}
    title={title}
  />
);
