import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { FieldValues } from 'react-hook-form';

import { FilterChipValue } from '../../../../../hooks/useFilterChips';
import { ChipProps } from '../../../Chips';

type Props<TFilterValues extends FieldValues> = {
  chipValue: FilterChipValue<TFilterValues>;
} & ChipProps;

export function FilterChipsExistsField<TFilterValues extends FieldValues>({
  chipValue,
  ...restProps
}: Props<TFilterValues>) {
  return (
    <Chip
      deleteIcon={<CancelIcon />}
      label={`${chipValue.label}: ${chipValue.value ? 'Нет' : 'Да'}`}
      {...restProps}
    />
  );
}
