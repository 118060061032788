import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { useEffect, useState } from 'react';

import { differenceInCalendarDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useFirstRender } from 'hooks/useFirstRender';
import { FieldTitle, RaRecord, required, useListContext } from 'react-admin';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { CalendarTodayIcon } from 'shared/mui/Icons';
import { TextField } from 'shared/mui/TextField/TextField';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { getEndOfDate } from 'utils';

import { CumulativeReportPeriodAlert } from './components/CumulativeReportAlerts/CumulativeReportPeriodAlert';
import { CumulativeReportRequiredFieldsAlert } from './components/CumulativeReportAlerts/CumulativeReportRequiredFieldsAlert';
import { CumulativeReportSuccessAlert } from './components/CumulativeReportAlerts/CumulativeReportSuccessAlert';
import { CumulativeReportFilterValues } from './CumulativeReportList';

const MAX_PERIOD_IN_DAYS = 31;

type Props = {
  form: UseFormReturn<any>;
  resetFilter: () => void;
  onSubmit: (values: any) => void;
  filters: CumulativeReportFilterValues;
};

registerLocale('ru', ru);

export const CumulativeReportFilter: React.FC<Props> = ({
  filters,
  form,
  resetFilter,
  onSubmit,
}) => {
  const [showAlert, setShowAlert] = useState(true);
  const { data } = useListContext();
  const { isFirstRender } = useFirstRender();

  const [currentCompanyValue, currentMerchantValue, currentProviderValue] = form.watch([
    'company',
    'merchant',
    'provider',
  ]);
  const isFormTouched = !!Object.keys(form.formState.touchedFields).length;

  useEffect(() => {
    if (!isFirstRender && isFormTouched) {
      form.setValue('merchant', '');
      form.setValue('provider', '');
      form.setValue('merchantAccounts', []);
    }
  }, [currentCompanyValue]);

  useEffect(() => {
    if (isFormTouched && !isFirstRender) {
      form.setValue('provider', '');
      form.setValue('merchantAccounts', []);
    }
  }, [currentMerchantValue]);

  useEffect(() => {
    if (!isFirstRender && isFormTouched) {
      form.setValue('merchantAccounts', []);
    }
  }, [currentMerchantValue, currentProviderValue]);

  const renderAlert = () => {
    if (Object.keys(form.formState.errors).length && !form.formState.errors?.periodError) {
      return <CumulativeReportRequiredFieldsAlert onClose={() => setShowAlert(false)} />;
    }

    if (form.formState.errors?.periodError) {
      return <CumulativeReportPeriodAlert onClose={() => setShowAlert(false)} />;
    }

    if (data.length) {
      return <CumulativeReportSuccessAlert filters={filters} onClose={() => setShowAlert(false)} />;
    }

    return <></>;
  };

  useEffect(() => {
    setShowAlert(true);
    const intervalInDays =
      differenceInCalendarDays(form.getValues('periodTo'), form.getValues('periodFrom')) + 1;
    if (intervalInDays > MAX_PERIOD_IN_DAYS) {
      form.setError('periodError', {
        type: 'custom',
        message: `Период формирования отчета не должен превышать ${MAX_PERIOD_IN_DAYS} календарных дней!`,
      });
    }
  }, [filters, form.formState.isDirty]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter alert={showAlert ? renderAlert() : undefined}>
          <FilterRow sx={{ mb: 2 }}>
            <Controller
              control={form.control}
              name="periodFrom"
              render={({ field, fieldState, formState }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      error={!!fieldState.error || !!formState.errors.periodError}
                      fullWidth
                      label="Период отчета от"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={form.getValues('periodTo')}
                  locale="ru"
                  maxDate={form.getValues('periodTo') || new Date()}
                  onChange={(date) => {
                    field.onChange(date);
                    form.clearErrors('periodError');
                  }}
                  selected={field.value}
                  selectsStart
                  startDate={field.value}
                />
              )}
            />
            <Controller
              control={form.control}
              name="periodTo"
              render={({ field, fieldState, formState }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <CalendarTodayIcon />,
                      }}
                      error={!!fieldState.error || !!formState.errors.periodError}
                      fullWidth
                      label="Период отчета до"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={field.value}
                  locale="ru"
                  maxDate={new Date()}
                  minDate={form.getValues('periodFrom')}
                  onChange={(date: Date) => {
                    field.onChange(getEndOfDate(date));
                    form.clearErrors('periodError');
                  }}
                  selected={field.value}
                  selectsEnd
                  startDate={form.getValues('periodFrom')}
                />
              )}
            />
            <ReferenceInput
              filter={{ active: true }}
              name="currency"
              perPage={1000}
              reference="currencies"
              resettable
              resource="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта отчета"
                name="currency"
                optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
                optionValue="alphaCode"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors.currency}
                    label="Валюта отчета"
                    variant="outlined"
                  />
                )}
              />
            </ReferenceInput>
            <SelectInputShrink
              choices={BOOLEAN_CHOICES}
              defaultValue={false}
              fullWidth
              helperText={false}
              id="accountsSummary"
              label={<FieldTitle isRequired={false} label="Итог по счетам" />}
              name="accountsSummary"
              source="accountsSummary"
              validate={required()}
              variant="outlined"
            />
          </FilterRow>
          <FilterRow>
            <ReferenceInput
              filter={{ archive: false }}
              isFilter={true}
              name="merchants"
              perPage={200}
              reference="companies"
              resettable
              resource="companies"
              source="company"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                name="company"
                optionText="name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors.company}
                    label="Компания"
                    variant="outlined"
                  />
                )}
                validate={required()}
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              filter={{
                companies: currentCompanyValue || '',
                archive: false,
              }}
              isFilter={true}
              name="merchants"
              perPage={200}
              reference="merchants"
              resettable
              resource="merchants"
              source="merchant"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                disabled={!currentCompanyValue}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                name="merchant"
                optionText="name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors.merchant}
                    label="Мерчант"
                    variant="outlined"
                  />
                )}
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              filter={{ merchantAccounts: { merchants: currentMerchantValue } }}
              isFilter={true}
              name="providers"
              perPage={200}
              reference="providers"
              resettable
              resource="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                disabled={!currentMerchantValue}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                name="provider"
                optionText="name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={!!form.formState.errors.provider}
                    label="Провайдер"
                    variant="outlined"
                  />
                )}
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              filter={{
                merchants: currentMerchantValue || '',
                provider: currentProviderValue || '',
              }}
              isFilter={true}
              name="merchantAccounts"
              perPage={200}
              reference="merchant_accounts"
              resettable
              resource="merchant_accounts"
              source="merchantAccounts"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!currentMerchantValue}
                helperText={false}
                label="Счет мерчанта"
                name="merchantAccounts"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
          </FilterRow>
          <FilterRow>
            <FilterActions sx={{ marginTop: '16px' }}>
              <FilterApplyButton key="apply-button" type="submit" />
              <FilterResetButton key="reset-button" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
