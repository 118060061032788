import React from 'react';

import { appColors } from 'themes/variables';
import { AmountDataType } from 'types';

import { AmountDataField } from './BalanceReportAmountField';
import { BalanceReportBoxCell } from './BalanceReportBoxCell';
import { StyledBoxCell, StyledRowBox } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';
import { useBalanceReportContext } from '../context/BalanceReportContext';

type Props = {
  paddingFirstCell: number;
  name: string;
  amountData: AmountDataType;
};

export const BalanceReportRow: React.FC<Props> = ({ paddingFirstCell, name, amountData }) => {
  const { filters } = useBalanceReportContext();

  return (
    <StyledRowBox firstCellWidth={376 - paddingFirstCell}>
      <BalanceReportBoxCell>{name}</BalanceReportBoxCell>
      <BalanceReportBoxCell />
      <BalanceReportBoxCell>{`${amountData?.convertedBeginPeriodBalance} ${filters?.currency}`}</BalanceReportBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.success.main}
          convertedValue={`${amountData.convertedPaymentAmount} ${filters?.currency}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.error.main}
          convertedValue={`${amountData.convertedPayoutAmount} ${filters?.currency}`}
        />
      </StyledBoxCell>
      <BalanceReportBoxCell>{`${amountData?.convertedRefundAmount} ${filters?.currency}`}</BalanceReportBoxCell>
      <BalanceReportBoxCell>{`${amountData?.convertedChargebackAmount} ${filters?.currency}`}</BalanceReportBoxCell>
      <BalanceReportBoxCell>{`${amountData?.convertedCommissionAmount} ${filters?.currency}`}</BalanceReportBoxCell>
      <BalanceReportBoxCell>{`${amountData?.convertedTotal} ${filters?.currency}`}</BalanceReportBoxCell>
      <BalanceReportBoxCell>{`${amountData?.convertedEndPeriodBalance} ${filters?.currency}`}</BalanceReportBoxCell>
      <BalanceReportBoxCell />
    </StyledRowBox>
  );
};
