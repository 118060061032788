import {
  ExportButton as AdminExportButton,
  ExportButtonProps as AdminExportButtonProps,
} from 'react-admin';

export type ExportButtonProps = AdminExportButtonProps;

export function ExportButton({
  variant = 'outlined',
  label = 'Экспортировать',
  size = 'medium',
  ...restProps
}: ExportButtonProps): JSX.Element {
  return <AdminExportButton label={label} size={size} variant={variant} {...restProps} />;
}
