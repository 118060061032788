import { Datagrid, DatagridProps } from 'shared/react-admin/Datagrids';

import { GatewayRuleMappingDatagridEmpty } from './GatewayRuleMappingDatagridEmpty';

export function GatewayRuleMappingDatagrid({
  children,
  isLoading,
  bulkActionButtons = false,
  empty = <GatewayRuleMappingDatagridEmpty>Шлюзы не добавлены</GatewayRuleMappingDatagridEmpty>,
  ...restProps
}: DatagridProps): JSX.Element {
  return (
    <Datagrid
      bulkActionButtons={bulkActionButtons}
      empty={empty}
      isLoading={isLoading}
      sx={{
        borderRadius: '4px',
        '& .MuiTableCell-root': { width: '850px', padding: '16px' },
        '& .MuiTableCell-root:last-child': { width: '65px' },
      }}
      {...restProps}
    >
      {children}
    </Datagrid>
  );
}
