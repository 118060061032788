import { ListButtonProps as RaListButtonProps } from 'react-admin';
import { Link } from 'react-router-dom';

import { ButtonStyled } from './LinkTextButton.styled';

export type LinkTextButtonProps = {
  filter?: Record<string, any>;
  pathname: string;
} & RaListButtonProps;
export const LinkTextButton: React.FC<LinkTextButtonProps> = ({
  filter,
  label,
  pathname,
  ...restProps
}) => {
  const encodeFilter = encodeURIComponent(JSON.stringify(filter));

  return (
    <ButtonStyled
      component={Link}
      label={label}
      to={{
        pathname,
        ...(filter && { search: `filter=${encodeFilter}` }),
      }}
      {...restProps}
    />
  );
};
