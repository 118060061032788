import { useEffect, useRef } from 'react';

import { RaRecord, useInput } from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { AutocompleteInputIntrospected } from 'shared/react-admin/Inputs';
import { fieldWidth } from 'themes/variables';

export const LimitsEditCurrencyInput = () => {
  const { watch } = useFormContext();
  const form = useForm();
  const {
    field: { value: currencyValue, onChange: onCurrencyChange },
  } = useInput({ source: 'currency' });
  const gatewayValueRef = useRef<string | null>(null);

  const gatewayValue = watch('gateway');

  useEffect(() => {
    if (gatewayValue && !gatewayValueRef.current) {
      gatewayValueRef.current = gatewayValue;

      return;
    }
    if (gatewayValueRef.current && gatewayValueRef.current !== gatewayValue) {
      onCurrencyChange(null);
    }
  }, [gatewayValue, form]);

  return (
    <AutocompleteInputIntrospected
      disabled={!gatewayValueRef.current}
      filterToQuery={(searchText) => ({ currency: searchText })}
      helperText={false}
      label="Валюта"
      onChange={onCurrencyChange}
      optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
      size="medium"
      source="name"
      sx={fieldWidth.default}
      value={currencyValue}
      variant="outlined"
    />
  );
};
