import { useState } from 'react';

import { queryClient } from 'App';
import { archiveMerchant } from 'config/requests';
import { useNotify } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { BaseDialog } from 'shared/mui/Dialogs';
import { Merchant } from 'types';

type MerchantsUnarchiveDialogProps = {
  record: Merchant;
  open: boolean;
  onClose: VoidFunction;
};

export const MerchantsUnarchiveDialog: React.FC<MerchantsUnarchiveDialogProps> = ({
  record,
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const onArchive = async () => {
    try {
      setIsLoading(true);
      await archiveMerchant(record.id, false);
      setIsLoading(false);
      notify(`Мерчант ${record.name} восстановлен`, { type: 'success' });
      onClose();
      await queryClient.refetchQueries(['merchants']);
    } catch (e: any) {
      notify(e?.data?.detail || 'Ошибка сервера', { type: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog
      message={`Мерчант ${record.name} будет переведен в активное состояние и станет доступен для настроек в системе и формирования финансовой отчетности.`}
      onClose={onClose}
      open={open}
      rightActionsSlot={[
        <Button key="close-button" label="отменить" onClick={onClose} variant="outlined" />,
        <Button
          isLoading={isLoading}
          key="archive-button"
          label="Восстановить"
          onClick={onArchive}
          variant="contained"
        />,
      ]}
      title="Восстановление мерчанта"
    />
  );
};
