import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';

import { Title, Check, DoNotDisturbAlt } from '@mui/icons-material';
import { RaRecord } from 'react-admin';
import { appColors } from 'themes/variables';

import { ReactComponent as PilotIcon } from '../../../../images/pilotFilled.svg';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

const sx = { width: 20, height: 20 };

export type GatewayStatusIconFieldProps = Omit<FunctionFieldProps, 'render'>;

const GatewayStatusIconConfig: Record<string, JSX.Element> = {
  [GATEWAY_STATUSES[GatewayStatus.Active].id]: (
    <Check htmlColor={appColors.success.light} sx={sx} />
  ),
  [GATEWAY_STATUSES[GatewayStatus.Test].id]: <Title htmlColor={appColors.warning.light} sx={sx} />,
  [GATEWAY_STATUSES[GatewayStatus.Pilot].id]: <PilotIcon style={sx} />,
};

const GatewayStatusIcon = ({ record }: { record: RaRecord }): JSX.Element =>
  GatewayStatusIconConfig[record.status] || (
    <DoNotDisturbAlt htmlColor={appColors.error.light} sx={sx} />
  );

export const GatewayStatusIconField = (props: GatewayStatusIconFieldProps): JSX.Element => (
  <FunctionField render={(record: RaRecord) => <GatewayStatusIcon record={record} />} {...props} />
);
