import { useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useGetOne } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { MappingItemRecord, Permission } from 'types';

import { BoxStyled } from './EditPayoutControlMapping.styled';
import { EditPayoutControlMappingAddConfirmDialog } from './EditPayoutControlMappingAddConfirmDialog/EditPayoutControlMappingAddConfirmDialog';
import { EditPayoutControlMappingAddDialog } from './EditPayoutControlMappingAddDialog/EditPayoutControlMappingAddDialog';
import { EditPayoutControlMappingDeleteDialog } from './EditPayoutControlMappingDeleteDialog/EditPayoutControlMappingDeleteDialog';
import { EditPayoutControlMappingField } from './EditPayoutControlMappingField/EditPayoutControlMappingField';
import { EditPayoutControlMappingReplaceConfirmDialog } from './EditPayoutControlMappingReplaceConfirmDialog/EditPayoutControlMappingReplaceConfirmDialog';
import { EditPayoutControlMappingReplaceDialog } from './EditPayoutControlMappingReplaceDialog/EditPayoutControlMappingReplaceDialog';
import { CurrentMappingContextProvider } from '../context/CurrentMappingContext';
import { useEditPayoutControlContext } from '../context/EditPayoutControlContext';

export const EditPayoutControlMapping: React.FC = () => {
  const [isOpenAddDialog, setIsAddDialog] = useState(false);
  const [isOpenReplaceDialog, setIsOpenReplaceDialog] = useState(false);
  const [isOpenConfirmAddDialog, setIsOpenConfirmAddDialog] = useState(false);
  const [isOpenConfirmReplaceDialog, setIsOpenConfirmReplaceDialog] = useState(false);
  const { hasResourcePermissions } = usePermissions();
  const { payoutControl } = useEditPayoutControlContext();

  const { data: mapping } = useGetOne(
    'mappings',
    { id: payoutControl.mapping },
    { enabled: !!payoutControl.mapping },
  );

  const {
    record: currentMapping,
    handleCloseRecordModal: handleCloseMappingExceptionDeleteModal,
    openRecordModal: openMappingExceptionDeleteModal,
    handleOpenRecordModal: handleOpenMappingExceptionDeleteModal,
  } = useRecordModal<MappingItemRecord>();

  return (
    <CurrentMappingContextProvider>
      <BoxStyled>
        <CardHeader
          {...(!mapping &&
            hasResourcePermissions('merchant_payout_controls', [
              Permission.Full,
              Permission.Patch,
            ]) && {
              actions: [
                <Button
                  key="add-test-clients-exceptions"
                  label="Добавить"
                  onClick={() => setIsAddDialog(true)}
                  size="small"
                  variant="outlined"
                />,
              ],
            })}
          description="Будет всегда отображаться для клиентов среди доступных методов на вывод."
          sx={{ height: '79px' }}
          title="Маппинг исключение"
          titleSize="small"
        />
        {mapping && (
          <CardContent sx={{ paddingBottom: '8px!important' }}>
            <EditPayoutControlMappingField
              mapping={mapping}
              onDelete={() => handleOpenMappingExceptionDeleteModal(mapping)}
              onReplace={() => setIsOpenReplaceDialog(true)}
            />
          </CardContent>
        )}
        {isOpenAddDialog && (
          <EditPayoutControlMappingAddDialog
            onClose={() => setIsAddDialog(false)}
            onOpenConfirmDialog={() => setIsOpenConfirmAddDialog(true)}
            open={isOpenAddDialog}
          />
        )}
        {isOpenReplaceDialog && (
          <EditPayoutControlMappingReplaceDialog
            mapping={mapping}
            onClose={() => setIsOpenReplaceDialog(false)}
            onOpenConfirmDialog={() => setIsOpenConfirmReplaceDialog(true)}
            open={isOpenReplaceDialog}
          />
        )}
        {isOpenConfirmAddDialog && (
          <EditPayoutControlMappingAddConfirmDialog
            onClose={() => {
              setIsOpenConfirmAddDialog(false);
              setIsAddDialog(true);
            }}
            onSubmit={() => setIsOpenConfirmAddDialog(false)}
            open={isOpenConfirmAddDialog}
          />
        )}
        {isOpenConfirmReplaceDialog && (
          <EditPayoutControlMappingReplaceConfirmDialog
            mapping={mapping}
            onClose={() => {
              setIsOpenConfirmReplaceDialog(false);
              setIsOpenReplaceDialog(true);
            }}
            onSubmit={() => setIsOpenConfirmReplaceDialog(false)}
            open={isOpenConfirmReplaceDialog}
          />
        )}
        {openMappingExceptionDeleteModal && (
          <EditPayoutControlMappingDeleteDialog
            isOpen={openMappingExceptionDeleteModal}
            mapping={currentMapping}
            onClose={handleCloseMappingExceptionDeleteModal}
          />
        )}
      </BoxStyled>
    </CurrentMappingContextProvider>
  );
};
