import { Avatar } from 'shared/mui/Avatars';
import { Chip } from 'shared/mui/Chips';
import { FunctionField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { RoleCategory, RoleEntity } from 'types';

export const UserRoleChipField: React.FC = () => {
  return (
    <ReferenceField link={false} reference="roles" source="roleEntities[0]">
      <FunctionField
        render={(role: RoleEntity) => {
          return (
            <ReferenceField link={false} reference="role_categories" source="roleCategories">
              <FunctionField
                render={(record: RoleCategory) => {
                  return <Chip avatar={<Avatar src={record?.description} />} label={role.name} />;
                }}
              />
            </ReferenceField>
          );
        }}
      />
    </ReferenceField>
  );
};
