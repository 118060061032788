import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { EMPTY_FIELD } from '../../../constants';
import { GatewayDeactivationReason } from '../../../types';

export const GatewayDeactivationReasonsGatewayCountField = ({
  label = 'Кол-во шлюзов',
}: Omit<FunctionFieldProps, 'render'>): JSX.Element => (
  <FunctionField
    label={label}
    render={(record: GatewayDeactivationReason) => {
      const isZero = record?.gatewayCount === 0;
      return (
        <Typography
          color={isZero ? appColors.text.disabled : appColors.primary.dark}
          variant="body2"
        >
          {isZero ? EMPTY_FIELD : record?.gatewayCount}
        </Typography>
      );
    }}
  />
);
