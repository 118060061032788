import { AxiosError } from 'axios';
import { deleteGatewayConversionSettings } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';

export function useDeleteGatewayConversionSettings() {
  const notify = useNotify();

  const mutation = useMutation(({ id }: { id: number }) => deleteGatewayConversionSettings(id), {
    onSuccess: async () => {
      notify('Настройки успешно удалены', { type: 'success' });
    },
    onError: (e) => {
      const error = e as AxiosError;
      notify(`Ошибка: ${error.message}`, { type: 'error' });
    },
  });

  return { deleteGatewayConversionSettings: mutation };
}
