import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const LabelWrapperStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const LabelStyled = styled(Box)({
  width: '65%',
  marginRight: '8px',
});
