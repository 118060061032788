import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
};

export const OrderConversionListRefreshDisable: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        title="Выключить автоматическое обновление"
      />
      <CardContent>Автоматическое обновление данных мониторинга будет приостановлено!</CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <Button
            key="close-button"
            label="Отменить"
            onClick={() => {
              onCancel && onCancel();
              onClose();
            }}
            variant="outlined"
          />,
          <Button
            color="error"
            key="save-button"
            label="Выключить"
            onClick={() => {
              onConfirm && onConfirm();
              onClose();
            }}
            variant="contained"
          />,
        ]}
      />
    </Dialog>
  );
};
