import { useChoicesContext } from 'ra-core';
import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputProps } from 'shared/react-admin/Inputs/AutocompleteInput/AutocompleteInput';

import { StyledAutocompleteInput } from './AutocompleteInputShrink.styled';

export type AutocompleteInputShrinkProps = AutocompleteInputProps;

export const AutocompleteInputShrink: React.FC<AutocompleteInputShrinkProps> = ({
  sx = {
    '.MuiFormControl-root': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  ...props
}) => {
  const {
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    isRequired: isRequiredOverride,
    id: idOverride,
    field: fieldOverride,
    defaultValue,
    onBlur,
    onChange,
    parse,
    format,
    resource: resourceProp,
    source: sourceProp,
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    validate,
  } = props;
  const { resource, source } = useChoicesContext({
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    resource: resourceProp,
    source: sourceProp,
  });

  const {
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    id: idOverride,
    field: fieldOverride,
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    isRequired: isRequiredOverride,
    onBlur,
    onChange,
    parse,
    format,
    resource,
    source,
    validate,
    ...props,
  });

  return (
    <StyledAutocompleteInput
      renderInput={(params) => {
        const mergedTextFieldProps = {
          ...params.InputProps,
          ...props.TextFieldProps?.InputProps,
        };

        return (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={mergedTextFieldProps}
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              <InputHelperText
                error={error?.message}
                helperText={props.helperText}
                touched={isTouched || isSubmitted}
              />
            }
            label={
              <FieldTitle
                isRequired={(props as any)?.validate?.isRequired || isRequiredOverride}
                label={props.label}
                resource={resourceProp}
                source={source}
              />
            }
            size={props.size}
            variant={props.variant}
          />
        );
      }}
      sx={sx}
      {...props}
    />
  );
};
