import { getMerchantPayoutControls } from 'config/requests';
import { useQuery } from 'react-query';
import { MerchantPayoutControl } from 'types';

type UseMerchantPayoutControlsValues = {
  merchantPayoutControls: MerchantPayoutControl | undefined;
  isLoadingMerchantPayoutControls: boolean;
  merchantPayoutControlsRefetch: () => void;
  merchantPayoutControlsRemove: () => void;
};

export function useMerchantPayoutControls(id?: string): UseMerchantPayoutControlsValues {
  const {
    data: merchantPayoutControls,
    isLoading: isLoadingMerchantPayoutControls,
    refetch: merchantPayoutControlsRefetch,
    remove: merchantPayoutControlsRemove,
  } = useQuery(
    ['merchant_payout_controls', id],
    () => (id ? getMerchantPayoutControls(id) : undefined),
    {
      enabled: !!id,
      retry: false,
    },
  );

  return {
    merchantPayoutControls,
    isLoadingMerchantPayoutControls,
    merchantPayoutControlsRefetch,
    merchantPayoutControlsRemove,
  };
}
