import { useContext } from 'react';

import { HistoryContext, HistoryContextType } from 'context/historyContext';

export function useHistoryContext(): HistoryContextType {
  const context = useContext(HistoryContext);

  if (!context) {
    throw new Error('Can not `useHistoryContext` outside of the `HistoryContextProvider`');
  }

  return context;
}
