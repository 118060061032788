import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserDialog } from 'shared/react-admin/Dialogs/EditGuesserDialog/EditGuesserDialog';
import { TextInputIntrospected } from 'shared/react-admin/Inputs';
import { Company, Permission } from 'types';

import { CompaniesArchiveDialog } from './components/CompaniesArchiveDialog/CompaniesArchiveDialog';
import { CompaniesDeleteDialog } from './components/CompaniesDeleteDialog/CompaniesDeleteDialog';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const transform = ({ createdAt, updatedAt, ...rest }: Company) => rest;

export const CompaniesEdit = () => {
  const { hasResourcePermissions } = usePermissions();
  const { goBack } = useGoBack();
  const { record } = useEditController<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Company>();

  return (
    <>
      <EditGuesserDialog
        mutationOptions={{ onError: () => undefined }}
        open={!openRecordModalDelete && !openRecordModalArchive}
        sx={{ width: '600px' }}
        title="Редактирование компании"
        transform={transform}
      >
        <CardContent sx={{ width: '552px' }}>
          <TextInputIntrospected
            fullWidth
            helperText={false}
            label="Название"
            size="medium"
            source="name"
            variant="outlined"
          />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="SaveButton" label="Сохранить" />,
            <CancelButton key="CancelButton" onClick={goBack} variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('companies', [Permission.Full, Permission.Delete]) ? (
              record?.archive ? (
                <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
              ) : (
                <DeleteButton
                  label="В архив"
                  onClick={() => handleOpenRecordModalArchive()}
                  variant="outlined"
                />
              )
            ) : undefined
          }
        />
      </EditGuesserDialog>
      {openRecordModalDelete && (
        <CompaniesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
      {openRecordModalArchive && (
        <CompaniesArchiveDialog
          isToRootOnSuccess={true}
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={record}
        />
      )}
    </>
  );
};
