import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { BulkDeleteButton } from 'shared/react-admin/Buttons';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledGatewayDeactivationReasonsListDatagrid } from './GatewayDeactivationReasonsListDatagrid.styled';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { GatewayDeactivationReason, Permission } from '../../../types';
import { GatewayDeactivationReasonsGatewayCountField } from '../components/GatewayDeactivationReasonsGatewayCountField';

type GatewayDeactivationReasonsListDatagridProps = {
  handleOpenRecordModalDelete: (record?: GatewayDeactivationReason) => void;
};

export const GatewayDeactivationReasonsListDatagrid: React.FC<
  GatewayDeactivationReasonsListDatagridProps
> = ({ handleOpenRecordModalDelete }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  return (
    <StyledGatewayDeactivationReasonsListDatagrid
      bulkActionButtons={
        hasResourcePermissions('gateway_deactivation_reasons', [
          Permission.Full,
          Permission.Delete,
        ]) ? (
          <BulkDeleteButton icon={<DeleteOutlineOutlinedIcon />} />
        ) : (
          false
        )
      }
    >
      <TextField label="ID" sortBy="id" source="originId" />
      <TextField label="Причина" sortable={false} source="reason" />
      <GatewayDeactivationReasonsGatewayCountField
        label="Кол-во шлюзов"
        sortable={false}
        source="gatewayCount"
      />
      <DateTimeField label="Дата создания" source="createdAt" />
      <DateTimeField label="Дата редактир." source="updatedAt" />
      <ActionsSlots
        render={(record: GatewayDeactivationReason) => (
          <>
            {hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="gateway_deactivation_reason" />
            )}
            <ShowDialogIconButton />
            {hasResourcePermissions('gateway_deactivation_reasons', [
              Permission.Full,
              Permission.Update,
            ]) && <EditDialogIconButton />}
            {hasResourcePermissions('gateway_deactivation_reasons', [
              Permission.Full,
              Permission.Delete,
            ]) && <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />}
          </>
        )}
      />
    </StyledGatewayDeactivationReasonsListDatagrid>
  );
};
