import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { DeleteButton, DeleteButtonProps } from 'react-admin';

const StyledDeleteButton = styled(DeleteButton)({
  borderColor: red[700],
  color: red[700],
  height: '36px',
  '&:hover': {
    backgroundColor: red[50],
  },
});

export function AppDeleteButton({
  label = '',
  variant = 'outlined',
  color = 'primary',
  ...restProps
}: DeleteButtonProps): JSX.Element {
  return <StyledDeleteButton color={color} label={label} variant={variant} {...restProps} />;
}
