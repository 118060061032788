import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ButtonGroup } from '@mui/material';
import { Button } from 'shared/mui/Buttons';

type Props = {
  onAddGroupClick: () => void;
  onAddExpressionClick: () => void;
  children?: React.ReactNode;
};

export function GatewayRulesFormRuleGroupActions({
  onAddGroupClick,
  onAddExpressionClick,
  children,
}: Props): JSX.Element {
  return (
    <>
      <ButtonGroup variant="outlined">
        <Button label="Выражение" onClick={onAddExpressionClick} startIcon={<AddIcon />} />
        <Button label="Группу" onClick={onAddGroupClick} startIcon={<AddIcon />} />
      </ButtonGroup>
      {children}
    </>
  );
}
