import { AppBar as BaseAppBar, Box, Toolbar } from '@mui/material';
import { UserMenu, useSidebarState } from 'react-admin';
import { NotificationButton } from 'shared/widgets/Notification/NotificationButton/NotificationButton';

import { AppLoadingIndicator } from './AppLoadingIndicator';
import { ENVIRONMENT_NAME } from '../config/entrypoint';
import { ReactComponent as Logo } from '../images/kuberaLogo.svg';
import { ReactComponent as Kubera } from '../images/kuberaTypography.svg';
import { appColors } from '../themes/variables';

export const AppBar = (): JSX.Element => {
  const [open] = useSidebarState();

  return (
    <BaseAppBar
      sx={{
        background: ENVIRONMENT_NAME === 'prod' ? appColors.header.black : appColors.header.green,
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }} variant="dense">
        <Box
          sx={{
            width: open ? '306px' : '65px',
            paddingLeft: open ? '16px' : '12px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            transition: 'width, padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          }}
        >
          <Logo
            style={{
              width: open ? '50px' : '40px',
              transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
          />
          {open && <Kubera style={{ marginLeft: 8 }} />}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <NotificationButton />
          <UserMenu />
        </Box>
      </Toolbar>
      <AppLoadingIndicator />
    </BaseAppBar>
  );
};
