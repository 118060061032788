import { Card, styled } from '@mui/material';

export const OrdersShowTableCard = styled(Card)({
  boxShadow: 'none',
  border: '1px solid rgba(0,0,0,0.12)',
  marginBottom: '24px',
  '& .MuiTable-root': {
    borderCollapse: 'unset',
  },
});
