import { format, fromUnixTime } from 'date-fns';
import { useGetList } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

import { CumulativeReportFilterValues } from '../../CumulativeReportList';

type Props = {
  onClose: () => void;
  filters: CumulativeReportFilterValues;
};

export const CumulativeReportSuccessAlert: React.FC<Props> = ({ onClose, filters }) => {
  const { periodFrom, periodTo } = filters;
  const { data: currencyData } = useGetList<Currency>('currencies', {
    filter: { alphaCode: filters?.currency },
    sort: { field: 'id', order: 'ASC' },
  });
  const currencyName = currencyData?.[0]
    ? `${currencyData?.[0].alphaCode}, ${currencyData?.[0].name}`
    : '';

  return (
    <Alert onClose={onClose} severity="success" sx={{ marginTop: '16px' }}>
      <Typography fontWeight={500}>Актуальный курс</Typography>
      <Typography>
        {`Отчет сформирован за период ${
          periodFrom ? format(fromUnixTime(periodFrom), 'dd.MM.yyyy HH:mm') : ''
        } -
          ${
            periodTo ? format(fromUnixTime(periodTo), 'dd.MM.yyyy HH:mm') : ''
          } и сконвертирован в валюте "${currencyName}" на текущую дату запроса.`}
      </Typography>
    </Alert>
  );
};
