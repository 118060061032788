import React, { memo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { usePermissions } from 'hooks/usePermissions';
import { Bar } from 'react-chartjs-2';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { Tooltip as TooltipMui } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { GatewayConversionListItem } from 'types/orderConversion';

import {
  ButtonStyled,
  ChartWrapperStyled,
  LegendWrapperStyled,
  TitleWrapperStyled,
  WrapperStyled,
} from './OrderConversionListGateway.styled';
import { OrderConversionListGatewaySwitch } from './OrderConversionListGatewaySwitch/OrderConversionListGatewaySwitch';
import { Permission } from '../../../../../types';
import { ConversionLegend } from '../components/ConversionLegend/ConversionLegend';
import { defaultOptions } from '../config/defaultOptions';
import { CONVERSION_COLORS } from '../constants/conversionColors';
import { TRANSLATE_LABELS } from '../constants/translateLabels';
import { useGatewayConversionSettings } from '../hooks/useGatewayConversionSettings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
);

const labels = ['Conversion'];

type Props = {
  isLoading: boolean;
  listItem: GatewayConversionListItem;
  onOpenCreateModal: (record: GatewayConversionListItem) => void;
  onOpenEditModal: (record: GatewayConversionListItem) => void;
};

// eslint-disable-next-line react/display-name
export const OrderConversionListGateway: React.FC<Props> = memo((props) => {
  const { hasResourcePermissions } = usePermissions();

  const datasets = props.listItem?.conversionItems.map((item) => {
    return {
      label: TRANSLATE_LABELS[item.label],
      data: [item.value],
      backgroundColor: CONVERSION_COLORS[item.label],
    };
  });

  const data: ChartData<'bar', number[], string> = {
    labels,
    datasets,
  };

  const { data: settings } = useGatewayConversionSettings(
    props.listItem?.gatewayConversionSettingId || null,
  );

  function renderTools() {
    if (props.isLoading) {
      return 'Загрузка...';
    }

    return (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {settings
          ? hasResourcePermissions('gateway_conversion_settings', [
              Permission.Full,
              Permission.Update,
            ]) && (
              <ButtonStyled
                color="secondary"
                label="Пороговые значения"
                onClick={() => {
                  props.onOpenEditModal(props.listItem);
                }}
                startIcon={<EditIcon />}
                variant="text"
              />
            )
          : hasResourcePermissions('gateway_conversion_settings', [
              Permission.Full,
              Permission.Create,
            ]) && (
              <ButtonStyled
                color="secondary"
                label="Пороговые значения"
                onClick={() => {
                  props.onOpenCreateModal(props.listItem);
                }}
                startIcon={<AddIcon />}
                variant="text"
              />
            )}
        {hasResourcePermissions('gateway_conversion_settings', [
          Permission.Full,
          Permission.Update,
          Permission.Create,
        ]) && <OrderConversionListGatewaySwitch disabled={!settings} record={settings} />}
      </Box>
    );
  }

  return (
    <WrapperStyled>
      <TitleWrapperStyled>
        <Typography component="h2" variant="h5">
          ID:{props.listItem?.id ?? 0} {props.listItem?.name}
        </Typography>
        {renderTools()}
      </TitleWrapperStyled>
      <ChartWrapperStyled>
        <Bar data={data} options={defaultOptions} />
      </ChartWrapperStyled>
      <LegendWrapperStyled>
        <ConversionLegend data={data} />
        {props.listItem?.messages?.length > 0 && (
          <TooltipMui
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '12px', gap: '4px' }}>
                {props.listItem?.messages.map((message, index) => (
                  <span key={index}>{message}</span>
                ))}
              </Box>
            }
          >
            <Chip
              color="warning"
              icon={<InfoOutlinedIcon />}
              label="Отклонения"
              onClick={() => ({})}
              state="default"
            />
          </TooltipMui>
        )}
      </LegendWrapperStyled>
    </WrapperStyled>
  );
});
