import { Form, Identifier, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { CreateStyled } from './ApiClientsShowWhiteIpAddressesCreateDialog.styled';
import { ApiClientsShowWhiteIpAddressesForm } from '../components/ApiClientsShowWhiteIpAddressesForm/ApiClientsShowWhiteIpAddressesForm';

type Props = {
  open: boolean;
  onClose: () => void;
  apiClient?: Identifier;
};

interface CreateFormValues extends FieldValues {
  ip: string;
  mask: string;
  apiClient: Identifier;
}

export const ApiClientsShowWhiteIpAddressesCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  apiClient,
}) => {
  const [create] = useCreate();
  const notify = useNotify();

  const onSubmit = (formValues: CreateFormValues) => {
    create(
      'api_client_white_ip_addresses',
      {
        data: {
          ...formValues,
          apiClient,
        },
      },
      {
        onSuccess: () => {
          notify('Белый IP адрес успешно создан!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => notify(`Ошибка: ${(error as Error).message}`, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CreateStyled resource="api_client_white_ip_addresses" sx={{ margin: 0 }}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание белого IP адреса"
          />
          <ApiClientsShowWhiteIpAddressesForm />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Создать" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </CreateStyled>
    </Dialog>
  );
};
