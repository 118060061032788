import { RaRecord } from 'react-admin';

import { UserEntityPermissionsNames } from './constants/userEntityPermissionsNames';
import { UserEntityPermissionsDialogCreate } from './UserEntityPermissionsDialogCreate/UserEntityPermissionsDialogCreate';
import { UserEntityPermissionsDialogEdit } from './UserEntityPermissionsDialogEdit/UserEntityPermissionsDialogEdit';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  record: RaRecord;
  entityName: UserEntityPermissionsNames;
  titleCreate: string;
  titleEdit: string;
};

const RECORD_PERMISSIONS_KEY = 'permissionsCount';

export const UserEntityPermissionsDialog: React.FC<Props> = ({
  open,
  onClose,
  record,
  entityName,
  titleCreate,
  titleEdit,
}) => {
  const isEditMode = !!record[RECORD_PERMISSIONS_KEY];
  if (isEditMode) {
    return (
      <UserEntityPermissionsDialogEdit
        entityName={entityName}
        onClose={onClose}
        open={open}
        recordId={record.originId}
        recordName={record.name}
        title={titleEdit}
      />
    );
  }
  return (
    <UserEntityPermissionsDialogCreate
      entityName={entityName}
      onClose={onClose}
      open={open}
      recordId={record.originId}
      recordName={record.name}
      title={titleCreate}
    />
  );
};
