import { ReferenceInput } from '..';

export const ReferenceInputIntrospectedComponent = ({
  perPage = 1000,
  children,
  ...props
}: any): JSX.Element => {
  return (
    <ReferenceInput {...props} perPage={perPage}>
      {children}
    </ReferenceInput>
  );
};
