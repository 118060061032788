import { queryClient } from 'App';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { MerchantNotification } from 'types';

import { useDeleteMerchantNotification } from '../hooks/useDeleteMerchantNotification';
import { useGetNotificationTransports } from '../hooks/useGetNotificationTransports';
import { getNotificationTransportName } from '../utils/getNotificationTransportName';

type MerchantsShowNotificationsDeleteDialogProps = {
  onClose: VoidFunction;
  open: boolean;
  record: MerchantNotification;
  onCloseSuccess: VoidFunction;
};

export const MerchantsShowNotificationsDeleteDialog: React.FC<
  MerchantsShowNotificationsDeleteDialogProps
> = ({ onClose, open, record, onCloseSuccess }) => {
  const { deleteMerchantNotification, isLoading } = useDeleteMerchantNotification(record.id, () => {
    onCloseSuccess();
    queryClient.invalidateQueries('merchant_notification_settings');
  });
  const { notificationTransports } = useGetNotificationTransports();
  const notificationTransportName = getNotificationTransportName(
    record?.notificationTransport,
    notificationTransports,
  );

  return (
    <DeleteDialog
      details={`Настройки с типом уведомления ${notificationTransportName} будут удалены без возможности восстановления.`}
      isLoading={isLoading}
      onClose={onClose}
      onDelete={deleteMerchantNotification}
      onSuccess={onCloseSuccess}
      open={open}
      title="Удаление настроек уведомления"
    />
  );
};
