import { CreateButton } from 'shared/mui/Buttons';
import { EditDialogIconButton, ShowDialogIconButton } from 'shared/mui/IconButtons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { LimitsFilters } from './LimitsFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { UserActionsIconButton } from '../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Permission } from '../../types';

export const LimitsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('limits', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create-limits" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Лимиты',
        tooltipText:
          'Данные о минимальных и максимальных значениях сумм пополнений и выводов шлюза в некоторой валюте.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <LimitsFilters />
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        {hasResourceAccess('gateways') && (
          <ReferenceOneField
            label="Шлюз"
            link="show"
            reference="gateways"
            sortBy="gateway.name"
            source="gateway"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.name"
            source="currency"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        <TextField emptyText="—" label="Мин. сумма пополнения" source="paymentMin" />
        <TextField emptyText="—" label="Макс. сумма пополнения" source="paymentMax" />
        <TextField emptyText="—" label="Мин. сумма вывода" source="payoutMin" />
        <TextField emptyText="—" label="Макс. сумма вывода" source="payoutMax" />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <ActionsSlots
          render={() => (
            <>
              {hasResourceAccess('user_actions') && <UserActionsIconButton entityName="limit" />}
              <ShowDialogIconButton />
              {hasResourcePermissions('limits', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
            </>
          )}
        />
      </Datagrid>
    </ListPage>
  );
};
