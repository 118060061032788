import { ElementType } from 'react';

import { Stack } from 'shared/mui/Stack/Stack';
import { SvgIcon } from 'shared/mui/SvgIcon/SvgIcon';
import { Typography } from 'shared/mui/Typography';

export type IconLabelProps = {
  label: string;
  icon: ElementType;
};

export const IconLabel = ({ label, icon }: IconLabelProps): JSX.Element => (
  <Stack direction="row" gap={1} sx={{ marginLeft: '2px' }}>
    <SvgIcon component={icon} inheritViewBox={true} sx={{ fontSize: '18px' }} />
    <Typography fontWeight={500} variant="body2">
      {label}
    </Typography>
  </Stack>
);
