import { styled } from '@mui/material/styles';

import { Box } from '../../Box';

export const FilterCollapsibleChipsToolbar = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
  minHeight: '56px',
});
