import { MERCHANT_SPACES_ROLES_ICONS, MerchantSpacesRole } from 'constants/roles';

import React from 'react';

import { useRecordContext, useTranslate } from 'react-admin';
import { Chip } from 'shared/mui/Chips';

export const MerchantSpacesRoleChip: React.FC = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Chip
      icon={MERCHANT_SPACES_ROLES_ICONS[record?.role as MerchantSpacesRole]}
      label={translate(`merchant_spaces_roles.${record?.role}`)}
    />
  );
};
