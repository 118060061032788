import { styled } from '@mui/material';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';

export const AlertStyled = styled(Alert)({
  backgroundColor: '#fdf0e6',
  color: '#5f2c01',
  '& .MuiAlert-icon svg': {
    fill: '#ED6C02',
  },
});

export const TypographyStyled = styled(Typography)({
  ol: {
    margin: 0,
  },
});
