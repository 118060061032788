import { FilterChipType } from 'constants/filterChipType';

import { useCallback } from 'react';

import { SelectInput, useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import {
  AutocompleteArrayInputShrink,
  AutocompleteInputShrink,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import {
  transformDateFilterValue,
  transformAmountFilterValue,
  splitAmountFilterValue,
  splitCreatedAtFilterValue,
} from 'utils';

import { BankOperationsFilterAmount } from './BankOperationsFilterAmount';
import { BankOperationsFilterDate } from './BankOperationsFilterDate';
import { useFilterChips } from '../../../../hooks/useFilterChips';
import { useFilters } from '../../../../hooks/useFilters';
import { BankOperationsFilterFormValues, Currency } from '../../../../types';

const ProviderAccountsFilter = (): JSX.Element => {
  const { filterValues, displayedFilters, setFilters } = useListContext();

  const clearAmountFrom = useCallback(() => {
    form.setValue('amountPennyFrom', '');
  }, []);

  const clearAmountTo = useCallback(() => {
    form.setValue('amountPennyTo', '');
  }, []);

  const { form, resetFilterBySource, resetFilterArrayById } = useFilters({
    ...filterValues,
    ...splitAmountFilterValue(filterValues?.amount?.between),
    ...splitCreatedAtFilterValue(filterValues?.createdAt?.between),
  });

  const onSubmit = (values: BankOperationsFilterFormValues) => {
    setFilters(
      {
        ...values,
        amountPennyFrom: null,
        amountPennyTo: null,
        startDate: null,
        endDate: null,
        amountPenny: {
          between: transformAmountFilterValue(values.amountPennyFrom, values.amountPennyTo),
        },
        dateOfOperation: { between: transformDateFilterValue(values.startDate, values.endDate) },
      },
      displayedFilters,
    );
  };

  const resetFilters = () => {
    setFilters({}, displayedFilters);
    form.reset({
      merchantAccount: '',
      merchant: '',
      gateway: '',
      type: '',
      amountPennyFrom: '',
      amountPennyTo: '',
      direction: '',
      startDate: '',
      endDate: '',
    });
  };

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'gateway', label: 'Шлюз', type: FilterChipType.Id },
      { source: 'type', label: 'Тип операции', type: FilterChipType.Id },
      { source: 'provider', label: 'Провайдер', type: FilterChipType.Id },
      { source: 'currency', label: 'Валюта', type: FilterChipType.IdArray },
      { source: 'merchant', label: 'Мерчант', type: FilterChipType.Id },
      { source: 'merchantAccount', label: 'Счет мерчанта', type: FilterChipType.Id },
      { source: 'dateOfOperation.between', label: 'Период', type: FilterChipType.DateBetween },
      { source: 'amountPenny.between', label: 'Сумма', type: FilterChipType.AmountBetween },
      { source: 'direction', label: 'Направление', type: FilterChipType.Direction },
    ],
    appliedFilters: filterValues,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilters} />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById}
          resetFilterBySource={resetFilterBySource}
        >
          <FilterFieldGroup>
            <BankOperationsFilterDate />
            <ReferenceInput name="merchant" perPage={200} reference="merchants" source="merchant">
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчант"
                name="merchant"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              name="merchantAccount"
              perPage={200}
              reference="merchant_accounts"
              source="merchantAccount"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Счет мерчанта"
                name="merchantAccount"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <EnumAutocompleteInputShrink
              filterToQuery={(searchText: string) => ({ description: searchText })}
              fullWidth
              helperText={false}
              label="Направление"
              name="direction"
              optionText="description"
              optionValue="value"
              resource="directions"
              source="direction"
              variant="outlined"
            />
            <ReferenceInput
              name="type"
              perPage={200}
              reference="bank_operation_types"
              source="type"
            >
              <SelectInput
                InputLabelProps={{ shrink: true }}
                emptyText="Все типы"
                fullWidth
                helperText={false}
                label="Тип операции"
                name="type"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput name="gateway" perPage={200} reference="gateways" source="gateway">
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                name="gateway"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              isFilter={true}
              name="provider"
              perPage={200}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <BankOperationsFilterAmount
              clearAmountFrom={clearAmountFrom}
              clearAmountTo={clearAmountTo}
            />
            <ReferenceArrayInput
              isFilter={true}
              name="currency"
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                variant="outlined"
              />
            </ReferenceArrayInput>
          </FilterFieldGroup>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};

export default ProviderAccountsFilter;
