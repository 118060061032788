import { queryClient } from 'App';
import { useNotify, useShowController, useUpdate } from 'react-admin';
import { Switch } from 'shared/mui/Switch';
import { Typography } from 'shared/mui/Typography';
import { ApiClient } from 'types';

import {
  ApiClientsShowTopPanelItem,
  ApiClientsShowTopPanelWrapper,
} from './ApiClientsShowTopPanel.styled';
import { ApiClientsShowTopPanelTitle } from './ApiClientsShowTopPanelTitle';

export const ApiClientsShowTopPanel = () => {
  const { record } = useShowController<ApiClient>();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const onSubmit = () => {
    update(
      'api_clients',
      {
        id: record?.id,
        data: {
          active: !record?.active,
        },
      },
      {
        onSuccess: () => {
          notify('Активность клиента API успешно изменена', {
            type: 'success',
          });
          queryClient.invalidateQueries('api_clients');
        },
        onError: (error) =>
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, { type: 'error' }),
      },
    );
  };

  return (
    <ApiClientsShowTopPanelWrapper>
      <ApiClientsShowTopPanelItem>
        <Switch checked={record?.active} disabled={isLoading} onClick={onSubmit} size="medium" />
        Активно
      </ApiClientsShowTopPanelItem>
      <ApiClientsShowTopPanelItem>
        <ApiClientsShowTopPanelTitle title="ID" />
        <Typography>{record?.originId}</Typography>
      </ApiClientsShowTopPanelItem>
      <ApiClientsShowTopPanelItem>
        <ApiClientsShowTopPanelTitle title="Название" />
        <Typography>{record?.name}</Typography>
      </ApiClientsShowTopPanelItem>
      <ApiClientsShowTopPanelItem>
        <ApiClientsShowTopPanelTitle title="Алиас" />
        <Typography>{record?.alias}</Typography>
      </ApiClientsShowTopPanelItem>
    </ApiClientsShowTopPanelWrapper>
  );
};
