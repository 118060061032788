import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { OrderAdjustments } from '../../../types/orderAdjustments';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  record: OrderAdjustments | undefined;
};

export const OrderAdjustmentsReportCommentDialog: React.FC<Props> = ({ open, record, onClose }) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ width: '600px' }}
        title={`Комментарий к операции #${record?.originId}`}
      />
      <CardContent>{record?.comment}</CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <CancelButton
            key="cancel-button"
            label="Вернуться к списку"
            onClick={onClose}
            variant="contained"
          />,
        ]}
      />
    </Dialog>
  );
};
