import { useEffect, useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';

import { UserDeleteModal } from './components/UserDeleteModal';
import { UsersForm } from './components/UsersForm';
import { Permission, User } from '../../types';

export const UsersEdit = () => {
  const { record } = useEditController<User>();
  const { hasResourcePermissions } = usePermissions();
  const [hasCorporateEmail, setHasCorporateEmail] = useState(false);
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } = useRecordModal<User>();

  const transform = (data: User) => {
    return {
      ...data,
      corporateEmail: hasCorporateEmail ? data.corporateEmail : null,
      roleEntities: data.roleEntities,
    };
  };

  useEffect(() => {
    setHasCorporateEmail(!!record?.corporateEmail);
  }, [record]);

  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButtonValidated alwaysEnable key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Пользователи"
      mutationOptions={{ onError: () => undefined }}
      rightActionsSlot={
        hasResourcePermissions('users', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
        ) : undefined
      }
      title="Редактирование пользователя"
      transform={transform}
    >
      <UsersForm
        hasCorporateEmail={hasCorporateEmail}
        setHasCorporateEmail={setHasCorporateEmail}
      />
      <UserDeleteModal
        handleCloseRecordModal={handleCloseRecordModal}
        openRecordModal={openRecordModal}
        record={record}
      />
    </EditGuesserPage>
  );
};
