import { useCallback } from 'react';

import { required, TextInputProps, useInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip/Tooltip/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import { generateName } from '../../../../../../config/requests';
import { ReactComponent as GenerateTextSVG } from '../../../../../../images/generateText.svg';
import { TextInputIntrospected } from '../../../../../../shared/react-admin/Inputs/TextInputs';

export type MerchantShowAccountsNameInputProps = {
  merchantId?: string;
} & TextInputProps;

export function MerchantShowAccountsNameInput({
  merchantId,
  ...rest
}: MerchantShowAccountsNameInputProps) {
  const { field } = useInput({ source: rest.source });
  const [provider, currency] = useWatch({ name: ['provider', 'currency'] });

  const handleClick = useCallback(async () => {
    const response = await generateName('merchant_account', {
      merchant: merchantId,
      provider,
      currency,
    });
    field.onChange(response.data.name);
  }, [merchantId, provider, currency]);

  return (
    <TextInputIntrospected
      {...rest}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <Tooltip title={<Typography fontSize={12}>Сгенерировать название</Typography>}>
            <span>
              <IconButton disabled={!provider || !currency} onClick={handleClick}>
                <GenerateTextSVG
                  style={{ color: provider && currency ? appColors.primary.main : 'gray' }}
                />
              </IconButton>
            </span>
          </Tooltip>
        ),
      }}
      size="medium"
      sx={{
        '& .MuiInputBase-root': { height: '56px' },
      }}
      validate={required()}
    />
  );
}
