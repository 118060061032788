import React, { isValidElement, useMemo } from 'react';

import { useHistoryURL } from 'hooks/useHistoryURL';
import { ResourceContextProvider, ResourceProps } from 'react-admin';
import { Route, Routes, useLocation } from 'react-router-dom';

export type DialogResourceProps = {
  name: string;
  list?: React.FC;
  create?: React.FC;
  edit?: React.FC;
  show?: React.FC;
  delete?: React.FC;
};

export const DialogResource = (props: DialogResourceProps): JSX.Element => {
  const { create: Create, edit: Edit, list: List, name, show: Show } = props;
  const location = useLocation();
  useHistoryURL();
  const state = location.state as { backgroundLocation?: Location };

  // Нужно для того, чтобы открывать модальное окно после перехода по ссылке
  // Так как внутри state после перехода по ссылке не будет backgroundLocation
  const isOpenModal = useMemo(
    () => ['/create', '/edit', '/show'].some((subPath) => location.pathname.includes(subPath)),
    [location],
  );

  return (
    <ResourceContextProvider value={name}>
      <>
        <Routes location={state?.backgroundLocation || location}>
          {List && <Route element={isValidElement(List) ? List : <List />} path="/*" />}
        </Routes>
        {(state?.backgroundLocation || isOpenModal) && (
          <Routes>
            {Create && <Route element={<Create />} path="create/*" />}
            {Show && <Route element={<Show />} path=":id/show/*" />}
            {Edit && <Route element={<Edit />} path=":id/edit/*" />}
          </Routes>
        )}
      </>
    </ResourceContextProvider>
  );
};

DialogResource.raName = 'Resource';

DialogResource.registerResource = ({
  icon,
  create,
  list,
  edit,
  show,
  name,
  options,
  recordRepresentation,
}: ResourceProps) => ({
  name,
  options,
  hasList: !!list,
  hasCreate: !!create,
  hasEdit: !!edit,
  hasShow: !!show,
  icon,
  recordRepresentation,
});
