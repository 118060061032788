import { styled } from '@mui/material/styles';

import { Box } from '../Box';

export const Row = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '16px',
});
