import React, { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import { ReferenceArrayInput, ReferenceArrayInputProps, useResourceContext } from 'react-admin';

import { fieldValidateGuesser } from '../../../utils';

export interface AppReferenceArrayInputProps extends ReferenceArrayInputProps {
  isFilter?: boolean;
}

const IntrospectedReferenceArrayInput = ({
  fields,
  validate,
  perPage = 1000,
  isFilter = false,
  ...props
}: any): JSX.Element => {
  const { guessedValidate, isError } = !isFilter
    ? fieldValidateGuesser({ fields, validate, ...props })
    : { guessedValidate: null, isError: false };
  if (isError) {
    return <Fragment />;
  }

  return (
    <ReferenceArrayInput validate={guessedValidate} {...props} perPage={perPage}>
      {props.children}
    </ReferenceArrayInput>
  );
};

const AppReferenceArrayInput = (props: AppReferenceArrayInputProps): JSX.Element => {
  const resource = useResourceContext(props);
  const { filter = {} } = props;

  return (
    <Introspecter
      component={IntrospectedReferenceArrayInput}
      includeDeprecated={true}
      resource={resource}
      {...props}
      filter={{ ...filter, groups: ['reference'] }}
    />
  );
};

export default AppReferenceArrayInput;
