import React, { ReactNode } from 'react';

import { AutocompleteRenderInputParams, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  AutocompleteInput as AdminAutocompleteInput,
  AutocompleteInputProps as AdminAutocompleteInputProps,
} from 'react-admin';
import { fieldWidth } from 'themes/variables';

export type AutocompleteInputProps = {
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
} & AdminAutocompleteInputProps;

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  sx,
  ...restProps
}: AdminAutocompleteInputProps) => {
  const defaultSx: SxProps<Theme> = {
    '.MuiFormControl-root': {
      marginTop: 0,
    },
    '.MuiFormHelperText-root': {
      marginTop: 0,
    },
    ...fieldWidth.medium,
  };
  return <AdminAutocompleteInput sx={{ ...defaultSx, ...sx }} {...restProps} />;
};
