import React from 'react';

import FlagProvider from '@unleash/proxy-client-react';
import App from 'App';
import { APIContextProvider } from 'context/apiContext';
import { HistoryContextProvider } from 'context/historyContext';
import { createRoot } from 'react-dom/client';
import { IConfig } from 'unleash-proxy-client';
import 'index.css';

import {
  UNLEASH_PROXY_APP_NAME,
  UNLEASH_PROXY_CLIENT_KEY,
  UNLEASH_PROXY_ENVIRONMENT,
  UNLEASH_PROXY_URL,
} from './config/entrypoint';

const config: IConfig = {
  url: UNLEASH_PROXY_URL,
  clientKey: UNLEASH_PROXY_CLIENT_KEY,
  appName: UNLEASH_PROXY_APP_NAME,
  environment: UNLEASH_PROXY_ENVIRONMENT,
};

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <APIContextProvider>
      <HistoryContextProvider>
        <FlagProvider config={config}>
          <App />
        </FlagProvider>
      </HistoryContextProvider>
    </APIContextProvider>
  </React.StrictMode>,
);
