import { ListButton as RaListButton, ListButtonProps as RaListButtonProps } from 'react-admin';

export type ListButtonProps = {
  filter?: Record<string, any>;
  pathname: string;
} & RaListButtonProps;

export const ListButton = ({
  filter,
  label,
  pathname,
  icon,
  ...restProps
}: ListButtonProps): JSX.Element => {
  const encodeFilter = encodeURIComponent(JSON.stringify(filter));

  return (
    <RaListButton
      icon={icon}
      label={label}
      to={{
        pathname,
        ...(filter && { search: `filter=${encodeFilter}` }),
      }}
      {...restProps}
    />
  );
};
