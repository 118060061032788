import ImageEye from '@mui/icons-material/RemoveRedEye';
import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ShowButton, ShowButtonProps } from 'react-admin';

import { appColors } from '../../../../themes/variables';

const StyledShowButton = styled(ShowButton)({
  color: appColors.secondary.main,
  height: '28px',
  minWidth: '28px',
  padding: '1px 0 0 3px',
  '& .MuiButton-startIcon': {
    fontSize: '20px',
    marginRight: 0,
  },
  '&:hover': { borderRadius: '50%' },
});

type AppListShowButtonProps = {
  iconSx?: SxProps<Theme>;
} & ShowButtonProps;

export function AppListShowButton({
  label = '',
  variant,
  iconSx,
  ...restProps
}: AppListShowButtonProps): JSX.Element {
  // @ts-ignore
  const icon = restProps.icon || <ImageEye sx={iconSx} />;

  return <StyledShowButton icon={icon} label={label} variant={variant} {...restProps} />;
}
