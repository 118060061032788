import { useState } from 'react';

import { CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { Merchant } from 'types';

import { CreatedSuccessDialog } from './components/CreatedSuccessDialog/CreatedSuccessDialog';
import { MerchantsCreateForm } from './MerchantsCreateForm';

export const MerchantsCreate: React.FC = () => {
  const [createdMerchant, setCreatedMerchant] = useState<Merchant | null>(null);

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Мерчанты"
      mutationOptions={{
        onError: () => undefined,
        onSuccess: (merchant: Merchant) => setCreatedMerchant(merchant),
      }}
      sx={{ marginTop: 0 }}
      title="Создание мерчанта"
    >
      <CardContent sx={{ paddingTop: '0!important', paddingBottom: '0!important' }}>
        <MerchantsCreateForm />
      </CardContent>
      {createdMerchant && <CreatedSuccessDialog merchant={createdMerchant} />}
    </CreateGuesserPage>
  );
};
