import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { commissionSchema } from 'components/Commissions/schemas/schema';
import { Form, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Create } from 'shared/react-admin/Create/Create';

import {
  CommissionsFormValues,
  MerchantShowCommissionsForm,
} from '../components/MerchantShowCommissionsForm';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
};

export const MerchantShowCommissionsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
}) => {
  const [create] = useCreate();
  const [errors, setErrors] = useState<string[]>([]);
  const notify = useNotify();

  const onSubmit = (formValues: CommissionsFormValues) => {
    create(
      'commissions',
      {
        data: {
          ...formValues,
          merchant: merchantId,
          min: formValues.min ? formValues.min + '' : null,
          max: formValues.max ? formValues.max + '' : null,
          fix: formValues.fix ? formValues.fix + '' : null,
          percent: formValues.percent ? formValues.percent + '' : null,
        },
      },
      {
        onSuccess: () => {
          notify('Комиссия успешно создана!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error: any) => {
          notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
          setErrors(error?.message.split('\n'));
        },
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open} transitionDuration={0}>
      <Create resource="commissions" sx={{ '.RaCreate-noActions': { margin: 0 } }}>
        <Form
          defaultValues={{ merchant: merchantId }}
          onSubmit={onSubmit as any}
          resolver={yupResolver<FieldValues>(commissionSchema)}
        >
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание комиссии"
          />
          <MerchantShowCommissionsForm errors={errors} merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Создать" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </Create>
    </Dialog>
  );
};
