import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

type GatewayDeactivationReasonsListFilterValues = {
  reason: string;
};

export const GatewayDeactivationReasonsListFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<GatewayDeactivationReasonsListFilterValues>({
    reason: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <TextInputShrink
              fullWidth
              helperText={false}
              label="Причина"
              name="reason"
              source="reason"
              variant="outlined"
            />
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterApplyButton key="apply-button" type="submit" />
              <FilterResetButton key="reset-button" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
